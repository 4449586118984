import React, { Component } from "react";
import SwipeNav from "../../navbar/SwipeNav";
import FeaturesCard from "../premiumComponents/featuresCard/FeaturesCard";
import "./premiumsuccess.scss";
import { Card, Button, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";

export class NoteSuccess extends Component {
  redirectHandler = (e) => {
    this.props.history.push("/app");
  };

  render() {
    var vw = window.innerWidth;

    var width = vw / 2.5 + 45 + "px";
    var height = vw / 2.5 + "px";

    var gifSize = {
      height: height,
      width: width,
    };
    return (
      <div className="premium-component">
        <SwipeNav />
        <div className="premium-page-content">
          <Alert variant="warning">
            <strong>Important</strong> Credit Card transaction will appear with:{" "}
            <strong>"CCBill 11032822 CANADA INC"</strong> on your statement for
            discrete billing!
          </Alert>
          <Card className="premium-success-card">
            <Card.Body className="premium-success-card-body">
              <h1 className="premium-success-title">Purchase Successful.</h1>
              <i className="fas fa-paper-plane purchase-success-icon-note"></i>
              <Button
                className="purchase-success-button-note"
                size="lg"
                onClick={this.redirectHandler}
              >
                Continue
              </Button>

              <p className="premium-success-subtitle">
                Your Notes have been added to your account and are ready to use.
              </p>
            </Card.Body>
          </Card>
          <FeaturesCard />
        </div>
      </div>
    );
  }
}

export default withRouter(NoteSuccess);
