import React, { useState, useEffect, useContext } from "react";
import "../desktopprofiletabs.scss";
import Axios from "axios";
import { UserContext } from "../../../../context/UserContext";
import { Link, withRouter } from "react-router-dom";
import PlacesAutocomplete from "react-places-autocomplete";
import Slider from "@material-ui/core/Slider";
import { Button, Card, Form, Badge, Alert } from "react-bootstrap";
import { api } from "../../../../api";

function DescriptionsTab(props) {
  const context = useContext(UserContext);
  const [headline, setHeadline] = useState(props.user.headline);
  const [headlineStatus, setHeadlineStatus] = useState(
    props.user.headlinestatus
  );
  const [headlineSaveButtonDisabled, setHeadlineSaveButtonDisabled] = useState(
    true
  );
  const [bio, setBio] = useState(props.user.bio);
  const [bioStatus, setBioStatus] = useState(props.user.biostatus);
  const [bioSaveButtonDisabled, setBioSaveButtonDisabled] = useState(true);
  const [successfullSaveAlertOpen, setSuccessfullSaveAlertOpen] = useState(
    false
  );

  const [benefits, setBenefits] = useState(props.user.benefits);

  const headlineChange = (e) => {
    setHeadline(e.target.value);
    setHeadlineSaveButtonDisabled(false);
  };

  const saveHeadline = async (e) => {
    e.preventDefault();
    setHeadlineSaveButtonDisabled(true);

    const data = {
      userid: context.user._id,
      headline: headline,
    };

    return api
      .updateHeadline(data)
      .then((res) => {
        if (res.status === 200) {
          setHeadlineStatus("pending");
          setSuccessfullSaveAlertOpen(true);
        }
      })
      .catch((err) => {});
  };

  const bioChange = (e) => {
    setBio(e.target.value);
    setBioSaveButtonDisabled(false);
  };

  const saveBio = async (e) => {
    e.preventDefault();
    setBioSaveButtonDisabled(true);

    const data = {
      userid: context.user._id,
      bio: bio,
    };

    return api
      .updateBio(data)
      .then((res) => {
        if (res.status === 200) {
          setBioStatus("pending");
          setSuccessfullSaveAlertOpen(true);
        }
      })
      .catch((err) => {});
  };

  const hideAlert = (e) => {
    setSuccessfullSaveAlertOpen(false);
  };

  const toggleBenefits = (value) => async (e) => {
    var benefitsCopy = benefits;

    if (value === "ActiveLifestyle") {
      if (benefitsCopy.ActiveLifestyle) {
        benefitsCopy.ActiveLifestyle = false;
      } else {
        benefitsCopy.ActiveLifestyle = true;
      }
    }

    if (value === "EmotionalConnection") {
      if (benefitsCopy.EmotionalConnection) {
        benefitsCopy.EmotionalConnection = false;
      } else {
        benefitsCopy.EmotionalConnection = true;
      }
    }

    if (value === "FlexibleSchedule") {
      if (benefitsCopy.FlexibleSchedule) {
        benefitsCopy.FlexibleSchedule = false;
      } else {
        benefitsCopy.FlexibleSchedule = true;
      }
    }

    if (value === "Attentive") {
      if (benefitsCopy.Attentive) {
        benefitsCopy.Attentive = false;
      } else {
        benefitsCopy.Attentive = true;
      }
    }

    if (value === "LongTerm") {
      if (benefits.LongTerm) {
        benefitsCopy.LongTerm = false;
      } else {
        benefitsCopy.LongTerm = true;
      }
    }

    if (value === "AllEthnicities") {
      if (benefitsCopy.AllEthnicities) {
        benefitsCopy.AllEthnicities = false;
      } else {
        benefitsCopy.AllEthnicities = true;
      }
    }

    if (value === "Discretion") {
      if (benefitsCopy.Discretion) {
        benefitsCopy.Discretion = false;
      } else {
        benefitsCopy.Discretion = true;
      }
    }

    if (value === "FineDining") {
      if (benefitsCopy.FineDining) {
        benefitsCopy.FineDining = false;
      } else {
        benefitsCopy.FineDining = true;
      }
    }

    if (value === "FriendsWithBenefits") {
      if (benefitsCopy.FriendsWithBenefits) {
        benefitsCopy.FriendsWithBenefits = false;
      } else {
        benefitsCopy.FriendsWithBenefits = true;
      }
    }

    if (value === "Investor") {
      if (benefitsCopy.Investor) {
        benefitsCopy.Investor = false;
      } else {
        benefitsCopy.Investor = true;
      }
    }

    if (value === "LifeOfLeisure") {
      if (benefitsCopy.LifeOfLeisure) {
        benefitsCopy.LifeOfLeisure = false;
      } else {
        benefitsCopy.LifeOfLeisure = true;
      }
    }

    if (value === "LuxuryLifestyle") {
      if (benefitsCopy.LuxuryLifestyle) {
        benefitsCopy.LuxuryLifestyle = false;
      } else {
        benefitsCopy.LuxuryLifestyle = true;
      }
    }

    if (value === "MarriageMinded") {
      if (benefitsCopy.MarriageMinded) {
        benefitsCopy.MarriageMinded = false;
      } else {
        benefitsCopy.MarriageMinded = true;
      }
    }

    if (value === "Mentorship") {
      if (benefitsCopy.Mentorship) {
        benefitsCopy.Mentorship = false;
      } else {
        benefitsCopy.Mentorship = true;
      }
    }

    if (value === "Monogamous") {
      if (benefitsCopy.Monogamous) {
        benefitsCopy.Monogamous = false;
      } else {
        benefitsCopy.Monogamous = true;
      }
    }

    if (value === "NoStringsAttached") {
      if (benefitsCopy.NoStringsAttached) {
        benefitsCopy.NoStringsAttached = false;
      } else {
        benefitsCopy.NoStringsAttached = true;
      }
    }

    if (value === "NonMonogamous") {
      if (benefitsCopy.NonMonogamous) {
        benefitsCopy.NonMonogamous = false;
      } else {
        benefitsCopy.NonMonogamous = true;
      }
    }

    if (value === "OpenRelationship") {
      if (benefitsCopy.OpenRelationship) {
        benefitsCopy.OpenRelationship = false;
      } else {
        benefitsCopy.OpenRelationship = true;
      }
    }

    if (value === "PassportReady") {
      if (benefitsCopy.PassportReady) {
        benefitsCopy.PassportReady = false;
      } else {
        benefitsCopy.PassportReady = true;
      }
    }

    if (value === "Platonic") {
      if (benefitsCopy.Platonic) {
        benefitsCopy.Platonic = false;
      } else {
        benefitsCopy.Platonic = true;
      }
    }

    if (value === "Romance") {
      if (benefitsCopy.Romance) {
        benefitsCopy.Romance = false;
      } else {
        benefitsCopy.Romance = true;
      }
    }

    if (value === "ShowsAndEntertainment") {
      if (benefitsCopy.ShowsAndEntertainment) {
        benefitsCopy.ShowsAndEntertainment = false;
      } else {
        benefitsCopy.ShowsAndEntertainment = true;
      }
    }

    if (value === "TravelToYou") {
      if (benefitsCopy.TravelToYou) {
        benefitsCopy.TravelToYou = false;
      } else {
        benefitsCopy.TravelToYou = true;
      }
    }

    if (value === "TravelWithMe") {
      if (benefitsCopy.TravelWithMe) {
        benefitsCopy.TravelWithMe = false;
      } else {
        benefitsCopy.TravelWithMe = true;
      }
    }

    if (value === "Vacations") {
      if (benefitsCopy.Vacations) {
        benefitsCopy.Vacations = false;
      } else {
        benefitsCopy.Vacations = true;
      }
    }

    setBenefits((prevBenefits) => {
      return {
        ...prevBenefits,
        benefitsCopy,
      };
    });

    const data = {
      userid: context.user._id,
      benefits: benefitsCopy,
    };

    return api
      .updateBenefits(data)
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="desktop-profile-tab-component">
      {successfullSaveAlertOpen && (
        <Alert
          variant="success"
          dismissible
          className="desktop-profile-save-success-alert"
          onClose={hideAlert}
        >
          Saved Successfully!
        </Alert>
      )}
      <div className="desktop-profile-tab-header">
        <h1 className="desktop-profile-tab-header-title">Your Description</h1>
      </div>
      <div className="desktop-profile-tab-content-container">
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">About You</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper-description">
            <Form className="desktop-profile-form" onSubmit={saveHeadline}>
              <Form.Group className="desktop-profile-form-group">
                <Form.Control
                  as="textarea"
                  placeholder="Enter information about you here"
                  rows="3"
                  value={headline}
                  onChange={headlineChange}
                />
                <div className="desktop-profile-form-footer">
                  <div className="desktop-profile-form-status-wrapper">
                    {headlineStatus === "pending" && (
                      <Badge variant="warning">Pending</Badge>
                    )}
                    {headlineStatus === "rejected" && (
                      <Badge variant="danger">Rejected</Badge>
                    )}
                    {headlineStatus === "approved" && (
                      <Badge variant="success">Approved</Badge>
                    )}
                  </div>
                  <div className="desktop-profile-form-save-button-wrapper">
                    <Button
                      className="desktop-profile-form-save-button"
                      size="lg"
                      type="submit"
                      disabled={headlineSaveButtonDisabled}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form.Group>
            </Form>
          </div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Looking For</h3>
          </div>
          <div className="desktop-profile-tab-section-benefits">
            <div className="desktop-profile-benefits-section">
              <Button
                variant="outline-primary"
                className={
                  benefits.ActiveLifestyle
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("ActiveLifestyle")}
              >
                Active Lifestyle{" "}
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.EmotionalConnection
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("EmotionalConnection")}
              >
                Emotional Connection
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.FlexibleSchedule
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("FlexibleSchedule")}
              >
                Flexible Schedule
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.Attentive
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("Attentive")}
              >
                Attentive
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.LongTerm
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("LongTerm")}
              >
                Long Term
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.AllEthnicities
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("AllEthnicities")}
              >
                All Ethnicities
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.Discretion
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("Discretion")}
              >
                Discretion
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.FineDining
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("FineDining")}
              >
                Fine Dining
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.FriendsWithBenefits
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("FriendsWithBenefits")}
              >
                Friends with Benefits
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.Investor
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("Investor")}
              >
                Investor
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.LifeOfLeisure
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("LifeOfLeisure")}
              >
                Life of Leisure
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.MarriageMinded
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("MarriageMinded")}
              >
                Marriage Minded
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.Mentorship
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("Mentorship")}
              >
                Mentorship
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.Monogamous
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("Monogamous")}
              >
                Monogamous
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.NoStringsAttached
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("NoStringsAttached")}
              >
                No Strings Attached
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.NonMonogamous
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("NonMonogamous")}
              >
                Non Monogamous
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.OpenRelationship
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("OpenRelationship")}
              >
                Open Relationship
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.PassportReady
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("PassportReady")}
              >
                Passport Ready
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.Platonic
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("Platonic")}
              >
                Platonic
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.Romance
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("Romance")}
              >
                Romance
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.ShowsAndEntertainment
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("ShowsAndEntertainment")}
              >
                Shows and Entertainment
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.TravelToYou
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("TravelToYou")}
              >
                Travel to You
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.TravelWithMe
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("TravelWithMe")}
              >
                Travel with Me
              </Button>
              <Button
                variant="outline-primary"
                className={
                  benefits.Vacations
                    ? "desktop-profile-benefits-button-checked"
                    : "desktop-profile-benefits-button"
                }
                onClick={toggleBenefits("Vacations")}
              >
                Vacations
              </Button>
            </div>
            <div className="desktop-profile-tab-section-form-wrapper-benefits">
              <Form className="desktop-profile-form" onSubmit={saveBio}>
                <Form.Group className="desktop-profile-form-group">
                  <Form.Control
                    as="textarea"
                    placeholder="Describe what you are looking for here"
                    rows="3"
                    value={bio}
                    onChange={bioChange}
                  />
                  <div className="desktop-profile-form-footer">
                    <div className="desktop-profile-form-status-wrapper">
                      {bioStatus === "pending" && (
                        <Badge variant="warning">Pending</Badge>
                      )}
                      {bioStatus === "rejected" && (
                        <Badge variant="danger">Rejected</Badge>
                      )}
                      {bioStatus === "approved" && (
                        <Badge variant="success">Approved</Badge>
                      )}
                    </div>
                    <div className="desktop-profile-form-save-button-wrapper">
                      <Button
                        className="desktop-profile-form-save-button"
                        size="lg"
                        type="submit"
                        disabled={bioSaveButtonDisabled}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(DescriptionsTab);
