import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";

import { withRouter, useHistory } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import { api } from "../../../../../api";

import "./editheadline.scss";

const EditHeadline = (props) => {
  const history = useHistory();
  const context = useContext(UserContext);
  const [headline, setHeadline] = useState(context.user.headline);
  const [headlineLen, setHeadlineLen] = useState(
    context.user.headline ? 300 - context.user.headline.length : 300
  );

  const headlineChange = (e) => {
    if (e.target.value.length < 301) {
      setHeadline(e.target.value);
      setHeadlineLen(300 - e.target.value.length);
    }
  };

  const saveHandler = () => {
    const data = {
      userid: context.user._id,
      headline,
    };
    return api
      .updateHeadline(data)
      .then(() => {
        const tempUser = context.user;
        tempUser.headline = headline;
        context.updateUserInfo("all", tempUser);
        props.closeHandler();
      })
      .catch(() => {});
  };

  return (
    <div className="edit-profile-v2-headline-container">
      <div className="edit-profile-v2-headline-header">
        <div
          className="edit-profile-v2-headline-header-section-left"
          onClick={() => history.goBack()}
        >
          <i className="fas fa-chevron-left" />
        </div>
        <div className="edit-profile-v2-header-section-middle">
          <h1 className="edit-profile-v2-header-title">About you</h1>
        </div>
      </div>
      <div className="edit-profile-v2-headline-title">
        <div className="edit-profile-v2-headline-title-text">
          <h1 className="edit-profile-v2-headline-title-one">Tell us</h1>
          <h1 className="edit-profile-v2-headline-title-two">
            About the real you
          </h1>
        </div>
        <div className="edit-profile-v2-headline-description">
          Enter some words about wishes, it will help people know about you.
        </div>
      </div>
      <div className="edit-profile-v2-headline-input-field">
        <Form className="edit-profile-v2-headline-form">
          <Form.Control
            as="textarea"
            rows="6"
            placeholder="Enter some text"
            onChange={headlineChange}
            value={headline}
            className="edit-profile-v2-headline-input"
            maxLength="300"
          />
        </Form>
        <div className="edit-profile-v2-headline-len">
          {`${headlineLen} characters`}
        </div>
      </div>
      <Button
        className="edit-profile-v2-headline-save"
        onClick={() => saveHandler()}
      >
        Save
      </Button>
    </div>
  );
};

export default withRouter(EditHeadline);
