import React, { Component } from "react";
import "./bio.scss";

import { Card } from "react-bootstrap";

export class Bio extends Component {
  render() {
    var bio = this.props.card.bio;
    var biostatus = this.props.card.biostatus;

    if (
      bio === null ||
      bio === undefined ||
      bio.length < 2 ||
      biostatus !== "approved"
    ) {
      return null;
    } else {
      return (
        <Card className="user-info-card">
          <Card.Body className="bio-card-body">
            <Card.Title className="bio-card-title">Looking For:</Card.Title>
            <Card.Text className="bio-card-text">{bio}</Card.Text>
          </Card.Body>
        </Card>
      );
    }
  }
}

export default Bio;
