import React from "react";
import { Button, Image } from "react-bootstrap";

import MatchCounter from "../navbar/navComponents/counters/MatchCounter";
import logo from "../../assets/logos/sugarbae-home-icon.png";
import { useHistory, withRouter } from "react-router-dom";
import "./headerstyles.scss";

function MatchesHeader(props) {
  let history = useHistory();

  const toggleFilters = (e) => {
    props.toggleFilters();
  };

  const goToPreviousPath = () => {
    history.goBack();
  };

  return (
    <div className="header-container">
      <div className="header-section-left">
        {/* <Button className="header-button" onClick={goToPreviousPath}>
          <i className="fas fa-long-arrow-alt-left header-back-icon"></i>
        </Button> */}
        <Button className="header-button" onClick={goToPreviousPath}>
          <i className="fas fa-heart header-heart-icon">
            <MatchCounter />
          </i>
        </Button>
      </div>
      <div className="header-section-center">
        <Image src={logo} className="header-logo" />
      </div>
      <div className="header-section-right">
        {/* <Button className="header-button">
          <i
            className="far fa-filter header-filter-icon"
            onClick={toggleFilters}
          ></i>
        </Button> */}
      </div>
    </div>
  );
}

export default withRouter(MatchesHeader);
