import React, { useState, useEffect } from "react";
import { api } from "../../api";
import ActiveSubAgeGroup from "../graphs/ActiveSubAgeGroup";
import "./views.scss";

function ActiveSubView(props) {
  return (
    <div className="view-container">
      <div className="view-row">
        <ActiveSubAgeGroup/>
      </div>
    </div>
  );
}

export default ActiveSubView;
