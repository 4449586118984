import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";

function Bodytype(props) {
  var filters = props.filters;
  const [bodytype, setBodytype] = useState(filters.bodytype);

  const changeHandler = (e) => {
    var bodytypeCopy = bodytype;

    if (e.target.value === "slim") {
      if (bodytypeCopy.slim) {
        bodytypeCopy.slim = false;
      } else {
        bodytypeCopy.slim = true;
      }
    } else if (e.target.value === "fit") {
      if (bodytypeCopy.fit) {
        bodytypeCopy.fit = false;
      } else {
        bodytypeCopy.fit = true;
      }
    } else if (e.target.value === "muscular") {
      if (bodytypeCopy.muscular) {
        bodytypeCopy.muscular = false;
      } else {
        bodytypeCopy.muscular = true;
      }
    } else if (e.target.value === "average") {
      if (bodytypeCopy.average) {
        bodytypeCopy.average = false;
      } else {
        bodytypeCopy.average = true;
      }
    } else if (e.target.value === "afewextrapounds") {
      if (bodytypeCopy.afewextrapounds) {
        bodytypeCopy.afewextrapounds = false;
      } else {
        bodytypeCopy.afewextrapounds = true;
      }
    } else if (e.target.value === "curvy") {
      if (bodytypeCopy.curvy) {
        bodytypeCopy.curvy = false;
      } else {
        bodytypeCopy.curvy = true;
      }
    } else if (e.target.value === "fullfigured") {
      if (bodytypeCopy.fullfigured) {
        bodytypeCopy.fullfigured = false;
      } else {
        bodytypeCopy.fullfigured = true;
      }
    }

    setBodytype((prevState) => {
      return { ...prevState, ...bodytypeCopy };
    });
  };

  const saveHandler = (e) => {
    props.updateHandler(bodytype);
  };

  return (
    <div className="filter-slider-component">
      <div className="filter-slider-overlay" onClick={props.onClose} />
      <div className="filter-slider-content">
        <div className="filter-slider-title-wrap">
          <i className="far fa-heart filter-sort-icon"></i>
          <h2 className="filter-slider-title">Bodytype</h2>
        </div>
        <div className="filter-slider-wrapper">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Slim"}
                type="checkbox"
                value="slim"
                checked={bodytype.slim}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Fit"}
                type="checkbox"
                value="fit"
                checked={bodytype.fit}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Muscular"}
                type="checkbox"
                value="muscular"
                checked={bodytype.muscular}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Average"}
                type="checkbox"
                value="average"
                checked={bodytype.average}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"A Few Extra Pounds"}
                type="checkbox"
                value="afewextrapounds"
                checked={bodytype.afewextrapounds}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Curvy"}
                type="checkbox"
                value="curvy"
                checked={bodytype.curvy}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Full Figured"}
                type="checkbox"
                value="fullfigured"
                checked={bodytype.fullfigured}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="filter-save-button-wrapper">
          <Button className="filter-save-button" onClick={saveHandler}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Bodytype;
