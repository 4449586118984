import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { resizeImages } from "../../utils/resizeImages";
import "./photoview.scss";

export const PhotoView = (props) => {
  const { images, closeHandler, index } = props;
  const [photoIndex, setPhotoIndex] = useState(index);

  return (
    <Lightbox
      mainSrc={images[photoIndex]}
      nextSrc={images[(photoIndex + 1) % images.length]}
      prevSrc={images[(photoIndex + images.length - 1) % images.length]}
      onCloseRequest={() => closeHandler()}
      onMovePrevRequest={() =>
        setPhotoIndex((photoIndex + images.length - 1) % images.length)
      }
      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
      wrapperClassName="lightbox-wrapper"
      reactModalStyle="lightbox-modal"
      imageCrossOrigin="lightbox-image"
    />
  );
};

export default PhotoView;
