import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";

function Education(props) {
  var filters = props.filters;
  const [education, setEducation] = useState(filters.education);

  const changeHandler = (e) => {
    var educationCopy = education;

    if (e.target.value === "highschool") {
      if (educationCopy.highschool) {
        educationCopy.highschool = false;
      } else {
        educationCopy.highschool = true;
      }
    } else if (e.target.value === "somecollege") {
      if (educationCopy.somecollege) {
        educationCopy.somecollege = false;
      } else {
        educationCopy.somecollege = true;
      }
    } else if (e.target.value === "associatesdegree") {
      if (educationCopy.associatesdegree) {
        educationCopy.associatesdegree = false;
      } else {
        educationCopy.associatesdegree = true;
      }
    } else if (e.target.value === "bachelorsdegree") {
      if (educationCopy.bachelorsdegree) {
        educationCopy.bachelorsdegree = false;
      } else {
        educationCopy.bachelorsdegree = true;
      }
    } else if (e.target.value === "graduatedegree") {
      if (educationCopy.graduatedegree) {
        educationCopy.graduatedegree = false;
      } else {
        educationCopy.graduatedegree = true;
      }
    } else if (e.target.value === "phd") {
      if (educationCopy.phd) {
        educationCopy.phd = false;
      } else {
        educationCopy.phd = true;
      }
    }

    setEducation((prevState) => {
      return { ...prevState, ...educationCopy };
    });
  };

  const saveHandler = (e) => {
    props.updateHandler(education);
  };

  return (
    <div className="filter-slider-component">
      <div className="filter-slider-overlay" onClick={props.onClose} />
      <div className="filter-slider-content">
        <div className="filter-slider-title-wrap">
          <i className="far fa-heart filter-sort-icon"></i>
          <h2 className="filter-slider-title">Education</h2>
        </div>
        <div className="filter-slider-wrapper">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"High School"}
                type="checkbox"
                value="highschool"
                checked={education.highschool}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Some College"}
                type="checkbox"
                value="somecollege"
                checked={education.somecollege}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Associates Degree"}
                type="checkbox"
                value="associatesdegree"
                checked={education.associatesdegree}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Bachelors Degree"}
                type="checkbox"
                value="bachelorsdegree"
                checked={education.bachelorsdegree}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Graduate Degree"}
                type="checkbox"
                value="graduatedegree"
                checked={education.graduatedegree}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"PhD"}
                type="checkbox"
                value="phd"
                checked={education.phd}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="filter-save-button-wrapper">
          <Button className="filter-save-button" onClick={saveHandler}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Education;
