import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export class AdminNavbar extends Component {
  render() {
    return (
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand>
          <Link to="/app/admin"> Sugarbae Admin</Link>
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link>
            <Link to="/app/admin/users">Users</Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/app/admin/images">Images</Link>
          </Nav.Link>
          {/* <Nav.Link>
            <Link to="/app/admin/chat">Chat</Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/app/admin/bots">Bots</Link>
          </Nav.Link>
          <Nav.Link>
            <Link to="/app/admin/payments">Payments</Link>
          </Nav.Link> */}
        </Nav>
        {/* <Form inline>
          <FormControl type="text" placeholder="Search" className="mr-sm-2" />
          <Button variant="outline-info">Search</Button>
        </Form> */}
      </Navbar>
    );
  }
}

export default AdminNavbar;
