import React, { useState, useEffect } from "react";
import { api } from "../../api";
import "./graphs.scss";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
var moment = require("moment");

function Engagement(props) {
  //const [maleSignupData, setMaleSignupData] = useState([]);
  //const [femaleSignupData, setFemaleSignupData] = useState([]);
  const [dailyEngagement, setEngagementData] = useState([]); 
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    return api
      .fetchEngagementData()
      .then((res) => {
        if (res.status === 200) {
          setEngagementData(res.data.dailyEngagement);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderCustomAxisTick = ({ x, y, payload }) => {
    var date = payload.value;

    var formattedDate = moment(date).format("MMM DD").toString();

    return (
      <text x={x} y={y + 25} fill="#666" textAnchor="middle">
        {formattedDate}
      </text>
    );
  };
  return (
    <div className="graph-container">
      <div className="graph-header">
        <h1 className="graph-title">Daily Engagement- No filter </h1>
      </div>
      <div className="graph-content-container">
        <div className="graph-content">
          <h2 className="graph-content-title">Likes, Dislikes and Matches</h2>
          <LineChart
            width={700}
            height={400}
            data={dailyEngagement}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Line type="monotone" dataKey="likes" stroke="#D95454" />
            <Line type="monotone" dataKey="dislikes" stroke="#82ca9d" />
            <Line type="monotone" dataKey="matches" stroke="#4c26c9" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="_id" tick={renderCustomAxisTick} />
            <YAxis/>
            <Tooltip />
          </LineChart>
        </div>
      </div>
    </div>
  );
}

export default Engagement;
