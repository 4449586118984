import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import "./filterscomponentsstyles.scss";
import { UserContext } from "../../../context/UserContext";
import Switch from "@material-ui/core/Switch";
import PremiumModal from "../../modals/premiumModal/PremiumModal";

function PlusSettings(props) {
  const context = useContext(UserContext);
  const filters = context.user.filters;

  const [premiumModalOpen, setPremiumModalOpen] = useState(false);

  const backHandler = (e) => {
    props.backHandler();
  };

  const privateToggle = (e) => {
    if (context.user.premium) {
      if (context.user.hidden) {
        context.updateHidden(false);
      } else {
        context.updateHidden(true);
      }
    } else {
      setPremiumModalOpen(true);
    }
  };

  const verifiedToggle = (e) => {
    if (context.user.premium) {
      if (filters.verified) {
        filters.verified = false;
        context.updateFilters(filters);
      } else {
        filters.verified = true;
        context.updateFilters(filters);
      }
    } else {
      setPremiumModalOpen(true);
    }
  };

  const resetAllHandler = (e) => {
    if (context.user.premium) {
      filters.verified = false;
      context.updateFilters(filters);
      context.updateHidden(false);
    } else {
      setPremiumModalOpen(true);
    }
  };

  const togglePremiumModal = (e) => {
    if (premiumModalOpen) {
      setPremiumModalOpen(false);
    } else {
      setPremiumModalOpen(true);
    }
  };

  return (
    <div className="filters-v2-component">
      {premiumModalOpen && (
        <PremiumModal
          onClose={togglePremiumModal}
          show={premiumModalOpen}
          option={"advancedFilters"}
        />
      )}
      <div className="filters-v2-header">
        <div className="advanced-filters-v2-header-section">
          <Button
            onClick={backHandler}
            className="advanced-filters-v2-back-button"
          >
            <i class="far fa-chevron-left advanced-filters-back-icon"></i>
          </Button>
          <h2 className="advanced-filters-v2-header-title">Plus Settings</h2>
        </div>
        <div className="filter-v2-header-section">
          <Button
            className="advanced-filters-v2-header-reset-all-button"
            onClick={resetAllHandler}
          >
            Reset All
          </Button>
        </div>
      </div>
      <div className="filters-v2-content">
        <div className="filters-v2-section">
          <div className="filters-v2-section-row">
            <div className="filters-v2-section-row-icon">
              <i className="far fa-eye-slash"></i>
            </div>
            <div className="filters-v2-section-row-title">
              Private Browsing Mode
            </div>
            <div className="filters-v2-section-row-content-plus">
              <Switch
                checked={context.user.hidden}
                onChange={privateToggle}
                name="private"
                className="plus-settings-switch"
              />
            </div>
          </div>
          <div className="filters-v2-section-row">
            <div className="filters-v2-section-row-icon">
              <i className="fas fa-badge-check"></i>
            </div>
            <div className="filters-v2-section-row-title">
              Verified Profiles Only
            </div>
            <div className="filters-v2-section-row-content-plus">
              <Switch
                checked={filters.verified}
                onChange={verifiedToggle}
                name="verified"
                className="plus-settings-switch"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlusSettings;
