import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import Axios from "axios";
import { UserContext } from "../../../../context/UserContext";
import ClipLoader from "react-spinners/ClipLoader";
import { Image, Button, Form, Alert, Badge } from "react-bootstrap";
import "../desktopviewstyles.scss";
import DesktopMessageCard from "../deskopMessageCard/DesktopMessageCard";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import socket from "../../../../helpers/Socket";
import { api } from "../../../../api";

function DesktopMatchesView(props) {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [messages, setMessages] = useState([]);
  const [unreadFilter, setUnreadFilter] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [endOfMessages, setEndOfMessages] = useState(false);

  useEffect(() => {
    const fetchData = async (e) => {
      const data = {
        userid: context.user._id,
        skip: skip,
      };

      return api
        .fetchChatMatches(data)
        .then((res) => {
          if (res.status === 200) {
            var messagesReceived = res.data.messages;
            var messagesState = messages;

            if (messagesReceived) {
              if (messagesReceived.length > 0) {
                messagesReceived.forEach((message, index) => {
                  messagesState.push(message);
                });
              } else {
                setEndOfMessages(true);
              }
            }

            setMessages(messagesState);
            setLoading(false);
            setFetching(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
  }, [skip]);

  const unreadToggle = (e) => {
    if (unreadFilter) {
      setUnreadFilter(false);
    } else {
      setUnreadFilter(true);
    }
  };

  const handleScroll = (e) => {
    if (!fetching && !loading && !endOfMessages) {
      setFetching(true);
      setSkip(skip + messages.length);
    }
  };

  useBottomScrollListener(handleScroll);

  return (
    <div className="desktop-messages-view-component">
      <div className="messages-view-header">
        <div className="messages-view-title-wrap">
          <h1 className="messages-view-title">Matches Messages</h1>
        </div>
        <div className="messages-view-actions-wrap">
          <div className="messages-view-action">
            <Form className="inbox-unread-checkbox-form">
              <Form.Check
                type="checkbox"
                id="inbox-unread-checkbox"
                label="Show Unread Only"
                checked={unreadFilter}
                onChange={unreadToggle}
              />
            </Form>
          </div>
        </div>
      </div>
      {!context.user.premium && (
        <div className="messages-view-cta">
          <div className="messages-view-cta-innerwrap">
            <i className="fal fa-gem messages-view-cta-icon"></i>
            <p className="messages-view-cta-text">
              Premium Members get 20x more responses, on average.{" "}
              <Link to="/app/premium" className="messages-view-cta-link">
                Learn More
              </Link>
            </p>
          </div>
          <div className="messages-view-cta-innerwrap">
            <Button variant="link" className="messages-view-cta-button">
              <i className="fas fa-chevron-right messages-view-cta-button-icon"></i>
            </Button>
          </div>
        </div>
      )}

      {loading ? (
        <div className="messages-view-messages-container">
          <div className="messages-container-loading-wrapper">
            <ClipLoader size={35} />
          </div>
        </div>
      ) : (
        <div className="messages-view-messages-container">
          {messages.length > 0 ? (
            <div>
              {unreadFilter ? (
                <div className="messages-container-innerwrap">
                  {messages.map((message, index) => {
                    if (
                      message.receiver.toString() ===
                        context.user._id.toString() &&
                      !message.isRead
                    ) {
                      return (
                        <DesktopMessageCard message={message} index={index} />
                      );
                    }
                  })}
                </div>
              ) : (
                <div className="messages-container-innerwrap">
                  {messages.map((message, index) => {
                    return (
                      <DesktopMessageCard message={message} index={index} />
                    );
                  })}
                </div>
              )}
            </div>
          ) : (
            <div className="messages-container-innerwrap">
              <div className="messages-container-no-messages-wrapper">
                <span>No Messages</span>
              </div>
            </div>
          )}
          {fetching && (
            <div className="messages-bottom-indicator">
              <ClipLoader size={35} />
            </div>
          )}
          {endOfMessages && (
            <div className="messages-bottom-indicator">
              <div className="end-of-messages-innerwrap">
                <h4 className="end-of-messages-title">No more messages!</h4>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default withRouter(DesktopMatchesView);
