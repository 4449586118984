import React, { Component } from "react";
import "./swipepops.scss";
import { Button, Card, Modal } from "react-bootstrap";
import Axios from "axios";
import { UserContext } from "../../../../context/UserContext";
import { withRouter } from "react-router-dom";
import Countdown, { zeroPad } from "react-countdown";
import { api } from "../../../../api";

export class BoostActivePop extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      show: true,
      premium: false,
      loading: true,
      image: "",
      countdownStart: false,
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData = async (e) => {
    var cardid = this.props.cardid;

    const data = {
      userid: this.context.user._id,
    };

    return api
      .fetchBoost(data)
      .then((res) => {
        if (res.status === 200) {
          var boost = res.data.boost;
          this.setState({ date: boost.date, loading: false });
        }
      })
      .catch((err) => {});
  };

  closeHandler = (e) => {
    this.setState({ show: false }, () => {
      this.props.closeHandler();
    });
  };

  countDownStartHandler = (e) => {
    this.setState({ countdownStart: true });
  };

  render() {
    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        return null;
      } else {
        return (
          <div className="boost-active-countdown-innerwrap">
            <i className="fas fa-bolt boost-active-countdown-boost-icon-left"></i>
            <span>
              {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
            <i className="fas fa-bolt boost-active-countdown-boost-icon-right"></i>
          </div>
        );
      }
    };

    if (this.state.loading) {
      return null;
    } else {
      return (
        <Modal size="lg" show={this.state.show} onHide={this.closeHandler}>
          <Modal.Body className="swipe-pop-modal-body-boost">
            <div className="swipe-pop-icon">
              <i className="fas fa-bolt swipe-pop-main-icon-bolt"></i>{" "}
            </div>
            <h2 className="swipe-pop-body-title">Boost is Active!</h2>
            <div className="swipe-pop-body-countdown-wrapper"></div>
            <p className="swipe-pop-body-subtitle">
              Your profile is boosted for the next <strong>60 Minutes</strong>.
              Keep swiping to maximize your matches!
            </p>
            <Card className="swipe-pop-features-card">
              <Card.Body className="swipe-pop-features-card-body">
                <div className="boost-active-countdown-wrapper">
                  <Countdown
                    date={new Date(this.state.date)}
                    className="boost-countdown"
                    renderer={renderer}
                    onStart={this.countDownStartHandler}
                  ></Countdown>
                </div>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer className="swipe-pop-footer">
            <Button
              className="swipe-pop-superlike-btn "
              size="lg"
              onClick={this.closeHandler}
            >
              Start Swiping
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
}

export default withRouter(BoostActivePop);
