import React, { useState, useContext, useRef, useEffect } from "react";
import "./editscreensstyles.scss";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import ReactCodeInput from "react-verification-code-input";
import EmailValidator from "email-validator";
import PasswordValidator from "password-validator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { api } from "../../../../api";

const passwordSchema = new PasswordValidator();
passwordSchema.is().min(6).is().max(100).has().not().spaces();

function PhoneNumber(props) {
  const context = useContext(UserContext);
  const [number, setNumber] = useState(context.user.verifications.phone.number);
  const [disabled, setDisabled] = useState(true);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [numberExistsError, setNumberExistsError] = useState(false);
  const [numberInvalidError, setNumberInvalidError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [validationCode, setValidationCode] = useState("");
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
  const [sendAgainSuccess, setSendAgainSuccess] = useState(false);

  useEffect(() => {
    var noPhoneNumber = true;
    if (
      context.user.verifications.phone.number !== null &&
      context.user.verifications.phone.number !== undefined
    ) {
      if (context.user.verifications.phone.number.length > 5) {
        noPhoneNumber = false;
      }
    }

    if (noPhoneNumber) {
      setNumber("");
      setDisabled(false);
    }
  }, []);

  const toggleTab = (e) => {
    props.toggleTab();
  };

  const disableHandler = (e) => {
    if (disabled) {
      setNumber("");
      setSubmitDisabled(true);
      setDisabled(false);
    } else {
      setNumber(context.user.verifications.phone.number);
      setDisabled(true);
      resetErrors();
    }
  };

  const validationCodeChange = async (e) => {
    setVerifyError(false);
    setValidationCode(e);

    if (e.length === 6) {
      setValidateLoading(true);

      const data = {
        validationcode: e,
      };

      await api
        .validateSmsToken(data)
        .then((res) => {
          if (res.status === 200) {
            context.updateUserInfo("verifications", res.data.verifications);
            setVerifySuccess(true);
            verifyHandler();
          }
        })
        .catch((err) => {
          console.log(err);
          setValidateLoading(false);
          setVerifyError(true);
        });
    }
  };

  const resetErrors = (e) => {
    setSendAgainSuccess(false);
    setVerifyButtonDisabled(false);
    setNumberInvalid(false);
    setNumberExistsError(false);
    setNumberInvalidError(false);
  };

  const changeHandler = (e) => {
    setNumber(e);
    setNumberInvalid(false);

    if (e.length < 17) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  };

  const sendAgainHandler = (e) => {
    sendSmsCode();
    setSendAgainSuccess(true);
  };

  const verifyHandler = (e) => {
    if (verifyOpen) {
      resetErrors();
      setDisabled(true);
      setVerifyOpen(false);
    } else {
      resetErrors();
      setVerifyButtonDisabled(true);
      sendSmsCode();
      setVerifyOpen(true);
    }
  };

  const sendSmsCode = async (e) => {
    const data = {
      user: context.user._id,
      phonenumber: number,
    };

    return api
      .generateSmsToken(data)
      .then((res) => {
        if (res.status === 200) {
          context.updateUserInfo("verifications", res.data.verifications);
        }
      })
      .catch((err) => {});
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    setLoading(true);
    verifyHandler();
  };

  var verified = context.user.verifications.phone.status;
  var noNum = true;
  if (
    context.user.verifications.phone.number !== null &&
    context.user.verifications.phone.number !== undefined
  ) {
    if (context.user.verifications.phone.number.length > 5) {
      noNum = false;
    }
  }

  return (
    <div className="edit-screen">
      <div className="edit-screen-header">
        <div className="edit-screen-header-section-left">
          <div className="edit-screen-header-return-button" onClick={toggleTab}>
            <i className="fas fa-chevron-left"></i>
          </div>
        </div>
        <div className="edit-screen-header-section-middle">
          <h1 className="edit-screen-header-title">Number</h1>
        </div>
      </div>
      <div className="edit-screen-content">
        <div className="edit-screen-input-wrapper">
          {verifyOpen ? (
            <Form className="edit-screen-email-form">
              <Form.Label className="edit-screen-form-label">
                Enter the 6 digit verification code we texted you:
              </Form.Label>
              <ReactCodeInput
                onChange={validationCodeChange}
                loading={validateLoading}
                disabled={validateLoading}
                fieldWidth={"16%"}
                className="verification-code-input-v2"
              />
              <div className="edit-screen-button-wrapper">
                {sendAgainSuccess ? (
                  <span className="new-code-sent-text">New code sent</span>
                ) : (
                  <Button
                    className="edit-screen-send-again-button"
                    onClick={sendAgainHandler}
                  >
                    Send again
                  </Button>
                )}
              </div>
            </Form>
          ) : (
            <Form className="edit-screen-email-form">
              <Form.Group className="phone-verification-v2-wrapper">
                {!disabled && <Form.Label>New Number</Form.Label>}
                {number !== null && number !== undefined ? (
                  <PhoneInput
                    country={"us"}
                    value={number}
                    onChange={changeHandler}
                    preferredCountries={["us", "ca", "gb"]}
                    disabled={disabled}
                  />
                ) : null}
              </Form.Group>
            </Form>
          )}
        </div>
        {!verifyOpen && (
          <div>
            {disabled ? (
              <div className="edit-screen-status-container">
                {verified ? (
                  <div className="edit-screen-status-wrapper-verified">
                    <i className="far fa-check-circle number-verified-icon"></i>
                    <span>Verified Number</span>
                  </div>
                ) : (
                  <div className="edit-screen-status-wrapper">
                    <i className="far fa-exclamation-circle number-alert-icon"></i>
                    <span>You need to verifiy your number</span>
                  </div>
                )}
              </div>
            ) : (
              <div className="edit-screen-status-wrapper">
                <i className="far fa-exclamation-circle number-alert-icon"></i>
                <span>Enter new cell phone number</span>
              </div>
            )}
          </div>
        )}
        {numberInvalidError && (
          <div className="edit-screen-status-wrapper">
            <i className="far fa-exclamation-circle number-alert-icon"></i>
            <span>The number you entered in invalid</span>
          </div>
        )}

        {numberExistsError && (
          <div className="edit-screen-status-wrapper">
            <i className="far fa-exclamation-circle number-alert-icon"></i>
            <span>The number you entered already exists in our system</span>
          </div>
        )}
        {verifyError && (
          <div className="edit-screen-status-wrapper">
            <i className="far fa-exclamation-circle number-alert-icon"></i>
            <span>The verification code you entered is incorrect</span>
          </div>
        )}
      </div>
      <div className="edit-screen-cta-button-wrapper">
        {verified ? (
          <div>
            {verifyOpen ? (
              <div className="edit-screen-cta-button-innerwrap">
                <div
                  className="edit-screen-update-button"
                  size="lg"
                  onClick={verifyHandler}
                >
                  Back
                </div>

                <div
                  className="edit-screen-cta-button-upgrade"
                  size="lg"
                  disabled={verifyButtonDisabled}
                >
                  Verify
                </div>
              </div>
            ) : (
              <div className="edit-screen-cta-button-innerwrap">
                {disabled ? (
                  <div
                    className="edit-screen-cta-button-upgrade"
                    size="lg"
                    onClick={disableHandler}
                  >
                    Update number
                  </div>
                ) : (
                  <div className="edit-screen-cta-button-inner-innerwrap">
                    {!noNum && (
                      <div
                        className="edit-screen-update-button"
                        size="lg"
                        onClick={disableHandler}
                      >
                        Cancel
                      </div>
                    )}
                    <div
                      className="edit-screen-cta-button-upgrade"
                      size="lg"
                      onClick={updateHandler}
                      disabled={submitDisabled}
                    >
                      Confirm number
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            {verifyOpen ? (
              <div className="edit-screen-cta-button-innerwrap">
                <Button
                  className="edit-screen-update-button"
                  size="lg"
                  onClick={verifyHandler}
                >
                  Back
                </Button>

                <Button
                  className="edit-screen-cta-button-upgrade"
                  size="lg"
                  disabled={verifyButtonDisabled}
                >
                  Verify
                </Button>
              </div>
            ) : (
              <div className="edit-screen-cta-button-innerwrap">
                {disabled ? (
                  <div className="edit-screen-cta-button-inner-innerwrap">
                    <Button
                      className="edit-screen-update-button"
                      size="lg"
                      onClick={disableHandler}
                    >
                      Update my number
                    </Button>

                    <Button
                      className="edit-screen-cta-button-upgrade"
                      size="lg"
                      onClick={verifyHandler}
                      disabled={verifyButtonDisabled}
                    >
                      Verify
                    </Button>
                  </div>
                ) : (
                  <div className="edit-screen-cta-button-inner-innerwrap">
                    {!noNum && (
                      <Button
                        className="edit-screen-update-button"
                        size="lg"
                        onClick={disableHandler}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      className="edit-screen-cta-button-upgrade"
                      size="lg"
                      onClick={updateHandler}
                    >
                      Confirm number
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default PhoneNumber;
