import React, { useEffect, useState, useContext } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Image as BootstrapImage } from 'react-bootstrap';
import { withRouter, useHistory } from 'react-router-dom';
import { UserContext } from '../../../../../context/UserContext';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../../utils/getCloudinaryInfo';
import SpotlightCard from '../../../../search/searchComponentsV2/spotlight/SpotlightCard';
import BoostModal from '../../../../modals/boostModal/BoostModal';
import BoostStatsModal from '../../../../modals/boostStatsModal/BoostStatsModal';
import PremiumModal from '../../../../modals/premiumModal/PremiumModal';
import Media from '../../../../../components/media/Media';

import boostPlusIcon from '../../../../../assets/Icons/boost_plus.png';
import './desktopspotlight.scss';

const moment = require('moment');

function DesktopSpotlight(props) {
  const context = useContext(UserContext);
  const history = useHistory();
  const [boostOpen, setBoostOpen] = useState(false);
  const [boostStatsOpen, setBoostStatsOpen] = useState(false);
  const [spotlightCards, setSpotlightCards] = useState(context.spotlight);
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);

  const placeholders = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  const images = context.user.imgurl;
  let mainImage = null;
  if (images.length > 0) {
    mainImage = images[0];
  }

  const toggleProfile = (e) => {
    const url = `/app/member/${e}`;
    history.push(url);
  };
  const premiumCloseHandler = (e) => {
    setPremiumModalOpen(false);
  };

  const toggleBoost = (e) => {
    console.log('inside Toggle Boost');
    if (context.user.premium) {
      console.log('User Premium');
      if (context.boostData.boosted) {
        if (boostStatsOpen) {
          setBoostStatsOpen(false);
        } else {
          setBoostStatsOpen(true);
        }
      } else if (boostOpen) {
        setBoostOpen(false);
      } else {
        setBoostOpen(true);
        console.log('Set Boost Open True');
      }
    } else {
      setPremiumModalOpen(true);
    }
  };

  if (context.boostData.boosted) {
    let expirationDate = context.boostData.boostExpirationDate;
    const now = moment();
    var dateType = 'hours';
    if (expirationDate) {
      expirationDate = moment(expirationDate);
      var difference = expirationDate.diff(now, 'hours');
      if (difference < 1) {
        difference = expirationDate.diff(now, 'minutes');
        dateType = 'minutes';
        if (difference < 1) {
          difference = expirationDate.diff(now, 'seconds');
          dateType = 'seconds';
        }
      }
    }
  }

  return (
    <div className="desktop-spotlight-component">
      {boostOpen && <BoostModal onClose={toggleBoost} show={boostOpen} />}
      {boostStatsOpen && (
        <BoostStatsModal onClose={toggleBoost} show={boostStatsOpen} />
      )}
      {premiumModalOpen && (
        <PremiumModal
          onClose={() => setPremiumModalOpen(false)}
          show={premiumModalOpen}
          option="boost"
        />
      )}
      <div className="desktop-spotlight-content">
        <div className="desktop-spotlight-cta-image" onClick={toggleBoost}>
          <div className="desktop-spotlight-cta-overlay-boosted">
            <i className="fas fa-bolt spotlight-cta-icon" />
            {dateType === 'hours' && (
              <p className="spotlight-boosted-time">{difference} hrs</p>
            )}
            {dateType === 'minutes' && (
              <p className="spotlight-boosted-time">{difference} min</p>
            )}
            {dateType === 'seconds' && (
              <p className="spotlight-boosted-time">{difference} sec</p>
            )}
          </div>

          <Media image={mainImage} />
          {/* <Image
            publicId={
              mainImage
                ? getImgName(mainImage)
                : "placeholder-user-image-thumbnail_vankru.png"
            }
            version={mainImage ? getImgVersion(mainImage) : "1590045309"}
            resource_type={
              mainImage ? (checkGIF(mainImage) ? "video" : "image") : null
            }
            effect="loop"
            cloud_name="sugarbae"
            className="spotlight-cta-user-image-desktop"
            fluid
          >
            <Transformation
              height="200"
              video_sampling="20"
              delay="250"
              duration="10"
              effect="loop"
              quality="auto"
              flags="lossy"
            />
          </Image> */}
        </div>

        {context.searchesLoading ? (
          <>
            {placeholders.map((placeholder, index) => (
              <div className="desktop-spotlight-placeholder" />
            ))}
          </>
        ) : (
          <>
            {context.spotlight.map((user, index) => {
              const images = user.imgurl;
              let mainImage = null;
              if (images.length > 0) {
                mainImage = images[0];
              }

              return (
                <div
                  className="desktop-spotlight-cta-image"
                  onClick={() => toggleProfile(user._id)}
                >
                  <Media image={mainImage} />
                  {/* <Image
                    publicId={getImgName(mainImage)}
                    version={getImgVersion(mainImage)}
                    resource_type={checkGIF(mainImage) ? 'video' : 'image'}
                    effect="loop"
                    cloud_name="sugarbae"
                    className="spotlight-cta-user-image-desktop"
                    fluid
                  >
                    <Transformation
                      height="200"
                      video_sampling="20"
                      delay="250"
                      duration="10"
                      effect="loop"
                      quality="auto"
                      flags="lossy"
                    />
                  </Image> */}
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(DesktopSpotlight);
