import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import {
  Table,
  Card,
  Button,
  Modal,
  Form,
  Alert,
  Badge,
} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import { api } from "../../../../api";
import { UserContext } from "../../../../context/UserContext";
import ImageCard from "./imageTabComponents/ImageCard";
import PersonalImages from "./imageTabComponents/PersonalImages";

import "../../admincomponents.scss";

const ImagesTab = () => {
  const initialState = {
    username: "",
    email: "",
    allImages: true,
    male: false,
    female: false,
    premium: false,
    nonPermium: false,
    banned: false,
    nonBanned: true,
  };
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState(initialState);
  const [showFilters, setShowFilters] = useState(false);
  const [showSearchOptions, setShowSearchOptions] = useState(false);
  const [searchOption, setSearchOption] = useState("username");
  const [searchPlaceholder, setSearchPlaceholder] = useState(
    "Search username..."
  );
  const [selectedUser, setSelectedUser] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState(false);
  const [reShow, setReShow] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = () => {
    const data = {
      filters,
      skip,
    };

    return api
      .adminFetchImages(data)
      .then((res) => {
        const newUsers = res.data;
        const temp = users.concat(newUsers);
        setUsers(temp);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searchOptionShow = () => {
    setShowSearchOptions(!showSearchOptions);
  };

  const selectUser = (index) => {
    setSelectedUser(index + 1);
  };

  const toggleSearchOption = (option) => {
    setSearchOption(option);
    if (option === "username") {
      setSearchPlaceholder("Search username...");
    } else if (option === "email") {
      setSearchPlaceholder("Search email...");
    }
    setFilters({
      ...filters,
      username: "",
      email: "",
    });
    setShowSearchOptions(false);
  };

  const keyHandler = (e) => {
    if (e.key === "Enter") {
      setSkip(0);
      setLoading(true);
      setUsers([]);
      setSearch(true);
    }
  };

  const onChangeHandler = (e) => {
    e.preventDefault();
    if (searchOption === "username") {
      setFilters({
        ...filters,
        username: e.target.value,
      });
    } else if (searchOption === "email") {
      setFilters({
        ...filters,
        email: e.target.value,
      });
    }
  };

  const checkFilters = (show) => {
    setShowFilters(show);
  };

  const closePersonalImages = () => {
    setSelectedUser(null);
  };

  const handleScroll = () => {
    setSkip(skip + 40);
  };

  const refreshData = () => {
    setUsers([]);
    setFilters(initialState);
    setLoading(true);
    setRefresh(true);
  };

  const resetFilter = () => {
    setFilters(initialState);
  };

  const applyFilters = () => {
    setUsers([]);
    setLoading(true);
    setRefresh(true);
  };

  const femaleOption = (e) => {
    setFilters({
      ...filters,
      allImages: false,
      female: e.target.checked,
      male: false,
    });
  };

  const maleOption = (e) => {
    setFilters({
      ...filters,
      allImages: false,
      male: e.target.checked,
      female: false,
    });
  };

  const premiumOption = (e) => {
    setFilters({
      ...filters,
      allImages: false,
      premium: e.target.checked,
      nonPermium: false,
    });
  };

  const nonPermiumOption = (e) => {
    setFilters({
      ...filters,
      allImages: false,
      premium: false,
      nonPermium: e.target.checked,
    });
  };

  const bannedOption = (e) => {
    setFilters({
      ...filters,
      allImages: false,
      banned: e.target.checked,
      nonBanned: false,
    });
  };

  const nonBannedOption = (e) => {
    setFilters({
      ...filters,
      allImages: false,
      banned: false,
      nonBanned: e.target.checked,
    });
  };

  const removeHandler = () => {
    users.splice(selectedUser - 1, 1);
    setUsers(users);
    setSelectedUser(null);
  };

  const banStatusHandler = () => {
    let temp = users;
    temp[selectedUser - 1].banned = true;
    setUsers(temp);
  };
  
  const declineHandler = currentUsers => {
    setUsers(currentUsers);
    setReShow(true);
  };

  useEffect(() => {
    if (skip !== 0) {
      setLoading(true);
      fetchData();
    }
  }, [skip]);

  useEffect(() => {
    if (refresh === true) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (search === true) {
      fetchData();
      setSearch(false);
    }
  }, [search]);

  useEffect(() => {
    if (reShow) {
      setReShow(false);
    }
  },[reShow]);

  const scrollRef = useBottomScrollListener(handleScroll);

  return (
    <Card bg={"dark"} text={"white"} className="usertable-card">
      <Card.Header>
        <div className="card-header-wrap">
          <div className="card-header-leftside" id="card-header-table">
            <span class="icon icon-moderate-images"></span>
            <h4 className="card-header-title">Moderate Images</h4>
          </div>
          <div className="loading-spinner">
            <ClipLoader size={10} color={"#FFF"} loading={loading} />
          </div>
        </div>
        <div
          className="refresh-image"
          style={showFilters ? { opacity: "20%" } : null}
          onClick={() => refreshData()}
        >
          <span className="icon icon-image-refresh"></span>
        </div>
        <div
          class="input-icons"
          style={
            showFilters
              ? { opacity: "20%", top: "20px", right: "150px" }
              : { top: "20px", right: "150px" }
          }
        >
          <div onClick={() => searchOptionShow()}>
            {showSearchOptions ? (
              <i class="fas fa-angle-up inner-icon"></i>
            ) : (
              <i class="fas fa-angle-down inner-icon"></i>
            )}
          </div>
          <input
            class="input-field"
            type="text"
            minlength="5"
            value={
              searchOption === "username"
                ? filters.username
                : searchOption === "email"
                ? filters.email
                : filters.subscriptionid
            }
            placeholder={searchPlaceholder}
            onChange={onChangeHandler}
            onKeyDown={keyHandler}
          />
        </div>
        {showSearchOptions && (
          <div
            className="image-search-option-dropdown"
            style={{ right: "150px" }}
          >
            <div
              className="search-option"
              onClick={() => toggleSearchOption("username")}
              style={
                searchOption === "username" ? { background: "#4F545C" } : null
              }
            >
              Username
            </div>
            <div
              className="search-option"
              onClick={() => toggleSearchOption("email")}
              style={
                searchOption === "email" ? { background: "#4F545C" } : null
              }
            >
              Email
            </div>
          </div>
        )}
        {!showFilters ? (
          <div
            className="usertable-filter-container"
            style={{ top: "20px", right: "70px" }}
            onClick={() => checkFilters(true)}
          >
            <span className="icon icon-filter"></span>
          </div>
        ) : (
          <div
            className="usertable-close-filter-container"
            style={{ top: "20px", right: "70px" }}
            onClick={() => checkFilters(false)}
          >
            <span className="icon icon-close"></span>
          </div>
        )}
        {showFilters && (
          <div className="chat-filters-panel">
            <div className="filter-header">
              <div className="filter-one-option">
                <span className="icon icon-settings"></span>
                {"  Show Images:"}
                <br></br>
                <div className="filter">
                  <input
                    type="checkbox"
                    className="filter-checkbox"
                    checked={filters.allImages}
                    onChange={resetFilter}
                  />
                  <label className="filter-checkbox-label">All Images</label>
                  <br />
                </div>
              </div>
            </div>
            <hr className="chat-filter-panel-divider" />
            <div className="chat-filter-panel-options">
              <div className="filter-one-option">
                <span className="icon icon-genders"></span>
                {"  Gender:"}<br></br>
                <div className="filter">
                  <input
                    type="checkbox"
                    className="filter-checkbox"
                    checked={filters.female}
                    onChange={femaleOption}
                  />
                  <label className="filter-checkbox-label">Female</label>
                  <br />
                  <input
                    type="checkbox"
                    className="filter-checkbox"
                    checked={filters.male}
                    onChange={maleOption}
                  />
                  <label className="filter-checkbox-label">Male</label>
                  <br />
                </div>
              </div>
              <div className="filter-one-option">
                <span className="icon icon-premium-filter"></span>
                {"  Premium:"}<br></br>
                <div className="filter">
                  <input
                    type="checkbox"
                    className="filter-checkbox"
                    checked={filters.premium}
                    onChange={premiumOption}
                  />
                  <label className="filter-checkbox-label">Premium</label>
                  <br />
                  <input
                    type="checkbox"
                    className="filter-checkbox"
                    checked={filters.nonPermium}
                    onChange={nonPermiumOption}
                  />
                  <label className="filter-checkbox-label">NonPremium</label>
                  <br />
                </div>
              </div>
              <div className="filter-one-option">
                <span className="icon icon-user-banned"></span>
                {"  Banned:"}<br></br>
                <div className="filter">
                  <input
                    type="checkbox"
                    className="filter-checkbox"
                    checked={filters.banned}
                    onChange={bannedOption}
                  />
                  <label className="filter-checkbox-label">Banned</label>
                  <br />
                  <input
                    type="checkbox"
                    className="filter-checkbox"
                    checked={filters.nonBanned}
                    onChange={nonBannedOption}
                  />
                  <label className="filter-checkbox-label">NonBanned</label>
                </div>
              </div>
            </div>
            <hr className="chat-filter-panel-divider" />
            <div className="chat-filter-panel-footer">
              <div
                className="chat-filter-option"
                style={{ marginRight: "50px" }}
                onClick={() => resetFilter()}
              >
                <span className="icon icon-trash"></span>
                <label className="footer-option-title">{"Reset Filters"}</label>
              </div>
              <div
                className="chat-filter-option"
                onClick={() => applyFilters()}
              >
                <span className="icon icon-apply-filter"></span>
                <label className="footer-option-title">{"Apply Filters"}</label>
              </div>
            </div>
          </div>
        )}
        {selectedUser && (
          <div className="personal-image-panel">
            <PersonalImages
              user={users[selectedUser - 1]}
              closeHandler={closePersonalImages}
              removeUser={removeHandler}
              banHandler={banStatusHandler}
            />
          </div>
        )}
      </Card.Header>
      <Card.Body className="image-panel-body">
        <div className="image-body-panel" ref={scrollRef}>
          {users.length !== 0 &&
            users.map((user, index) => {
              return (
                <ImageCard
                  users={users}
                  index={index}
                  selectHandler={selectUser}
                  declineHandler={declineHandler}
                />
              );
            })}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ImagesTab;
