import React, { useContext, useState } from "react";
import { Image, Button } from "react-bootstrap";

import { UserContext } from "../../../../context/UserContext";
import getmore from "../../../../assets/Images/matches/getmore.png";
import { submitBoost } from "../../../../helpers/swipeActions";
import BoostModal from "../../../modals/boostModal/BoostModal";
import BoostStatsModal from "../../../modals/boostStatsModal/BoostStatsModal";
import PremiumModal from "../../../modals/premiumModal/PremiumModal";
import backgroundPattern from "../../../../assets/modelBackgroundPattern.svg";

import "./boost.scss";

export const Boost = (props) => {
  const context = useContext(UserContext);
  const [boostOpen, setBoostOpen] = useState(false);
  const [boostStatsOpen, setBoostStatsOpen] = useState(false);
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);

  const { option } = props;
  const { cards } = context.swipeData;
  const imageOne = cards && cards.length > 0 && cards[0].imgurl[0];
  const imageTwo = cards && cards.length > 0 && cards[1].imgurl[0];
  const imageThree = cards && cards.length > 0 && cards[2].imgurl[0];

  const handleBoost = async (e) => {
    if (context.user.premium) {
      if (context.boostData.boosted) {
        if (boostStatsOpen) {
          setBoostStatsOpen(false);
        } else {
          setBoostStatsOpen(true);
        }
      } else if (boostOpen) {
        setBoostOpen(false);
      } else {
        setBoostOpen(true);
      }
    } else {
      setPremiumModalOpen(true);
    }
  };

  const premiumCloseHandler = (e) => {
    setPremiumModalOpen(false);
  };
  return (
    <>
      <div className="boost-active-popup">
        {boostOpen && <BoostModal onClose={handleBoost} show={boostOpen} />}
        {boostStatsOpen && (
          <BoostStatsModal onClose={handleBoost} show={boostStatsOpen} />
        )}
        {premiumModalOpen && (
          <PremiumModal
            option="boost"
            show={premiumModalOpen}
            onClose={premiumCloseHandler}
          />
        )}
        <div className="boost-active-popup-header">
          <Image src={imageOne} className="boost-active-back-image" />
          <Image src={imageTwo} className="boost-active-front-image" />
          <Image src={imageThree} className="boost-active-back-image" />
          <Image src={getmore} className="boost-active-badge" />
        </div>
        <label className="boost-active-title">
          {option === "likes" ? "Get more likes" : "Get more matches"}
        </label>
        <br />
        {context.boostData.boosted ? (
          <label className="boost-active-description">
            Your profile is boosted. Keep swiping!
          </label>
        ) : (
          <label className="boost-active-description">
            {option === "likes"
              ? "Boost your profile to get more likes."
              : "Boost your profile to get more matches."}
          </label>
        )}

        <br />
        {!context.boostData.boosted && (
          <Button
            variant="danger"
            className="boost-active-submit"
            onClick={() => handleBoost()}
          >
            Boost Your Profile
          </Button>
        )}
      </div>
    </>
  );
};

export default Boost;
