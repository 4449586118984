import React, { useState, useEffect, useContext } from "react";
import { Button, Image } from "react-bootstrap";
import "./filters.scss";
import { UserContext } from "../../context/UserContext";
import AdvancedFilters from "./filtersComponents/AdvancedFilters";
import PlusSettings from "./filtersComponents/PlusSettings";
import SortBy from "./sliders/SortBy";
import Location from "./sliders/Location";
import Distance from "./sliders/Distance";
import AgeRange from "./sliders/AgeRange";
import AdvancedFilterCounter from "./filtersComponents/AdvancedFilterCounter";
import { api } from "../../api";
import plusIcon from "../../assets/Icons/sugarbaePlusIcon.png";

function Filters(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);
  const [plusSettingsOpen, setPlusSettingsOpen] = useState(false);
  const [sortByOpen, setSortByOpen] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [distanceOpen, setDistanceOpen] = useState(false);
  const [ageRangeOpen, setAgeRangeOpen] = useState(false);

  const toggleAdvancedFilters = (e) => {
    if (advancedFiltersOpen) {
      setAdvancedFiltersOpen(false);
    } else {
      setAdvancedFiltersOpen(true);
    }
  };

  const togglePlusSettings = (e) => {
    if (plusSettingsOpen) {
      setPlusSettingsOpen(false);
    } else {
      setPlusSettingsOpen(true);
    }
  };

  const closeHandler = (e) => {
    props.closeHandler();
  };

  const updateSortBy = async (e) => {
    if (e) {
      filters.sortby = e;
    }

    context.updateFilters(filters);
    setSortByOpen(false);
  };

  const updateLocation = async (e) => {
    if (e) {
      filters.address = e;
    }

    const data = {
      address: filters.address,
    };

    await api
      .updateAddress(data)
      .then((res) => {
        if (res.status === 200) {
          filters.location = res.data.location;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    context.updateFilters(filters);
    setLocationOpen(false);
  };

  const updateDistance = async (e) => {
    if (e) {
      filters.distance = e;
    }

    context.updateFilters(filters);
    setDistanceOpen(false);
  };

  const updateAgeRange = async (e) => {
    if (e) {
      filters.age = e;
    }

    context.updateFilters(filters);
    setAgeRangeOpen(false);
  };

  const clearFilterHandler = (name) => (e) => {
    if (name === "distance") {
      updateDistance(500);
    } else if (name === "age") {
      updateAgeRange([18, 80]);
    }
  };

  const toggleEdit = (name) => (e) => {
    if (name === "sortby") {
      if (sortByOpen) {
        setSortByOpen(false);
      } else {
        setSortByOpen(true);
      }
    } else if (name === "location") {
      if (locationOpen) {
        setLocationOpen(false);
      } else {
        setLocationOpen(true);
      }
    } else if (name === "distance") {
      if (distanceOpen) {
        setDistanceOpen(false);
      } else {
        setDistanceOpen(true);
      }
    } else if (name === "age") {
      if (ageRangeOpen) {
        setAgeRangeOpen(false);
      } else {
        setAgeRangeOpen(true);
      }
    }
  };

  let sortbyText = filters.sortby;
  let locationText = filters.address;
  let maximumDistanceText = filters.distance;
  const ageRange = filters.age;
  let ageRangeText = "-";

  if (ageRange) {
    if (ageRange[0] === 18 && ageRange[1] === 80) {
      ageRangeText = "Any";
    } else {
      ageRangeText = `${ageRange[0].toString()} - ${ageRange[1].toString()}`;
    }
  }

  if (!sortbyText) {
    sortbyText = "-";
  }
  if (!locationText) {
    locationText = "-";
  }

  if (maximumDistanceText) {
    if (maximumDistanceText === 500) {
      maximumDistanceText = "Any";
    } else {
      maximumDistanceText = `${maximumDistanceText.toString()} kilometers`;
    }
  } else {
    maximumDistanceText = "-";
  }

  if (advancedFiltersOpen) {
    return <AdvancedFilters backHandler={toggleAdvancedFilters} />;
  }
  if (plusSettingsOpen) {
    return <PlusSettings backHandler={togglePlusSettings} />;
  }
  return (
    <div className="filters-v2-component">
      {sortByOpen && (
        <SortBy
          onClose={toggleEdit("sortby")}
          filters={filters}
          updateHandler={updateSortBy}
        />
      )}
      {locationOpen && (
        <Location
          onClose={toggleEdit("location")}
          filters={filters}
          updateHandler={updateLocation}
        />
      )}
      {distanceOpen && (
        <Distance
          onClose={toggleEdit("distance")}
          filters={filters}
          updateHandler={updateDistance}
        />
      )}
      {ageRangeOpen && (
        <AgeRange
          onClose={toggleEdit("age")}
          filters={filters}
          updateHandler={updateAgeRange}
        />
      )}
      <div className="filters-v2-header">
        <div className="filter-v2-header-section">
          <h2 className="filters-v2-header-title">Filters</h2>
        </div>
        <div className="filter-v2-header-section">
          <Button
            className="filters-v2-header-close-button"
            onClick={closeHandler}
          >
            <i className="far fa-times" />
          </Button>
        </div>
      </div>
      <div className="filters-v2-content">
        <div className="filters-v2-section">
          <div
            className="filters-v2-section-row"
            onClick={toggleEdit("sortby")}
          >
            <div className="filters-v2-section-row-icon">
              <i className="far fa-sort" />
            </div>
            <div className="filters-v2-section-row-title">Sort By</div>
            <div className="filters-v2-section-row-content">{sortbyText}</div>
            <div className="filters-v2-section-row-action">
              <i className="far fa-chevron-right" />
            </div>
          </div>
          <div
            className="filters-v2-section-row"
            onClick={toggleEdit("location")}
          >
            <div className="filters-v2-section-row-icon">
              <i className="far fa-home" />
            </div>
            <div className="filters-v2-section-row-title">Location</div>
            <div className="filters-v2-section-row-content">{locationText}</div>
            <div className="filters-v2-section-row-action">
              <i className="far fa-chevron-right" />
            </div>
          </div>
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("distance")}
            >
              <i className="far fa-expand-alt" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("distance")}
            >
              Maximum Distance
            </div>
            <div
              className="filters-v2-section-row-content"
              onClick={toggleEdit("distance")}
            >
              {maximumDistanceText}
            </div>

            {filters.distance === 500 ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("distance")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("distance")}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </div>
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("age")}
            >
              <i className="far fa-id-card" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("age")}
            >
              Age Range
            </div>
            <div
              className="filters-v2-section-row-content"
              onClick={toggleEdit("age")}
            >
              {ageRangeText}
            </div>
            {filters.age[0] === 18 && filters.age[1] === 80 ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("age")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("age")}
              >
                <i className="far fa-times-circle" />{" "}
              </div>
            )}
          </div>
        </div>
        <div className="filters-v2-section">
          <div
            className="filters-v2-section-row"
            onClick={toggleAdvancedFilters}
          >
            <div className="filters-v2-section-row-icon">
              <i className="far fa-wine-glass-alt" />
            </div>
            <div className="filters-v2-section-row-title">Advanced Filters</div>
            <div className="filters-v2-section-row-content-counter">
              <AdvancedFilterCounter filters={filters} />
            </div>
            <div className="filters-v2-section-row-action">
              <i className="far fa-chevron-right" />
            </div>
          </div>
          <div className="filters-v2-section-row" onClick={togglePlusSettings}>
            <div className="filters-v2-section-row-icon">
              <Image src={plusIcon} className="filters-sb-plus-icon" fluid />
            </div>
            <div className="filters-v2-section-row-title">
              Sugarbae Plus Settings
            </div>
            <div className="filters-v2-section-row-content" />
            <div className="filters-v2-section-row-action">
              <i className="far fa-chevron-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filters;
