import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../../context/UserContext';
import { withRouter } from 'react-router-dom';
import { Image, Button, Dropdown } from 'react-bootstrap';

import { api } from '../../../api';
import logo from '../../../assets/logos/sugarbae-home-icon.png';
import male_user from '../../../assets/Images/adminSidebar/male_user.png';
import female_user from '../../../assets/Images/adminSidebar/female_user.png';

import './sidebar.scss';

function Sidebar(props) {
  const context = useContext(UserContext);
  const [tab, setTab] = useState('home');
  const [userImage, setUserImage] = useState('');
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const fetchData = async (e) => {
      const data = {
        userid: context.user._id,
      };

      return api
        .fetchOwnUser(data)
        .then((res) => {
          if (res.status === 200) {
            var user = res.data.user;
            if (user.imgurl.length !== 0) {
              setUserImage(user.imgurl[0]);
            } else if (user.gender === 'male') {
              setUserImage(male_user);
            } else {
              setUserImage(female_user);
            }
            setUserName(user.username);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!context.user.isAdmin) {
      props.history.push('/app');
    }

    if (tab === 'users') {
      props.tabChange('users');
    } else if (tab === 'chat') {
      props.tabChange('chat');
    } else if (tab === 'images') {
      props.tabChange('images');
    } else if (tab === 'reports') {
      props.tabChange('reports');
    } else if (tab === 'home') {
      props.tabChange('home');
    } else if (tab === 'bots') {
      props.tabChange('bots');
    }

    return () => {};
  }, [tab]);

  const tabHandler = (props) => (e) => {
    if (props === 'users') {
      setTab('users');
    } else if (props === 'chat') {
      setTab('chat');
    } else if (props === 'images') {
      setTab('images');
    } else if (props === 'reports') {
      setTab('reports');
    } else if (props === 'home') {
      setTab('home');
    } else if (props === 'bots') {
      setTab('bots');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('auth');
    props.history.push('/');
  };

  var userButtonClass = 'sidebar-button';
  var chatButtonClass = 'sidebar-button';
  var imageButtonClass = 'sidebar-button';
  var reportButtonClass = 'sidebar-button';
  var homeButtonClass = 'sidebar-button';
  var botButtonClass = 'sidebar-button';

  if (tab === 'users') {
    userButtonClass = 'sidebar-button-active';
  } else if (tab === 'chat') {
    chatButtonClass = 'sidebar-button-active';
  } else if (tab === 'images') {
    imageButtonClass = 'sidebar-button-active';
  } else if (tab === 'reports') {
    reportButtonClass = 'sidebar-button-active';
  } else if (tab === 'home') {
    homeButtonClass = 'sidebar-button-active';
  } else if (tab === 'bots') {
    botButtonClass = 'sidebar-button-active';
  }

  return (
    <div className="sidebar-component">
      <div className="sidebar-logo-container">
        <Image src={logo} className="sidebar-logo" />
      </div>
      <div className="sidebar-navigation">
        <Button className={homeButtonClass} onClick={tabHandler('home')}>
          {tab === 'home' ? (
            <span className="icon icon-home-focus"></span>
          ) : (
            <span className="icon icon-home"></span>
          )}
        </Button>
        <Button className={userButtonClass} onClick={tabHandler('users')}>
          {tab === 'users' ? (
            <span className="icon icon-users-focus"></span>
          ) : (
            <span className="icon icon-users"></span>
          )}
        </Button>
        <Button className={chatButtonClass} onClick={tabHandler('chat')}>
          {tab === 'chat' ? (
            <span className="icon icon-message-focus"></span>
          ) : (
            <span className="icon icon-message"></span>
          )}
        </Button>
        <Button className={imageButtonClass} onClick={tabHandler('images')}>
          {tab === 'images' ? (
            <span className="icon icon-image-focus"></span>
          ) : (
            <span className="icon icon-image"></span>
          )}
        </Button>
        <Button className={reportButtonClass} onClick={tabHandler('reports')}>
          {tab === 'reports' ? (
            <span className="icon icon-flag-focus"></span>
          ) : (
            <span className="icon icon-flag"></span>
          )}
        </Button>
        {/* <Button className={botButtonClass} onClick={tabHandler("bots")}>
          {tab === "bots" ? (
            <i className="fal fa-robot  icon-bot-focus"></i>
          ) : (
            <i className="fal fa-robot  icon-bot"></i>
          )}
        </Button> */}
      </div>
      <div className="sidebar-owner-container">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <Image src={userImage} className="sidebar-admin-user" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="sidebar-menu">
            <Dropdown.Item href="/app" className="sidebar-option">
              <Image src={logo} className="sidebar-option-logo" />
              <div className="sidebar-option-title">Back to App</div>
            </Dropdown.Item>
            <Dropdown.Divider className="option-divider" />
            <Dropdown.Item href="#/action-2" className="sidebar-option">
              <span className="icon icon-settings"></span>
              <div className="sidebar-option-title">Settings</div>
            </Dropdown.Item>
            <Dropdown.Divider className="option-divider" />
            <Dropdown.Item
              className="sidebar-option"
              onClick={() => handleLogout()}
            >
              <Image src={userImage} className="sidebar-option-user" />
              <div className="sidebar-logout-option-title">
                {userName}
                <div className="sidebar-logout">
                  <span className="icon icon-power"></span>
                  <p className="btn-logout">Log out</p>
                </div>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default withRouter(Sidebar);
