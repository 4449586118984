import React from "react";
import { Link, withRouter } from "react-router-dom";
import MessageCounter from "../navComponents/counters/MessageCounter";
import "./mobilenav.scss";
import userIcon from "../../../assets/Icons/navbar/user.png";
import userIconActive from "../../../assets/Icons/navbar/user-active.png";
import searchIcon from "../../../assets/Icons/navbar/search.png";
import searchIconActive from "../../../assets/Icons/navbar/search-active.png";
import swipeIcon from "../../../assets/Icons/navbar/swipe.png";
import swipeIconActive from "../../../assets/Icons/navbar/swipe-active.png";
import messageIcon from "../../../assets/Icons/navbar/message.png";
import messageIconActive from "../../../assets/Icons/navbar/message-active.png";
import { Image } from "react-bootstrap";

function MobileNav(props) {
  var pathname = props.location.pathname;

  return (
    <div className="mobile-nav">
      <Link to="/app/search" className="mobile-nav-section">
        {pathname === "/app/search" ? (
          <Image src={searchIconActive} className="mobile-nav-icon" fluid />
        ) : (
          <Image src={searchIcon} className="mobile-nav-icon" fluid />
        )}
      </Link>
      <Link to="/app" className="mobile-nav-section">
        {pathname === "/app" ? (
          <Image src={swipeIconActive} className="mobile-nav-icon" fluid />
        ) : (
          <Image src={swipeIcon} className="mobile-nav-icon" fluid />
        )}
      </Link>
      <Link to="/app/messages" className="mobile-nav-section">
        {pathname === "/app/messages" ? (
          <div className="mobile-nav-icon-wrapper">
            <Image src={messageIconActive} className="mobile-nav-icon" fluid />
            <MessageCounter />
          </div>
        ) : (
          <div className="mobile-nav-icon-wrapper">
            <Image src={messageIcon} className="mobile-nav-icon" fluid />
            <MessageCounter />
          </div>
        )}

        {/* <i className={chatIconStyle}>
          <MessageCounter />
        </i> */}
      </Link>
      <Link to="/app/profile" className="mobile-nav-section">
        {pathname === "/app/profile" ? (
          <Image src={userIconActive} className="mobile-nav-icon" fluid />
        ) : (
          <Image src={userIcon} className="mobile-nav-icon" fluid />
        )}
      </Link>
    </div>
  );
}

export default withRouter(MobileNav);
