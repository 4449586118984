import React, { useState, useEffect } from "react";
import { api } from "../../api";
import ActiveUser from "../graphs/ActiveUser";
import "./views.scss";

function ActiveUserView(props) {
  return (
    <div className="view-container">
      <div className="view-row">
        <ActiveUser/>
      </div>
    </div>
  );
}

export default ActiveUserView;
