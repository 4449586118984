import React, { useEffect, useState, useContext } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import BottomScrollListener from "react-bottom-scroll-listener";
import ClipLoader from "react-spinners/ClipLoader";
import { UserContext } from "../../../../context/UserContext";
import UserCard from "../userCard/UserCard";
import SortByDropdown from "../sortbyDropdown/SortByDropdown";
import LogoSpinner from "../../../spinners/LogoSpinner";
import Profile from "../../../profile/Profile";
import Chat from "../../../chat/Chat";
import "./searchgrid.scss";

export const SearchGrid = (props) => {
  const context = useContext(UserContext);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [searchProfileOpen, setSearchProfileOpen] = useState(false);
  const [cardid, setCardid] = useState("");
  const [sortby, setSortby] = useState("Top Picks");
  const [chatid, setChatid] = useState("");
  const [chatOpen, setChatOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleScroll = (e) => {
    if (!context.endSearches && !scrollLoading) {
      setScrollLoading(true);
      context.getMoreSearches();
    }
  };

  const handleSortChange = (e) => {
    setSortby(e);
    context.sortbySearches();
  };

  const searchProfileHandler = (e) => {
    setCardid(e);
    setSearchProfileOpen(true);
  };

  const closeSearchProfileHandler = (e) => {
    setSearchProfileOpen(false);
    setCardid("");
  };

  const chatHandler = (e) => {
    setChatOpen(true);
    setSearchProfileOpen(false);
    setChatid(e);
  };

  const closeChatHandler = (e) => {
    setChatOpen(false);
    setChatid("");
  };

  const likeFromProfileHandler = (e) => {
    const cardid = e;
    let likedUsersCopy = context.likedUsers;
    likedUsersCopy.push(cardid);
    context.updateLikedUsers(likedUsersCopy);
  };

  const superlikeFromProfileHandler = (e) => {
    const cardid = e;
    let superlikedUsersCopy = context.superlikedUsers;
    superlikedUsersCopy.push(cardid);
    context.updateSuperlikedUsers(superlikedUsersCopy);
  };

  const undoFromProfileHandler = (cardid, action) => {
    if (action === "like") {
      let likedUsersCopy = context.likedUsers;
      likedUsersCopy = likedUsersCopy.filter((e) => e !== cardid);
      context.updateLikedUsers(likedUsersCopy);
    } else if (action === "superlike") {
      let superlikedUsersCopy = context.superlikedUsers;
      superlikedUsersCopy = superlikedUsersCopy.filter((e) => e !== cardid);
      context.updateSuperlikedUsers(superlikedUsersCopy);
    }
    setRefresh(true);
  };

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setScrollLoading(false);
  }, [context.searches]);

  return (
    <div className="search-grid-container-main">
      {searchProfileOpen && (
        <Profile
          cardid={cardid}
          closeHandler={closeSearchProfileHandler}
          chatHandler={chatHandler}
          likeHandler={likeFromProfileHandler}
          superlikeHandler={superlikeFromProfileHandler}
          undoHandler={undoFromProfileHandler}
        />
      )}

      {chatOpen && <Chat chatid={chatid} onClose={closeChatHandler} />}
      <BottomScrollListener onBottom={handleScroll} offset={4000}>
        {(scrollRef) => (
          <div className="search-grid-component" ref={scrollRef}>
            <SortByDropdown fetchUsers={handleSortChange} />
            {context.searchesLoading ? (
              <div className="loading-wrapper">
                <ClipLoader />
              </div>
            ) : null}
            {context.searchesLoading === false &&
            context.searches.length !== 0 ? (
              context.searches.map((user, index) => {
                return (
                  <UserCard
                    index={index}
                    user={user}
                    likedUsers={context.likedUsers}
                    superlikedUsers={context.superlikedUsers}
                    searchProfileHandler={searchProfileHandler}
                    chatHandler={chatHandler}
                    likeHandler={likeFromProfileHandler}
                    undoHandler={undoFromProfileHandler}
                  />
                );
              })
            ) : (
              <div className="no-users-grid-wrapper">
                {!context.searchesLoading ? (
                  <div>
                    <div className="users-empty-grid-content">
                      <div className="empty-users-img">
                        <i className="fad fa-empty-set emptyuser-icon"></i>{" "}
                      </div>
                      <div className="users-empty-text">
                        {" "}
                        There are no users in this area. <br></br>Please change
                        location or widin your search distance.{" "}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            <div className="search-scroll-loader-wrapper">
              <BeatLoader loading={scrollLoading} size="15px" color="#dbdbdb" />
            </div>
            {context.endSearches ? (
              <div className="max-users-wrap">
                <h4>
                  There are no more users for your current search filters.
                </h4>
              </div>
            ) : null}
          </div>
        )}
      </BottomScrollListener>
    </div>
  );
};

export default SearchGrid;
