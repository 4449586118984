import React, { useState, useContext, useEffect } from "react";
import "./unmatchedReport.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Link, withRouter, useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { api } from "../../api";

export const UnmatchedReport = props => {
  const context = useContext(UserContext);
  const [disabledBtn, setDisableBtn] = useState(true);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [reportContent, setReportContent] = useState("");
  const location = useLocation();
  const pathname = location.pathname;
  const paths = pathname.split("/");
  const unmatchedId = paths[paths.length - 1];
  const userId = context.user._id;

  const submitHandler = (e) => {
    e.preventDefault();
    reportAndUnmatched();
  };

  const reportAndUnmatched = async() => {
    const data = {
      userid: userId,
      cardid: unmatchedId,
      reason: reportContent,
    };

    return await api
      .submitReport(data)
      .then((res) => {
        api.submitUnmatch(data)
        .then((res) => {
          setSubmitSuccess(true);
          context.updateMessageData(unmatchedId, "noMessage", "unmatch");
        })
        .catch((err) => {});
      })
  };

  const textChangeHandler = (e) => {
    if (e.target.value && e.target.value.length > 10) {
      setReportContent(e.target.value);
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  };

  useEffect(() => {
    if (submitSuccess) {
      setTimeout(() => {
        props.history.push("/app/messages");
      }, 2000);
    }
  }, [submitSuccess]);

  return (
    <>
      <Link to="/app/messages" className="redirect-to-messages">
        <p className="close">x</p>
      </Link>
      <div className="report-content-wrap">
        <h1 className="report-title">Tell us more</h1>
        <h3 className="report-description">
          Tell us what happened so we can keep sour community safe
        </h3>
        {submitSuccess && (
          <Alert variant="success">
            Successfully Reported and Unmatched.
          </Alert>
        )}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Control
              as="textarea"
              rows="3"
              placeholder={"Tell us what happened..."}
              onChange={textChangeHandler}
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={disabledBtn}
            className={disabledBtn ? "submit-report-disabled" : "submit-report"}
          >
            Submit report
          </Button>
        </Form>
      </div>
    </>
  );
};

export default withRouter(UnmatchedReport);