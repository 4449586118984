import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import "../admincomponents.scss";

export class BotsCard extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  activateBots = async (e) => {
    this.setState({ loading: true });

    const token = localStorage.getItem("auth");
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + "/api/admin/activatebots";
    const config = {
      headers: { auth: token },
    };

    await Axios.post(url, config)
      .then((res) => {
        console.log("succesfull");
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log("Error Fetching Users");
      });
  };

  activateDemos = async (e) => {
    this.setState({ loading: true });

    const token = localStorage.getItem("auth");
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + "/api/admin/activatedemos";
    const config = {
      headers: { auth: token },
    };

    await Axios.post(url, config)
      .then((res) => {
        console.log("succesfull");
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log("Error Fetching Users");
      });
  };

  activateRealUsers = async (e) => {
    this.setState({ loading: true });

    const token = localStorage.getItem("auth");
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + "/api/admin/activaterealusers";
    const config = {
      headers: { auth: token },
    };

    await Axios.post(url, config)
      .then((res) => {
        console.log("succesfull");
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log("Error Fetching Users");
      });
  };

  activateRealPremiumUsers = async (e) => {
    this.setState({ loading: true });

    const token = localStorage.getItem("auth");
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + "/api/admin/activaterealpremiumusers";
    const config = {
      headers: { auth: token },
    };

    await Axios.post(url, config)
      .then((res) => {
        console.log("succesfull");
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log("Error Fetching Users");
      });
  };

  render() {
    return (
      <Card bg={"dark"} text={"white"} style={{ width: "18rem" }}>
        <Card.Header>
          <div className="card-header-wrap">
            <div className="card-header-leftside">
              <h4 className="card-header-title">Total users</h4>
            </div>
            <div className="card-header-rightside">
              {this.state.loading ? (
                <ClipLoader
                  size={10}
                  color={"#fff"}
                  loading={this.state.loading}
                />
              ) : (
                <div>
                  <Button variant="outline-light" onClick={this.activateBots}>
                    Activate Bots
                  </Button>
                  <Button variant="outline-light" onClick={this.activateDemos}>
                    Activate Demo Bots
                  </Button>
                  <Button
                    variant="outline-light"
                    onClick={this.activateRealUsers}
                  >
                    Activate Real Users
                  </Button>
                  <Button
                    variant="outline-light"
                    onClick={this.activateRealPremiumUsers}
                  >
                    Activate Premium Users
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Card.Text></Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

export default BotsCard;
