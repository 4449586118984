import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../../context/UserContext";
import { Button, Image } from "react-bootstrap";
import "../userprofilev2componentstyles.scss";
import { api } from "../../../../api";
import diamondIcon from "../../../../assets/Icons/diamond-shape.svg";
import { withRouter, useHistory } from "react-router";

function Diamonds(props) {
  const context = useContext(UserContext);
  let history = useHistory();
  const [diamondCount, setDiamondCount] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    const data = {
      userid: context.user._id,
    };

    return api
      .fetchDiamondCount(data)
      .then((res) => {
        if (res.status === 200) {
          setDiamondCount(res.data.diamondCount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const walletHandler = (e) => {
    history.push("/app/wallet");
  };

  return (
    <div className="user-profile-v2-component" onClick={walletHandler}>
      <div className="profile-component-section">
        <div className="user-profile-v2-icon-wrapper">
          <Image
            src={diamondIcon}
            className="user-profile-diamond-icon"
            fluid
          />
        </div>
        {context.user.gender === "male" ? (
          <h4 className="verification-progress-title">Inventory</h4>
        ) : (
          <h4 className="verification-progress-title">Diamonds</h4>
        )}
        <h1 className="verifications-progress-number">{diamondCount}</h1>
      </div>
    </div>
  );
}

export default withRouter(Diamonds);
