import React from "react";
import { withRouter } from "react-router-dom";
import Notifications from "../../components/notifications/Notifications";

function NotificationsPage() {
  let vh = window.innerHeight;
  let vw = window.innerWidth;
  var height = vh + "px";

  if (vw >= 992) {
    return null;
  } else {
    return (
      <div className="app-component" style={{ height: height }}>
        <Notifications />
      </div>
    );
  }
}

export default withRouter(NotificationsPage);
