import React, { Component } from 'react';
import './usercard.scss';
import { Card, Image as StampImage } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import Axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import UserCardActions from '../userCardActions/UserCardActions';
import {
  submitLike,
  submitSuperlike,
  submitUndo,
  submitNote,
} from '../../../../helpers/swipeActions';
import Match from '../../../swipe/swipeComponents/match/Match';
import LikePop from '../../../swipe/swipeComponents/swipePops/LikePop';
import SuperlikePop from '../../../swipe/swipeComponents/swipePops/SuperlikePop';
import superlikedStamp from '../../../../assets/stamps/superliked-stamp.png';
import likedStamp from '../../../../assets/stamps/liked-stamp.png';
import undoStamp from '../../../../assets/stamps/undo-stamp.png';
import Media from '../../../../components/media/Media';

import NoteInvalidModal from '../../../modals/noteInvalidModal/NoteInvalidModal';
import SendNotePop from '../../../modals/sendNotePop/SendNotePop';
import UndoPop from '../../../swipe/swipeComponents/swipePops/UndoPop';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { withRouter } from 'react-router-dom';
import { api } from '../../../../api';

export class UserCard extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      liked: false,
      likeStamp: false,
      likeInvalid: false,
      superliked: false,
      superlikeStamp: false,
      superlikeInvalid: false,
      matched: false,
      chatid: '',
      noteInvalid: false,
      sendNoteOpen: false,
      undoInvalid: false,
      undoStamp: false,
      userID: '',
    };
  }

  componentDidMount() {
    var likedUsers = this.props.likedUsers;
    var superlikedUsers = this.props.superlikedUsers;
    var userID = this.props.user._id;
    var liked = false;
    var superliked = false;

    if (superlikedUsers.includes(userID)) {
      superliked = true;
    } else if (likedUsers.includes(userID)) {
      liked = true;
    }

    if (superliked) {
      this.setState({ liked: true, superlikeStamp: true, userID: userID });
    } else if (liked) {
      this.setState({ liked: true, likeStamp: true, userID: userID });
    }
  }

  componentWillReceiveProps() {
    var likedUsers = this.props.likedUsers;
    var superlikedUsers = this.props.superlikedUsers;
    var userID = this.props.user._id;
    var liked = false;
    var superliked = false;

    if (superlikedUsers.includes(userID)) {
      superliked = true;
    } else if (likedUsers.includes(userID)) {
      liked = true;
    }

    if (superliked) {
      this.setState({ liked: true, superlikeStamp: true });
    } else if (liked) {
      this.setState({ liked: true, likeStamp: true });
    }

    if (!superliked && !liked) {
      this.setState({
        liked: false,
        likeStamp: false,
        superlikeStamp: false,
      });
    }
  }

  actionsHandler = async (e) => {
    if (e === 'like') {
      this.handleLike();
    }
    if (e === 'undo') {
      this.handleUndo();
    }

    if (e === 'note') {
      var chatid = this.props.user._id;
      this.props.chatHandler(chatid);
    }
  };

  handleLike = async (e) => {
    var userid = this.context.user._id;
    var cardid = this.props.user._id;
    this.setState({ likeStamp: true });
    const like = await submitLike(userid, cardid).catch((err) => {
      console.log(err);
    });
    var valid = like.valid;
    var match = like.match;

    if (valid) {
      if (match) {
        this.setState({ matched: true, matchid: cardid, liked: true });
      } else {
        this.setState({ liked: true });
      }
      this.props.likeHandler(cardid);
    } else {
      this.setState({ likeInvalid: true, likeStamp: false });
    }
  };

  handleUndo = async (e) => {
    this.setState({ undoStamp: true });
    var userid = this.context.user._id;
    var cardid = this.props.user._id;
    var action = '';

    if (this.state.likeStamp) {
      action = 'like';
    }

    if (this.state.superlikeStamp) {
      action = 'superlike';
    }

    const undo = await submitUndo(userid, cardid, action).catch((err) => {
      console.log(err);
    });

    var valid = undo.valid;

    if (valid) {
      this.setState({ likeStamp: false, liked: false, superlikeStamp: false });
      this.props.undoHandler(cardid, action);
    } else {
      this.setState({ undoInvalid: true });
    }
    this.setState({ undoStamp: false });
  };

  handleNote = async (e) => {
    var userid = this.context.user._id;
    var cardid = this.props.user._id;
    const data = {
      userid: userid,
    };
    var valid = false;

    return api
      .noteCheck(data)
      .then((res) => {
        if (res.status === 200) {
          valid = res.data.valid;
        }
      })
      .catch((err) => {});

    if (valid) {
      this.setState({ sendNoteOpen: true });
    } else {
      this.setState({ noteInvalid: true });
    }
  };

  handleSubmitNote = async (message) => {
    this.setState({ sendNoteOpen: false });
    var userid = this.context.user._id;
    var cardid = this.props.user._id;

    const noteMessage = await submitNote(userid, cardid, message).catch(
      (err) => {
        console.log(err);
      }
    );

    var match = noteMessage.match;

    if (match) {
      this.setState({
        matched: true,
        matchid: cardid,
        liked: true,
        likeStamp: true,
      });
    } else {
      this.setState({ liked: true, likeStamp: true });
    }
  };

  closeMatchHandler = (e) => {
    this.setState({ matched: false, matchid: '' });
  };

  closePopHandler = (e) => {
    this.setState({
      likeInvalid: false,
      superlikeInvalid: false,
      noteInvalid: false,
      sendNoteOpen: false,
      undoInvalid: false,
    });
  };

  searchProfileHandler = (e) => {
    var cardid = this.props.user._id;
    this.props.searchProfileHandler(cardid);
  };

  render() {
    var imgurl = this.props.user.imgurl;
    var username = this.props.user.username;
    var address = this.props.user.address;
    var age = this.props.user.age;
    var lastlogin = this.props.user.lastlogin;
    var online = false;

    var currentTime = Date.now();
    var lastloginDate = new Date(lastlogin);
    var lastloginTime = lastloginDate.getTime();
    var difference = currentTime - lastloginTime;
    if (difference < 3600000) {
      online = true;
    }

    var premium = false;
    if (this.props.user.premium) {
      premium = true;
    }
    if (imgurl.length > 0) {
      var image = imgurl[0];
    } else {
      var image = null;
    }

    let vhh = window.innerHeight;
    let vhw = window.innerWidth;
    var top = vhh * 0.17 + 'px';
    var left = vhw * 0.06 + 'px';
    var superlikeBottom = vhh / 3.5 + 'px';
    var superlikeLeft = vhw / 4 + 'px';

    var likeStampPosition = {
      top: top,
      left: left,
    };
    var superlikeStampPosition = {
      bottom: superlikeBottom,
      left: superlikeLeft,
    };

    var vw = window.innerWidth;

    if (vw > 992) {
      var cardStyle = {
        flexBasis: '20%',
      };
    } else {
      var cardStyle = {
        flexBasis: '48%',
      };
    }

    return (
      <Card className="search-user-card" style={cardStyle}>
        {this.state.matched ? (
          <Match
            matchid={this.state.matchid}
            closeMatchHandler={this.closeMatchHandler}
          />
        ) : null}

        {this.state.sendNoteOpen ? (
          <SendNotePop
            closeHandler={this.closePopHandler}
            cardid={this.props.user._id}
            submitHandler={this.handleSubmitNote}
          />
        ) : null}

        {this.state.noteInvalid ? (
          <NoteInvalidModal
            closeHandler={this.closePopHandler}
            cardid={this.props.user._id}
          />
        ) : null}

        {this.state.undoInvalid ? (
          <UndoPop
            closeHandler={this.closePopHandler}
            cardid={this.state.cardid}
          />
        ) : null}

        {this.state.likeInvalid ? (
          <LikePop
            closeHandler={this.closePopHandler}
            cardid={this.props.user._id}
          />
        ) : null}
        {this.state.superlikeInvalid ? (
          <SuperlikePop
            closeHandler={this.closePopHandler}
            cardid={this.props.user._id}
          />
        ) : null}
        {this.state.likeStamp ? (
          <StampImage src={likedStamp} className="liked-stamp-image" />
        ) : null}

        {this.state.undoStamp ? (
          <StampImage src={undoStamp} className="undo-stamp-image" />
        ) : null}
        {this.state.superlikeStamp ? (
          <StampImage src={superlikedStamp} className="liked-stamp-image" />
        ) : null}
        <Card.Body
          className="search-user-card-body"
          onClick={this.searchProfileHandler}
        >
          <Media image={image} />
          {/* <Image
            publicId={getImgName(image)}
            version={getImgVersion(image)}
            resource_type={checkGIF(image) ? "video" : "image"}
            effect="loop"
            cloud_name="sugarbae"
            className="search-user-card-image"
            fluid
            onClick={this.searchProfileHandler}
          >
            <Transformation
              height="200"
              video_sampling="20"
              delay="250"
              duration="10"
              effect="loop"
              quality="auto"
              flags="lossy"
            />
          </Image> */}
          <div className="search-user-card-info">
            <div className="search-user-card-info-wrap">
              <div className="search-user-card-info-innerwrap">
                <div className="search-user-card-info-text">{username}</div>
                <div className="search-user-card-info-text">{address}</div>
              </div>
              <div
                className="search-user-card-info-innerwrap"
                style={{ alignItems: 'flex-end' }}
              >
                <div className="search-user-card-info-text">{age}</div>
                {online ? (
                  <div className="search-user-card-info-text">
                    <i className="fas fa-circle search-user-card-online-icon"></i>{' '}
                    <span className="search-user-card-info-text">Online</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="search-user-card-footer">
          <UserCardActions
            actionsHandler={this.actionsHandler}
            liked={this.state.liked}
          />
        </Card.Footer>
      </Card>
    );
  }
}

export default withRouter(UserCard);
