import React, { Component } from "react";
import "./seekingarrangements.scss";
import HomeFooter from "../../../components/footers/homeFooter/HomeFooter";
import HomeNav from "../../../components/navbar/HomeNav/HomeNav";
import arrangementHeader from "../../../assets/Images/Headers/arrangement-header.jpg";
import { Image } from "react-bootstrap";
import ArrangementBenefits from "../../../components/content/arranagementBenefits/ArrangementBenefits";
import ArrangementCta from "../../../components/ctas/arrangementCta/ArrangementCta";
import ArrangementHero from "../../../assets/Images/Headers/arrangement-hero-desktop.jpg";

export class SeekingArrangements extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }
  render() {
    var vw = window.innerWidth;
    return (
      <div className="arrangement-page">
        <HomeNav />

        <div className="arrangement-hero">
          <div className="arrangement-hero-content-container">
            <div className="arrangement-hero-image-wrapper">
              {vw > 992 ? (
                <Image
                  src={ArrangementHero}
                  className="arrangement-hero-image-desktop"
                />
              ) : (
                <Image
                  src={arrangementHeader}
                  className="arrangement-hero-image"
                />
              )}
            </div>
            <div className="arrangement-hero-content-wrapper">
              <h1 className="arrangement-hero-title">
                What is an Arrangement?
              </h1>
              <p className="arrangement-hero-content">
                An arrangement, also known as sugar dating or sugaring, is where
                a sugar daddy and a sugar baby are upfront about their
                expectations and what they are looking for in a relationship.
                Whether you’re into companionship, intimacy, or anything in
                between; you’re very likely to find yourself a treat on
                Sugarbae.
              </p>
            </div>
          </div>
        </div>
        <div className="arrangement-content-container">
          <div className="arrangement-how-it-works">
            <h2 className="arrangement-hiw-title">How an Arrangement Works:</h2>
            <p className="arrangement-hiw-content">
              At its core, an arrangement or a sugar date is a connection
              between a wealthy gentleman and a gorgeous lady. All of the terms
              and limitations are clearly communicated upfront including what to
              expect and, more importantly, what NOT to expect from each other.
              The sugar baby ensures a delightful and soothing company for the
              sugar daddy. In return, the sugar daddy expresses his appreciation
              in the form of generous allowances, gifts, dinners, vacations, and
              more.
            </p>
            <p className="arrangement-hiw-content">
              It is a simplified and hassle-free form of dating that turns
              conventional dating on its head. You get to experience the best
              moments as you would in a traditional relationship; but it has
              none of the drama, dishonesty, and lack of transparency associated
              with traditional dating.
            </p>
          </div>
        </div>
        <ArrangementBenefits />
        <ArrangementCta />
        <HomeFooter />
        <span></span>
      </div>
    );
  }
}

export default SeekingArrangements;
