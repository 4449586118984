import React, { useState, useContext, useEffect } from 'react';
import './premium.scss';
import { Button, Alert } from 'react-bootstrap';
import PremiumFeatures from '../carousels/PremiumFeatures';
import { useHistory, withRouter, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../utils/getCloudinaryInfo';
import { Image as BootstrapImage } from 'react-bootstrap';
import sugarbaePlusLogo from '../../assets/logos/sugarbae-home-icon.png';
import axios from 'axios';
import { api } from '../../api';
import ClipLoader from 'react-spinners/ClipLoader';
import Media from '../../components/media/Media';

function Premium(props) {
  const location = useLocation();

  const [onboarding, setOnboarding] = useState(false);

  useEffect(() => {
    if (context.user.premium) {
      history.push('/app/profile');
    }
  }, []);

  const context = useContext(UserContext);
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const backHandler = (e) => {
    if (location) {
      if (location.state) {
        if (location.state.onboarding) {
          history.push('/app');
        } else {
          history.goBack();
        }
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  const checkoutHandler = async (e) => {
    setLoading(true);
    var ipAddress = null;
    await axios
      .get('https://json.geoiplookup.io', {
        withCredentials: false,
      })
      .then((res) => {
        ipAddress = res.data.ip;
      })
      .catch((err) => {
        console.log(err);
      });
    const data = {
      ipAddress,
    };
    var checkSuccess = true;
    await api
      .checkRiskIp(data)
      .then((res) => {
        if (res.data === 'unacceptable') {
          setAlertOpen(true);
          setLoading(false);
          checkSuccess = false;
        }
      })
      .catch(() => {});
    if (checkSuccess) {
      var checkoutBaseURL =
        'https://api.ccbill.com/wap-frontflex/flexforms/9cf2db3b-15b7-4c18-ac89-675802db2728';

      var checkoutURL = checkoutBaseURL + '?Email=' + context.user.email;
      window.location.replace(checkoutURL);
    }
  };

  var mainImage = null;

  if (context.user.imgurl) {
    if (context.user.imgurl.length > 0) {
      mainImage = context.user.imgurl[0];
    }
  }

  return (
    <div className="premium-component">
      {loading && (
        <div className="premium-checkout-loading-overlay">
          <ClipLoader color="white" />
        </div>
      )}
      <div className="premium-page-content">
        {alertOpen && (
          <Alert variant="danger" onClose={() => setAlertOpen(false)}>
            Your account requires ID verification before you can activate
            Premium. Please email support@sugarbae.com to verify your identity.
          </Alert>
        )}
        <div className="premium-page-header-v2">
          <h1 className="premium-page-h1">Upgrade</h1>
          <h2 className="premium-page-h2">Premium</h2>
          <p className="premium-page-p">
            Unlock the world's best dating experience
          </p>
        </div>
        <div className="premium-page-price-section">
          <div className="premium-section-image-wrapper">
            {mainImage === null ? (
              <BootstrapImage
                className="sugarbae-plus-logo-premium"
                fluid
                src={sugarbaePlusLogo}
              />
            ) : (
              <Media image={mainImage} />
              // <Image
              //   publicId={getImgName(mainImage)}
              //   version={getImgVersion(mainImage)}
              //   resource_type={checkGIF(mainImage) ? "video" : "image"}
              //   effect="loop"
              //   cloud_name="sugarbae"
              //   className="premium-page-user-image"
              //   fluid
              // >
              //   <Transformation
              //     height="200"
              //     video_sampling="20"
              //     delay="250"
              //     duration="10"
              //     effect="loop"
              //     quality="auto"
              //     flags="lossy"
              //   />
              // </Image>
            )}
          </div>
          <div className="premium-section-price-wrapper">
            <h1 className="premium-section-title">Sugarbae Premium</h1>
            <div className="premium-section-price">
              <span className="premium-dollar-sign">$</span>
              <span className="premium-price-price">19.97</span>
              <span className="premium-price-per-month">/ per month</span>
            </div>
          </div>
        </div>
        <div className="premium-page-features-wrapper">
          <PremiumFeatures option="noButton" />
        </div>
      </div>
      <div className="premium-page-cta">
        <Button
          className="premium-free-button"
          variant="link"
          size="lg"
          onClick={backHandler}
        >
          Continue with Free
        </Button>
        <Button
          className="premium-activate-button"
          variant="danger"
          size="lg"
          onClick={checkoutHandler}
        >
          Activate Premium
        </Button>
      </div>
    </div>
  );
}

export default withRouter(Premium);
