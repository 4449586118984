const profileProgressCalculator = async function (user) {
  var progress = 0;
  var nextStep = "";
  var stepLink = "";

  var images = user.imgurl;
  var benefits = user.benefits;
  var headline = user.headline;
  var bio = user.bio;
  var verifications = user.verifications;
  var height = user.height;
  var bodytype = user.bodytype;
  var ethnicity = user.ethnicity;
  var lifestyle = user.lifestyle;
  var imagesComplete = false;
  var bioComplete = false;
  var headlineComplete = false;
  var benefitsComplete = false;
  var phoneVerified = false;
  var emailVerified = false;
  var heightComplete = false;
  var bodytypeComplete = false;
  var ethnicityComplete = false;
  var educationComplete = false;
  var relationshipComplete = false;
  var childrenComplete = false;
  var smokingComplete = false;
  var drinkingComplete = false;
  var cannabisComplete = false;
  var exerciseComplete = false;
  var jobtitleComplete = false;
  var annualIncomeComplete = false;
  var networthComplete = false;
  var premiumComplete = false;

  if (images.length > 0) {
    progress = progress + 20;
    imagesComplete = true;
  }

  if (headline) {
    if (headline.length > 0) {
      progress = progress + 10;
      headlineComplete = true;
    }
  }

  if (bio) {
    if (bio.length > 0) {
      progress = progress + 10;
      bioComplete = true;
    }
  }

  var benefitsCount = Object.values(benefits).filter((item) => item === true)
    .length;

  if (benefitsCount > 0) {
    progress = progress + 10;
    benefitsComplete = true;
  }

  if (verifications.phone.status) {
    progress = progress + 10;
    phoneVerified = true;
  }

  if (verifications.email.status) {
    progress = progress + 10;
    emailVerified = true;
  }

  if (user.gender === "female") {
    if (height) {
      progress = progress + 2;
      heightComplete = true;
    }
    if (bodytype) {
      progress = progress + 2;
      bodytypeComplete = true;
    }
    if (ethnicity) {
      progress = progress + 2;
      ethnicityComplete = true;
    }
    if (lifestyle.education) {
      progress = progress + 2;
      educationComplete = true;
    }
    if (lifestyle.relationship) {
      progress = progress + 2;
      relationshipComplete = true;
    }
    if (lifestyle.children) {
      progress = progress + 2;
      childrenComplete = true;
    }
    if (lifestyle.smoking) {
      progress = progress + 2;
      smokingComplete = true;
    }
    if (lifestyle.drinking) {
      progress = progress + 2;
      drinkingComplete = true;
    }
    if (lifestyle.cannabis) {
      progress = progress + 2;
      cannabisComplete = true;
    }
    if (lifestyle.exercise) {
      progress = progress + 2;
      exerciseComplete = true;
    }
  } else if (user.gender === "male") {
    if (height) {
      progress = progress + 1.54;
      heightComplete = true;
    }
    if (bodytype) {
      progress = progress + 1.54;
      bodytypeComplete = true;
    }
    if (ethnicity) {
      progress = progress + 1.54;
      ethnicityComplete = true;
    }
    if (lifestyle.education) {
      progress = progress + 1.54;
      educationComplete = true;
    }
    if (lifestyle.relationship) {
      progress = progress + 1.54;
      relationshipComplete = true;
    }
    if (lifestyle.children) {
      progress = progress + 1.54;
      childrenComplete = true;
    }
    if (lifestyle.smoking) {
      progress = progress + 1.54;
      smokingComplete = true;
    }
    if (lifestyle.drinking) {
      progress = progress + 1.54;
      drinkingComplete = true;
    }
    if (lifestyle.cannabis) {
      progress = progress + 1.54;
      cannabisComplete = true;
    }
    if (lifestyle.exercise) {
      progress = progress + 1.54;
      exerciseComplete = true;
    }
    if (lifestyle.networth) {
      progress = progress + 1.54;
      networthComplete = true;
    }
    if (lifestyle.annualincome) {
      progress = progress + 1.54;
      annualIncomeComplete = true;
    }
    if (lifestyle.job.jobtitle) {
      progress = progress + 1.54;
      jobtitleComplete = true;
    }
  }

  if (user.premium) {
    progress = progress + 10;
    premiumComplete = true;
  }

  if (!imagesComplete) {
    nextStep = "Upload Images";
    stepLink = "/app/profile/photos";
  } else if (!headlineComplete) {
    nextStep = "Finish About You";
    stepLink = "/app/profile/descriptions";
  } else if (!bioComplete) {
    nextStep = "Finish Looking For";
    stepLink = "/app/profile/descriptions";
  } else if (!benefitsComplete) {
    nextStep = "Choose Benefits";
    stepLink = "/app/profile/descriptions";
  } else if (!emailVerified) {
    nextStep = "Verify Email";
    stepLink = "/app/profile/verifications";
  } else if (!phoneVerified) {
    nextStep = "Verify Phone";
    stepLink = "/app/profile/verifications";
  } else if (!heightComplete) {
    nextStep = "Select Height";
    stepLink = "/app/profile/personal";
  } else if (!bodytypeComplete) {
    nextStep = "Select Bodytype";
    stepLink = "/app/profile/personal";
  } else if (!ethnicityComplete) {
    nextStep = "Select Ethnicity";
    stepLink = "/app/profile/personal";
  } else if (user.gender === "male" && !jobtitleComplete) {
    nextStep = "Select Job Title";
    stepLink = "/app/profile";
  } else if (user.gender === "male" && !annualIncomeComplete) {
    nextStep = "Select Annual Income";
    stepLink = "/app/profile";
  } else if (user.gender === "male" && !networthComplete) {
    nextStep = "Select Net Worth";
    stepLink = "/app/profile";
  } else if (!educationComplete) {
    nextStep = "Select Education";
    stepLink = "/app/profile/personal";
  } else if (!relationshipComplete) {
    nextStep = "Select Relationship";
    stepLink = "/app/profile/personal";
  } else if (!childrenComplete) {
    nextStep = "Select Children";
    stepLink = "/app/profile/personal";
  } else if (!smokingComplete) {
    nextStep = "Select Smoking";
    stepLink = "/app/profile/personal";
  } else if (!drinkingComplete) {
    nextStep = "Select Drinking";
    stepLink = "/app/profile/personal";
  } else if (!cannabisComplete) {
    nextStep = "Select Cannabis";
    stepLink = "/app/profile/personal";
  } else if (!exerciseComplete) {
    nextStep = "Select Exercise";
    stepLink = "/app/profile/personal";
  } else {
    nextStep = "Finished";
    stepLink = "/app/profile";
  }

  progress = Math.floor(progress);

  return { progress, nextStep, stepLink };
};

export default profileProgressCalculator;
