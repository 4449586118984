import React, { Component } from "react";
import AdminNavbar from "../../admincomponents/adminNavbar/AdminNavbar";
import { UserContext } from "../../../context/UserContext";
import { withRouter } from "react-router-dom";
import BotsCard from "../../admincomponents/botsCard/BotsCard";
import PaymentsCard from "../../admincomponents/paymentsCard/PaymentsCard";

export class Payments extends Component {
  static contextType = UserContext;

  componentWillMount() {
    if (!this.context.user.isAdmin) {
      this.props.history.push("/app");
    }
  }
  render() {
    return (
      <div>
        <AdminNavbar />
        <PaymentsCard />
      </div>
    );
  }
}

export default withRouter(Payments);
