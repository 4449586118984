import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';

import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import Media from '../../../../components/media/Media';

import './matchcard.scss';

export const MatchCard = (props) => {
  const image = props.card.user.imgurl[0];

  const profileToggle = () => {
    props.profileToggle(props.card.user);
  };

  const chatToggle = () => {
    props.history.push(`messages/${props.card.user._id}`);
  };

  return (
    <div className="match-card-component">
      <Card className="match-card">
        <Card.Body className="matches-card-body">
          <div className="matches-card-info">
            <h2 className="matches-card-username">
              {props.card.user.username}, {props.card.user.age}
            </h2>
          </div>

          <Media image={image} />
          {/* <Image
            publicId={
              image
                ? getImgName(image)
                : "larger-placeholder-user-image_vvdghq.png"
            }
            version={image ? getImgVersion(image) : "1590046357"}
            resource_type={
              image ? (checkGIF(image) ? "video" : "image") : null
            }
            cloud_name="sugarbae"
            className="matches-card-image"
            onClick={() => profileToggle()}
          >
            <Transformation
              height="675"
              width="540"
              video_sampling="20"
              delay="250"
              duration="10"
              effect="loop"
              quality="auto"
              flags="lossy"
            />
          </Image> */}
          <div className="matches-card-action-wrapper">
            <div className="matches-card-button" onClick={() => chatToggle()}>
              <i className="far fa-comment match-card-chat-icon"></i>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default withRouter(MatchCard);
