import React from "react";
import { Card } from "react-bootstrap";
import moment from "moment";

import "../../../admincomponents.scss";

export const AdminMessage = props => {
  const { message, data, user, card } = props;
  const date = moment(message.createdAt).calendar();

  const messageClass = data.sent ? "message-sent" : "message-received";

  return (
    <div className="message-component">
      <Card className="message-card-admin">
        <Card.Header className="message-card-header">
          {data.timeStamp && (
            <Card.Title className="message-card-title">{date}</Card.Title>
          )}
        </Card.Header>
        <Card.Body className="message-card-body">
          <div className={messageClass}>
            {message.message}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AdminMessage;