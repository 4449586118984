import Axios from "axios";
import api from "../api/api";

export function ValidateToken() {
  const token = localStorage.getItem("auth");
  const baseurl = process.env.REACT_APP_API_URL;
  const url = baseurl + "/api/protected";
  const config = {
    headers: { auth: token },
  };
  if (!token) {
    return false;
  } else {
    var ipAddress = null;
    Axios.get("https://json.geoiplookup.io", {
      withCredentials: false,
    })
      .then((res) => {
        ipAddress = res.data.ip;
      })
      .catch((err) => {
        console.log(err);
      });
    const params = {
      ipAddress,
    };
    return api
      .fetchProtected(params)
      .then((res) => {
        return true;
      })
      .catch((eerr) => {
        localStorage.removeItem("auth");
        return false;
      });
  }
}

export default ValidateToken;
