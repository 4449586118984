import io from 'socket.io-client';

import { events } from './constants';

const baseurl = process.env.REACT_APP_API_URL;

const socket = io(baseurl);

class SocketManager {
  // Event emitters
  sendMessage = (data) => socket.emit(events.SEND_MESSAGE, data);
  readMessages = (data) => socket.emit(events.READ_MESSAGES, data);
  checkMatch = (data) => socket.emit(events.CHECK_MATCH, data);
  makeVideoCall = (data) => socket.emit(events.MAKE_VIDEO_CALL, data);
  rejectVideoCall = (data) => socket.emit(events.REJECT_VIDEO_CALL, data);
  checkLike = (data) => socket.emit(events.CHECK_LIKE, data);

  // Event listeners
  onNewMessage = (username, callback) => {
    const event = `${events.NEW_MESSAGE}${username}`
    socket.on(event, callback);
  };
  offNewMessage = (username) => {
    const event = `${events.NEW_MESSAGE}${username}`;
    socket.off(event);
  };

  onNewMatch = (userId, callback) => {
    const event = `${events.NEW_MATCH}${userId}`
    socket.on(event, callback);
  };
  offNewMatch = (userId) => {
    const event = `${events.NEW_MATCH}${userId}`;
    socket.off(event);
  };

  onNewLike = (userId, callback) => {
    const event = `${events.NEW_LIKE}${userId}`
    socket.on(event, callback);
  };
  offNewLike = (userId) => {
    const event = `${events.NEW_LIKE}${userId}`;
    socket.off(event);
  };

  onReadMessagesSuccess = (userId, callback) => {
    const event = `${events.READ_MESSAGES_SUCCESS}${userId}`;
    socket.on(event, callback);
  };
  offReadMessagesSuccess = (userId) => {
    const event = `${events.READ_MESSAGES_SUCCESS}${userId}`;
    socket.off(event);
  };
}

export default new SocketManager();
