import React, { Component } from "react";
import "./nomessages.scss";
import { Card, Image } from "react-bootstrap";
import noMessages from "../../../../../assets/Images/messages/conversation.png";

export class NoMessages extends Component {
  render() {
    return (
      <Card className="no-messages-card">
        <Card.Body className="no-messages-card-body">
          <Image src={noMessages} className="no-messages-image" />
        </Card.Body>
        <Card.Footer className="no-messages-card-footer">
          <h4 className="no-messages-title">No Messages... yet!</h4>
          <p className="no-messages-subtitle">
            When you match or receive messages from other users they will appear
            here in your inbox.
          </p>
        </Card.Footer>
      </Card>
    );
  }
}

export default NoMessages;
