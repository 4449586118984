import React, { useState, useEffect, useContext } from 'react';
import DesktopNavV2 from '../desktopNavV2/DesktopNavV2';
import DesktopSmallNavV2 from '../desktopNavV2/DesktopSmallNavV2';
import DesktopSidebarV2 from '../desktopSidebarV2/DesktopSidebarV2';
import './desktopprofile.scss';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { UserContext } from '../../../../context/UserContext';
import { api } from '../../../../api';
import verificationIcon from '../../../../assets/Icons/badge_verification.svg';
import { Image as BootstrapImage } from 'react-bootstrap';
import likeIcon from '../../../../assets/Icons/likeIcon.png';
import complimentIcon from '../../../../assets/Icons/complimentIcon.png';
import messageIconRed from '../../../../assets/Icons/messageIconRed.png';
import GoogleMapReact from 'google-map-react';
import About from '../../../profile/profileV2Components/about/About';
import LookingFor from '../../../profile/profileV2Components/lookingFor/LookingFor';
import DesktopPhotoGridV2 from '../desktopPhotoGridV2/DesktopPhotoGridV2';
import Verifications from '../../../profile/profileV2Components/verifications/Verifications';
import Socials from '../../../profile/profileV2Components/socials/Socials';
import ProfileV2Footer from '../../../profile/profileV2Components/footer/ProfileV2Footer';
import { withRouter, useHistory } from 'react-router-dom';
import heartWhite from '../../../../assets/Icons/heartWhite.png';
import {
  submitLikeSearch,
  removeLikeSearch,
} from '../../../../helpers/swipeActions';
import ComplimentModal from '../../../modals/complimentModal/ComplimentModal';
import checkRedIcon from '../../../../assets/Icons/checkRedIcon.png';
import facebook from '../../../../assets/Icons/social/facebook.png';
import facebookGrey from '../../../../assets/Icons/social/facebookGrey.png';
import instagram from '../../../../assets/Icons/social/instagram.png';
import instagramGrey from '../../../../assets/Icons/social/instagramGrey.png';
import linkedin from '../../../../assets/Icons/social/linkedin.png';
import linkedinGrey from '../../../../assets/Icons/social/linkedinGrey.png';
import twitter from '../../../../assets/Icons/social/twitter.png';
import twitterGrey from '../../../../assets/Icons/social/twitterGrey.png';
import DesktopPersonalInfo from './DesktopPersonalInfo';
import DesktopAbout from './DesktopAbout';
import Media from '../../../../components/media/Media';

function DesktopProfileV2(props) {
  const context = useContext(UserContext);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [card, setCard] = useState(null);
  const [activeConversation, setActiveConversation] = useState(false);
  const [previewCoordinates, setPreviewCoordinates] = useState([]);
  const [showMapPreview, setShowMapPreview] = useState(false);
  const [complimentModalOpen, setComplimentModalOpen] = useState(false);
  const [liked, setLiked] = useState(true);
  const [matched, setMatched] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (card !== null) {
      let isLiked = false;
      if (context.likedUsers.includes(card._id)) {
        isLiked = true;
      }
      setLiked(isLiked);
    }
  }, [card]);

  const messageHandler = (e) => {
    const chatLink = `/app/messages/${card._id}`;
    history.push(chatLink);
  };

  const toggleLike = async (e) => {
    if (liked) {
      setLiked(false);
      const removeLike = await removeLikeSearch(
        context.user._id,
        card._id
      ).catch((err) => {
        console.log(err);
      });

      if (removeLike.valid) {
        var tempLikedUsers = context.likedUsers;
        const index = tempLikedUsers.indexOf(card._id);

        if (index > -1) {
          tempLikedUsers.splice(index, 1);
          context.updateLikedUsers(tempLikedUsers);
        }
      } else {
        setLiked(false);
      }
    } else {
      setLiked(true);

      const sendLike = await submitLikeSearch(context.user._id, card._id).catch(
        (err) => {
          console.log(err);
        }
      );

      if (sendLike.valid) {
        var tempLikedUsers = context.likedUsers;

        if (!tempLikedUsers.includes(card._id)) {
          tempLikedUsers.push(card._id);
          context.updateLikedUsers(tempLikedUsers);
        }
      } else {
        setLiked(false);
      }

      if (sendLike.match) {
        setMatched(true);
      }
    }
  };

  const menuToggle = (e) => {};

  const fetchData = async (e) => {
    const data = {
      userid: context.user._id,
      cardid: props.profileurl,
    };

    return api
      .fetchUserProfile(data)
      .then((res) => {
        const { card } = res.data;
        setCard(card);
        setLoading(false);
        fetchCoordinates(card.address);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCoordinates = async (e) => {
    const data = {
      address: e,
    };

    await api.fetchAddressCoordinates(data).then((res) => {
      if (res.status === 200) {
        setPreviewCoordinates(res.data.coordinates);
        setShowMapPreview(true);
      }
    });
  };

  let mainImage = null;

  if (!loading) {
    if (card && card.imgurl.length > 0) {
      mainImage = card.imgurl[0];
    }
  }
  let isOwnProfile = false;

  if (!loading) {
    if (context.user.username === card.username) {
      isOwnProfile = true;
    }
  }

  const modalToggle = (e) => {
    if (complimentModalOpen) {
      setComplimentModalOpen(false);
    } else {
      setComplimentModalOpen(true);
    }
  };

  const reportHandler = (e) => {
    props.goBack();
  };

  const banHandler = (e) => {
    context.removeBannedUserFromContext(e);
    props.goBack();
  };

  let verified = false;
  if (!loading) {
    if (card.verifications.email.status && card.verifications.phone.status) {
      verified = true;
    }
  }

  let idVerified = false;
  if (!loading) {
    if (card.verifications.id.status) {
      idVerified = true;
    }
  }

  const socialConnections = {
    facebook: false,
    instagram: false,
    twitter: false,
    linkedin: false,
  };

  const superliked = false;

  return (
    <div className="desktop-v2-container">
      {complimentModalOpen && (
        <ComplimentModal
          show={complimentModalOpen}
          modalToggle={modalToggle}
          profileUser={card}
        />
      )}
      <DesktopSidebarV2 />
      <div className="desktop-v2-content">
        <div className="desktop-v2-profile-content">
          <div className="desktop-v2-profile-main">
            <div className="desktop-v2-profile-user-header">
              <div className="desktop-v2-profile-user-header-section">
                <div className="desktop-v2-profile-user-image-wrapper">
                  <Media image={mainImage} />
                  {/* <Image
                    publicId={
                      mainImage
                        ? getImgName(mainImage)
                        : "larger-placeholder-user-image_vvdghq.png"
                    }
                    version={
                      mainImage ? getImgVersion(mainImage) : "1590046357"
                    }
                    resource_type={
                      mainImage
                        ? checkGIF(mainImage)
                          ? "video"
                          : "image"
                        : null
                    }
                    effect="loop"
                    cloud_name="sugarbae"
                    className="desktop-v2-profile-user-image"
                    fluid
                  >
                    <Transformation
                      height="200"
                      video_sampling="20"
                      delay="250"
                      duration="10"
                      effect="loop"
                      quality="auto"
                      flags="lossy"
                    />
                  </Image> */}
                </div>
                {!loading && (
                  <div className="desktop-v2-profile-header-user-info">
                    <div>
                      <div className="desktop-v2-profile-user-info-username-age-wrap">
                        <h1 className="desktop-v2-profile-header-username">
                          {card.username},
                        </h1>
                        <p className="desktop-v2-profile-header-age">
                          {card.age}
                        </p>
                        {verified && (
                          <BootstrapImage
                            src={verificationIcon}
                            className="desktop-v2-profile-header-verified-icon"
                          />
                        )}
                      </div>
                      <div className="desktop-v2-profile-user-address">
                        {card.address}
                      </div>
                    </div>
                    <div className="desktop-v2-profile-header-actions-wrapper">
                      {liked ? (
                        <div
                          className="desktop-v2-profile-user-header-compliment-button"
                          onClick={toggleLike}
                          style={{ marginRight: '1rem' }}
                        >
                          <BootstrapImage
                            src={heartWhite}
                            className="desktop-v2-profile-user-header-button-icon"
                          />
                        </div>
                      ) : (
                        <div
                          className="desktop-v2-profile-user-header-button"
                          onClick={toggleLike}
                        >
                          <BootstrapImage
                            src={likeIcon}
                            className="desktop-v2-profile-user-header-button-icon"
                          />
                        </div>
                      )}

                      {context.user.gender === 'male' && (
                        <div
                          className="desktop-v2-profile-user-header-compliment-button"
                          onClick={modalToggle}
                        >
                          <BootstrapImage
                            src={complimentIcon}
                            className="desktop-v2-profile-user-header-button-icon"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="desktop-v2-profile-user-header-section">
                {!loading && (
                  <div className="desktop-v2-profile-user-header-right-side">
                    <div
                      className="desktop-v2-profile-user-header-menu-button"
                      onClick={menuToggle}
                    >
                      <i className="far fa-ellipsis-h desktop-v2-profile-user-header-menu-icon" />
                    </div>

                    <div
                      className="desktop-v2-profile-user-header-message-button"
                      onClick={messageHandler}
                    >
                      <BootstrapImage
                        src={messageIconRed}
                        className="desktop-v2-profile-send-message-icon"
                        fluid
                      />
                      <span className="desktop-v2-profile-send-message-text">
                        Message
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="desktop-v2-profile-user-content-container">
              <DesktopPhotoGridV2 card={card} loading={loading} />

              {!loading && (
                <div className="desktop-v2-profile-user-content-section">
                  <DesktopAbout card={card} />
                </div>
              )}

              <div className="desktop-v2-profile-user-content-section">
                <h1 className="desktop-v2-profile-user-content-section-title">
                  Location
                </h1>
                <div className="desktop-v2-profile-user-location-map-wrapper">
                  {!loading && showMapPreview ? (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: 'AIzaSyCoSbKzFFYZ2HnK-jOZwIqdhwp7Aq4fu4I',
                      }}
                      defaultCenter={{
                        lat: previewCoordinates[1],
                        lng: previewCoordinates[0],
                      }}
                      defaultZoom={11}
                      options={{
                        panControl: false,
                        mapTypeControl: false,
                        scrollwheel: false,
                        disableDefaultUI: true,
                      }}
                      defaultOptions={{
                        fullscreenControl: false,
                      }}
                    />
                  ) : (
                    <div className="desktop-v2-profile-user-location-map-placeholder" />
                  )}
                </div>
              </div>
              {!loading && (
                <div className="desktop-v2-profile-user-content-section">
                  <LookingFor profileUser={card} />
                </div>
              )}
              {!loading && (
                <div className="desktop-v2-profile-user-content-section">
                  <DesktopPersonalInfo card={card} />
                </div>
              )}
              {!loading && context.user.isAdmin && (
                <div className="desktop-v2-profile-user-content-section">
                  <ProfileV2Footer
                    profileUser={card}
                    modalToggle={modalToggle}
                    isOwnProfile={isOwnProfile}
                    reportHandler={reportHandler}
                    banHandler={banHandler}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="desktop-v2-profile-right">
            <DesktopSmallNavV2 />
            {/* {!loading && (
              <div className="desktop-v2-profile-right-content">
                <div className="desktop-v2-profile-right-side-section">
                  <h1 className="desktop-v2-profile-right-title">
                    Verifications
                  </h1>
                  <div
                    className={
                      idVerified
                        ? "desktop-v2-verification-item-active"
                        : "desktop-v2-verification-item"
                    }
                  >
                    ID Verified
                    {idVerified && (
                      <BootstrapImage
                        src={checkRedIcon}
                        className="desktop-v2-verification-icon"
                      />
                    )}
                  </div>
                </div>
                <div className="desktop-v2-profile-right-side-section">
                  <h1 className="desktop-v2-profile-right-title">Socials</h1>
                  <div
                    className={
                      card.socials.google
                        ? "desktop-v2-profile-social-item-active"
                        : "desktop-v2-profile-social-item"
                    }
                  >
                    <div className="desktop-v2-profile-verifications-social-icon-wrapper">
                      {card.socials.google ? (
                        <i
                          className="fab fa-google"
                          style={{ color: "#4285F4" }}
                        />
                      ) : (
                        <i
                          className="fab fa-google"
                          style={{ color: "#b5b5b5" }}
                        />
                      )}
                    </div>
                    Google
                    {card.socials.google && (
                      <BootstrapImage
                        src={checkRedIcon}
                        className="desktop-v2-verification-icon"
                      />
                    )}
                  </div>
                  <div
                    className={
                      card.socials.facebook
                        ? "desktop-v2-profile-social-item-active"
                        : "desktop-v2-profile-social-item"
                    }
                  >
                    <div className="desktop-v2-profile-verifications-social-icon-wrapper">
                      <BootstrapImage
                        src={card.socials.facebook ? facebook : facebookGrey}
                        className="desktop-v2-profile-verifications-social-icon"
                      />
                    </div>
                    Facebook
                    {card.socials.facebook && (
                      <BootstrapImage
                        src={checkRedIcon}
                        className="desktop-v2-verification-icon"
                      />
                    )}
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(DesktopProfileV2);
