import React, { useState, useEffect, useContext } from "react";
import "./desktopgrid.scss";
import { withRouter } from "react-router-dom";
import DesktopSearchResults from "../desktopSearchResults/DesktopSearchResults";
import ClipLoader from "react-spinners/ClipLoader";
import { UserContext } from "../../../../context/UserContext";
import DesktopSearchFilters from "../desktopSearchFilters/DesktopSearchFilters";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

function DesktopGrid(props) {
  const context = useContext(UserContext);
  const [scrollLoading, setScrollLoading] = useState(true);

  const searchSubmitHandler = (e) => {
    context.sortbyDesktopSearches();
  };

  const scrollHandler = (e) => {
    if (!context.endDesktopSearches && !scrollLoading) {
      context.getMoreDesktopSearches();
      setScrollLoading(true);
    }
  };

  useEffect(() => {
    setScrollLoading(false);
  },[context.desktopSearches]);

  useBottomScrollListener(scrollHandler);

  return (
    <div className="desktop-grid-component-sb">
      <div className="desktop-search-filters-sidebar">
        <DesktopSearchFilters
          filters={props.filters}
          searchSubmit={searchSubmitHandler}
        />
      </div>
      <div className="desktop-search-results-container">
        {context.desktopSearchesLoading ? (
          <div className="desktop-search-results-loading-container">
            <ClipLoader />
          </div>
        ) : (
          <DesktopSearchResults
            cards={context.desktopSearches}
            likedUsers={context.desktopLikedUsers}
            superlikedUsers={context.desktopSuperlikedUsers}
            maxReached={context.endDesktopSearches}
            scrollLoading={scrollLoading}
          />
        )}
      </div>
    </div>
  );
}

export default withRouter(DesktopGrid);
