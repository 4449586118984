import React, { Component } from "react";
import HomeNav from "../../../components/navbar/HomeNav/HomeNav";
import HomeFooter from "../../../components/footers/homeFooter/HomeFooter";
import "./howitworks.scss";
import { Container, Button, Card } from "react-bootstrap";
import cardHeaderOne from "../../../assets/Images/sugar-daddy/header-one-sugar-daddy-card.jpg";
import cardHeaderTwo from "../../../assets/Images/sugar-daddy/header-two-sugar-daddy-card.jpg";
import cardHeaderThree from "../../../assets/Images/sugar-daddy/header-three-sugar-daddy-card.jpg";
import cardHeaderFour from "../../../assets/Images/sugar-daddy/header-four-sugar-daddy-card.jpg";
import cardHeaderFive from "../../../assets/Images/sugar-daddy/header-five-sugar-daddy-card.jpg";
import cardHeaderSix from "../../../assets/Images/sugar-daddy/header-six-sugar-daddy-card.jpg";
import MetaTags from "react-meta-tags";

import { Link } from "react-router-dom";
import NumberOneCta from "../../../components/ctas/numberOneCta/NumberOneCta";
import GettingStarted from "../../../components/content/gettingStarted/GettingStarted";
import Features from "../../../components/content/features/Features";
import Featuresv2 from "../../../components/content/features/Featuresv2";
import JoinNowCta from "../../../components/ctas/joinNowCta/JoinNowCta";

export class HowItWorksPage extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="how-it-works-page">
        <MetaTags>
          <title>How it Works | What is a Arrangement? | Sugarbae </title>{" "}
          <meta
            name="description"
            content="Arrangement Dating 101 - Everything you need to know about the Arranagement Lifestyle. What is an arrangement? How to Find a Successful Man or Attractive Woman? Learn now."
          />
        </MetaTags>
        <HomeNav />
        <div className="hiwp-hero">
          <Container className="hiwp-hero-container">
            <h1 className="hiwp-hero-title">Sugar Dating 101</h1>
            <p className="hiwp-hero-subtitle">
              Everything you need to know about finding an arrangement.
            </p>
            <div className="hiwp-hero-btn-wrap">
              <Link to="/sugar-daddy" className="hiwp-hero-link">
                <Button variant="primary" className="hiwp-hero-btn">
                  Find a Sugar Daddy
                </Button>
              </Link>
              <Link to="/sugar-baby" className="hiwp-hero-link">
                <Button variant="primary" className="hiwp-hero-btn">
                  Find an Sugar Baby
                </Button>
              </Link>
            </div>
            <div className="hiwp-learn-more-wrapper">
              <p className="hiwp-learn-more">Learn More</p>
              <i className="fas fa-chevron-down hiwp-learn-more-down-arrow"></i>
            </div>
          </Container>
        </div>

        <div className="hiwp-card-section-wrap">
          <div className="hiwp-container">
            <Card border="dark" className="hiwp-card">
              <Card.Img variant="top" src={cardHeaderOne} />
              <Card.Body>
                <Card.Title>
                  <h3 className="sd-mc-cardtitle">WHAT IS AN ARRANGEMENT?</h3>
                </Card.Title>
                <Card.Text>
                  A sugar relationship, commonly known as an arrangement, is
                  when two people communicate upfront about their needs,
                  boundaries and expectations to form a fulfilling, transparent
                  and mutually beneficial relationship.
                </Card.Text>
                <div className="sd-btn-wrap">
                  <Link to="/arrangement">
                    <Button variant="outline-dark">Learn More</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
            <Card border="dark" className="hiwp-card">
              <Card.Img variant="top" src={cardHeaderSix} />
              <Card.Body>
                <Card.Title>
                  <h3 className="sd-mc-cardtitle">WHAT IS A SUGAR DADDY?</h3>
                </Card.Title>
                <Card.Text>
                  A sugar daddy is an experienced, accomplished, and generous
                  individual who doesn’t mind sharing his fortune, experience,
                  and resources with a transparent and worthwhile companion.
                  He’s driven, and enjoys providing for attractive women -
                  including gifts, allowances, and a luxury lifestyle.
                </Card.Text>
                <div className="sd-btn-wrap">
                  <Link to="/sugar-daddy">
                    <Button variant="outline-dark">Learn More</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
            <Card border="dark" className="hiwp-card">
              <Card.Img variant="top" src={cardHeaderTwo} />
              <Card.Body>
                <Card.Title>
                  <h3 className="sd-mc-cardtitle">WHAT IS A SUGAR BABY?</h3>
                </Card.Title>
                <Card.Text>
                  A sugar baby is a gorgeous, caring, and attentive female. She
                  understands how hard successful men work, and what they are
                  looking for in a relationship. She is eager to keep her man
                  satisfied and looking to grow both financially and
                  intellectually under his wings.
                </Card.Text>
                <div className="sd-btn-wrap">
                  <Link to="/sugar-baby">
                    <Button variant="outline-dark">Learn More</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
            <Card border="dark" className="hiwp-card">
              <Card.Img variant="top" src={cardHeaderThree} />
              <Card.Body>
                <Card.Title>
                  <h3 className="sd-mc-cardtitle">WHAT ARE THE BENEFITS?</h3>
                </Card.Title>
                <Card.Text>
                  Arrangements have many benefits that are not available in a
                  traditional dating experience. Many men seek arranagements
                  that are no drama, no strings attached, and are mutually
                  beneficial. Expectations are clearly defined up front. There
                  are no games and both parties cut straight to the chase,
                  resulting in a mutually beneficial relationship where everyone
                  is satisfied.
                </Card.Text>
                <div className="sd-btn-wrap">
                  <Link to="/arrangement">
                    <Button variant="outline-dark">Learn More</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
            <Card border="dark" className="hiwp-card">
              <Card.Img variant="top" src={cardHeaderFive} />
              <Card.Body>
                <Card.Title>
                  <h3 className="sd-mc-cardtitle">
                    HOW DO I FIND A SUGAR DADDY?
                  </h3>
                </Card.Title>
                <Card.Text>
                  Luckily, our dating app has made it very easy to find a
                  successful man. On Sugarbae, you can set up your profile,
                  choose your benefits, and meet generous successful members in
                  less than 5 minutes. Sign up is 100% free.
                </Card.Text>
                <div className="sd-btn-wrap">
                  <Link to="/sugar-daddy">
                    <Button variant="outline-dark">Learn More</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
            <Card border="dark" className="hiwp-card">
              <Card.Img variant="top" src={cardHeaderFour} />
              <Card.Body>
                <Card.Title>
                  <h3 className="sd-mc-cardtitle">
                    HOW DO I FIND A SUGAR BABY?
                  </h3>
                </Card.Title>
                <Card.Text>
                  Luckily, our app has made it very easy to find an attractive
                  female member. On Sugarbae, you can set up your profile,
                  choose your benefits, and meet attractive females in less than
                  5 minutes. Sign up is 100% free.
                </Card.Text>
                <div className="sd-btn-wrap">
                  <Link to="/sugar-baby">
                    <Button variant="outline-dark">Learn More</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <Featuresv2 />
        <JoinNowCta />
        <HomeFooter />
      </div>
    );
  }
}

export default HowItWorksPage;
