import React, { Component } from "react";
import Welcome from "../../components/welcome/Welcome";

export class WelcomePage extends Component {
  render() {
    let vh = window.innerHeight;
    var height = vh + "px";

    return (
      <div
        className="app-component"
        style={{ height: height, maxHeight: height }}
      >
        {" "}
        <Welcome />
      </div>
    );
  }
}

export default WelcomePage;
