import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import ReportBug from "../../../support/supportComponents/reportBugForm/ReportBug";
import ReportUser from "../../../support/supportComponents/reportUserForm/ReportUser";
import SupportDesktop from "../../../support/supportDesktop/SupportDesktop";
import { UserContext } from "../../../../context/UserContext";

function Help(props) {
  const context = useContext(UserContext);
  const [faqTabsOpen, setFaqTabsOpen] = useState({
    cancelSubscription: false,
    reportBug: false,
    reportUser: false,
    requestRefund: false,
    otherQuestion: false,
  });
  const history = useHistory();
  useEffect(() => {
    intercomFunction();

    return () => {
      const w = window;
      const ic = w.Intercom;
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    };
  }, []);

  const openIntercomHandler = (e) => {
    window.Intercom("update", {
      hide_default_launcher: false,
    });
    window.Intercom("show");
  };

  const intercomFunction = (e) => {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      const d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      const l = function () {
        const s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nnlt04yu";
        const x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }

    window.Intercom("boot", {
      app_id: "nnlt04yu",
      username: context.user.username, // Full name
      email: context.user.email, // Email address
    });
    window.Intercom("update", {
      hide_default_launcher: false,
    });
  };

  const faqToggleHandler = (props) => (e) => {
    if (props === "cancelSubscription") {
      if (faqTabsOpen.cancelSubscription) {
        setFaqTabsOpen({
          ...faqTabsOpen,
          cancelSubscription: false,
        });
      } else {
        setFaqTabsOpen({
          ...faqTabsOpen,
          cancelSubscription: true,
        });
      }
    }

    if (props === "reportBug") {
      if (faqTabsOpen.reportBug) {
        setFaqTabsOpen({
          ...faqTabsOpen,
          reportBug: false,
        });
      } else {
        setFaqTabsOpen({
          ...faqTabsOpen,
          reportBug: true,
        });
      }
    }

    if (props === "reportUser") {
      if (faqTabsOpen.reportUser) {
        setFaqTabsOpen({
          ...faqTabsOpen,
          reportUser: false,
        });
      } else {
        setFaqTabsOpen({
          ...faqTabsOpen,
          reportUser: true,
        });
      }
    }
    if (props === "requestRefund") {
      if (faqTabsOpen.requestRefund) {
        setFaqTabsOpen({
          ...faqTabsOpen,
          requestRefund: false,
        });
      } else {
        setFaqTabsOpen({
          ...faqTabsOpen,
          requestRefund: true,
        });
      }
    }
    if (props === "otherQuestion") {
      if (faqTabsOpen.otherQuestion) {
        setFaqTabsOpen({
          ...faqTabsOpen,
          otherQuestion: false,
        });
      } else {
        setFaqTabsOpen({
          ...faqTabsOpen,
          otherQuestion: true,
        });
      }
    }
  };

  const backHandler = (e) => {
    props.backHandler();
  };

  return (
    <div className="desktop-v2-settings-content">
      <div className="desktop-v2-settings-content-header">
        <div className="desktop-v2-settings-back-button" onClick={backHandler}>
          <i
            className="far fa-long-arrow-alt-left"
            style={{ marginRight: "5px" }}
          />
          <span>Back</span>
        </div>
        <h1 className="desktop-v2-settings-content-header-title">
          Help & Support
        </h1>
      </div>
      <div>
        <div className="settings-v2-content">
          <div className="support-v2-header">
            <h1 className="support-v2-title">SugarBae LLC - We're here to help.</h1>
            <h2 className="support-v2-subtitle">
                <p>Premium support available{" "}
                  <span style={{ color: "#C44B4B" }}>24/7</span>.
                </p>
            </h2>
            <h3 className="support-v2-subtitle">
              444 Alaska Avenue, Suite #BEW253
              Torrance, CA 90503
              USA
           </h3>
          </div>
          <div className="mobile-faq-container">
            <Button
              className="mobile-faq-button"
              size="lg"
              onClick={faqToggleHandler("reportBug")}
            >
              <div className="mobile-faq-button-content">
                <i className="fas fa-question-circle faq-support-icon" />{" "}
                <span>Report a Bug</span>
              </div>
              <div className="mobile-faq-button-content">
                {faqTabsOpen.reportBug ? (
                  <i className="fas fa-minus right-arrow-button-icon" />
                ) : (
                  <i className="fas fa-plus right-arrow-button-icon" />
                )}{" "}
              </div>
            </Button>
            {faqTabsOpen.reportBug && (
              <div className="mobile-faq-tab-dropdown-form">
                <div className="mobile-faq-tab-dropdown-content-container">
                  <ReportBug />
                </div>
              </div>
            )}
            <Button
              className="mobile-faq-button"
              size="lg"
              onClick={faqToggleHandler("reportUser")}
            >
              <div className="mobile-faq-button-content">
                <i className="fas fa-question-circle faq-support-icon" />{" "}
                <span>Report a User</span>
              </div>
              <div className="mobile-faq-button-content">
                {faqTabsOpen.reportUser ? (
                  <i className="fas fa-minus right-arrow-button-icon" />
                ) : (
                  <i className="fas fa-plus right-arrow-button-icon" />
                )}{" "}
              </div>
            </Button>
            {faqTabsOpen.reportUser && (
              <div className="mobile-faq-tab-dropdown-form">
                <div className="mobile-faq-tab-dropdown-content-container">
                  <ReportUser />
                </div>
              </div>
            )}
            <Button
              className="mobile-faq-button"
              size="lg"
              onClick={faqToggleHandler("cancelSubscription")}
            >
              <div className="mobile-faq-button-content">
                <i className="fas fa-question-circle faq-support-icon" />{" "}
                <span>Cancel Subscription</span>
              </div>
              <div className="mobile-faq-button-content">
                {faqTabsOpen.cancelSubscription ? (
                  <i className="fas fa-minus right-arrow-button-icon" />
                ) : (
                  <i className="fas fa-plus right-arrow-button-icon" />
                )}
              </div>
            </Button>
            {faqTabsOpen.cancelSubscription && (
              <div className="mobile-faq-tab-dropdown">
                <div className="mobile-faq-tab-dropdown-content-container">
                  <h4 className="mobile-faq-tab-content-title">
                    How can I cancel my Sugarbae Premium subscription?
                  </h4>
                  <p className="mobile-faq-tab-content">
                    You can cancel your subscription by going to Profile >
                    Settings > Manage Subscription and then clicking "Cancel
                    Subscription". <br /> <br />
                    If you are having trouble cancelling your subscription you
                    can contact us via Live Chat on this page or email us at
                    support@sugarbae.com.
                  </p>
                </div>
              </div>
            )}
            <Button
              className="mobile-faq-button"
              size="lg"
              onClick={faqToggleHandler("requestRefund")}
            >
              <div className="mobile-faq-button-content">
                <i className="fas fa-question-circle faq-support-icon" />{" "}
                <span>Request a Refund</span>
              </div>
              <div className="mobile-faq-button-content">
                {faqTabsOpen.requestRefund ? (
                  <i className="fas fa-minus right-arrow-button-icon" />
                ) : (
                  <i className="fas fa-plus right-arrow-button-icon" />
                )}{" "}
              </div>
            </Button>
            {faqTabsOpen.requestRefund && (
              <div className="mobile-faq-tab-dropdown">
                <div className="mobile-faq-tab-dropdown-content-container">
                  <h4 className="mobile-faq-tab-content-title">
                    Refund Requests for Premium Subscription.
                  </h4>
                  <p className="mobile-faq-tab-content">
                    We normally do not offer refunds on Premium subscriptions.
                    In extreme circumstances we can make an exception. Please
                    email support@sugarbae.com
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="support-v2-contact-us-section">
            <h1 className="support-v2-contact-title">Contact Us</h1>
            <p className="support-v2-contact-subtitle">
              Contact us thorugh one of our support channels.
            </p>
            <Button
              className="mobile-faq-button"
              size="lg"
              onClick={openIntercomHandler}
            >
              <div className="mobile-faq-button-content">
                <i className="far fa-comments-alt faq-support-icon" />{" "}
                <span>Live Chat Support</span>
              </div>
            </Button>
            <Button className="mobile-faq-button" size="lg">
              <div className="mobile-faq-button-content">
                <i className="far fa-envelope faq-support-icon" />{" "}
                <span>Email support@sugarbae.com</span>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
