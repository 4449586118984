import React, { useState, useContext, useEffect } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Image as BootstrapImage, Alert } from 'react-bootstrap';
import GoogleMapReact from 'google-map-react';
import { withRouter, useHistory } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import DesktopNavV2 from '../desktopNavV2/DesktopNavV2';
import DesktopSmallNavV2 from '../desktopNavV2/DesktopSmallNavV2';
import DesktopSidebarV2 from '../desktopSidebarV2/DesktopSidebarV2';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { UserContext } from '../../../../context/UserContext';
import verificationIcon from '../../../../assets/Icons/badge_verification.svg';
import DesktopPhotoGridV2 from '../desktopPhotoGridV2/DesktopPhotoGridV2';
import { api } from '../../../../api';
import About from '../../../profile/profileV2Components/about/About';
import ProfileV2Footer from '../../../profile/profileV2Components/footer/ProfileV2Footer';
import LookingFor from '../../../profile/profileV2Components/lookingFor/LookingFor';
import './desktopuserstyles.scss';
import Diamonds from '../../../userProfile/userProfileV2Components/diamonds/Diamonds';
import VerificationsProgress from '../../../userProfile/userProfileV2Components/verificationsProgress/VerificationsProgress';
import Boost from '../../../userProfile/userProfileV2Components/boost/Boost';
import BoostStatsModal from '../../../modals/boostStatsModal/BoostStatsModal';
import BoostModal from '../../../modals/boostModal/BoostModal';
import VerificationModal from '../../../verifications/VerificationModal';
import checkRedIcon from '../../../../assets/Icons/checkRedIcon.png';
import facebook from '../../../../assets/Icons/social/facebook.png';
import facebookGrey from '../../../../assets/Icons/social/facebookGrey.png';
import instagram from '../../../../assets/Icons/social/instagram.png';
import instagramGrey from '../../../../assets/Icons/social/instagramGrey.png';
import linkedin from '../../../../assets/Icons/social/linkedin.png';
import linkedinGrey from '../../../../assets/Icons/social/linkedinGrey.png';
import twitter from '../../../../assets/Icons/social/twitter.png';
import twitterGrey from '../../../../assets/Icons/social/twitterGrey.png';
import facebookWhite from '../../../../assets/Icons/social/facebookWhite.png';
import instagramWhite from '../../../../assets/Icons/social/instagramWhite.png';
import twitterWhite from '../../../../assets/Icons/social/twitterWhite.png';
import linkedinWhite from '../../../../assets/Icons/social/linkedinWhite.png';
import DesktopAbout from '../desktopProfileV2/DesktopAbout';
import DesktopPersonalInfo from '../desktopProfileV2/DesktopPersonalInfo';
import DesktopUserAbout from './DesktopUserAbout';
import DesktopLookingFor from './DesktopLookingFor';
import DesktopUserLocation from './DesktopUserLocation';
import DesktopUserPersonalInfo from './DesktopUserPersonalInfo';
import PremiumModal from '../../../modals/premiumModal/PremiumModal';
import DesktopBenefits from './DesktopBenefits';
import Media from '../../../../components/media/Media';

function DesktopUserProfileV2(props) {
  const context = useContext(UserContext);
  const history = useHistory();

  const [previewCoordinates, setPreviewCoordinates] = useState([]);
  const [showMapPreview, setShowMapPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [boostOpen, setBoostOpen] = useState(false);
  const [boostStatsOpen, setBoostStatsOpen] = useState(false);
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [clicked, setClicked] = useState(false);

  const clientID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

  let mainImage = null;
  if (context.user.imgurl.length > 0) {
    mainImage = context.user.imgurl[0];
  }

  let verified = false;
  if (
    context.user.verifications.email.status &&
    context.user.verifications.phone.status
  ) {
    verified = true;
  }

  useEffect(() => {
    fetchCoordinates(context.user.address);
  }, [context.user.address]);

  const toggleBoost = (e) => {
    if (context.user.premium) {
      if (context.boostData.boosted) {
        if (boostStatsOpen) {
          setBoostStatsOpen(false);
        } else {
          setBoostStatsOpen(true);
        }
      } else if (boostOpen) {
        setBoostOpen(false);
      } else {
        setBoostOpen(true);
      }
    } else {
      setPremiumModalOpen(true);
    }
  };

  const updateHandler = (e) => {
    fetchCoordinates(e);
  };

  const fetchCoordinates = async (e) => {
    setShowMapPreview(false);
    const data = {
      address: e,
    };

    await api.fetchAddressCoordinates(data).then((res) => {
      if (res.status === 200) {
        setPreviewCoordinates(res.data.coordinates);
        setShowMapPreview(true);
      }
    });
  };

  const inValid = () => {
    setShowWarning(true);
  };

  const verificationToggle = (e) => {
    if (verificationModalOpen) {
      setVerificationModalOpen(false);
    } else {
      setVerificationModalOpen(true);
    }
  };

  const idVerified = false;

  const googleHandler = async (e) => {
    const { profileObj, googleId } = e;
    const { email } = profileObj;

    if (profileObj && email) {
      if (context.user.email) {
        if (context.user.email === email) {
          const data = {
            option: 'google',
            email,
          };
          return api.connectSocialNetwork(data).then(() => {
            context.updateUserInfo('socials', 'google');
            checkHandler();
          });
        }
        inValid();
      } else {
        const emailData = { email };
        let emailExists = false;
        await api
          .checkEmail(emailData)
          .then((res) => {
            const { valid } = res.data;
            if (valid === false) {
              emailExists = true;
            }
          })
          .catch((err) => {});

        if (emailExists) {
          return inValid();
        }
        const data = {
          option: 'google',
          email,
        };
        return api.connectSocialNetwork(data).then(() => {
          context.updateUserInfo('socials', 'google');
          context.loadContext();
          checkHandler();
        });
      }
    } else {
      return inValid();
    }
  };
  const googleHandlerFailure = (e) => {};

  const connectFacebookHander = (e) => {};
  const connectGoogleHandler = async (e) => {
    const { profileObj, googleId } = e;
    const { email } = profileObj;
  };

  const checkHandler = () => {
    setRefresh(true);
  };

  const responseFacebook = async (e) => {
    if (clicked) {
      const { email, id } = e;

      if (email) {
        if (context.user.email === email) {
          const data = {
            option: 'google',
            email,
          };
          return api.connectSocialNetwork(data).then(() => {
            context.updateUserInfo('socials', 'google');
            checkHandler();
          });
        }
        inValid();
      } else {
        const emailData = { email };
        let emailExists = false;
        await api
          .checkEmail(emailData)
          .then((res) => {
            const { valid } = res.data;
            if (valid === false) {
              emailExists = true;
            }
          })
          .catch((err) => {});

        if (emailExists) {
          return inValid();
        }
        const data = {
          option: 'facebook',
          email,
        };
        return api.connectSocialNetwork(data).then(() => {
          context.updateUserInfo('socials', 'facebook');
          context.loadContext();
          checkHandler();
        });
      }
    } else {
      inValid();
    }
  };

  const clickedComponent = () => {
    setClicked(true);
  };

  return (
    <div className="desktop-v2-container">
      {boostOpen && <BoostModal onClose={toggleBoost} show={boostOpen} />}
      {boostStatsOpen && (
        <BoostStatsModal onClose={toggleBoost} show={boostStatsOpen} />
      )}
      {verificationModalOpen && (
        <VerificationModal
          closeHandler={verificationToggle}
          show={verificationModalOpen}
          desktop
        />
      )}
      {premiumModalOpen && (
        <PremiumModal
          onClose={() => setPremiumModalOpen(false)}
          show={premiumModalOpen}
          option="boost"
        />
      )}

      <DesktopSidebarV2 />
      <div className="desktop-v2-content">
        <div className="desktop-v2-profile-content">
          <div className="desktop-v2-profile-main">
            <div className="desktop-v2-profile-user-header">
              <div className="desktop-v2-profile-user-header-section">
                <div className="desktop-v2-profile-user-image-wrapper">
                  <Media image={mainImage} />
                  {/* <Image
                    publicId={
                      mainImage
                        ? getImgName(mainImage)
                        : "larger-placeholder-user-image_vvdghq.png"
                    }
                    version={
                      mainImage ? getImgVersion(mainImage) : "1590046357"
                    }
                    resource_type={
                      mainImage
                        ? checkGIF(mainImage)
                          ? "video"
                          : "image"
                        : null
                    }
                    effect="loop"
                    cloud_name="sugarbae"
                    className="desktop-v2-profile-user-image"
                    fluid
                  >
                    <Transformation
                      height="200"
                      video_sampling="20"
                      delay="250"
                      duration="10"
                      effect="loop"
                      quality="auto"
                      flags="lossy"
                    />
                  </Image> */}
                </div>
                <div className="desktop-v2-profile-header-user-info">
                  <div>
                    <div className="desktop-v2-profile-user-info-username-age-wrap">
                      <h1 className="desktop-v2-profile-header-username">
                        {context.user.username},
                      </h1>
                      <p className="desktop-v2-profile-header-age">
                        {context.user.age}
                      </p>
                      {verified && (
                        <BootstrapImage
                          src={verificationIcon}
                          className="desktop-v2-profile-header-verified-icon"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="desktop-v2-profile-user-header-section">
                <div className="desktop-v2-profile-user-header-right-side" />
              </div>
            </div>
            <div className="desktop-v2-profile-user-content-container">
              <DesktopPhotoGridV2
                card={context.user}
                loading={false}
                isOwnProfile
              />
              <div
                className="desktop-v2-profile-user-content-section"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div className="desktop-v2-profile-user-profile-box">
                  <Diamonds />
                </div>
                <div className="desktop-v2-profile-user-profile-box">
                  <VerificationsProgress
                    desktop
                    verificationToggle={verificationToggle}
                  />
                </div>
                <div className="desktop-v2-profile-user-profile-box">
                  <Boost toggleBoost={toggleBoost} />
                </div>
              </div>
              <div className="desktop-v2-profile-user-content-section">
                <DesktopUserAbout card={context.user} />
              </div>
              <DesktopUserLocation
                card={context.user}
                previewCoordinates={previewCoordinates}
                showMapPreview={showMapPreview}
                updateHandler={updateHandler}
              />
              <div className="desktop-v2-profile-user-content-section">
                <DesktopLookingFor card={context.user} />
              </div>
              <div className="desktop-v2-profile-user-content-section">
                <DesktopBenefits card={context.user} />
              </div>
              <div className="desktop-v2-profile-user-content-section">
                <DesktopUserPersonalInfo card={context.user} />
              </div>
            </div>
          </div>
          <div className="desktop-v2-profile-right">
            <DesktopSmallNavV2 />
            <div className="desktop-v2-profile-right-content">
              <div className="desktop-v2-profile-right-side-section">
                <h1 className="desktop-v2-profile-right-title">
                  Verifications
                </h1>
                <div
                  className={
                    idVerified
                      ? 'desktop-v2-verification-item-active'
                      : 'desktop-v2-verification-item'
                  }
                >
                  ID Verified
                  {idVerified && (
                    <BootstrapImage
                      src={checkRedIcon}
                      className="desktop-v2-verification-icon"
                    />
                  )}
                </div>
                {/* <div
                  className={
                    idVerified
                      ? "desktop-v2-verification-item-active"
                      : "desktop-v2-verification-item"
                  }
                >
                  Net Worth
                  {idVerified && (
                    <BootstrapImage
                      src={checkRedIcon}
                      className="desktop-v2-verification-icon"
                    />
                  )}
                </div> */}
              </div>
              <div className="desktop-v2-profile-right-side-section">
                <h1 className="desktop-v2-profile-right-title">Socials</h1>
                {showWarning && (
                  <Alert variant="warning">
                    Social media connections must use the same email address as
                    your Sugarbae account.
                  </Alert>
                )}
                {context.user.socials.google ? (
                  <div className="desktop-v2-profile-social-item-active">
                    <div className="desktop-v2-profile-verifications-social-icon-wrapper no-margin">
                      <i
                        className="fab fa-google"
                        style={{ color: '#4285F4' }}
                      />
                    </div>
                    Google
                    <BootstrapImage
                      src={checkRedIcon}
                      className="desktop-v2-verification-icon"
                    />
                  </div>
                ) : (
                  <GoogleLogin
                    clientId={clientID}
                    onSuccess={googleHandler}
                    onFailure={googleHandlerFailure}
                    icon={false}
                    render={(renderProps) => (
                      <div
                        className="desktop-v2-profile-social-item-connect"
                        onClick={renderProps.onClick}
                      >
                        <div className="desktop-v2-profile-verifications-social-icon-wrapper no-margin">
                          <i
                            className="fab fa-google"
                            style={{ color: '#fff' }}
                          />
                        </div>
                        Connect
                      </div>
                    )}
                  />
                )}
                {context.user.socials.facebook ? (
                  <div className="desktop-v2-profile-social-item-active">
                    <div className="desktop-v2-profile-verifications-social-icon-wrapper">
                      <BootstrapImage
                        src={facebook}
                        className="desktop-v2-profile-verifications-social-icon"
                      />
                    </div>
                    Facebook
                    <BootstrapImage
                      src={checkRedIcon}
                      className="desktop-v2-verification-icon"
                    />
                  </div>
                ) : (
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID}
                    fields="name,email,picture"
                    onClick={clickedComponent}
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <div
                        className="desktop-v2-profile-social-item-connect"
                        onClick={renderProps.onClick}
                      >
                        <div className="desktop-v2-profile-verifications-social-icon-wrapper">
                          <BootstrapImage
                            src={facebookWhite}
                            className="desktop-v2-profile-verifications-social-icon"
                          />
                        </div>
                        Connect
                      </div>
                    )}
                  />
                )}
                {/* {socialConnections.instagram ? (
                  <div className="desktop-v2-profile-social-item-active">
                    <div className="desktop-v2-profile-verifications-social-icon-wrapper">
                      <BootstrapImage
                        src={instagram}
                        className="desktop-v2-profile-verifications-social-icon"
                      />
                    </div>
                    Instagram
                    <BootstrapImage
                      src={checkRedIcon}
                      className="desktop-v2-verification-icon"
                    />
                  </div>
                ) : (
                  <div className="desktop-v2-profile-social-item-connect">
                    <div className="desktop-v2-profile-verifications-social-icon-wrapper">
                      <BootstrapImage
                        src={instagramWhite}
                        className="desktop-v2-profile-verifications-social-icon"
                      />
                    </div>
                    Connect
                  </div>
                )} */}
                {/* {socialConnections.twitter ? (
                  <div className="desktop-v2-profile-social-item-active">
                    <div className="desktop-v2-profile-verifications-social-icon-wrapper">
                      <BootstrapImage
                        src={twitter}
                        className="desktop-v2-profile-verifications-social-icon"
                      />
                    </div>
                    Twitter
                    <BootstrapImage
                      src={checkRedIcon}
                      className="desktop-v2-verification-icon"
                    />
                  </div>
                ) : (
                  <div className="desktop-v2-profile-social-item-connect">
                    <div className="desktop-v2-profile-verifications-social-icon-wrapper">
                      <BootstrapImage
                        src={twitterWhite}
                        className="desktop-v2-profile-verifications-social-icon"
                      />
                    </div>
                    Connect
                  </div>
                )} */}
                {/* {socialConnections.linkedin ? (
                  <div className="desktop-v2-profile-social-item-active">
                    <div className="desktop-v2-profile-verifications-social-icon-wrapper">
                      <BootstrapImage
                        src={linkedin}
                        className="desktop-v2-profile-verifications-social-icon"
                      />
                    </div>
                    LinkedIn
                    <BootstrapImage
                      src={checkRedIcon}
                      className="desktop-v2-verification-icon"
                    />
                  </div>
                ) : (
                  <div className="desktop-v2-profile-social-item-connect">
                    <div className="desktop-v2-profile-verifications-social-icon-wrapper">
                      <BootstrapImage
                        src={linkedinWhite}
                        className="desktop-v2-profile-verifications-social-icon"
                      />
                    </div>
                    Connect
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(DesktopUserProfileV2);
