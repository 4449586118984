export const getImgName = imgURL => {
  var entities = imgURL.split("/");
  if (entities[entities.length - 2] === "Assets") {
    return `Assets/${entities[entities.length - 1]}`;
  }
  if (entities[entities.length - 1].slice(-3) !== "gif") {
    return `images/${entities[entities.length - 1]}`;
  } else {
    return `videos/${entities[entities.length - 1]}`;
  }
};

export const getImgVersion = imgURL => {
  var entities = imgURL.split("/");
  var imgVersion = entities[entities.length - 3].slice(1);

  return imgVersion;
};

export const checkGIF = imgURL => {
  var extension = imgURL.slice(-3);

  return extension === "gif" ? true : false;
}