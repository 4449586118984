import React, { Component } from "react";
import "./usercardactions.scss";
import { Card } from "react-bootstrap";

export class UserCardActions extends Component {
  actionsHandler = (props) => async (e) => {
    if (props === "like") {
      this.props.actionsHandler("like");
    }

    if (props === "undo") {
      this.props.actionsHandler("undo");
    }

    if (props === "superlike") {
      if (!this.props.liked) {
        this.props.actionsHandler("superlike");
      }
    }
    if (props === "note") {
      this.props.actionsHandler("note");
    }
  };

  render() {
    let vhh = window.innerHeight;
    let vhw = window.innerWidth - 16;
    var buttonWrapWidth = vhw / 2;
    var buttonWrapHeight = buttonWrapWidth;

    var buttonStyle = {
      height: buttonWrapHeight,
      width: buttonWrapWidth,
      maxHeight: "55px",
      maxWidth: "55px",
    };
    return (
      <Card className="user-card-actions-card">
        <Card.Header className="user-card-actions-header">
          <div className="user-card-actions-btn-wrapper" style={buttonStyle}>
            <div
              className="user-card-actions-btn"
              onClick={this.actionsHandler("note")}
            >
              <i className="fas fa-comment-alt-lines user-card-actions-btn-icon message-btn-icon"></i>
            </div>
          </div>
          {this.props.liked ? (
            <div className="user-card-actions-btn-wrapper" style={buttonStyle}>
              <div
                className="user-card-actions-btn"
                onClick={this.actionsHandler("undo")}
              >
                <i className="fas fa-undo user-card-actions-btn-icon undo-btn-icon"></i>
              </div>
            </div>
          ) : (
            <div className="user-card-actions-btn-wrapper" style={buttonStyle}>
              <div
                className="user-card-actions-btn"
                onClick={this.actionsHandler("like")}
              >
                <i className="fas fa-heart user-card-actions-btn-icon favorite-btn-icon"></i>
              </div>
            </div>
          )}
        </Card.Header>
      </Card>
    );
  }
}

export default UserCardActions;
