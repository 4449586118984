import React, { Component } from "react";
import "../help.scss";
import FaqGrid from "../../../components/faqGrid/FaqGrid";
import SwipeNav from "../../../components/navbar/SwipeNav";

export class Faq extends Component {
  render() {
    let vh = window.innerHeight;
    var height = vh + "px";
    return (
      <div className="help-container">
        <SwipeNav />
        <div className="help-page-container">
          <div className="help-page-content-wrap">
            <h1 className="help-page-title">FAQ / Help</h1>
            <FaqGrid />
            <div className="below-faq-wrapper">
              <p>
                Unable to find an answer to your question? Contact us at
                <span className="email-highlight-faq">
                  support@sugarbae.com
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Faq;
