import React, { useState, useContext } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { UserContext } from "../../../../context/UserContext";
import PremiumFeatures from "../../../carousels/PremiumFeatures";
import { api } from "../../../../api";

const moment = require("moment");

function ManageSubscription(props) {
  const context = useContext(UserContext);
  const { subscription } = context.user;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [cancelError, setCancelError] = useState(false);

  const toggleConfirmModal = (e) => {
    if (confirmModalOpen) {
      setConfirmModalOpen(false);
    } else {
      setConfirmModalOpen(true);
    }
  };

  const cancelSubscriptionHandler = (e) => {
    toggleConfirmModal();
    setCancelLoading(true);
    const data = {
      userid: context.user._id,
    };

    return api
      .cancelSubscription(data)
      .then((res) => {
        if (res.status === 200) {
          const { successfulCancel } = res.data;
          if (successfulCancel) {
            const tempSubscription = context.user.subscription;
            tempSubscription.active = false;
            context.updateUserInfo("subscription", tempSubscription);

            setCancelSuccess(true);
            setCancelError(false);
            setCancelLoading(false);
          } else {
            setCancelSuccess(false);
            setCancelError(true);
            setCancelLoading(false);
          }
        } else {
          setCancelSuccess(false);
          setCancelError(true);
          setCancelLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let nextBillingDate = null;

  if (subscription) {
    let { startDate } = subscription;

    if (startDate) {
      startDate = moment(startDate).format("MMMM Do YYYY");
    }

    const { active } = subscription;
    if (active) {
      var status = "Active";
    } else {
      var status = "Not Active";
    }
    const { plan } = subscription;

    if (plan === "monthly") {
      nextBillingDate = moment(subscription.startDate)
        .add(1, "month")
        .format("MMMM Do YYYY");
    } else if (plan === "semiannual") {
      nextBillingDate = moment(subscription.startDate)
        .add(180, "days")
        .format("MMMM Do YYYY");
    } else if (plan === "annual") {
      nextBillingDate = moment(subscription.startDate)
        .add(365, "days")
        .format("MMMM Do YYYY");
    }

    const { alert } = subscription;

    const { currency } = subscription;
    const { billingPrice } = subscription;
  }

  return (
    <div className="desktop-v2-settings-content">
      {cancelLoading && (
        <div className="premium-checkout-loading-overlay">
          <ClipLoader color="white" />
        </div>
      )}
      {confirmModalOpen && (
        <Modal
          show={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          className="modal-v2"
          centered
        >
          <Modal.Body className="modal-v2-body-deactivate">
            <i className="far fa-exclamation-circle number-alert-icon-confirm" />
            <h1 className="confirm-modal-title">Are you sure?</h1>
          </Modal.Body>
          <Modal.Footer className="modal-v2-footer">
            <Button
              className="modal-action-button"
              onClick={cancelSubscriptionHandler}
            >
              Yes, cancel my subscription
            </Button>
            <Button
              className="modal-close-button"
              onClick={() => setConfirmModalOpen(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <div className="desktop-v2-settings-row">
        <div className="desktop-v2-settings-row-innerwrap">
          <div className="desktop-v2-settings-row-section">
            <span className="desktop-v2-settings-row-title">Status</span>
          </div>
          <div className="desktop-v2-settings-row-section">
            {subscription.active ? (
              <span className="desktop-v2-settings-active-text">Active</span>
            ) : (
              <span className="desktop-v2-settings-plan-text">Not Active</span>
            )}
          </div>
        </div>
      </div>
      <div className="desktop-v2-settings-row">
        <div className="desktop-v2-settings-row-innerwrap">
          <div className="desktop-v2-settings-row-section">
            <span className="desktop-v2-settings-row-title">Plan</span>
          </div>
          <div className="desktop-v2-settings-row-section">
            <span className="desktop-v2-settings-plan-text">
              {subscription.plan}
            </span>
          </div>
        </div>
      </div>
      <div className="desktop-v2-settings-row">
        <div className="desktop-v2-settings-row-innerwrap">
          <div className="desktop-v2-settings-row-section">
            {subscription.active ? (
              <span className="desktop-v2-settings-row-title">
                Next Billing Date
              </span>
            ) : (
              <span className="desktop-v2-settings-row-title">
                Subscription End Date
              </span>
            )}
          </div>
          <div className="desktop-v2-settings-row-section">
            <span className="desktop-v2-settings-plan-text">
              {nextBillingDate}
            </span>
          </div>
        </div>
      </div>
      {cancelSuccess && (
        <Alert variant="success">
          You have successfully cancelled your subscription & will not be billed
          moving forward. You can resume your subscription at any time. Sorry to
          see you go!
        </Alert>
      )}
      {cancelError && (
        <Alert variant="warning">
          There was an error cancelling your subscription. Please try again or
          contact support.
        </Alert>
      )}
      <div className="desktop-v2-settings-content-footer">
        {!context.user.premium && !subscription.active && <PremiumFeatures />}
        {context.user.premium || subscription.active ? (
          <div className="edit-screen-bottom-contact-wrapper">
            <h1 className="edit-screen-bottom-contact-title">
              Contact Support
            </h1>
            <p className="edit-screen-bottom-contact-subtitle">
              support@sugarbae.com
            </p>
          </div>
        ) : null}
        {subscription.active && (
          <div
            className="desktop-v2-settings-cancel-subscription-button"
            onClick={() => setConfirmModalOpen(true)}
          >
            Cancel Subscription
          </div>
        )}
      </div>
    </div>
  );
}

export default ManageSubscription;
