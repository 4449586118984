import React, { useState, useContext, useEffect } from "react";
import "../premium.scss";
import { Button, Alert } from "react-bootstrap";
import PremiumFeatures from "../../carousels/PremiumFeatures";
import { useHistory, withRouter } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { Image, Transformation } from "cloudinary-react";
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from "../../../utils/getCloudinaryInfo";
import { Image as BootstrapImage } from "react-bootstrap";
import sugarbaePlusLogo from "../../../assets/logos/sugarbae-home-icon.png";
import axios from "axios";
import { api } from "../../../api";
import ClipLoader from "react-spinners/ClipLoader";
import backgroundPattern from "../../../assets/Images/background-pattern.png";
var moment = require("moment");

function PremiumSuccess(props) {
  const context = useContext(UserContext);

  var location = props.location.pathname;
  console.log(location);

  let history = useHistory();
  var mainImage = null;

  if (context.user.imgurl) {
    if (context.user.imgurl.length > 0) {
      mainImage = context.user.imgurl[0];
    }
  }

  const ctaHandler = (e) => {
    history.push("/app/search");
  };

  var todaysDate = moment().format("MMM, Do, YYYY").toString();
  var oneMonthDate = moment()
    .add(1, "month")
    .format("MMM, Do, YYYY")
    .toString();

  var boostPurchase = false;

  if (location.includes("boost")) {
    boostPurchase = true;
    // if (!context.boostData.boosted) {
    //   api
    //     .checkBoosted()
    //     .then((res) => {})
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  }

  return (
    <div className="premium-success-component">
      <div className="premium-success-content">
        <div className="premium-success-thank-you-section">
          <h1 className="premium-success-thank-you-title">
            Hi, {context.user.username}
          </h1>
          {boostPurchase ? (
            <p className="premium-success-thank-you-subtitle">
              Thank you for purchasing your profile
              <span style={{ color: "#c44b4b" }}> boost</span>.
            </p>
          ) : (
            <p className="premium-success-thank-you-subtitle">
              Thank you for purchasing your{" "}
              <span style={{ color: "#c44b4b" }}>premium</span> subscription.
            </p>
          )}
        </div>
        <div className="premium-success-receipt-section">
          <h2 className="premium-success-receipt-title">Order Details</h2>
          <div className="premium-success-order-details-wrapper">
            <p className="premium-success-detail">
              Date of purchase:{" "}
              <span style={{ color: "#c44b4b" }}>{todaysDate}</span>
            </p>
            <p className="premium-success-detail">
              Amount Paid: <span style={{ color: "#c44b4b" }}>$19.97</span>
            </p>
            <p className="premium-success-detail">
              Next Payment Date:{" "}
              <span style={{ color: "#c44b4b" }}>{oneMonthDate}</span>
            </p>
          </div>
          <div className="premium-success-message-section">
            <p className="premium-success-message-text">
              Welcome to Sugarbae Premium, the world's best dating experience.
              We are happy to have you as part of our family.
            </p>
            <p className="premium-success-message-text">
              Enjoy your Premium benefits.
            </p>
            <p className="premium-success-message-text">Love, Sugarbae.</p>
          </div>
        </div>
      </div>
      <div className="premium-success-page-cta-v2">
        <Button
          className="premium-success-button-v2"
          variant="danger"
          size="lg"
          onClick={ctaHandler}
        >
          Search Your Match
        </Button>
      </div>
    </div>
  );
}

export default withRouter(PremiumSuccess);
