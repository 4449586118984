import React, { useState, useContext } from "react";
import "../modalv2styles.scss";
import { Modal, Button, Image, Form } from "react-bootstrap";
import { UserContext } from "../../../context/UserContext";
import getmore from "../../../assets/Images/matches/getmore.png";
import { submitBoostV2 } from "../../../helpers/swipeActions";
import { withRouter, useHistory } from "react-router-dom";

function VerificationModal(props) {
  let history = useHistory();
  const context = useContext(UserContext);
  const [show, setShow] = useState(props.show);

  const { cards } = context.swipeData;
  const imageOne = cards && cards.length > 0 && cards[0].imgurl[0];
  const imageTwo = cards && cards.length > 0 && cards[1].imgurl[0];
  const imageThree = cards && cards.length > 0 && cards[2].imgurl[0];

  const vw = window.innerWidth;

  const closeHandler = (e) => {
    setShow(false);
    props.onClose();
  };

  var mainImage = null;
  var images = context.user.imgurl;
  if (images) {
    if (images.length > 0) {
      mainImage = images[0];
    }
  }

  const verificationHandler = (e) => {
    if (vw > 991) {
      history.push("/app/profile");
    } else {
      history.push("/app/verification");
    }
  };

  var option = props.option;
  if (!option) {
    option = "default";
  }

  return (
    <Modal
      show={show}
      className="modal-v2"
      onHide={closeHandler}
      centered={true}
    >
      <Modal.Body className="modal-v2-body">
        <div className="boost-active-popup-modal">
          <div className="boost-modal-header">
            <div className="boost-modal-header-overlay" />
            <div className="verification-modal-header-wrap">
              <div className="verification-modal-safety-badge">
                <i className="far fa-lock" />
              </div>
              <Image
                src={mainImage}
                className="verification-modal-front-image"
              />
            </div>
          </div>
          <label className="boost-active-title" style={{ marginTop: "1.5rem" }}>
            Verification Required
          </label>
          <br />
          <label className="boost-active-description">
            Verify your email & phone to unlock messaging.
          </label>
          <br />
        </div>
        <div className="modal-premium-features-wrapper">
          <div className="modal-premium-feature">
            <i className="far fa-envelope verify-modal-icon"></i>
            <p className="modal-premium-feature-subtitle">Email</p>
            {context.user.verifications.email.status ? (
              <h1 className="modal-premium-feature-title">Verified</h1>
            ) : (
              <h1 className="modal-premium-feature-title">Not Verified</h1>
            )}
          </div>
          <div className="modal-premium-feature">
            <i className="fas fa-mobile verify-modal-icon"></i>
            <p className="modal-premium-feature-subtitle">Phone</p>
            {context.user.verifications.phone.status ? (
              <h1 className="modal-premium-feature-title">Not Verified</h1>
            ) : (
              <h1 className="modal-premium-feature-title">Not Verified</h1>
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="modal-v2-footer">
        <Button
          variant="secondary"
          className="modal-verify-button"
          onClick={verificationHandler}
        >
          Verify Yourself
        </Button>

        <Button className="modal-close-button" onClick={closeHandler}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default withRouter(VerificationModal);
