import React, { Component } from "react";
import { Card, Form, Button } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import "./advancedsearchfilters.scss";
import { UserContext } from "../../../../context/UserContext";
import Axios from "axios";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { AdvancedFiltersModal } from "../../../modals/advancedFiltersModal/AdvancedFiltersModal";
import { api } from "../../../../api";

var heights = [
  "4'0\"",
  "4'1\"",
  "4'2\"",
  "4'3\"",
  "4'4\"",
  "4'5\"",
  "4'6\"",
  "4'7\"",
  "4'8\"",
  "4'9\"",
  "4'10\"",
  "4'11\"",
  "5'0\"",
  "5'1\"",
  "5'2\"",
  "5'3\"",
  "5'4\"",
  "5'5\"",
  "5'6\"",
  "5'7\"",
  "5'8\"",
  "5'9\"",
  "5'10\"",
  "5'11\"",
  "6'0\"",
  "6'1\"",
  "6'2\"",
  "6'3\"",
  "6'4\"",
  "6'5\"",
  "6'6\"",
  "6'7\"",
  "6'8\"",
  "6'9\"",
  "6'10\"",
  "6'11\"",
  "7'0\"",
];

export class AdvancedSearchFilters extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        photosvideos: {
          photos: true,
          videos: false,
          verified: false,
        },
        ethnicity: {
          white: false,
          black: false,
          asian: false,
          hispanic: false,
          indian: false,
          middleeastern: false,
          other: false,
        },
        bodytype: {
          slim: false,
          fit: false,
          muscular: false,
          average: false,
          afewextrapounds: false,
          curvy: false,
          fullfigured: false,
        },
        height: [0, 36],
        smoking: {
          nonsmoker: false,
          lightsmoker: false,
          heavysmoker: false,
        },
        drinking: {
          nondrinker: false,
          socialdrinker: false,
          heavydrinker: false,
        },
        exercise: {
          never: false,
          sometimes: false,
          regularly: false,
          often: false,
          daily: false,
        },
        children: {
          nokids: false,
          onekid: false,
          twokids: false,
          threekids: false,
          fourpluskids: false,
        },
        relationshipstatus: {
          single: false,
          divorced: false,
          separated: false,
          marriedbutlooking: false,
          openrelationship: false,
          widowed: false,
        },
        education: {
          highschool: false,
          somecollege: false,
          associatesdegree: false,
          bachelorsdegree: false,
          graduatedegree: false,
          phd: false,
        },
      },
      changed: false,
      bodytypeOpen: false,
      ethnicityOpen: false,
      smokingOpen: false,
      drinkingOpen: false,
      exerciseOpen: false,
      childrenOpen: false,
      relationshipOpen: false,
      educationOpen: false,
      premium: false,
      advancedFilterModalOpen: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (e) => {
    const data = {
      userid: this.context.user._id,
    };

    return api
      .fetchFilters(data)
      .then((res) => {
        if (res.status === 200) {
          var filters = res.data.filters;
          var premium = res.data.premium;

          var bodytype = filters.bodytype;
          var ethnicity = filters.ethnicity;
          var smoking = filters.smoking;
          var drinking = filters.drinking;
          var exercise = filters.exercise;
          var children = filters.children;
          var relationshipstatus = filters.relationshipstatus;
          var education = filters.education;

          var bodytypeOpen = false;
          var ethnicityOpen = false;
          var smokingOpen = false;
          var drinkingOpen = false;
          var exerciseOpen = false;
          var childrenOpen = false;
          var relationshipOpen = false;
          var educationOpen = false;

          if (bodytype) {
            if (
              bodytype.slim ||
              bodytype.fit ||
              bodytype.muscular ||
              bodytype.fullfigured ||
              bodytype.average ||
              bodytype.curvy ||
              bodytype.afewextrapounds
            ) {
              bodytypeOpen = true;
            }
          }

          if (ethnicity) {
            if (
              ethnicity.white ||
              ethnicity.black ||
              ethnicity.asian ||
              ethnicity.hispanic ||
              ethnicity.middleeastern ||
              ethnicity.other ||
              ethnicity.indian
            ) {
              ethnicityOpen = true;
            }
          }

          if (smoking) {
            if (
              smoking.nonsmoker ||
              smoking.lightsmoker ||
              smoking.heavysmoker
            ) {
              smokingOpen = true;
            }
          }
          if (drinking) {
            if (
              drinking.nondrinker ||
              drinking.lightdrinker ||
              drinking.heavydrinker
            ) {
              drinkingOpen = true;
            }
          }

          if (exercise) {
            if (
              exercise.never ||
              exercise.daily ||
              exercise.regularly ||
              exercise.often ||
              exercise.sometimes
            ) {
              exerciseOpen = true;
            }
          }

          if (children) {
            if (
              children.fourpluskids ||
              children.nokids ||
              children.onekid ||
              children.threekids ||
              children.twokids
            ) {
              childrenOpen = true;
            }
          }

          if (relationshipstatus) {
            if (
              relationshipstatus.single ||
              relationshipstatus.marriedbutlooking ||
              relationshipstatus.widowed ||
              relationshipstatus.divorced ||
              relationshipstatus.openrelationship ||
              relationshipstatus.separated
            ) {
              relationshipOpen = true;
            }
          }

          if (education) {
            if (
              education.associatesdegree ||
              education.bachelorsdegree ||
              education.graduatedegree ||
              education.highschool ||
              education.phd ||
              education.somecollege
            ) {
              educationOpen = true;
            }
          }

          this.setState({
            filters: filters,
            premium: premium,
            bodytypeOpen: bodytypeOpen,
            ethnicityOpen: ethnicityOpen,
            smokingOpen: smokingOpen,
            drinkingOpen: drinkingOpen,
            exerciseOpen: exerciseOpen,
            childrenOpen: childrenOpen,
            relationshipOpen: relationshipOpen,
            educationOpen: educationOpen,
          });
        }
      })
      .catch((err) => {});
  };

  categoryToggle = (props) => (e) => {
    if (props === "bodytype") {
      if (this.state.bodytypeOpen) {
        this.setState({ bodytypeOpen: false });
      } else {
        this.setState({ bodytypeOpen: true });
      }
    }
    if (props === "ethnicity") {
      if (this.state.ethnicityOpen) {
        this.setState({ ethnicityOpen: false });
      } else {
        this.setState({ ethnicityOpen: true });
      }
    }
    if (props === "photosvideos") {
      if (this.state.photosOpen) {
        this.setState({ photosOpen: false });
      } else {
        this.setState({ photosOpen: true });
      }
    }
    if (props === "smoking") {
      if (this.state.smokingOpen) {
        this.setState({ smokingOpen: false });
      } else {
        this.setState({ smokingOpen: true });
      }
    }
    if (props === "drinking") {
      if (this.state.drinkingOpen) {
        this.setState({ drinkingOpen: false });
      } else {
        this.setState({ drinkingOpen: true });
      }
    }

    if (props === "exercise") {
      if (this.state.exerciseOpen) {
        this.setState({ exerciseOpen: false });
      } else {
        this.setState({ exerciseOpen: true });
      }
    }
    if (props === "children") {
      if (this.state.childrenOpen) {
        this.setState({ childrenOpen: false });
      } else {
        this.setState({ childrenOpen: true });
      }
    }

    if (props === "relationship") {
      if (this.state.relationshipOpen) {
        this.setState({ relationshipOpen: false });
      } else {
        this.setState({ relationshipOpen: true });
      }
    }
    if (props === "education") {
      if (this.state.educationOpen) {
        this.setState({ educationOpen: false });
      } else {
        this.setState({ educationOpen: true });
      }
    }
  };

  verifiedToggle = (e) => {
    if (this.state.premium) {
      var filters = this.state.filters;
      var photosvideos = filters.photosvideos;
      if (this.state.filters.photosvideos.verified) {
        photosvideos.verified = false;
      } else {
        photosvideos.verified = true;
      }
      filters.photosvideos = photosvideos;

      this.setState({ filters: filters, changed: true }, () => {
        this.saveFilters();
      });
    } else {
      this.setState({ advancedFilterModalOpen: true });
    }
  };

  handleHeightChange = (event, newValue) => {
    if (this.state.premium) {
      var filters = this.state.filters;
      filters.height = newValue;
      this.setState({ filters: filters, changed: true });
    } else {
      this.setState({ advancedFilterModalOpen: true });
    }
  };

  handleHeightSelect = (e) => {
    if (this.state.premium) {
      this.saveFilters();
    }
  };

  ethnicityToggle = (props) => (e) => {
    if (this.state.premium) {
      var filters = this.state.filters;
      var ethnicity = filters.ethnicity;

      if (props === "white") {
        if (ethnicity.white) {
          ethnicity.white = false;
        } else {
          ethnicity.white = true;
        }
      }

      if (props === "black") {
        if (ethnicity.black) {
          ethnicity.black = false;
        } else {
          ethnicity.black = true;
        }
      }

      if (props === "asian") {
        if (ethnicity.asian) {
          ethnicity.asian = false;
        } else {
          ethnicity.asian = true;
        }
      }

      if (props === "hispanic") {
        if (ethnicity.hispanic) {
          ethnicity.hispanic = false;
        } else {
          ethnicity.hispanic = true;
        }
      }

      if (props === "indian") {
        if (ethnicity.indian) {
          ethnicity.indian = false;
        } else {
          ethnicity.indian = true;
        }
      }

      if (props === "middleeastern") {
        if (ethnicity.middleeastern) {
          ethnicity.middleeastern = false;
        } else {
          ethnicity.middleeastern = true;
        }
      }

      if (props === "other") {
        if (ethnicity.other) {
          ethnicity.other = false;
        } else {
          ethnicity.other = true;
        }
      }

      filters.ethnicity = ethnicity;

      this.setState({ filters: filters, changed: true }, () => {
        this.saveFilters();
      });
    } else {
      this.setState({ advancedFilterModalOpen: true });
    }
  };

  bodytypeToggle = (props) => (e) => {
    if (this.state.premium) {
      var filters = this.state.filters;
      var bodytype = filters.bodytype;
      if (props === "slim") {
        if (bodytype.slim) {
          bodytype.slim = false;
        } else {
          bodytype.slim = true;
        }
      }

      if (props === "muscular") {
        if (bodytype.muscular) {
          bodytype.muscular = false;
        } else {
          bodytype.muscular = true;
        }
      }

      if (props === "average") {
        if (bodytype.average) {
          bodytype.average = false;
        } else {
          bodytype.average = true;
        }
      }

      if (props === "fit") {
        if (bodytype.fit) {
          bodytype.fit = false;
        } else {
          bodytype.fit = true;
        }
      }

      if (props === "curvy") {
        if (bodytype.curvy) {
          bodytype.curvy = false;
        } else {
          bodytype.curvy = true;
        }
      }

      if (props === "afewextrapounds") {
        if (bodytype.afewextrapounds) {
          bodytype.afewextrapounds = false;
        } else {
          bodytype.afewextrapounds = true;
        }
      }

      if (props === "fullfigured") {
        if (bodytype.fullfigured) {
          bodytype.fullfigured = false;
        } else {
          bodytype.fullfigured = true;
        }
      }

      filters.bodytype = bodytype;

      this.setState({ filters: filters, changed: true }, () => {
        this.saveFilters();
      });
    } else {
      this.setState({ advancedFilterModalOpen: true });
    }
  };

  smokingToggle = (props) => (e) => {
    if (this.state.premium) {
      var filters = this.state.filters;
      var smoking = filters.smoking;

      if (props === "nonsmoker") {
        if (smoking.nonsmoker) {
          smoking.nonsmoker = false;
        } else {
          smoking.nonsmoker = true;
        }
      }
      if (props === "lightsmoker") {
        if (smoking.lightsmoker) {
          smoking.lightsmoker = false;
        } else {
          smoking.lightsmoker = true;
        }
      }
      if (props === "heavysmoker") {
        if (smoking.heavysmoker) {
          smoking.heavysmoker = false;
        } else {
          smoking.heavysmoker = true;
        }
      }

      filters.smoking = smoking;

      this.setState({ filters: filters, changed: true }, () => {
        this.saveFilters();
      });
    } else {
      this.setState({ advancedFilterModalOpen: true });
    }
  };

  drinkingToggle = (props) => (e) => {
    if (this.state.premium) {
      var filters = this.state.filters;
      var drinking = filters.drinking;

      if (props === "nondrinker") {
        if (drinking.nondrinker) {
          drinking.nondrinker = false;
        } else {
          drinking.nondrinker = true;
        }
      }
      if (props === "socialdrinker") {
        if (drinking.socialdrinker) {
          drinking.socialdrinker = false;
        } else {
          drinking.socialdrinker = true;
        }
      }
      if (props === "heavydrinker") {
        if (drinking.heavydrinker) {
          drinking.heavydrinker = false;
        } else {
          drinking.heavydrinker = true;
        }
      }

      filters.drinking = drinking;

      this.setState({ filters: filters, changed: true }, () => {
        this.saveFilters();
      });
    } else {
      this.setState({ advancedFilterModalOpen: true });
    }
  };

  exerciseToggle = (props) => (e) => {
    if (this.state.premium) {
      var filters = this.state.filters;
      var exercise = filters.exercise;

      if (props === "never") {
        if (exercise.never) {
          exercise.never = false;
        } else {
          exercise.never = true;
        }
      }
      if (props === "sometimes") {
        if (exercise.sometimes) {
          exercise.sometimes = false;
        } else {
          exercise.sometimes = true;
        }
      }
      if (props === "regularly") {
        if (exercise.regularly) {
          exercise.regularly = false;
        } else {
          exercise.regularly = true;
        }
      }
      if (props === "often") {
        if (exercise.often) {
          exercise.often = false;
        } else {
          exercise.often = true;
        }
      }
      if (props === "daily") {
        if (exercise.daily) {
          exercise.daily = false;
        } else {
          exercise.daily = true;
        }
      }

      filters.exercise = exercise;

      this.setState({ filters: filters, changed: true }, () => {
        this.saveFilters();
      });
    } else {
      this.setState({ advancedFilterModalOpen: true });
    }
  };

  childrenToggle = (props) => (e) => {
    if (this.state.premium) {
      var filters = this.state.filters;
      var children = filters.children;

      if (props === "nokids") {
        if (children.nokids) {
          children.nokids = false;
        } else {
          children.nokids = true;
        }
      }
      if (props === "onekid") {
        if (children.onekid) {
          children.onekid = false;
        } else {
          children.onekid = true;
        }
      }
      if (props === "twokids") {
        if (children.twokids) {
          children.twokids = false;
        } else {
          children.twokids = true;
        }
      }
      if (props === "threekids") {
        if (children.threekids) {
          children.threekids = false;
        } else {
          children.threekids = true;
        }
      }
      if (props === "fourpluskids") {
        if (children.fourpluskids) {
          children.fourpluskids = false;
        } else {
          children.fourpluskids = true;
        }
      }

      filters.children = children;

      this.setState({ filters: filters, changed: true }, () => {
        this.saveFilters();
      });
    } else {
      this.setState({ advancedFilterModalOpen: true });
    }
  };

  relationshipToggle = (props) => (e) => {
    if (this.state.premium) {
      var filters = this.state.filters;
      var relationshipstatus = filters.relationshipstatus;

      if (props === "single") {
        if (relationshipstatus.single) {
          relationshipstatus.single = false;
        } else {
          relationshipstatus.single = true;
        }
      }
      if (props === "divorced") {
        if (relationshipstatus.divorced) {
          relationshipstatus.divorced = false;
        } else {
          relationshipstatus.divorced = true;
        }
      }
      if (props === "separated") {
        if (relationshipstatus.separated) {
          relationshipstatus.separated = false;
        } else {
          relationshipstatus.separated = true;
        }
      }
      if (props === "marriedbutlooking") {
        if (relationshipstatus.marriedbutlooking) {
          relationshipstatus.marriedbutlooking = false;
        } else {
          relationshipstatus.marriedbutlooking = true;
        }
      }
      if (props === "openrelationship") {
        if (relationshipstatus.openrelationship) {
          relationshipstatus.openrelationship = false;
        } else {
          relationshipstatus.openrelationship = true;
        }
      }
      if (props === "widowed") {
        if (relationshipstatus.widowed) {
          relationshipstatus.widowed = false;
        } else {
          relationshipstatus.widowed = true;
        }
      }

      filters.relationshipstatus = relationshipstatus;

      this.setState({ filters: filters, changed: true }, () => {
        this.saveFilters();
      });
    } else {
      this.setState({ advancedFilterModalOpen: true });
    }
  };

  educationToggle = (props) => (e) => {
    if (this.state.premium) {
      var filters = this.state.filters;
      var education = filters.education;

      if (props === "highschool") {
        if (education.highschool) {
          education.highschool = false;
        } else {
          education.highschool = true;
        }
      }
      if (props === "somecollege") {
        if (education.somecollege) {
          education.somecollege = false;
        } else {
          education.somecollege = true;
        }
      }
      if (props === "associatesdegree") {
        if (education.associatesdegree) {
          education.associatesdegree = false;
        } else {
          education.associatesdegree = true;
        }
      }
      if (props === "bachelorsdegree") {
        if (education.bachelorsdegree) {
          education.bachelorsdegree = false;
        } else {
          education.bachelorsdegree = true;
        }
      }
      if (props === "graduatedegree") {
        if (education.graduatedegree) {
          education.graduatedegree = false;
        } else {
          education.graduatedegree = true;
        }
      }
      if (props === "phd") {
        if (education.phd) {
          education.phd = false;
        } else {
          education.phd = true;
        }
      }

      filters.education = education;

      this.setState({ filters: filters, changed: true }, () => {
        this.saveFilters();
      });
    } else {
      this.setState({ advancedFilterModalOpen: true });
    }
  };

  saveFilters = async (e) => {
    const data = {
      userid: this.context.user._id,
      filters: this.state.filters,
    };

    return api
      .updateAdvancedFilters(data)
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {});
  };

  closeHandler = (e) => {
    this.props.closeHandler();
    if (this.state.changed) {
      this.props.updateHandler();
    }
  };

  modalCloseHandler = (e) => {
    this.setState({ advancedFilterModalOpen: false });
  };

  render() {
    var minHeight = heights[this.state.filters.height[0]];
    var maxHeight = heights[this.state.filters.height[1]];
    return (
      <div className="advanced-searchfilters-component">
        <div className="search-filters-overlay" onClick={this.closeHandler} />

        {this.state.advancedFilterModalOpen ? (
          <AdvancedFiltersModal closeHandler={this.modalCloseHandler} />
        ) : null}
        <Card className="advanced-search-filters-card">
          <Card.Header className="advanced-search-header">
            <Button
              onClick={this.closeHandler}
              className="advanced-filters-close-btn"
            >
              <i className="fas fa-times advanced-filters-close-icon"></i>
            </Button>
            <Card.Title className="advanced-search-filters-title">
              Advanced Search Filters
            </Card.Title>
          </Card.Header>

          <Card.Body className="advanced-search-filters-body">
            <Form>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Have they verified themselves?
                </Form.Label>
                <div
                  className="advanced-switch-wrapper"
                  onClick={this.verifiedToggle}
                >
                  <div className="advanced-switch-label">
                    <i className="far fa-badge-check advanced-switch-icon"></i>
                    <span className="advanced-switch-label-text">
                      Verified profiles only
                    </span>
                  </div>
                  <Toggle
                    icons={false}
                    checked={this.state.filters.photosvideos.verified}
                    id="advanced-toggle"
                    onClick={this.verifiedToggle}
                  />
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className="search-filters-label">Height</Form.Label>
                <div className="advanced-search-filters-slider-wrapper">
                  <h2 className="search-slider-title">
                    Between {minHeight} and {maxHeight}
                  </h2>
                  <div className="slider-innerwrap">
                    <Slider
                      min={0}
                      max={36}
                      value={this.state.filters.height}
                      onChange={this.handleHeightChange}
                      onChangeCommitted={this.handleHeightSelect}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Bodytype
                </Form.Label>
                <div
                  className="advanced-switch-wrapper"
                  onClick={this.categoryToggle("bodytype")}
                >
                  <div className="advanced-switch-label">
                    <i className="far fa-walking advanced-switch-icon"></i>
                    <span className="advanced-switch-label-text">
                      Bodytype Filters
                    </span>
                  </div>
                  {this.state.bodytypeOpen ? (
                    <i className="far fa-minus advanced-switch-icon"></i>
                  ) : (
                    <i className="far fa-plus advanced-switch-icon"></i>
                  )}
                </div>
                {this.state.bodytypeOpen ? (
                  <div className="advanced-search-filters-slider-wrapper">
                    <h2 className="search-slider-title"></h2>
                    <div className="toggles-innerwrap">
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Slim"
                          checked={this.state.filters.bodytype.slim}
                          onChange={this.bodytypeToggle("slim")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Fit"
                          checked={this.state.filters.bodytype.fit}
                          onChange={this.bodytypeToggle("fit")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Muscular"
                          checked={this.state.filters.bodytype.muscular}
                          onChange={this.bodytypeToggle("muscular")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Average"
                          checked={this.state.filters.bodytype.average}
                          onChange={this.bodytypeToggle("average")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Full Figured"
                          checked={this.state.filters.bodytype.fullfigured}
                          onChange={this.bodytypeToggle("fullfigured")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Curvy"
                          checked={this.state.filters.bodytype.curvy}
                          onChange={this.bodytypeToggle("curvy")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="A Few Extra Pounds"
                          checked={this.state.filters.bodytype.afewextrapounds}
                          onChange={this.bodytypeToggle("afewextrapounds")}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Ethnicity
                </Form.Label>
                <div
                  className="advanced-switch-wrapper"
                  onClick={this.categoryToggle("ethnicity")}
                >
                  <div className="advanced-switch-label">
                    <i className="far fa-users advanced-switch-icon"></i>
                    <span className="advanced-switch-label-text">
                      Ethnicity Filters
                    </span>
                  </div>
                  {this.state.ethnicityOpen ? (
                    <i className="far fa-minus advanced-switch-icon"></i>
                  ) : (
                    <i className="far fa-plus advanced-switch-icon"></i>
                  )}
                </div>
                {this.state.ethnicityOpen ? (
                  <div className="advanced-search-filters-slider-wrapper">
                    <h2 className="search-slider-title"></h2>
                    <div className="toggles-innerwrap">
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="White"
                          checked={this.state.filters.ethnicity.white}
                          onChange={this.ethnicityToggle("white")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Black"
                          checked={this.state.filters.ethnicity.black}
                          onChange={this.ethnicityToggle("black")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Asian"
                          checked={this.state.filters.ethnicity.asian}
                          onChange={this.ethnicityToggle("asian")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Hispanic"
                          checked={this.state.filters.ethnicity.hispanic}
                          onChange={this.ethnicityToggle("hispanic")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Indian"
                          checked={this.state.filters.ethnicity.indian}
                          onChange={this.ethnicityToggle("indian")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Middle Eastern"
                          checked={this.state.filters.ethnicity.middleeastern}
                          onChange={this.ethnicityToggle("middleeastern")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Other"
                          checked={this.state.filters.ethnicity.other}
                          onChange={this.ethnicityToggle("other")}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Smoking
                </Form.Label>
                <div
                  className="advanced-switch-wrapper"
                  onClick={this.categoryToggle("smoking")}
                >
                  <div className="advanced-switch-label">
                    <i className="far fa-smoking advanced-switch-icon"></i>
                    <span className="advanced-switch-label-text">
                      Smoking Filters
                    </span>
                  </div>
                  {this.state.smokingOpen ? (
                    <i className="far fa-minus advanced-switch-icon"></i>
                  ) : (
                    <i className="far fa-plus advanced-switch-icon"></i>
                  )}
                </div>
                {this.state.smokingOpen ? (
                  <div className="advanced-search-filters-slider-wrapper">
                    <h2 className="search-slider-title"></h2>
                    <div className="toggles-innerwrap">
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Non Smoker"
                          checked={this.state.filters.smoking.nonsmoker}
                          onChange={this.smokingToggle("nonsmoker")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Light Smoker"
                          checked={this.state.filters.smoking.lightsmoker}
                          onChange={this.smokingToggle("lightsmoker")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Heavy Smoker"
                          checked={this.state.filters.smoking.heavysmoker}
                          onChange={this.smokingToggle("heavysmoker")}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Drinking
                </Form.Label>
                <div
                  className="advanced-switch-wrapper"
                  onClick={this.categoryToggle("drinking")}
                >
                  <div className="advanced-switch-label">
                    <i className="far fa-cocktail advanced-switch-icon"></i>
                    <span className="advanced-switch-label-text">
                      Drinking Filters
                    </span>
                  </div>
                  {this.state.drinkingOpen ? (
                    <i className="far fa-minus advanced-switch-icon"></i>
                  ) : (
                    <i className="far fa-plus advanced-switch-icon"></i>
                  )}
                </div>
                {this.state.drinkingOpen ? (
                  <div className="advanced-search-filters-slider-wrapper">
                    <h2 className="search-slider-title"></h2>
                    <div className="toggles-innerwrap">
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Non Drinker"
                          checked={this.state.filters.drinking.nondrinker}
                          onChange={this.drinkingToggle("nondrinker")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Social Drinker"
                          checked={this.state.filters.drinking.socialdrinker}
                          onChange={this.drinkingToggle("socialdrinker")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Heavy Drinker"
                          checked={this.state.filters.drinking.heavydrinker}
                          onChange={this.drinkingToggle("heavydrinker")}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Exercise
                </Form.Label>
                <div
                  className="advanced-switch-wrapper"
                  onClick={this.categoryToggle("exercise")}
                >
                  <div className="advanced-switch-label">
                    <i className="far fa-dumbbell advanced-switch-icon"></i>
                    <span className="advanced-switch-label-text">
                      Exercise Filters
                    </span>
                  </div>
                  {this.state.exerciseOpen ? (
                    <i className="far fa-minus advanced-switch-icon"></i>
                  ) : (
                    <i className="far fa-plus advanced-switch-icon"></i>
                  )}
                </div>
                {this.state.exerciseOpen ? (
                  <div className="advanced-search-filters-slider-wrapper">
                    <h2 className="search-slider-title"></h2>
                    <div className="toggles-innerwrap">
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Never"
                          checked={this.state.filters.exercise.never}
                          onChange={this.exerciseToggle("never")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Sometimes"
                          checked={this.state.filters.exercise.sometimes}
                          onChange={this.exerciseToggle("sometimes")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Regularly"
                          checked={this.state.filters.exercise.regularly}
                          onChange={this.exerciseToggle("regularly")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Often"
                          checked={this.state.filters.exercise.often}
                          onChange={this.exerciseToggle("often")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Daily"
                          checked={this.state.filters.exercise.daily}
                          onChange={this.exerciseToggle("daily")}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Children
                </Form.Label>
                <div
                  className="advanced-switch-wrapper"
                  onClick={this.categoryToggle("children")}
                >
                  <div className="advanced-switch-label">
                    <i className="far fa-baby advanced-switch-icon"></i>
                    <span className="advanced-switch-label-text">
                      Children Filters
                    </span>
                  </div>
                  {this.state.childrenOpen ? (
                    <i className="far fa-minus advanced-switch-icon"></i>
                  ) : (
                    <i className="far fa-plus advanced-switch-icon"></i>
                  )}
                </div>
                {this.state.childrenOpen ? (
                  <div className="advanced-search-filters-slider-wrapper">
                    <h2 className="search-slider-title"></h2>
                    <div className="toggles-innerwrap">
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="No Kids"
                          checked={this.state.filters.children.nokids}
                          onChange={this.childrenToggle("nokids")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="1 Kid"
                          checked={this.state.filters.children.onekid}
                          onChange={this.childrenToggle("onekid")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="2 Kids"
                          checked={this.state.filters.children.twokids}
                          onChange={this.childrenToggle("twokids")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="3 Kids"
                          checked={this.state.filters.children.threekids}
                          onChange={this.childrenToggle("threekids")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="4+ Kids"
                          checked={this.state.filters.children.fourpluskids}
                          onChange={this.childrenToggle("fourpluskids")}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Relationship Status
                </Form.Label>
                <div
                  className="advanced-switch-wrapper"
                  onClick={this.categoryToggle("relationship")}
                >
                  <div className="advanced-switch-label">
                    <i className="far fa-heart advanced-switch-icon"></i>
                    <span className="advanced-switch-label-text">
                      Relationship Status Filters
                    </span>
                  </div>
                  {this.state.relationshipOpen ? (
                    <i className="far fa-minus advanced-switch-icon"></i>
                  ) : (
                    <i className="far fa-plus advanced-switch-icon"></i>
                  )}
                </div>
                {this.state.relationshipOpen ? (
                  <div className="advanced-search-filters-slider-wrapper">
                    <h2 className="search-slider-title"></h2>
                    <div className="toggles-innerwrap">
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Single"
                          checked={this.state.filters.relationshipstatus.single}
                          onChange={this.relationshipToggle("single")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Divorced"
                          checked={
                            this.state.filters.relationshipstatus.divorced
                          }
                          onChange={this.relationshipToggle("divorced")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Separated"
                          checked={
                            this.state.filters.relationshipstatus.separated
                          }
                          onChange={this.relationshipToggle("separated")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Married But Looking"
                          checked={
                            this.state.filters.relationshipstatus
                              .marriedbutlooking
                          }
                          onChange={this.relationshipToggle(
                            "marriedbutlooking"
                          )}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Open Relationship"
                          checked={
                            this.state.filters.relationshipstatus
                              .openrelationship
                          }
                          onChange={this.relationshipToggle("openrelationship")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Widowed"
                          checked={
                            this.state.filters.relationshipstatus.widowed
                          }
                          onChange={this.relationshipToggle("widowed")}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Education
                </Form.Label>
                <div
                  className="advanced-switch-wrapper"
                  onClick={this.categoryToggle("education")}
                >
                  <div className="advanced-switch-label">
                    <i className="far fa-graduation-cap advanced-switch-icon"></i>
                    <span className="advanced-switch-label-text">
                      Education Level Filters
                    </span>
                  </div>
                  {this.state.educationOpen ? (
                    <i className="far fa-minus advanced-switch-icon"></i>
                  ) : (
                    <i className="far fa-plus advanced-switch-icon"></i>
                  )}
                </div>
                {this.state.educationOpen ? (
                  <div className="advanced-search-filters-slider-wrapper">
                    <h2 className="search-slider-title"></h2>
                    <div className="toggles-innerwrap">
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="High School"
                          checked={this.state.filters.education.highschool}
                          onChange={this.educationToggle("highschool")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Some College"
                          checked={this.state.filters.education.somecollege}
                          onChange={this.educationToggle("somecollege")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Associates Degree"
                          checked={
                            this.state.filters.education.associatesdegree
                          }
                          onChange={this.educationToggle("associatesdegree")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Bachelors Degree"
                          checked={this.state.filters.education.bachelorsdegree}
                          onChange={this.educationToggle("bachelorsdegree")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="Graduate Degree"
                          checked={this.state.filters.education.graduatedegree}
                          onChange={this.educationToggle("graduatedegree")}
                        />
                      </div>
                      <div className="filter-item-toggle">
                        <Form.Check
                          type="checkbox"
                          label="PhD / Post Doctoral"
                          checked={this.state.filters.education.phd}
                          onChange={this.educationToggle("phd")}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default AdvancedSearchFilters;
