import React from "react";
import "./notifications.scss";
import { useHistory, withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";

function Notifications(props) {
  let history = useHistory();

  const backHandler = (e) => {
    history.goBack();
  };

  return (
    <div className="notifications-v2-component">
      <div className="notifications-v2-header">
        <div className="notifications-v2-header-section-left">
          <Button
            className="notifications-v2-back-button"
            onClick={backHandler}
          >
            <i className="fas fa-chevron-left"></i>
          </Button>
        </div>
        <div className="notifications-v2-header-section-middle">
          Notifications
        </div>
      </div>
      <div className="notifications-v2-content"></div>
    </div>
  );
}

export default withRouter(Notifications);
