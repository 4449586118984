import React, { useEffect, useState } from 'react';
import { Modal, Form, InputGroup, Button } from 'react-bootstrap';
import { api, socket } from '../../../../../api';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../../../utils/getCloudinaryInfo';
import { Image as NoneImage } from 'react-bootstrap';
import male_user from '../../../../../assets/Images/adminSidebar/male_user.png';
import female_user from '../../../../../assets/Images/adminSidebar/female_user.png';
import Media from '../../../../../components/media/Media';

function BotConvoModal(props) {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [replyMessage, setReplyMessage] = useState('');

  var senderUser = props.message.sender;
  var receiverUser = props.message.receiver;

  const handleClose = (e) => {
    props.handleClose();
  };

  const messageSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      sender: receiverUser.username,
      receiver: senderUser.username,
      message: replyMessage,
    };

    socket.sendMessage(data);

    const messageArrayData = {
      sender: receiverUser._id,
      receiver: senderUser._id,
      message: replyMessage,
    };
    messages.push(messageArrayData);
    setReplyMessage('');

    const chatData = {
      chatid: props.message.chat._id,
    };

    return api.updateBotChat(chatData).catch((err) => {
      console.log(err);
    });
  };

  const messageChangeHandler = (e) => {
    setReplyMessage(e.target.value);
  };

  useEffect(() => {
    if (props.show) {
      fetchConvo();
    }
  }, [props.show]);

  const fetchConvo = (e) => {
    const data = {
      message: props.message,
    };

    return api
      .fetchBotConvo(data)
      .then((res) => {
        if (res.status === 200) {
          setMessages(res.data.messages);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal show={props.show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton className="bot-convo-modal-header">
        <Modal.Title></Modal.Title>
        <div className="admin-bot-message-image-container">
          {senderUser.imgurl[0] ? (
            <Media image={senderUser.imgurl[0]} />
          ) : (
            // <Image
            //   publicId={getImgName(senderUser.imgurl[0])}
            //   version={getImgVersion(senderUser.imgurl[0])}
            //   resource_type={checkGIF(senderUser.imgurl[0]) ? "video" : "image"}
            //   effect="loop"
            //   cloud_name="sugarbae"
            //   className="img-userinfo"
            // >
            //   <Transformation
            //     height="250"
            //     video_sampling="20"
            //     delay="250"
            //     duration="10"
            //     effect="loop"
            //   />
            // </Image>
            <div className="none-image-container">
              <NoneImage
                className="none-user-image"
                src={senderUser.gender === 'male' ? male_user : female_user}
              />
            </div>
          )}
          <div className="admin-bot-message-image-divider">
            <i className="fas fa-chevron-right admin-bot-message-arrow"></i>
          </div>
          {receiverUser.imgurl[0] ? (
            <Media image={receiverUser.imgurl[0]} />
          ) : (
            // <Image
            //   publicId={getImgName(receiverUser.imgurl[0])}
            //   version={getImgVersion(receiverUser.imgurl[0])}
            //   resource_type={
            //     checkGIF(receiverUser.imgurl[0]) ? 'video' : 'image'
            //   }
            //   effect="loop"
            //   cloud_name="sugarbae"
            //   className="img-userinfo"
            // >
            //   <Transformation
            //     height="200"
            //     video_sampling="20"
            //     delay="250"
            //     duration="10"
            //     effect="loop"
            //   />
            // </Image>
            <div className="none-image-container">
              <NoneImage
                className="none-user-image"
                src={receiverUser.gender === 'male' ? male_user : female_user}
              />
            </div>
          )}
        </div>

        <div className="admin-bot-message-info-container">
          <div className="admin-bot-message-usernames">
            <p className="admin-bot-message-usernames-line">
              <span className="admin-bot-username">{senderUser.username}</span>{' '}
              to{' '}
              <span className="admin-bot-username">
                {receiverUser.username}
                {receiverUser.bot && ' (Bot)'}
              </span>
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="bot-convo-modal-body">
        {messages.map((message, index) => {
          var fromBot = false;

          if (message.sender.toString() === receiverUser._id.toString()) {
            fromBot = true;
          }

          if (fromBot) {
            return (
              <div className="bot-convo-message-wrapper-sent">
                <div className="bot-convo-message-from-bot">
                  <span className="bot-convo-chat-text-sent">
                    {message.message}
                  </span>
                </div>
              </div>
            );
          } else {
            return (
              <div className="bot-convo-message-wrapper-received">
                <div className="bot-convo-message-to-bot">
                  <span className="bot-convo-chat-text-received">
                    {message.message}
                  </span>
                </div>
              </div>
            );
          }
        })}
      </Modal.Body>
      <Modal.Footer className="bot-convo-modal-footer">
        <Form onSubmit={messageSubmitHandler} className="bot-modal-convo-form">
          <InputGroup>
            <Form.Control
              as="textarea"
              id="bot-modal-reply-form-input"
              onChange={messageChangeHandler}
              value={replyMessage}
            />
            <InputGroup.Append>
              <Button variant="primary" type="submit">
                Send
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Form>
      </Modal.Footer>
    </Modal>
  );
}

export default BotConvoModal;
