import React, { useState } from "react";
import "./settings.scss";
import { Button } from "react-bootstrap";
import AccountSettings from "./settingsV2Components/accountSettings/AccountSettings";
import SocialNetworks from "./settingsV2Components/socialNetworks/SocialNetworks";
import NotificationSettings from "./settingsV2Components/notificationSettings/NotificationSettings";
import Community from "./settingsV2Components/community/Community";
import SettingsFooter from "./settingsV2Components/settingsFooter/SettingsFooter";
import { Link } from "react-router-dom";
import PhoneNumber from "./settingsEditScreens/PhoneNumber";
import Email from "./settingsEditScreens/Email";
import TextNotifications from "./settingsEditScreens/TextNotifications";
import EmailNotifications from "./settingsEditScreens/EmailNotifications";
import ManageSubscription from "./settingsEditScreens/ManageSubscription";
import Identity from "./settingsEditScreens/Indentity";
import DeactivateAccount from "./settingsEditScreens/DeactivateAccount";

function Settings(props) {
  const [tabOpen, setTabOpen] = useState(false);
  const [tab, setTab] = useState(null);

  const toggleTab = (e) => {
    if (tabOpen) {
      setTabOpen(false);
      setTab(null);
    } else {
      setTabOpen(true);
      setTab(e);
    }
  };

  if (tabOpen) {
    if (tab === "phone") {
      return <PhoneNumber toggleTab={toggleTab} />;
    } else if (tab === "email") {
      return <Email toggleTab={toggleTab} />;
    } else if (tab === "identity") {
      return <Identity toggleTab={toggleTab} />;
    } else if (tab === "emailNotifications") {
      return <EmailNotifications toggleTab={toggleTab} />;
    } else if (tab === "textNotifications") {
      return <TextNotifications toggleTab={toggleTab} />;
    } else if (tab === "manageSubscription") {
      return <ManageSubscription toggleTab={toggleTab} />;
    } else if (tab === "deactivateAccount") {
      return <DeactivateAccount toggleTab={toggleTab} />;
    }
  } else {
    return (
      <div className="settings-v2-component">
        <div className="settings-v2-header">
          <div className="settings-v2-header-section-left">
            <Link to="/app/profile">
              <div className="settings-v2-header-return-button">
                <i className="fas fa-chevron-left"></i>
              </div>
            </Link>
          </div>
          <div className="settings-v2-header-section-middle">
            <h1 className="settings-v2-header-title">Settings</h1>
          </div>
          <div className="settings-v2-header-section-right">
            <Link to="/app/profile">
              <div className="settings-v2-header-done-button">Done</div>
            </Link>
          </div>
        </div>
        <div className="settings-v2-content">
          <AccountSettings toggleTab={toggleTab} />
          <SocialNetworks />
          <NotificationSettings toggleTab={toggleTab} />
          <Community toggleTab={toggleTab} />
          <SettingsFooter toggleTab={toggleTab} />
        </div>
      </div>
    );
  }
}

export default Settings;
