import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Image, Button } from "react-bootstrap";
import "./numberonecta.scss";
import whiteLogo from "../../../assets/logos/logo_white.png";

export class NumberOneCta extends Component {
  render() {
    return (
      <div className="sd-cta">
        <div className="sd-cta-img-wrap">
          <Image className="sd-cta-img" src={whiteLogo} />
        </div>
        <h2 className="sd-cta-title">The #1 Arrangement Dating App</h2>
        <div className="sd-cta-btn-wrap">
          <Link to="/signup" className="sd-cta-link">
            <Button className="sd-cta-btn" variant="outline-light">
              Join Free
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default NumberOneCta;
