import React, { useState, useContext } from "react";
import "../modalv2styles.scss";
import { Modal, Button, Image, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import getmore from "../../../assets/Images/matches/getmore.png";
import { submitBoostV2 } from "../../../helpers/swipeActions";

function PremiumModal(props) {
  const context = useContext(UserContext);
  const [show, setShow] = useState(props.show);

  const { cards } = context.swipeData;
  const imageOne = cards && cards.length > 0 && cards[0].imgurl[0];
  const imageTwo = cards && cards.length > 0 && cards[1].imgurl[0];
  const imageThree = cards && cards.length > 0 && cards[2].imgurl[0];

  const closeHandler = (e) => {
    setShow(false);
    props.onClose();
  };

  let mainImage = null;
  const images = context.user.imgurl;
  if (images) {
    if (images.length > 0) {
      mainImage = images[0];
    }
  }

  let { option } = props;
  if (!option) {
    option = "default";
  }

  return (
    <Modal show={show} className="modal-v2" onHide={closeHandler} centered>
      <Modal.Body className="modal-v2-body">
        <div className="boost-active-popup-modal">
          <div className="boost-modal-header">
            <Image src={imageOne} className="boost-active-back-image" />
            <Image src={mainImage} className="boost-active-front-image" />
            <Image src={imageThree} className="boost-active-back-image" />
            <Image src={getmore} className="boost-active-badge" />
          </div>
          <label className="boost-active-title">Go Premium</label>
          <br />
          {option === "advancedFilters" && (
            <label className="boost-active-description">
              Unlock Advanced Search Filters!
            </label>
          )}
          {option === "undo" && (
            <label className="boost-active-description">
              Unlock Undo to Go Back and Swipe Again!
            </label>
          )}
          {option === "boost" && (
            <label className="boost-active-description">
              Unlock Profile Boost to get 10X more matches and attention.
            </label>
          )}
          {option === "messages" && (
            <label className="boost-active-description">
              Go Premium to unlock unlimited messaging.
            </label>
          )}
          <br />
        </div>
        {option === "messages" && (
          <div className="modal-premium-features-wrapper-center">
            <div className="modal-premium-feature">
              <i className="far fa-paper-plane boost-package-icon" />
              <p className="modal-premium-feature-subtitle">Unlimited</p>
              <h1 className="modal-premium-feature-title">Messaging</h1>
            </div>
          </div>
        )}
        {option === "advancedFilters" && (
          <div className="modal-premium-features-wrapper">
            <div className="modal-premium-feature">
              <i className="far fa-eye-slash boost-package-icon" />
              <p className="modal-premium-feature-subtitle">Private</p>
              <h1 className="modal-premium-feature-title">Browsing Mode</h1>
            </div>
            <div className="modal-premium-feature">
              <i className="fas fa-badge-check boost-package-icon" />
              <p className="modal-premium-feature-subtitle">Verified</p>
              <h1 className="modal-premium-feature-title">Profiles Only</h1>
            </div>
          </div>
        )}
        {option === "undo" && (
          <div className="modal-premium-features-wrapper">
            <div className="modal-premium-feature">
              <i className="far fa-undo boost-package-icon" />
              <p className="modal-premium-feature-subtitle">Undo</p>
              <h1 className="modal-premium-feature-title">Go Back</h1>
            </div>
            <div className="modal-premium-feature">
              <i className="fas fa-badge-check boost-package-icon" />
              <p className="modal-premium-feature-subtitle">Verified</p>
              <h1 className="modal-premium-feature-title">Profiles Only</h1>
            </div>
          </div>
        )}
        {option === "boost" && (
          <div className="modal-premium-features-wrapper">
            <div className="modal-premium-feature">
              <i className="far fa-bolt boost-package-icon" />
              <p className="modal-premium-feature-subtitle">Boost</p>
              <h1 className="modal-premium-feature-title">Your Profile</h1>
            </div>
            <div className="modal-premium-feature">
              <i className="fas fa-badge-check boost-package-icon" />
              <p className="modal-premium-feature-subtitle">Get 10x</p>
              <h1 className="modal-premium-feature-title">More Attention</h1>
            </div>
          </div>
        )}
      </Modal.Body>

      <Modal.Footer className="modal-v2-footer">
        <Link to="/app/premium" style={{ width: "100%" }}>
          <div variant="danger" className="modal-action-button">
            Continue
          </div>
        </Link>

        <div className="modal-close-button" onClick={closeHandler}>
          Close
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default PremiumModal;
