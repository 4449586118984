import React, { useState, useContext } from "react";
import "./nomessagesmatchv2.scss";
import { Button, Image } from "react-bootstrap";
import { contextType } from "google-map-react";
import giftIcon from "../../../../assets/Images/compliment/gifticon.png";
import { UserContext } from "../../../../context/UserContext";

function NoMessagesMatch(props) {
  const context = useContext(UserContext);

  const toggleComplimentModal = (e) => {
    props.toggleComplimentModal();
  };

  const toggleSuperlikeModal = (e) => {
    props.toggleSuperlikeModal();
  };

  if (context.user.gender === "male") {
    return (
      <div className="chat-v2-no-messages-component">
        <div className="chat-v2-no-messages-compliment-wrapper">
          <Image src={giftIcon} className="chat-v2-gift-icon" fluid />
          <p className="chat-v2-compliment-subtitle">
            Start chatting with a nice compliment
          </p>
        </div>
        <div className="chat-v2-compliment-button-wrapper">
          <div
            className="profile-v2-footer-header"
            onClick={toggleComplimentModal}
            style={{ borderRadius: "5px" }}
          >
            <div
              className="profile-v2-footer-header-innerwrap"
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-gift profile-footer-gift-icon" />
              <h4 className="profile-v2-footer-title">Send a compliment</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="chat-v2-no-messages-component">
      <div className="chat-v2-no-messages-compliment-wrapper">
        <p className="chat-v2-compliment-subtitle">
          Send a message to kickstart the conversation
        </p>
      </div>
    </div>
  );
}

export default NoMessagesMatch;
