import React, { Component } from "react";
import "./faqgrid.scss";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

export class FaqGrid extends Component {
  render() {
    return (
      <Accordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            What payment methods do you accept?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              We accept all major credit cards including Visa, Mastercard,
              American Express credit and debit cards. All payment processing is
              100% secure using entrypted technology and your payment
              information is never stored on our servers.
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            What is Sugarbae Premium?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <p>
                Sugarbae Premium is a premium membership that provides
                additional user benefits. Premium costs $49/month or $468/year.
                The benefits include:
              </p>
              <ol>
                <li>Unlimited Messaging To Other Users</li>
                <li>Premium Profile Badge</li>
                <li>Premium Profile Ranking (rank higher in search)</li>
                <li>10x more attention from other users.</li>
                <li>
                  No long term commitment require. Cancel anytime from
                  dashboard.
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            What type of photos/videos can I upload?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <p>
                Photos you upload need to be representative of the real you and
                can't include:
              </p>
              <ol>
                <li>Copyrighted content</li>
                <li>Text</li>
                <li>Contact information, including social media usernames</li>
                <li>Use of drugs or anything illegal</li>
                <li>Any other people</li>
                <li>
                  Yourself at a younger age if you don't look the same today
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            What can't I write on my profile?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <p>The following is prohibited from your profile:</p>
              <ol>
                <li>Sexually explicit language</li>
                <li>Abusive language</li>
                <li>Copyrighted content</li>
                <li>References to illegal activity, including drug use</li>
                <li>
                  Contact information, including your address, phone number, or
                  email address
                </li>
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            How do I signup for Sugarbae Premium?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <p>
                Sugarbae Premium is available for a monthly or annual
                subscription. The monthly plan is $49/month and the annual plan
                is $39/month (paid annually). Sugarbae Premium gives you
                unlimited messages, higher profile rankings, profile badge, and
                other benefits. To signup for Sugarbae Premium go to
                sugarbae.com/app/premium
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="5">
            How do I cancel my Premium subscription?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <Card.Body>
              <p>
                To manage your subcription, go to
                sugarbae.com/app/settings/subscription
              </p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

export default FaqGrid;
