import React, { useState, useEffect, useContext } from "react";
import "../desktopprofiletabs.scss";
import Axios from "axios";
import { UserContext } from "../../../../context/UserContext";
import { Link, withRouter } from "react-router-dom";
import PlacesAutocomplete from "react-places-autocomplete";
import Slider from "@material-ui/core/Slider";
import { Button, Card, Form } from "react-bootstrap";
import { api } from "../../../../api";

function ProfileInfoTab(props) {
  const context = useContext(UserContext);
  const [height, setHeight] = useState([props.user.height]);
  const [bodytype, setBodytype] = useState(props.user.bodytype);
  const [ethnicity, setEthnicity] = useState(props.user.ethnicity);
  const [education, setEducation] = useState(props.user.lifestyle.education);
  const [relationship, setRelationship] = useState(
    props.user.lifestyle.relationship
  );
  const [children, setChildren] = useState(props.user.lifestyle.children);
  const [smoking, setSmoking] = useState(props.user.lifestyle.smoking);
  const [cannabis, setCannabis] = useState(props.user.lifestyle.cannabis);
  const [drinking, setDrinking] = useState(props.user.lifestyle.drinking);
  const [exercise, setExercise] = useState(props.user.lifestyle.exercise);

  const heightChange = async (e) => {
    setHeight(e.target.value);

    const data = {
      userid: context.user._id,
      height: e.target.value,
    };

    return api
      .updateProfileHeight(data)
      .then((res) => {})
      .catch((err) => {});
  };
  const bodytypeChange = async (e) => {
    setBodytype(e.target.value);
    const data = {
      userid: context.user._id,
      bodytype: e.target.value,
    };

    return api
      .updateBodytype(data)
      .then((res) => {})
      .catch((err) => {});
  };
  const ethnicityChange = async (e) => {
    setEthnicity(e.target.value);

    const data = {
      userid: context.user._id,
      ethnicity: e.target.value,
    };

    return api
      .updateEthnicity(data)
      .then((res) => {})
      .catch((err) => {});
  };
  const educationChange = async (e) => {
    setEducation(e.target.value);
    const data = {
      userid: context.user._id,
      education: e.target.value,
    };

    return api
      .updateEducation(data)
      .then((res) => {})
      .catch((err) => {});
  };
  const relationshipChange = async (e) => {
    setRelationship(e.target.value);

    const data = {
      userid: context.user._id,
      relationshipStatus: e.target.value,
    };

    return api
      .updateRelationshipStatus(data)
      .then((res) => {})
      .catch((err) => {});
  };
  const childrenChange = async (e) => {
    setChildren(e.target.value);

    const data = {
      userid: context.user._id,
      children: e.target.value,
    };

    return api
      .updateChildren(data)
      .then((res) => {})
      .catch((err) => {});
  };
  const smokingChange = async (e) => {
    setSmoking(e.target.value);

    const data = {
      userid: context.user._id,
      smoking: e.target.value,
    };

    return api
      .updateSmoking(data)
      .then((res) => {})
      .catch((err) => {});
  };
  const drinkingChange = async (e) => {
    setDrinking(e.target.value);

    const data = {
      userid: context.user._id,
      drinking: e.target.value,
    };

    return api
      .updateDrinking(data)
      .then((res) => {})
      .catch((err) => {});
  };
  const cannabisChange = async (e) => {
    setCannabis(e.target.value);

    const data = {
      userid: context.user._id,
      cannabis: e.target.value,
    };

    return api
      .updateCannabis(data)
      .then((res) => {})
      .catch((err) => {});
  };
  const exerciseChange = async (e) => {
    setExercise(e.target.value);

    const data = {
      userid: context.user._id,
      exercise: e.target.value,
    };

    return api
      .updateExercise(data)
      .then((res) => {})
      .catch((err) => {});
  };

  return (
    <div className="desktop-profile-tab-component">
      <div className="desktop-profile-tab-header">
        <h1 className="desktop-profile-tab-header-title">Appearance</h1>
      </div>
      <div className="desktop-profile-tab-content-container">
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Height</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Control
                  as="select"
                  className="desktop-profile-form-input"
                  onChange={heightChange}
                  value={height}
                >
                  <option value="" disabled selected hidden>
                    Select Your Height
                  </option>
                  <option>4' 0"</option>
                  <option>4' 1"</option>
                  <option>4' 2"</option>
                  <option>4' 3"</option>
                  <option>4' 4"</option>
                  <option>4' 5"</option>
                  <option>4' 6"</option>
                  <option>4' 7"</option>
                  <option>4' 8"</option>
                  <option>4' 9"</option>
                  <option>4' 10"</option>
                  <option>4' 11"</option>
                  <option>5' 0"</option>
                  <option>5' 1"</option>
                  <option>5' 2"</option>
                  <option>5' 3"</option>
                  <option>5' 4"</option>
                  <option>5' 5"</option>
                  <option>5' 6"</option>
                  <option>5' 7"</option>
                  <option>5' 8"</option>
                  <option>5' 9"</option>
                  <option>5' 10"</option>
                  <option>5' 11"</option>
                  <option>6' 1"</option>
                  <option>6' 2"</option>
                  <option>6' 3"</option>
                  <option>6' 4"</option>
                  <option>6' 5"</option>
                  <option>6' 6"</option>
                  <option>6' 7"</option>
                  <option>6' 8"</option>
                  <option>6' 9"</option>
                  <option>6' 10"</option>
                  <option>6' 11"</option>
                  <option>7' 0"</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper"></div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Bodytype</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Control
                  as="select"
                  className="desktop-profile-form-input"
                  onChange={bodytypeChange}
                  value={bodytype}
                >
                  <option value="" disabled selected hidden>
                    Select Your Body Type
                  </option>
                  <option>Slim</option>
                  <option>Fit</option>
                  <option>Average</option>
                  <option>Curvy</option>
                  <option>A Few Extra Pounds</option>
                  <option>Full / Overweight</option>
                  <option>Other</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper"></div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Ethnicity</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Control
                  as="select"
                  className="desktop-profile-form-input"
                  onChange={ethnicityChange}
                  value={ethnicity}
                >
                  <option value="" disabled selected hidden>
                    Select Your Ethnicity
                  </option>
                  <option>Asian</option>
                  <option>Black / African Decent</option>
                  <option>Latin / Hispanic</option>
                  <option>East Indian</option>
                  <option>Middle Eastern</option>
                  <option>Mixed</option>
                  <option>Native American</option>
                  <option>Pacific Islander</option>
                  <option>White / Caucasian</option>
                  <option>Other</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper"></div>
        </div>
      </div>
      <div className="desktop-profile-tab-header">
        <h1 className="desktop-profile-tab-header-title">
          Personal Information
        </h1>
      </div>
      <div className="desktop-profile-tab-content-container">
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Education</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Control
                  as="select"
                  className="desktop-profile-form-input"
                  onChange={educationChange}
                  value={education}
                >
                  <option value="" disabled selected hidden>
                    Select your education level
                  </option>
                  <option>High School</option>
                  <option>Some College</option>
                  <option>Associates Degree</option>
                  <option>Bachelors Degree</option>
                  <option>Graduate Degree</option>
                  <option>PhD / Post Doctoral</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper"></div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Relationship</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Control
                  as="select"
                  className="desktop-profile-form-input"
                  onChange={relationshipChange}
                  value={relationship}
                >
                  <option value="" disabled selected hidden>
                    Select Your Relationship Status
                  </option>
                  <option>Single</option>
                  <option>Divorced</option>
                  <option>Separated</option>
                  <option>Married But Looking</option>
                  <option>Open Relationship</option>
                  <option>Widowed</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper"></div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Children</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Control
                  as="select"
                  className="desktop-profile-form-input"
                  onChange={childrenChange}
                  value={children}
                >
                  <option value="" disabled selected hidden>
                    Select How Many Kids You Have
                  </option>
                  <option>No Kids</option>
                  <option>1 Kid</option>
                  <option>2 Kid</option>
                  <option>3 Kid</option>
                  <option>4+ Kid</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper"></div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Smoking</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Control
                  as="select"
                  className="desktop-profile-form-input"
                  onChange={smokingChange}
                  value={smoking}
                >
                  <option value="" disabled selected hidden>
                    Select Your Smoking Habits
                  </option>
                  <option>Non Smoker</option>
                  <option>Light Smoker</option>
                  <option>Heavy Smoker</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper"></div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Drinking</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Control
                  as="select"
                  className="desktop-profile-form-input"
                  onChange={drinkingChange}
                  value={drinking}
                >
                  <option value="" disabled selected hidden>
                    Select Your Drinking Habits
                  </option>
                  <option>Non Drinker</option>
                  <option>Social Drinker</option>
                  <option>Heavy Drinker</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper"></div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Cannabis</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Control
                  as="select"
                  className="desktop-profile-form-input"
                  onChange={cannabisChange}
                  value={cannabis}
                >
                  <option value="" disabled selected hidden>
                    Select Your Cannabis Habits
                  </option>
                  <option>Non Cannabis Consumer</option>
                  <option>Social Cannabis Consumer</option>
                  <option>Heavy Cannabis Consumer</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper"></div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Exercise</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Control
                  as="select"
                  className="desktop-profile-form-input"
                  onChange={exerciseChange}
                  value={exercise}
                >
                  <option value="" disabled selected hidden>
                    Select Your Exercise Habits
                  </option>
                  <option>Never</option>
                  <option>Sometimes</option>
                  <option>Regularly</option>
                  <option>Often</option>
                  <option>Daily</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper"></div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ProfileInfoTab);
