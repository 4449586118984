import React, { useState, useEffect, useContext } from 'react';
import './desktopmatchestab.scss';
import Axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import { Button } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { api } from '../../../../api';
import Media from '../../../../components/media/Media';

var moment = require('moment');

function DesktopMatchesTab(props) {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    context.updateMatchesAsRead();
    setFetching(false);
  }, [context.matches]);

  const handleScroll = (e) => {
    if (!fetching && !context.maxMatches) {
      setFetching(true);
      context.checkMoreMatches();
    }
  };

  useBottomScrollListener(handleScroll);

  if (loading) {
    return (
      <div className="desktop-matches-tab-loading-wrapper">
        <ClipLoader size={35} />
      </div>
    );
  } else {
    return (
      <div className="desktop-matches-tab">
        {context.matches.length > 0 ? (
          <div className="desktop-matches-content-container">
            {context.matches.map((match, index) => {
              var images = match.user.imgurl;
              var username = match.user.username;
              var headline = match.user.headline;
              var headlinestatus = match.user.headlinestatus;
              var height = match.user.height;
              var bodytype = match.user.bodytype;
              var ethnicity = match.user.ethnicity;
              var age = match.user.age;
              var address = match.user.address;
              var cardImage = null;
              if (images.length > 0) {
                cardImage = images[0];
              }

              var matchDate = moment(match.date);
              var timeDifference = moment().diff(matchDate, 'hours');
              var timestampText = 'matched ' + timeDifference + ' hours ago';

              if (timeDifference > 24) {
                timeDifference = moment().diff(matchDate, 'days');
                timestampText = 'matched ' + timeDifference + ' days ago';
              } else if (timeDifference < 1) {
                timeDifference = moment().diff(matchDate, 'minutes');
                if (timeDifference < 1) {
                  timeDifference = moment().diff(matchDate, 'seconds');

                  if (timeDifference < 10) {
                    timestampText = 'matched just now';
                  } else {
                    timestampText =
                      'matched ' + timeDifference + ' seconds ago';
                  }
                } else {
                  timestampText = 'matched ' + timeDifference + ' minutes ago';
                }
              }

              var profileUrl = '/app/member/' + match.user.profileurl;
              var messageUrl = '/app/messages/' + match.user.profileurl;

              return (
                <div className="desktop-matches-card">
                  <div className="desktop-matches-card-left-side">
                    {cardImage ? (
                      <Link to={profileUrl} className="desktop-match-card-link">
                        <Media image={cardImage} />

                        {/* <Image
                          publicId={getImgName(cardImage)}
                          version={getImgVersion(cardImage)}
                          resource_type={
                            checkGIF(cardImage) ? "video" : "image"
                          }
                          effect="loop"
                          cloud_name="sugarbae"
                          className="desktop-matches-card-image"
                        >
                          <Transformation
                            height="200"
                            video_sampling="20"
                            delay="250"
                            duration="10"
                            effect="loop"
                            quality="auto"
                            flags="lossy"
                          />
                        </Image> */}
                      </Link>
                    ) : (
                      <Link to={profileUrl} className="desktop-match-card-link">
                        <div className="desktop-matches-card-image-placeholder">
                          <i className="far fa-user desktop-matches-card-placeholder-icon"></i>
                        </div>
                      </Link>
                    )}
                    <div className="desktop-matches-card-user-info">
                      <div className="desktop-matches-card-user-info-left">
                        <Link
                          to={profileUrl}
                          className="desktop-match-card-link"
                        >
                          <h2 className="desktop-matches-card-username">
                            {username}
                          </h2>
                          {headlinestatus === 'approved' && (
                            <p className="desktop-match-card-headline">
                              {headline}
                            </p>
                          )}
                          <p className="desktop-match-card-age-location">
                            {age} • {address}
                          </p>
                        </Link>
                        {match.messaged ? (
                          <Button
                            className="desktop-match-card-view-conversation-button"
                            href={messageUrl}
                          >
                            View Conversation
                          </Button>
                        ) : (
                          <Button
                            className="desktop-match-card-send-message-button"
                            href={messageUrl}
                          >
                            <i className="far fa-comment-alt-lines match-card-send-message-icon"></i>
                            Send Message
                          </Button>
                        )}
                      </div>
                      <Link to={profileUrl} className="desktop-match-card-link">
                        <div className="desktop-matches-card-user-info-right">
                          <ul className="desktop-match-card-user-info-list">
                            <li className="desktop-match-card-user-info-list-item">
                              <span className="desktop-match-card-user-info-title">
                                Height
                              </span>
                              <span className="desktop-match-card-user-info-content">
                                {height}
                              </span>
                            </li>
                            <li className="desktop-match-card-user-info-list-item">
                              <span className="desktop-match-card-user-info-title">
                                Bodytype
                              </span>
                              <span className="desktop-match-card-user-info-content">
                                {bodytype}
                              </span>
                            </li>
                            <li className="desktop-match-card-user-info-list-item">
                              <span className="desktop-match-card-user-info-title">
                                Ethnicity
                              </span>
                              <span className="desktop-match-card-user-info-content">
                                {ethnicity}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <Link to={profileUrl} className="desktop-match-card-link">
                    <div className="desktop-match-card-timestamp-wrapper">
                      <p className="desktop-match-card-timestamp">
                        {timestampText}
                      </p>
                    </div>
                  </Link>
                </div>
              );
            })}
            {fetching && (
              <div className="messages-bottom-indicator">
                <ClipLoader size={35} />
              </div>
            )}
            {context.maxMatches && (
              <div className="messages-bottom-indicator">
                <div className="end-of-messages-innerwrap">
                  <h4 className="end-of-messages-title">No more matches!</h4>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="desktop-no-likes-container">
            <div className="desktop-no-likes-innerwrap">
              <i className="fas fa-empty-set desktop-no-likes-icon"></i>
              <h2 className="desktop-no-likes-title">
                You have no matches yet!
              </h2>
              <p className="desktop-no-likes-subtitle">
                Keep liking users to receive more likes and matches.
              </p>
              <Link to="/app/search">
                <Button className="desktop-no-likes-button" size="lg">
                  <i className="far fa-search no-likes-search-icon"></i>
                  Search
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(DesktopMatchesTab);
