import React from "react";
import { Image, Button } from "react-bootstrap";
import logo from "../../../../../assets/logos/vertical-white-bg.png";
import "../settingsv2styles.scss";
import { withRouter } from "react-router-dom";

function SettingsFooter(props) {
  const toggleTab = (name) => (e) => {
    props.toggleTab(name);
  };

  const logoutHandler = (e) => {
    localStorage.removeItem("auth");
    props.history.push("/");
  };

  return (
    <div className="settings-v2-section-footer">
      <Image src={logo} className="settings-v2-footer-logo" fluid />
      <p className="settings-v2-footer-version-text">Version 3.0</p>
      <Button
        className="settings-v2-footer-manage-subscription-button"
        onClick={toggleTab("manageSubscription")}
      >
        Manage Subscription
      </Button>
      <Button
        className="settings-v2-footer-delete-account-button"
        onClick={toggleTab("deactivateAccount")}
      >
        Delete Account
      </Button>
      <Button
        className="settings-v2-footer-logout-button"
        onClick={logoutHandler}
      >
        Logout
      </Button>
    </div>
  );
}

export default withRouter(SettingsFooter);
