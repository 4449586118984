import React, { useState, useContext, useEffect, useRef } from "react";
import "./logins.scss";
import { withRouter, useHistory, useLocation, Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import ReactCodeInput from "react-verification-code-input";
import PlacesAutocomplete from "react-places-autocomplete";
import ReactGA from "react-ga";
import { Button, Form, InputGroup, Image, Alert } from "react-bootstrap";
import PasswordValidator from "password-validator";
import Axios from "axios";
import { api } from "../../../api";
import { UserContext } from "../../../context/UserContext";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import EmailValidator from "email-validator";

const passwordSchema = new PasswordValidator();

passwordSchema.is().min(6).is().max(100).has().not().spaces();

const moment = require("moment");

function EmailLogin(props) {
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [userExists, setUserExists] = useState(false);
  const [verificationOpen, setVerificationOpen] = useState(false);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verifyError, setVerifyError] = useState(false);
  const [code, setCode] = useState("");
  const [signupStep, setSignupStep] = useState("home");
  const [gender, setGender] = useState(null);
  const [birthdate, setBirthdate] = useState(null);
  const [address, setAddress] = useState("");
  const [changingAddress, setChangingAddress] = useState("");
  const [username, setUsername] = useState(null);
  const [usernameError, setUsernameError] = useState(false);
  const [usernameExistsError, setUsernameExistsError] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [loginPassword, setLoginPassword] = useState("");
  const [socialLogin, setSocialLogin] = useState(false);
  const [socialType, setSocialType] = useState(false);

  // Errors
  const [errors, setErrors] = useState({
    emailInvalid: false,
    emailExists: false,
    gender: false,
    birthday: false,
    location: false,
    usernameInvalid: false,
    usernameExists: false,
    passwordInvalid: false,
    passwordMatch: false,
    loginPassword: false,
  });

  const todaysDate = moment().format("YYYY-MM-DD");
  const minDate = moment(todaysDate).subtract(18, "years").format("YYYY-MM-DD");

  const Joi = require("@hapi/joi");
  const usernameschema = Joi.object({
    username: Joi.string().alphanum().min(3).max(30).required(),
  });

  useEffect(() => {
    if (location.state) {
      const {
        email,
        password,
        signupStep,
        socialLogin,
        socialType,
      } = location.state;

      if (location.state) {
        setSignupStep("gender");
        setEmail(email);
        setPassword(password);
        setSocialLogin(socialLogin);
        setSocialType(socialType);
      }
    }
  }, []);

  const checkNumberCount = () => {
    var count = 0;

    for (var index in username) {
      if (!isNaN(username[index])) {
        count = count + 1;
      }
    }

    if (count > 3) {
      return false;
    } else {
      return true;
    }
  };

  const verificationCodeSubmit = async (e) => {
    if (verificationCode.length === 6) {
      setVerificationLoading(true);

      if (verificationCode.toString() === code.toString()) {
        setSignupStep("gender");
        setVerifyError(true);
      }
    } else {
      setVerifyError(true);
    }
    setVerificationLoading(false);
  };

  const verificationCodeChange = async (e) => {
    setVerifyError(false);
    setVerificationCode(e);
  };

  const sendCodeHandler = async (e) => {
    if (submitDisabled) {
      setEmailInvalid(true);
    } else {
      setSubmitDisabled(true);

      let isEmailValid = await EmailValidator.validate(email);

      if (isEmailValid) {
        const data = {
          email,
        };

        await api
          .checkEmail(data)
          .then((res) => {
            if (res.status === 200) {
              const { valid, verificationCode } = res.data;
              if (valid === false) {
                setUserExists(true);
                setSignupStep("loginPassword");
              } else {
                setSignupStep("gender");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setErrors({ ...errors, emailInvalid: true });
      }
      setSubmitDisabled(false);
    }
  };

  const changeAddressHandler = (e) => {
    setChangingAddress(e);
  };

  const commitAddressHandler = (e) => {
    setErrors({ ...errors, location: false });
    setAddress(e);
    setChangingAddress(e);
  };

  const backHandler = (e) => {
    setSubmitDisabled(false);
    if (signupStep === "gender") {
      if (socialLogin) {
        history.goBack();
      } else {
        setSignupStep("none");
      }
    } else if (signupStep === "birthday") {
      setSignupStep("gender");
    } else if (signupStep === "location") {
      setSignupStep("birthday");
    } else if (signupStep === "username") {
      setSignupStep("location");
    } else if (signupStep === "password") {
      setSignupStep("username");
    } else if (signupStep === "loginPassword") {
      setSignupStep("");
    } else if (verificationOpen) {
      setVerificationOpen(false);
      setSubmitDisabled(false);
    } else {
      history.goBack();
    }
  };

  const continueHandler = async (e) => {
    if (!submitDisabled) {
      if (signupStep === "gender") {
        if (gender !== null) {
          setSignupStep("birthday");
        } else {
          setErrors({ ...errors, gender: true });
        }
      } else if (signupStep === "birthday") {
        if (birthdate !== null) {
          setSignupStep("location");
        } else {
          setErrors({ ...errors, birthday: true });
        }
      } else if (signupStep === "location") {
        if (address !== null && address.length > 0) {
          setSignupStep("username");
        } else {
          setErrors({ ...errors, location: true });
        }
      } else if (signupStep === "username") {
        let usernameValid = false;
        if (username !== null) {
          const checkUsername = usernameschema.validate({ username });
          const countNumberCheck = checkNumberCount();

          if (!checkUsername.error && countNumberCheck) {
            const data = {
              username,
            };
            await api
              .checkUsername(data)
              .then((res) => {
                if (res.status === 200) {
                  usernameValid = res.data.valid;
                  if (!res.data.valid) {
                    setErrors({ ...errors, usernameExists: true });
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            setErrors({ ...errors, usernameInvalid: true });
          }
        } else {
          setErrors({ ...errors, usernameInvalid: true });
        }
        if (usernameValid) {
          if (socialLogin) {
            setSubmitDisabled(true);
            let ipAddress = null;
            await Axios.get("https://json.geoiplookup.io", {
              withCredentials: false,
            })
              .then((res) => {
                ipAddress = res.data.ip;
              })
              .catch((err) => {
                console.log(err);
              });

            const data = {
              phone: null,
              email,
              password,
              username,
              gender,
              address,
              birthdate,
              ipAddress,
              socialLogin: true,
              socialLoginType: socialType,
            };
            await api
              .submitSignup(data)
              .then((res) => {
                if (res.status === 200) {
                  localStorage.setItem("auth", res.data);
                  history.push("/app/welcome");
                }
              })
              .catch((err) => {});
          } else {
            setSignupStep("password");
          }
        } else {
          setErrors({ ...errors, usernameInvalid: true });
          setSubmitDisabled(false);
        }
      } else if (signupStep === "password") {
        let passwordValid = false;

        if (password === confirmPassword) {
          if (passwordSchema.validate(password)) {
            passwordValid = true;
          } else {
            setErrors({ ...errors, passwordInvalid: true });
          }
        } else {
          setErrors({ ...errors, passwordMatch: true });
        }
        if (passwordValid) {
          setSubmitDisabled(true);

          let ipAddress = null;
          await Axios.get("https://json.geoiplookup.io", {
            withCredentials: false,
          })
            .then((res) => {
              ipAddress = res.data.ip;
            })
            .catch((err) => {
              console.log(err);
            });

          const data = {
            phone: null,
            email,
            password,
            username,
            gender,
            address,
            birthdate,
            ipAddress,
            socialLogin: false,
            socialLoginType: null,
          };

          await api
            .submitSignup(data)
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem("auth", res.data);
                history.push("/app/welcome");
              }
            })
            .catch((err) => {
              console.log(err);
              setErrors({ ...errors, passwordInvalid: true });
              setSubmitDisabled(false);
            });
        } else {
          setErrors({ ...errors, passwordInvalid: true });
          setSubmitDisabled(false);
        }
      } else if (signupStep === "loginPassword") {
        setSubmitDisabled(true);
        if (loginPassword.length > 0) {
          const data = {
            email,
            number: null,
            password: loginPassword,
            oauth: null,
          };
          return api
            .submitLogin(data)
            .then((res) => {
              if (res.status === 200) {
                localStorage.setItem("auth", res.data.token);
                history.push("/app/search");
              } else {
                setSubmitDisabled(false);
                setErrors({ ...errors, loginPassword: true });
              }
            })
            .catch((err) => {
              setSubmitDisabled(false);
              setErrors({ ...errors, loginPassword: true });
              console.log(err);
            });
        } else {
          setSubmitDisabled(false);
          setErrors({ ...errors, loginPassword: true });
        }
      }
    }
  };

  const searchOptions = {
    types: ["(cities)"],
  };

  if (signupStep === "loginPassword") {
    return (
      <div className="login-v2-component">
        <div className="login-v2-header">
          <div className="login-v2-back-button" onClick={backHandler}>
            <i className="far fa-chevron-left login-v2-back-icon" />
            <span>Back</span>
          </div>
          <div className="login-v2-title-section">
            <h2 className="login-v2-pre-title">Enter Your</h2>
            <h1 className="login-v2-title">Password</h1>
          </div>
        </div>
        <div className="login-v2-content">
          <div className="login-v2-address-input-wrapper">
            <input
              className="login-v2-birthdate"
              type="password"
              id="loginPassword"
              value={loginPassword}
              onChange={(e) => {
                setLoginPassword(e.target.value);
                setErrors({ ...errors, loginPassword: false });
              }}
              placeholder="Enter Password"
            />
          </div>
          {errors.loginPassword && (
            <div className="login-v2-error-container">
              <Alert variant="warning">
                Password in incorrect.{" "}
                <Link to="/reset-password" className="lp-forgot-password-link">
                  Click here to reset your password.
                </Link>
              </Alert>
            </div>
          )}
        </div>
        <div className="login-v2-footer">
          <div className="login-v2-footer-button" onClick={continueHandler}>
            Login
          </div>
        </div>
      </div>
    );
  }

  if (signupStep === "password") {
    return (
      <div className="login-v2-component">
        <div className="login-v2-header">
          <div className="login-v2-back-button" onClick={backHandler}>
            <i className="far fa-chevron-left login-v2-back-icon" />
            <span>Back</span>
          </div>
          <div className="login-v2-title-section">
            <h2 className="login-v2-pre-title">Create Your</h2>
            <h1 className="login-v2-title">Password</h1>
          </div>
        </div>
        <div className="login-v2-content">
          <div className="login-v2-address-input-wrapper">
            <input
              className="login-v2-birthdate"
              type="password"
              id="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrors({
                  ...errors,
                  passwordMatch: false,
                  passwordInvalid: false,
                });
              }}
              placeholder="Enter Password"
            />
            <input
              className="login-v2-birthdate"
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setErrors({
                  ...errors,
                  passwordMatch: false,
                  passwordInvalid: false,
                });
              }}
              style={{ marginTop: "1rem" }}
              placeholder="Confirm Password"
            />
          </div>
          {errors.passwordInvalid && (
            <div className="login-v2-error-container">
              <Alert variant="warning">
                Invalid Password. Minimum length: 6 characters. No spaces.
              </Alert>
            </div>
          )}
          {errors.passwordMatch && (
            <div className="login-v2-error-container">
              <Alert variant="warning">Passwords do no match.</Alert>
            </div>
          )}
        </div>
        <div className="login-v2-footer">
          <div className="login-v2-footer-button" onClick={continueHandler}>
            Continue
          </div>
        </div>
      </div>
    );
  }

  if (signupStep === "username") {
    return (
      <div className="login-v2-component">
        <div className="login-v2-header">
          <div className="login-v2-back-button" onClick={backHandler}>
            <i className="far fa-chevron-left login-v2-back-icon" />
            <span>Back</span>
          </div>
          <div className="login-v2-title-section">
            <h2 className="login-v2-pre-title">Create Your</h2>
            <h1 className="login-v2-title">Username</h1>
          </div>
        </div>
        <div className="login-v2-content">
          <div className="login-v2-address-input-wrapper">
            <input
              className="login-v2-birthdate"
              type="text"
              id="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value.toLowerCase());
                setErrors({
                  ...errors,
                  usernameExists: false,
                  usernameInvalid: false,
                });
              }}
            />
          </div>
          {errors.usernameInvalid && (
            <div className="login-v2-error-container">
              <Alert variant="warning">
                Username is invalid. Must be at least 3 characters long. No
                special characters allowed. Max length 30 characters. Must
                contains less 3 numbers.
              </Alert>
            </div>
          )}
          {errors.usernameExists && (
            <div className="login-v2-error-container">
              <Alert variant="warning">
                Username is taken. Please enter a different username.{" "}
              </Alert>
            </div>
          )}
        </div>
        <div className="login-v2-footer">
          <div className="login-v2-footer-button" onClick={continueHandler}>
            Continue
          </div>
        </div>
      </div>
    );
  }

  if (signupStep === "location") {
    return (
      <div className="login-v2-component">
        <div className="login-v2-header">
          <div className="login-v2-back-button" onClick={backHandler}>
            <i className="far fa-chevron-left login-v2-back-icon" />
            <span>Back</span>
          </div>
          <div className="login-v2-title-section">
            <h2 className="login-v2-pre-title">Enter Your</h2>
            <h1 className="login-v2-title">Location</h1>
          </div>
        </div>
        <div className="login-v2-content">
          <div className="login-v2-address-input-wrapper">
            <PlacesAutocomplete
              value={changingAddress}
              onChange={changeAddressHandler}
              onSelect={commitAddressHandler}
              searchOptions={searchOptions}
              shouldFetchSuggestions
              highlightFirstSuggestion
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <>
                  <input
                    className="login-v2-address-input"
                    type="text"
                    placeholder="Enter your city"
                    {...getInputProps({
                      placeholder: "Enter your city",
                    })}
                  />
                  <div className="login-v2-address-dropdown">
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "location-suggestion-item-active"
                        : "location-suggestion-item";

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                          })}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="far fa-map-marker-alt location-suggestion-icon" />
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </PlacesAutocomplete>
          </div>
          {errors.location && (
            <div className="login-v2-error-container">
              <Alert variant="warning">Please select your location. </Alert>
            </div>
          )}
        </div>
        <div className="login-v2-footer">
          <div className="login-v2-footer-button" onClick={continueHandler}>
            Continue
          </div>
        </div>
      </div>
    );
  }

  if (signupStep === "birthday") {
    return (
      <div className="login-v2-component">
        <div className="login-v2-header">
          <div className="login-v2-back-button" onClick={backHandler}>
            <i className="far fa-chevron-left login-v2-back-icon" />
            <span>Back</span>
          </div>
          <div className="login-v2-title-section">
            <h2 className="login-v2-pre-title">Enter Your</h2>
            <h1 className="login-v2-title">Birthday</h1>
          </div>
        </div>
        <div className="login-v2-content">
          <input
            className="login-v2-birthdate"
            type="date"
            id="date"
            value={birthdate}
            max={minDate}
            onChange={(e) => {
              setBirthdate(e.target.value);
              setErrors({ ...errors, birthday: false });
            }}
          />
          {errors.birthday && (
            <div className="login-v2-error-container">
              <Alert variant="warning">Please select your birthday. </Alert>
            </div>
          )}
        </div>
        <div className="login-v2-footer">
          <div className="login-v2-footer-button" onClick={continueHandler}>
            Continue
          </div>
        </div>
      </div>
    );
  }

  if (signupStep === "gender") {
    return (
      <div className="login-v2-component">
        <div className="login-v2-header">
          <div className="login-v2-back-button" onClick={backHandler}>
            <i className="far fa-chevron-left login-v2-back-icon" />
            <span>Back</span>
          </div>
          <div className="login-v2-title-section">
            <h2 className="login-v2-pre-title">Select Your</h2>
            <h1 className="login-v2-title">Gender</h1>
          </div>
        </div>
        <div className="login-v2-content">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className={
                gender === "male"
                  ? "login-v2-gender-button-active"
                  : "login-v2-gender-button"
              }
              onClick={() => {
                setGender("male");
                setErrors({ ...errors, gender: false });
              }}
            >
              <div className="login-v2-gender-button-content">
                {/* <Image src={maleSignupIcon} className="login-v2-signup-icon" /> */}
              </div>
              <div
                className={
                  gender === "male"
                    ? "login-v2-gender-button-footer-active"
                    : "login-v2-gender-button-footer"
                }
              >
                Male
              </div>
            </div>
            <div
              className={
                gender === "female"
                  ? "login-v2-gender-button-active"
                  : "login-v2-gender-button"
              }
              onClick={() => {
                setGender("female");
                setErrors({ ...errors, gender: false });
              }}
            >
              <div className="login-v2-gender-button-content-female">
                {/* <Image
                  src={femaleSignupIcon}
                  className="login-v2-signup-icon"
                /> */}
              </div>
              <div
                className={
                  gender === "female"
                    ? "login-v2-gender-button-footer-active"
                    : "login-v2-gender-button-footer"
                }
              >
                Female
              </div>
            </div>
          </div>
          {errors.gender && (
            <div className="login-v2-error-container">
              <Alert variant="warning">Please select your gender </Alert>
            </div>
          )}
        </div>
        <div className="login-v2-footer">
          <div className="login-v2-footer-button" onClick={continueHandler}>
            Continue
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="login-v2-component">
      <div className="login-v2-header">
        <div className="login-v2-back-button" onClick={backHandler}>
          <i className="far fa-chevron-left login-v2-back-icon" />
          <span>Back</span>
        </div>

        <div className="login-v2-title-section">
          <h2 className="login-v2-pre-title">Enter your</h2>
          <h1 className="login-v2-title">Email</h1>
        </div>
      </div>
      <div className="login-v2-content">
        <input
          className="login-v2-birthdate"
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          placeholder="Enter your email address"
        />
        {errors.emailInvalid && (
          <div className="login-v2-error-container">
            <Alert variant="warning">Error: Invalid Email Address</Alert>
          </div>
        )}
      </div>
      <div className="login-v2-footer">
        <div className="login-v2-footer-button" onClick={sendCodeHandler}>
          Continue
        </div>
      </div>
    </div>
  );
}

export default withRouter(EmailLogin);
