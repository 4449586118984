import React from "react";
import { Button, Image } from "react-bootstrap";
import firstPlace from "../../../assets/Images/Features/first_place.png";
import giftImage from "../../../assets/Images/Features/gift.png";
import idealImage from "../../../assets/Images/Features/ideal.png";
import chatImage from "../../../assets/Images/Features/chat.png";
import verifiedImage from "../../../assets/Images/Features/verified.png";
import freeImage from "../../../assets/Images/Features/free.png";
import convenienceImage from "../../../assets/Images/Features/convenience.png";
import mutuallyBeneficial from "../../../assets/Images/Features/mutuallyBeneficial.png";
import transparent from "../../../assets/Images/Features/transparent.png";
import endlessOptions from "../../../assets/Images/Features/endlessOptions.png";
import limitless from "../../../assets/Images/Features/limitless.png";
import noDrama from "../../../assets/Images/Features/noDrama.png";
import { Link } from "react-router-dom";
import ArrangementCta from "../../ctas/arrangementCta/ArrangementCta";

function ArrangementBenefits() {
  return (
    <div className="features-content">
      <div className="features-header">
        <div className="features-header-content">
          <h2 className="features-header-title">Experience the Benefits...</h2>
          <p className="features-header-subtitle">
            An arrangement or a sugar date can come in different forms with
            varying terms but what all arrangements have in common is the
            stress-free experience for both sides.
          </p>
        </div>
      </div>
      <div className="features-content-container">
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={convenienceImage} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">Convenience</h2>
            <p className="features-text">
              Arrangements are an online dating shortcut. Both genders can find
              what they are looking for efficiently, without games or delay.
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={mutuallyBeneficial} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">Mutually Beneficial</h2>
            <p className="features-text">
              An arrangement is based on equal consideration of the needs,
              desires, and expectations of both sides and results in a
              fulfilling experience for both.
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={transparent} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">Transparency</h2>
            <p className="features-text">
              Since all terms are communicated upfront, there are no surprises
              and both sides know what exactly to expect from each other.
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={endlessOptions} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">Endless Options</h2>
            <p className="features-text">
              Although not common, if you do see your sugar relationship heading
              south, you can exit anytime and find a new arrangement whenever
              you feel like it.
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={limitless} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">Limitless Possibilities</h2>
            <p className="features-text">
              A major advantage of an arrangement is that the possibilities are
              limited only by your imagination and the willingness of your
              partner to your terms and expectations.
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={noDrama} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">No Drama</h2>
            <p className="features-text">
              Because of clear upfront communication, both sides understand
              their obligations and thus there are no dramas, dishonesty, and
              stress in a sugar relationship.
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArrangementBenefits;
