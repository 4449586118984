import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import "../admincomponents.scss";

export class UserTotalCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTotal: null,
      botTotal: null,
      createdToday: null,
      loading: false
    };
  }

  fetchData = async e => {
    this.setState({ loading: true });
    const token = localStorage.getItem("auth");
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + "/api/admin/usertotal";
    const config = {
      headers: { auth: token }
    };

    await Axios.post(url, config)
      .then(res => {
        console.log(res.data);
        this.setState(
          {
            userTotal: res.data.data.userTotal,
            botTotal: res.data.data.botTotal,
            createdToday: res.data.data.createdToday
          },
          () => {
            this.setState({ loading: false });
          }
        );
      })
      .catch(err => {
        console.log("Error Fetching Users");
      });
  };

  render() {
    return (
      <Card bg={"dark"} text={"white"} style={{ width: "18rem" }}>
        <Card.Header>
          <div className="card-header-wrap">
            <div className="card-header-leftside">
              <h4 className="card-header-title">Total users</h4>
            </div>
            <div className="card-header-rightside">
              {this.state.loading ? (
                <ClipLoader
                  size={10}
                  color={"#fff"}
                  loading={this.state.loading}
                />
              ) : (
                <Button variant="outline-light" onClick={this.fetchData}>
                  Refresh
                </Button>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Card.Text>Bots: {this.state.botTotal}</Card.Text>
          <Card.Text>Non Bots: {this.state.userTotal}</Card.Text>
          <Card.Text>Created Today: {this.state.createdToday}</Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

export default UserTotalCard;
