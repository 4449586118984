import React, { useState, useContext } from "react";
import "./editscreensstyles.scss";
import { Link } from "react-router-dom";
import { Button, Modal, Alert } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import PremiumFeatures from "../../../carousels/PremiumFeatures";
import { api } from "../../../../api";
import ClipLoader from "react-spinners/ClipLoader";

var moment = require("moment");

function ManageSubscription(props) {
  const context = useContext(UserContext);
  const subscription = context.user.subscription;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [cancelError, setCancelError] = useState(false);

  const toggleTab = (e) => {
    props.toggleTab();
  };

  const toggleConfirmModal = (e) => {
    if (confirmModalOpen) {
      setConfirmModalOpen(false);
    } else {
      setConfirmModalOpen(true);
    }
  };

  const cancelSubscriptionHandler = (e) => {
    toggleConfirmModal();
    setCancelLoading(true);
    const data = {
      userid: context.user._id,
    };

    return api
      .cancelSubscription(data)
      .then((res) => {
        if (res.status === 200) {
          var successfulCancel = res.data.successfulCancel;
          if (successfulCancel) {
            var tempSubscription = context.user.subscription;
            tempSubscription.active = false;
            context.updateUserInfo("subscription", tempSubscription);

            setCancelSuccess(true);
            setCancelError(false);
            setCancelLoading(false);
          } else {
            setCancelSuccess(false);
            setCancelError(true);
            setCancelLoading(false);
          }
        } else {
          setCancelSuccess(false);
          setCancelError(true);
          setCancelLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (subscription) {
    var startDate = subscription.startDate;

    if (startDate) {
      startDate = moment(startDate).format("MMMM Do YYYY");
    }

    var active = subscription.active;
    if (active) {
      var status = "Active";
    } else {
      var status = "Not Active";
    }
    var plan = subscription.plan;
    var nextBillingDate = null;

    if (plan === "monthly") {
      nextBillingDate = moment(subscription.startDate)
        .add(1, "month")
        .format("MMMM Do YYYY");
    } else if (plan === "semiannual") {
      nextBillingDate = moment(subscription.startDate)
        .add(180, "days")
        .format("MMMM Do YYYY");
    } else if (plan === "annual") {
      nextBillingDate = moment(subscription.startDate)
        .add(365, "days")
        .format("MMMM Do YYYY");
    }

    var alert = subscription.alert;

    var currency = subscription.currency;
    var billingPrice = subscription.billingPrice;
  }

  return (
    <div className="edit-screen">
      {cancelLoading && (
        <div className="premium-checkout-loading-overlay">
          <ClipLoader color="white" />
        </div>
      )}
      {confirmModalOpen && (
        <Modal
          show={confirmModalOpen}
          onClose={toggleConfirmModal}
          className="modal-v2"
          centered
        >
          <Modal.Body className="modal-v2-body-deactivate">
            <i className="far fa-exclamation-circle number-alert-icon-confirm"></i>
            <h1 className="confirm-modal-title">Are you sure?</h1>
          </Modal.Body>
          <Modal.Footer className="modal-v2-footer">
            <Button
              className="modal-action-button"
              onClick={cancelSubscriptionHandler}
            >
              Yes, cancel my subscription
            </Button>
            <Button className="modal-close-button" onClick={toggleConfirmModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <div className="edit-screen-header">
        <div className="edit-screen-header-section-left">
          <Button
            className="edit-screen-header-return-button"
            onClick={toggleTab}
          >
            <i className="fas fa-chevron-left"></i>
          </Button>
        </div>
        <div className="edit-screen-header-section-middle">
          <h1 className="edit-screen-header-title">Manage Subscription</h1>
        </div>
        <div className="edit-screen-header-section-right">
          <Button
            className="edit-screen-header-done-button"
            onClick={toggleTab}
          >
            Done
          </Button>
        </div>
      </div>
      <div className="edit-screen-content">
        <div className="edit-screen-v2-section">
          <div className="edit-screen-v2-section-content">
            <div className="edit-screen-v2-section-row">
              <div className="edit-screen-v2-section-row-title-full-width">
                Status
              </div>
              <div className="edit-screen-v2-section-row-content">
                {subscription.active ? "Active" : "Not Active"}
              </div>
            </div>
            <div className="edit-screen-v2-section-row">
              <div className="edit-screen-v2-section-row-title-full-width">
                Plan
              </div>
              <div
                className="edit-screen-v2-section-row-content"
                style={{ textTransform: "capitalize" }}
              >
                {subscription.plan}
              </div>
            </div>
            <div className="edit-screen-v2-section-row">
              <div className="edit-screen-v2-section-row-title-full-width">
                {subscription.active
                  ? "Next Billing Date"
                  : "Subscription End Date"}{" "}
              </div>
              <div className="edit-screen-v2-section-row-content">
                {nextBillingDate}
              </div>
            </div>
            {cancelSuccess && (
              <Alert variant="success">
                You have successfully cancelled your subscription & will not be
                billed moving forward. You can resume your subscription at any
                time. Sorry to see you go!
              </Alert>
            )}
            {cancelError && (
              <Alert variant="warning">
                There was an error cancelling your subscription. Please try
                again or contact support.
              </Alert>
            )}
          </div>
        </div>
        {!context.user.premium && !subscription.active && (
          <div className="edit-screen-premium-cta"></div>
        )}

        <div className="edit-screen-cta-button-wrapper">
          {!context.user.premium && !subscription.active && <PremiumFeatures />}
          {context.user.premium || subscription.active ? (
            <div className="edit-screen-bottom-contact-wrapper">
              <h1 className="edit-screen-bottom-contact-title">
                Contact Support
              </h1>
              <p className="edit-screen-bottom-contact-subtitle">
                support@sugarbae.com
              </p>
            </div>
          ) : null}
          {subscription.active && (
            <Button
              className="edit-screen-cta-button-cancel"
              size="lg"
              onClick={toggleConfirmModal}
            >
              Cancel Subscription
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ManageSubscription;
