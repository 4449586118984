import React, { useState, useEffect } from "react";
import { api } from "../../api";
import "./graphs.scss";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend
} from "recharts";
var moment = require("moment");

function DailySignups(props) {
  //const [maleSignupData, setMaleSignupData] = useState([]);
  //const [femaleSignupData, setFemaleSignupData] = useState([]);
  const [signupData, setSignupData] = useState([]); 
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    return api
      .fetchSignupData()
      .then((res) => {
        if (res.status === 200) {
          //setMaleSignupData(res.data.maleSignupData);
          //setFemaleSignupData(res.data.femaleSignupData);
          setSignupData(res.data.signupData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderCustomAxisTick = ({ x, y, payload }) => {
    var date = payload.value;

    var formattedDate = moment(date).format("MMM DD").toString();

    return (
      <text x={x} y={y + 25} fill="#666" textAnchor="middle">
        {formattedDate}
      </text>
    );
  };
  return (
    <div className="graph-container">
      <div className="graph-header">
        <h1 className="graph-title">Daily Signup by Gender </h1>
      </div>
      <div className="graph-content-container">
        <div className="graph-content">
          <h2 className="graph-content-title">SugarBae and alphaDate</h2>
          <BarChart
            width={500}
            height={300}
            data={signupData}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Tooltip />
            <Bar dataKey="SBfemale" stackId="a" fill="#db2adb" />
            <Bar dataKey="SBmale" stackId="a" fill="#525cd1" />
            <Bar dataKey="ADfemale" stackId="a" fill="#eda8e4" />
            <Bar dataKey="ADmale" stackId="a" fill="#acb0e8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="_id" tick={renderCustomAxisTick} />
            <YAxis />
          </BarChart>
        </div>
      </div>
    </div>
  );
}

export default DailySignups;
