import React, { useState, useContext } from "react";
import "./editscreensstyles.scss";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";

function DeactivateAccount(props) {
  const context = useContext(UserContext);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const toggleTab = (e) => {
    props.toggleTab();
  };

  const toggleConfirmModal = (e) => {
    if (confirmModalOpen) {
      setConfirmModalOpen(false);
    } else {
      setConfirmModalOpen(true);
    }
  };

  const deactivateAccountHandler = (e) => {
    const data = {
      userid: context.user._id,
    };

    return api
      .deactivateAccount(data)
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("auth");
          props.history.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="edit-screen">
      {confirmModalOpen && (
        <Modal
          show={confirmModalOpen}
          onClose={toggleConfirmModal}
          className="modal-v2"
          centered
        >
          <Modal.Body className="modal-v2-body-deactivate">
            <i className="far fa-exclamation-circle number-alert-icon-confirm"></i>
            <h1 className="confirm-modal-title">Are you sure?</h1>
          </Modal.Body>
          <Modal.Footer className="modal-v2-footer">
            <Button
              className="modal-action-button"
              onClick={deactivateAccountHandler}
            >
              Yes, deactivate my account
            </Button>
            <Button className="modal-close-button" onClick={toggleConfirmModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <div className="edit-screen-header">
        <div className="edit-screen-header-section-left">
          <Button
            className="edit-screen-header-return-button"
            onClick={toggleTab}
          >
            <i className="fas fa-chevron-left"></i>
          </Button>
        </div>
        <div className="edit-screen-header-section-middle">
          <h1 className="edit-screen-header-title">Deactivate Account</h1>
        </div>
        <div className="edit-screen-header-section-right">
          <Button
            className="edit-screen-header-done-button"
            onClick={toggleTab}
          >
            Close
          </Button>
        </div>
      </div>
      <div className="edit-screen-content">
        <div className="edit-screen-v2-section">
          <div className="edit-screen-v2-section-content">
            <h1 className="deactivate-account-title">
              Deactivate and Remove your Sugarbae account.
            </h1>
            <p className="deactivate-account-subtitle">
              Are you sure you want to deactivate your account? You will lose
              all your matches and chats. You can reactivate your account by
              logging in anytime.
            </p>
            <div className="edit-screen-status-wrapper">
              <i className="far fa-exclamation-circle number-alert-icon"></i>
              <span>You will be automatically logged out after confirming</span>
            </div>
          </div>
        </div>

        <div className="edit-screen-cta-button-wrapper">
          <Button
            className="edit-screen-cta-button-upgrade"
            size="lg"
            onClick={toggleConfirmModal}
          >
            Confirm Deactivation
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DeactivateAccount;
