import React, { useState, useContext } from "react";
import "./desktopsliders.scss";
import { Image, Form } from "react-bootstrap";
import { UserContext } from "../../../../../context/UserContext";

function Relationship(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [relationshipStatus, setRelationshipStatus] = useState(
    filters.relationshipstatus
  );

  const changeHandler = (e) => {
    const relationshipStatusCopy = relationshipStatus;

    if (e.target.value === "single") {
      if (relationshipStatusCopy.single) {
        relationshipStatusCopy.single = false;
      } else {
        relationshipStatusCopy.single = true;
      }
    } else if (e.target.value === "divorced") {
      if (relationshipStatusCopy.divorced) {
        relationshipStatusCopy.divorced = false;
      } else {
        relationshipStatusCopy.divorced = true;
      }
    } else if (e.target.value === "separated") {
      if (relationshipStatusCopy.separated) {
        relationshipStatusCopy.separated = false;
      } else {
        relationshipStatusCopy.separated = true;
      }
    } else if (e.target.value === "marriedbutlooking") {
      if (relationshipStatusCopy.marriedbutlooking) {
        relationshipStatusCopy.marriedbutlooking = false;
      } else {
        relationshipStatusCopy.marriedbutlooking = true;
      }
    } else if (e.target.value === "openrelationship") {
      if (relationshipStatusCopy.openrelationship) {
        relationshipStatusCopy.openrelationship = false;
      } else {
        relationshipStatusCopy.openrelationship = true;
      }
    } else if (e.target.value === "widowed") {
      if (relationshipStatusCopy.widowed) {
        relationshipStatusCopy.widowed = false;
      } else {
        relationshipStatusCopy.widowed = true;
      }
    }

    setRelationshipStatus((prevState) => ({
      ...prevState,
      ...relationshipStatusCopy,
    }));
  };

  const saveHandler = (e) => {
    props.updateHandler(relationshipStatus);
  };

  const resetHandler = (e) => {};

  return (
    <div className="desktop-v2-filters-content">
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.onClose}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-icon">
              <i className="far fa-heart filter-sort-icon" />
            </div>
            <div className="desktop-v2-filters-slider-title">
              Relationship Status
            </div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={resetHandler}
            >
              Reset
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Single"
                type="checkbox"
                value="single"
                checked={relationshipStatus.single}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Divorced"
                type="checkbox"
                value="divorced"
                checked={relationshipStatus.divorced}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Separated"
                type="checkbox"
                value="separated"
                checked={relationshipStatus.separated}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Married But Looking"
                type="checkbox"
                value="marriedbutlooking"
                checked={relationshipStatus.marriedbutlooking}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Open Relationship"
                type="checkbox"
                value="openrelationship"
                checked={relationshipStatus.openrelationship}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Widowed"
                type="checkbox"
                value="widowed"
                checked={relationshipStatus.widowed}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="desktop-filter-slider-footer" onClick={saveHandler}>
          <div className="desktop-filter-slider-apply-button">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default Relationship;
