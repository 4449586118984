import React from "react";
import "./featuresv2.scss";
import { Button, Image } from "react-bootstrap";
import firstPlace from "../../../assets/Images/Features/first_place.png";
import giftImage from "../../../assets/Images/Features/gift.png";
import idealImage from "../../../assets/Images/Features/ideal.png";
import chatImage from "../../../assets/Images/Features/chat.png";
import verifiedImage from "../../../assets/Images/Features/verified.png";
import freeImage from "../../../assets/Images/Features/free.png";
import { Link } from "react-router-dom";

function Featuresv2() {
  return (
    <div className="features-content">
      <div className="features-header">
        <div className="features-header-content">
          <h2 className="features-header-title">
            The #1 Sugar Daddy Dating App
          </h2>
          <p className="features-header-subtitle">
            Some of the features that set us apart...
          </p>
        </div>
      </div>
      <div className="features-content-container">
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={firstPlace} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">
              The Fastest Growing Sugar Dating Website
            </h2>
            <p className="features-text">
              With over 700k+ active members and our rapid growth pace, you can
              rest assured that there are endless options for you to choose from
              on our site.
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={idealImage} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">Your Ideal Arrangement</h2>
            <p className="features-text">
              Finally a dating experience where you can find what you are
              actually looking for. Stop wasting time swiping on other apps and
              find exactly what you want in just minutes.
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={chatImage} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">Real-Time Messaging</h2>
            <p className="features-text">
              Set your profile up in a jiffy and begin messaging successful men
              and attractive women. Chat, discuss an arrangement, and plan to
              meet in real life.
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={giftImage} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">Choose Your Benefits</h2>
            <p className="features-text">
              When creating your profile, you will select the specific dating
              benefits that you are looking for. Sugar dating is about finding
              your ideal arrangement, and cutting straight to the point with no
              games or delay in between
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={verifiedImage} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">Background Verified Profiles</h2>
            <p className="features-text">
              Sugarbae takes fake accounts, scams, and catfishes very seriously.
              Over 75% of the profiles on Sugarbae are verified - meaning you
              know who you are talking to is real and their pictures are
              accurate.
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
        <div className="features-card">
          <div className="features-icon-wrapper">
            <Image src={freeImage} className="features-icon-image" />
          </div>
          <div className="features-content-wrapper">
            <h2 className="features-title">100% Free to Use</h2>
            <p className="features-text">
              Sugarbae profile in just minutes, 100% free. No catches. No credit
              card required. Begin messaging users and find your ideal
              arrangement right now.
            </p>
            <div className="features-card-cta">
              <Link to="/signup">
                <Button className="features-card-button">
                  Get Started
                  <i className="fas fa-chevron-right features-card-button-icon"></i>
                </Button>
              </Link>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Featuresv2;
