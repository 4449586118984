import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { motion } from "framer-motion";
import { Button, Form } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";

import { UserContext } from "../../../../../context/UserContext";

import "./editvalue.scss";
import { api } from "../../../../../api";

const heights = [
  "4' 0\"",
  "4' 1\"",
  "4' 2\"",
  "4' 3\"",
  "4' 4\"",
  "4' 5\"",
  "4' 6\"",
  "4' 7\"",
  "4' 8\"",
  "4' 9\"",
  "4' 10\"",
  "4' 11\"",
  "5' 0\"",
  "5' 1\"",
  "5' 2\"",
  "5' 3\"",
  "5' 4\"",
  "5' 5\"",
  "5' 6\"",
  "5' 7\"",
  "5' 8\"",
  "5' 9\"",
  "5' 10\"",
  "5' 11\"",
  "6' 0\"",
  "6' 1\"",
  "6' 2\"",
  "6' 3\"",
  "6' 4\"",
  "6' 5\"",
  "6' 6\"",
  "6' 7\"",
  "6' 8\"",
  "6' 9\"",
  "6' 10\"",
  "6' 11\"",
  "7' 0\"",
];

const EditValue = (props) => {
  const context = useContext(UserContext);
  const [editOption, setEditOption] = useState(null);
  const [value, setValue] = useState(null);
  const [height, setHeight] = useState(
    heights.findIndex((item) => item === context.user.height)
  );
  const [heightValue, setHeightValue] = useState(context.user.height);

  const heightChange = (event, newValue) => {
    setHeight(newValue);
    setHeightValue(heights[newValue]);
  };

  const heightSelect = (event, newValue) => {
    setHeight(newValue);
    setHeightValue(heights[newValue]);
  };

  const changeValue = (data) => {
    setValue(data);
  };

  const resetHandler = () => {
    setValue(null);
  };

  const applyHandler = () => {
    if (editOption === "Height") {
      if (heightValue) {
        const data = {
          userid: context.user._id,
          height: heightValue,
        };

        return api
          .updateProfileHeight(data)
          .then(() => {
            let tempUser = context.user;
            tempUser.height = heightValue;
            context.updateUserInfo("all", tempUser);
            props.closeHandler();
          })
          .catch(() => {});
      } else {
        props.closeHandler();
      }
    } else {
      if (!value) {
        props.closeHandler();
      } else {
        if (editOption === "Relationship Status") {
          const data = {
            userid: context.user._id,
            relationshipStatus: value,
          };
          return api.updateRelationshipStatus(data).then(() => {
            let tempUser = context.user;
            tempUser.lifestyle.relationship = value;
            context.updateUserInfo("all", tempUser);
            props.closeHandler();
          });
        } else if (editOption === "Bodytype") {
          const data = {
            userid: context.user._id,
            bodytype: value,
          };
          return api.updateBodytype(data).then(() => {
            let tempUser = context.user;
            tempUser.bodytype = value;
            context.updateUserInfo("all", tempUser);
            props.closeHandler();
          });
        } else if (editOption === "Ethinicity") {
          const data = {
            userid: context.user._id,
            ethnicity: value,
          };
          return api.updateEthnicity(data).then(() => {
            let tempUser = context.user;
            tempUser.ethnicity = value;
            context.updateUserInfo("all", tempUser);
            props.closeHandler();
          });
        } else if (editOption === "Drinking") {
          const data = {
            userid: context.user._id,
            drinking: value,
          };
          return api.updateDrinking(data).then(() => {
            let tempUser = context.user;
            tempUser.lifestyle.drinking = value;
            context.updateUserInfo("all", tempUser);
            props.closeHandler();
          });
        } else if (editOption === "Smoking") {
          const data = {
            userid: context.user._id,
            smoking: value,
          };
          return api.updateSmoking(data).then(() => {
            let tempUser = context.user;
            tempUser.lifestyle.smoking = value;
            context.updateUserInfo("all", tempUser);
            props.closeHandler();
          });
        } else if (editOption === "Cannabis") {
          const data = {
            userid: context.user._id,
            cannabis: value,
          };
          return api.updateCannabis(data).then(() => {
            let tempUser = context.user;
            tempUser.lifestyle.cannabis = value;
            context.updateUserInfo("all", tempUser);
            props.closeHandler();
          });
        } else if (editOption === "Education") {
          const data = {
            userid: context.user._id,
            education: value,
          };
          return api.updateEducation(data).then(() => {
            let tempUser = context.user;
            tempUser.lifestyle.education = value;
            context.updateUserInfo("all", tempUser);
            props.closeHandler();
          });
        } else if (editOption === "Kids") {
          const data = {
            userid: context.user._id,
            children: value,
          };
          return api.updateChildren(data).then(() => {
            let tempUser = context.user;
            tempUser.lifestyle.children = value;
            context.updateUserInfo("all", tempUser);
            props.closeHandler();
          });
        } else if (editOption === "Exercise") {
          const data = {
            userid: context.user._id,
            exercise: value,
          };
          return api.updateExercise(data).then(() => {
            let tempUser = context.user;
            tempUser.lifestyle.exercise = value;
            context.updateUserInfo("all", tempUser);
            props.closeHandler();
          });
        }
      }
    }
  };

  useEffect(() => {
    const { option } = props;
    if (option === "relationshipStatus") {
      setEditOption("Relationship Status");
      setValue(context.user.lifestyle.relationship);
    } else if (option === "height") {
      setEditOption("Height");
      setValue(context.user.height);
    } else if (option === "bodytype") {
      setEditOption("Bodytype");
      setValue(context.user.bodytype);
    } else if (option === "ethinicity") {
      setEditOption("Ethinicity");
      setValue(context.user.ethnicity);
    } else if (option === "drinking") {
      setEditOption("Drinking");
      setValue(context.user.lifestyle.drinking);
    } else if (option === "smoking") {
      setEditOption("Smoking");
      setValue(context.user.lifestyle.smoking);
    } else if (option === "cannabis") {
      setEditOption("Cannabis");
      setValue(context.user.lifestyle.cannabis);
    } else if (option === "education") {
      setEditOption("Education");
      setValue(context.user.lifestyle.education);
    } else if (option === "kids") {
      setEditOption("Kids");
      setValue(context.user.lifestyle.children);
    } else if (option === "exercise") {
      setEditOption("Exercise");
      setValue(context.user.lifestyle.exercise);
    }
  }, [props.option]);

  return (
    <div className="edit-value-component">
      <div className="edit-value-overlay" onClick={props.closeHandler} />
      <motion.div
        className="edit-profile-v2-input-value"
        initial={{ opacity: 0, y: "100%" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 0 }}
      >
        <div className="edit-profile-v2-input-header">
          <div className="edit-profile-v2-input-header-text">
            {editOption === "Relationship Status" && (
              <i className="far fa-heart edit-profile-v2-icon"></i>
            )}
            {editOption === "Height" && (
              <i className="far fa-ruler-vertical edit-profile-v2-icon"></i>
            )}
            {editOption === "Bodytype" && (
              <i className="far fa-ruler-vertical edit-profile-v2-icon"></i>
            )}
            {editOption === "Ethinicity" && (
              <i className="far fa-smile edit-profile-v2-icon"></i>
            )}
            {editOption === "Drinking" && (
              <i className="far fa-wine-glass edit-profile-v2-icon"></i>
            )}
            {editOption === "Smoking" && (
              <i className="far fa-smoking edit-profile-v2-icon"></i>
            )}
            {editOption === "Cannabis" && (
              <i className="far fa-cannabis edit-profile-v2-icon"></i>
            )}
            {editOption === "Education" && (
              <i className="far fa-graduation-cap edit-profile-v2-icon"></i>
            )}
            {editOption === "Kids" && (
              <i className="far fa-child edit-profile-v2-icon"></i>
            )}
            {editOption === "Exercise" && (
              <i className="far fa-dumbbell edit-profile-v2-icon"></i>
            )}
            <span className="edit-profile-v2-input-header-title">
              {editOption}
            </span>
          </div>
          <div className="edit-profile-v2-input-header-right">
            <Button
              className="edit-profile-v2-header-reset-button"
              disabled={!value}
              onClick={() => resetHandler()}
            >
              Reset
            </Button>
          </div>
        </div>
        {editOption === "Relationship Status" && (
          <div className="edit-profile-v2-input-body">
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Single")}
            >
              <input type="radio" checked={value === "Single"} />
              <label className="edit-profile-v2-radio-label">Single</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Divorced")}
            >
              <input type="radio" checked={value === "Divorced"} />
              <label className="edit-profile-v2-radio-label">Divorced</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Seperated")}
            >
              <input type="radio" checked={value === "Seperated"} />
              <label className="edit-profile-v2-radio-label">Seperated</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Married But Looking")}
            >
              <input type="radio" checked={value === "Married But Looking"} />
              <label className="edit-profile-v2-radio-label">
                Married But Looking
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Open Relationship")}
            >
              <input type="radio" checked={value === "Open Relationship"} />
              <label className="edit-profile-v2-radio-label">
                Open Relationship
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Widowed")}
            >
              <input type="radio" checked={value === "Widowed"} />
              <label className="edit-profile-v2-radio-label">Widowed</label>
            </div>
          </div>
        )}
        {editOption === "Bodytype" && (
          <div className="edit-profile-v2-input-body">
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Slim")}
            >
              <input type="radio" checked={value === "Slim"} />
              <label className="edit-profile-v2-radio-label">Slim</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Fit")}
            >
              <input type="radio" checked={value === "Fit"} />
              <label className="edit-profile-v2-radio-label">Fit</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Average")}
            >
              <input type="radio" checked={value === "Average"} />
              <label className="edit-profile-v2-radio-label">Average</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Curvy")}
            >
              <input type="radio" checked={value === "Curvy"} />
              <label className="edit-profile-v2-radio-label">Curvy</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("A Few Extra Pounds")}
            >
              <input type="radio" checked={value === "A Few Extra Pounds"} />
              <label className="edit-profile-v2-radio-label">
                A Few Extra Pounds
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Full / Overweight")}
            >
              <input type="radio" checked={value === "Full / Overweight"} />
              <label className="edit-profile-v2-radio-label">
                Full / Overweight
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Other")}
            >
              <input type="radio" checked={value === "Other"} />
              <label className="edit-profile-v2-radio-label">Other</label>
            </div>
          </div>
        )}
        {editOption === "Ethinicity" && (
          <div className="edit-profile-v2-input-body">
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Asian")}
            >
              <input type="radio" checked={value === "Asian"} />
              <label className="edit-profile-v2-radio-label">Asian</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Black / African Decent")}
            >
              <input
                type="radio"
                checked={value === "Black / African Decent"}
              />
              <label className="edit-profile-v2-radio-label">
                Black / African Decent
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Latin / Hispanic")}
            >
              <input type="radio" checked={value === "Latin / Hispanic"} />
              <label className="edit-profile-v2-radio-label">
                Latin / Hispanic
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("East Indian")}
            >
              <input type="radio" checked={value === "East Indian"} />
              <label className="edit-profile-v2-radio-label">East Indian</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Middle Eastern")}
            >
              <input type="radio" checked={value === "Middle Eastern"} />
              <label className="edit-profile-v2-radio-label">
                Middle Eastern
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Mixed")}
            >
              <input type="radio" checked={value === "Mixed"} />
              <label className="edit-profile-v2-radio-label">Mixed</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Native American")}
            >
              <input type="radio" checked={value === "Native American"} />
              <label className="edit-profile-v2-radio-label">
                Native American
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Pacific Islander")}
            >
              <input type="radio" checked={value === "Pacific Islander"} />
              <label className="edit-profile-v2-radio-label">
                Pacific Islander
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("White / Caucasian")}
            >
              <input type="radio" checked={value === "White / Caucasian"} />
              <label className="edit-profile-v2-radio-label">
                White / Caucasian
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Other")}
            >
              <input type="radio" checked={value === "Other"} />
              <label className="edit-profile-v2-radio-label">Other</label>
            </div>
          </div>
        )}
        {editOption === "Drinking" && (
          <div className="edit-profile-v2-input-body">
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Non Drinker")}
            >
              <input type="radio" checked={value === "Non Drinker"} />
              <label className="edit-profile-v2-radio-label">Non Drinker</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Social Drinker")}
            >
              <input type="radio" checked={value === "Social Drinker"} />
              <label className="edit-profile-v2-radio-label">
                Social Drinker
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Heavy Drinker")}
            >
              <input type="radio" checked={value === "Heavy Drinker"} />
              <label className="edit-profile-v2-radio-label">
                Heavy Drinker
              </label>
            </div>
          </div>
        )}
        {editOption === "Smoking" && (
          <div className="edit-profile-v2-input-body">
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Non Smoker")}
            >
              <input type="radio" checked={value === "Non Smoker"} />
              <label className="edit-profile-v2-radio-label">Non Smoker</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Light Smoker")}
            >
              <input type="radio" checked={value === "Light Smoker"} />
              <label className="edit-profile-v2-radio-label">
                Light Smoker
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Heavy Smoker")}
            >
              <input type="radio" checked={value === "Heavy Smoker"} />
              <label className="edit-profile-v2-radio-label">
                Heavy Smoker
              </label>
            </div>
          </div>
        )}
        {editOption === "Cannabis" && (
          <div className="edit-profile-v2-input-body">
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Non Cannabis Consumer")}
            >
              <input type="radio" checked={value === "Non Cannabis Consumer"} />
              <label className="edit-profile-v2-radio-label">
                Non Cannabis Consumer
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Social Cannabis Consumer")}
            >
              <input
                type="radio"
                checked={value === "Social Cannabis Consumer"}
              />
              <label className="edit-profile-v2-radio-label">
                Social Cannabis Consumer
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Heavy Cannabis Consumer")}
            >
              <input
                type="radio"
                checked={value === "Heavy Cannabis Consumer"}
              />
              <label className="edit-profile-v2-radio-label">
                Heavy Cannabis Consumer
              </label>
            </div>
          </div>
        )}
        {editOption === "Education" && (
          <div className="edit-profile-v2-input-body">
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("High School")}
            >
              <input type="radio" checked={value === "High School"} />
              <label className="edit-profile-v2-radio-label">High School</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Some College")}
            >
              <input type="radio" checked={value === "Some College"} />
              <label className="edit-profile-v2-radio-label">
                Some College
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Associates Degree")}
            >
              <input type="radio" checked={value === "Associates Degree"} />
              <label className="edit-profile-v2-radio-label">
                Associates Degree
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Bachelors Degree")}
            >
              <input type="radio" checked={value === "Bachelors Degree"} />
              <label className="edit-profile-v2-radio-label">
                Bachelors Degree
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Graduate Degree")}
            >
              <input type="radio" checked={value === "Graduate Degree"} />
              <label className="edit-profile-v2-radio-label">
                Graduate Degree
              </label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("PhD / Post Doctoral")}
            >
              <input type="radio" checked={value === "PhD / Post Doctoral"} />
              <label className="edit-profile-v2-radio-label">
                PhD / Post Doctoral
              </label>
            </div>
          </div>
        )}
        {editOption === "Kids" && (
          <div className="edit-profile-v2-input-body">
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("No Kids")}
            >
              <input type="radio" checked={value === "No Kids"} />
              <label className="edit-profile-v2-radio-label">No Kids</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("1 Kid")}
            >
              <input type="radio" checked={value === "1 Kid"} />
              <label className="edit-profile-v2-radio-label">1 Kid</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("2 Kid")}
            >
              <input type="radio" checked={value === "2 Kid"} />
              <label className="edit-profile-v2-radio-label">2 Kid</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("3 Kid")}
            >
              <input type="radio" checked={value === "3 Kid"} />
              <label className="edit-profile-v2-radio-label">3 Kid</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("4+ Kid")}
            >
              <input type="radio" checked={value === "4+ Kid"} />
              <label className="edit-profile-v2-radio-label">4+ Kid</label>
            </div>
          </div>
        )}
        {editOption === "Exercise" && (
          <div className="edit-profile-v2-input-body">
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Never")}
            >
              <input type="radio" checked={value === "Never"} />
              <label className="edit-profile-v2-radio-label">Never</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Sometimes")}
            >
              <input type="radio" checked={value === "Sometimes"} />
              <label className="edit-profile-v2-radio-label">Sometimes</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Regularly")}
            >
              <input type="radio" checked={value === "Regularly"} />
              <label className="edit-profile-v2-radio-label">Regularly</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Often")}
            >
              <input type="radio" checked={value === "Often"} />
              <label className="edit-profile-v2-radio-label">Often</label>
            </div>
            <div
              className="edit-profile-v2-input-option"
              onClick={() => changeValue("Daily")}
            >
              <input type="radio" checked={value === "Daily"} />
              <label className="edit-profile-v2-radio-label">Daily</label>
            </div>
          </div>
        )}
        {editOption === "Height" && (
          <Form.Group className="edit-profile-v2-height">
            <Form.Label className="edit-profile-v2-height-label">
              {`Height: ${heightValue ? heightValue : ""}`}
            </Form.Label>
            <Slider
              min={0}
              max={36}
              value={height}
              onChange={heightChange}
              onChangeCommitted={heightSelect}
            />
          </Form.Group>
        )}
        <Button
          className="edit-profile-v2-apply-button"
          onClick={() => applyHandler()}
        >
          Apply
        </Button>
      </motion.div>
    </div>
  );
};

export default withRouter(EditValue);
