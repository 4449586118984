import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Countdown from "react-countdown";
import {
  Button,
  Modal,
} from "react-bootstrap";

import "../modals.scss";

export const SpamModal = props => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    setShow(true);
  }, []);

  const closeHandler = () => {
    setShow(false);
  };

  useEffect(() => {
    if (!show) {
      props.closeHandler();
    }
  }, [show]);

  return (
    <Modal size="lg" show={show} onHide={closeHandler}>
      <Modal.Body className="spam-pop-modal-body-message">
        <div className="swipe-pop-icon">
          <i className="fas fa-comment-alt-lines swipe-pop-main-icon-message"></i>{" "}
        </div>
        <h2 className="swipe-pop-body-title">Lock Messaging!</h2>
        <div className="swipe-pop-body-countdown-wrapper"></div>
        <p className="swipe-pop-body-subtitle">
          {props.description}
        </p>
        <div className="swipe-pop-body-countdown-wrapper">
          <Countdown
            date={new Date(props.timeout)}
            className="swipe-pop-timer"
            daysInHours="true"
          ></Countdown>
        </div>
      </Modal.Body>
      <Modal.Footer className="swipe-pop-footer">
        <Button
          className="swipe-pop-close-btn"
          onClick={closeHandler}
          size="lg"
          variant="link"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(SpamModal);