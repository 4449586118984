import React, { useState, useContext, useEffect } from "react";
import { Image, Button, Form, InputGroup } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import phoneIcon from "../../../../assets/Icons/phoneIcon.svg";
import emailIcon from "../../../../assets/Icons/emailIcon.svg";
import userIcon from "../../../../assets/Icons/userIcon.svg";
import verifiedIcon from "../../../../assets/Icons/verifiedGreen.png";
import { UserContext } from "../../../../context/UserContext";
import EditEmail from "./EditEmail";
import EditPhone from "./EditPhone";

function AccountSettings(props) {
  const context = useContext(UserContext);

  const [email, setEmail] = useState(context.user.email);
  const [number, setNumber] = useState("");
  const [noNumber, setNoNumber] = useState(false);

  const [editEmailOpen, setEditEmailOpen] = useState(false);
  const [emailVerifyOpen, setEmailVerifyOpen] = useState(false);

  const [editPhoneOpen, setEditPhoneOpen] = useState(false);
  const [phoneVerifyOpen, setPhoneVerifyOpen] = useState(false);

  useEffect(() => {
    if (
      context.user.verifications.phone.number !== null &&
      context.user.verifications.phone.number !== undefined &&
      context.user.verifications.phone.number.length > 0
    ) {
      setNumber(context.user.verifications.phone.number);
    } else {
      setNoNumber(true);
    }
  }, []);

  const toggleEditPhone = (e) => {
    if (editPhoneOpen) {
      setEditPhoneOpen(false);
    } else {
      setEditPhoneOpen(true);
    }
  };

  const toggleVerifyPhone = (e) => {
    if (phoneVerifyOpen) {
      setPhoneVerifyOpen(false);
    } else {
      setPhoneVerifyOpen(true);
    }
  };

  const toggleEditEmail = (e) => {
    if (editEmailOpen) {
      setEditEmailOpen(false);
    } else {
      setEditEmailOpen(true);
    }
  };

  const toggleVerifyEmail = (e) => {
    if (emailVerifyOpen) {
      setEmailVerifyOpen(false);
    } else {
      setEmailVerifyOpen(true);
    }
  };

  const refreshEmailHandler = (e) => {
    setEmail(context.user.email);
  };

  const refreshPhoneHandler = (e) => {
    setNumber(context.user.verifications.phone.number);
  };

  let emailVerified = false;
  if (context.user.verifications.email.status) {
    emailVerified = true;
  }

  let phoneVerified = false;
  if (context.user.verifications.phone.status) {
    phoneVerified = true;
  }

  if (editEmailOpen) {
    return (
      <EditEmail
        editEmailOpenToggle={() => setEditEmailOpen(false)}
        verifyEmailOpenToggle={() => setEmailVerifyOpen(false)}
        refresh={refreshEmailHandler}
        option="edit"
      />
    );
  }

  if (emailVerifyOpen) {
    return (
      <EditEmail
        editEmailOpenToggle={() => setEditEmailOpen(false)}
        verifyEmailOpenToggle={() => setEmailVerifyOpen(false)}
        refresh={refreshEmailHandler}
        option="verification"
      />
    );
  }

  if (editPhoneOpen) {
    return (
      <EditPhone
        editPhoneOpenToggle={() => setEditPhoneOpen(false)}
        verifyPhoneOpenToggle={() => setPhoneVerifyOpen(false)}
        refresh={refreshPhoneHandler}
        option="edit"
      />
    );
  }

  if (phoneVerifyOpen) {
    return (
      <EditPhone
        editPhoneOpenToggle={() => setEditPhoneOpen(false)}
        verifyPhoneOpenToggle={() => setPhoneVerifyOpen(false)}
        refresh={refreshPhoneHandler}
        option="verification"
      />
    );
  }

  return (
    <div className="desktop-v2-settings-content">
      <div className="desktop-v2-settings-row">
        <div className="desktop-v2-settings-row-innerwrap">
          <div className="desktop-v2-settings-row-section">
            <Image src={phoneIcon} className="desktop-v2-settings-icon" fluid />
            <span className="desktop-v2-settings-title">Phone Number</span>
          </div>
          {noNumber ? (
            <div className="desktop-v2-settings-row-section">
              <div
                className="desktop-v2-settings-add-number-button"
                onClick={toggleEditPhone}
              >
                <i
                  className="far fa-exclamation-circle"
                  style={{ marginRight: "5px" }}
                />
                <span>Add Phone Number</span>
              </div>
            </div>
          ) : (
            <div className="desktop-v2-settings-row-section">
              <div
                className="desktop-v2-settings-edit-button"
                onClick={toggleEditPhone}
              >
                <i className="far fa-edit" />
              </div>
              <Form className="desktop-v2-settings-form">
                <Form.Group className="desktop-v2-settings-form-group">
                  <PhoneInput
                    country="us"
                    value={number}
                    preferredCountries={["us", "ca", "gb"]}
                    disabled
                  />
                  {phoneVerified ? (
                    <Image
                      src={verifiedIcon}
                      className="desktop-v2-settings-form-verified-icon"
                      fluid
                    />
                  ) : (
                    <div
                      className="desktop-v2-settings-verify-button"
                      onClick={toggleVerifyPhone}
                    >
                      <i
                        className="far fa-exclamation-circle"
                        style={{ marginRight: "5px" }}
                      />
                      <span>Verify</span>
                    </div>
                  )}
                </Form.Group>
              </Form>
            </div>
          )}
        </div>
      </div>
      <div className="desktop-v2-settings-row">
        <div className="desktop-v2-settings-row-innerwrap">
          <div className="desktop-v2-settings-row-section">
            <Image src={emailIcon} className="desktop-v2-settings-icon" fluid />
            <span className="desktop-v2-settings-title">Email</span>
          </div>
          <div className="desktop-v2-settings-row-section">
            <div
              className="desktop-v2-settings-edit-button"
              onClick={toggleEditEmail}
            >
              <i className="far fa-edit" />
            </div>
            <Form className="desktop-v2-settings-form">
              <Form.Group className="desktop-v2-settings-form-group">
                <Form.Control
                  type="email"
                  value={email}
                  disabled
                  className="desktop-v2-settings-form-control"
                />
                {emailVerified ? (
                  <Image
                    src={verifiedIcon}
                    className="desktop-v2-settings-form-verified-icon"
                    fluid
                  />
                ) : (
                  <div
                    className="desktop-v2-settings-verify-button"
                    onClick={toggleVerifyEmail}
                  >
                    <i
                      className="far fa-exclamation-circle"
                      style={{ marginRight: "5px" }}
                    />
                    <span>Verify</span>
                  </div>
                )}
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      {/* <div className="desktop-v2-settings-row">
        <div className="desktop-v2-settings-row-innerwrap no-bottom-border">
          <div className="desktop-v2-settings-row-section">
            <Image src={userIcon} className="desktop-v2-settings-icon" fluid />
            <span className="desktop-v2-settings-title">Username</span>
          </div>
          <div className="desktop-v2-settings-row-section">
            <span>Form</span>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default AccountSettings;
