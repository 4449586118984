import React, { useState, useContext } from "react";
import { Button, Modal, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { api } from "../../../api";

export const ReportModal = props => {
  const context = useContext(UserContext);
  const [reportOpen, setReportOpen] = useState(true);
  const reportUser = props.card;
  const { reportHandler } = props;

  const reportToggle = (e) => {
    props.closeHandler();
  };

  const submitReport = props => {
    const data = {
      userid: context.user._id,
      cardid: reportUser._id,
      reason: props,
    };

    return api
      .submitReport(data)
      .then((res) => {
        reportHandler();
        reportToggle();
      })
      .catch((err) => {});
  }

  return (
    <Modal
      show={reportOpen}
      onHide={reportToggle}
      centered
      className="match-card-modal"
    >
      <Modal.Header className="match-card-modal-header">
        <Modal.Title className="match-card-modal-title">
          Report {props.card.username}
        </Modal.Title>
        <p className="match-card-modal-subtitle">
          Reports are kept anonymous.
        </p>
      </Modal.Header>
      <Modal.Body className="match-modal-body">
        <Button
          variant="outline-secondary"
          className="match-modal-footer-btn-main"
          onClick={() => submitReport("Inappropriate Behavior")}
          size="lg"
        >
          Inappropriate Behavior
        </Button>
        <Button
          variant="outline-secondary"
          className="match-modal-footer-btn-main"
          onClick={() => submitReport("Feels Like Spam")}
          size="lg"
        >
          Feels like spam
        </Button>
        <Button
          variant="outline-secondary"
          className="match-modal-footer-btn-main"
          onClick={() => submitReport("Other")}
          size="lg"
        >
          Other
        </Button>
      </Modal.Body>
      <Modal.Footer className="match-modal-footer">
        <Button
          variant="outline-secondary"
          className="match-modal-footer-btn"
          onClick={() => reportToggle()}
          size="lg"
        >
          {"Cancel"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default withRouter(ReportModal);