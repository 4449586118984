import React, { useState, useEffect } from "react";
import { api } from "../../api";
import DailySignups from "../graphs/DailySignups";
import Engagement from "../graphs/Engagement";
import "./views.scss";

function EngagementView(props) {
  return (
    <div className="view-container">
      <div className="view-row">
        <Engagement/>
      </div>
    </div>
  );
}

export default EngagementView;
