import React, { Component } from "react";
import "./searchfilters.scss";
import { Card, Form, Button, Badge } from "react-bootstrap";
import PlacesAutocomplete from "react-places-autocomplete";
import Slider from "@material-ui/core/Slider";
import Axios from "axios";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";

var heights = [
  "4'0\"",
  "4'1\"",
  "4'2\"",
  "4'3\"",
  "4'4\"",
  "4'5\"",
  "4'6\"",
  "4'7\"",
  "4'8\"",
  "4'9\"",
  "4'10\"",
  "4'11\"",
  "5'0\"",
  "5'1\"",
  "5'2\"",
  "5'3\"",
  "5'4\"",
  "5'5\"",
  "5'6\"",
  "5'7\"",
  "5'8\"",
  "5'9\"",
  "5'10\"",
  "5'11\"",
  "6'0\"",
  "6'1\"",
  "6'2\"",
  "6'3\"",
  "6'4\"",
  "6'5\"",
  "6'6\"",
  "6'7\"",
  "6'8\"",
  "6'9\"",
  "6'10\"",
  "6'11\"",
  "7'0\"",
];

export class SearchFilters extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      address: "",
      changingAddress: "",
      distance: 200,
      age: [18, 55],
      changed: false,
      filters: {},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (e) => {
    const data = {
      userid: this.context.user._id,
    };

    return api
      .fetchFilters(data)
      .then((res) => {
        if (res.status === 200) {
          var filters = res.data.filters;
          var age = filters.age;
          var address = res.data.address;
          var distance = filters.distance;
          this.setState({
            age: age,
            address: address,
            distance: distance,
            filters: filters,
          });
        }
      })
      .catch((err) => {});
  };

  saveFilters = async (e) => {
    const data = {
      userid: this.context.user._id,
      address: this.state.address,
      age: this.state.age,
      distance: this.state.distance,
    };

    return api
      .updateBasicFilters(data)
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {});
  };

  removeFiltersSave = async (e) => {
    const data = {
      user: this.context.user._id,
      filters: this.state.filters,
    };

    return api
      .updateFilters(data)
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {});
  };

  handleAddressChange = (address) => {
    this.setState({ changingAddress: address });
  };

  handleAddressSelect = (address) => {
    this.setState(
      { address: address, changingAddress: address, changed: true },
      () => {
        this.saveFilters();
      }
    );
  };

  handleDistanceChange = (event, newValue) => {
    this.setState({ distance: newValue, changed: true });
  };
  handleDistanceSelect = (e) => {
    this.saveFilters();
  };

  handleAgeChange = (event, newValue) => {
    this.setState({ age: newValue, changed: true });
  };
  handleAgeSelect = (e) => {
    this.saveFilters();
  };

  closeHandler = (e) => {
    this.props.closeHandler();

    if (this.state.changed) {
      this.props.updateHandler();
    }
  };

  advancedHandler = (e) => {
    this.props.advancedHandler();

    if (this.state.changed) {
      this.props.updateHandler();
    }
  };

  removeFilterHander = (props) => (e) => {
    var filters = this.state.filters;
    if (props === "verified") {
      var photosvideos = filters.photosvideos;
      photosvideos.verified = false;
    } else if (props === "age") {
      filters.age = [18, 80];
      this.setState({ age: [18, 80] });
    } else if (props === "distance") {
      filters.distance = 200;
      this.setState({ distance: 200 });
    } else if (props === "height") {
      filters.height = [0, 36];
    } else if (props === "bodytype") {
      var bodytype = {
        afewextrapounds: false,
        average: false,
        curvy: false,
        fit: false,
        fullfigured: false,
        muscular: false,
        slim: false,
      };
      filters.bodytype = bodytype;
    } else if (props === "ethnicity") {
      var ethnicity = {
        white: false,
        asian: false,
        hispanic: false,
        other: false,
        black: false,
        indian: false,
        middleeastern: false,
      };
      filters.ethnicity = ethnicity;
    } else if (props === "smoking") {
      var smoking = {
        lightsmoker: false,
        nonsmoker: false,
        heavysmoker: false,
      };
      filters.smoking = smoking;
    } else if (props === "drinking") {
      var drinking = {
        lightdrinker: false,
        heavydrinker: false,
        nondrinker: false,
      };
      filters.drinking = drinking;
    } else if (props === "exercise") {
      var exercise = {
        daily: false,
        never: false,
        often: false,
        regularly: false,
        sometimes: false,
      };
      filters.exercise = exercise;
    } else if (props === "children") {
      var children = {
        fourpluskids: false,
        nokids: false,
        onekid: false,
        threekids: false,
        twokids: false,
      };
      filters.children = children;
    } else if (props === "relationshipstatus") {
      var relationshipstatus = {
        single: false,
        divorced: false,
        marriedbutlooking: false,
        widowed: false,
        openrealtionship: false,
        separated: false,
      };
      filters.relationshipstatus = relationshipstatus;
    } else if (props === "education") {
      var education = {
        associatesdegree: false,
        bachelorsdegree: false,
        graduatedegree: false,
        highschool: false,
        phd: false,
        somecollege: false,
      };
      filters.education = education;
    } else if (props === "all") {
      this.setState({ age: [18, 80], distance: 200 });

      filters.age = [18, 80];
      filters.height = [0, 36];
      filters.distance = 200;
      filters.photosvideos = {
        photos: true,
        videos: false,
        verified: false,
      };
      filters.bodytype = {
        afewextrapounds: false,
        average: false,
        curvy: false,
        fit: false,
        fullfigured: false,
        muscular: false,
        slim: false,
      };
      filters.children = {
        fourpluskids: false,
        nokids: false,
        onekid: false,
        threekids: false,
        twokids: false,
      };
      filters.education = {
        associatesdegree: false,
        bachelorsdegree: false,
        graduatedegree: false,
        highschool: false,
        phd: false,
        somecollege: false,
      };
      filters.relationshipstatus = {
        single: false,
        divorced: false,
        marriedbutlooking: false,
        widowed: false,
        openrealtionship: false,
        separated: false,
      };
      filters.exercise = {
        daily: false,
        never: false,
        often: false,
        regularly: false,
        sometimes: false,
      };
      filters.ethnicity = {
        white: false,
        asian: false,
        hispanic: false,
        other: false,
        black: false,
        indian: false,
        middleeastern: false,
      };
      filters.smoking = {
        lightsmoker: false,
        nonsmoker: false,
        heavysmoker: false,
      };
      filters.drinking = {
        lightdrinker: false,
        heavydrinker: false,
        nondrinker: false,
      };
    }

    this.setState({ filters: filters, changed: true }, () => {
      this.removeFiltersSave();
    });
  };

  render() {
    const searchOptions = {
      types: ["(cities)"],
    };
    var filters = this.state.filters;

    var age = this.state.age;
    var distance = this.state.distance;
    var bodytype = filters.bodytype;
    var ethnicity = filters.ethnicity;
    var smoking = filters.smoking;
    var drinking = filters.drinking;
    var exercise = filters.exercise;
    var height = filters.height;
    var children = filters.children;
    var relationshipstatus = filters.relationshipstatus;
    var education = filters.education;
    var photosvideos = filters.photosvideos;

    var ageFilterActive = false;
    var distanceFilterActive = false;
    var bodytypeFilterActive = false;
    var ethnicityFilterActive = false;
    var smokingFilterActive = false;
    var drinkingFilterActive = false;
    var exerciseFilterActive = false;
    var heightFilterActive = false;
    var childrenFilterActive = false;
    var relationshipStatusFilterActive = false;
    var educationFilterActive = false;
    var verifiedFilterActive = false;

    if (age) {
      var ageMin = age[0];
      var ageMax = age[1];
      if (ageMin > 18 || ageMax < 80) {
        ageFilterActive = true;
      }
    }

    if (distance < 200) {
      distanceFilterActive = true;
    }

    if (bodytype) {
      if (
        bodytype.afewextrapounds ||
        bodytype.active ||
        bodytype.curvy ||
        bodytype.fit ||
        bodytype.fullfigured ||
        bodytype.muscular ||
        bodytype.slim
      ) {
        bodytypeFilterActive = true;
      }
    }

    if (ethnicity) {
      if (
        ethnicity.asian ||
        ethnicity.black ||
        ethnicity.hispanic ||
        ethnicity.indian ||
        ethnicity.middleeastern ||
        ethnicity.other ||
        ethnicity.white
      ) {
        ethnicityFilterActive = true;
      }
    }

    if (smoking) {
      if (smoking.heavysmoker || smoking.lightsmoker || smoking.nonsmoker) {
        smokingFilterActive = true;
      }
    }

    if (drinking) {
      if (
        drinking.nondrinker ||
        drinking.socialdrinker ||
        drinking.heavydrinker
      ) {
        drinkingFilterActive = true;
      }
    }

    if (exercise) {
      if (
        exercise.daily ||
        exercise.never ||
        exercise.often ||
        exercise.regularly ||
        exercise.sometimes
      ) {
        exerciseFilterActive = true;
      }
    }

    if (height) {
      var heightMin = height[0];
      var heightMax = height[1];
      var heightMinString = heights[heightMin];
      var heightMaxString = heights[heightMax];

      if (heightMin > 0 || heightMax < 36) {
        heightFilterActive = true;
      }
    }

    if (children) {
      if (
        children.fourpluskids ||
        children.nokids ||
        children.onekid ||
        children.threekids ||
        children.twokids
      ) {
        childrenFilterActive = true;
      }
    }

    if (relationshipstatus) {
      if (
        relationshipstatus.divorced ||
        relationshipstatus.marriedbutlooking ||
        relationshipstatus.openrelationship ||
        relationshipstatus.separated ||
        relationshipstatus.single ||
        relationshipstatus.widowed
      ) {
        relationshipStatusFilterActive = true;
      }
    }

    if (education) {
      if (
        education.associatesdegree ||
        education.bachelorsdegree ||
        education.graduatedegree ||
        education.highschool ||
        education.phd ||
        education.somecollege
      ) {
        educationFilterActive = true;
      }
    }

    if (photosvideos) {
      if (photosvideos.verified) {
        verifiedFilterActive = true;
      }
    }

    return (
      <div className="searchfilters-component">
        <div className="search-filters-overlay" onClick={this.closeHandler} />
        <Card className="search-filters-card">
          <Card.Header className="search-filters-header">
            <Button
              onClick={this.closeHandler}
              className="search-filters-close-btn"
            >
              <i className="fas fa-times search-filters-close-icon"></i>
            </Button>
            <Card.Title className="search-filters-title">
              Search Filters
            </Card.Title>
          </Card.Header>

          <Card.Body className="search-filters-body">
            <Card className="active-filters-card">
              <Card.Header className="active-filters-card-header">
                <Card.Title className="active-filters-card-title">
                  Active Filters
                </Card.Title>
                {verifiedFilterActive ||
                ageFilterActive ||
                distanceFilterActive ||
                heightFilterActive ||
                bodytypeFilterActive ||
                ethnicityFilterActive ||
                smokingFilterActive ||
                drinkingFilterActive ||
                childrenFilterActive ||
                exerciseFilterActive ||
                relationshipStatusFilterActive ||
                educationFilterActive ? (
                  <Button
                    className="active-filters-clear-all-btn"
                    onClick={this.removeFilterHander("all")}
                  >
                    Clear All Filters
                  </Button>
                ) : null}
              </Card.Header>
              <Card.Body className="active-filters-card-body">
                {!verifiedFilterActive &&
                !ageFilterActive &&
                !distanceFilterActive &&
                !heightFilterActive &&
                !bodytypeFilterActive &&
                !ethnicityFilterActive &&
                !smokingFilterActive &&
                !drinkingFilterActive &&
                !childrenFilterActive &&
                !exerciseFilterActive &&
                !relationshipStatusFilterActive &&
                !educationFilterActive ? (
                  <div className="no-active-filters-wrapper">
                    <span className="no-active-filter-text">
                      No Active Search Filters
                    </span>
                  </div>
                ) : null}

                {verifiedFilterActive ? (
                  <Button
                    className="search-active-filter-btn"
                    onClick={this.removeFilterHander("verified")}
                  >
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Verified: True
                  </Button>
                ) : null}
                {ageFilterActive ? (
                  <Button
                    className="search-active-filter-btn"
                    onClick={this.removeFilterHander("age")}
                  >
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Age: {ageMin}-{ageMax}
                  </Button>
                ) : null}
                {distanceFilterActive ? (
                  <Button
                    className="search-active-filter-btn"
                    onClick={this.removeFilterHander("distance")}
                  >
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Distance: Within {distance} Miles
                  </Button>
                ) : null}
                {heightFilterActive ? (
                  <Button
                    className="search-active-filter-btn"
                    onClick={this.removeFilterHander("height")}
                  >
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Height: {heightMinString} - {heightMaxString}
                  </Button>
                ) : null}
                {bodytypeFilterActive ? (
                  <Button
                    className="search-active-filter-btn"
                    onClick={this.removeFilterHander("bodytype")}
                  >
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Bodytype: {bodytype.slim ? "Slim" : null}{" "}
                    {bodytype.fit ? "Fit" : null}{" "}
                    {bodytype.muscular ? "Muscular" : null}{" "}
                    {bodytype.average ? "Average" : null}{" "}
                    {bodytype.afewextrapounds ? "A Few Extra Pounds" : null}{" "}
                    {bodytype.curvy ? "Curvy" : null}{" "}
                    {bodytype.fullfigured ? "Full Figured" : null}
                  </Button>
                ) : null}
                {ethnicityFilterActive ? (
                  <Button
                    className="search-active-filter-btn"
                    onClick={this.removeFilterHander("ethnicity")}
                  >
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Ethnicity: {ethnicity.asian ? "Asian" : null}{" "}
                    {ethnicity.black ? "Black" : null}{" "}
                    {ethnicity.hispanic ? "Hispanic" : null}{" "}
                    {ethnicity.indian ? "Indian" : null}{" "}
                    {ethnicity.middleeastern ? "Middle Eastern" : null}{" "}
                    {ethnicity.other ? "Other" : null}{" "}
                    {ethnicity.white ? "White" : null}
                  </Button>
                ) : null}
                {smokingFilterActive ? (
                  <Button className="search-active-filter-btn">
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Smoking: {smoking.nonsmoker ? "Non Smoker" : null}{" "}
                    {smoking.lightsmoker ? "Light Smoker" : null}{" "}
                    {smoking.heavysmoker ? "Heavy Smoker" : null}{" "}
                  </Button>
                ) : null}
                {drinkingFilterActive ? (
                  <Button
                    className="search-active-filter-btn"
                    onClick={this.removeFilterHander("smoking")}
                  >
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Drinking: {drinking.nondrinker ? "Non Drinker" : null}{" "}
                    {drinking.lightdrinker ? "Light Drinker" : null}{" "}
                    {drinking.heavydrinker ? "Heavy Drinker" : null}{" "}
                  </Button>
                ) : null}
                {exerciseFilterActive ? (
                  <Button
                    className="search-active-filter-btn"
                    onClick={this.removeFilterHander("exercise")}
                  >
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Exercise: {exercise.never ? "Never" : null}{" "}
                    {exercise.daily ? "Daily" : null}{" "}
                    {exercise.often ? "Often" : null}{" "}
                    {exercise.regularly ? "Regularly" : null}{" "}
                    {exercise.sometimes ? "Sometimes" : null}{" "}
                  </Button>
                ) : null}
                {childrenFilterActive ? (
                  <Button
                    className="search-active-filter-btn"
                    onClick={this.removeFilterHander("children")}
                  >
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Children: {children.nokids ? "No Kids" : null}{" "}
                    {children.fourpluskids ? "4+ Kids" : null}{" "}
                    {children.onekid ? "1 Kid" : null}{" "}
                    {children.threekids ? "3 Kids" : null}{" "}
                    {children.twokids ? "2 Kids" : null}{" "}
                  </Button>
                ) : null}
                {relationshipStatusFilterActive ? (
                  <Button
                    className="search-active-filter-btn"
                    onClick={this.removeFilterHander("relationshipstatus")}
                  >
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Relationship Status:{" "}
                    {relationshipstatus.divorced ? "Divorced" : null}{" "}
                    {relationshipstatus.marriedbutlooking
                      ? "Married But Looking"
                      : null}{" "}
                    {relationshipstatus.openrelationship
                      ? "Open Relationship"
                      : null}{" "}
                    {relationshipstatus.separated ? "Separated" : null}{" "}
                    {relationshipstatus.single ? "Single" : null}{" "}
                    {relationshipstatus.widowed ? "Widowed" : null}{" "}
                  </Button>
                ) : null}
                {educationFilterActive ? (
                  <Button
                    className="search-active-filter-btn"
                    onClick={this.removeFilterHander("education")}
                  >
                    <i className="fas fa-times search-filters-remove-icon"></i>
                    Education:{" "}
                    {education.associatesdegree
                      ? "Associates Degree"
                      : null}{" "}
                    {education.bachelorsdegree ? "Bachelors Degree" : null}{" "}
                    {education.graduatedegree ? "Graduate Degree" : null}{" "}
                    {education.highschool ? "High School" : null}{" "}
                    {education.phd ? "PhD" : null}{" "}
                    {education.somecollege ? "Some College" : null}{" "}
                  </Button>
                ) : null}
              </Card.Body>
            </Card>
            <Form>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Location
                </Form.Label>
                <PlacesAutocomplete
                  value={this.state.changingAddress}
                  onChange={this.handleAddressChange}
                  onSelect={this.handleAddressSelect}
                  searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="search-filters-location-input-wrapper">
                      <Form.Control
                        size="lg"
                        {...getInputProps({
                          placeholder: this.state.address,
                          className: "search-filters-location-input",
                        })}
                      />

                      <div className="search-filters-dropdown-card">
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Form.Group>
              <Form.Group>
                <Form.Label className="search-filters-label">Age</Form.Label>
                <div className="search-filters-slider-wrapper">
                  <h2 className="search-slider-title">
                    Between {this.state.age[0]} and {this.state.age[1]}
                  </h2>
                  <div className="slider-innerwrap">
                    <Slider
                      min={18}
                      max={80}
                      value={this.state.age}
                      onChange={this.handleAgeChange}
                      onChangeCommitted={this.handleAgeSelect}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Distance
                </Form.Label>
                <div className="search-filters-slider-wrapper">
                  <h2 className="search-slider-title">
                    Up to {this.state.distance} miles away
                  </h2>
                  <div className="slider-innerwrap">
                    <Slider
                      min={5}
                      max={200}
                      value={this.state.distance}
                      onChange={this.handleDistanceChange}
                      onChangeCommitted={this.handleDistanceSelect}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Label className="search-filters-label">
                  Filters
                </Form.Label>
                <div>
                  <Button
                    className="advanced-search-filters-btn"
                    size="lg"
                    onClick={this.advancedHandler}
                  >
                    Set advanced Search Filters
                    <i class="fas fa-chevron-right search-filters-btn-icon"></i>
                  </Button>
                </div>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default SearchFilters;
