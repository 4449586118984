import React, { useState, useEffect, useContext } from "react";
import "./desktopsearch.scss";
import DesktopNav from "../desktopNav/DesktopNav";
import { withRouter } from "react-router-dom";
import DesktopSearchFilters from "./desktopSearchFilters/DesktopSearchFilters";
import DesktopSearchResults from "./desktopSearchResults/DesktopSearchResults";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { UserContext } from "../../../context/UserContext";
import DesktopGrid from "./desktopGrid/DesktopGrid";
import { api } from "../../../api";

function DesktopSearch(props) {
  const context = useContext(UserContext);
  const [filters, setFilters] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async (e) => {
      const data = {
        userid: context.user._id,
      };

      return api
        .fetchFilters(data)
        .then((res) => {
          if (res.status === 200) {
            setFilters(res.data.filters);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="desktop-search-component">
        <DesktopNav />
        <div className="desktop-search-container">
          <div className="desktop-search-container-loading-innerwrap">
            {/* <ClipLoader /> */}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="desktop-search-component">
        <DesktopNav />
        <div className="desktop-search-container">
          <DesktopGrid filters={filters} />
        </div>
      </div>
    );
  }
}

export default withRouter(DesktopSearch);
