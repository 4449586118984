import React, { useState, useContext } from "react";
import "./desktopsliders.scss";
import Slider from "@material-ui/core/Slider";
import { Image } from "react-bootstrap";
import { UserContext } from "../../../../../context/UserContext";
import heightIcon from "../../../../../assets/Icons/heightIcon.svg";

function Age(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [age, setAge] = useState(filters.age);

  const changeHandler = (event, newValue) => {
    if (newValue[0] > 80) {
      newValue[0] = 80;
    } else if (newValue[0] < 18) {
      newValue[0] = 18;
    }

    if (newValue[1] > 80) {
      newValue[1] = 80;
    } else if (newValue[1] < 18) {
      newValue[1] = 18;
    }
    setAge(newValue);
  };

  const commitHandler = (event, newValue) => {
    if (newValue[0] > 80) {
      newValue[0] = 80;
    } else if (newValue[0] < 18) {
      newValue[0] = 18;
    }

    if (newValue[1] > 80) {
      newValue[1] = 80;
    } else if (newValue[1] < 18) {
      newValue[1] = 18;
    }
    setAge(newValue);
  };

  const saveHandler = (e) => {
    props.updateHandler(age);
  };

  const resetHandler = (e) => {
    setAge([18, 80]);
  };

  return (
    <div className="desktop-v2-filters-content">
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.backHandler}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-icon">
              <Image src={heightIcon} className="filter-v2-icon" fluid />
            </div>
            <div className="desktop-v2-filters-slider-title">Age Range</div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={resetHandler}
            >
              Reset
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <div className="desktop-filter-slider-v2-form-wrapper">
            <h1 className="filter-slider-v2-slider-title">
              Between <span style={{ fontWeight: "700" }}>{age[0]}</span> and
              <span style={{ fontWeight: "700" }}> {age[1]}</span>
            </h1>
            <div className="filter-slider-v2-slider-wrapper">
              <Slider
                min={17}
                max={88}
                value={age}
                onChange={changeHandler}
                onChangeCommitted={commitHandler}
                valueLabelDisplay="on"
              />
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-footer" onClick={saveHandler}>
          <div className="desktop-filter-slider-apply-button">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default Age;
