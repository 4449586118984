import React, { useState, useContext, useRef, useEffect } from 'react';
import './editscreensstyles.scss';
import { Link } from 'react-router-dom';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { UserContext } from '../../../../context/UserContext';
import ReactCodeInput from 'react-verification-code-input';
import EmailValidator from 'email-validator';
import PasswordValidator from 'password-validator';

import { api } from '../../../../api';

const passwordSchema = new PasswordValidator();
passwordSchema.is().min(6).is().max(100).has().not().spaces();

function Email(props) {
  const context = useContext(UserContext);
  const [email, setEmail] = useState(context.user.email);
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [passwordIncorrectError, setPasswordIncorrectError] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [emailInvalidError, setEmailInvalidError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [validationCode, setValidationCode] = useState('');
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
  const [sendAgainSuccess, setSendAgainSuccess] = useState(false);

  const inputRef = useRef(null);

  const toggleTab = (e) => {
    props.toggleTab();
  };

  useEffect(() => {
    if (!verifyOpen) {
      inputRef.current.focus();
    }
  }, [disabled]);

  const disableHandler = (e) => {
    if (disabled) {
      setEmail('');
      setPassword('');
      setDisabled(false);
    } else {
      setEmail(context.user.email);
      setDisabled(true);
      resetErrors();
    }
  };

  const validationCodeChange = async (e) => {
    setVerifyError(false);
    setValidationCode(e);

    if (e.length === 6) {
      setValidateLoading(true);

      const data = {
        validationcode: e,
      };

      await api
        .validateEmailToken(data)
        .then((res) => {
          if (res.status === 200) {
            context.updateUserInfo('verifications', res.data.verifications);
            setVerifySuccess(true);
            verifyHandler();
          }
        })
        .catch((err) => {
          console.log(err);
          setValidateLoading(false);
          setVerifyError(true);
        });
    }
  };

  const resetErrors = (e) => {
    setSendAgainSuccess(false);
    setVerifyButtonDisabled(false);
    setEmailInvalid(false);
    setPasswordInvalid(false);
    setPasswordIncorrectError(false);
    setEmailExistsError(false);
    setEmailInvalidError(false);
  };

  const changeHandler = (e) => {
    setEmail(e.target.value.toLowerCase());
    setEmailInvalid(false);
    setSubmitDisabled(false);
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
    setPasswordInvalid(false);
    setSubmitDisabled(false);
  };

  const sendAgainHandler = (e) => {
    sendEmailCode();
    setSendAgainSuccess(true);
  };

  const verifyHandler = (e) => {
    if (verifyOpen) {
      resetErrors();
      setDisabled(true);
      setVerifyOpen(false);
    } else {
      resetErrors();
      setVerifyButtonDisabled(true);
      sendEmailCode();
      setVerifyOpen(true);
    }
  };

  const sendEmailCode = async (e) => {
    const data = {
      user: context.user._id,
      email: email,
    };

    return api
      .generateEmailToken(data)
      .then((res) => {})
      .catch((err) => {});
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    setLoading(true);

    var emailValid = EmailValidator.validate(email);
    var passwordValid = passwordSchema.validate(password);

    if (!emailValid) {
      setEmailInvalid(true);
    }
    if (!passwordValid) {
      setPasswordInvalid(true);
    }

    if (emailValid && passwordValid) {
      var data = {
        email: email,
        password: password,
      };

      await api
        .updateEmail(data)
        .then((res) => {
          if (res.status === 200) {
            if (
              res.data.emailValid &&
              res.data.passwordValid &&
              !res.data.emailExists
            ) {
              context.updateUserInfo('email', email);
              var tempVerifications = context.user.verifications;
              tempVerifications.email.status = false;
              context.updateUserInfo('verifications', tempVerifications);
              verifyHandler();
            } else {
              if (!res.data.emailValid) {
                setEmailInvalid(true);

                if (!res.data.emailExists) {
                  setEmailInvalidError(true);
                }
              }

              if (!res.data.passwordValid) {
                setPasswordInvalid(true);
                setPasswordIncorrectError(true);
              }

              if (res.data.emailExists) {
                setEmailInvalid(true);
                setEmailExistsError(true);
              }
              setSubmitDisabled(false);
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setSubmitDisabled(false);
          setLoading(false);
        });
    }
  };

  var verified = context.user.verifications.email.status;

  return (
    <div className="edit-screen">
      <div className="edit-screen-header">
        <div className="edit-screen-header-section-left">
          <Button
            className="edit-screen-header-return-button"
            onClick={toggleTab}
          >
            <i className="fas fa-chevron-left"></i>
          </Button>
        </div>
        <div className="edit-screen-header-section-middle">
          <h1 className="edit-screen-header-title">Email</h1>
        </div>
      </div>
      <div className="edit-screen-content">
        <div className="edit-screen-input-wrapper">
          {verifyOpen ? (
            <Form className="edit-screen-email-form">
              <Form.Label className="edit-screen-form-label">
                Enter the 6 digit verification code we emailed you:
              </Form.Label>
              <ReactCodeInput
                onChange={validationCodeChange}
                loading={validateLoading}
                disabled={validateLoading}
                fieldWidth={'16%'}
                className="verification-code-input-v2"
              />
              <div className="edit-screen-button-wrapper">
                {sendAgainSuccess ? (
                  <span className="new-code-sent-text">New code sent</span>
                ) : (
                  <Button
                    className="edit-screen-send-again-button"
                    onClick={sendAgainHandler}
                  >
                    Send again
                  </Button>
                )}
              </div>
            </Form>
          ) : (
            <Form className="edit-screen-email-form">
              <Form.Group>
                {!disabled && <Form.Label>New Email</Form.Label>}

                <InputGroup className="verification-content-form">
                  <InputGroup.Prepend className="verification-content-form-prepend">
                    <InputGroup.Text className="verification-content-form-text">
                      <i className="far fa-envelope verification-form-icon"></i>
                    </InputGroup.Text>
                  </InputGroup.Prepend>

                  <Form.Control
                    type="email"
                    className="verification-content-form-input"
                    value={email}
                    disabled={disabled}
                    onChange={changeHandler}
                    ref={inputRef}
                    invalid={emailInvalid}
                  />
                </InputGroup>
              </Form.Group>
              {!disabled && (
                <Form.Group>
                  <Form.Label>Confirm Your Password</Form.Label>
                  <InputGroup className="verification-content-form">
                    <InputGroup.Prepend className="verification-content-form-prepend">
                      <InputGroup.Text className="verification-content-form-text">
                        <i className="far fa-key verification-form-icon"></i>
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Form.Control
                      type="password"
                      disabled={disabled}
                      value={password}
                      onChange={passwordChangeHandler}
                      size="lg"
                      invalid={passwordInvalid}
                    />
                  </InputGroup>
                </Form.Group>
              )}
            </Form>
          )}
        </div>
        {!verifyOpen && (
          <div>
            {disabled ? (
              <div className="edit-screen-status-container">
                {verified ? (
                  <div className="edit-screen-status-wrapper-verified">
                    <i className="far fa-check-circle number-verified-icon"></i>
                    <span>Verified Email</span>
                  </div>
                ) : (
                  <div className="edit-screen-status-wrapper">
                    <i className="far fa-exclamation-circle number-alert-icon"></i>
                    <span>You need to verifiy your email</span>
                  </div>
                )}
              </div>
            ) : (
              <div className="edit-screen-status-wrapper">
                <i className="far fa-exclamation-circle number-alert-icon"></i>
                <span>Enter new email and confirm your password</span>
              </div>
            )}
          </div>
        )}
        {emailInvalidError && (
          <div className="edit-screen-status-wrapper">
            <i className="far fa-exclamation-circle number-alert-icon"></i>
            <span>The email address you entered in invalid</span>
          </div>
        )}
        {passwordIncorrectError && (
          <div className="edit-screen-status-wrapper">
            <i className="far fa-exclamation-circle number-alert-icon"></i>
            <span>You entered the wrong password</span>
          </div>
        )}
        {emailExistsError && (
          <div className="edit-screen-status-wrapper">
            <i className="far fa-exclamation-circle number-alert-icon"></i>
            <span>The email you entered already exists in our system</span>
          </div>
        )}
        {verifyError && (
          <div className="edit-screen-status-wrapper">
            <i className="far fa-exclamation-circle number-alert-icon"></i>
            <span>The verification code you entered is incorrect</span>
          </div>
        )}
      </div>
      <div className="edit-screen-cta-button-wrapper">
        {verified ? (
          <div>
            {verifyOpen ? (
              <div className="edit-screen-cta-button-innerwrap">
                <Button
                  className="edit-screen-update-button"
                  size="lg"
                  onClick={verifyHandler}
                >
                  Back
                </Button>

                <Button
                  className="edit-screen-cta-button-upgrade"
                  size="lg"
                  disabled={verifyButtonDisabled}
                >
                  Verify
                </Button>
              </div>
            ) : (
              <div className="edit-screen-cta-button-innerwrap">
                {disabled ? (
                  <Button
                    className="edit-screen-cta-button-upgrade"
                    size="lg"
                    onClick={disableHandler}
                  >
                    Update email
                  </Button>
                ) : (
                  <div className="edit-screen-cta-button-inner-innerwrap">
                    <Button
                      className="edit-screen-update-button"
                      size="lg"
                      onClick={disableHandler}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="edit-screen-cta-button-upgrade"
                      size="lg"
                      onClick={updateHandler}
                      disabled={submitDisabled}
                    >
                      Confirm email
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            {verifyOpen ? (
              <div className="edit-screen-cta-button-innerwrap">
                <Button
                  className="edit-screen-update-button"
                  size="lg"
                  onClick={verifyHandler}
                >
                  Back
                </Button>

                <Button
                  className="edit-screen-cta-button-upgrade"
                  size="lg"
                  disabled={verifyButtonDisabled}
                >
                  Verify
                </Button>
              </div>
            ) : (
              <div className="edit-screen-cta-button-innerwrap">
                {disabled ? (
                  <div className="edit-screen-cta-button-inner-innerwrap">
                    <Button
                      className="edit-screen-update-button"
                      size="lg"
                      onClick={disableHandler}
                    >
                      Update my email
                    </Button>

                    <Button
                      className="edit-screen-cta-button-upgrade"
                      size="lg"
                      onClick={verifyHandler}
                      disabled={verifyButtonDisabled}
                    >
                      Verify
                    </Button>
                  </div>
                ) : (
                  <div className="edit-screen-cta-button-inner-innerwrap">
                    <Button
                      className="edit-screen-update-button"
                      size="lg"
                      onClick={disableHandler}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="edit-screen-cta-button-upgrade"
                      size="lg"
                      onClick={updateHandler}
                    >
                      Confirm email
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Email;
