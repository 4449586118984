import React, { Component } from "react";
import HomeNav from "../../../components/navbar/HomeNav/HomeNav";
import "../landingpage.scss";
import { Image, Form, Button, Alert, ProgressBar, Card } from "react-bootstrap";
import logo from "../../../assets/logos/logo_white.png";
import { Link } from "react-router-dom";
import nytLogo from "../../../assets/Images/Landing/recommended-by/nyt-logo.png";
import cnnLogo from "../../../assets/Images/Landing/recommended-by/cnn-logo.png";
import peopleLogo from "../../../assets/Images/Landing/recommended-by/people-logo.png";
import huffLogo from "../../../assets/Images/Landing/recommended-by/huff-logo.png";
import PlacesAutocomplete from "react-places-autocomplete";
import EmailValidator from "email-validator";
import Axios from "axios";
import PasswordValidator from "password-validator";
import ReactGA from "react-ga";
import { geocodeByAddress } from "react-places-autocomplete";
import { withRouter } from "react-router-dom";
import api from "../../../api/api";

const passwordSchema = new PasswordValidator();

passwordSchema.is().min(6).is().max(100).has().not().spaces();

const Joi = require("@hapi/joi");
const usernameSchema = Joi.object({
  username: Joi.string().alphanum().min(3).max(30).required(),
});

export class HookupLanding extends Component {
  state = {
    email: "",
    emailInvalid: false,
    stepsOpen: false,
    step: "gender",
    gender: "",
    address: "",
    changingAddress: "",
    addressInvalid: false,
    age: null,
    ageInvalid: false,
    username: "",
    usernameInvalid: false,
    usernameExistsError: false,
    password: "",
    confirmPassword: "",
    passwordInvalid: false,
    passwordNotMatchError: false,
    progress: 20,
  };

  emailSubmitHandler = async (e) => {
    e.preventDefault();
    var email = this.state.email;
    var valid = EmailValidator.validate(email);

    if (valid) {
      const baseurl = process.env.REACT_APP_API_URL;
      const url = baseurl + "/api/user/checkEmail";
      const data = {
        email: email,
      };

      return api
        .checkEmail(data)
        .then((res) => {
          if (res.status === 200) {
            var valid = res.data.valid;
            if (valid) {
              this.setState({ stepsOpen: true });
            } else {
              this.setState({ emailExistsError: true, emailInvalid: true });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({ emailInvalid: true });
    }
  };

  emailChangeHandler = (e) => {
    this.setState({ email: e.target.value, emailInvalid: false });
  };

  genderSelectHandler = (props) => (e) => {
    this.setState({ gender: props });
    setTimeout(() => {
      this.setState({ step: "age", progress: 40 });
    }, 400);
  };

  ageChangeHandler = (e) => {
    var age = e.target.value;
    if (age < 99) {
      this.setState({ age: e.target.value, ageInvalid: false }, () => {
        if (this.state.age.length === 2) {
          if (age > 17 && age < 99) {
            setTimeout(() => {
              this.setState({ step: "location", progress: 60 });
            }, 400);
          } else {
            this.setState({ ageInvalid: true, age: null });
          }
        }
      });
    }
  };

  handleAddressChange = (e) => {
    this.setState({ changingAddress: e, addressInvalid: false });
  };
  handleAddressSelect = async (e) => {
    var address = e;
    var valid = false;

    if (address.length > 0) {
      await geocodeByAddress(address)
        .then((res) => {
          if (res === "ZERO_RESULTS") {
            valid = false;
          } else {
            address = res[0].formatted_address;
            valid = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      if (valid) {
        this.setState({
          address: address,
          changingAddress: address,
          addressInvalid: false,
        });
        setTimeout(() => {
          this.setState({ step: "usernamepassword", progress: 80 });
        }, 400);
      } else {
        this.setState({ addressInvalid: true });
      }
    } else {
      this.setState({ addressInvalid: true });
    }
  };

  usernameChangeHandler = (e) => {
    this.setState({
      username: e.target.value,
      usernameInvalid: false,
      usernameExistsError: false,
    });
  };

  passwordChangeHandler = (e) => {
    this.setState({
      password: e.target.value,
      passwordInvalid: false,
      passwordNotMatchError: false,
    });
  };

  confirmPasswordChangeHandler = (e) => {
    this.setState({
      confirmPassword: e.target.value,
      passwordInvalid: false,
      passwordNotMatchError: false,
    });
  };

  handleBack = (e) => {
    var step = this.state.step;
    if (step === "gender") {
      this.setState({ stepsOpen: false });
    } else if (step === "age") {
      this.setState({ step: "gender" });
    } else if (step === "location") {
      this.setState({ step: "age" });
    } else if (step === "usernamepassword") {
      this.setState({ step: "location" });
    }
  };

  signupSubmit = async (e) => {
    e.preventDefault();
    this.setState({ signupButtonDisabled: true }, async (e) => {
      var email = this.state.email;
      var age = this.state.age;
      var gender = this.state.gender;
      var address = this.state.address;
      var username = this.state.username.toLowerCase().trim();
      var password = this.state.password;
      var confirmPassword = this.state.confirmPassword;

      var usernameValid = usernameSchema.validate({ username: username });

      var usernameExists = true;

      var passwordValid = passwordSchema.validate(password);
      var passwordMatch = false;
      if (password === confirmPassword) {
        passwordMatch = true;
      } else {
        this.setState({ passwordNotMatchError: true });
      }

      if (!passwordValid) {
        this.setState({ passwordInvalid: true });
      }

      if (!usernameValid.error) {
        const data = {
          username: username,
        };

        await api
          .checkUsername(data)
          .then((res) => {
            if (res.status === 200) {
              var valid = res.data.valid;
              if (valid) {
                usernameExists = false;
              } else {
                this.setState({
                  usernameExistsError: true,
                  signupButtonDisabled: false,
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              usernameInvalid: true,
              signupButtonDisabled: false,
            });
          });
      } else {
        this.setState({ usernameInvalid: true, signupButtonDisabled: false });
      }

      if (
        !usernameValid.error &&
        !usernameExists &&
        passwordValid &&
        passwordMatch
      ) {
        this.setState({ progress: 100 });
        const data = {
          email: email,
          password: password,
          username: username,
          gender: gender,
          address: address,
          age: age,
        };

        await api
          .submitSignup(data)
          .then((res) => {
            if (res.status === 200) {
              window.dataLayer.push({ event: "Signup" });

              if (gender === "male") {
                ReactGA.event({
                  category: "Login/Signup",
                  action: "Successful Signup",
                  label: "male signup",
                });
              }

              if (gender === "female") {
                ReactGA.event({
                  category: "Login/Signup",
                  action: "Successful Signup",
                  label: "female signup",
                });
              }

              ReactGA.set({ userId: username });
              localStorage.setItem("auth", res.data);
              this.props.history.push({
                pathname: "/app",
                state: { welcomeModalOpen: true },
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });

    this.setState({ signupButtonDisabled: false });
  };

  render() {
    const searchOptions = {
      types: ["(cities)"],
    };
    var landingPageGif =
      "https://res.cloudinary.com/sugarbae/image/upload/q_auto,f_auto,c_scale,h_716/v1588059093/Assets/ezgif-3-fb9d05ec3be8_m5m9ic.gif";
    if (this.state.stepsOpen) {
      return (
        <div className="landing-page-container-form">
          <div className="landing-page-header">
            <ProgressBar
              animated
              now={this.state.progress}
              className="landing-page-progress-bar"
            />

            {/* <Card className="landing-page-step-card">
              <Card.Img
                variant="top"
                className="landing-page-top-img"
                src={bannerImage}
              />
            </Card> */}
            {this.state.step === "gender" && (
              <div className="landing-page-step-form">
                <h2 className="landing-page-step-form-title">
                  Choose Your Gender:
                </h2>
                <div
                  className={
                    this.state.gender === "male"
                      ? "landing-select-active"
                      : "landing-select"
                  }
                  onClick={this.genderSelectHandler("male")}
                >
                  {this.state.gender === "male" && (
                    <i className="fas fa-check-circle landing-select-check-icon"></i>
                  )}
                  Male
                </div>
                <div
                  className={
                    this.state.gender === "female"
                      ? "landing-select-active"
                      : "landing-select"
                  }
                  onClick={this.genderSelectHandler("female")}
                >
                  {this.state.gender === "female" && (
                    <i className="fas fa-check-circle landing-select-check-icon"></i>
                  )}
                  Female
                </div>
              </div>
            )}
            {this.state.step === "age" && (
              <div className="landing-page-step-form">
                <h2 className="landing-page-step-form-title-age">
                  Verify Your Age
                </h2>
                <p className="age-input-subtitle">
                  Must Be 18+ to use Sugarbae
                </p>

                <Form.Control
                  type="number"
                  name="age"
                  maxLength="2"
                  size="lg"
                  placeholder="18+"
                  className="landing-form-age-input"
                  value={this.state.age}
                  onChange={this.ageChangeHandler}
                  min="18"
                  max="99"
                  isInvalid={this.state.ageInvalid}
                />
              </div>
            )}
            {this.state.step === "location" && (
              <div className="landing-page-step-form">
                <h2 className="landing-page-step-form-title-age">
                  Enter Your Location
                </h2>
                <p className="age-input-subtitle">
                  Type your city and select from dropdown.
                </p>

                <PlacesAutocomplete
                  value={this.state.changingAddress}
                  onChange={this.handleAddressChange}
                  onSelect={this.handleAddressSelect}
                  searchOptions={searchOptions}
                  highlightFirstSuggestion={true}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="sf-location-input-wrapper">
                      <Form.Control
                        size="lg"
                        isInvalid={this.state.addressInvalid}
                        {...getInputProps({
                          placeholder: "Enter Your City Here",
                          className: "signup-form-input",
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Invalid location. Please select your city from the
                        dropdown menu.
                      </Form.Control.Feedback>

                      <div className="sf-dropdown-card">
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            )}
            {this.state.step === "usernamepassword" && (
              <div className="landing-page-step-form">
                <p className="landing-page-last-step-text">Last Step!</p>
                <h2 className="landing-page-step-form-title">
                  Create Your Username & Password
                </h2>
                <Form onSubmit={this.signupSubmit}>
                  <Form.Group>
                    <Form.Label className="landing-page-form-label">
                      Username
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Visible by all members"
                      onChange={this.usernameChangeHandler}
                      value={this.state.username}
                      className="signup-form-input"
                      size="lg"
                      isInvalid={this.state.usernameInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      Invalid Username. Must be between 3-30 Characters. No
                      spaces or special characters allowed.
                    </Form.Control.Feedback>

                    {this.state.usernameExistsError && (
                      <Alert variant="danger">
                        Username already exists. Please choose another username.
                      </Alert>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="landing-page-form-label">
                      Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Minimum length: 6 characters"
                      onChange={this.passwordChangeHandler}
                      value={this.state.password}
                      className="signup-form-input"
                      size="lg"
                      isInvalid={this.state.passwordInvalid}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="landing-page-form-label">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm Your Password"
                      onChange={this.confirmPasswordChangeHandler}
                      value={this.state.confirmPassword}
                      className="signup-form-input"
                      size="lg"
                      isInvalid={this.state.passwordInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      Invalid Password. Minimum length: 6 characters. No spaces.
                    </Form.Control.Feedback>
                    {this.state.passwordNotMatchError && (
                      <Alert variant="danger">
                        Passwords Do Not Match. Please Confirm Your Password.
                      </Alert>
                    )}
                  </Form.Group>

                  <Button
                    type="submit"
                    className="landing-page-signup-submit-button"
                    size="lg"
                  >
                    Create Your Account
                    <i className="fas fa-chevron-circle-right landing-page-signup-button-icon"></i>
                  </Button>
                </Form>
              </div>
            )}
          </div>
          <div className="landing-page-back-wrapper">
            <div className="landing-page-back-button" onClick={this.handleBack}>
              <i className="fas fa-chevron-circle-left landing-page-back-icon"></i>
              <span className="landing-page-back-text">Back</span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="landing-page-container">
          <div className="landing-page-header">
            <Image src={logo} className="landing-page-logo" />
            <h1 className="landing-page-title">
              The #1 Sugar Daddy Dating App.
            </h1>
            <p className="landing-page-subtitle">Get Spoiled By Wealthy Men.</p>
            <Form
              className="landing-page-form"
              onSubmit={this.emailSubmitHandler}
            >
              {this.state.emailExistsError && (
                <Alert variant="danger" className="landing-page-alert">
                  Invalid Email. Enter a different email and try again.
                </Alert>
              )}
              <Form.Group className="landing-page-form-group">
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  className="landing-page-input"
                  onChange={this.emailChangeHandler}
                  size="lg"
                  isInvalid={this.state.emailInvalid}
                  value={this.state.email}
                />
                <Button
                  type="submit"
                  className="landing-page-submit-button"
                  size="lg"
                >
                  Join Free
                </Button>
              </Form.Group>
              <p className="landing-page-form-subtitle">
                100% Free Signup. Safe, Secure & Private. Over 1 Million Members
                Online Now. Find Your Perfect Arrangement Today.
              </p>
            </Form>
          </div>
          <div className="landing-page-content">
            <div className="landing-page-gif-wrapper">
              <Image src={landingPageGif} className="landing-page-gif" />
              <div className="banner-video-container-desktop">
                <video
                  id="banner-video-desktop"
                  loop
                  autoPlay
                  muted
                  playsInline
                >
                  <source
                    src="https://res.cloudinary.com/sugarbae/video/upload/ac_none,q_auto:eco,vc_auto/v1587418115/Assets/homeback_video_background_desktop_final_x6sbv1.mp4"
                    type="video/mp4"
                    id="banner-video-desktop-src"
                  />
                  <source
                    src="https://res.cloudinary.com/sugarbae/video/upload/ac_none,q_auto:eco,vc_auto/v1587418115/Assets/homeback_video_background_desktop_final_x6sbv1.webm"
                    type="video/webm"
                    id="banner-video-desktop-src"
                  />
                </video>
              </div>
            </div>
            <div className="landing-page-features">
              <div className="landing-page-feature-item">
                <i className="far fa-gifts landing-page-feature-icon"></i>

                <h3 className="landing-page-feature-title">
                  Find a Sugar Daddy In Minutes
                </h3>
                <p className="landing-page-feature-description">
                  Join for free and begin matching and chatting with Sugar
                  Daddies in your area.
                </p>
              </div>
              <div className="landing-page-feature-item">
                <i className="far fa-badge-check landing-page-feature-icon"></i>

                <h3 className="landing-page-feature-title">
                  Verified & Real Profiles
                </h3>
                <p className="landing-page-feature-description">
                  We take safety & security very seriously. Over 50% of members
                  are verified so you know they are real.
                </p>
              </div>
              <div className="landing-page-feature-item">
                <i className="far fa-users landing-page-feature-icon"></i>
                <h3 className="landing-page-feature-title">
                  Over 1 Million Active Users
                </h3>
                <p className="landing-page-feature-description">
                  Millions of Women have found successful arrangements using
                  Sugarbae. Your perfect match is online now.
                </p>
              </div>
            </div>
            <div className="landing-page-seen-on">
              <div className="landing-page-seen-on-wrapper">
                <ul className="landing-page-seen-on-list">
                  <li className="landing-page-seen-on-list-item">
                    <Image
                      src={nytLogo}
                      className="landing-page-seen-on-logo"
                    />
                  </li>
                  <li className="landing-page-seen-on-list-item">
                    <Image
                      src={cnnLogo}
                      className="landing-page-seen-on-logo"
                    />
                  </li>
                  <li className="landing-page-seen-on-list-item">
                    <Image
                      src={peopleLogo}
                      className="landing-page-seen-on-logo"
                    />
                  </li>
                  <li className="landing-page-seen-on-list-item">
                    <Image
                      src={huffLogo}
                      className="landing-page-seen-on-logo"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="landing-page-cta">
              <p className="landing-page-cta-quote">
                "Millions of Woman are joining Sugarbae to meet wealthy,
                attractive men. It's safe, secure and free to join."
              </p>
              <cite className="landing-page-quote-cite">
                New York Times, 2020
              </cite>
              <Button
                className="landing-page-cta-button"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Get Started Free
              </Button>
            </div>

            <div className="landing-page-footer">
              <Link to="/terms" className="landing-page-footer-link">
                Terms of Service
              </Link>
              <Link to="/terms" className="landing-page-footer-link">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(HookupLanding);
