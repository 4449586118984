import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import BottomScrollListener from "react-bottom-scroll-listener";

import { UserContext } from "../../context/UserContext";

import Chat from "../../components/chat/Chat";
import DesktopMessages from "../../components/desktop/desktopMessages/DesktopMessages";
import MessagesMatches from "../../components/messages/messagesComponents/MessagesMatches";
import Inbox from "../../components/messages/inbox/Inbox";
import SwipeNav from "../../components/navbar/SwipeNav";
import MobileNav from "../../components/navbar/MobileNav/MobileNav";
import MessagesHeader from "../../components/headers/MessagesHeader";

import "./messages.scss";
import DesktopMessagesV2 from "../../components/desktop/desktopV2/desktopMessagesV2/DesktopMessagesV2";

function Messages(props) {
  const context = useContext(UserContext);
  const [chatOpen, setChatOpen] = useState(false);
  const [chatid, setChatId] = useState(null);
  const [index, setIndex] = useState(0);
  const [finishedScrolling, setFinishedScrolling] = useState(false);

  useEffect(() => {
    updateScrollHeight();
  }, []);
  const updateScrollHeight = (e) => {
    let searchHeight = document.getElementById("scroll-ref-context");
    if (context.scrollPositions.messages > 0) {
      if (searchHeight) {
        searchHeight.scrollTop = context.scrollPositions.messages;
      }
    }
    setFinishedScrolling(true);
  };

  const updateScrollHandler = (e) => {
    var scrollTop = e.target.scrollTop;
    context.updateScrollPosition("messages", scrollTop);
  };

  useEffect(() => {
    const chatid = props.match.params.chatid;
    const userid = context.user._id;

    window.scrollTo(0, 0);

    props.handleBoost(userid);

    if (chatid) {
      setChatOpen(true);
      setChatId(chatid);
    }
  }, [props.match.params.chatid]);

  const chatToggle = (chatid) => {
    props.history.push(`/app/messages/${chatid}`);
  };

  const closeChatHandler = (e) => {
    props.history.push("/app/messages");
  };

  const handleScroll = (e) => {
    setIndex((prevState) => prevState + 1);
  };

  const vh = window.innerHeight;
  const vw = window.innerWidth;
  const height = vh + "px";

  if (vw >= 992) {
    return <DesktopMessagesV2 chatid={chatid} />;
  } else {
    return (
      <div
        className="messages-page"
        style={{ height: height, maxHeight: height }}
      >
        <MessagesHeader />
        {chatOpen && <Chat chatid={chatid} onClose={closeChatHandler} />}
        {!chatOpen && (
          <BottomScrollListener onBottom={handleScroll} offset={4000}>
            {(scrollRef) => (
              <div
                className="messages-matches-wrapper"
                ref={scrollRef}
                id="scroll-ref-context"
                onScroll={updateScrollHandler}
              >
                <MessagesMatches chatToggle={chatToggle} />
                <Inbox chatToggle={chatToggle} index={index} />
              </div>
            )}
          </BottomScrollListener>
        )}
        <MobileNav />
      </div>
    );
  }
}

export default withRouter(Messages);
