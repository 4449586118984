import React, { useState, useContext, useRef, useEffect } from "react";
import "./editscreensstyles.scss";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";

function Gender(props) {
  const context = useContext(UserContext);

  const [gender, setGender] = useState(context.user.identity.gender);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const toggleTab = (e) => {
    props.toggleSubTab();
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setSaveButtonDisabled(true);

    var data = {
      option: "gender",
      gender: gender,
    };

    await api
      .updateIdentity(data)
      .then((res) => {
        if (res.status === 200) {
          context.updateUserInfo("identity", res.data.identity);
          toggleTab();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeHandler = (e) => {
    var name = e.target.name;
    var genderCopy = gender;

    if (name === "male") {
      genderCopy.male = true;
      genderCopy.female = false;
      genderCopy.other = false;
    } else if (name === "female") {
      genderCopy.male = false;
      genderCopy.female = true;
      genderCopy.other = false;
    } else if (name === "other") {
      genderCopy.male = false;
      genderCopy.female = false;
      genderCopy.other = true;
    }

    setGender((prevState) => {
      return { ...prevState, ...genderCopy };
    });
    setSaveButtonDisabled(false);
  };

  return (
    <div className="edit-screen">
      <div className="edit-screen-header">
        <div className="edit-screen-header-section-left">
          <Button
            className="edit-screen-header-return-button"
            onClick={toggleTab}
          >
            <i className="fas fa-chevron-left"></i>
          </Button>
        </div>
        <div className="edit-screen-header-section-middle">
          <h1 className="edit-screen-header-title">Gender</h1>
        </div>
      </div>
      <div className="edit-screen-content">
        <div className="settings-v2-section">
          <div className="settings-v2-section-content">
            <Form className="edit-screen-form">
              <Form.Group className="edit-screen-form-group">
                <Form.Check
                  checked={gender.male}
                  name="male"
                  label={"Male"}
                  value={gender.male}
                  onChange={changeHandler}
                  type="radio"
                />
                <Form.Check
                  checked={gender.female}
                  name="female"
                  label={"Female"}
                  value={gender.female}
                  onChange={changeHandler}
                  type="radio"
                />
                <Form.Check
                  checked={gender.other}
                  name="other"
                  label={"Other"}
                  value={gender.other}
                  onChange={changeHandler}
                  type="radio"
                />
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      <div className="edit-screen-cta-button-wrapper">
        <Button
          className="edit-screen-update-button"
          size="lg"
          onClick={toggleTab}
        >
          Back
        </Button>

        <Button
          className="edit-screen-cta-button-upgrade"
          size="lg"
          disabled={saveButtonDisabled}
          onClick={submitHandler}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}

export default Gender;
