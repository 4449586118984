import React from "react";
import { Button, Image } from "react-bootstrap";
import "./headerstyles.scss";
import logo from "../../assets/logos/sugarbae-home-icon.png";
import { Link } from "react-router-dom";
import MatchCounter from "../navbar/navComponents/counters/MatchCounter";

function MessagesHeader(props) {
  return (
    <div className="header-container">
      <div className="header-section-left">
        <Link to="/app/matches">
          <Button className="header-button">
            <i className="far fa-heart header-heart-icon">
              <MatchCounter />
            </i>
          </Button>
        </Link>
      </div>
      <div className="header-section-center">
        <Image src={logo} className="header-logo" />
      </div>
      <div className="header-section-right">
        {/* <Link to="/app/notifications">
          <Button className="header-button">
            <i className="far fa-bell header-filter-icon"></i>
          </Button>
        </Link> */}
      </div>
    </div>
  );
}

export default MessagesHeader;
