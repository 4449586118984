import React, { Component } from "react";
import "../welcome.scss";
import "./offer.scss";
import { Button, Image, Form, Alert, FormCheck, Card } from "react-bootstrap";
import { UserContext } from "../../../context/UserContext";
import Axios from "axios";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { withRouter } from "react-router-dom";
import premiumIcon from "../../../assets/Images/welcome/premium-icon.png";
import freeIcon from "../../../assets/Images/welcome/free-icon.png";
import premiumGif from "../../../assets/Images/welcome/premium-diamond.gif";

export class Offer extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      disabledBtn: false,
      loading: false,
    };
  }

  render() {
    var vw = window.innerWidth;

    var width = vw / 2.5 + 45 + "px";
    var height = vw / 2.5 + "px";

    var gifSize = {
      height: height,
      width: width,
    };

    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="welcome-component-container">
          <ProgressBar animated now={95} className="welcome-progress-bar" />
          <div className="offer-content-wrapper">
            <div className="welcome-title-wrap-premium">
              <div className="premium-gif-wrapper">
                <Image
                  src={premiumGif}
                  className="premium-gif"
                  style={gifSize}
                />
              </div>
              <h1 className="welcome-title">Upgrade to Premium</h1>
              <p className="welcome-subtitle">
                Unlock the world's best dating experience.
              </p>
            </div>
            <div className="welcome-offer-container">
              <Card className="welcome-offer-card">
                <Card.Header className="welcome-offer-card-header">
                  <h2 className="offer-title">Premium</h2>
                  <Image
                    src={premiumIcon}
                    fluid
                    className="welcome-offer-icon"
                  />
                </Card.Header>
                <Card.Body className="welcome-offer-card-body">
                  <div className="welcome-offer-item">
                    <i className="fas fa-heart offer-item-icon-heart"></i>
                    <h2 className="offer-item-title">
                      Get <strong>Unlimited</strong> Likes
                    </h2>
                  </div>
                  <div className="welcome-offer-item">
                    <i className="fas fa-stars offer-item-icon-superlike"></i>
                    <h2 className="offer-item-title">
                      Unlock <strong>Superlikes</strong>
                    </h2>
                  </div>
                  <div className="welcome-offer-item">
                    <i className="fas fa-bolt offer-item-icon-bolt"></i>
                    <h2 className="offer-item-title">
                      Weekly <strong>Profile Boosts</strong>
                    </h2>
                  </div>
                  <div className="welcome-offer-item">
                    <i className="fas fa-paper-plane offer-item-icon-message"></i>
                    <h2 className="offer-item-title">
                      Message <strong>Anyone</strong>
                    </h2>
                  </div>
                  <div className="welcome-offer-item">
                    <i className="fas fa-sliders-h offer-item-icon-filter"></i>
                    <h2 className="offer-item-title">
                      Advanced <strong>Search Filters</strong>
                    </h2>
                  </div>
                  <div className="welcome-offer-item">
                    <i className="fas fa-eye offer-item-icon-eye"></i>
                    <h2 className="offer-item-title">
                      <strong>View</strong> Who Likes You
                    </h2>
                  </div>
                  <div className="welcome-offer-item">
                    <i className="fas fa-check-double offer-item-icon-superlike"></i>
                    <h2 className="offer-item-title">
                      Message <strong></strong>Read Receipts
                    </h2>
                  </div>
                </Card.Body>
              </Card>

              <div className="welcome-offer-wrapper">
                <div className="offer-header-wrapper-free">
                  <h2 className="offer-title">Free</h2>
                  <Image src={freeIcon} className="welcome-offer-icon" />
                </div>
                <div className="offer-features-wrapper">
                  <div className="offer-feature">
                    <i className="fas fa-times welcome-offer-checkmark"></i>
                    <span className="offer-feature-text">
                      Can't see who visits your profile
                    </span>
                  </div>
                  <div className="offer-feature">
                    <i className="fas fa-times welcome-offer-checkmark"></i>
                    <span className="offer-feature-text">
                      Can't see who read your messages
                    </span>
                  </div>
                  <div className="offer-feature">
                    <i className="fas fa-times welcome-offer-checkmark"></i>
                    <span className="offer-feature-text">
                      No profile rank boost
                    </span>
                  </div>
                  <div className="offer-feature">
                    <i className="fas fa-times welcome-offer-checkmark"></i>
                    <span className="offer-feature-text">
                      No premium search filters
                    </span>
                  </div>
                  <div className="offer-feature">
                    <i className="fas fa-check welcome-offer-checkmark"></i>
                    <span className="offer-feature-text">
                      Regular Profile Badge
                    </span>
                  </div>
                  <div className="offer-feature">
                    <i className="fas fa-check welcome-offer-checkmark"></i>
                    <span className="offer-feature-text">
                      Send Messages & Favorites
                    </span>
                  </div>
                  <div className="offer-feature">
                    <i className="fas fa-check welcome-offer-checkmark"></i>
                    <span className="offer-feature-text">
                      Match with other members
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="welcome-btn-wrap">
            <Button
              variant="primary"
              className="premium-btn-advance"
              href="/app/premium"
              disabled={this.state.disabledBtn}
            >
              Upgrade to Premium
            </Button>
            <Link
              to={{
                pathname: "/app",
                state: { welcomeModalOpen: true },
              }}
              className="welcome-skip-link"
            >
              Continue with Free
            </Link>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(Offer);
