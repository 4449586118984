import React from "react";
import { Image, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import twitterIcon from "../../../assets/Icons/twitter-icon.svg";
import instagramIcon from "../../../assets/Icons/instagram-icon.svg";
import "./homefooter.scss";

function HomeFooter(props) {
  return (
    <div className="home-page-sub-footer">
      <div className="home-page-footer-nav-wrapper">
        <nav className="home-page-footer-nav">
          <div className="home-page-footer-nav-link-wrapper">
            <Link to="/" className="home-page-footer-link">
              FAQ
            </Link>
            <Link to="/contact" className="home-page-footer-link">
              Contact
            </Link>
            <Link to="/about" className="home-page-footer-link">
              About
            </Link>
          </div>
          <div className="home-page-footer-nav-social-wrapper">
            <Link to="/" className="home-page-footer-social-link">
              <Image
                src={twitterIcon}
                className="home-page-footer-social-icon"
              />
            </Link>
            <Link to="/" className="home-page-footer-social-link">
              <Image
                src={instagramIcon}
                className="home-page-footer-social-icon"
              />
            </Link>
          </div>
        </nav>
      </div>
      <div className="home-page-sub-footer-wrapper">
        <div className="home-page-sub-footer-innerwrap">
          <div className="home-page-sub-footer-trademark-wrapper">
            <p className="home-page-sub-footer-trademark">
              © 2020 Sugarbae Inc.
            </p>
          </div>
          <div className="home-page-sub-footer-links-wrapper">
            <nav className="home-page-sub-footer-nav">
              <Link to="/safety" className="home-page-sub-footer-link">
                Dating Safety
              </Link>
              <Link to="/terms" className="home-page-sub-footer-link">
                Terms
              </Link>
              <Link to="/privacy" className="home-page-sub-footer-link">
                Privacy
              </Link>
              <Link to="/security" className="home-page-sub-footer-link">
                Security
              </Link>
            </nav>
          </div>
        </div>
        <div className="home-page-desktop-sub-footer-social-wrapper">
          <Link to="/" className="home-page-footer-social-link">
            <Image src={twitterIcon} className="home-page-footer-social-icon" />
          </Link>
          <Link to="/" className="home-page-footer-social-link">
            <Image
              src={instagramIcon}
              className="home-page-footer-social-icon"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default withRouter(HomeFooter);
