import React, { useContext, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";

import PhotoGrid from "../../profile/profileV2Components/photoGrid/PhotoGrid";
import ProfileInfo from "../../profile/profileV2Components/profileInfo/ProfileInfo";
import LookingFor from "../../profile/profileV2Components/lookingFor/LookingFor";
import EditValue from "./editProfileV2Components/editValue/EditValue";
import BasicInfo from "./editProfileV2Components/basicInfo/BasicInfo";
import EditBio from "./editProfileV2Components/editBio/EditBio";
import EditHeadline from "./editProfileV2Components/editHeadline/EditHeadline";
import EditBenefits from "./editProfileV2Components/editBenefits/EditBenefits";
import EditGallery from "./editProfileV2Components/editPhotoGallery/EditGallery";
import { UserContext } from "../../../context/UserContext";

import "./editprofilev2.scss";
import PhotoGridV2 from "../../photoGrid/PhotoGridV2";

function EditProfileV2(props) {
  const context = useContext(UserContext);
  const history = useHistory();
  const [showEditPopup, setShowEditPopup] = useState(null);
  const [showBasicInfo, setShowBasicInfo] = useState(false);
  const [showLookingfor, setShowLookingfor] = useState(false);
  const [showHeadline, setShowHeadline] = useState(false);
  const [showBenefits, setShowBenefits] = useState(false);
  const [showGallery, setShowGallery] = useState(false);

  useEffect(() => {
    const { option } = props;

    if (option === "basicinfo") {
      setShowBasicInfo(true);
    } else if (option === "lookingfor") {
      setShowLookingfor(true);
    } else if (option === "aboutyou") {
      setShowHeadline(true);
    } else if (option === "benefits") {
      setShowBenefits(true);
    } else if (option === "gallery") {
      setShowGallery(true);
    }
  }, []);

  const editHandler = (option) => {
    if (!showEditPopup) {
      setShowEditPopup(option);
    }
  };

  const editBasicInfo = () => {
    history.push("/app/profile/edit/basicinfo");
  };

  const editLookingfor = () => {
    history.push("/app/profile/edit/lookingfor");
  };

  const editHeadline = () => {
    history.push("/app/profile/edit/aboutyou");
  };

  const editBenefits = () => {
    history.push("/app/profile/edit/benefits");
  };

  const editGallery = () => {
    history.push("/app/profile/edit/gallery");
  };

  const closeEdit = () => {
    if (!showEditPopup) {
      props.history.push("/app/profile");
    }
  };

  const closeHandler = () => {
    setShowEditPopup(null);
    setShowBasicInfo(false);
    setShowLookingfor(false);
    setShowHeadline(false);
    setShowBenefits(false);
    setShowGallery(false);
  };

  let hasBio = false;
  let hasHeadline = false;
  if (context.user.bio) {
    if (context.user.bio.length > 0) {
      hasBio = true;
    }
  }
  if (context.user.headline) {
    if (context.user.headline.length > 0) {
      hasHeadline = true;
    }
  }

  return (
    <div className="edit-profile-v2-component">
      {showEditPopup && (
        <EditValue option={showEditPopup} closeHandler={closeHandler} />
      )}
      {showBasicInfo && <BasicInfo closeHandler={closeHandler} />}
      {showLookingfor && <EditBio closeHandler={closeHandler} />}
      {showHeadline && <EditHeadline closeHandler={closeHandler} />}
      {showBenefits && <EditBenefits closeHandler={closeHandler} />}
      {showGallery && <EditGallery closeHandler={closeHandler} />}
      <div className="edit-profile-v2-header">
        <div
          className="edit-profile-v2-header-section-left"
          onClick={() => closeEdit()}
        >
          <div className="edit-profile-v2-header-return-button">
            <i className="fas fa-chevron-left" />
          </div>
        </div>
        <div className="edit-profile-v2-header-section-middle">
          <h1 className="edit-profile-v2-header-title">Edit Profile</h1>
        </div>
        <div
          className="edit-profile-v2-header-section-right"
          onClick={() => closeEdit()}
        >
          <div className="edit-profile-v2-header-done-button">Done</div>
        </div>
      </div>
      <div className="edit-profile-v2-content">
        <div className="edit-profile-v2-photo-view">
          <h1 className="edit-profile-v2-title">Photos & Videos</h1>
          <PhotoGridV2 profileUser={context.user} ownProfile />
          <div
            className="edit-profile-v2-photo-button"
            onClick={() => editGallery()}
          >
            Manage Gallery
          </div>
        </div>
        <div className="edit-profile-v2-basic" onClick={() => editBasicInfo()}>
          <div className="edit-profile-v2-basic-content">
            <h1 className="edit-profile-v2-title capitalize">Basic Info</h1>
            <label className="edit-profile-v2-text capitalize">{`${context.user.address}, ${context.user.gender}, ${context.user.age}`}</label>
          </div>
          <div className="edit-profile-v2-row-action">
            <i className="fas fa-chevron-right edit-profile-v2-right-icon" />
          </div>
        </div>
        <div className="edit-profile-v2-basic" onClick={() => editLookingfor()}>
          <div className="edit-profile-v2-basic-content">
            <h1 className="edit-profile-v2-title">Looking For</h1>
            {hasBio ? (
              <label className="edit-profile-v2-text">{context.user.bio}</label>
            ) : (
              <label className="edit-profile-v2-text">
                Describe what you are looking for
              </label>
            )}
          </div>
          <div className="edit-profile-v2-row-action">
            <i className="fas fa-chevron-right edit-profile-v2-right-icon" />
          </div>
        </div>
        <div
          className="edit-profile-v2-basic no-bottom-border"
          onClick={() => editHeadline()}
        >
          <div className="edit-profile-v2-basic-content">
            <h1 className="edit-profile-v2-title">About you</h1>
            {hasHeadline ? (
              <label className="edit-profile-v2-text">
                {context.user.headline}
              </label>
            ) : (
              <label className="edit-profile-v2-text">
                Describe a little about yourself
              </label>
            )}
          </div>
          <div className="edit-profile-v2-row-action">
            <i className="fas fa-chevron-right edit-profile-v2-right-icon" />
          </div>
        </div>
        <div className="edit-profile-v2-info">
          <h1 className="edit-profile-v2-title">Profile Info</h1>
          <ProfileInfo profileUser={context.user} editHandler={editHandler} />
        </div>
        <div className="edit-profile-v2-benefits">
          <div className="edit-profile-v2-benefits-header">
            <h1 className="edit-profile-v2-title">My Benefits</h1>
            <div className="edit-profile-v2-benefits-header-action">
              <Button
                className="edit-profile-v2-header-done-button"
                onClick={() => editBenefits()}
              >
                Edit
              </Button>
            </div>
          </div>
          <LookingFor profileUser={context.user} isEdit />
        </div>
      </div>
    </div>
  );
}

export default withRouter(EditProfileV2);
