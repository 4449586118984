import React from 'react';
import EmailLogin from '../../components/home/logins/EmailLogin';
import homeLogo from '../../assets/logos/homeLogo.png';
import { Image } from 'react-bootstrap';
import ResetPassword from '../../components/resetPassword/ResetPassword';

function ResetPasswordPage() {
  const vh = window.innerHeight;
  const vw = window.innerWidth;

  if (vw > 991) {
    return (
      <div className="welcome-page-v2-component">
        <div className="welcome-page-v2-header">
          <Image src={homeLogo} className="welcome-page-v2-logo" fluid />
        </div>
        <div className="welcome-page-v2-content">
          <ResetPassword />
        </div>
      </div>
    );
  } else {
    return <ResetPassword />;
  }
}

export default ResetPasswordPage;
