import React, { useState, useEffect, useContext } from "react";
import "./desktopsearchresults.scss";
import ClipLoader from "react-spinners/ClipLoader";
import { withRouter } from "react-router-dom";
import DesktopSearchCard from "./DesktopSearchCard";

function DesktopSearchResults(props) {
  return (
    <div className="desktop-search-results-component">
      {props.cards.map((card, index) => {
        var isLiked = false;
        var isSuperliked = false;

        if (props.likedUsers.includes(card._id)) {
          isLiked = true;
        }

        if (props.superlikedUsers.includes(card._id)) {
          isSuperliked = true;
        }

        return (
          <DesktopSearchCard
            card={card}
            key={index}
            isLiked={isLiked}
            isSuperliked={isSuperliked}
          />
        );
      })}
      {props.scrollLoading && (
        <div className="desktop-search-results-indicator-container">
          <ClipLoader />
        </div>
      )}
      {props.maxReached && (
        <div className="desktop-search-results-indicator-container">
          <span className="desktop-search-results-max-reached">
            No more users for the set filters.
          </span>
        </div>
      )}
    </div>
  );
}

export default withRouter(DesktopSearchResults);
