import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";

import { UserContext } from "../context/UserContext";
import { socket } from "../api";
import NotificationBar from "../components/notificationBar/NotificationBar";
import VideoRoom from "../components/videoRoom/VideoRoom";

import "./auth.scss";

export function Auth(props) {
  const context = useContext(UserContext);
  const [data, setData] = useState();
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationType, setNotificationType] = useState("message");
  const [message, setMessage] = useState(null);
  const [roomData, setRoomData] = useState({});
  const [showVideoRoom, setShowVideoRoom] = useState(false);

  useEffect(() => {
    socket.onNewMessage(context.user.username, (msgReturn) => {
      if (msgReturn.message) {
        setNotificationOpen(preValue => {
          if (!preValue) {
            setData(msgReturn);
            setNotificationType("message");
            return true;
          }

          return preValue;
        });
      } else if (msgReturn.isVideoCall) {
        setRoomData({
          caller: msgReturn.caller,
          roomName: msgReturn.roomName,
        });
        setShowVideoRoom(true);
      }
    });
    socket.onNewMatch(context.user._id, (match) => {
      setNotificationOpen(preValue => {
        if (!preValue) {
          setData(match);
          setNotificationType("match");
          return true;
        }

        return preValue;
      });
    });
    socket.onNewLike(context.user._id, (like) => {
      setNotificationOpen(preValue => {
        if (!preValue) {
          setData(like);
          setNotificationType("like");
          context.updateLikes();
          return true;
        }

        return preValue;
      });
    });

    return () => {
      socket.offNewMessage(context.user.username);
      socket.offNewMatch(context.user._id);
      socket.offNewLike(context.user._id);
    };
  }, []);

  const closeNotificationHandler = (e) => {
    setNotificationOpen(false);
    setNotificationType("");
    setMessage(null);
  };

  const closeVideoRoom = () => {
    setRoomData({});
    setShowVideoRoom(false);
  };

  const token = localStorage.getItem("auth");

  if (!token) {
    return (
      props.history.push("/login"),
      (
        <div>
          <p>No-Longer-LoggedIn</p>
        </div>
      )
    );
  }

  if (context.user.loaded === true) {
    return (
      <div className="auth-container" style={{ position: "relative" }}>
        {!(
          notificationType === "message" &&
          data &&
          context.user.username === data?.senderusername
        ) && (
          <NotificationBar
            data={data}
            notificationType={notificationType}
            notificationOpen={notificationOpen}
            closeHandler={closeNotificationHandler}
          />
        )}

        {showVideoRoom && (
          <VideoRoom
            roomData={roomData}
            endHandler={closeVideoRoom}
          />
        )}

        {props.children}
      </div>
    );
  }
}

export default withRouter(Auth);
