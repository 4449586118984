import React, { useState } from "react";
import FacebookLogin from "react-facebook-login";
import logo from "../../assets/logos/facebook.png";
import "./FacebookLogin.scss";

const FacebookLoginComponent = props => {
  const [clicked, setClicked] = useState(false);
  const responseFacebook = (response) => {
    if (clicked) {
      props.onCheck({info: response, case: "facebook"});
    }
  }
  console.log("test")

  const clickedComponent = () => {
    setClicked(true);
  }
  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID}
      fields="name,email,picture"
      onClick={clickedComponent}
      callback={responseFacebook}
      cssClass="btnFacebook-signin"
      icon={<img src={logo} className="facebook-logo" />}
      textButton={props.isSignIn ? "   Continue with Facebook" : "   Signup with Facebook"}
    />
  );
};

export default FacebookLoginComponent;