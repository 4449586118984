import React, { useContext, useState } from 'react';
import './desktopnavv2.scss';
import { Image as BootstrapImage } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import { withRouter, useHistory } from 'react-router-dom';
import desktopNavLogo from '../../../../assets/logos/horizontal-whitebg-3x.png';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { UserContext } from '../../../../context/UserContext';
import Media from '../../../../components/media/Media';

function DesktopSmallNavV2(props) {
  const history = useHistory();
  const context = useContext(UserContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  let mainImage = null;
  if (context.user.imgurl.length > 0) {
    mainImage = context.user.imgurl[0];
  }

  const dropdownHandler = (e) => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    } else {
      setDropdownOpen(true);
    }
  };

  const logoutHandler = (e) => {
    localStorage.removeItem('auth');
    history.push('/app');
  };

  let dropdownStyle = 'desktop-nav-button-v2';
  if (dropdownOpen) {
    dropdownStyle = 'desktop-nav-button-v2-active';
  }

  let isSearch = false;
  if (props.location.pathname === '/app/search') {
    isSearch = true;
  }

  return (
    <div className="desktop-nav-action-container-v2 desktop-small-nav">
      {/* {!dropdownOpen && (
        <div className="desktop-nav-button-v2">
          <i className="far fa-bell desktop-notification-icon-v2" />
        </div>
      )} */}

      <div className={dropdownStyle} onClick={dropdownHandler}>
        <div className="desktop-nav-user-left-wrapper">
          <div className="desktop-nav-user-image-wrapper-v2">
            <Media image={mainImage} />
            {/* <Image
              publicId={
                mainImage
                  ? getImgName(mainImage)
                  : 'placeholder-user-image-thumbnail_vankru.png'
              }
              version={mainImage ? getImgVersion(mainImage) : '1590045309'}
              resource_type={
                mainImage ? (checkGIF(mainImage) ? 'video' : 'image') : null
              }
              effect="loop"
              cloud_name="sugarbae"
              className="desktop-nav-user-image-v2"
              fluid
            >
              <Transformation
                height="200"
                video_sampling="20"
                delay="250"
                duration="10"
                effect="loop"
                quality="auto"
                flags="lossy"
              />
            </Image> */}
          </div>
          {dropdownOpen && (
            <h1 className="desktop-nav-v2-username">{context.user.username}</h1>
          )}
        </div>
        {dropdownOpen ? (
          <i className="far fa-chevron-up desktop-notification-icon-v2" />
        ) : (
          <i className="far fa-chevron-down desktop-notification-icon-v2" />
        )}

        {dropdownOpen && (
          <div className="nav-v2-dropdown-menu">
            <div
              className="nav-v2-dropdown-item"
              onClick={() => history.push('/app/settings')}
            >
              <div className="nav-v2-dropdown-icon-wrap">
                <i className="far fa-cog nav-v2-dropdown-icon" />
              </div>

              <h2 className="nav-v2-dropdown-item-text">Settings</h2>
            </div>
            <div
              className="nav-v2-dropdown-item"
              onClick={() => history.push('/app/settings/notifications')}
            >
              <div className="nav-v2-dropdown-icon-wrap">
                <i className="far fa-bell nav-v2-dropdown-icon" />
              </div>

              <h2 className="nav-v2-dropdown-item-text">Notifications</h2>
            </div>
            <div
              className="nav-v2-dropdown-item"
              onClick={() => history.push('/app/settings/community')}
            >
              <div className="nav-v2-dropdown-icon-wrap">
                <i className="far fa-question-circle nav-v2-dropdown-icon" />
              </div>

              <h2 className="nav-v2-dropdown-item-text">Community</h2>
            </div>
            <div
              className="nav-v2-dropdown-item"
              onClick={() => history.push('/app/settings/managesubscription')}
            >
              <div className="nav-v2-dropdown-icon-wrap">
                <i className="far fa-user-friends nav-v2-dropdown-icon" />
              </div>
              <h2 className="nav-v2-dropdown-item-text">Manage Subscription</h2>
            </div>
            <div
              className="nav-v2-dropdown-item nav-dropdown-noborder"
              onClick={logoutHandler}
            >
              <div className="nav-v2-dropdown-icon-wrap">
                <i className="far fa-sign-out nav-v2-dropdown-icon-logout" />
              </div>

              <h2 className="nav-v2-dropdown-item-text">Logout</h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(DesktopSmallNavV2);
