import React, { useState } from 'react';
import { Image, Transformation } from 'cloudinary-react';

import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../utils/getCloudinaryInfo';
import './photogridcomponentstyles.scss';
import Media from '../../../components/media/Media';

const EditPhoto = (props) => {
  const { tab } = props;
  const [images, setImages] = useState(props.images);
  const [index, setIndex] = useState(props.index);
  const image = images[index];
  const [showMoreActions, setShowMoreActions] = useState(false);

  const moreActionHandler = () => {
    if (showMoreActions) {
      setShowMoreActions(false);
    } else {
      setShowMoreActions(true);
    }
  };

  const prevImage = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const nextImage = () => {
    if (index < images.length - 1) {
      setIndex(index + 1);
    }
  };

  const removeHandler = () => {
    props.removeHandler(props.index);
    setShowMoreActions(false);
    props.closeHandler();
  };

  const setAsFirst = () => {
    props.setAsFirst(props.index);
    setIndex(0);
    setShowMoreActions(false);
    props.closeHandler();
  };

  const setAsFirstPrivate = () => {
    props.setAsFirstPrivate(props.index);
    setIndex(0);
    setShowMoreActions(false);
    props.closeHandler();
  };

  const makeAsPrivate = () => {
    props.makeAsPrivate(props.index);
    props.closeHandler();
  };

  const makeAsPublic = () => {
    props.makeAsPublic(props.index);
    props.closeHandler();
  };

  return (
    <div className="edit-profile-v2-one-photo-view">
      {props.images.length > 1 && (
        <>
          <div
            className="edit-profile-v2-photo-prev"
            onClick={() => prevImage()}
          >
            <i className="fa fa-chevron-left" />
          </div>
          <div
            className="edit-profile-v2-photo-next"
            onClick={() => nextImage()}
          >
            <i className="fa fa-chevron-right" />
          </div>
        </>
      )}

      <div className="edit-profile-v2-photo-actions">
        <div
          className="edit-profile-v2-one-photo-view-close"
          onClick={() => props.closeHandler()}
        >
          <i className="fas fa-times edit-profile-v2-one-photo-close-action" />
        </div>
        <div className="edit-profile-v2-one-photo-view-detail">{`${
          index + 1
        } / ${images.length}`}</div>
        <div
          className="edit-profile-v2-one-photo-view-more"
          onClick={() => moreActionHandler()}
          style={{ cursor: 'pointer' }}
        >
          ...
        </div>
        {showMoreActions && tab === 'public' && (
          <div className="edit-profile-v2-photo-more-actions">
            {props.index !== 0 && (
              <div
                className="edit-profile-v2-photo-more-action"
                onClick={() => setAsFirst()}
              >
                <i className="far fa-user edit-profile-v2-more-action-icon" />
                <label>Set as a main pic</label>
              </div>
            )}
            <div
              className="edit-profile-v2-photo-more-action"
              onClick={() => makeAsPrivate()}
            >
              <i className="far fa-lock edit-profile-v2-more-action-icon" />
              <label>Make private</label>
            </div>
            <div
              className="edit-profile-v2-photo-remove-action"
              onClick={() => removeHandler()}
            >
              <i className="far fa-trash-alt edit-profile-v2-more-action-icon" />
              <label>Delete</label>
            </div>
          </div>
        )}
        {showMoreActions && tab === 'private' && (
          <div className="edit-profile-v2-photo-more-actions">
            {props.index !== 0 && (
              <div
                className="edit-profile-v2-photo-more-action"
                onClick={() => setAsFirstPrivate()}
              >
                <i className="far fa-user edit-profile-v2-more-action-icon" />
                <label>Set as a main pic</label>
              </div>
            )}
            <div
              className="edit-profile-v2-photo-more-action"
              onClick={() => makeAsPublic()}
            >
              <i className="far fa-unlock-alt edit-profile-v2-more-action-icon" />
              <label>Make public</label>
            </div>
            <div
              className="edit-profile-v2-photo-remove-action"
              onClick={() => removeHandler()}
            >
              <i className="far fa-trash-alt edit-profile-v2-more-action-icon" />
              <label>Delete</label>
            </div>
          </div>
        )}
      </div>
      <div className="edit-profile-v2-one-photo-view-body">
        <Media image={image} />
        {/* <Image
          publicId={getImgName(image)}
          version={getImgVersion(image)}
          resource_type={checkGIF(image) ? "video" : "image"}
          effect="loop"
          cloud_name="sugarbae"
          className="edit-profile-v2-one-photo-content"
          fluid
        >
          <Transformation
            height="200"
            video_sampling="20"
            delay="250"
            duration="10"
            effect="loop"
            quality="auto"
            flags="lossy"
          />
        </Image> */}
      </div>
    </div>
  );
};

export default EditPhoto;
