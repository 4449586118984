import React, { useState, useEffect, useContext } from "react";
import "./desktopmatches.scss";
import { Link, withRouter } from "react-router-dom";
import DesktopNav from "../desktopNav/DesktopNav";
import Axios from "axios";
import { UserContext } from "../../../context/UserContext";
import ClipLoader from "react-spinners/ClipLoader";
import { Image, Button, Form, Alert, Badge } from "react-bootstrap";
import socket from "../../../helpers/Socket";
import DesktopMatchesTab from "./desktopMatchesTab/DesktopMatchesTab";
import DesktopLikesTab from "./desktopLikesTab/DesktopLikesTab";

function DesktopMatches(props) {
  const [tab, setTab] = useState("matches");

  const tabToggle = (props) => (e) => {
    if (props === "matches") {
      if (tab !== "matches") {
        setTab("matches");
      }
    } else if (props === "likes") {
      if (tab !== "likes") {
        setTab("likes");
      }
    }
  };

  useEffect(() => {
    if (props.url === "/app/likes") {
      setTab("likes");
    }
  }, []);

  return (
    <div className="desktop-matches-component">
      <DesktopNav />
      <div className="desktop-matches-container">
        <div className="desktop-matches-tabs">
          <div className="desktop-matches-tab-button-wrapper">
            <Link to="/app/matches" className="desktop-matches-tab-link">
              <Button
                className={
                  tab === "matches"
                    ? "desktop-matches-tab-button-active"
                    : "desktop-matches-tab-button"
                }
                size="lg"
              >
                Matches
              </Button>
            </Link>
            <Link to="/app/likes" className="desktop-matches-tab-link">
              <Button
                className={
                  tab === "likes"
                    ? "desktop-matches-tab-button-active"
                    : "desktop-matches-tab-button"
                }
                size="lg"
              >
                Likes
              </Button>
            </Link>
          </div>
        </div>
        {tab === "matches" && <DesktopMatchesTab />}
        {tab === "likes" && <DesktopLikesTab />}
      </div>
    </div>
  );
}

export default withRouter(DesktopMatches);
