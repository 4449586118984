import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import "../admincomponents.scss";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import InjectedCheckoutFormAdmin from "./CheckoutFormAdmin";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export class PaymentsCard extends Component {
  render() {
    return (
      <div>
        <InjectedCheckoutFormAdmin />
      </div>
    );
  }
}

export default PaymentsCard;
