import React, { useState, useContext } from "react";
import "./desktopsliders.scss";
import { Image, Form } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import { UserContext } from "../../../../../context/UserContext";

function Education(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [education, setEducation] = useState(filters.education);

  const changeHandler = (e) => {
    const educationCopy = education;

    if (e.target.value === "highschool") {
      if (educationCopy.highschool) {
        educationCopy.highschool = false;
      } else {
        educationCopy.highschool = true;
      }
    } else if (e.target.value === "somecollege") {
      if (educationCopy.somecollege) {
        educationCopy.somecollege = false;
      } else {
        educationCopy.somecollege = true;
      }
    } else if (e.target.value === "associatesdegree") {
      if (educationCopy.associatesdegree) {
        educationCopy.associatesdegree = false;
      } else {
        educationCopy.associatesdegree = true;
      }
    } else if (e.target.value === "bachelorsdegree") {
      if (educationCopy.bachelorsdegree) {
        educationCopy.bachelorsdegree = false;
      } else {
        educationCopy.bachelorsdegree = true;
      }
    } else if (e.target.value === "graduatedegree") {
      if (educationCopy.graduatedegree) {
        educationCopy.graduatedegree = false;
      } else {
        educationCopy.graduatedegree = true;
      }
    } else if (e.target.value === "phd") {
      if (educationCopy.phd) {
        educationCopy.phd = false;
      } else {
        educationCopy.phd = true;
      }
    }

    setEducation((prevState) => ({ ...prevState, ...educationCopy }));
  };
  const saveHandler = (e) => {
    props.updateHandler(education);
  };
  const resetHandler = (e) => {};

  return (
    <div className="desktop-v2-filters-content">
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.onClose}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-icon">
              <i className="far fa-graduation-cap filter-sort-icon" />
            </div>
            <div className="desktop-v2-filters-slider-title">Education</div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={resetHandler}
            >
              Reset
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="High School"
                type="checkbox"
                value="highschool"
                checked={education.highschool}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Some College"
                type="checkbox"
                value="somecollege"
                checked={education.somecollege}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Associates Degree"
                type="checkbox"
                value="associatesdegree"
                checked={education.associatesdegree}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Bachelors Degree"
                type="checkbox"
                value="bachelorsdegree"
                checked={education.bachelorsdegree}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Graduate Degree"
                type="checkbox"
                value="graduatedegree"
                checked={education.graduatedegree}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="PhD"
                type="checkbox"
                value="phd"
                checked={education.phd}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="desktop-filter-slider-footer" onClick={saveHandler}>
          <div className="desktop-filter-slider-apply-button">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default Education;
