import React, { Component } from "react";
import "./actions.scss";
import { Card } from "react-bootstrap";

export class Actions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      undoDisabled: true,
      scroll: false,
    };
  }

  componentWillReceiveProps() {
    if (this.props.undoDisabled) {
      this.setState({ undoDisabled: true });
    } else {
      this.setState({ undoDisabled: false });
    }
  }

  actionsHandler = async (action) => {
    switch (action) {
      case "like":
        return this.props.actionsHandler("like");
      case "dislike":
        return this.props.actionsHandler("dislike");
      case "undo":
        if (!this.state.undoDisabled) {
          return this.props.actionsHandler("undo");
        }
        return;
      case "superlike":
        return this.props.actionsHandler("superlike");
      case "boost":
        return this.props.actionsHandler("boost");
      default:
    }
  };

  render() {
    var btnstyle = "actions-card-btn";
    var buttonWrapperStyle = "actions-button-wrapper";

    if (this.props.scrolled) {
      btnstyle = "actions-card-btn-scrolled";
      buttonWrapperStyle = "actions-button-wrapper-scrolled";
    }
    return (
      <div className="actions-component">
        <div className={buttonWrapperStyle}>
          <div
            className={btnstyle}
            onClick={() => this.actionsHandler("dislike")}
          >
            <i className="far fa-times actions-card-icon-large dislike-btn-icon"></i>
          </div>
        </div>

        <div className={buttonWrapperStyle}>
          <div className={btnstyle} onClick={() => this.actionsHandler("like")}>
            <i className="fas fa-heart actions-card-icon-large favorite-btn-icon"></i>
          </div>
        </div>
      </div>
    );
  }
}

export default Actions;
