// TODO: move this to api folder

import io from "socket.io-client";

// let socket = io("https://api.sugarbae.com");

const baseurl = process.env.REACT_APP_API_URL;

let socket = io(baseurl);

export default socket;
