import React, { Component } from "react";
import "./premium.scss";
import { Link, withRouter } from "react-router-dom";

import { Image } from "react-bootstrap";
import { UserContext } from "../../context/UserContext";
import Premium from "../../components/premium/Premium";
import DesktopProfile from "../../components/desktop/desktopPremium/DesktopPremium";
import homeLogo from "../../assets/logos/homeLogo.png";

export class PremiumPage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const vh = window.innerHeight;
    const vw = window.innerWidth;
    const height = `${vh}px`;

    if (vw >= 992) {
      return (
        <div className="welcome-page-v2-component">
          <div className="welcome-page-v2-header">
            <Image src={homeLogo} className="welcome-page-v2-logo" fluid />
          </div>
          <div className="welcome-page-v2-content">
            <Premium />
          </div>
        </div>
      );
    }
    return (
      <div className="app-component" style={{ height, maxHeight: height }}>
        <Premium />
      </div>
    );
  }
}

export default withRouter(PremiumPage);
