import React, { useState, useEffect, useContext, useRef } from 'react';
import '../desktopprofiletabs.scss';
import Axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import { Link, withRouter } from 'react-router-dom';
import PlacesAutocomplete from 'react-places-autocomplete';
import Slider from '@material-ui/core/Slider';
import {
  Alert,
  Button,
  Card,
  Form,
  DropdownButton,
  Dropdown,
  Badge,
} from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import S3 from 'react-aws-s3';
import Media from '../../../../components/media/Media';

import PhotoGrid from '../../../userProfile/editProfile/editProfileV2Components/photoGrid/PhotoGrid';
import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import ClipLoader from 'react-spinners/ClipLoader';
import { api } from '../../../../api';

const config = {
  bucketName: process.env.REACT_APP_AWS_S3_BUCKET,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEYID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESSKEY,
};

const ReactS3Client = new S3(config);

function PhotosTab(props) {
  const context = useContext(UserContext);
  const fileInput = useRef(null);
  const [images, setImages] = useState(props.user.imgurl);
  const [uploading, setUploading] = useState(false);
  const [isAccepted, setAccepted] = useState(true);

  var imageTypes = [
    'image/webp',
    'image/heif',
    'image/heic',
    'image/jpg',
    'image/jpe',
    'image/jpeg',
    'image/png',
    'image/pdf',
    'image/jp2',
    'image/wdp',
    'image/jxr',
    'image/hdp',
    'image/svg',
    'image/tif',
    'image/tiff',
  ];

  var videoTypes = [
    'image/gif',
    'video/flv',
    'video/avi',
    'video/m3u8',
    'video/ts',
    'video/m2ts',
    'video/mts',
    'video/mov',
    'video/mkv',
    'video/mp4',
    'video/mpeg',
    'video/mpd',
    'video/webm',
    'video/wmv',
    'video/x-matroska',
    'video/quicktime',
  ];

  useEffect(() => {
    const saveImages = async (e) => {
      const data = {
        userid: context.user._id,
        images: images,
      };

      return api
        .saveImages(data)
        .then((res) => {
          if (res.status === 200) {
            setUploading(false);
          }
        })
        .catch((err) => {});
    };

    saveImages();
  }, [images]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const fileChangeHandler = async (e) => {
    const files = e.target.files;
    const filesArray = [];
    for (const key of Object.keys(files)) {
      filesArray.push(files);
    }
    const length = filesArray.length;
    var count = 0;
    setUploading(true);
    setAccepted(true);

    const uploaders = filesArray.map(async (file, index) => {
      var fd = new FormData();
      var filetype = file[index].type;
      if (file[index].name.slice(-3) === 'flv') {
        filetype = 'video/flv';
      } else if (file[index].name.slice(-3) === 'mkv') {
        filetype = 'video/mkv';
      } else if (file[index].name.slice(-3) === 'flv') {
        filetype = 'video/mov';
      }
      var url = '';
      fd.append('file', file[index]);
      fd.append('api_key', '323661161668594');
      fd.append('timestamp', (Date.now() / 1000) | 0);

      if (imageTypes.includes(filetype)) {
        var bytes = '';
        await getBase64(file[index]).then((data) => {
          bytes = data;
        });

        var success = true;
        await api
          .imageRekognition(bytes.split(',')[1])
          .then((res) => {
            var { data } = res;
            if (data === 'unacceptable') {
              success = false;
              setAccepted(false);
            }
          })
          .catch((err) => console.log(err));

        if (!success) {
          setUploading(false);
          return;
        }
        url = 'https://api.cloudinary.com/v1_1/sugarbae/image/upload';
        fd.append('upload_preset', 'txv5v2xj');
      }

      if (videoTypes.includes(filetype)) {
        url = 'https://api.cloudinary.com/v1_1/sugarbae/video/upload';
        fd.append('upload_preset', 'om70jgxq');
      }

      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
        },
        withCredentials: false,
      };

      await Axios.post(url, fd, options, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      })
        .then(async (res) => {
          count = count + 1;
          var imgurl = res.data.secure_url;
          if (videoTypes.includes(filetype)) {
            imgurl = imgurl.slice(0, -3);
            imgurl = imgurl + 'gif';
            const firstHalf = imgurl.slice(0, 49);
            const secondHalf = imgurl.slice(49);
            const settings = 'vs_20,dl_250,h_200,e_loop,du_10/';
            const fullUrl = firstHalf + settings + secondHalf;
            imgurl = fullUrl.slice(0, -3) + 'gif';

            // detect text and check content of video
            let isPassed = true;
            let keyText = null;
            let keyContent = null;
            const uploadTextVideoPromise = ReactS3Client.uploadFile(
              file[index],
              `${context.user._id}text`
            )
              .then((res) => (keyText = res.key))
              .catch((err) => console.error(err));
            const uploadContentVideoPromise = ReactS3Client.uploadFile(
              file[index],
              `${context.user._id}content`
            )
              .then((res) => (keyContent = res.key))
              .catch((err) => console.error(err));
            const uploadPromises = [
              uploadTextVideoPromise,
              uploadContentVideoPromise,
            ];
            await Promise.all(uploadPromises);

            const dataKey = {
              key: keyText,
              imgurl,
              userid: context.user._id,
            };
            const dataContent = {
              key: keyContent,
              imgurl,
              userid: context.user._id,
            };
            const detectTextPromise = api
              .videoRekognitionText(dataKey)
              .then((res) => {
                if (!res.data.isPassed) {
                  isPassed = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });
            const detectModerationPromise = api
              .videoRekognitionContent(dataContent)
              .then((res) => {
                if (!res.data.isPassed) {
                  isPassed = false;
                }
              })
              .catch((err) => {
                console.log(err);
              });
            const checkVideoPromise = [
              detectTextPromise,
              detectModerationPromise,
            ];
            await Promise.all(checkVideoPromise);

            if (!isPassed) {
              setAccepted(false);
              return setUploading(false);
            }
          }

          setImages((prevImages) => [...prevImages, imgurl]);

          if (count === length) {
            setUploading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setUploading(false);
        });
    });
  };

  const addPhotosHandler = (e) => {
    fileInput.current.click();
  };

  const makePrimaryHandler = (index) => async (e) => {
    var imagesCopy = [...images];
    var first = imagesCopy[index];

    imagesCopy.sort(function (x, y) {
      return x == first ? -1 : y == first ? 1 : 0;
    });

    setImages(imagesCopy);
  };
  const deletePhotoHandler = (index) => async (e) => {
    var imagesCopy = [...images];
    imagesCopy.splice(index, 1);
    setImages(imagesCopy);
  };

  return (
    <div className="desktop-profile-tab-component">
      <div className="desktop-profile-tab-header">
        <h1 className="desktop-profile-tab-header-title">Photos</h1>
      </div>

      {!isAccepted && (
        <Alert variant="danger">
          Some of your images(videos) were not approved. It must not contain
          text or adult content.
        </Alert>
      )}

      <div className="desktop-profile-tab-content-container-photos">
        {uploading && (
          <div className="desktop-profile-photos-uploading-indicator">
            <div className="desktop-profile-photos-uploading-innerwrap">
              <h2 className="desktop-profile-photos-uploading-title">
                Uploading...
              </h2>
              <ClipLoader color="#f9f9f9" />
            </div>
          </div>
        )}
        <div className="desktop-profile-photos-wrapper">
          {images.map((image, index) => {
            return (
              <div className="desktop-image-wrapper">
                <Media image={image} />
                {/* <Image
                  publicId={getImgName(image)}
                  version={getImgVersion(image)}
                  resource_type={checkGIF(image) ? "video" : "image"}
                  cloud_name="sugarbae"
                  className="desktop-image"
                >
                  <Transformation
                    height="200"
                    video_sampling="20"
                    delay="250"
                    duration="10"
                    effect="loop"
                    quality="auto"
                    flags="lossy"
                  />
                </Image> */}
                <div className="desktop-image-footer">
                  <div className="desktop-images-primary-indicator">
                    {index === 0 && (
                      <Badge
                        variant="primary"
                        className="desktop-images-primary-badge"
                      >
                        Primary
                      </Badge>
                    )}
                  </div>
                  <Dropdown className="desktop-image-settings-button-dropdown">
                    <Dropdown.Toggle className="desktop-image-settings-button">
                      <i className="fas fa-cog desktop-image-settings-icon"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {index !== 0 && (
                        <Dropdown.Item
                          as="button"
                          onClick={makePrimaryHandler(index)}
                        >
                          Make Primary
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        as="button"
                        onClick={deletePhotoHandler(index)}
                      >
                        Delete Photo
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            );
          })}
        </div>
        <div className="desktop-profile-photos-cta">
          <div className="desktop-photos-upload-input-wrapper">
            <label
              className="desktop-profile-photo-upload-label"
              htmlFor="desktop-photo-input"
            >
              <input
                type="file"
                multiple
                className="desktop-photo-input"
                name="desktop-photo-input"
                onChange={fileChangeHandler}
                id="desktop-photo-input"
                ref={fileInput}
                accept=".gif,.webp,.heif,.heic,.jpg,.jpe,.jpeg,.png,.pdf,.jp2,.wdp,.jxr,.hdp,,.svg,.tif,.tiff,.flv,.avi,.m3u8,.ts,.m2ts,.mts,.mov,.mkv,.mp4,.mpeg,mpd,.webm,.wmv"
              />
              <Button
                className="desktop-photo-input-upload-button"
                onClick={addPhotosHandler}
                size="lg"
              >
                Add Photos / Videos
              </Button>
            </label>
          </div>
          <div className="desktop-photos-cta-subtitle-wrapper">
            <p className="desktop-photos-cta-subtitle">
              Upload Photos or Short Videos so other users know what you look
              like. Make sure you are in the photo, there is no nudity, contact
              information, or innappropriate content.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PhotosTab);
