import React, { Component } from "react";
import "../help.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import LoadingSubmitNotification from "../../../components/notifications/LoadingSubmitNotification";
import { withRouter } from "react-router-dom";
import SwipeNav from "../../../components/navbar/SwipeNav";

export class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledBtn: true,
      reportText: null,
      loading: false,
      loadingSuccess: false,
    };
  }

  textChangeHandler = (e) => {
    this.setState({ reportText: e.target.value }, () => {
      if (this.state.reportText.length > 5) {
        this.setState({ disabledBtn: false });
      } else {
        this.setState({ disabledBtn: true });
      }
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ loading: true, loadingSuccess: true, error: false }, () => {
      setTimeout(() => {
        this.setState({ loading: false }, () => {
          this.props.history.push("/app");
        });
      }, 3000);
    });
  };
  render() {
    let vh = window.innerHeight;
    var height = vh + "px";
    return (
      <div
        className="app-component"
        style={{ height: height, maxHeight: height }}
      >
        {" "}
        <SwipeNav />
        {this.state.loading ? (
          <LoadingSubmitNotification
            loading={this.state.loading}
            success={this.state.loadingSuccess}
            loadingMsg={"Submitting Report..."}
            successMsg={"Successfully Submitted Report"}
          />
        ) : null}
        <div className="help-container">
          <div className="help-page-content-wrap">
            <h1 className="help-page-title">Report An Issue</h1>
            <h3 className="help-page-subtitletwo">
              Fill out the form with as much details as possible about the issue
              you are having.
            </h3>
            <Form onSubmit={this.submitHandler}>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Describe the issue below:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder={"Describe your issue in detail here..."}
                  onChange={this.textChangeHandler}
                />
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                disabled={this.state.disabledBtn}
                className={
                  this.state.disabledBtn ? "submit-report-btn-disabled" : null
                }
              >
                Submit
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Report);
