import React, { useContext, useRef, useEffect, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";

import Message from "../message/Message";
import { UserContext } from "../../../../context/UserContext";
import NoMessagesMatch from "../noMessagesMatch/NoMessagesMatch";

import "./messagefeed.scss";

var moment = require("moment");

function MessageFeed(props) {
  const context = useContext(UserContext);
  const messages = props.messages;
  const messagesLength = messages.length;
  const user = props.user;
  const match = props.match;
  const card = props.card;

  const bottomRef = useRef(null);
  const [fetching, setFetching] = useState(props.fetching);

  useEffect(() => {
    if (messagesLength > 0 && messagesLength < 16) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (messagesLength > 0 && fetching) {
      document.getElementById("previous-position") &&
        document.getElementById("previous-position").scrollIntoView();
    }
    setFetching(false);
  }, [messagesLength]);

  useEffect(() => {
    if (props.endOfMessages) {
      setFetching(false);
    }
  }, [props.endOfMessages]);

  useEffect(() => {
    if (props.messaging && messagesLength > 0) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
      props.checkMessaging();
    }
  }, [props.messaging]);

  const scrollHandler = () => {
    const div = document.getElementById("chat-mobile-content");
    if (div.scrollTop === 0 && !props.endOfMessages && !fetching) {
      setFetching(true);
      props.fetchMore();
    }
  };

  if (messagesLength > 0) {
    return (
      <div className="message-feed-component">
        {fetching && (
          <div className="fetching-spinner">
            <BeatLoader />
          </div>
        )}
        {messages.map((message, index) => {
          var consecutive = false;
          var timeStamp = false;
          var matchStamp = false;
          var matchDate = "";
          var isRead = message.isRead;
          var isLast = false;
          var showReadReceipt = false;
          var adjustedIndex = index + 1;
          var received = false;

          if (message.sender === context.user._id) {
            received = false;
          } else {
            received = true;
          }

          if (messagesLength === adjustedIndex) {
            isLast = true;
          }

          if (
            isLast &&
            message.sender.toString() === context.user._id.toString()
          ) {
            showReadReceipt = true;
          }

          if (index > 0) {
            var previousIndex = index - 1;
            var previousMessage = messages[previousIndex];
            var date = moment(message.createdAt);
            var previousMessageTime = moment(previousMessage.createdAt);
            var difference = date.diff(previousMessageTime, "hours");

            if (difference > 5) {
              timeStamp = true;
            }

            if (previousMessage.sender === message.sender) {
              consecutive = true;
            }
          } else {
            if (match === null || match === undefined) {
              matchStamp = false;
              matchDate = "";
            } else {
              matchStamp = true;
              matchDate = moment(match.date).calendar();
            }
          }

          var data = {
            consecutive: consecutive,
            timeStamp: timeStamp,
            matchStamp: matchStamp,
            matchDate: matchDate,
            isRead: isRead,
            isLast: isLast,
            showReadReceipt: showReadReceipt,
            received: received,
          };

          if (props.previousIndex !== 0 && index === props.previousIndex) {
            return (
              <div id="previous-position">
                <Message
                  message={message}
                  data={data}
                  user={user}
                  card={card}
                />
              </div>
            );
          } else {
            return (
              <Message message={message} data={data} user={user} card={card} />
            );
          }
        })}
        <div ref={bottomRef} />
      </div>
    );
  } else {
    return (
      <div className="message-feed-component">
        <NoMessagesMatch chatid={props.chatid} match={match} />
      </div>
    );
  }
}

export default MessageFeed;
