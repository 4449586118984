import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import axios from "axios";
import VerifyButton from "@passbase/button/react";

import Root from "./Root";
import * as serviceWorker from "./serviceWorker";
import "./root.scss";
import 'react-perfect-scrollbar/dist/css/styles.css';

// import "rheostat/initialize";
// import "react-toastify/dist/ReactToastify.css";

axios.defaults.withCredentials = true;

// Initialize Sentry
const sentryEnv = process.env.REACT_APP_SENTRY_ENVIRONMENT;
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
if (sentryEnv && (sentryEnv === "staging" || sentryEnv === "production")) {
  Sentry.init({
    dsn: sentryDsn,
    environment: sentryEnv,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: sentryEnv === "staging" ? 1.0 : 0.25,
    beforeSend(event, hint) {
      if (hint.originalException === "Timeout") return null;
      return event;
    },
  });
}

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
