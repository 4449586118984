import React, { useState } from 'react';
import {
  Card,
  Form,
  Button,
  InputGroup,
  FormControl,
  Alert,
} from 'react-bootstrap';
import '../botstab.scss';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../../../utils/getCloudinaryInfo';
import { Image as NoneImage } from 'react-bootstrap';
import male_user from '../../../../../assets/Images/adminSidebar/male_user.png';
import female_user from '../../../../../assets/Images/adminSidebar/female_user.png';
import moment from 'moment';
import { api, socket } from '../../../../../api';
import BotConvoModal from './BotConvoModal';
import Media from '../../../../../components/media/Media';

function BotMessageCard(props) {
  const [replyMessage, setReplyMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [convoModalOpen, setConvoModalOpen] = useState(false);

  const messageChangeHandler = (e) => {
    setReplyMessage(e.target.value);
  };

  const fetchConvo = (e) => {
    setConvoModalOpen(true);
  };

  const handleClose = (e) => {
    setConvoModalOpen(false);
  };

  const messageSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      sender: props.message.receiver.username,
      receiver: props.message.sender.username,
      message: replyMessage,
    };
    socket.sendMessage(data);
    setSuccess(true);
    setReplyMessage('');

    const chatData = {
      chatid: props.message.chat._id,
    };

    return api.updateBotChat(chatData).catch((err) => {
      console.log(err);
    });
  };

  var senderUser = props.message.sender;
  var receiverUser = props.message.receiver;

  var messageTimestamp = props.message.chat.createdAt;
  var formattedTimestamp = '';

  if (messageTimestamp) {
    messageTimestamp = moment(messageTimestamp);
    var timeDifference = moment().diff(messageTimestamp, 'hours');

    if (timeDifference > 24) {
      timeDifference = moment().diff(messageTimestamp, 'days');
      formattedTimestamp = timeDifference + ' days ago';
    } else if (timeDifference < 1) {
      timeDifference = moment().diff(messageTimestamp, 'minutes');
      if (timeDifference < 1) {
        timeDifference = moment().diff(messageTimestamp, 'seconds');
        formattedTimestamp = timeDifference + ' seconds ago';
      } else {
        formattedTimestamp = timeDifference + ' minutes ago';
      }
    } else {
      formattedTimestamp = timeDifference + ' hours ago';
    }
  }

  return (
    <Card className="admin-bot-message-card">
      <BotConvoModal
        show={convoModalOpen}
        message={props.message}
        handleClose={handleClose}
      />

      <Card.Header className="admin-bot-message-card-header">
        <div className="admin-bot-message-image-container">
          {senderUser.imgurl[0] ? (
            <Media image={senderUser.imgurl[0]} />
          ) : (
            // <Image
            //   publicId={getImgName(senderUser.imgurl[0])}
            //   version={getImgVersion(senderUser.imgurl[0])}
            //   resource_type={checkGIF(senderUser.imgurl[0]) ? "video" : "image"}
            //   effect="loop"
            //   cloud_name="sugarbae"
            //   className="img-userinfo"
            // >
            //   <Transformation
            //     height="250"
            //     video_sampling="20"
            //     delay="250"
            //     duration="10"
            //     effect="loop"
            //   />
            // </Image>
            <div className="none-image-container">
              <NoneImage
                className="none-user-image"
                src={senderUser.gender === 'male' ? male_user : female_user}
              />
            </div>
          )}
          <div className="admin-bot-message-image-divider">
            <i className="fas fa-chevron-right admin-bot-message-arrow"></i>
          </div>
          {receiverUser.imgurl[0] ? (
            <Media image={receiverUser.imgurl[0]} />
          ) : (
            // <Image
            //   publicId={getImgName(receiverUser.imgurl[0])}
            //   version={getImgVersion(receiverUser.imgurl[0])}
            //   resource_type={
            //     checkGIF(receiverUser.imgurl[0]) ? 'video' : 'image'
            //   }
            //   effect="loop"
            //   cloud_name="sugarbae"
            //   className="img-userinfo"
            // >
            //   <Transformation
            //     height="200"
            //     video_sampling="20"
            //     delay="250"
            //     duration="10"
            //     effect="loop"
            //   />
            // </Image>
            <div className="none-image-container">
              <NoneImage
                className="none-user-image"
                src={receiverUser.gender === 'male' ? male_user : female_user}
              />
            </div>
          )}
        </div>

        <div className="admin-bot-message-info-container">
          <div className="admin-bot-message-usernames">
            <p className="admin-bot-message-usernames-line">
              <span className="admin-bot-username">{senderUser.username}</span>{' '}
              to{' '}
              <span className="admin-bot-username">
                {receiverUser.username}
                {receiverUser.bot && ' (Bot)'}
              </span>
            </p>
          </div>
          <div className="admin-bot-message-timestamp">
            {formattedTimestamp}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="admin-bot-message-card-body">
        <div className="admin-bot-view-convo-cta">
          <Button className="admin-bot-view-convo-button" onClick={fetchConvo}>
            <i className="fas fa-chevron-up view-convo-icon"></i>
          </Button>
        </div>
        <div className="admin-bot-message-content-wrapper">
          <p className="admin-bot-message-content">
            {props.message.chat.message}
          </p>
        </div>
      </Card.Body>
      <Card.Footer className="admin-bot-message-card-footer">
        {success ? (
          <Alert variant="success">Successfully Sent Message</Alert>
        ) : (
          <Form onSubmit={messageSubmitHandler}>
            <InputGroup>
              <Form.Control
                as="textarea"
                id="bot-tab-reply-form-input"
                onChange={messageChangeHandler}
                value={replyMessage}
              />
              <InputGroup.Append>
                <Button variant="primary" type="submit">
                  Send
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        )}
      </Card.Footer>
    </Card>
  );
}

export default BotMessageCard;
