import React, { useState, useEffect, useContext, useRef } from 'react';
import './desktopphotogrid.scss';
import { Image, Transformation } from 'cloudinary-react';
import { Image as BootstrapImage, ProgressBar } from 'react-bootstrap';
import Axios from 'axios';
import S3 from 'react-aws-s3';
import BounceLoader from 'react-spinners/BounceLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import PhotoView from '../../../photoView/PhotoView';
import { UserContext } from '../../../../context/UserContext';
import { api } from '../../../../api';
import photoIcon from '../../../../assets/Icons/input/image.png';
import videoIcon from '../../../../assets/Icons/input/video.png';
import EditPhoto from '../../../photoGrid/photoGridComponents/EditPhoto';
import PhotoRequestModal from '../../../modals/photoRequestModal/PhotoRequestModal';
import Media from '../../../../components/media/Media';
import { uploadMedia } from '../../../../helpers/UploadMedia';

const imageTypes = [
  'image/webp',
  'image/heif',
  'image/heic',
  'image/jpg',
  'image/jpe',
  'image/jpeg',
  'image/png',
  'image/pdf',
  'image/jp2',
  'image/wdp',
  'image/jxr',
  'image/hdp',
  'image/svg',
  'image/tif',
  'image/tiff',
];

const videoTypes = [
  'image/gif',
  'video/flv',
  'video/avi',
  'video/m3u8',
  'video/ts',
  'video/m2ts',
  'video/mts',
  'video/mov',
  'video/mkv',
  'video/mp4',
  'video/mpeg',
  'video/mpd',
  'video/webm',
  'video/wmv',
  'video/x-matroska',
  'video/quicktime',
];

const config = {
  bucketName: process.env.REACT_APP_AWS_S3_BUCKET,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEYID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESSKEY,
};

const ReactS3Client = new S3(config);

function DesktopPhotoGridV2(props) {
  const context = useContext(UserContext);

  const { card, isOwnProfile } = props;
  const [tab, setTab] = useState('public');
  const [enableView, setEnableView] = useState(false);
  const [viewIndex, setViewIndex] = useState(null);
  const [requested, setRequested] = useState(false);
  const [requestApproved, setRequestApproved] = useState(false);
  const [files, setFiles] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [isAccepted, setIsAccepted] = useState(true);
  const [length, setLength] = useState(0);
  const [index, setIndex] = useState(0);
  const [publicImages, setPublicImages] = useState([]);
  const [privateImages, setPrivateImages] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [editImageOpen, setEditImageOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(null);
  const [uploadingText, setUploadingText] = useState('Uploading Files');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [peopleOpen, setPeopleOpen] = useState(false);
  const [requestsOpen, setRequestsOpen] = useState(false);

  let photoInputRef = useRef();
  const videoInputRef = useRef();

  const togglePeople = (e) => {
    if (peopleOpen) {
      setPeopleOpen(false);
    } else {
      setPeopleOpen(true);
    }
  };
  const toggleRequests = (e) => {
    if (requestsOpen) {
      setRequestsOpen(false);
    } else {
      setRequestsOpen(true);
    }
  };

  const closeHandler = () => {
    setEditImageOpen(false);
    setImageIndex(null);
  };

  const editImage = (e) => {
    setImageIndex(e);
    setEditImageOpen(true);
  };

  useEffect(() => {
    if (!props.loading) {
      checkRequest();
    }
    if (!props.loading) {
      setPublicImages(card.imgurl);
      setPrivateImages(card.privatePhotos);
    }
  }, [props.loading]);

  const togglePhoto = (e) => {
    photoInputRef.click();
  };
  const toggleVideo = (e) => {
    photoInputRef.click();
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const checkRequest = async () => {
    const data = {
      cardid: props.card._id,
    };

    await api
      .checkRequest(data)
      .then((res) => {
        const { request } = res.data;
        if (context.user._id === props.card._id) {
          setRequested(true);
          setRequestApproved(true);
        } else if (request) {
          setRequested(true);
          setRequestApproved(request.approved);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const requestHandler = (e) => {
    if (!requested) {
      const data = {
        cardid: props.card._id,
      };

      return api
        .requestPrivatePics(data)
        .then((res) => {
          if (res.status === 200) {
            setRequested(true);
          }
        })
        .catch(() => {});
    }
  };

  const showImageHandler = (e) => {
    setViewIndex(e);
    setEnableView(true);
  };

  const imageClickHandler = (e) => {
    if (isOwnProfile) {
      editImage(e);
    } else {
      showImageHandler(e);
    }
  };

  const privatePhotoCount = privateImages.length;
  const placeholders = [1, 2, 3, 4, 5, 6];

  // Images Upload

  const fileChange = async (e) => {
    const files = e.target.files;
    if (files.length === 0) return;

    setFiles(files);
    setUploading(true);
    setLength(length);
    setUploadPercentage(0);
    setIndex(0);
    setIsAccepted(true);
  };

  const saveImages = async (e) => {
    const data = {
      userid: context.user._id,
      images: publicImages,
    };

    return api
      .saveImages(data)
      .then((res) => {
        const tempUser = context.user;
        tempUser.imgurl = publicImages;
        context.updateUserInfo('all', tempUser);
      })
      .catch((err) => {});
  };

  const savePrivateImages = async (e) => {
    const data = {
      userid: context.user._id,
      images: privateImages,
    };

    return api
      .savePrivateImages(data)
      .then(() => {
        const tempUser = context.user;
        tempUser.privatePhotos = privateImages;
        context.updateUserInfo('all', tempUser);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (uploading) {
      upload();
    }
  }, [uploading]);

  // useEffect(() => {
  //   if (uploading) {
  //     let count = 0;
  //     const filesArray = [];
  //     for (const key of Object.keys(files)) {
  //       filesArray.push(files);
  //     }
  //     const { length } = filesArray;
  //     setUploadingText('Checking if media meets our guidelines');
  //     setUploadPercentage(25);
  //     const uploaders = filesArray.map(async (file, index) => {
  //       const fd = new FormData();
  //       let filetype = file[index].type;
  //       if (file[index].name.slice(-3) === 'flv') {
  //         filetype = 'video/flv';
  //       } else if (file[index].name.slice(-3) === 'mkv') {
  //         filetype = 'video/mkv';
  //       } else if (file[index].name.slice(-3) === 'flv') {
  //         filetype = 'video/mov';
  //       }
  //       let url = '';
  //       fd.append('file', file[index]);
  //       fd.append('api_key', '323661161668594');
  //       fd.append('timestamp', (Date.now() / 1000) | 0);

  //       if (imageTypes.includes(filetype)) {
  //         let bytes = '';
  //         await getBase64(file[index]).then((data) => {
  //           bytes = data;
  //         });

  //         let success = true;
  //         await api
  //           .imageRekognition(bytes.split(',')[1])
  //           .then((res) => {
  //             const { data } = res;
  //             if (data === 'unacceptable') {
  //               success = false;
  //               setIsAccepted(false);
  //             }
  //           })
  //           .catch((err) => console.log(err));

  //         if (!success) {
  //           setUploading(false);
  //           return;
  //         }
  //         setUploadPercentage(75);
  //         setUploadingText('Content Approved');
  //         url = 'https://api.cloudinary.com/v1_1/sugarbae/image/upload';
  //         fd.append('upload_preset', 'txv5v2xj');
  //       }

  //       if (videoTypes.includes(filetype)) {
  //         url = 'https://api.cloudinary.com/v1_1/sugarbae/video/upload';
  //         fd.append('upload_preset', 'om70jgxq');
  //       }

  //       const options = {
  //         onUploadProgress: (progressEvent) => {
  //           const { loaded, total } = progressEvent;
  //           const percent = Math.floor((loaded * 100) / total);
  //           setUploadPercentage(percent);
  //           setIndex(index);
  //         },
  //         withCredentials: false,
  //       };

  //       setUploadingText('Saving Photos');

  //       await Axios.post(url, fd, options, {
  //         headers: { 'X-Requested-With': 'XMLHttpRequest' },
  //       }).then(async (res) => {
  //         count += 1;
  //         let imgurl = res.data.secure_url;
  //         if (videoTypes.includes(filetype)) {
  //           imgurl = imgurl.slice(0, -3);
  //           imgurl += 'gif';
  //           const firstHalf = imgurl.slice(0, 49);
  //           const secondHalf = imgurl.slice(49);
  //           const settings = 'vs_20,dl_250,h_200,e_loop,du_10/';
  //           const fullUrl = firstHalf + settings + secondHalf;
  //           imgurl = `${fullUrl.slice(0, -3)}gif`;

  //           // detect text and check content of video
  //           let isPassed = true;
  //           let keyText = null;
  //           let keyContent = null;
  //           const uploadTextVideoPromise = ReactS3Client.uploadFile(
  //             file[index],
  //             `${context.user._id}text`
  //           )
  //             .then((res) => (keyText = res.key))
  //             .catch((err) => console.error(err));
  //           const uploadContentVideoPromise = ReactS3Client.uploadFile(
  //             file[index],
  //             `${context.user._id}content`
  //           )
  //             .then((res) => (keyContent = res.key))
  //             .catch((err) => console.error(err));
  //           const uploadPromises = [
  //             uploadTextVideoPromise,
  //             uploadContentVideoPromise,
  //           ];
  //           await Promise.all(uploadPromises);

  //           const dataKey = {
  //             key: keyText,
  //             imgurl,
  //             userid: context.user._id,
  //           };
  //           const dataContent = {
  //             key: keyContent,
  //             imgurl,
  //             userid: context.user._id,
  //           };
  //           const detectTextPromise = api
  //             .videoRekognitionText(dataKey)
  //             .then((res) => {
  //               if (!res.data.isPassed) {
  //                 isPassed = false;
  //               }
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //             });
  //           const detectModerationPromise = api
  //             .videoRekognitionContent(dataContent)
  //             .then((res) => {
  //               if (!res.data.isPassed) {
  //                 isPassed = false;
  //               }
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //             });
  //           const checkVideoPromise = [
  //             detectTextPromise,
  //             detectModerationPromise,
  //           ];
  //           await Promise.all(checkVideoPromise);

  //           if (!isPassed) {
  //             setUploading(false);
  //             setIsAccepted(false);
  //             return null;
  //           }
  //         }

  //         if (tab === 'public') {
  //           var tempImages = publicImages;
  //           var uploading = true;
  //           tempImages.push(imgurl);
  //           setPublicImages(publicImages);
  //         } else {
  //           var tempImages = privateImages;
  //           var uploading = true;
  //           tempImages.push(imgurl);
  //           setPrivateImages(privateImages);
  //         }

  //         if (count === length) {
  //           uploading = false;
  //         }

  //         setUploading(uploading);
  //         setRefresh(true);
  //       });
  //     });
  //   }
  // }, [uploading]);

  const upload = async (e) => {
    let file = files[0];

    const uploadImage = await uploadMedia(file, context.user._id, tab);
    var tempImages = uploadImage.images;

    if (tab === 'public') {
      setPublicImages(tempImages);
    } else {
      setPrivateImages(tempImages);
    }

    setUploading(false);
    setRefresh(true);
  };

  useEffect(() => {
    if (refresh) {
      saveImages();
      savePrivateImages();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!isAccepted) {
      setTimeout(() => {
        setIsAccepted(true);
      }, 5000);
    }
  }, [isAccepted]);

  const setAsFirst = (index) => {
    const firstImage = publicImages[index];
    publicImages.splice(index, 1);
    publicImages.unshift(firstImage);
    setPublicImages(publicImages);
    setRefresh(true);
  };

  const setAsFirstPrivate = (index) => {
    const firstImage = privateImages[index];
    privateImages.splice(index, 1);
    privateImages.unshift(firstImage);
    setPrivateImages(privateImages);
    setRefresh(true);
  };

  const makeAsPrivate = (index) => {
    const tempImage = publicImages[index];
    publicImages.splice(index, 1);
    privateImages.push(tempImage);
    setPublicImages(publicImages);
    setPrivateImages(privateImages);
    setRefresh(true);
  };

  const makeAsPublic = (index) => {
    const tempImage = privateImages[index];
    privateImages.splice(index, 1);
    publicImages.push(tempImage);
    setPublicImages(publicImages);
    setPrivateImages(privateImages);
    setRefresh(true);
  };
  const removeHandler = (index) => {
    if (tab === 'public') {
      publicImages.splice(index, 1);
      setPublicImages(publicImages);
    } else {
      privateImages.splice(index, 1);
      setPrivateImages(privateImages);
    }
    setRefresh(true);
  };

  return (
    <div className="desktop-v2-photo-grid-container">
      {requestsOpen && (
        <PhotoRequestModal
          option="requests"
          closeHandler={() => setRequestsOpen(false)}
        />
      )}
      {peopleOpen && (
        <PhotoRequestModal
          option="people"
          closeHandler={() => setPeopleOpen(false)}
        />
      )}
      {uploading && (
        <div className="desktop-v2-photo-grid-loading-indicator">
          <div className="desktop-v2-photo-grid-loading-container">
            <div className="desktop-v2-photo-grid-loading-popup">
              <ClipLoader size={14} color="#888" />
              <h4 className="desktop-v2-photo-grid-loading-title">
                Uploading Media...
              </h4>
              <ProgressBar
                animated
                now={uploadPercentage}
                label={uploadPercentage}
                className="upload-progress-bar"
              />
              <p className="desktop-v2-photo-grid-subtitle">{uploadingText}</p>
            </div>
          </div>
        </div>
      )}
      <input
        type="file"
        accept=".gif,.webp,.heif,.heic,.jpg,.jpe,.jpeg,.png,.pdf,.jp2,.wdp,.jxr,.hdp,,.svg,.tif,.tiff,.flv,.avi,.m3u8,.ts,.m2ts,.mts,.mov,.mkv,.mp4,.mpeg,mpd,.webm,.wmv"
        multiple={false}
        onChange={fileChange}
        className="photo-grid-input"
        name="photo-input"
        id="photo-input"
        ref={(fileInput) => (photoInputRef = fileInput)}
      />
      {enableView && (
        <PhotoView
          images={tab === 'public' ? publicImages : privateImages}
          index={viewIndex}
          closeHandler={() => setEnableView(false)}
        />
      )}
      {editImageOpen && isOwnProfile && (
        <EditPhoto
          tab={tab}
          images={tab === 'public' ? publicImages : privateImages}
          index={imageIndex}
          setAsFirst={setAsFirst}
          setAsFirstPrivate={setAsFirstPrivate}
          removeHandler={removeHandler}
          makeAsPrivate={makeAsPrivate}
          makeAsPublic={makeAsPublic}
          closeHandler={closeHandler}
        />
      )}
      <div className="desktop-v2-photo-grid-tab-container">
        <div
          className={
            tab === 'public'
              ? 'desktop-v2-photo-grid-tab-active'
              : 'desktop-v2-photo-grid-tab'
          }
          onClick={() => setTab('public')}
        >
          Public
        </div>
        <div
          className={
            tab === 'private'
              ? 'desktop-v2-photo-grid-tab-active'
              : 'desktop-v2-photo-grid-tab'
          }
          onClick={() => setTab('private')}
        >
          Private
        </div>
        {isOwnProfile && (
          <div
            className="desktop-v2-photo-grid-menu"
            onClick={() => setDropdownOpen(!dropdownOpen)}
          >
            <i
              className={dropdownOpen ? 'far fa-times' : 'far fa-ellipsis-h'}
            />
          </div>
        )}
        {dropdownOpen && (
          <div className="desktop-v2-photo-grid-dropdown">
            <div
              className="desktop-v2-photo-grid-dropdown-row"
              onClick={togglePeople}
            >
              <div className="desktop-v2-photo-grid-dropdown-row-section">
                <div>
                  <i className="far fa-user-friends request-row-icon" />
                </div>
                <div className="request-row-title">People</div>
              </div>
              <div className="desktop-v2-photo-grid-dropdown-row-section">
                <div className="request-row-number">
                  {context.approvedRequests.length}
                </div>
                <i className="far fa-chevron-right request-right-icon" />
              </div>
            </div>
            <div
              className="desktop-v2-photo-grid-dropdown-row"
              onClick={toggleRequests}
            >
              <div className="desktop-v2-photo-grid-dropdown-row-section">
                <div>
                  <i className="far fa-user-plus request-row-icon-red" />
                </div>
                <div className="request-row-title">Requests</div>
              </div>
              <div className="desktop-v2-photo-grid-dropdown-row-section">
                <div className="request-row-number">
                  {context.requests.length}
                </div>
                <i className="far fa-chevron-right request-right-icon" />
              </div>
            </div>
          </div>
        )}
      </div>

      {/* {tab === "private" && isOwnProfile && (
        <div className="desktop-v2-photo-grid-request-container">
          <div className="photo-grid-requests-container">
            <div className="photo-grid-requests-row" onClick={togglePeople}>
              <div className="photo-grid-request-row-section">
                <i className="far fa-user-friends request-row-icon" />
                <div className="request-row-title">People</div>
              </div>
              <div className="photo-grid-request-row-section">
                <div className="request-row-number">
                  {context.approvedRequests.length}
                </div>
                <i className="far fa-chevron-right request-right-icon" />
              </div>
            </div>
            <div className="photo-grid-requests-row" onClick={toggleRequests}>
              <div className="photo-grid-request-row-section">
                <i className="far fa-user-plus request-row-icon-red" />
                <div className="request-row-title">Requests</div>
              </div>
              <div className="photo-grid-request-row-section">
                <div className="request-row-number">
                  {context.requests.length}
                </div>
                <i className="far fa-chevron-right request-right-icon" />
              </div>
            </div>
          </div>
        </div>
      )} */}
      <div className="desktop-v2-photo-grid-content-container">
        {tab === 'public' && (
          <>
            {props.loading ? (
              <>
                {placeholders.map((placeholder, index) => {
                  let imageStyle = {};
                  if (index === 0 && !isOwnProfile) {
                    imageStyle = {
                      marginLeft: '1rem',
                    };
                  }

                  return (
                    <div
                      className="desktop-v2-photo-grid-placeholder"
                      style={imageStyle}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {isOwnProfile && (
                  <>
                    <div
                      className="desktop-v2-photo-grid-input"
                      onClick={togglePhoto}
                      style={{ marginLeft: '1rem' }}
                    >
                      <BootstrapImage
                        src={photoIcon}
                        className="desktop-v2-photo-grid-input-icon"
                      />
                      <p className="desktop-v2-photo-grid-input-title">
                        Add Photo
                      </p>
                    </div>
                    <div
                      className="desktop-v2-photo-grid-input"
                      onClick={toggleVideo}
                    >
                      <BootstrapImage
                        src={videoIcon}
                        className="desktop-v2-photo-grid-input-icon"
                      />
                      <p className="desktop-v2-photo-grid-input-title">
                        Add Video
                      </p>
                    </div>
                  </>
                )}
                {publicImages.length > 0 ? (
                  <>
                    {publicImages.map((image, index) => {
                      let imageStyle = {};
                      if (index === 0 && !isOwnProfile) {
                        imageStyle = {
                          marginLeft: '1rem',
                        };
                      }

                      return (
                        <div
                          className="desktop-v2-photo-grid-user-photo-wrapper"
                          style={imageStyle}
                          onClick={() => imageClickHandler(index)}
                        >
                          <Media image={image} />
                          {/* <Image
                            publicId={
                              image
                                ? getImgName(image)
                                : "placeholder-user-image-thumbnail_vankru.png"
                            }
                            version={
                              image ? getImgVersion(image) : "1590045309"
                            }
                            resource_type={
                              image
                                ? checkGIF(image)
                                  ? "video"
                                  : "image"
                                : null
                            }
                            effect="loop"
                            cloud_name="sugarbae"
                            className="desktop-v2-photo-grid-user-image"
                            fluid
                          >
                            <Transformation
                              height="200"
                              video_sampling="20"
                              delay="250"
                              duration="10"
                              effect="loop"
                              quality="auto"
                              flags="lossy"
                            />
                          </Image> */}
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {!isOwnProfile && (
                      <div className="desktop-v2-photo-grid-no-photos-indicator">
                        <div className="desktop-v2-photo-grid-no-photos-innerwrap">
                          <h1 className="desktop-v2-photo-grid-no-photos-title">
                            This user has no private photos.
                          </h1>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
        {tab === 'private' && (
          <>
            {props.loading ? (
              <>
                {placeholders.map((placeholder, index) => {
                  let imageStyle = {};
                  if (index === 0) {
                    imageStyle = {
                      marginLeft: '1rem',
                    };
                  }

                  return (
                    <div
                      className="desktop-v2-photo-grid-placeholder"
                      style={imageStyle}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {isOwnProfile && (
                  <>
                    <div
                      className="desktop-v2-photo-grid-input"
                      onClick={togglePhoto}
                      style={{ marginLeft: '1rem' }}
                    >
                      <BootstrapImage
                        src={photoIcon}
                        className="desktop-v2-photo-grid-input-icon"
                      />
                      <p className="desktop-v2-photo-grid-input-title">
                        Add Private Photo
                      </p>
                    </div>
                    <div
                      className="desktop-v2-photo-grid-input"
                      onClick={toggleVideo}
                    >
                      <BootstrapImage
                        src={videoIcon}
                        className="desktop-v2-photo-grid-input-icon"
                      />
                      <p className="desktop-v2-photo-grid-input-title">
                        Add Private Video
                      </p>
                    </div>
                  </>
                )}
                {privateImages.length > 0 ? (
                  <>
                    {requestApproved ? (
                      <>
                        {privateImages.map((image, index) => {
                          let imageStyle = {};
                          if (index === 0 && !isOwnProfile) {
                            imageStyle = {
                              marginLeft: '1rem',
                            };
                          }

                          return (
                            <div
                              className="desktop-v2-photo-grid-user-photo-wrapper"
                              style={imageStyle}
                              onClick={() => imageClickHandler(index)}
                            >
                              <Media image={image} />
                              {/* <Image
                                publicId={
                                  image
                                    ? getImgName(image)
                                    : 'placeholder-user-image-thumbnail_vankru.png'
                                }
                                version={
                                  image ? getImgVersion(image) : '1590045309'
                                }
                                resource_type={
                                  image
                                    ? checkGIF(image)
                                      ? 'video'
                                      : 'image'
                                    : null
                                }
                                effect="loop"
                                cloud_name="sugarbae"
                                className="desktop-v2-photo-grid-user-image"
                                fluid
                              >
                                <Transformation
                                  height="200"
                                  video_sampling="20"
                                  delay="250"
                                  duration="10"
                                  effect="loop"
                                  quality="auto"
                                  flags="lossy"
                                />
                              </Image> */}
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {privateImages.map((image, index) => {
                          let imageStyle = {};
                          if (index === 0 && !isOwnProfile) {
                            imageStyle = {
                              marginLeft: '1rem',
                            };
                          }

                          if (index > 3) {
                            return null;
                          }

                          return (
                            <div
                              className="desktop-v2-photo-grid-user-photo-wrapper"
                              style={imageStyle}
                              onClick={() => imageClickHandler(index)}
                            >
                              <Media image={image} />
                              {/* <Image
                                publicId={
                                  image
                                    ? getImgName(image)
                                    : 'placeholder-user-image-thumbnail_vankru.png'
                                }
                                version={
                                  image ? getImgVersion(image) : '1590045309'
                                }
                                resource_type={
                                  image
                                    ? checkGIF(image)
                                      ? 'video'
                                      : 'image'
                                    : null
                                }
                                effect="loop"
                                cloud_name="sugarbae"
                                className="desktop-v2-photo-grid-user-image-blur"
                                fluid
                              >
                                <Transformation
                                  height="200"
                                  video_sampling="20"
                                  delay="250"
                                  duration="10"
                                  effect="loop"
                                  quality="auto"
                                  flags="lossy"
                                />
                              </Image> */}
                              <div className="profile-v2-photo-unlock">
                                <div className="profile-v2-unlock-icon-wrapper">
                                  <i className="far fa-lock" />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div
                          className="desktop-v2-photo-grid-user-photo-wrapper-request"
                          onClick={requestHandler}
                        >
                          <div className="desktop-profile-v2-photo-request-innerwrap">
                            <h2 className="desktop-profile-v2-request-title">
                              Private ({privatePhotoCount})
                            </h2>
                            <div className="desktop-profile-v2-unlock-icon-wrapper-request">
                              <i className="far fa-lock" />
                            </div>
                            {requested ? (
                              <p className="desktop-profile-v2-request-subtitle">
                                Access Requested
                              </p>
                            ) : (
                              <p className="desktop-profile-v2-request-subtitle">
                                Request to View
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {!isOwnProfile && (
                      <div className="desktop-v2-photo-grid-no-photos-indicator">
                        <div className="desktop-v2-photo-grid-no-photos-innerwrap">
                          <h1 className="desktop-v2-photo-grid-no-photos-title">
                            This user has no private photos.
                          </h1>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DesktopPhotoGridV2;
