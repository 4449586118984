import React, { Component } from "react";
import { Checkmark } from "react-checkmark";
import "./loadingsubmitnotification.scss";
import ClipLoader from "react-spinners/ClipLoader";

export class LoadingSubmitNotification extends Component {
  render() {
    return (
      <div className="loading-submit-overlay">
        <div className="loading-submit-innerwrap">
          <div className="loading-submit-wrapper">
            <div className="loading-submit-icon">
              {this.props.success ? (
                <Checkmark size={120} />
              ) : (
                <div>
                  <ClipLoader color={"#fff"} size={100} />
                </div>
              )}
            </div>
            <div className="loading-submit-textwrap">
              {this.props.success ? (
                <h4 className="loading-submit-text">{this.props.successMsg}</h4>
              ) : (
                <h4 className="loading-submit-text">{this.props.loadingMsg}</h4>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingSubmitNotification;
