import React, { useState, useContext } from "react";
import DesktopNavV2 from "../desktopNavV2/DesktopNavV2";
import DesktopSidebarV2 from "../desktopSidebarV2/DesktopSidebarV2";
import "./desktopmatches.scss";
import { UserContext } from "../../../../context/UserContext";
import LikesCard from "../../../matches/matchesComponents/likesCard/LikesCard";
import { MatchCard } from "../../../matches/matchesComponentsV2/matchCard/MatchCard";
import Boost from "../../../matches/matchesComponentsV2/boost/Boost";
import DesktopMatchCard from "./DesktopMatchCard";
import DesktopLikesCard from "./DesktopLikesCard";
import { submitLike } from "../../../../helpers/swipeActions";
import DesktopMatchedPopup from "../desktopMatchedPopup/DesktopMatchedPopup";

function DesktopMatchesV2(props) {
  const context = useContext(UserContext);
  const [matched, setMatched] = useState(false);
  const [matchedUser, setMatchedUser] = useState(null);

  const [tab, setTab] = useState("likes");

  let likesTabStyle = "desktop-matches-nav-tab";
  let matchesTabStyle = "desktop-matches-nav-tab";

  if (tab === "likes") {
    likesTabStyle = "desktop-matches-nav-tab-active";
  }

  if (tab === "matches") {
    matchesTabStyle = "desktop-matches-nav-tab-active";
  }

  const handleMatch = (e) => {
    setMatchedUser(e);
    setMatched(true);
  };

  const likeHandler = async (e) => {};
  const dislikeHandler = (e) => {};
  const toggleViewLikesModal = (e) => {};
  const toggleProfile = (e) => {};

  return (
    <div className="desktop-v2-container">
      {matched && (
        <DesktopMatchedPopup
          card={matchedUser}
          closeMatchHandler={() => {
            setMatched(false);
            setMatchedUser(null);
          }}
        />
      )}
      <DesktopSidebarV2 />
      <div className="desktop-v2-content">
        <DesktopNavV2 />
        <div className="desktop-matches-container">
          <div className="desktop-matches-navigation">
            <div
              className={likesTabStyle}
              onClick={() => {
                setTab("likes");
              }}
            >
              Likes
            </div>
            <div
              className={matchesTabStyle}
              onClick={() => {
                setTab("matches");
              }}
            >
              Matches
            </div>
          </div>
          <div className="desktop-matches-results">
            {tab === "matches" && (
              <div className="desktop-matches-results-innerwrap">
                {context.matches && context.matches.length > 0 ? (
                  <>
                    {context.matches.map((match, index) => (
                      <DesktopMatchCard
                        index={index}
                        card={match}
                        profileToggle={toggleProfile}
                      />
                    ))}
                  </>
                ) : (
                  <div className="end-indicator">
                    <Boost option="matches" />
                  </div>
                )}
              </div>
            )}

            {tab === "likes" && (
              <div className="desktop-matches-results-innerwrap">
                {context.likes && context.likes.length > 0 ? (
                  <>
                    {context.likes.map((like, index) => (
                      <DesktopLikesCard
                        index={index}
                        like={like}
                        premium={context.user.premium}
                        likeHandler={likeHandler}
                        dislikeHandler={dislikeHandler}
                        viewLikesModalToggle={toggleViewLikesModal}
                        profileToggle={toggleProfile}
                        handleMatch={handleMatch}
                      />
                    ))}
                  </>
                ) : (
                  <div className="end-indicator">
                    <Boost option="likes" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesktopMatchesV2;
