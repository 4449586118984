import React, { Component } from "react";
import "./headline.scss";

import { Card } from "react-bootstrap";

export class Headline extends Component {
  render() {
    var headline = this.props.card.headline;
    var headlinestatus = this.props.card.headlinestatus;
    var username = this.props.card.username;

    if (
      headline === null ||
      headline === undefined ||
      headline.length < 2 ||
      headlinestatus !== "approved"
    ) {
      return null;
    } else {
      return (
        <Card className="user-info-card">
          <Card.Body className="headline-card-body">
            <Card.Title className="headline-card-title">
              About {username}:
            </Card.Title>
            <Card.Text className="headline-card-text">
              {this.props.card.headline}
            </Card.Text>
          </Card.Body>
        </Card>
      );
    }
  }
}

export default Headline;
