import React, { Component } from "react";
import "./maintenancepage.scss";
import { Container, Image, Card, Button } from "react-bootstrap";
import logo from "../../../assets/logos/icon-logo-white.png";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

export class MaintenancePage extends Component {
  logoutHandler = (e) => {
    console.log("logout handler");
    localStorage.removeItem("auth");
    this.props.history.push("/");
  };
  render() {
    return (
      <div className="maintenance-page">
        <div className="maintenance-nav">
          <Image
            src={logo}
            className="maintenance-nav-logo"
            onClick={this.logoutHandler}
          />
        </div>
        <Container className="maintenance-container">
          <Card className="maintenance-card">
            <Card.Body className="maintenance-body">
              <h1 className="maintenance-title">
                We've got something special in store for you.
              </h1>
              <p className="maintenance-subtitle">
                We are currently performing site upgrades. Please check back
                soon.
              </p>
              <Button className="maintenance-btn" onClick={this.logoutHandler}>
                Home
              </Button>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

export default withRouter(MaintenancePage);
