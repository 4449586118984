import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import MessageQueueItem from "../../../messages/inbox/inboxComponents/messageQueue/MessageQueueItem";
import "./desktopmessagesv2.scss";
import DesktopNewMatches from "./DesktopNewMatches";
import BottomScrollListener from "react-bottom-scroll-listener";
import ClipLoader from "react-spinners/ClipLoader";
import MatchQueue from "../../../messages/messagesComponents/matchQueue/MatchQueue";

function DeskopInbox(props) {
  const context = useContext(UserContext);
  const [fetching, setFetching] = useState(false);

  const setFilterBy = (e) => {
    context.updateMessageQueueSortby(e);
  };

  const openChatHandler = (e) => {
    props.openChatHandler(e);
  };

  const handleScroll = (e) => {
    if (
      !context.messageQueueLoading &&
      context.messageData &&
      context.messageData.length > 0 &&
      !fetching
    ) {
      setFetching(true);
      context.getMoreMessage();
    }
  };

  useEffect(() => {
    setFetching(false);
  }, [context.messageData]);

  return (
    <BottomScrollListener onBottom={handleScroll} offset={500}>
      {(scrollRef) => (
        <div className="desktop-inbox-v2" ref={scrollRef}>
          <DesktopNewMatches />
          <div className="desktop-inbox-sort-container">
            <Dropdown className="desktop-inbox-dropdown">
              <Dropdown.Toggle
                variant="success"
                className="desktop-inbox-dropdown-toggle"
                id="dropdown-basic"
              >
                <div className="desktop-dropdown-toggle-title">
                  {context.messageQueueSortby}
                </div>
                <div className="desktop-dropdown-toggle-icon">
                  <i className="far fa-chevron-down" />
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="desktop-dropdown-menu-inbox">
                <Dropdown.Item onClick={() => setFilterBy("All Messages")}>
                  <div className="desktop-inbox-dropdown-item-icon-wrap" />
                  <div className="desktop-inbox-dropdown-item-title">
                    <span className="icon desktop-icon-message-sort-all" />
                    All Messages
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFilterBy("Compliments")}>
                  <div className="desktop-inbox-dropdown-item-icon-wrap" />
                  <div className="desktop-inbox-dropdown-item-title">
                    <span className="icon desktop-icon-message-sort-compliments" />
                    Compliments
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFilterBy("Online")}>
                  <div className="desktop-inbox-dropdown-item-icon-wrap" />
                  <div className="desktop-inbox-dropdown-item-title">
                    <span className="icon desktop-icon-message-sort-online" />
                    Online
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setFilterBy("Matches")}>
                  <div className="desktop-inbox-dropdown-item-icon-wrap" />
                  <div className="desktop-inbox-dropdown-item-title">
                    <span className="icon desktop-icon-message-sort-matches" />
                    Matches
                  </div>
                </Dropdown.Item>

                <Dropdown.Item onClick={() => setFilterBy("Nearest")}>
                  <div className="desktop-inbox-dropdown-item-icon-wrap" />
                  <div className="desktop-inbox-dropdown-item-title">
                    <span className="icon desktop-icon-message-sort-nearest" />
                    Nearest
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="desktop-v2-message-queue">
            {context.messageQueueLoading ? (
              <div
                className="desktop-chat-v2-loading-indicator"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ClipLoader size="18px" color="#b5b5b5" />
              </div>
            ) : (
              <>
                {context.messageData && context.messageData.length > 0 ? (
                  <>
                    <div style={{ cursor: "pointer" }}>
                      {context.messageData.map((message, index) => {
                        const userid = message.user._id;
                        return (
                          <div onClick={() => openChatHandler(userid)}>
                            <MessageQueueItem
                              id={userid}
                              chatToggle={() => {}}
                              message={message}
                              userId={context.user._id}
                              index={index}
                            />
                          </div>
                        );
                      })}
                    </div>
                    {fetching && (
                      <div
                        style={{
                          padding: "1rem",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ClipLoader size="12px" />
                      </div>
                    )}
                  </>
                ) : (
                  <div>
                    <span>No Messages</span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </BottomScrollListener>
  );
}

export default DeskopInbox;
