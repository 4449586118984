import React, { useContext, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import "../userprofilev2componentstyles.scss";
import check from "../../../../assets/Icons/check.svg";
import { withRouter, useHistory } from "react-router-dom";

function VerificationsProgress(props) {
  const context = useContext(UserContext);
  const history = useHistory();

  let progress = 0;
  if (context.user.verifications.email.status) {
    progress += 33;
  }
  if (context.user.verifications.phone.status) {
    progress += 33;
  }
  if (context.user.verifications.id.status) {
    progress += 33;
  }

  let verified = false;
  if (
    context.user.verifications.phone.status &&
    context.user.verifications.email.status &&
    context.user.verifications.id.status
  ) {
    verified = true;
    progress = 100;
  }

  const verificationIconStyle = "fas fa-badge-check verification-progress-icon";
  // if (verified) {
  //   verificationIconStyle =
  //     "fas fa-badge-check verification-progress-icon-verified";
  // }

  const toggleEditProfile = (e) => {
    props.toggleEditProfile();
  };

  const verificationToggle = (e) => {
    if (props.desktop) {
      props.verificationToggle();
    } else {
      history.push("/app/verification");
    }
  };

  return (
    <div className="user-profile-v2-component" onClick={verificationToggle}>
      <div className="profile-component-section">
        <div className="user-profile-v2-icon-wrapper">
          <Image src={check} className="user-profile-diamond-icon" fluid />
        </div>
        <h4 className="verification-progress-title">Verifications</h4>
        <h1 className="verifications-progress-number">{progress}%</h1>
      </div>
    </div>
  );
}

export default withRouter(VerificationsProgress);
