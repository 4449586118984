import React, { useState, useContext, useEffect } from "react";
import "./complimentmodal.scss";
import { Button, Form, Modal, Image } from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { api, socket } from "../../../api";
import giftBackground from "../../../assets/Images/compliment/complimentbackground.png";
import giftIcon from "../../../assets/Images/compliment/gifticon.png";
import diamondIcon from "../../../assets/Icons/diamond-shape.svg";
import diamondIconWhite from "../../../assets/Icons/whiteDiamond.png";
import { submitCompliment } from "../../../helpers/swipeActions";

function ComplimentModal(props) {
  const context = useContext(UserContext);
  const history = useHistory();
  const [sendDisabled, setSendDisabled] = useState(true);
  const [show, setShow] = useState(props.show);
  const [message, setMessage] = useState("");
  const [selectedItem, setSelectedItem] = useState(0);
  const [success, setSuccess] = useState(false);
  const [refillRequired, setRefillRequired] = useState(false);

  const modalToggle = (e) => {
    if (success) {
      if (props.success !== undefined) {
        props.success();
      }
    }
    props.modalToggle();
  };

  const messageChangeHandler = (e) => {
    setMessage(e.target.value);

    if (e.target.value.length > 2) {
      setSendDisabled(false);
    } else {
      setSendDisabled(true);
    }
  };

  const itemToggle = (props) => (e) => {
    setSelectedItem(props);
  };

  const closeHandler = (e) => {
    props.modalToggle();
  };

  const sendHandler = async (e) => {
    if (sendDisabled) return;
    let diamondCount = 0;
    if (selectedItem === 0) {
      diamondCount = 1;
    } else if (selectedItem === 1) {
      diamondCount = 5;
    } else if (selectedItem === 2) {
      diamondCount = 10;
    } else if (selectedItem === 3) {
      diamondCount = 25;
    } else if (selectedItem === 4) {
      diamondCount = 50;
    } else if (selectedItem === 5) {
      diamondCount = 100;
    }

    if (context.user.inventory.diamonds >= diamondCount) {
      const payload = {
        receiver: props.profileUser._id,
        message,
        diamondCount,
      };
      const sendCompliment = await submitCompliment(payload).catch((err) => {
        console.log(err);
      });
      if (sendCompliment === true) {
        const data = {
          sender: context.user.username,
          receiver: props.profileUser.username,
          message,
          compliment: {
            status: true,
            message,
            date: Date.now(),
            isRead: false,
            diamonds: diamondCount,
            sender: context.user._id,
            receiver: props.profileUser._id,
          },
        };

        socket.sendMessage(data);
        let tempInventory = context.user.inventory;
        let newDiamondCount = context.user.inventory.diamonds - diamondCount;
        tempInventory.diamonds = newDiamondCount;
        context.updateUserInfo("inventory", tempInventory);
        context.updateMessageData(props.profileUser._id, message, "send", true);

        setSuccess(true);
      } else {
        setRefillRequired(true);
      }
    } else {
      setRefillRequired(true);
    }
  };

  const refillHandler = (e) => {
    history.push("/app/wallet/purchase");
  };

  return (
    <Modal show={show} onHide={modalToggle}>
      {refillRequired ? (
        <div>
          <Modal.Body className="compliment-modal-body">
            <div className="compliment-refill-container">
              <Image
                src={diamondIcon}
                className="compliment-modal-icon"
                fluid
                style={{ width: "10%" }}
              />
              <h1 className="compliment-refill-title">Refill Required</h1>
              <p className="compliment-refill-subtitle">
                You need to refill Diamonds to send this compliment.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer className="modal-v2-footer">
            <Button
              className="compliment-modal-send-button"
              onClick={refillHandler}
            >
              Refill
            </Button>

            <Button className="modal-close-button" onClick={closeHandler}>
              Close
            </Button>
          </Modal.Footer>
        </div>
      ) : (
        <div>
          <Modal.Body className="compliment-modal-body">
            {success && (
              <div className="compliment-modal-success-wrapper">
                <i className="far fa-gift compliment-modal-gift-icon" />
                <h4 className="compliment-modal-success-title">Thank you</h4>
                <p className="compliment-modal-success-subtitle">
                  Your compliment was sent successfully
                </p>
              </div>
            )}
            {!success && (
              <h4 className="compliment-modal-body-title">Compliment</h4>
            )}
            {!success && (
              <Form className="compliment-modal-form">
                <Form.Group className="compliment-modal-item-form-group">
                  <div className="compliment-modal-item-form-group-innerwrap">
                    <div
                      className={
                        selectedItem === 0
                          ? "compliment-modal-item-wrapper-active"
                          : "compliment-modal-item-wrapper"
                      }
                    >
                      <div
                        className="compliment-modal-item-content"
                        onClick={itemToggle(0)}
                      >
                        <Image
                          src={giftBackground}
                          className="compliment-modal-image"
                          fluid
                        />
                        <Image
                          src={giftIcon}
                          className="compliment-modal-icon"
                          fluid
                        />
                      </div>
                      <div
                        className={
                          selectedItem === 0
                            ? "compliment-modal-item-footer-active"
                            : "compliment-modal-item-footer"
                        }
                        onClick={itemToggle(0)}
                      >
                        <div className="compliment-modal-item-footer-content">
                          <Image
                            src={
                              selectedItem === 0
                                ? diamondIconWhite
                                : diamondIcon
                            }
                            className="compliment-diamond-icon"
                            fluid
                          />
                          1
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        selectedItem === 1
                          ? "compliment-modal-item-wrapper-active"
                          : "compliment-modal-item-wrapper"
                      }
                    >
                      <div
                        className="compliment-modal-item-content"
                        onClick={itemToggle(1)}
                      >
                        <Image
                          src={giftBackground}
                          className="compliment-modal-image"
                          fluid
                        />
                        <Image
                          src={giftIcon}
                          className="compliment-modal-icon"
                          fluid
                        />
                      </div>
                      <div
                        className={
                          selectedItem === 1
                            ? "compliment-modal-item-footer-active"
                            : "compliment-modal-item-footer"
                        }
                        onClick={itemToggle(1)}
                      >
                        <div className="compliment-modal-item-footer-content">
                          <Image
                            src={
                              selectedItem === 1
                                ? diamondIconWhite
                                : diamondIcon
                            }
                            className="compliment-diamond-icon"
                            fluid
                          />
                          5
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        selectedItem === 2
                          ? "compliment-modal-item-wrapper-active"
                          : "compliment-modal-item-wrapper"
                      }
                    >
                      <div
                        className="compliment-modal-item-content"
                        onClick={itemToggle(2)}
                      >
                        <Image
                          src={giftBackground}
                          className="compliment-modal-image"
                          fluid
                        />
                        <Image
                          src={giftIcon}
                          className="compliment-modal-icon"
                          fluid
                        />
                      </div>
                      <div
                        className={
                          selectedItem === 2
                            ? "compliment-modal-item-footer-active"
                            : "compliment-modal-item-footer"
                        }
                        onClick={itemToggle(2)}
                      >
                        <div className="compliment-modal-item-footer-content">
                          <Image
                            src={
                              selectedItem === 2
                                ? diamondIconWhite
                                : diamondIcon
                            }
                            className="compliment-diamond-icon"
                            fluid
                          />
                          10
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        selectedItem === 3
                          ? "compliment-modal-item-wrapper-active"
                          : "compliment-modal-item-wrapper"
                      }
                    >
                      <div
                        className="compliment-modal-item-content"
                        onClick={itemToggle(3)}
                      >
                        <Image
                          src={giftBackground}
                          className="compliment-modal-image"
                          fluid
                        />
                        <Image
                          src={giftIcon}
                          className="compliment-modal-icon"
                          fluid
                        />
                      </div>
                      <div
                        className={
                          selectedItem === 3
                            ? "compliment-modal-item-footer-active"
                            : "compliment-modal-item-footer"
                        }
                        onClick={itemToggle(3)}
                      >
                        <div className="compliment-modal-item-footer-content">
                          <Image
                            src={
                              selectedItem === 3
                                ? diamondIconWhite
                                : diamondIcon
                            }
                            className="compliment-diamond-icon"
                            fluid
                          />
                          25
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        selectedItem === 4
                          ? "compliment-modal-item-wrapper-active"
                          : "compliment-modal-item-wrapper"
                      }
                    >
                      <div
                        className="compliment-modal-item-content"
                        onClick={itemToggle(4)}
                      >
                        <Image
                          src={giftBackground}
                          className="compliment-modal-image"
                          fluid
                        />
                        <Image
                          src={giftIcon}
                          className="compliment-modal-icon"
                          fluid
                        />
                      </div>
                      <div
                        className={
                          selectedItem === 4
                            ? "compliment-modal-item-footer-active"
                            : "compliment-modal-item-footer"
                        }
                        onClick={itemToggle(4)}
                      >
                        <div className="compliment-modal-item-footer-content">
                          <Image
                            src={
                              selectedItem === 4
                                ? diamondIconWhite
                                : diamondIcon
                            }
                            className="compliment-diamond-icon"
                            fluid
                          />
                          50
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        selectedItem === 5
                          ? "compliment-modal-item-wrapper-active"
                          : "compliment-modal-item-wrapper"
                      }
                    >
                      <div
                        className="compliment-modal-item-content"
                        onClick={itemToggle(5)}
                      >
                        <Image
                          src={giftBackground}
                          className="compliment-modal-image"
                          fluid
                        />
                        <Image
                          src={giftIcon}
                          className="compliment-modal-icon"
                          fluid
                        />
                      </div>
                      <div
                        className={
                          selectedItem === 5
                            ? "compliment-modal-item-footer-active"
                            : "compliment-modal-item-footer"
                        }
                        onClick={itemToggle(5)}
                      >
                        <div className="compliment-modal-item-footer-content">
                          <Image
                            src={
                              selectedItem === 5
                                ? diamondIconWhite
                                : diamondIcon
                            }
                            className="compliment-diamond-icon"
                            fluid
                          />
                          100
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="compliment-modal-send-message-form-group">
                  <Form.Control
                    as="textarea"
                    rows="4"
                    value={message}
                    onChange={messageChangeHandler}
                    placeholder="Write greeting..."
                    className="compliment-modal-text-area"
                  />
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer className="modal-v2-footer">
            {!success && (
              <div
                className="compliment-modal-send-button"
                disabled={sendDisabled}
                onClick={sendHandler}
              >
                Send
              </div>
            )}

            <div className="modal-close-button" onClick={closeHandler}>
              Close
            </div>
          </Modal.Footer>
        </div>
      )}
    </Modal>
  );
}

export default withRouter(ComplimentModal);
