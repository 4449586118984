import React, { Component } from "react";
import {
  Container,
  Image,
  Card,
  Button,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import "./homegrid.scss";
import axios from "axios";
import { UserCardHome } from "../../card/UserCardHome";
import { Link } from "react-router-dom";
import { api } from "../../../api";

export class HomeGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      limit: 12,
      gender: "",
    };
  }

  fetchUsers = async (e) => {
    var gender = this.state.gender;
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + "/api/user/homegrid";
    const data = {
      gender: gender,
    };

    return api
      .fetchHomeGrid(data)
      .then((res) => {
        const receivedUsers = res.data.data.users;
        this.setState({
          users: receivedUsers,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  genderChangeHandler = (props) => (e) => {
    if (props === "female") {
      this.setState({ gender: "female" }, () => {
        this.fetchUsers();
      });
    }
    if (props === "male") {
      this.setState({ gender: "male" }, () => {
        this.fetchUsers();
      });
    }

    if (props === "both") {
      this.setState({ gender: "both" }, () => {
        this.fetchUsers();
      });
    }
  };

  componentWillMount() {
    this.setState({ gender: this.props.gender }, () => {
      this.fetchUsers();
    });
  }
  render() {
    var dropdownTitle = null;

    if (this.state.gender === "male") {
      dropdownTitle = "Browse Male Members";
    } else if (this.state.gender === "female") {
      dropdownTitle = "Browse Female Members";
    } else {
      dropdownTitle = "Browse All Members";
    }

    return (
      <Container className="homegrid-container">
        <div className="hg-title-wrap">
          <h2 className="homegrid-title">
            Over <span className="one-million-hg">1 Million</span> Active Users
          </h2>
          <h3 className="homegrid-subtitle">
            Find your ideal arrangement today.
          </h3>
        </div>

        <div className="homegrid-genderpick-wrapper">
          <DropdownButton title={dropdownTitle} variant="outline-secondary">
            {this.state.gender === "male" ? null : (
              <div>
                <Dropdown.Item onSelect={this.genderChangeHandler("male")}>
                  Browse Male Members
                </Dropdown.Item>
                <Dropdown.Divider />
              </div>
            )}

            {this.state.gender === "female" ? null : (
              <div>
                <Dropdown.Item onSelect={this.genderChangeHandler("female")}>
                  Browse Female Members
                </Dropdown.Item>
              </div>
            )}

            {this.state.gender === "male" || this.state.gender === "female" ? (
              <div>
                <Dropdown.Divider />

                <Dropdown.Item onSelect={this.genderChangeHandler("both")}>
                  Browse All Members
                </Dropdown.Item>
              </div>
            ) : null}
          </DropdownButton>
        </div>

        {this.state.users.map((user, index) => {
          return (
            <UserCardHome
              key={index}
              user={user}
              username={user.username}
              address={user.address}
              imgurl={user.imgurl}
              approvedimgurl={user.approvedimgurl}
              videourl={user.videourl}
              profileurl={user.profileurl}
              lastlogin={user.lastlogin}
              premium={user.premium}
              verified={user.verified}
            />
          );
        })}
        <div className="homegrid-seemore-wrapper">
          <Link to="/signup" className="homegrid-seemore-link">
            <Button variant="secondary" className="homegrid-seemore-btn">
              See More
            </Button>
          </Link>
        </div>
      </Container>
    );
  }
}

export default HomeGrid;
