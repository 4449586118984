import React, { useContext, useState, useEffect } from "react";
import { SwipeNav } from "../navbar/SwipeNav";
import "./newsearch.scss";
import { UserContext } from "../../context/UserContext";
import SearchGridV2 from "./searchComponentsV2/searchGridV2/SearchGridV2";
import Spotlight from "./searchComponentsV2/spotlight/Spotlight";
import ClipLoader from "react-spinners/ClipLoader";
import ProfileV2 from "../profile/ProfileV2";
import SearchFilters from "./searchComponents/searchFilters/SearchFilters";
import SearchHeader from "../headers/SearchHeader";
import AdvancedSearchFilters from "./searchComponents/advancedSearchFilters/AdvancedSearchFilters";
import BottomScrollListener from "react-bottom-scroll-listener";
import BeatLoader from "react-spinners/BeatLoader";
import Filters from "../filters/Filters";

function NewSearch() {
  const context = useContext(UserContext);
  const [profileOpen, setProfileOpen] = useState(false);
  const [profileUser, setProfileUser] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [finishedScrolling, setFinishedScrolling] = useState(false);

  useEffect(() => {
    updateScrollHeight();
  }, []);

  useEffect(() => {
    setScrollLoading(false);
  }, [context.searches]);

  const updateScrollHeight = (e) => {
    let searchHeight = document.getElementById("scroll-ref-context");
    if (context.scrollPositions.search > 0) {
      if (searchHeight) {
        searchHeight.scrollTop = context.scrollPositions.search;
      }
    }
    setFinishedScrolling(true);
  };

  const toggleProfile = (e) => {
    if (profileOpen) {
      setProfileOpen(false);
      setProfileUser(null);
    } else {
      setProfileOpen(true);
      setProfileUser(e);
    }
  };

  const toggleFilters = (e) => {
    if (filtersOpen) {
      setFiltersOpen(false);
    } else {
      setFiltersOpen(true);
    }
  };

  const updateHandler = (e) => {
    context.sortbySearches();
  };

  const advancedHandler = (e) => {
    if (advancedFiltersOpen) {
      setAdvancedFiltersOpen(false);
    } else {
      setAdvancedFiltersOpen(true);
    }
  };

  const handleScroll = (e) => {
    if (!context.endSearches && !scrollLoading) {
      setScrollLoading(true);
      context.getMoreSearches();
    }
  };

  const updateScrollHandler = (e) => {
    var scrollTop = e.target.scrollTop;
    context.updateScrollPosition("search", scrollTop);
  };

  return (
    <div className="search-component-v2">
      {profileOpen && (
        <ProfileV2
          toggleProfile={toggleProfile}
          profileUser={profileUser}
          updateHandler={updateHandler}
        />
      )}
      <SearchHeader toggleFilters={toggleFilters} />
      {filtersOpen && (
        <Filters
          closeHandler={toggleFilters}
          updateHandler={updateHandler}
          advancedHandler={advancedHandler}
        />
      )}

      {context.searchesLoading ? (
        <div className="search-component-v2-loading-wrapper">
          <ClipLoader />
        </div>
      ) : (
        <BottomScrollListener onBottom={handleScroll} offset={4000}>
          {(scrollRef) => (
            <div
              className="search-component-v2-content-container"
              onScroll={updateScrollHandler}
              ref={scrollRef}
              id="scroll-ref-context"
            >
              <Spotlight
                toggleProfile={toggleProfile}
                finishedScrolling={finishedScrolling}
              />
              <SearchGridV2
                toggleProfile={toggleProfile}
                scrollLoading={scrollLoading}
                endSearches={context.endSearches}
                finishedScrolling={finishedScrolling}
              />
            </div>
          )}
        </BottomScrollListener>
      )}
    </div>
  );
}

export default NewSearch;
