import React, { useState, useEffect } from 'react';
import { Image, Button } from 'react-bootstrap';
import { withRouter, useHistory, Link } from 'react-router-dom';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import HomeNav from '../../../components/navbar/HomeNav/HomeNav';
import './homepage.scss';
import homepageHeroImage from '../../../assets/logos/home-hero-logo.png';
import mainLogo from '../../../assets/logos/logo-horizontal-dark-background.png';

import HomeFooter from '../../../components/footers/homeFooter/HomeFooter';
import ValidateToken from '../../../helpers/ValidateToken';
import './homepagev2.scss';
import logo from '../../../assets/logos/landingPageLogo.png';
import facebookIcon from '../../../assets/Icons/social/facebookContinue.png';
import googleIcon from '../../../assets/Icons/social/googleContinue.png';
import { api } from '../../../api';

function HomePage(props) {
  const history = useHistory();
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (ValidateToken() !== false) return props.history.push('/app/search');

    window.scrollTo(0, 0);
  }, []);

  const phoneToggle = (e) => {
    history.push('/phone');
  };

  const emailToggle = (e) => {
    history.push('/email');
  };

  const facebookHandler = async (e) => {
    if (clicked) {
      const { email, id } = e;
      if (email && id) {
        const data = {
          email,
        };

        await api
          .checkEmail(data)
          .then((res) => {
            if (res.status === 200) {
              const { valid } = res.data;
              if (valid === true) {
                history.push('/email', {
                  email,
                  password: id,
                  signupStep: 'gender',
                  socialLogin: true,
                  socialType: 'facebook',
                });
              } else {
                const data = {
                  email,
                  password: id,
                  oauth: true,
                  number: null,
                };

                return api
                  .submitLogin(data)
                  .then((res) => {
                    if (res.status === 200) {
                      localStorage.setItem('auth', res.data.token);
                      history.push('/app/search');
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const googleHandler = async (e) => {
    const { profileObj, googleId } = e;
    const { email } = profileObj;
    if (email && googleId) {
      const data = {
        email,
      };

      await api
        .checkEmail(data)
        .then((res) => {
          if (res.status === 200) {
            const { valid } = res.data;
            if (valid === true) {
              history.push('/email', {
                email,
                password: googleId,
                signupStep: 'gender',
                socialLogin: true,
                socialType: 'google',
              });
            } else {
              const data = {
                email,
                password: googleId,
                oauth: true,
                number: null,
              };

              return api
                .submitLogin(data)
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem('auth', res.data.token);
                    history.push('/app/search');
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const clickedComponent = () => {
    setClicked(true);
  };

  const googleHandlerFailure = (e) => {};

  const vw = window.innerWidth;
  return (
    <div className="home-page-v2-container">
      <Image src={logo} className="home-page-v2-logo" />
      <p className="home-page-v2-logo-subtitle">Find an Arrangement</p>
      <div className="home-page-v2-continue-button-red" onClick={phoneToggle}>
        Continue with phone number
      </div>
      <div className="home-page-v2-continue-button-white" onClick={emailToggle}>
        Continue with email
      </div>
      <p className="home-page-v2-continue-with-secondary-text">
        Or continue with:
      </p>
      <div className="home-page-v2-continue-with-secondary-container">
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID}
          callback={facebookHandler}
          onClick={clickedComponent}
          fields="name,email,picture"
          render={(renderProps) => (
            <div
              className="home-page-v2-continue-secondary-button"
              onClick={renderProps.onClick}
            >
              <Image
                src={facebookIcon}
                className="home-page-v2-social-icon"
                fluid
              />
            </div>
          )}
        />
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
          render={(renderProps) => (
            <div
              className="home-page-v2-continue-secondary-button"
              onClick={renderProps.onClick}
            >
              <Image
                src={googleIcon}
                className="home-page-v2-social-icon"
                fluid
              />
            </div>
          )}
          onSuccess={googleHandler}
          onFailure={googleHandlerFailure}
          cookiePolicy="single_host_origin"
        />
        ,
      </div>

      <p className="home-page-v2-continue-with-secondary-subtitle-text">
        We will never post anything to Facebook.
      </p>
      <p className="home-page-v2-disclaimer-text">
        By clicking continue you agree to our{' '}
        <span className="home-page-v2-disclaimer-text-red">
          Terms & Conditions
        </span>{' '}
        and our{' '}
        <span className="home-page-v2-disclaimer-text-red">Privacy Policy</span>
      </p>
    </div>
  );
}

export default withRouter(HomePage);
