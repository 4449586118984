import React, { useState, useEffect, useContext } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Button, Image as IconImage, Alert } from 'react-bootstrap';

import { UserContext } from '../../../context/UserContext';
import { api } from '../../../api';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../utils/getCloudinaryInfo';
import Media from '../../../components/media/Media';

function PrivateTab(props) {
  return (
    <div>
      {props.requestApproved ? (
        <div className="profile-v2-photo-grid-content">
          {props.privateImages.map((image, index) => {
            return (
              <div className="profile-v2-photo-wrapper">
                <Media image={image} />
                {/* <Image
                  publicId={
                    image
                      ? getImgName(image)
                      : "larger-placeholder-user-image_vvdghq.png"
                  }
                  version={image ? getImgVersion(image) : "1590046357"}
                  resource_type={
                    image ? (checkGIF(image) ? "video" : "image") : null
                  }
                  effect="loop"
                  cloud_name="sugarbae"
                  className="profile-v2-photo"
                  onClick={() => props.showImage(index)}
                >
                  <Transformation
                    height="200"
                    video_sampling="20"
                    delay="250"
                    duration="10"
                    effect="loop"
                    quality="auto"
                    flags="lossy"
                  />
                </Image> */}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="profile-v2-photo-grid-content">
          {props.privateImages &&
            props.privateImages.map((image, index) => {
              if (index > 2) {
                return null;
              } else {
                return (
                  <div className="profile-v2-photo-wrapper">
                    <Media image={image} />

                    {/* <Image
                      publicId={
                        image
                          ? getImgName(image)
                          : 'larger-placeholder-user-image_vvdghq.png'
                      }
                      version={image ? getImgVersion(image) : '1590046357'}
                      resource_type={
                        image ? (checkGIF(image) ? 'video' : 'image') : null
                      }
                      effect="loop"
                      cloud_name="sugarbae"
                      className={
                        props.requestApproved
                          ? 'profile-v2-photo'
                          : 'profile-v2-photo-blur'
                      }
                    >
                      <Transformation
                        height="200"
                        video_sampling="20"
                        delay="250"
                        duration="10"
                        effect="loop"
                        quality="auto"
                        flags="lossy"
                      />
                    </Image> */}

                    <div className="profile-v2-photo-unlock">
                      <div className="profile-v2-unlock-icon-wrapper">
                        <i className="far fa-lock" />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          <div
            className="profile-v2-photo-wrapper-request"
            onClick={() => props.requestHandler()}
          >
            <div className="profile-v2-photo-request-innerwrap">
              <h2 className="profile-v2-request-title">
                Private ({props.privatePhotoCount})
              </h2>
              <div className="profile-v2-unlock-icon-wrapper-request">
                <i className="far fa-lock" />
              </div>
              {props.requested ? (
                <p className="profile-v2-request-subtitle">Access Requested</p>
              ) : (
                <p className="profile-v2-request-subtitle">Request to View</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PrivateTab;
