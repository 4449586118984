import React, { useState, useEffect, useContext } from "react";
import "../desktopprofiletabs.scss";
import Axios from "axios";
import { UserContext } from "../../../../context/UserContext";
import { Link, withRouter } from "react-router-dom";
import PlacesAutocomplete from "react-places-autocomplete";
import Slider from "@material-ui/core/Slider";
import { Button, Card, Form, Alert, Badge } from "react-bootstrap";
import ReactCodeInput from "react-verification-code-input";
import EmailValidator from "email-validator";
import PhoneInput from "react-phone-input-2";
import { api } from "../../../../api";

function VerificationsTab(props) {
  const context = useContext(UserContext);
  const [email, setEmail] = useState(props.user.email);
  const [emailInputDisabled, setEmailInputDisabled] = useState(true);
  const [emailConfirmationOpen, setEmailConfirmationOpen] = useState(false);
  const [emailVerified, setEmailVerified] = useState(
    props.user.verifications.email.status
  );
  const [emailValidationCode, setEmailValidationCode] = useState("");
  const [emailValidationLoading, setEmailValidationLoading] = useState(false);
  const [emailValidationCodeError, setEmailValidationCodeError] = useState(
    false
  );

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneInputDisabled, setPhoneInputDisabled] = useState(false);
  const [phoneConfirmationOpen, setPhoneConfirmationOpen] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(
    props.user.verifications.phone.status
  );
  const [phoneValidationCode, setPhoneValidationCode] = useState("");
  const [phoneValidationLoading, setPhoneValidationLoading] = useState(false);
  const [phoneValidationCodeError, setPhoneValidationCodeError] = useState(
    false
  );

  const [phoneConfirmButtonDisabled, setPhoneConfirmButtonDisabled] = useState(
    true
  );

  var userVerified = false;

  if (phoneVerified && emailVerified) {
    userVerified = true;
  }

  useEffect(() => {
    if (props.user.verifications.phone.number) {
      if (props.user.verifications.phone.number.length > 8) {
        setPhoneConfirmButtonDisabled(false);
        setPhoneNumber(props.user.verifications.phone.number);
      }
    }
  }, []);

  const emailConfirmationToggle = async (e) => {
    e.preventDefault();
    const data = {
      user: context.user._id,
      email: email,
    };

    api
      .generateEmailToken(data)
      .then((res) => {
        setEmailConfirmationOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const emailValidationCodeChange = async (e) => {
    setEmailValidationCode(e);
    setEmailValidationCodeError(false);
    if (e.length === 6) {
      setEmailValidationLoading(true);

      const data = {
        user: context.user._id,
        validationcode: e,
      };

      return api
        .validateEmailToken(data)
        .then((res) => {
          if (res.status === 200) {
            setEmailVerified(true);
            setEmailValidationLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setEmailValidationLoading(false);
          setEmailValidationCode("");
          setEmailValidationCodeError(true);
        });
    }
  };

  const phoneConfirmationToggle = async (e) => {
    e.preventDefault();

    const data = {
      user: context.user._id,
      phonenumber: phoneNumber,
    };

    return api
      .generateSmsToken(data)
      .then((res) => {
        setPhoneConfirmationOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const phoneValidationCodeChange = async (e) => {
    setPhoneValidationCode(e);
    setPhoneValidationCodeError(false);
    if (e.length === 6) {
      setPhoneValidationLoading(true);

      const data = {
        user: context.user._id,
        validationcode: e,
      };

      return api
        .validateSmsToken(data)
        .then((res) => {
          if (res.status === 200) {
            setPhoneVerified(true);
            setPhoneValidationLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setPhoneValidationLoading(false);
          setPhoneValidationCode("");
          setPhoneValidationCodeError(true);
        });
    }
  };

  const phoneChangeHandler = (e) => {
    setPhoneNumber(e);
    if (e.length > 8) {
    }
  };

  return (
    <div className="desktop-profile-tab-component">
      <div className="desktop-profile-tab-header">
        <h1 className="desktop-profile-tab-header-title">Verifications</h1>
      </div>
      <div className="desktop-profile-tab-content-container">
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap-verifications">
            <h3 className="desktop-profile-tab-section-title-verifications">
              Step 1:<br></br> Verify Your Email Address
            </h3>
            {emailVerified && <Badge variant="success">Verified</Badge>}
          </div>
          <div className="desktop-profile-tab-section-form-wrapper-verifications">
            {emailVerified ? (
              <Alert variant="success">
                <i className="far fa-check-circle"></i> Email Verified.
              </Alert>
            ) : (
              <Form className="desktop-profile-form">
                {emailConfirmationOpen ? (
                  <Form.Group className="desktop-profile-form-group">
                    {emailValidationCodeError && (
                      <Alert variant="danger">
                        Error verifying code. Please try again.
                      </Alert>
                    )}
                    <Form.Label>
                      Enter the 6 digit verification code we emailed you. Check
                      Your Spam & Promotions Folder If You Do Not Receive the
                      Email!
                    </Form.Label>
                    <ReactCodeInput
                      onChange={emailValidationCodeChange}
                      loading={emailValidationLoading}
                      disabled={emailValidationLoading}
                      fieldWidth={"16.6%"}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className="desktop-profile-form-group">
                    <Form.Control
                      type="email"
                      value={email}
                      disabled={emailInputDisabled}
                    />
                    <div className="desktop-verify-button-wrapper">
                      <Button
                        type="submit"
                        className="desktop-verify-advance-button"
                        onClick={emailConfirmationToggle}
                      >
                        Confirm Email
                      </Button>
                    </div>
                  </Form.Group>
                )}
              </Form>
            )}
          </div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap-verifications">
            <h3 className="desktop-profile-tab-section-title-verifications">
              Step 2:<br></br>Verify Your Cell Phone Number
            </h3>
            {phoneVerified && <Badge variant="success">Verified</Badge>}
          </div>
          <div className="desktop-profile-tab-section-form-wrapper-verifications">
            {phoneVerified ? (
              <Alert variant="success">
                <i className="far fa-check-circle"></i> Phone Verified.
              </Alert>
            ) : (
              <Form className="desktop-profile-form">
                {phoneConfirmationOpen ? (
                  <Form.Group className="desktop-profile-form-group">
                    {phoneValidationCodeError && (
                      <Alert variant="danger">
                        Error verifying code. Please try again.
                      </Alert>
                    )}
                    <Form.Label>
                      Enter the 6 digit verification code we texted you.
                    </Form.Label>
                    <ReactCodeInput
                      onChange={phoneValidationCodeChange}
                      loading={phoneValidationLoading}
                      disabled={phoneValidationLoading}
                      fieldWidth={"16.6%"}
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className="desktop-profile-form-group">
                    <PhoneInput
                      country={"us"}
                      value={phoneNumber}
                      onChange={phoneChangeHandler}
                      preferredCountries={["us", "ca", "gb"]}
                      disabled={true}
                    />
                    <div className="desktop-verify-button-wrapper">
                      <Button
                        type="submit"
                        className="desktop-verify-advance-button"
                        onClick={phoneConfirmationToggle}
                        disabled={phoneConfirmButtonDisabled}
                      >
                        Confirm Cell Number
                      </Button>
                    </div>
                  </Form.Group>
                )}
              </Form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(VerificationsTab);
