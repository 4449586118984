import React, { Component } from "react";

import SwipeNav from "../navbar/SwipeNav";
import SearchGrid from "./searchComponents/searchGrid/SearchGrid";
import SearchFiltersBar from "./searchComponents/searchFiltersBar/SearchFiltersBar";
import SearchFilters from "./searchComponents/searchFilters/SearchFilters";
import AdvancedSearchFilters from "./searchComponents/advancedSearchFilters/AdvancedSearchFilters";
import { UserContext } from "../../context/UserContext";

import "./search.scss";

export class Search extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      filtersOpen: false,
      advancedFiltersOpen: false,
      update: false,
    };
  }
  openHandler = (e) => {
    this.setState({ filtersOpen: true });
  };
  closeHandler = (e) => {
    this.context.sortbySearches();
    this.setState({ filtersOpen: false, advancedFiltersOpen: false });
  };
  advancedHandler = (e) => {
    this.setState({ filtersOpen: false, advancedFiltersOpen: true });
  };

  updateHandler = (e) => {
    this.setState({ update: true }, () => {
      this.setState({ update: false });
    });
  };
  render() {
    return (
      <div className="search-component">
        <SwipeNav />
        {this.state.update ? null : <SearchGrid />}
        {this.state.filtersOpen ? (
          <SearchFilters
            closeHandler={this.closeHandler}
            advancedHandler={this.advancedHandler}
            updateHandler={this.updateHandler}
          />
        ) : null}
        {this.state.advancedFiltersOpen ? (
          <AdvancedSearchFilters
            closeHandler={this.closeHandler}
            advancedHandler={this.advancedHandler}
            updateHandler={this.updateHandler}
          />
        ) : null}

        <SearchFiltersBar openHandler={this.openHandler} />
      </div>
    );
  }
}

export default Search;
