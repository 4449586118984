import React, { Component } from "react";
import "./swipematch.scss";
import SwipeNav from "../navbar/SwipeNav";
import MatchesTabs from "./matchesComponents/matchesTabs/MatchesTabs";

export class SwipeMatch extends Component {
  render() {
    return (
      <div className="swipematch-component">
        <SwipeNav />
        <MatchesTabs
          url={this.props.url}
          handleBoost={() => {
            this.props.handleBoost();
          }}
        />
      </div>
    );
  }
}

export default SwipeMatch;
