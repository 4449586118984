import React, { Component } from "react";
import Complete from "../../../components/welcome/complete/Complete";

export class CompletePage extends Component {
  render() {
    let vh = window.innerHeight;
    var height = vh + "px";
    return (
      <div
        className="app-component"
        style={{ height: height, maxHeight: height }}
      >
        {" "}
        <Complete />
      </div>
    );
  }
}

export default CompletePage;
