import React, { Component } from "react";
import Photos from "../../../components/welcome/photos/Photos";

export class PhotosPage extends Component {
  render() {
    let vh = window.innerHeight;
    var height = vh + "px";
    return (
      <div
        className="app-component"
        style={{ height: height, maxHeight: height }}
      >
        {" "}
        <Photos />
      </div>
    );
  }
}

export default PhotosPage;
