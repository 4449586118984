import React, { useState, useContext, useEffect, useRef } from "react";
import "./verification.scss";
import "./idverify.scss";
import { Button, Form, InputGroup, Image } from "react-bootstrap";
import { api } from "../../api";
import { UserContext } from "../../context/UserContext";
import Webcam from "react-webcam";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { set } from "react-ga";

var moment = require("moment");

function IdVerify(props) {
  const context = useContext(UserContext);
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState(context.user.identity.firstName);
  const [lastName, setLastName] = useState(context.user.identity.lastName);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const [blob, setBlob] = useState({});
  const [videoSubmitLoading, setVideoSubmitLoading] = useState(false);
  const [videoSubmitSuccess, setVideoSubmitSuccess] = useState(false);
  const [photoPreviewOpen, setPhotoPreviewOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [mediaErrorVideo, setMediaErrorVideo] = useState(false);
  var timer = null;
  const hiddenFileInput = React.useRef(null);
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();

    startTimer();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleStopCaptureClick = React.useCallback(() => {
    clearTimeout(timer);
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const startTimer = (e) => {
    timer = setTimeout(() => {
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }, 8000);
  };

  useEffect(() => {
    if (recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      setBlob(blob);
      setPreviewUrl(url);
      setPreviewOpen(true);
    }
  }, [recordedChunks]);

  const videoMediaError = (e) => {
    setMediaErrorVideo(true);
  };

  const uploadClick = (event) => {
    hiddenFileInput.current.click();
  };
  const uploadChange = async (event) => {
    setUploadingFile(true);

    // ! Check File Size
    const fileUploaded = event.target.files[0];
    var fd = new FormData();
    var url = "https://api.cloudinary.com/v1_1/sugarbae/video/upload";

    fd.append("file", fileUploaded);
    fd.append("api_key", "323661161668594");
    fd.append("timestamp", (Date.now() / 1000) | 0);
    fd.append("upload_preset", "bxqmnqa8");

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        //   setUploadPercentage(percent);
        //   setIndex(index);
      },
      withCredentials: false,
    };

    await Axios.post(url, fd, options, {
      headers: { "X-Requested-With": "XMLHttpRequest" },
    })
      .then((res) => {
        var imgurl = res.data.secure_url;

        if (imgurl) {
          setPreviewUrl(imgurl);
          setPreviewOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setUploadingFile(false);

    console.log(fileUploaded.size);
  };

  const handleVideoSubmit = async (e) => {
    if (mediaErrorVideo) {
      setVideoSubmitLoading(true);
      verificationSave(previewUrl);
    } else {
      setVideoSubmitLoading(true);
      var fd = new FormData();
      var url = "https://api.cloudinary.com/v1_1/sugarbae/video/upload";

      fd.append("file", blob);

      fd.append("api_key", "323661161668594");
      fd.append("timestamp", (Date.now() / 1000) | 0);
      fd.append("upload_preset", "bxqmnqa8");

      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          //   setUploadPercentage(percent);
          //   setIndex(index);
        },
        withCredentials: false,
      };

      await Axios.post(url, fd, options, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      })
        .then((res) => {
          var imgurl = res.data.secure_url;

          if (imgurl) {
            verificationSave(imgurl);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const submitPhotoHandler = (e) => {};

  const verificationSave = async (e) => {
    const data = {
      video: e,
    };

    await api
      .saveVerificationVideo(data)
      .then((res) => {
        if (res.status === 200) {
          setVideoSubmitLoading(false);
          setVideoSubmitSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const firstNameChangeHandler = (e) => {
    setFirstName(e.target.value);
  };
  const lastNameChangeHandler = (e) => {
    setLastName(e.target.value);
  };

  const advanceStepHandler = (e) => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      setStep(4);
    }
  };

  const backHandler = (e) => {
    if (step === 1) {
      props.toggleClose();
    } else if (step === 2) {
      if (videoSubmitSuccess) {
        setVideoSubmitSuccess(false);
      } else {
        setStep(1);
      }
    } else if (step === 3) {
      setStep(2);
    } else if (step === 4) {
      setStep(3);
    }
  };

  const recordAgainHandler = (e) => {
    setRecordedChunks([]);
    setPreviewUrl("");
    setCapturing(false);
    setPreviewOpen(false);
  };

  const photoAgainHandler = (e) => {
    setImagePreview("");
    setPhotoPreviewOpen(false);
  };

  const handleCapture = (e) => {
    const imgsrc = webcamRef.current.getScreenshot();
    setImagePreview(imgsrc);
    setPhotoPreviewOpen(true);
  };

  let dateText = moment().format("MMMM Do YYYY");

  if (step === 1) {
    return (
      <div>
        <h1 className="verification-content-title">
          Step 1: Confirm Your Full Name
        </h1>
        <Form className="id-verify-form">
          <Form.Group className="id-verify-form-group">
            <Form.Control
              type="text"
              value={firstName}
              onChange={firstNameChangeHandler}
              placeholder={"First Name"}
              className="id-verify-input"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              value={lastName}
              onChange={lastNameChangeHandler}
              placeholder={"Last Name"}
              className="id-verify-input"
            />
          </Form.Group>
        </Form>
        <div className="verification-footer">
          <Button
            className="verification-footer-button-top"
            onClick={backHandler}
            variant="link"
          >
            Back
          </Button>

          <Button
            className="verification-footer-button"
            onClick={advanceStepHandler}
            variant="danger"
          >
            Next
          </Button>
        </div>
      </div>
    );
  } else if (step === 2) {
    if (videoSubmitSuccess) {
      return (
        <div>
          <h1 className="verification-content-title">
            Successfully submitted video
          </h1>

          <p className="verification-subtitle">
            Your verification video was successfully submitted and is under
            review. Click Next to finish your verification.
          </p>

          <div className="verification-footer-border">
            <Button
              className="verification-footer-button-top"
              onClick={backHandler}
              variant="link"
            >
              Back
            </Button>

            <Button
              className="verification-footer-button"
              onClick={advanceStepHandler}
              variant="danger"
            >
              Next
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1 className="verification-content-title">
            Step 2: Take A Quick Video Selfie
          </h1>
          {previewOpen ? (
            <div className="verification-video-wrapper">
              <video autoplay controls className="verification-preview-video">
                <source src={previewUrl} type="video/webm" />
              </video>
            </div>
          ) : (
            <>
              {mediaErrorVideo ? null : (
                <div className="verification-video-wrapper">
                  <Webcam
                    videoConstraints={{ facingMode: "user" }}
                    ref={webcamRef}
                    audio={true}
                    onUserMediaError={videoMediaError}
                  />
                  {capturing && (
                    <div className="verification-recording-icon-wrapper">
                      <i class="far fa-dot-circle"></i>
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          <div className="verification-video-instructions-wrapper">
            <h1 className="verification-content-title">Instructions</h1>

            <ul className="verification-video-instructions">
              <li className="verification-video-instructions-item">
                Record shot selfie video
              </li>
              <li className="verification-video-instructions-item">
                Clearly show your face with good lighting
              </li>
              <li className="verification-video-instructions-item">
                Say your full name and today's date
              </li>
            </ul>
            <p className="verification-video-script-text">
              "My name is {firstName} {lastName}, today is {dateText}."
            </p>
          </div>

          <div className="verification-footer-border">
            {!capturing && !videoSubmitLoading && !uploadingFile && (
              <div>
                {previewOpen ? (
                  <Button
                    className="verification-footer-button-top"
                    variant="link"
                    onClick={recordAgainHandler}
                  >
                    Record Again
                  </Button>
                ) : (
                  <Button
                    className="verification-footer-button-top"
                    variant="link"
                    onClick={backHandler}
                  >
                    Back
                  </Button>
                )}
              </div>
            )}

            {capturing ? (
              <Button
                className="verification-footer-button"
                variant="danger"
                onClick={handleStopCaptureClick}
              >
                Stop Recording
              </Button>
            ) : (
              <div>
                {previewOpen ? (
                  <Button
                    className="verification-footer-button verification-footer-cliploader"
                    variant="danger"
                    onClick={handleVideoSubmit}
                    disabled={videoSubmitLoading}
                  >
                    {videoSubmitLoading && (
                      <ClipLoader size={"15px"} color={"#fff"} />
                    )}
                    {videoSubmitLoading ? "Submitting..." : "Submit Video"}
                  </Button>
                ) : (
                  <>
                    {mediaErrorVideo ? (
                      <>
                        <Button
                          className="verification-footer-button verification-footer-cliploader"
                          variant="danger"
                          onClick={uploadClick}
                          disabled={uploadingFile}
                        >
                          {uploadingFile && (
                            <ClipLoader size={"15px"} color={"#fff"} />
                          )}
                          {uploadingFile ? "Uploading..." : "Upload"}
                        </Button>
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={uploadChange}
                          style={{ display: "none" }}
                          accept="video/*"
                        />
                      </>
                    ) : (
                      <Button
                        className="verification-footer-button"
                        variant="danger"
                        onClick={handleStartCaptureClick}
                      >
                        Record
                      </Button>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      );
    }
  } else if (step === 3) {
    return (
      <div>
        <h1 className="verification-content-title">
          Step 3: Take a Picture of Your ID Card
        </h1>
        {photoPreviewOpen ? (
          <div className="verification-video-wrapper">
            <Image
              src={imagePreview}
              className="verification-image-preview"
              fluid
            />
          </div>
        ) : (
          <div className="verification-video-wrapper">
            <Webcam
              videoConstraints={{ facingMode: "user" }}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              audio={false}
            />
          </div>
        )}
        <div className="verification-video-instructions-wrapper">
          <h1 className="verification-content-title">Instructions</h1>
          <p className="verification-subtitle">
            Hold your ID card up to the camera so the information is visable and
            take a picture.
          </p>
          <ul className="verification-video-instructions">
            <li className="verification-video-instructions-item">
              Use a Passport, Driving License or National ID Card
            </li>
            <li className="verification-video-instructions-item">
              Hold ID Card Close to the Camera
            </li>
            <li className="verification-video-instructions-item">
              Make sure card information is readable
            </li>
          </ul>
        </div>

        {photoPreviewOpen ? (
          <div className="verification-footer-border">
            <Button
              className="verification-footer-button-top"
              variant="link"
              onClick={photoAgainHandler}
            >
              Take again
            </Button>

            <Button
              className="verification-footer-button"
              variant="danger"
              onClick={submitPhotoHandler}
            >
              Submit Photo
            </Button>
          </div>
        ) : (
          <div className="verification-footer-border">
            <Button
              className="verification-footer-button-top"
              variant="link"
              onClick={backHandler}
            >
              Back
            </Button>

            <Button
              className="verification-footer-button"
              variant="danger"
              onClick={handleCapture}
            >
              Take Picture
            </Button>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <div className="verification-footer">
          <Button className="verification-footer-button-top" variant="link">
            Back
          </Button>

          <Button className="verification-footer-button" variant="danger">
            Next
          </Button>
        </div>
      </div>
    );
  }
}

export default IdVerify;
