import React, { Component } from "react";
import { Card, Badge } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import "../userinfocards.scss";

export class UserInfo extends Component {
  static contextType = UserContext;

  render() {
    var card = this.props.card;
    var address = card.address;
    var age = card.age;
    var height = card.height;
    var ethnicity = card.ethnicity;
    var bodytype = card.bodytype;
    var gender = card.gender;

    var showHeight = false;
    var showEthnicity = false;
    var showBodytype = false;

    if (height !== null && height !== undefined) {
      showHeight = true;
    }
    if (ethnicity !== null && ethnicity !== undefined) {
      showEthnicity = true;
    }
    if (bodytype !== null && bodytype !== undefined) {
      showBodytype = true;
    }

    var verified = false;

    if (card.verifications.email.status && card.verifications.phone.status) {
      verified = true;
    }

    return (
      <Card className="user-info-card">
        <Card.Header className="user-info-card-header">
          <Card.Title className="user-info-card-header-title">
            <i className="far fa-address-card user-info-header-icon"></i>
            Basic Info
          </Card.Title>
        </Card.Header>
        <Card.Body className="user-info-card-body">
          <div className="user-info-section">
            <h4 className="user-info-section-title">Location: </h4>
            <p className="user-info-section-text">{address}</p>
          </div>
          <div
            className={
              !showHeight && !showBodytype && !showEthnicity
                ? "user-info-section-bottom"
                : "user-info-section"
            }
          >
            <h4 className="user-info-section-title">Age: </h4>
            <p className="user-info-section-text">{age}</p>
          </div>
          {showHeight && (
            <div
              className={
                !showBodytype && !showEthnicity
                  ? "user-info-section-bottom"
                  : "user-info-section"
              }
            >
              <h4 className="user-info-section-title">Height: </h4>
              <p className="user-info-section-text">{height}</p>
            </div>
          )}
          {showBodytype && (
            <div
              className={
                !showEthnicity
                  ? "user-info-section-bottom"
                  : "user-info-section"
              }
            >
              <h4 className="user-info-section-title">Bodytype: </h4>
              <p className="user-info-section-text">{bodytype}</p>
            </div>
          )}
          {showEthnicity && (
            <div className="user-info-section-bottom">
              <h4 className="user-info-section-title">Ethnicity: </h4>
              <p className="user-info-section-text">{ethnicity}</p>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  }
}

export default UserInfo;
