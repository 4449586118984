import React, { Component } from 'react';
import './matchcard.scss';
import Axios from 'axios';
import { Card, Button, Modal, Alert } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import { UserContext } from '../../../../context/UserContext';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { api } from '../../../../api';
import Media from '../../../../components/media/Media';

export class MatchCard extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      unmatchOpen: false,
      reportOpen: false,
      reportSuccess: false,
    };
  }

  sendButtonHandler = (e) => {
    var chatUrl = '/app/messages/' + this.props.match.user._id;
    this.props.link(chatUrl);
  };

  menuToggle = (e) => {
    if (this.state.menuOpen) {
      this.setState({ menuOpen: false });
    } else {
      this.setState({ menuOpen: true });
    }
  };
  unmatchToggle = (e) => {
    if (this.state.unmatchOpen) {
      this.setState({ unmatchOpen: false, menuOpen: false });
    } else {
      this.setState({ unmatchOpen: true, menuOpen: false });
    }
  };
  reportToggle = (e) => {
    if (this.state.reportOpen) {
      this.setState({
        reportOpen: false,
        menuOpen: false,
        unmatchOpen: false,
        reportSuccess: false,
      });
    } else {
      this.setState({
        reportOpen: true,
        menuOpen: false,
        unmatchOpen: false,
        reportSuccess: false,
      });
    }
  };

  submitUnmatch = (props) => async (e) => {
    const data = {
      userid: this.context.user._id,
      cardid: this.props.match.user._id,
      reason: props,
    };

    return api
      .submitUnmatch(data)
      .then((res) => {
        this.unmatchToggle();
        window.location.reload();
      })
      .catch((err) => {});
  };
  submitReport = (props) => async (e) => {
    const data = {
      userid: this.context.user._id,
      cardid: this.props.match.user._id,
      reason: props,
    };

    return api
      .submitReport(data)
      .then((res) => {
        this.setState({ reportSuccess: true });
      })
      .catch((err) => {});
  };

  profileToggle = (e) => {
    var cardid = this.props.match.user._id;
    this.props.profileToggle(cardid);
  };

  render() {
    var imgurl = this.props.match.user.imgurl;

    if (imgurl) {
      if (this.props.match.user.imgurl.length > 0) {
        var image = imgurl[0];
        var imgurl = image;
        var imgurlb = image;
      } else {
        var imgurl = null;
        var imgurlb = null;
      }
    } else {
      var imgurl = null;
      var imgurlb = null;
    }

    return (
      <div className="match-card-component">
        <Modal
          show={this.state.menuOpen}
          onHide={this.menuToggle}
          centered
          className="match-card-modal"
        >
          <Modal.Body className="match-modal-body">
            <Button
              variant="outline-secondary"
              className="match-modal-footer-btn-main"
              onClick={this.reportToggle}
              size="lg"
            >
              Report {this.props.match.user.username}
            </Button>
            <Button
              variant="outline-secondary"
              className="match-modal-footer-btn-main"
              onClick={this.unmatchToggle}
              size="lg"
            >
              Unmatch
            </Button>
            <Button
              variant="outline-secondary"
              className="match-modal-footer-btn-main"
              onClick={this.sendButtonHandler}
              size="lg"
            >
              Open Chat
            </Button>
            <Button
              variant="outline-secondary"
              className="match-modal-footer-btn-main"
              onClick={this.profileToggle}
              size="lg"
            >
              Open Profile
            </Button>
          </Modal.Body>
          <Modal.Footer className="match-modal-footer">
            <Button
              variant="outline-secondary"
              className="match-modal-footer-btn"
              onClick={this.menuToggle}
              size="lg"
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.unmatchOpen}
          onHide={this.unmatchToggle}
          centered
          className="match-card-modal"
        >
          <Modal.Header className="match-card-modal-header">
            <Modal.Title className="match-card-modal-title">
              Are you sure?
            </Modal.Title>
            <p className="match-card-modal-subtitle">Tell us why</p>
          </Modal.Header>
          <Modal.Body className="match-modal-body">
            <Button
              variant="outline-secondary"
              className="match-modal-footer-btn-main"
              onClick={this.submitUnmatch('Inappropriate Behavior')}
              size="lg"
            >
              Inappropriate Behavior
            </Button>
            <Button
              variant="outline-secondary"
              className="match-modal-footer-btn-main"
              onClick={this.submitUnmatch('Feels like spam')}
              size="lg"
            >
              Feels like spam
            </Button>
            <Button
              variant="outline-secondary"
              className="match-modal-footer-btn-main"
              onClick={this.submitUnmatch('Other')}
              size="lg"
            >
              Other
            </Button>
            <Button
              variant="outline-secondary"
              className="match-modal-footer-btn-main"
              onClick={this.submitUnmatch('No reason')}
              size="lg"
            >
              No Reason
            </Button>
          </Modal.Body>
          <Modal.Footer className="match-modal-footer">
            <Button
              variant="outline-secondary"
              className="match-modal-footer-btn"
              onClick={this.unmatchToggle}
              size="lg"
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.reportOpen}
          onHide={this.reportToggle}
          centered
          className="match-card-modal"
        >
          <Modal.Header className="match-card-modal-header">
            <Modal.Title className="match-card-modal-title">
              Report {this.props.match.user.username}
            </Modal.Title>
            <p className="match-card-modal-subtitle">
              Reports are kept anonymous.
            </p>
          </Modal.Header>
          {this.state.reportSuccess ? (
            <Modal.Body className="match-modal-body">
              <Alert variant="success">
                Successfully reported user. We will review your report manually.
                Thank you for helping keep Sugarbae safe.
              </Alert>
            </Modal.Body>
          ) : (
            <Modal.Body className="match-modal-body">
              <Button
                variant="outline-secondary"
                className="match-modal-footer-btn-main"
                onClick={this.submitReport('Inappropriate Behavior')}
                size="lg"
              >
                Inappropriate Behavior
              </Button>
              <Button
                variant="outline-secondary"
                className="match-modal-footer-btn-main"
                onClick={this.submitReport('Feels Like Spam')}
                size="lg"
              >
                Feels like spam
              </Button>
              <Button
                variant="outline-secondary"
                className="match-modal-footer-btn-main"
                onClick={this.submitReport('Other')}
                size="lg"
              >
                Other
              </Button>
            </Modal.Body>
          )}

          <Modal.Footer className="match-modal-footer">
            <Button
              variant="outline-secondary"
              className="match-modal-footer-btn"
              onClick={this.reportToggle}
              size="lg"
            >
              {this.state.reportSuccess ? 'Continue' : 'Cancel'}
            </Button>
          </Modal.Footer>
        </Modal>
        <Card className="match-card">
          <Card.Header className="match-card-header">
            <div className="match-card-header-info-wrap">
              <Media image={imgurl} />
              {/* <Image
                publicId={
                  imgurl
                    ? getImgName(imgurl)
                    : 'placeholder-user-image-thumbnail_vankru.png'
                }
                version={imgurl ? getImgVersion(imgurl) : '1590045309'}
                resource_type={
                  imgurl ? (checkGIF(imgurl) ? 'video' : 'image') : null
                }
                cloud_name="sugarbae"
                className="match-card-header-image"
                onClick={this.profileToggle}
              >
                <Transformation
                  height="100"
                  width="100"
                  radius="max"
                  background="white"
                  video_sampling="20"
                  delay="250"
                  duration="10"
                  effect="loop"
                  quality="auto"
                  flags="lossy"
                />
              </Image> */}
              <div className="match-card-header-text-wrapper">
                <h1 className="match-card-username">
                  {this.props.match.user.username}
                </h1>
                <p className="match-card-header-subtitle">New Match!</p>
              </div>
            </div>
            <div className="match-card-header-menu-wrap">
              <Button className="match-card-menu-btn" onClick={this.menuToggle}>
                <i className="fas fa-ellipsis-h match-card-menu-icon"></i>
              </Button>
            </div>
          </Card.Header>
          <Card.Body className="match-card-body">
            <Button
              className="match-card-send-btn"
              onClick={this.sendButtonHandler}
            >
              <i className="fas fa-comment-alt-lines match-card-send-icon"></i>
            </Button>
            <div className="match-card-image-text">
              <p className="match-card-subtitle">It's a</p>
              <h1 className="match-card-title">Match!</h1>
            </div>

            <Media image={imgurlb} />
            {/* <Image
              publicId={
                imgurlb
                  ? getImgName(imgurlb)
                  : 'larger-placeholder-user-image_vvdghq.png'
              }
              version={imgurlb ? getImgVersion(imgurlb) : '1590046357'}
              resource_type={
                imgurlb ? (checkGIF(imgurlb) ? 'video' : 'image') : null
              }
              cloud_name="sugarbae"
              fluid
              className="match-card-image"
            >
              <Transformation
                height="540"
                width="540"
                video_sampling="20"
                delay="250"
                duration="10"
                effect="loop"
                quality="auto"
                flags="lossy"
              />
            </Image> */}
          </Card.Body>
          <Card.Footer className="match-card-footer">
            <div className="match-card-footer-innerwrap">
              <div className="match-card-footer-info-top">
                <i className="fal fa-map-marker-alt match-card-footer-icon"></i>
                <span className="match-card-footer-info-text">
                  {this.props.match.user.address}
                </span>
              </div>
              <div className="match-card-footer-info">
                <i className="fal fa-user-circle match-card-footer-icon"></i>
                <span className="match-card-footer-info-text">
                  {this.props.match.user.age} • {this.props.match.user.height} •{' '}
                  {this.props.match.user.ethnicity}
                </span>
              </div>
            </div>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

export default MatchCard;
