import React, { useState, useContext, useEffect } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import PhotoGridV2 from "../../photoGrid/PhotoGridV2";
import { UserContext } from "../../../context/UserContext";
import "../welcomev2.scss";
import { api } from "../../../api";
import { countBenefits } from "../../../helpers/CountBenefits";

const BenefitsV2 = (props) => {
  const history = useHistory();
  const context = useContext(UserContext);
  const [showWarning, setShowWarning] = useState(false);
  const [advanceDisabled, setAdvanceDisabled] = useState(true);
  const [benefits, setBenefits] = useState(context.user.benefits);
  const [refresh, setRefresh] = useState(false);

  const toggleBenefits = (value) => {
    const benefitsCopy = benefits;

    if (value === "ActiveLifestyle") {
      if (benefitsCopy.ActiveLifestyle) {
        benefitsCopy.ActiveLifestyle = false;
      } else {
        benefitsCopy.ActiveLifestyle = true;
      }
    }

    if (value === "EmotionalConnection") {
      if (benefitsCopy.EmotionalConnection) {
        benefitsCopy.EmotionalConnection = false;
      } else {
        benefitsCopy.EmotionalConnection = true;
      }
    }

    if (value === "FlexibleSchedule") {
      if (benefitsCopy.FlexibleSchedule) {
        benefitsCopy.FlexibleSchedule = false;
      } else {
        benefitsCopy.FlexibleSchedule = true;
      }
    }

    if (value === "Attentive") {
      if (benefitsCopy.Attentive) {
        benefitsCopy.Attentive = false;
      } else {
        benefitsCopy.Attentive = true;
      }
    }

    if (value === "LongTerm") {
      if (benefits.LongTerm) {
        benefitsCopy.LongTerm = false;
      } else {
        benefitsCopy.LongTerm = true;
      }
    }

    if (value === "AllEthnicities") {
      if (benefitsCopy.AllEthnicities) {
        benefitsCopy.AllEthnicities = false;
      } else {
        benefitsCopy.AllEthnicities = true;
      }
    }

    if (value === "Discretion") {
      if (benefitsCopy.Discretion) {
        benefitsCopy.Discretion = false;
      } else {
        benefitsCopy.Discretion = true;
      }
    }

    if (value === "FineDining") {
      if (benefitsCopy.FineDining) {
        benefitsCopy.FineDining = false;
      } else {
        benefitsCopy.FineDining = true;
      }
    }

    if (value === "FriendsWithBenefits") {
      if (benefitsCopy.FriendsWithBenefits) {
        benefitsCopy.FriendsWithBenefits = false;
      } else {
        benefitsCopy.FriendsWithBenefits = true;
      }
    }

    if (value === "Investor") {
      if (benefitsCopy.Investor) {
        benefitsCopy.Investor = false;
      } else {
        benefitsCopy.Investor = true;
      }
    }

    if (value === "LifeOfLeisure") {
      if (benefitsCopy.LifeOfLeisure) {
        benefitsCopy.LifeOfLeisure = false;
      } else {
        benefitsCopy.LifeOfLeisure = true;
      }
    }

    if (value === "LuxuryLifestyle") {
      if (benefitsCopy.LuxuryLifestyle) {
        benefitsCopy.LuxuryLifestyle = false;
      } else {
        benefitsCopy.LuxuryLifestyle = true;
      }
    }

    if (value === "MarriageMinded") {
      if (benefitsCopy.MarriageMinded) {
        benefitsCopy.MarriageMinded = false;
      } else {
        benefitsCopy.MarriageMinded = true;
      }
    }

    if (value === "Mentorship") {
      if (benefitsCopy.Mentorship) {
        benefitsCopy.Mentorship = false;
      } else {
        benefitsCopy.Mentorship = true;
      }
    }

    if (value === "Monogamous") {
      if (benefitsCopy.Monogamous) {
        benefitsCopy.Monogamous = false;
      } else {
        benefitsCopy.Monogamous = true;
      }
    }

    if (value === "NoStringsAttached") {
      if (benefitsCopy.NoStringsAttached) {
        benefitsCopy.NoStringsAttached = false;
      } else {
        benefitsCopy.NoStringsAttached = true;
      }
    }

    if (value === "NonMonogamous") {
      if (benefitsCopy.NonMonogamous) {
        benefitsCopy.NonMonogamous = false;
      } else {
        benefitsCopy.NonMonogamous = true;
      }
    }

    if (value === "OpenRelationship") {
      if (benefitsCopy.OpenRelationship) {
        benefitsCopy.OpenRelationship = false;
      } else {
        benefitsCopy.OpenRelationship = true;
      }
    }

    if (value === "PassportReady") {
      if (benefitsCopy.PassportReady) {
        benefitsCopy.PassportReady = false;
      } else {
        benefitsCopy.PassportReady = true;
      }
    }

    if (value === "Platonic") {
      if (benefitsCopy.Platonic) {
        benefitsCopy.Platonic = false;
      } else {
        benefitsCopy.Platonic = true;
      }
    }

    if (value === "Romance") {
      if (benefitsCopy.Romance) {
        benefitsCopy.Romance = false;
      } else {
        benefitsCopy.Romance = true;
      }
    }

    if (value === "ShowsAndEntertainment") {
      if (benefitsCopy.ShowsAndEntertainment) {
        benefitsCopy.ShowsAndEntertainment = false;
      } else {
        benefitsCopy.ShowsAndEntertainment = true;
      }
    }

    if (value === "TravelToYou") {
      if (benefitsCopy.TravelToYou) {
        benefitsCopy.TravelToYou = false;
      } else {
        benefitsCopy.TravelToYou = true;
      }
    }

    if (value === "TravelWithMe") {
      if (benefitsCopy.TravelWithMe) {
        benefitsCopy.TravelWithMe = false;
      } else {
        benefitsCopy.TravelWithMe = true;
      }
    }

    if (value === "Vacations") {
      if (benefitsCopy.Vacations) {
        benefitsCopy.Vacations = false;
      } else {
        benefitsCopy.Vacations = true;
      }
    }

    setBenefits(benefitsCopy);
    setRefresh(true);

    const data = {
      userid: context.user._id,
      benefits: benefitsCopy,
    };

    return api
      .updateBenefits(data)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  const skipHandler = async (e) => {
    const benefitCount = await countBenefits(benefits);

    console.log(benefitCount);

    if (benefitCount > 0) {
      history.push("/app/welcome/lookingfor");
    } else {
      setShowWarning(true);
    }
  };

  const confirmSkipHandler = (e) => {
    history.push("/app/welcome/lookingfor");
  };

  const isDisabled = false;

  const vh = window.innerHeight;
  const vw = window.innerWidth;
  let maxHeight = null;
  if (vw > 991) {
    maxHeight = `${vh * 0.4}px`;
  }

  const photoContainerStyle = {
    maxHeight,
    display: "flex",
    flexWrap: "wrap",
    paddingTop: "1rem",
  };

  return (
    <div className="welcome-v2-container-benefits">
      {showWarning && (
        <div className="no-photos-warning">
          <div className="no-photos-innerwrap">
            <div
              className="no-photos-warning-overlay"
              onClick={() => {
                setShowWarning(false);
              }}
            />
            <div className="no-photos-warning-content">
              <div className="no-photos-content-warning">
                <i className="far fa-exclamation-triangle no-photos-warning-icon" />
                <h1 className="no-photos-warning-title">Warning</h1>
                <p className="no-photos-warning-subtitle">
                  Choose your dating benefits to get the best results on
                  Sugarbae
                </p>
              </div>
              <div
                className="no-photos-footer-button"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                Select Benefits
              </div>
              <div
                className="no-photos-warning-footer"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                <span
                  className="no-photos-close-text"
                  onClick={confirmSkipHandler}
                >
                  Skip
                </span>
                <i className="far fa-times-circle" />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="welcome-v2-header-benefits">
        <div className="welcome-v2-title">
          <h1 className="welcome-v2-title-one">Your</h1>
          <h2 className="welcome-v2-title-two">Benefits</h2>
        </div>
        <p className="welcome-v2-subtitle">
          Select the dating benefits you are looking to find on Sugarbae.
        </p>
      </div>
      <div className="welcome-v2-benefits-body" style={photoContainerStyle}>
        <div
          className={
            benefits.ActiveLifestyle
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("ActiveLifestyle")}
        >
          Active Lifestyle
        </div>
        <div
          className={
            benefits.EmotionalConnection
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("EmotionalConnection")}
        >
          Emotional Connection
        </div>
        <div
          className={
            benefits.FlexibleSchedule
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("FlexibleSchedule")}
        >
          Flexible Schedule
        </div>
        <div
          className={
            benefits.Attentive
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Attentive")}
        >
          Attentive
        </div>
        <div
          className={
            benefits.LongTerm
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("LongTerm")}
        >
          Long Term
        </div>
        <div
          className={
            benefits.AllEthnicities
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("AllEthnicities")}
        >
          All Ethnicities
        </div>
        <div
          className={
            benefits.Discretion
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Discretion")}
        >
          Discretion
        </div>
        <div
          className={
            benefits.FineDining
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("FineDining")}
        >
          Fine Dining
        </div>
        <div
          className={
            benefits.FriendsWithBenefits
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("FriendsWithBenefits")}
        >
          Friends with Benefits
        </div>
        <div
          className={
            benefits.Investor
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Investor")}
        >
          Investor
        </div>
        <div
          className={
            benefits.LifeOfLeisure
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("LifeOfLeisure")}
        >
          Life of Leisure
        </div>
        <div
          className={
            benefits.MarriageMinded
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("MarriageMinded")}
        >
          Marriage Minded
        </div>
        <div
          className={
            benefits.Mentorship
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Mentorship")}
        >
          Mentorship
        </div>
        <div
          className={
            benefits.Monogamous
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Monogamous")}
        >
          Monogamous
        </div>
        <div
          className={
            benefits.NoStringsAttached
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("NoStringsAttached")}
        >
          No Strings Attached
        </div>
        <div
          className={
            benefits.NonMonogamous
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("NonMonogamous")}
        >
          Non Monogamous
        </div>
        <div
          className={
            benefits.OpenRelationship
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("OpenRelationship")}
        >
          Open Relationship
        </div>
        <div
          className={
            benefits.PassportReady
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("PassportReady")}
        >
          Passport Ready
        </div>
        <div
          className={
            benefits.Platonic
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Platonic")}
        >
          Platonic
        </div>
        <div
          className={
            benefits.Romance
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Romance")}
        >
          Romance
        </div>
        <div
          className={
            benefits.ShowsAndEntertainment
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("ShowsAndEntertainment")}
        >
          Shows and Entertainment
        </div>
        <div
          className={
            benefits.TravelToYou
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("TravelToYou")}
        >
          Travel to You
        </div>
        <div
          className={
            benefits.TravelWithMe
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("TravelWithMe")}
        >
          Travel with Me
        </div>
        <div
          className={
            benefits.Vacations
              ? "welcome-v2-benefit-option-active"
              : "welcome-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Vacations")}
        >
          Vacations
        </div>
      </div>

      <div className="welcome-v2-footer-benefits">
        <div className="welcome-v2-button-skip" onClick={() => skipHandler()}>
          Skip
        </div>
        <div
          variant="danger"
          className="welcome-v2-button"
          disabled={isDisabled}
          onClick={() => history.push("/app/welcome/lookingfor")}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default withRouter(BenefitsV2);
