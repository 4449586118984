import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../../../context/UserContext";
import PremiumModal from "../../../../modals/premiumModal/PremiumModal";
import Relationship from "./Relationship";
import Height from "./Height";
import Bodytype from "./Bodytype";
import Ethnicity from "./Ethnicity";
import Drinking from "./Drinking";
import Smoking from "./Smoking";
import Cannabis from "./Cannabis";
import Education from "./Education";
import Exercise from "./Exercise";
import Kids from "./Kids";

const heights = [
  "4'0\"",
  "4'1\"",
  "4'2\"",
  "4'3\"",
  "4'4\"",
  "4'5\"",
  "4'6\"",
  "4'7\"",
  "4'8\"",
  "4'9\"",
  "4'10\"",
  "4'11\"",
  "5'0\"",
  "5'1\"",
  "5'2\"",
  "5'3\"",
  "5'4\"",
  "5'5\"",
  "5'6\"",
  "5'7\"",
  "5'8\"",
  "5'9\"",
  "5'10\"",
  "5'11\"",
  "6'0\"",
  "6'1\"",
  "6'2\"",
  "6'3\"",
  "6'4\"",
  "6'5\"",
  "6'6\"",
  "6'7\"",
  "6'8\"",
  "6'9\"",
  "6'10\"",
  "6'11\"",
  "7'0\"",
];

function Advanced(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;

  const [relationshipOpen, setRelationshipOpen] = useState(false);
  const [heightOpen, setHeightOpen] = useState(false);
  const [bodytypeOpen, setBodytypeOpen] = useState(false);
  const [ethnicityOpen, setEthnicityOpen] = useState(false);
  const [drinkingOpen, setDrinkingOpen] = useState(false);
  const [smokingOpen, setSmokingOpen] = useState(false);
  const [cannabisOpen, setCannabisOpen] = useState(false);
  const [educationOpen, setEducationOpen] = useState(false);
  const [exerciseOpen, setExerciseOpen] = useState(false);
  const [kidsOpen, setKidsOpen] = useState(false);
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);

  const backHandler = (e) => {
    props.backHandler();
  };

  const togglePremiumModal = (e) => {
    if (premiumModalOpen) {
      setPremiumModalOpen(false);
    } else {
      setPremiumModalOpen(true);
    }
  };
  const updateHandler = (name) => (props) => {
    if (name === "relationship") {
      if (props) {
        filters.relationshipstatus = props;
        context.updateFilters(filters);
        setRelationshipOpen(false);
      }
    } else if (name === "bodytype") {
      if (props) {
        filters.bodytype = props;
        context.updateFilters(filters);
        setBodytypeOpen(false);
      }
    } else if (name === "ethnicity") {
      if (props) {
        filters.ethnicity = props;
        context.updateFilters(filters);
        setEthnicityOpen(false);
      }
    } else if (name === "drinking") {
      if (props) {
        filters.drinking = props;
        context.updateFilters(filters);
        setDrinkingOpen(false);
      }
    } else if (name === "smoking") {
      if (props) {
        filters.smoking = props;
        context.updateFilters(filters);
        setSmokingOpen(false);
      }
    } else if (name === "cannabis") {
      if (props) {
        filters.cannabis = props;
        context.updateFilters(filters);
        setCannabisOpen(false);
      }
    } else if (name === "education") {
      if (props) {
        filters.education = props;
        context.updateFilters(filters);
        setEducationOpen(false);
      }
    } else if (name === "kids") {
      if (props) {
        filters.children = props;
        context.updateFilters(filters);
        setKidsOpen(false);
      }
    } else if (name === "exercise") {
      if (props) {
        filters.exercise = props;
        context.updateFilters(filters);
        setExerciseOpen(false);
      }
    } else if (name === "height") {
      if (props) {
        filters.height = props;
        context.updateFilters(filters);
        setHeightOpen(false);
      }
    }
  };

  const toggleEdit = (name) => (e) => {
    if (context.user.premium) {
      if (name === "relationship") {
        if (relationshipOpen) {
          setRelationshipOpen(false);
        } else {
          setRelationshipOpen(true);
        }
      } else if (name === "height") {
        if (heightOpen) {
          setHeightOpen(false);
        } else {
          setHeightOpen(true);
        }
      } else if (name === "bodytype") {
        if (bodytypeOpen) {
          setBodytypeOpen(false);
        } else {
          setBodytypeOpen(true);
        }
      } else if (name === "ethnicity") {
        if (ethnicityOpen) {
          setEthnicityOpen(false);
        } else {
          setEthnicityOpen(true);
        }
      } else if (name === "drinking") {
        if (drinkingOpen) {
          setDrinkingOpen(false);
        } else {
          setDrinkingOpen(true);
        }
      } else if (name === "smoking") {
        if (smokingOpen) {
          setSmokingOpen(false);
        } else {
          setSmokingOpen(true);
        }
      } else if (name === "cannabis") {
        if (cannabisOpen) {
          setCannabisOpen(false);
        } else {
          setCannabisOpen(true);
        }
      } else if (name === "education") {
        if (educationOpen) {
          setEducationOpen(false);
        } else {
          setEducationOpen(true);
        }
      } else if (name === "exercise") {
        if (exerciseOpen) {
          setExerciseOpen(false);
        } else {
          setExerciseOpen(true);
        }
      } else if (name === "kids") {
        if (kidsOpen) {
          setKidsOpen(false);
        } else {
          setKidsOpen(true);
        }
      }
    } else {
      togglePremiumModal();
    }
  };

  const resetAllHandler = (e) => {
    if (context.user.premium) {
      const tempRelationship = {
        single: false,
        divorced: false,
        separated: false,
        married: false,
        openrelationship: false,
        widowed: false,
      };
      const tempHeight = [0, 36];
      const tempBodytype = {
        slim: false,
        fit: false,
        muscular: false,
        average: false,
        afewextrapounds: false,
        curvy: false,
        fullfigured: false,
      };
      const tempEthnicity = {
        white: false,
        black: false,
        asian: false,
        hispanic: false,
        indian: false,
        middleeastern: false,
        other: false,
      };
      const tempDrinking = {
        nondrinker: false,
        socialdrinker: false,
        heavydrinker: false,
      };
      const tempSmoking = {
        nonsmoker: false,
        lightsmoker: false,
        heavysmoker: false,
      };
      const tempCannabis = {
        nonsmoker: false,
        lightsmoker: false,
        heavysmoker: false,
      };
      const tempChildren = {
        nokids: false,
        onekid: false,
        twokids: false,
        threekids: false,
        fourpluskids: false,
      };
      const tempExercise = {
        never: false,
        sometimes: false,
        regularly: false,
        often: false,
        daily: false,
      };
      const tempEducation = {
        highschool: false,
        somecollege: false,
        associatesdegree: false,
        bachelorsdegree: false,
        graduatedegree: false,
        phd: false,
      };

      filters.education = tempEducation;
      filters.exercise = tempExercise;
      filters.children = tempChildren;
      filters.cannabis = tempCannabis;
      filters.smoking = tempSmoking;
      filters.drinking = tempDrinking;
      filters.ethnicity = tempEthnicity;
      filters.bodytype = tempBodytype;
      filters.relationshipstatus = tempRelationship;
      filters.height = tempHeight;
      context.updateFilters(filters);
    } else {
      setPremiumModalOpen(true);
    }
  };

  const clearFilterHandler = (name) => (e) => {
    if (name === "relationship") {
      const tempRelationship = {
        single: false,
        divorced: false,
        separated: false,
        married: false,
        openrelationship: false,
        widowed: false,
      };
      filters.relationshipstatus = tempRelationship;
      context.updateFilters(filters);
    } else if (name === "height") {
      const tempHeight = [0, 36];
      filters.height = tempHeight;
      context.updateFilters(filters);
    } else if (name === "bodytype") {
      const tempBodytype = {
        slim: false,
        fit: false,
        muscular: false,
        average: false,
        afewextrapounds: false,
        curvy: false,
        fullfigured: false,
      };
      filters.bodytype = tempBodytype;
      context.updateFilters(filters);
    } else if (name === "ethnicity") {
      const tempEthnicity = {
        white: false,
        black: false,
        asian: false,
        hispanic: false,
        indian: false,
        middleeastern: false,
        other: false,
      };
      filters.ethnicity = tempEthnicity;
      context.updateFilters(filters);
    } else if (name === "drinking") {
      const tempDrinking = {
        nondrinker: false,
        socialdrinker: false,
        heavydrinker: false,
      };
      filters.drinking = tempDrinking;
      context.updateFilters(filters);
    } else if (name === "smoking") {
      const tempSmoking = {
        nonsmoker: false,
        lightsmoker: false,
        heavysmoker: false,
      };
      filters.smoking = tempSmoking;
      context.updateFilters(filters);
    } else if (name === "cannabis") {
      const tempCannabis = {
        nonsmoker: false,
        lightsmoker: false,
        heavysmoker: false,
      };
      filters.cannabis = tempCannabis;
      context.updateFilters(filters);
    } else if (name === "kids") {
      const tempChildren = {
        nokids: false,
        onekid: false,
        twokids: false,
        threekids: false,
        fourpluskids: false,
      };
      filters.children = tempChildren;
      context.updateFilters(filters);
    } else if (name === "exercise") {
      const tempExercise = {
        never: false,
        sometimes: false,
        regularly: false,
        often: false,
        daily: false,
      };
      filters.exercise = tempExercise;
      context.updateFilters(filters);
    } else if (name === "education") {
      const tempEducation = {
        highschool: false,
        somecollege: false,
        associatesdegree: false,
        bachelorsdegree: false,
        graduatedegree: false,
        phd: false,
      };
      filters.education = tempEducation;
      context.updateFilters(filters);
    }
  };

  let relationshipText = "";
  let relationshipSelect = true;
  if (filters.relationshipstatus) {
    const { relationshipstatus } = filters;
    if (relationshipstatus.single) {
      relationshipText += "Single, ";
      relationshipSelect = false;
    }
    if (relationshipstatus.divorced) {
      relationshipText += "Divorced, ";
      relationshipSelect = false;
    }
    if (relationshipstatus.separated) {
      relationshipText += "Separated, ";
      relationshipSelect = false;
    }
    if (relationshipstatus.marriedbutlooking) {
      relationshipText += "Married, ";
      relationshipSelect = false;
    }
    if (relationshipstatus.openrelationship) {
      relationshipText += "Open, ";
      relationshipSelect = false;
    }
    if (relationshipstatus.widowed) {
      relationshipText += "Widowed, ";
      relationshipSelect = false;
    }
  }
  let heightText = "";
  let heightSelect = true;
  if (filters.height) {
    const minHeight = heights[filters.height[0]];
    const maxHeight = heights[filters.height[1]];

    if (filters.height[0] !== 0 || filters.height[1] !== 36) {
      heightText = `${minHeight} - ${maxHeight}`;
      heightSelect = false;
    }
  }
  let bodytypeText = "";
  let bodytypeSelect = true;
  if (filters.bodytype) {
    const { bodytype } = filters;
    if (bodytype.slim) {
      bodytypeText += "Slim, ";
      bodytypeSelect = false;
    }
    if (bodytype.fit) {
      bodytypeText += "Fit, ";
      bodytypeSelect = false;
    }
    if (bodytype.muscular) {
      bodytypeText += "Muscular, ";
      bodytypeSelect = false;
    }
    if (bodytype.average) {
      bodytypeText += "Average, ";
      bodytypeSelect = false;
    }
    if (bodytype.afewextrapounds) {
      bodytypeText += "A Few Extra Pounds, ";
      bodytypeSelect = false;
    }
    if (bodytype.curvy) {
      bodytypeText += "Curvy, ";
      bodytypeSelect = false;
    }
    if (bodytype.fullfigured) {
      bodytypeText += "Full Figured, ";
      bodytypeSelect = false;
    }
  }
  let ethnicityText = "";
  let ethnicitySelect = true;
  if (filters.ethnicity) {
    const { ethnicity } = filters;
    if (ethnicity.white) {
      ethnicityText += "White, ";
      ethnicitySelect = false;
    }
    if (ethnicity.black) {
      ethnicityText += "Black, ";
      ethnicitySelect = false;
    }
    if (ethnicity.asian) {
      ethnicityText += "Asian, ";
      ethnicitySelect = false;
    }
    if (ethnicity.hispanic) {
      ethnicityText += "Hispanic, ";
      ethnicitySelect = false;
    }
    if (ethnicity.indian) {
      ethnicityText += "Indian, ";
      ethnicitySelect = false;
    }
    if (ethnicity.middleeastern) {
      ethnicityText += "Middle Eastern, ";
      ethnicitySelect = false;
    }
    if (ethnicity.other) {
      ethnicityText += "Other, ";
      ethnicitySelect = false;
    }
  }
  let drinkingText = "";
  let drinkingSelect = true;
  if (filters.drinking) {
    const { drinking } = filters;
    if (drinking.nondrinker) {
      drinkingText += "Non Drinker, ";
      drinkingSelect = false;
    }
    if (drinking.socialdrinker) {
      drinkingText += "Social Drinker, ";
      drinkingSelect = false;
    }
    if (drinking.heavydrinker) {
      drinkingText += "Heavy Drinker, ";
      drinkingSelect = false;
    }
  }
  let smokingText = "";
  let smokingSelect = true;
  if (filters.smoking) {
    const { smoking } = filters;
    if (smoking.nonsmoker) {
      smokingText += "Non Smoker, ";
      smokingSelect = false;
    }
    if (smoking.lightsmoker) {
      smokingText += "Social Smoker, ";
      smokingSelect = false;
    }
    if (smoking.heavysmoker) {
      smokingText += "Heavy Smoker, ";
      smokingSelect = false;
    }
  }
  let cannabisText = "";
  let cannabisSelect = true;
  if (filters.cannabis) {
    const { cannabis } = filters;
    if (cannabis.nonsmoker) {
      cannabisText += "Non Smoker, ";
      cannabisSelect = false;
    }
    if (cannabis.lightsmoker) {
      cannabisText += "Social Smoker, ";
      cannabisSelect = false;
    }
    if (cannabis.heavysmoker) {
      cannabisText += "Heavy Smoker, ";
      cannabisSelect = false;
    }
  }
  let educationText = "";
  let educationSelect = true;

  if (filters.education) {
    const { education } = filters;
    if (education.highschool) {
      educationText += "High School, ";
      educationSelect = false;
    }
    if (education.somecollege) {
      educationText += "Some College, ";
      educationSelect = false;
    }
    if (education.associatesdegree) {
      educationText += "Associates Degree, ";
      educationSelect = false;
    }
    if (education.bachelorsdegree) {
      educationText += "Bachelors Degree, ";
      educationSelect = false;
    }
    if (education.graduatedegree) {
      educationText += "Graduate Degree, ";
      educationSelect = false;
    }
    if (education.phd) {
      educationText += "PhD, ";
      educationSelect = false;
    }
  }
  let kidsText = "";
  let kidsSelect = true;

  if (filters.children) {
    const { children } = filters;
    if (children.nokids) {
      kidsText += "No Kids, ";
      kidsSelect = false;
    }
    if (children.onekid) {
      kidsText += "One Kid, ";
      kidsSelect = false;
    }
    if (children.twokids) {
      kidsText += "Two Kids, ";
      kidsSelect = false;
    }
    if (children.threekids) {
      kidsText += "Three Kids, ";
      kidsSelect = false;
    }
    if (children.fourpluskids) {
      kidsText += "Four Plus Kids, ";
      kidsSelect = false;
    }
  }
  let exerciseText = "";
  let exerciseSelect = true;
  if (filters.exercise) {
    const { exercise } = filters;
    if (exercise.never) {
      exerciseText += "Never, ";
      exerciseSelect = false;
    }
    if (exercise.sometimes) {
      exerciseText += "Sometimes, ";
      exerciseSelect = false;
    }
    if (exercise.regularly) {
      exerciseText += "Regularly, ";
      exerciseSelect = false;
    }
    if (exercise.often) {
      exerciseText += "Often, ";
      exerciseSelect = false;
    }
    if (exercise.daily) {
      exerciseText += "Daily, ";
      exerciseSelect = false;
    }
  }

  if (relationshipOpen) {
    return (
      <Relationship
        onClose={toggleEdit("relationship")}
        filters={filters}
        updateHandler={updateHandler("relationship")}
      />
    );
  }
  if (heightOpen) {
    return (
      <Height
        onClose={toggleEdit("height")}
        filters={filters}
        updateHandler={updateHandler("height")}
      />
    );
  }

  if (bodytypeOpen) {
    return (
      <Bodytype
        onClose={toggleEdit("bodytype")}
        filters={filters}
        updateHandler={updateHandler("bodytype")}
      />
    );
  }

  if (ethnicityOpen) {
    return (
      <Ethnicity
        onClose={toggleEdit("ethnicity")}
        filters={filters}
        updateHandler={updateHandler("ethnicity")}
      />
    );
  }

  if (drinkingOpen) {
    return (
      <Drinking
        onClose={toggleEdit("drinking")}
        filters={filters}
        updateHandler={updateHandler("drinking")}
      />
    );
  }

  if (smokingOpen) {
    return (
      <Smoking
        onClose={toggleEdit("smoking")}
        filters={filters}
        updateHandler={updateHandler("smoking")}
      />
    );
  }

  if (cannabisOpen) {
    return (
      <Cannabis
        onClose={toggleEdit("cannabis")}
        filters={filters}
        updateHandler={updateHandler("cannabis")}
      />
    );
  }

  if (educationOpen) {
    return (
      <Education
        onClose={toggleEdit("education")}
        filters={filters}
        updateHandler={updateHandler("education")}
      />
    );
  }

  if (exerciseOpen) {
    return (
      <Exercise
        onClose={toggleEdit("exercise")}
        filters={filters}
        updateHandler={updateHandler("exercise")}
      />
    );
  }

  if (kidsOpen) {
    return (
      <Kids
        onClose={toggleEdit("kids")}
        filters={filters}
        updateHandler={updateHandler("kids")}
      />
    );
  }

  return (
    <div className="desktop-v2-filters-content">
      {premiumModalOpen && (
        <PremiumModal
          onClose={togglePremiumModal}
          show={premiumModalOpen}
          option="advancedFilters"
        />
      )}

      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.backHandler}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-title">
              Advanced Filters
            </div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={clearFilterHandler}
            >
              Reset All
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("relationship")}
            >
              <i className="far fa-heart" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("relationship")}
            >
              Relationship Status
            </div>
            {relationshipSelect ? (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("relationship")}
              >
                <span className="filters-v2-select-text">Select</span>
              </div>
            ) : (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("relationship")}
              >
                {relationshipText}
              </div>
            )}

            {relationshipSelect ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("relationship")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("relationship")}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </div>
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("height")}
            >
              <i className="far fa-ruler-vertical" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("height")}
            >
              Height
            </div>
            {heightSelect ? (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("height")}
              >
                <span className="filters-v2-select-text">Select</span>
              </div>
            ) : (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("height")}
              >
                {heightText}
              </div>
            )}
            {heightSelect ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("height")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("height")}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </div>
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("bodytype")}
            >
              <i className="far fa-child" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("bodytype")}
            >
              Bodytype
            </div>
            {bodytypeSelect ? (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("bodytype")}
              >
                <span className="filters-v2-select-text">Select</span>
              </div>
            ) : (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("bodytype")}
              >
                {bodytypeText}
              </div>
            )}
            {bodytypeSelect ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("bodytype")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("bodytype")}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </div>
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("ethnicity")}
            >
              <i className="far fa-smile" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("ethnicity")}
            >
              Ethnicity
            </div>
            {ethnicitySelect ? (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("ethnicity")}
              >
                <span className="filters-v2-select-text">Select</span>
              </div>
            ) : (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("ethnicity")}
              >
                {ethnicityText}
              </div>
            )}
            {ethnicitySelect ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("ethnicity")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("ethnicity")}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </div>
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("drinking")}
            >
              <i className="far fa-wine-glass-alt" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("drinking")}
            >
              Drinking
            </div>
            {drinkingSelect ? (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("drinking")}
              >
                <span className="filters-v2-select-text">Select</span>
              </div>
            ) : (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("drinking")}
              >
                {drinkingText}
              </div>
            )}
            {drinkingSelect ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("drinking")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("drinking")}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </div>
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("smoking")}
            >
              <i className="far fa-smoking" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("smoking")}
            >
              Smoking
            </div>
            {smokingSelect ? (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("smoking")}
              >
                <span className="filters-v2-select-text">Select</span>
              </div>
            ) : (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("smoking")}
              >
                {smokingText}
              </div>
            )}
            {smokingSelect ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("smoking")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("smoking")}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </div>
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("cannabis")}
            >
              <i className="far fa-cannabis" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("cannabis")}
            >
              Cannabis
            </div>
            {cannabisSelect ? (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("cannabis")}
              >
                <span className="filters-v2-select-text">Select</span>
              </div>
            ) : (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("cannabis")}
              >
                {cannabisText}
              </div>
            )}
            {cannabisSelect ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("cannabis")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("cannabis")}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </div>
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("education")}
            >
              <i className="far fa-graduation-cap" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("education")}
            >
              Education
            </div>
            {educationSelect ? (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("education")}
              >
                <span className="filters-v2-select-text">Select</span>
              </div>
            ) : (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("education")}
              >
                {educationText}
              </div>
            )}
            {educationSelect ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("education")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("education")}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </div>
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("kids")}
            >
              <i className="far fa-baby" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("kids")}
            >
              Kids
            </div>
            {kidsSelect ? (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("kids")}
              >
                <span className="filters-v2-select-text">Select</span>
              </div>
            ) : (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("kids")}
              >
                {kidsText}
              </div>
            )}
            {kidsSelect ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("kids")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("kids")}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </div>
          <div className="filters-v2-section-row">
            <div
              className="filters-v2-section-row-icon"
              onClick={toggleEdit("exercise")}
            >
              <i className="far fa-dumbbell" />
            </div>
            <div
              className="filters-v2-section-row-title"
              onClick={toggleEdit("exercise")}
            >
              Exercise
            </div>
            {exerciseSelect ? (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("exercise")}
              >
                <span className="filters-v2-select-text">Select</span>
              </div>
            ) : (
              <div
                className="filters-v2-section-row-content"
                onClick={toggleEdit("exercise")}
              >
                {exerciseText}
              </div>
            )}
            {exerciseSelect ? (
              <div
                className="filters-v2-section-row-action"
                onClick={toggleEdit("exercise")}
              >
                <i className="far fa-chevron-right" />
              </div>
            ) : (
              <div
                className="filters-v2-section-row-action"
                onClick={clearFilterHandler("exercise")}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advanced;
