import React, { useState, useEffect } from "react";
import Inbox from "../../../messages/inbox/Inbox";
import DesktopMessages from "../../desktopMessages/DesktopMessages";
import DesktopNavV2 from "../desktopNavV2/DesktopNavV2";
import DesktopSidebarV2 from "../desktopSidebarV2/DesktopSidebarV2";
import DesktopSwipeV2 from "../desktopSwipeV2/DesktopSwipeV2";
import DesktopInbox from "./DeskopInbox";
import DesktopChat from "./DesktopChat";
import "./desktopmessagesv2.scss";

function DesktopMessagesV2(props) {
  const [chatid, setChatid] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const openChatHandler = (e) => {
    setRefresh(true);
    setChatid(e);
  };

  useEffect(() => {
    if (props.chatid) {
      setChatid(props.chatid);
    }
  }, [props.chatid]);

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  return (
    <div className="desktop-v2-container">
      <DesktopSidebarV2 />
      <div className="desktop-v2-content">
        <DesktopNavV2 />
        <div className="desktop-v2-content-container">
          <DesktopInbox openChatHandler={openChatHandler} />
          {!refresh && <DesktopChat chatid={chatid} />}
        </div>
      </div>
    </div>
  );
}

export default DesktopMessagesV2;
