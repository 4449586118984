import React, { useState, useContext, useRef, useEffect } from "react";
import "./editscreensstyles.scss";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";

function Address(props) {
  const context = useContext(UserContext);

  const toggleTab = (e) => {
    props.toggleSubTab();
  };

  var nameCompleted = false;
  var birthDateCompleted = false;
  var addressCompleted = false;
  var genderCompleted = false;
  var firstName = null;
  var middleName = null;
  var lastName = null;

  if (context.user.identity) {
    firstName = context.user.identity.firstName;
    middleName = context.user.identity.middleName;
    lastName = context.user.identity.lastName;
  }

  var identityVerified = true;
  var fullName = "Matthew Stewart";
  var birthDateText = "October 24th 1994";
  var addressText = "51 Maher Ave, Toronto ON";
  var genderText = "Male";

  return (
    <div className="edit-screen">
      <div className="edit-screen-header">
        <div className="edit-screen-header-section-left">
          <Button
            className="edit-screen-header-return-button"
            onClick={toggleTab}
          >
            <i className="fas fa-chevron-left"></i>
          </Button>
        </div>
        <div className="edit-screen-header-section-middle">
          <h1 className="edit-screen-header-title">Address</h1>
        </div>
      </div>
      <div className="edit-screen-content">
        {/* <div className="settings-v2-section">
          <div className="settings-v2-section-content">
            <div
              className="settings-v2-section-row"
              onClick={toggleSubTab("name")}
            >
              <div className="settings-v2-section-row-title">Full Name</div>
              {!nameCompleted ? (
                <div className="settings-v2-section-row-content">
                  <Button className="settings-v2-connect-button">
                    Required
                  </Button>
                </div>
              ) : (
                <div className="settings-v2-section-row-content">
                  {fullName}
                </div>
              )}
              <div className="settings-v2-section-row-action">
                <i className="far fa-chevron-right  settings-v2-right-icon"></i>
              </div>
            </div>
            <div
              className="settings-v2-section-row"
              onClick={toggleSubTab("birthDate")}
            >
              <div className="settings-v2-section-row-title">Birth Date</div>
              {!birthDateCompleted ? (
                <div className="settings-v2-section-row-content">
                  <Button className="settings-v2-connect-button">
                    Required
                  </Button>
                </div>
              ) : (
                <div className="settings-v2-section-row-content">
                  {birthDateText}
                </div>
              )}
              <div className="settings-v2-section-row-action">
                <i className="far fa-chevron-right  settings-v2-right-icon"></i>
              </div>
            </div>
            <div
              className="settings-v2-section-row"
              onClick={toggleSubTab("gender")}
            >
              <div className="settings-v2-section-row-title">Gender</div>
              {!genderCompleted ? (
                <div className="settings-v2-section-row-content">
                  <Button className="settings-v2-connect-button">
                    Required
                  </Button>
                </div>
              ) : (
                <div className="settings-v2-section-row-content">
                  {genderText}
                </div>
              )}
              <div className="settings-v2-section-row-action">
                <i className="far fa-chevron-right  settings-v2-right-icon"></i>
              </div>
            </div>
            <div
              className="settings-v2-section-row"
              onClick={toggleSubTab("address")}
            >
              <div className="settings-v2-section-row-title">Address</div>
              {!addressCompleted ? (
                <div className="settings-v2-section-row-content">
                  <Button className="settings-v2-connect-button">
                    Required
                  </Button>
                </div>
              ) : (
                <div className="settings-v2-section-row-content">
                  {addressText}
                </div>
              )}
              <div className="settings-v2-section-row-action">
                <i className="far fa-chevron-right  settings-v2-right-icon"></i>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Address;
