import React, { useState, useEffect, useContext, useRef } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import {
  ProgressBar,
  Card,
  Button,
  Image as IconImage,
  Alert,
} from 'react-bootstrap';
import { arrayMove } from 'react-sortable-hoc';
import { UserContext } from '../../../context/UserContext';
import { api } from '../../../api';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../utils/getCloudinaryInfo';
import { useHistory, withRouter } from 'react-router-dom';
import Axios from 'axios';
import S3 from 'react-aws-s3';
import BounceLoader from 'react-spinners/BounceLoader';
import EditPhoto from './EditPhoto';
import { uploadMedia } from '../../../helpers/UploadMedia';

var imageTypes = [
  'image/webp',
  'image/heif',
  'image/heic',
  'image/jpg',
  'image/jpe',
  'image/jpeg',
  'image/png',
  'image/pdf',
  'image/jp2',
  'image/wdp',
  'image/jxr',
  'image/hdp',
  'image/svg',
  'image/tif',
  'image/tiff',
];

var videoTypes = [
  'image/gif',
  'video/flv',
  'video/avi',
  'video/m3u8',
  'video/ts',
  'video/m2ts',
  'video/mts',
  'video/mov',
  'video/mkv',
  'video/mp4',
  'video/mpeg',
  'video/mpd',
  'video/webm',
  'video/wmv',
  'video/x-matroska',
  'video/quicktime',
];

const config = {
  bucketName: process.env.REACT_APP_AWS_S3_BUCKET,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEYID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESSKEY,
};

const ReactS3Client = new S3(config);

function OwnProfile(props) {
  const context = useContext(UserContext);
  const [images, setImages] = useState(context.user.imgurl);
  const [privateImages, setPrivateImages] = useState(
    context.user.privatePhotos ? context.user.privatePhotos : []
  );
  const [editImageOpen, setEditImageOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [files, setFiles] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [index, setIndex] = useState(0);
  const [length, setLength] = useState(0);
  const [isAccepted, setIsAccepted] = useState(true);
  let history = useHistory();
  let fileRef = useRef();

  var tab = props.tab;

  var mapImages = [];
  if (props.tab === 'public') {
    mapImages = images;
  } else if (props.tab === 'private') {
    mapImages = privateImages;
  }

  var remainingBoxes = 6 - mapImages.length;

  const closeHandler = () => {
    setEditImageOpen(false);
    setImageIndex(null);
  };

  const editImage = (e) => {
    setImageIndex(e);
    setEditImageOpen(true);
  };

  const togglePeople = (e) => {
    history.push('/app/requests/people');
  };

  const toggleRequests = (e) => {
    history.push('/app/requests');
  };
  const uploadClickHandler = () => {
    fileRef.click();
  };

  const fileChange = async (e) => {
    const files = e.target.files;
    if (files.length === 0) return;

    setFiles(files);
    setUploading(true);
    setLength(length);
    setUploadPercentage(0);
    setIndex(0);
    setIsAccepted(true);
  };

  const saveImages = async (e) => {
    const data = {
      userid: context.user._id,
      images: images,
    };

    return api
      .saveImages(data)
      .then((res) => {
        let tempUser = context.user;
        tempUser.imgurl = images;
        context.updateUserInfo('all', tempUser);
      })
      .catch((err) => {});
  };

  const savePrivateImages = async (e) => {
    const data = {
      userid: context.user._id,
      images: privateImages,
    };

    return api
      .savePrivateImages(data)
      .then(() => {
        let tempUser = context.user;
        tempUser.privatePhotos = privateImages;
        context.updateUserInfo('all', tempUser);
      })
      .catch(() => {});
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (tab === 'public') {
      setImages(arrayMove(images, oldIndex, newIndex));
    } else {
      setPrivateImages(arrayMove(privateImages, oldIndex, newIndex));
    }
    setRefresh(true);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const removeHandler = (index) => {
    if (tab === 'public') {
      images.splice(index, 1);
      setImages(images);
    } else {
      privateImages.splice(index, 1);
      setPrivateImages(privateImages);
    }
    setRefresh(true);
  };

  const setAsFirst = (index) => {
    const firstImage = images[index];
    images.splice(index, 1);
    images.unshift(firstImage);
    setImages(images);
    setRefresh(true);
  };

  const setAsFirstPrivate = (index) => {
    const firstImage = privateImages[index];
    privateImages.splice(index, 1);
    privateImages.unshift(firstImage);
    setPrivateImages(privateImages);
    setRefresh(true);
  };

  const makeAsPrivate = (index) => {
    const tempImage = images[index];
    images.splice(index, 1);
    privateImages.push(tempImage);
    setImages(images);
    setPrivateImages(privateImages);
    setRefresh(true);
  };

  const makeAsPublic = (index) => {
    const tempImage = privateImages[index];
    privateImages.splice(index, 1);
    images.push(tempImage);
    setImages(images);
    setPrivateImages(privateImages);
    setRefresh(true);
  };

  useEffect(() => {
    if (uploading) {
      upload();
    }
  }, [uploading]);

  const upload = async (e) => {
    let file = files[0];

    const uploadImage = await uploadMedia(file, context.user._id, tab);
    var tempImages = uploadImage.images;

    if (tab === 'public') {
      setImages(tempImages);
    } else {
      setPrivateImages(tempImages);
    }

    setUploading(false);
    setRefresh(true);
  };

  // useEffect(() => {
  //   if (uploading) {
  //     var count = 0;
  //     const filesArray = [];
  //     for (const key of Object.keys(files)) {
  //       filesArray.push(files);
  //     }
  //     const length = filesArray.length;
  //     const uploaders = filesArray.map(async (file, index) => {
  //       var fd = new FormData();
  //       var filetype = file[index].type;
  //       if (file[index].name.slice(-3) === "flv") {
  //         filetype = "video/flv";
  //       } else if (file[index].name.slice(-3) === "mkv") {
  //         filetype = "video/mkv";
  //       } else if (file[index].name.slice(-3) === "flv") {
  //         filetype = "video/mov";
  //       }
  //       var url = "";
  //       fd.append("file", file[index]);
  //       fd.append("api_key", "323661161668594");
  //       fd.append("timestamp", (Date.now() / 1000) | 0);

  //       if (imageTypes.includes(filetype)) {
  //         var bytes = "";
  //         await getBase64(file[index]).then((data) => {
  //           bytes = data;
  //         });

  //         var success = true;
  //         await api
  //           .imageRekognition(bytes.split(",")[1])
  //           .then((res) => {
  //             var { data } = res;
  //             if (data === "unacceptable") {
  //               success = false;
  //               setIsAccepted(false);
  //             }
  //           })
  //           .catch((err) => console.log(err));

  //         if (!success) {
  //           setUploading(false);
  //           return;
  //         }
  //         url = "https://api.cloudinary.com/v1_1/sugarbae/image/upload";
  //         fd.append("upload_preset", "txv5v2xj");
  //       }

  //       if (videoTypes.includes(filetype)) {
  //         url = "https://api.cloudinary.com/v1_1/sugarbae/video/upload";
  //         fd.append("upload_preset", "om70jgxq");
  //       }

  //       const options = {
  //         onUploadProgress: (progressEvent) => {
  //           const { loaded, total } = progressEvent;
  //           let percent = Math.floor((loaded * 100) / total);
  //           setUploadPercentage(percent);
  //           setIndex(index);
  //         },
  //         withCredentials: false,
  //       };

  //       await Axios.post(url, fd, options, {
  //         headers: { "X-Requested-With": "XMLHttpRequest" },
  //       }).then(async (res) => {
  //         count = count + 1;
  //         var imgurl = res.data.secure_url;
  //         if (videoTypes.includes(filetype)) {
  //           imgurl = imgurl.slice(0, -3);
  //           imgurl = imgurl + "gif";
  //           const firstHalf = imgurl.slice(0, 49);
  //           const secondHalf = imgurl.slice(49);
  //           const settings = "vs_20,dl_250,h_200,e_loop,du_10/";
  //           const fullUrl = firstHalf + settings + secondHalf;
  //           imgurl = fullUrl.slice(0, -3) + "gif";

  //           // detect text and check content of video
  //           let isPassed = true;
  //           let keyText = null;
  //           let keyContent = null;
  //           const uploadTextVideoPromise = ReactS3Client.uploadFile(
  //             file[index],
  //             `${context.user._id}text`
  //           )
  //             .then((res) => (keyText = res.key))
  //             .catch((err) => console.error(err));
  //           const uploadContentVideoPromise = ReactS3Client.uploadFile(
  //             file[index],
  //             `${context.user._id}content`
  //           )
  //             .then((res) => (keyContent = res.key))
  //             .catch((err) => console.error(err));
  //           const uploadPromises = [
  //             uploadTextVideoPromise,
  //             uploadContentVideoPromise,
  //           ];
  //           await Promise.all(uploadPromises);

  //           const dataKey = {
  //             key: keyText,
  //             imgurl,
  //             userid: context.user._id,
  //           };
  //           const dataContent = {
  //             key: keyContent,
  //             imgurl,
  //             userid: context.user._id,
  //           };
  //           const detectTextPromise = api
  //             .videoRekognitionText(dataKey)
  //             .then((res) => {
  //               if (!res.data.isPassed) {
  //                 isPassed = false;
  //               }
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //             });
  //           const detectModerationPromise = api
  //             .videoRekognitionContent(dataContent)
  //             .then((res) => {
  //               if (!res.data.isPassed) {
  //                 isPassed = false;
  //               }
  //             })
  //             .catch((err) => {
  //               console.log(err);
  //             });
  //           const checkVideoPromise = [
  //             detectTextPromise,
  //             detectModerationPromise,
  //           ];
  //           await Promise.all(checkVideoPromise);

  //           if (!isPassed) {
  //             setUploading(false);
  //             setIsAccepted(false);
  //             return null;
  //           }
  //         }

  //         if (tab === "public") {
  //           var tempImages = images;
  //           var uploading = true;
  //           tempImages.push(imgurl);
  //           setImages(images);
  //         } else {
  //           var tempImages = privateImages;
  //           var uploading = true;
  //           tempImages.push(imgurl);
  //           setPrivateImages(privateImages);
  //         }

  //         if (count === length) {
  //           uploading = false;
  //         }

  //         setUploading(uploading);
  //         setRefresh(true);
  //       });
  //     });
  //   }
  // }, [uploading]);

  useEffect(() => {
    if (refresh) {
      saveImages();
      savePrivateImages();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!isAccepted) {
      setTimeout(() => {
        setIsAccepted(true);
      }, 5000);
    }
  }, [isAccepted]);

  let isOnboarding = false;

  if (props.onboarding) {
    if (props.onboarding === true) {
      isOnboarding = true;
    }
  }

  return (
    <div className="photo-grid-own-profile">
      {uploading && (
        <div className="photo-uploading-overlay">
          <Card className="edit-upload-progress-card">
            <Card.Body className="upload-progress-card-body">
              <BounceLoader size="20" loading={uploading} />
              <h4 className="upload-progress-title">Uploading...</h4>
              <ProgressBar
                animated
                now={uploadPercentage}
                label={uploadPercentage}
                className="upload-progress-bar"
              />
            </Card.Body>
          </Card>
        </div>
      )}

      {editImageOpen && (
        <EditPhoto
          tab={tab}
          images={tab === 'public' ? images : privateImages}
          index={imageIndex}
          setAsFirst={setAsFirst}
          setAsFirstPrivate={setAsFirstPrivate}
          removeHandler={removeHandler}
          makeAsPrivate={makeAsPrivate}
          makeAsPublic={makeAsPublic}
          closeHandler={closeHandler}
        />
      )}

      <input
        type="file"
        accept=".gif,.webp,.heif,.heic,.jpg,.jpe,.jpeg,.png,.pdf,.jp2,.wdp,.jxr,.hdp,,.svg,.tif,.tiff,.flv,.avi,.m3u8,.ts,.m2ts,.mts,.mov,.mkv,.mp4,.mpeg,mpd,.webm,.wmv"
        multiple={false}
        onChange={fileChange}
        className="photo-grid-input"
        name="photo-input"
        id="photo-input"
        ref={(fileInput) => (fileRef = fileInput)}
      />
      {props.tab === 'private' && !isOnboarding && (
        <div className="photo-grid-requests-container">
          <div className="photo-grid-requests-row" onClick={togglePeople}>
            <div className="photo-grid-request-row-section">
              <i className="far fa-user-friends request-row-icon" />
              <div className="request-row-title">People</div>
            </div>
            <div className="photo-grid-request-row-section">
              <div className="request-row-number">
                {context.approvedRequests.length}
              </div>
              <i className="far fa-chevron-right request-right-icon" />
            </div>
          </div>
          <div className="photo-grid-requests-row" onClick={toggleRequests}>
            <div className="photo-grid-request-row-section">
              <i className="far fa-user-plus request-row-icon-red" />
              <div className="request-row-title">Requests</div>
            </div>
            <div className="photo-grid-request-row-section">
              <div className="request-row-number">
                {context.requests.length}
              </div>
              <i className="far fa-chevron-right request-right-icon" />
            </div>
          </div>
        </div>
      )}

      <div className="profile-v2-photo-grid-content-ownprofile">
        {mapImages.map((image, index) => {
          return (
            <div className="profile-v2-photo-wrapper-ownprofile">
              <img src={image} width="100%" height="100%" />

              {/* <Image
                publicId={
                  image
                    ? getImgName(image)
                    : 'larger-placeholder-user-image_vvdghq.png'
                }
                version={image ? getImgVersion(image) : '1590046357'}
                resource_type={
                  image ? (checkGIF(image) ? 'video' : 'image') : null
                }
                effect="loop"
                cloud_name="sugarbae"
                className="profile-v2-photo-ownprofile"
                onClick={() => editImage(index)}
              >
                <Transformation
                  height="200"
                  video_sampling="20"
                  delay="250"
                  duration="10"
                  effect="loop"
                  quality="auto"
                  flags="lossy"
                />
              </Image> */}
            </div>
          );
        })}

        {remainingBoxes === 6 && (
          <>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" style={{ color: '#D95454' }} />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
          </>
        )}

        {remainingBoxes === 5 && (
          <>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
          </>
        )}
        {remainingBoxes === 4 && (
          <>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
          </>
        )}
        {remainingBoxes === 3 && (
          <>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
          </>
        )}
        {remainingBoxes === 2 && (
          <>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
          </>
        )}
        {remainingBoxes === 1 && (
          <>
            <div
              className="photo-grid-v2-input-placeholder"
              onClick={uploadClickHandler}
            >
              <i className="far fa-plus-square" />
            </div>
          </>
        )}
      </div>
      {props.tab === 'private' && isOnboarding && (
        <div className="photo-grid-requests-container">
          <p className="private-photo-text">
            Other users must request to view your private photos.
          </p>
        </div>
      )}
      {props.gallery && (
        <Button
          className="edit-profile-v2-gallery-add-new"
          onClick={uploadClickHandler}
        >
          Add Photo / Video
        </Button>
      )}
    </div>
  );
}

export default withRouter(OwnProfile);
