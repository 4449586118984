import React, { Component } from 'react';

import { Image, Transformation } from 'cloudinary-react';

import { UserContext } from '../../../../context/UserContext';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import './mainimage.scss';
import Media from '../../../../components/media/Media';

var moment = require('moment');

export class MainImage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      online: true,
      imageLoaded: true,
      featuredBenefit: '',
    };
  }

  imageLoadHandler = (e) => {
    this.setState({ imageLoaded: true });
  };

  render() {
    var card = this.props.card;
    var verifications = card.verifications;
    var gender = card.gender;
    var username = card.username;
    var address = card.address;
    var image = this.props.card.imgurl[0];

    var lifestyle = card.lifestyle;
    var age = card.age;
    var height = card.height;
    var verified = false;

    var lastlogin = moment(card.lastlogin);
    var lastHour = moment().subtract(5, 'hours');
    var online = lastlogin.isAfter(lastHour);

    if (verifications.email.status && verifications.phone.status) {
      verified = true;
    }

    if (height === null || height === undefined) {
      height = "5'5'";
    }

    var networth = lifestyle.networth;

    var jobtitle = lifestyle.job.jobtitle;

    var showNetworth = false;
    var showJob = false;
    var showInfo = false;
    var showBenefit = false;

    var benefits = card.benefits;
    var selectedBenefits = [];
    var nonSelectedBenefits = [];
    var featuredBenefit = '';

    if (benefits.ActiveLifestyle) {
      selectedBenefits.push('Active Lifestyle');
    } else {
      nonSelectedBenefits.push('Active Lifestyle');
    }
    if (benefits.EmotionalConnection) {
      selectedBenefits.push('Emotional Connection');
    } else {
      nonSelectedBenefits.push('Emotional Connection');
    }
    if (benefits.FlexibleSchedule) {
      selectedBenefits.push('Flexible Schedule');
    } else {
      nonSelectedBenefits.push('Flexible Schedule');
    }
    if (benefits.Attentive) {
      selectedBenefits.push('Attentive');
    } else {
      nonSelectedBenefits.push('Attentive');
    }
    if (benefits.LongTerm) {
      selectedBenefits.push('Long Term');
    } else {
      nonSelectedBenefits.push('Long Term');
    }
    if (benefits.AllEthnicities) {
      selectedBenefits.push('All Ethnicities');
    } else {
      nonSelectedBenefits.push('All Ethnicities');
    }
    if (benefits.Discretion) {
      selectedBenefits.push('Discretion');
    } else {
      nonSelectedBenefits.push('Discretion');
    }
    if (benefits.FineDining) {
      selectedBenefits.push('Fine Dining');
    } else {
      nonSelectedBenefits.push('Fine Dining');
    }
    if (benefits.FriendsWithBenefits) {
      selectedBenefits.push('Friends With Benefits');
    } else {
      nonSelectedBenefits.push('Friends With Benefits');
    }
    if (benefits.Investor) {
      selectedBenefits.push('Investor');
    } else {
      nonSelectedBenefits.push('Investor');
    }
    if (benefits.LifeOfLeisure) {
      selectedBenefits.push('Life of Leisure');
    } else {
      nonSelectedBenefits.push('Life of Leisure');
    }
    if (benefits.LuxuryLifestyle) {
      selectedBenefits.push('Luxury Lifestyle');
    } else {
      nonSelectedBenefits.push('Luxury Lifestyle');
    }
    if (benefits.MarriageMinded) {
      selectedBenefits.push('Marriage Minded');
    } else {
      nonSelectedBenefits.push('Marriage Minded');
    }
    if (benefits.Mentorship) {
      selectedBenefits.push('Mentorship');
    } else {
      nonSelectedBenefits.push('Mentorship');
    }
    if (benefits.Monogamous) {
      selectedBenefits.push('Monogamous');
    } else {
      nonSelectedBenefits.push('Monogamous');
    }
    if (benefits.NoStringsAttached) {
      selectedBenefits.push('No Strings Attached');
    } else {
      nonSelectedBenefits.push('No Strings Attached');
    }
    if (benefits.NonMonogamous) {
      selectedBenefits.push('Non Monogamous');
    } else {
      nonSelectedBenefits.push('Non Monogamous');
    }
    if (benefits.OpenRelationship) {
      selectedBenefits.push('Open Relationship');
    } else {
      nonSelectedBenefits.push('Open Relationship');
    }
    if (benefits.PassportReady) {
      selectedBenefits.push('Passport Ready');
    } else {
      nonSelectedBenefits.push('Passport Ready');
    }
    if (benefits.Platonic) {
      selectedBenefits.push('Platonic');
    } else {
      nonSelectedBenefits.push('Platonic');
    }
    if (benefits.Romance) {
      selectedBenefits.push('Romance');
    } else {
      nonSelectedBenefits.push('Romance');
    }
    if (benefits.ShowsAndEntertainment) {
      selectedBenefits.push('Shows and Entertainment');
    } else {
      nonSelectedBenefits.push('Shows and Entertainment');
    }
    if (benefits.TravelToYou) {
      selectedBenefits.push('Travel to You');
    } else {
      nonSelectedBenefits.push('Travel to You');
    }
    if (benefits.TravelWithMe) {
      selectedBenefits.push('Travel with Me');
    } else {
      nonSelectedBenefits.push('Travel with Me');
    }
    if (benefits.Vacations) {
      selectedBenefits.push('Vacations');
    } else {
      nonSelectedBenefits.push('Vacations');
    }

    if (selectedBenefits.length > 0) {
      var featuredBenefit =
        selectedBenefits[Math.floor(Math.random() * selectedBenefits.length)];
    } else {
      var featuredBenefit =
        nonSelectedBenefits[
          Math.floor(Math.random() * nonSelectedBenefits.length)
        ];
    }

    if (networth === null || networth === undefined) {
      showNetworth = false;
    }

    if (featuredBenefit === null || featuredBenefit === undefined) {
      showBenefit = false;
    }

    if (jobtitle === null || jobtitle === undefined) {
      showJob = false;
    }

    return (
      <div className="main-image-container">
        <Media image={image} />
        {/* <Image
          publicId={
            image
              ? getImgName(image)
              : "larger-placeholder-user-image_vvdghq.png"
          }
          version={image ? getImgVersion(image) : "1590046357"}
          resource_type={image ? (checkGIF(image) ? "video" : "image") : null}
          effect="loop"
          cloud_name="sugarbae"
          className="main-image"
          onLoad={this.imageLoadHandler}
        >
          <Transformation
            height="200"
            video_sampling="20"
            delay="250"
            duration="10"
            effect="loop"
            quality="auto"
            flags="lossy"
          />
        </Image> */}

        <div
          className={
            this.props.noActions
              ? 'main-image-user-info-container-noactions'
              : 'main-image-user-info-container'
          }
        >
          <div className="main-image-info-innerwrap">
            <div className="main-image-info-top">
              {verified ? (
                <i className="fad fa-badge-check verified-main-image-icon"></i>
              ) : null}
              <h1 className="main-image-username">{username},</h1>
              <p className="main-image-age">{age}</p>
            </div>
            <div className="main-image-info-bottom">
              <span className="main-image-user-address">{address}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainImage;
