import React, { Component } from "react";
import "../welcome.scss";
import { Button, Image } from "react-bootstrap";
import { UserContext } from "../../../context/UserContext";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { withRouter } from "react-router-dom";
import siteLogoWhite from "../../../assets/logos/logo_white.png";
import welcomeImg from "../../../assets/Images/welcome/welcome-page-chat.png";
import { Checkmark } from "react-checkmark";

export class Complete extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="welcome-component-container">
        <ProgressBar animated now={100} className="welcome-progress-bar" />
        <div className="welcome-content-wrapper">
          <Image src={siteLogoWhite} fluid className="welcome-site-logo" />
          <div className="welcome-checkmark-wrapper">
            <Checkmark size={120} />
          </div>
          <h1 className="welcome-title">
            Congrats! You're profile is activated.
          </h1>
          <p className="welcome-subtitle">
            Now you can begin messaging and favoriting users. Good luck!
          </p>
        </div>
        <div className="welcome-btn-wrap">
          <Button variant="primary" className="premium-btn-advance" href="/app">
            Get Started
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(Complete);
