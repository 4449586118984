import React from 'react';
import './searchusercardv2.scss';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import Media from '../../../media/Media';
const moment = require('moment');

function SearchUserCardV2(props) {
  const images = props.user.imgurl;
  let count = 0;
  let mainImage = null;
  if (images.length > 0) {
    mainImage = images[0];
    count = images.length;
  }

  let isVerified = false;
  if (
    props.user.verifications.phone.status &&
    props.user.verifications.email.status
  ) {
    isVerified = true;
  }

  let userInfoClass = 'search-user-card-v2-user-info-wrapper-notverified';
  if (isVerified) {
    userInfoClass = 'search-user-card-v2-user-info-wrapper-verified';
  }

  let isActive = false;
  const { lastlogin } = props.user;
  if (lastlogin) {
    const oneHourAgo = moment().subtract(5, 'hours');

    if (moment(lastlogin).isAfter(oneHourAgo)) {
      isActive = true;
    }
  }

  let activeIconClass = 'fas fa-circle search-user-card-online-icon-offline';

  if (isActive) {
    activeIconClass = 'fas fa-circle search-user-card-online-icon-online';
  }

  const toggleProfile = (e) => {
    props.onClick(props.user);
  };

  const vw = window.innerWidth;
  const cardWidth = (vw - 5) / 2;
  let cardHeight = cardWidth * 1.25;

  if (vw > 992) {
    cardHeight = '400px';
  }

  const userCardStyle = {
    width: `${cardWidth}px`,
    height: `${cardHeight}px`,
  };

  return (
    <div
      className="search-user-card-v2"
      style={userCardStyle}
      onClick={toggleProfile}
    >
      <div className="search-user-card-v2-overlay" />
      {props.finishedScrolling && (
        <div className="search-user-card-images-container">
          <div className="search-user-card-image-count-wrapper">
            <i className="far fa-camera image-count-camera-icon" />
            <span className="search-user-card-image-count">
              {count.toString()}
            </span>
          </div>

          <Media image={mainImage} />

          {/* <Image
            publicId={getImgName(mainImage)}
            version={getImgVersion(mainImage)}
            resource_type={checkGIF(mainImage) ? "video" : "image"}
            effect="loop"
            cloud_name="sugarbae"
            className="search-user-card-v2-image"
            fluid
          >
            <Transformation
              height="200"
              video_sampling="20"
              delay="250"
              duration="10"
              effect="loop"
              quality="auto"
              flags="lossy"
            />
          </Image> */}
        </div>
      )}

      {props.finishedScrolling && (
        <div className="search-user-card-v2-footer">
          {isVerified && (
            <div className="search-user-card-v2-verified-wrapper">
              <i className="fas fa-badge-check search-user-card-v2-verified-icon" />
            </div>
          )}

          <div className={userInfoClass}>
            <div className="search-user-card-v2-user-info-content-wrapper">
              <div className="search-user-card-v2-username">
                {props.user.username},
              </div>
              <div className="search-user-card-v2-age">{props.user.age}</div>
            </div>
            <div className="search-user-card-v2-user-info-content-wrapper">
              <div className="search-user-card-v2-address">
                {props.user.address}
              </div>
            </div>
          </div>
          <div className="search-user-card-v2-activity-wrapper">
            <i className={activeIconClass} />
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchUserCardV2;
