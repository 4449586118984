import React, { useContext, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import './profilev2.scss';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../utils/getCloudinaryInfo';
import PhotoGrid from './profileV2Components/photoGrid/PhotoGrid';
import ProgressBar from '../progressBar';
import { UserContext } from '../../context/UserContext';
import ProfileV2Footer from './profileV2Components/footer/ProfileV2Footer';
import Verifications from './profileV2Components/verifications/Verifications';
import Socials from './profileV2Components/socials/Socials';
import LookingFor from './profileV2Components/lookingFor/LookingFor';
import About from './profileV2Components/about/About';
import ComplimentModal from '../modals/complimentModal/ComplimentModal';
import { Link, withRouter } from 'react-router-dom';
import { submitLikeSearch, removeLikeSearch } from '../../helpers/swipeActions';
import PhotoGridV2 from '../photoGrid/PhotoGridV2';
import Media from '../../components/media/Media';

function ProfileV2(props) {
  const [percent, setPercent] = useState(0);
  const [card, setCard] = useState(props.profileUser);
  const [liked, setLiked] = useState(false);
  const [matched, setMatched] = useState(false);
  const [complimentModalOpen, setComplimentModalOpen] = useState(false);
  const context = useContext(UserContext);

  useEffect(() => {
    var isLiked = false;
    if (context.likedUsers.includes(card._id)) {
      isLiked = true;
    }
    setLiked(isLiked);
  }, []);

  const toggleLike = async (e) => {
    if (liked) {
      const removeLike = await removeLikeSearch(
        context.user._id,
        card._id
      ).catch((err) => {
        console.log(err);
      });

      if (removeLike.valid) {
        setLiked(false);
        var tempLikedUsers = context.likedUsers;
        const index = tempLikedUsers.indexOf(card._id);

        if (index > -1) {
          tempLikedUsers.splice(index, 1);
          context.updateLikedUsers(tempLikedUsers);
        }
      }
    } else {
      const sendLike = await submitLikeSearch(context.user._id, card._id).catch(
        (err) => {
          console.log(err);
        }
      );

      if (sendLike.valid) {
        setLiked(true);
        var tempLikedUsers = context.likedUsers;

        if (!tempLikedUsers.includes(card._id)) {
          tempLikedUsers.push(card._id);
          context.updateLikedUsers(tempLikedUsers);
        }
      }

      if (sendLike.match) {
        setMatched(true);
      }
    }
  };

  const toggleProfile = (e) => {
    props.toggleProfile();
  };

  const nextCardHandler = (e) => {
    context.searches.map((search, index) => {
      var cardid = search._id;
      if (cardid === card._id) {
        var nextCard = context.searches[index + 1];
        setCard(nextCard);
      }
    });
  };

  var mainImage = null;
  if (card.imgurl.length > 0) {
    mainImage = card.imgurl[0];
  }

  var isOwnProfile = false;
  if (context.user.username === card.username) {
    isOwnProfile = true;
  }

  var hideActions = false;

  if (props.location.pathname.includes('messages')) {
    hideActions = true;
  }

  const scrollHandler = () => {
    const div = document.getElementById('profile-v2');
    const height = div.scrollHeight - div.offsetHeight;
    setPercent((div.scrollTop / height) * 100);
  };

  const modalToggle = (e) => {
    if (complimentModalOpen) {
      setComplimentModalOpen(false);
    } else {
      setComplimentModalOpen(true);
    }
  };

  const reportHandler = (e) => {
    toggleProfile();
  };

  const banHandler = (e) => {
    toggleProfile();
    context.removeBannedUserFromContext(e);
  };

  var verified = true;
  var username = card.username;
  var age = card.age;
  var address = card.address;

  var chatLink = 'messages/' + card._id;

  return (
    <div className="profile-v2">
      {complimentModalOpen && (
        <ComplimentModal
          show={complimentModalOpen}
          modalToggle={modalToggle}
          profileUser={card}
        />
      )}
      <div className="profile-v2-header">
        <Button onClick={toggleProfile} className="profile-v2-close-button">
          <i className="fal fa-window-close profile-v2-close-icon"></i>
        </Button>
      </div>
      <div
        className="profile-v2-container"
        id="profile-v2"
        onScroll={scrollHandler}
      >
        <ProgressBar percent={percent} />
        <div className="profile-v2-main-image-container">
          <div className="profile-v2-main-image-user-info-container">
            <div className="main-image-info-innerwrap">
              <div className="main-image-info-top">
                {verified ? (
                  <i className="fad fa-badge-check verified-main-image-icon"></i>
                ) : null}
                <h1 className="main-image-username">{username},</h1>
                <p className="main-image-age">{age}</p>
              </div>
              <div className="main-image-info-bottom">
                <span className="main-image-user-address">{address}</span>
              </div>
            </div>
          </div>
          <Media image={mainImage} />
          {/* <Image
            publicId={
              mainImage
                ? getImgName(mainImage)
                : "placeholder-user-image-thumbnail_vankru.png"
            }
            version={mainImage ? getImgVersion(mainImage) : "1590045309"}
            resource_type={
              mainImage ? (checkGIF(mainImage) ? "video" : "image") : null
            }
            effect="loop"
            cloud_name="sugarbae"
            className="profile-v2-main-image"
            fluid
          >
            <Transformation
              height="200"
              video_sampling="20"
              delay="250"
              duration="10"
              effect="loop"
              quality="auto"
              flags="lossy"
            />
          </Image> */}
        </div>
        <div className="profile-v2-container-innerwrap">
          <About profileUser={card} isOwnProfile={isOwnProfile} />
          <LookingFor profileUser={card} isOwnProfile={isOwnProfile} />
          <PhotoGridV2
            profileUser={card}
            ownProfile={isOwnProfile}
            gallery={false}
          />
          {/* <Verifications profileUser={card} />
          <Socials profileUser={card} /> */}
          <ProfileV2Footer
            profileUser={card}
            modalToggle={modalToggle}
            isOwnProfile={isOwnProfile}
            reportHandler={reportHandler}
            banHandler={banHandler}
          />
        </div>
        {!isOwnProfile && !hideActions && (
          <div className="profile-v2-actions-container">
            <Link to={chatLink}>
              <div className="profile-v2-actions-button">
                <i className="fas fa-comment-alt profile-v2-send-message-icon"></i>
              </div>
            </Link>
            <div
              className={
                liked
                  ? 'profile-v2-actions-button-liked'
                  : 'profile-v2-actions-button'
              }
              onClick={toggleLike}
            >
              <i
                className={
                  liked
                    ? 'fas fa-heart profile-v2-heart-icon-liked'
                    : 'fas fa-heart profile-v2-heart-icon'
                }
              ></i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(ProfileV2);
