import React, { useEffect, useState } from "react";

import "./style.scss";

export const ProgressBar = props => {
  const top = 1.2 * props.percent;
  return (
    <div className="progressbar-scroll-indicator">
      <div className="indicator" style={{ top: `${top}px`}}></div>
    </div>
  );
};

export default ProgressBar;