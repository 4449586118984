import React from "react";

function Privacy(props) {
  const backHandler = (e) => {
    props.backHandler();
  };
  return (
    <div className="desktop-v2-settings-content">
      <div className="desktop-v2-settings-content-header">
        <div className="desktop-v2-settings-back-button" onClick={backHandler}>
          <i
            className="far fa-long-arrow-alt-left"
            style={{ marginRight: "5px" }}
          />
          <span>Back</span>
        </div>
        <h1 className="desktop-v2-settings-content-header-title">
          Privacy Policy
        </h1>
      </div>
      <div className="desktop-v2-settings-help-content">
        <p>
          By using Sugarbae.com, you accept the practices described in this
          Privacy Policy. This Privacy Policy may be changed occasionally,
          without notice. By using this service or visiting this website you
          agree to the most current policies listed here.
        </p>
        <p>
          You can choose not to provide us with any information at any time, but
          doing so may prevent you from using certain or all features of our
          website if the information is necessary for using those features. Such
          features may include, but are not limited to, communicating with other
          users.
        </p>
        <p>
          By becoming a member of this website, you agree to receive email
          communication from us. You can opt out of receiving some messages,
          such as promotional messages, understanding that it may take us a
          reasonable amount of time to process your opt-out request. You may not
          be able to opt out of transactional emails, messages about your
          account, and service messages.
        </p>
        <p>
          Our website is intended for users 18 years old and above. We do not
          knowingly collect personal information from children or anyone under
          the age of 18.
        </p>
        <h3 className="help-page-subtitle">Information We Collect</h3>
        <p>
          We may collect and store any personal information you enter on our
          website or while using our service, including your name, address,
          email address, phone number, payment method information, and any
          information about yourself, activities, and interests that you enter
          while using our service.
        </p>
        <p>
          Information like your IP address, device ID, browser type, language,
          access times, referring website address, and the content of any
          undeleted cookies from us in your browser may also be collected
          automatically.
        </p>
        <p>
          We may also use cookies, web beacons, and other similar technology to
          track your use of our site, including your activity on our site, the
          time and date of your use, the links you click, and the searches you
          make.
        </p>
        <p>
          Third party advertising companies and service providers may also
          collect information from our users about their interaction with
          advertisements posted on our site. We will never provide personal
          information to third parties.
        </p>
        <h3 className="help-page-subtitle">Information You Provide</h3>
        <p>
          Any personal information, photographs, and content you post on our
          website while using our service is governed by our Terms of Service
          and must follow the rules and guidelines listed within them.
        </p>
        <p>
          Information you post on your profile will not be shown on publicly
          viewable web pages but may be shown to other logged in sugarbae.com
          users, depending on your privacy settings. We're not responsible for
          who reads this information or what others do with this information.
        </p>
        <p>
          You can access any information we collect or you provide any time
          until your account has been deleted.
        </p>
        <h3 className="help-page-subtitle">How We Use Your Information</h3>
        <p>
          The information we collect from you is used to improve our website,
          service, and any advertisements listed on our site. We may collect or
          use your information directly, or through a third party vendor, who
          will only use your information to perform services for us.
        </p>
        <p>We may also use your information to:</p>
        <ul>
          <li>
            Communicate with you about our product or any other products or
            service we think will interest you
          </li>
          <li>Provide you with customer service and manage your account</li>
          <li>
            Analyze your use of our website and service to improve your
            experience and our products
          </li>
          <li>
            Create and develop content and advertising that's relevant and of
            interest to you on our sites or in our advertising on other sites
          </li>
          <li>Track ads and perform website analytics</li>
          <li>Enforce the rules and guidelines of our Terms of Service</li>
          <li>
            Process your payments for any subscriptions or payments made through
            our website
          </li>
        </ul>
        <h3 className="help-page-subtitle">
          How We Protect Your Personal Information
        </h3>
        <p>
          We'll never share your personal information with others except through
          the following circumstances:
        </p>
        <ul>
          <li>
            Third parties that are performing services on our behalf related to
            your account or use of this website. They will only have access to
            your information related to the service they are performing for us.
            They will not be able to share your personal information or use it
            for any reason other than the services they are contracted to
            perform for us.
          </li>
          <li>
            For a subpoena, a court order, or a request for cooperation from law
            enforcement or a government agency
          </li>
          <li>
            To defend against legal claims or to exercise our legal rights
          </li>
          <li>In connection with the sale of our business</li>
          <li>
            To deliver or develop targeted advertising and report on your
            interactions with advertising
          </li>
        </ul>
        <p>
          We may also use or share non-personal information or personal
          information that is in non-human readable form to improve our service
          or analyze the performance of our service.
        </p>
        <p>
          We will always take any necessary and appropriate measures to protect
          your personal information from being accessed or disclosed
          inappropriately. But please always use caution when using this website
          or service and sharing any personal information online as we cannot
          guarantee your personal information, communications with other
          members, searches, or other information will always remain secure.
        </p>
        <h3 className="help-page-subtitle">
          Interest-Based Online Advertising and Google Analytics
        </h3>
        <p>
          This section of our Privacy Notice provides details about
          interest-based online advertising (also called "targeted advertising",
          “remarketing”, or "behavioral advertising"), Google Analytics, and
          your choices with respect to this type of advertising.
        </p>
        <p>
          We use Google Analytics’ 3rd-party audience data such as age, gender
          and interests to better understanding the behavior of our customers
          and work with companies that collect information about your online
          activities to provide advertising targeted to suit your interests and
          preferences. For example, you may see certain ads on this website or
          other websites because we contract with Google and other similar
          companies to target our ads based on information we or they have
          collected, including information that was collected through automated
          means (such as cookies and web beacons). These companies also use
          automated technologies to collect information when you click on our
          ads, which helps track and manage the effectiveness of our marketing
          efforts.
        </p>
        <p>
          You may opt out of the automated collection of information by
          third-party ad networks for the purpose of delivering advertisements
          tailored to your interests, by visiting the consumer opt-out page for
          the Self-Regulatory Principles for Online Behavioral Advertising at
          http://www.aboutads.info/choices/ and edit or opt-out your Google
          Display Network ads’ preferences at
          http://www.google.com/ads/preferences/. Because those opt-out and
          preference control pages are specific to the individual browser used
          to visit it, and because that page is not operated by sugarbae.com, we
          are unable to perform the opt-outs on your behalf.
        </p>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at support@sugarbae.com.
        </p>
      </div>
    </div>
  );
}

export default Privacy;
