import React, { Component } from "react";
import { Card, Form } from "react-bootstrap";
import "./planpicker.scss";

export class NotesPurchaseOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: "packageB",
    };
  }
  planToggle = (props) => (e) => {
    this.setState({ plan: props }, () => {
      this.props.updatePlan(props);
    });
  };
  render() {
    var annualChecked = false;
    var monthlyChecked = false;
    var semiannualChecked = false;

    if (this.state.plan === "packageC") {
      annualChecked = true;
    } else if (this.state.plan === "packageA") {
      monthlyChecked = true;
    } else if (this.state.plan === "packageB") {
      semiannualChecked = true;
    }

    return (
      <div className="plan-select-wrapper">
        <Card
          className={
            this.state.plan === "packageC" ? "plan-card" : "plan-card-inactive"
          }
          onClick={this.planToggle("packageC")}
        >
          <Card.Body
            className={
              this.state.plan === "packageC"
                ? "plan-card-body"
                : "plan-card-body-inactive"
            }
          >
            <h1
              className={
                this.state.plan === "packageC"
                  ? "plan-card-months"
                  : "plan-card-months-inactive"
              }
            >
              60
            </h1>
            <p
              className={
                this.state.plan === "packageC"
                  ? "plan-card-subtitle"
                  : "plan-card-subtitle-inactive"
              }
            >
              Notes
            </p>
            <h4 className="plan-card-discount">Save 36%</h4>
          </Card.Body>
          <Card.Footer
            className={
              this.state.plan === "packageC"
                ? "plan-card-footer"
                : "plan-card-footer-inactive"
            }
          >
            <h2
              className={
                this.state.plan === "packageC"
                  ? "plan-card-month-total-price"
                  : "plan-card-month-total-price-inactive"
              }
            >
              $1.42/ea
            </h2>
            <Form>
              <Form.Check type="radio" checked={annualChecked} />
            </Form>
          </Card.Footer>
        </Card>
        <Card
          className={
            this.state.plan === "packageB" ? "plan-card" : "plan-card-inactive"
          }
          onClick={this.planToggle("packageB")}
        >
          {" "}
          <Card.Body
            className={
              this.state.plan === "packageB"
                ? "plan-card-body"
                : "plan-card-body-inactive"
            }
          >
            {" "}
            <h1
              className={
                this.state.plan === "packageB"
                  ? "plan-card-months"
                  : "plan-card-months-inactive"
              }
            >
              25
            </h1>
            <p
              className={
                this.state.plan === "packageB"
                  ? "plan-card-subtitle"
                  : "plan-card-subtitle-inactive"
              }
            >
              Notes
            </p>
            <h4 className="plan-card-discount">Save 27%</h4>
          </Card.Body>
          <Card.Footer
            className={
              this.state.plan === "packageB"
                ? "plan-card-footer"
                : "plan-card-footer-inactive"
            }
          >
            <h2
              className={
                this.state.plan === "packageB"
                  ? "plan-card-month-total-price"
                  : "plan-card-month-total-price-inactive"
              }
            >
              $1.60/ea
            </h2>
            <Form>
              <Form.Check type="radio" checked={semiannualChecked} />
            </Form>
          </Card.Footer>
        </Card>
        <Card
          className={
            this.state.plan === "packageA" ? "plan-card" : "plan-card-inactive"
          }
          onClick={this.planToggle("packageA")}
        >
          <Card.Body
            className={
              this.state.plan === "packageA"
                ? "plan-card-body"
                : "plan-card-body-inactive"
            }
          >
            <h1
              className={
                this.state.plan === "packageA"
                  ? "plan-card-months"
                  : "plan-card-months-inactive"
              }
            >
              5
            </h1>
            <p
              className={
                this.state.plan === "packageA"
                  ? "plan-card-subtitle"
                  : "plan-card-subtitle-inactive"
              }
            >
              Notes
            </p>
          </Card.Body>
          <Card.Footer
            className={
              this.state.plan === "packageA"
                ? "plan-card-footer"
                : "plan-card-footer-inactive"
            }
          >
            <h2
              className={
                this.state.plan === "packageA"
                  ? "plan-card-month-total-price"
                  : "plan-card-month-total-price-inactive"
              }
            >
              $2.20/ea
            </h2>
            <Form>
              <Form.Check type="radio" checked={monthlyChecked} />
            </Form>
          </Card.Footer>
        </Card>
      </div>
    );
  }
}

export default NotesPurchaseOptions;
