import React, { Component } from "react";
import "./swipepops.scss";
import { Button, Card, Image, Modal } from "react-bootstrap";
import Axios from "axios";
import { UserContext } from "../../../../context/UserContext";
import { withRouter } from "react-router-dom";

export class UndoPop extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      show: true,
      premium: false,
      loading: false,
      image: "",
    };
  }

  closeHandler = (e) => {
    this.setState({ show: false }, () => {
      this.props.closeHandler();
    });
  };

  carouselSelect = (selectedIndex, e) => {
    this.setState({ carouselIndex: selectedIndex });
  };

  linkClickHandler = (e) => {
    this.props.history.push("/app/premium");
  };

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <Modal size="lg" show={this.state.show} onHide={this.closeHandler}>
          <Modal.Body className="swipe-pop-modal-body-undo">
            <div className="swipe-pop-body-premium-title-wrap">
              <i className="fas fa-gem swipe-pop-premium-icon"></i>
              <span className="swipe-pop-body-premium-title">Go Premium</span>
            </div>
            <div className="swipe-pop-icon">
              <i className="fas fa-undo swipe-pop-main-icon"></i>{" "}
            </div>
            <h2 className="swipe-pop-body-title">Unlimited Rewinds</h2>
            <div className="swipe-pop-body-countdown-wrapper"></div>
            <p className="swipe-pop-body-subtitle">
              Unlock rewinds with Premium. Go back and swipe again!
            </p>
            <Card className="swipe-pop-features-card">
              <Card.Body className="swipe-pop-features-card-body">
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-undo swipe-pop-feature-icon-eye"></i>
                  <p className="swipe-pop-feature-text">Unlimited Rewinds</p>
                </div>
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-bolt swipe-pop-feature-icon-bolt"></i>
                  <p className="swipe-pop-feature-text">Profile Boosts</p>
                </div>
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-heart swipe-pop-feature-icon-heart"></i>
                  <p className="swipe-pop-feature-text">Unlimited Likes</p>
                </div>
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-stars swipe-pop-feature-icon-stars"></i>
                  <p className="swipe-pop-feature-text">Extra Super Likes</p>
                </div>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer className="swipe-pop-footer">
            <Button
              className="swipe-pop-premium-btn"
              size="lg"
              onClick={this.linkClickHandler}
            >
              Continue
            </Button>
            <Button
              className="swipe-pop-close-btn"
              onClick={this.closeHandler}
              size="lg"
              variant="link"
            >
              No Thanks
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
}

export default withRouter(UndoPop);
