import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";

function Exercise(props) {
  var filters = props.filters;
  const [exercise, setExercise] = useState(filters.exercise);

  const changeHandler = (e) => {
    var exerciseCopy = exercise;

    if (e.target.value === "never") {
      if (exerciseCopy.never) {
        exerciseCopy.never = false;
      } else {
        exerciseCopy.never = true;
      }
    } else if (e.target.value === "sometimes") {
      if (exerciseCopy.sometimes) {
        exerciseCopy.sometimes = false;
      } else {
        exerciseCopy.sometimes = true;
      }
    } else if (e.target.value === "regularly") {
      if (exerciseCopy.regularly) {
        exerciseCopy.regularly = false;
      } else {
        exerciseCopy.regularly = true;
      }
    } else if (e.target.value === "often") {
      if (exerciseCopy.often) {
        exerciseCopy.often = false;
      } else {
        exerciseCopy.often = true;
      }
    } else if (e.target.value === "daily") {
      if (exerciseCopy.daily) {
        exerciseCopy.daily = false;
      } else {
        exerciseCopy.daily = true;
      }
    }

    setExercise((prevState) => {
      return { ...prevState, ...exerciseCopy };
    });
  };

  const saveHandler = (e) => {
    props.updateHandler(exercise);
  };

  return (
    <div className="filter-slider-component">
      <div className="filter-slider-overlay" onClick={props.onClose} />
      <div className="filter-slider-content">
        <div className="filter-slider-title-wrap">
          <i className="far fa-heart filter-sort-icon"></i>
          <h2 className="filter-slider-title">Exercise</h2>
        </div>
        <div className="filter-slider-wrapper">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Never"}
                type="checkbox"
                value="never"
                checked={exercise.never}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Sometimes"}
                type="checkbox"
                value="sometimes"
                checked={exercise.sometimes}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Regularly"}
                type="checkbox"
                value="regularly"
                checked={exercise.regularly}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Often"}
                type="checkbox"
                value="often"
                checked={exercise.often}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Daily"}
                type="checkbox"
                value="daily"
                checked={exercise.daily}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="filter-save-button-wrapper">
          <Button className="filter-save-button" onClick={saveHandler}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Exercise;
