import React, { useState, useContext } from "react";
import "./desktopsliders.scss";
import { Image, Form } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import { UserContext } from "../../../../../context/UserContext";

function Exercise(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [exercise, setExercise] = useState(filters.exercise);

  const changeHandler = (e) => {
    const exerciseCopy = exercise;

    if (e.target.value === "never") {
      if (exerciseCopy.never) {
        exerciseCopy.never = false;
      } else {
        exerciseCopy.never = true;
      }
    } else if (e.target.value === "sometimes") {
      if (exerciseCopy.sometimes) {
        exerciseCopy.sometimes = false;
      } else {
        exerciseCopy.sometimes = true;
      }
    } else if (e.target.value === "regularly") {
      if (exerciseCopy.regularly) {
        exerciseCopy.regularly = false;
      } else {
        exerciseCopy.regularly = true;
      }
    } else if (e.target.value === "often") {
      if (exerciseCopy.often) {
        exerciseCopy.often = false;
      } else {
        exerciseCopy.often = true;
      }
    } else if (e.target.value === "daily") {
      if (exerciseCopy.daily) {
        exerciseCopy.daily = false;
      } else {
        exerciseCopy.daily = true;
      }
    }

    setExercise((prevState) => ({ ...prevState, ...exerciseCopy }));
  };

  const saveHandler = (e) => {
    props.updateHandler(exercise);
  };

  const resetHandler = (e) => {};

  return (
    <div className="desktop-v2-filters-content">
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.onClose}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-icon">
              <i className="far fa-dumbbell filter-sort-icon" />
            </div>
            <div className="desktop-v2-filters-slider-title">Exercise</div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={resetHandler}
            >
              Reset
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Never"
                type="checkbox"
                value="never"
                checked={exercise.never}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Sometimes"
                type="checkbox"
                value="sometimes"
                checked={exercise.sometimes}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Regularly"
                type="checkbox"
                value="regularly"
                checked={exercise.regularly}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Often"
                type="checkbox"
                value="often"
                checked={exercise.often}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Daily"
                type="checkbox"
                value="daily"
                checked={exercise.daily}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="desktop-filter-slider-footer" onClick={saveHandler}>
          <div className="desktop-filter-slider-apply-button">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default Exercise;
