import React, { useContext, useState, useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { api } from "../../../../../api";

import { UserContext } from "../../../../../context/UserContext";
import {
  FacebookConnect,
  GoogleConnect,
  TwitterConnect,
} from "../../../../connectSocialNet";

import "../settingsv2styles.scss";

function SocialNetworks(props) {
  const context = useContext(UserContext);
  const { socials } = context.user;
  const [refresh, setRefresh] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [clicked, setClicked] = useState(false);

  const clientID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;

  const checkHandler = () => {
    setRefresh(true);
  };

  const inValid = () => {
    setShowWarning(true);
  };

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (showWarning) {
      setTimeout(() => {
        setShowWarning(false);
      }, 4000);
    }
  }, [showWarning]);

  const googleHandler = async (e) => {
    const { profileObj, googleId } = e;
    const { email } = profileObj;

    if (profileObj && email) {
      if (context.user.email) {
        if (context.user.email === email) {
          const data = {
            option: "google",
            email,
          };
          return api.connectSocialNetwork(data).then(() => {
            context.updateUserInfo("socials", "google");
            checkHandler();
          });
        }
        inValid();
      } else {
        const emailData = { email };
        let emailExists = false;
        await api
          .checkEmail(emailData)
          .then((res) => {
            const { valid } = res.data;
            if (valid === false) {
              emailExists = true;
            }
          })
          .catch((err) => {});

        if (emailExists) {
          return inValid();
        }
        const data = {
          option: "google",
          email,
        };
        return api.connectSocialNetwork(data).then(() => {
          context.updateUserInfo("socials", "google");
          context.loadContext();
          checkHandler();
        });
      }
    } else {
      return inValid();
    }
  };
  const googleHandlerFailure = (e) => {};

  const responseFacebook = async (e) => {
    if (clicked) {
      const { email, id } = e;

      if (email) {
        if (context.user.email === email) {
          const data = {
            option: "google",
            email,
          };
          return api.connectSocialNetwork(data).then(() => {
            context.updateUserInfo("socials", "google");
            checkHandler();
          });
        }
        inValid();
      } else {
        const emailData = { email };
        let emailExists = false;
        await api
          .checkEmail(emailData)
          .then((res) => {
            const { valid } = res.data;
            if (valid === false) {
              emailExists = true;
            }
          })
          .catch((err) => {});

        if (emailExists) {
          return inValid();
        }
        const data = {
          option: "facebook",
          email,
        };
        return api.connectSocialNetwork(data).then(() => {
          context.updateUserInfo("socials", "facebook");
          context.loadContext();
          checkHandler();
        });
      }
    } else {
      inValid();
    }
  };

  const clickedComponent = () => {
    setClicked(true);
  };

  return (
    <div className="settings-v2-section">
      <h1 className="settings-v2-section-title">Social Networks</h1>
      <div className="settings-v2-section-content">
        {showWarning && (
          <Alert variant="warning">
            Social media connections must use the same email address as your
            Sugarbae account.
          </Alert>
        )}
        <div className="settings-v2-section-row">
          <div className="settings-v2-section-row-icon">
            <i className="fab fa-google settings-google-icon" />
          </div>
          <div className="settings-v2-section-row-title">Google</div>
          <div className="settings-v2-section-row-content">
            {socials.google ? (
              <p className="check-social-connection">Connected</p>
            ) : (
              <GoogleLogin
                clientId={clientID}
                onSuccess={googleHandler}
                onFailure={googleHandlerFailure}
                className="google-connect-button"
                icon={false}
              >
                Connect
              </GoogleLogin>
            )}
          </div>
          <div className="settings-v2-section-row-action">
            {!socials.google && (
              <i className="fas fa-chevron-right settings-v2-right-icon" />
            )}
          </div>
        </div>
        <div className="settings-v2-section-row">
          <div className="settings-v2-section-row-icon">
            <i className="fab fa-facebook-f settings-facebook-icon" />
          </div>
          <div className="settings-v2-section-row-title">Facebook</div>
          <div className="settings-v2-section-row-content">
            {socials.facebook ? (
              <p className="check-social-connection">Connected</p>
            ) : (
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID}
                fields="name,email,picture"
                onClick={clickedComponent}
                callback={responseFacebook}
                cssClass="facebook-connect-button"
                textButton="Connect"
              />
            )}
          </div>
          <div className="settings-v2-section-row-action">
            {!socials.facebook && (
              <i className="fas fa-chevron-right settings-v2-right-icon" />
            )}
          </div>
        </div>
        {/* <div className="settings-v2-section-row">
          <div className="settings-v2-section-row-icon">
            <i className="fab fa-twitter settings-twitter-icon" />
          </div>
          <div className="settings-v2-section-row-title">Twitter</div>
          <div className="settings-v2-section-row-content">
            {socials.twitter ? (
              <p className="check-social-connection">Connected</p>
            ) : (
              <TwitterConnect onCheck={checkHandler} inValid={inValid} />
            )}
          </div>
          <div className="settings-v2-section-row-action">
            {!socials.twitter && (
              <i className="fas fa-chevron-right settings-v2-right-icon" />
            )}
          </div>
        </div> */}
        {/* <div className="settings-v2-section-row">
          <div className="settings-v2-section-row-icon">
            <i className="fab fa-linkedin-in settings-linkedin-icon" />
          </div>
          <div className="settings-v2-section-row-title">LinkedIn</div>
          <div className="settings-v2-section-row-content">
            <Button className="settings-v2-connect-button">Connect</Button>
          </div>
          <div className="settings-v2-section-row-action">
            <i className="fas fa-chevron-right settings-v2-right-icon" />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default SocialNetworks;
