import React, { useState, useEffect, useContext } from "react";
import "./editscreensstyles.scss";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";
import Switch from "@material-ui/core/Switch";

function TextNotifications(props) {
  const context = useContext(UserContext);

  const [preferences, setPreferences] = useState(context.user.preferences);
  const toggleTab = (e) => {
    props.toggleTab();
  };

  const handleEmailToggle = (name) => (e) => {
    var preferencesCopy = preferences;
    var text = preferencesCopy.text;
    if (name === "messages") {
      if (text.messages) {
        text.messages = false;
      } else {
        text.messages = true;
      }
    }
    if (name === "visits") {
      if (text.visits) {
        text.visits = false;
      } else {
        text.visits = true;
      }
    }
    if (name === "favorites") {
      if (text.favorites) {
        text.favorites = false;
      } else {
        text.favorites = true;
      }
    }
    if (name === "matches") {
      if (text.matches) {
        text.matches = false;
      } else {
        text.matches = true;
      }
    }

    preferencesCopy.text = text;
    setPreferences((prevState) => {
      return { ...prevState, ...preferencesCopy };
    });
    context.updatePreferences(preferencesCopy);
  };

  return (
    <div className="edit-screen">
      <div className="edit-screen-header">
        <div className="edit-screen-header-section-left">
          <Button
            className="edit-screen-header-return-button"
            onClick={toggleTab}
          >
            <i className="fas fa-chevron-left"></i>
          </Button>
        </div>
        <div className="edit-screen-header-section-middle">
          <h1 className="edit-screen-header-title">Text Notifications</h1>
        </div>
      </div>
      <div className="edit-screen-content">
        <div className="edit-screen-v2-section">
          <div className="edit-screen-v2-section-content">
            <div className="edit-screen-v2-section-row">
              <div className="edit-screen-v2-section-row-title-full-width">
                When someone sends me a message
              </div>
              <div className="edit-screen-v2-section-row-content">
                <Switch
                  checked={preferences.text.messages}
                  onChange={handleEmailToggle("messages")}
                  name="switchA"
                  className="plus-settings-switch"
                />
              </div>
            </div>
            <div className="edit-screen-v2-section-row">
              <div className="edit-screen-v2-section-row-title-full-width">
                When someone views my profile
              </div>
              <div className="edit-screen-v2-section-row-content">
                <Switch
                  checked={preferences.text.visits}
                  onChange={handleEmailToggle("visits")}
                  name="switchB"
                  className="plus-settings-switch"
                />
              </div>
            </div>
            <div className="edit-screen-v2-section-row">
              <div className="edit-screen-v2-section-row-title-full-width">
                When someone likes me
              </div>
              <div className="edit-screen-v2-section-row-content">
                <Switch
                  checked={preferences.text.favorites}
                  onChange={handleEmailToggle("favorites")}
                  name="switchC"
                  className="plus-settings-switch"
                />
              </div>
            </div>
            <div className="edit-screen-v2-section-row">
              <div className="edit-screen-v2-section-row-title-full-width">
                When I get a new match
              </div>
              <div className="edit-screen-v2-section-row-content">
                <Switch
                  checked={preferences.text.matches}
                  onChange={handleEmailToggle("matches")}
                  name="switchD"
                  className="plus-settings-switch"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextNotifications;
