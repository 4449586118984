import React, { useState, useContext, useRef, useEffect } from "react";
import { Image, Button, Form, InputGroup } from "react-bootstrap";
import ReactCodeInput from "react-verification-code-input";
import EmailValidator from "email-validator";
import PasswordValidator from "password-validator";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";

const passwordSchema = new PasswordValidator();
passwordSchema.is().min(6).is().max(100).has().not().spaces();

function EditEmail(props) {
  const context = useContext(UserContext);
  const [email, setEmail] = useState(context.user.email);
  const [password, setPassword] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [passwordIncorrectError, setPasswordIncorrectError] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [emailInvalidError, setEmailInvalidError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [validateLoading, setValidateLoading] = useState(false);
  const [validationCode, setValidationCode] = useState("");
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
  const [sendAgainSuccess, setSendAgainSuccess] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (props.option === "verification") {
      sendEmailCode();
      setVerifyOpen(true);
    }
  }, []);

  const validationCodeChange = async (e) => {
    setVerifyError(false);
    setValidationCode(e);

    if (e.length === 6) {
      setValidateLoading(true);

      const data = {
        validationcode: e,
      };

      await api
        .validateEmailToken(data)
        .then((res) => {
          if (res.status === 200) {
            context.updateUserInfo("verifications", res.data.verifications);
            setVerifySuccess(true);
            props.editEmailOpenToggle();
          }
        })
        .catch((err) => {
          console.log(err);
          setValidateLoading(false);
          setVerifyError(true);
        });
    }
  };
  const resetErrors = (e) => {
    setSendAgainSuccess(false);
    setVerifyButtonDisabled(false);
    setEmailInvalid(false);
    setPasswordInvalid(false);
    setPasswordIncorrectError(false);
    setEmailExistsError(false);
    setEmailInvalidError(false);
  };

  const changeHandler = (e) => {
    setEmail(e.target.value.toLowerCase());
    setEmailInvalid(false);

    if (email.length > 0 && password.length > 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
    setPasswordInvalid(false);

    if (email.length > 0 && password.length > 0) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const sendAgainHandler = (e) => {
    sendEmailCode();
    setSendAgainSuccess(true);
  };

  const verifyHandler = (e) => {
    if (verifyOpen) {
      resetErrors();
      setDisabled(true);
      setVerifyOpen(false);
      props.editEmailOpenToggle();
    } else {
      resetErrors();
      setVerifyButtonDisabled(true);
      sendEmailCode();
      setVerifyOpen(true);
    }
  };
  const sendEmailCode = async (e) => {
    const data = {
      user: context.user._id,
      email,
    };

    return api
      .generateEmailToken(data)
      .then((res) => {})
      .catch((err) => {});
  };
  const updateHandler = async (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    setLoading(true);

    const emailValid = EmailValidator.validate(email);
    const passwordValid = passwordSchema.validate(password);

    if (!emailValid) {
      setEmailInvalid(true);
    }
    if (!passwordValid) {
      setPasswordInvalid(true);
    }

    if (emailValid && passwordValid) {
      const data = {
        email,
        password,
      };

      await api
        .updateEmail(data)
        .then((res) => {
          if (res.status === 200) {
            if (
              res.data.emailValid &&
              res.data.passwordValid &&
              !res.data.emailExists
            ) {
              context.updateUserInfo("email", email);
              const tempVerifications = context.user.verifications;
              tempVerifications.email.status = false;
              context.updateUserInfo("verifications", tempVerifications);
              props.refresh();
              verifyHandler();
            } else {
              if (!res.data.emailValid) {
                setEmailInvalid(true);

                if (!res.data.emailExists) {
                  setEmailInvalidError(true);
                }
              }

              if (!res.data.passwordValid) {
                setPasswordInvalid(true);
                setPasswordIncorrectError(true);
              }

              if (res.data.emailExists) {
                setEmailInvalid(true);
                setEmailExistsError(true);
              }
              setSubmitDisabled(false);
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setSubmitDisabled(false);
          setLoading(false);
        });
    }
  };

  const backHandler = (e) => {
    if (props.option === "verification") {
      props.verifyEmailOpenToggle();
    } else if (verifyOpen) {
      setVerifyOpen(false);
    } else {
      props.editEmailOpenToggle();
    }
  };

  return (
    <div className="desktop-v2-settings-content">
      <div className="desktop-v2-settings-content-header">
        <div className="desktop-v2-settings-back-button" onClick={backHandler}>
          <i
            className="far fa-long-arrow-alt-left"
            style={{ marginRight: "5px" }}
          />
          <span>Back</span>
        </div>
        {verifyOpen ? (
          <h1 className="desktop-v2-settings-content-header-title">
            Verify Email Address
          </h1>
        ) : (
          <h1 className="desktop-v2-settings-content-header-title">
            Update Email Address
          </h1>
        )}
      </div>
      {emailInvalidError && (
        <div className="desktop-v2-settings-edit-row">
          <div className="edit-screen-status-wrapper">
            <i className="far fa-exclamation-circle number-alert-icon" />
            <span>The email address you entered in invalid</span>
          </div>
        </div>
      )}
      {passwordIncorrectError && (
        <div className="desktop-v2-settings-edit-row">
          <div className="edit-screen-status-wrapper">
            <i className="far fa-exclamation-circle number-alert-icon" />
            <span>You entered the wrong password</span>
          </div>
        </div>
      )}
      {emailExistsError && (
        <div className="desktop-v2-settings-edit-row">
          <div className="edit-screen-status-wrapper">
            <i className="far fa-exclamation-circle number-alert-icon" />
            <span>The email you entered already exists in our system</span>
          </div>
        </div>
      )}
      {verifyOpen ? (
        <div className="desktop-v2-settings-edit-row">
          <Form className="desktop-v2-settings-form-edit">
            <Form.Group className="desktop-v2-settings-form-group-verification">
              <Form.Label className="desktop-v2-settings-form-label">
                Enter the 6 digit verification code we emailed you:
              </Form.Label>
              <ReactCodeInput
                onChange={validationCodeChange}
                loading={validateLoading}
                disabled={validateLoading}
                fieldWidth="16%"
                className="verification-code-input-v2"
              />
              <div className="edit-screen-button-wrapper">
                {sendAgainSuccess ? (
                  <span className="new-code-sent-text">New code sent</span>
                ) : (
                  <Button
                    className="edit-screen-send-again-button"
                    onClick={sendAgainHandler}
                  >
                    Send again
                  </Button>
                )}
              </div>
            </Form.Group>
          </Form>
          {verifyError && (
            <div className="edit-screen-status-wrapper">
              <i className="far fa-exclamation-circle number-alert-icon" />
              <span>The verification code you entered is incorrect</span>
            </div>
          )}
        </div>
      ) : (
        <div className="desktop-v2-settings-edit-row">
          <Form
            className="desktop-v2-settings-form-edit"
            onSubmit={updateHandler}
          >
            <div className="desktop-v2-settings-form-edit-innerwrap">
              <Form.Group className="desktop-v2-settings-form-group-edit">
                <Form.Label className="desktop-v2-settings-form-label">
                  Enter New Email:
                </Form.Label>
                <InputGroup className="desktop-v2-settings-input-group">
                  <InputGroup.Prepend className="desktop-v2-settings-input-group-prepend">
                    <InputGroup.Text className="desktop-v2-settings-input-group-text">
                      <i className="far fa-envelope verification-form-icon" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="email"
                    className="desktop-v2-settings-form-control-edit"
                    placeholder={email}
                    onChange={changeHandler}
                    ref={inputRef}
                    invalid={emailInvalid}
                    disabled={disabled}
                    value={email}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="desktop-v2-settings-form-group-edit">
                <Form.Label className="desktop-v2-settings-form-label">
                  Confirm Password:
                </Form.Label>
                <InputGroup className="desktop-v2-settings-input-group">
                  <InputGroup.Prepend className="desktop-v2-settings-input-group-prepend">
                    <InputGroup.Text className="desktop-v2-settings-input-group-text">
                      <i className="far fa-key verification-form-icon" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="password"
                    className="desktop-v2-settings-form-control-edit"
                    value={password}
                    disabled={disabled}
                    onChange={passwordChangeHandler}
                    invalid={passwordInvalid}
                  />
                </InputGroup>
              </Form.Group>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "1.5rem",
              }}
            >
              <Button
                type="submit"
                className="desktop-v2-settings-form-edit-submit"
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}

export default EditEmail;
