import React, { useState, useContext, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import "./wallet.scss";
import BottomScrollListener from "react-bottom-scroll-listener";
import diamondIcon from "../../assets/Icons/diamonds_multiple.svg";
import diamondFlatIcon from "../../assets/Icons/diamond-shape.svg";
import PurchaseDiamonds from "./PurchaseDiamonds";
import { UserContext } from "../../context/UserContext";
import WalletHistory from "./WalletHistory";
import RedeemDiamonds from "./RedeemDiamonds";
import { api } from "../../api";

function Wallet(props) {
  const context = useContext(UserContext);

  const history = useHistory();
  const [diamondPackage, setDiamondPackage] = useState(0);
  const [purchaseOpen, setPurchaseOpen] = useState(false);
  const [complimentHistory, setComplimentHistory] = useState([]);
  const [redeemOpen, setRedeemOpen] = useState(false);
  const [scrollTrigger, setScrollTrigger] = useState(false);
  const [femaleBalance, setFemaleBalance] = useState(null);

  useEffect(() => {
    if (props.location.pathname.includes("purchase")) {
      setPurchaseOpen(true);
    } else if (props.location.pathname.includes("redeem")) {
      setRedeemOpen(true);
    } else if (context.user.gender === "female") {
      fetchFemaleCount();
    }
  }, []);

  const fetchFemaleCount = async (e) => {
    const data = {
      userid: context.user._id,
    };

    return api
      .fetchDiamondCount(data)
      .then((res) => {
        if (res.status === 200) {
          setFemaleBalance(res.data.diamondCount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const backHandler = (e) => {
    history.goBack();
  };

  const packageToggle = (props) => (e) => {
    setDiamondPackage(props);
  };

  const handleScroll = (e) => {
    if (scrollTrigger) {
      setScrollTrigger(false);
    } else {
      setScrollTrigger(true);
    }
  };

  const walletCtaHandler = (e) => {
    if (context.user.gender === "male") {
      history.push("/app/wallet/purchase");
    } else {
      history.push("/app/wallet/redeem");
    }
  };

  if (purchaseOpen) {
    return <PurchaseDiamonds />;
  }
  if (redeemOpen) {
    return <RedeemDiamonds />;
  }
  return (
    <div className="wallet-component">
      <div className="wallet-header">
        <div className="wallet-header-left">
          <div className="wallet-return-button" onClick={backHandler}>
            <i className="fas fa-chevron-left" />
          </div>
        </div>
        <div className="wallet-header-middle">
          <h1 className="wallet-header-title">Wallet</h1>
        </div>
      </div>
      <BottomScrollListener onBottom={handleScroll} offset={4000}>
        {(scrollRef) => (
          <div className="wallet-content" ref={scrollRef}>
            <div className="wallet-content-header">
              <div className="wallet-balance-container">
                <div className="wallet-balance-info">
                  <h1 className="wallet-balance-title">Balance:</h1>
                  <div className="wallet-balance-wrapper">
                    <Image
                      src={diamondFlatIcon}
                      className="wallet-balance-icon"
                      fluid
                    />
                    {context.user.gender === "male" ? (
                      <span className="wallet-balance-text">
                        {context.user.inventory.diamonds}
                      </span>
                    ) : (
                      <span className="wallet-balance-text">
                        {femaleBalance}
                      </span>
                    )}
                  </div>
                </div>
                <div className="wallet-balance-cta" onClick={walletCtaHandler}>
                  <div className="wallet-balance-cta-button">
                    <i
                      className={
                        context.user.gender === "male"
                          ? "far fa-plus"
                          : "far fa-exchange"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <WalletHistory scrollTrigger={scrollTrigger} />
          </div>
        )}
      </BottomScrollListener>

      <div className="wallet-footer">
        {context.user.gender === "male" ? (
          <div
            variant="danger"
            className="wallet-footer-button"
            onClick={() => {
              history.push("/app/wallet/purchase");
            }}
          >
            Buy Diamonds
          </div>
        ) : (
          <div
            variant="danger"
            className="wallet-footer-button"
            onClick={() => {
              history.push("/app/wallet/redeem");
            }}
          >
            Redeem Diamonds
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(Wallet);
