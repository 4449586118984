import React, { useState, useEffect, useContext, useRef } from 'react';
import { UserContext } from '../../../../context/UserContext';
import { api, socket } from '../../../../api';
import './desktopmessagesv2.scss';
import Chat from '../../../chat/Chat';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { Image, Transformation } from 'cloudinary-react';
import { useHistory, withRouter } from 'react-router-dom';
import {
  Image as BootstrapImage,
  Button,
  Form,
  InputGroup,
  FormControl,
} from 'react-bootstrap';
import verifiedIcon from '../../../../assets/Icons/badge_verification.svg';
import Message from '../../../chat/chatComponents/message/Message';
import NoMessagesMatch from '../../../chat/chatComponents/noMessagesMatch/NoMessagesMatch';
import ComplimentModal from '../../../modals/complimentModal/ComplimentModal';
import ClipLoader from 'react-spinners/ClipLoader';
import PremiumModal from '../../../modals/premiumModal/PremiumModal';
import Media from '../../../../components/media/Media';
import VerificationModal from '../../../modals/verificationModal/VerificationModal';

const moment = require('moment');

function DesktopChat(props) {
  const context = useContext(UserContext);
  const history = useHistory();
  const [skip, setSkip] = useState(0);
  const [safetyTipOpen, setSafetyTipOpen] = useState(false);
  const [endOfMessages, setEndOfMessages] = useState(false);
  const [card, setCard] = useState(null);
  const [matchData, setMatchData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [photoCount, setPhotoCount] = useState(0);
  const [sendMessage, setSendMessage] = useState('');
  const [chatDisabled, setChatDisabled] = useState(true);
  const [disabledSendButton, setDisabledSendButton] = useState(true);
  const [complimentModalOpen, setComplimentModalOpen] = useState(false);
  const [superlikeModalOpen, setSuperlikeModalOpen] = useState(false);
  const [roomData, setRoomData] = useState({});
  const [isSpam, setIsSpam] = useState(false);
  const [spamTimeout, setSpamTimeout] = useState(null);
  const [spamDescription, setSpamDescription] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [messaging, setMessaging] = useState(false);
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);

  const moment = require('moment');
  const bottomRef = useRef(null);

  useEffect(() => {
    if (props.chatid) {
      fetchConversation();
    }

    return () => {
      socket.offNewMessage(context.user.username);
      socket.offReadMessagesSuccess(context.user._id);
    };
  }, [props.chatid]);
  useEffect(() => {
    const chatDisabled = true;

    if (context.user.premium) {
      setChatDisabled(false);
    } else if (
      context.user.gender === 'female' &&
      context.user.verifications.email.status &&
      context.user.verifications.phone.status
    ) {
      setChatDisabled(false);
    }
  }, []);

  useEffect(() => {
    if (skip !== 0) {
      fetchConversation();
    }
  }, [skip]);

  useEffect(() => {
    if (!loading && messages.length > 0) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const socketOn = () => {
    // subscribe to chat socket
    socket.onNewMessage(context.user.username, (newMessage, warning) => {
      if (newMessage.rejectVideoCall) {
        setRoomData({
          rejected: true,
        });
      }
      if (newMessage.banned) {
        // setIsBanned(true);
      } else if (newMessage.spam || newMessage.muted) {
        const description = newMessage.spam
          ? 'Your message was detected as spam and so lock messaging, please wait for a while.'
          : 'Your message contains unacceptable content and so lock messaging, please wait for a while.';
        setSpamDescription(description);
        setIsSpam(true);
        setSpamTimeout(newMessage.timeout);
      } else if (warning) {
        setShowWarning(true);
      }
      if (
        newMessage.senderusername === context.user.username ||
        newMessage.sender === props.chatid
      ) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setMessaging(true);
        // Mark message as read because the user is currently active
        readMessages();
      }
    });

    // subscribe to read messages socket
    socket.onReadMessagesSuccess(context.user._id, ({ receiverId }) => {
      if (receiverId === props.chatid) {
        setMessages((messages) => {
          const messagesRead = messages.map((message) => ({
            ...message,
            isRead: true,
          }));
          return messagesRead;
        });
      }
    });
  };

  const readMessages = async (e) => {
    const data = {
      senderId: props.chatid,
      receiverId: context.user._id,
    };

    socket.readMessages(data);
  };

  const toggleComplimentModal = (e) => {
    if (complimentModalOpen) {
      setComplimentModalOpen(false);
    } else {
      setComplimentModalOpen(true);
    }
  };

  const toggleSuperlikeModal = (e) => {
    if (superlikeModalOpen) {
      setSuperlikeModalOpen(false);
    } else if (!chatDisabled) {
      setSuperlikeModalOpen(true);
    }
  };

  const submitMessageHandler = (e) => {
    e.preventDefault();

    if (!disabledSendButton) {
      setDisabledSendButton(true);
      const data = {
        sender: context.user.username,
        receiver: card.username,
        message: sendMessage,
      };
      socket.sendMessage(data);
      context.updateMessageData(props.chatid, sendMessage, 'send');
      setSendMessage('');
    }

    setMessaging(true);
  };
  const messageAbilityCheck = (e) => {
    if (chatDisabled) {
      if (context.user.gender === 'male') {
        setPremiumModalOpen(true);
      } else {
        setVerificationModalOpen(true);
      }
    }
  };
  const sendMessageChangeHandler = (e) => {
    setSendMessage(e.target.value);

    if (e.target.value.length > 0) {
      setDisabledSendButton(false);
    } else {
      setDisabledSendButton(true);
    }
  };

  const imageClickHandler = (e) => {
    const url = `/app/member/${props.chatid}`;
    history.push(url);
  };

  const fetchConversation = async (e) => {
    const data = {
      userid: context.user._id,
      chatid: props.chatid,
      skip,
    };

    return api
      .fetchMessages(data)
      .then((res) => {
        if (res.status === 200) {
          const messageData = res.data.messages;
          const cardData = res.data.card;
          const userData = res.data.user;
          const matchDataTemp = res.data.match;

          if (userData.alerts.safetyTipOne) {
            setSafetyTipOpen(true);
          }
          if (!messageData && messageData.length < 15) {
            setEndOfMessages(true);
          }

          const tempMessages = messages;
          const newMessages = tempMessages.concat(messageData);

          if (
            cardData.verifications.phone.status &&
            cardData.verifications.email.status
          ) {
            setIsVerified(true);
          }

          if (cardData.imgurl) {
            if (cardData.imgurl.length > 0) {
              setMainImage(cardData.imgurl[0]);
              setPhotoCount(cardData.imgurl.length);
            }
          }

          setCard(cardData);
          setMessages(newMessages);
          setMatchData(matchDataTemp);
          setLoading(false);
          readMessages();
          if (skip === 0) {
            socketOn();
          }
          bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (messaging && messages.length > 0) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      // props.checkMessaging();
    }
  }, [messaging]);

  const matchText = 'You matched text ago';

  if (props.chatid === null) {
    return (
      <div className="desktop-chat-v2-none">
        <h1 className="desktop-chat-select-convo-text">
          Select a conversation
        </h1>
        <p className="desktop-chat-select-convo-subtitle">
          Choose a conversation from your inbox
        </p>
      </div>
    );
  }

  return (
    <div className="desktop-chat-v2">
      {complimentModalOpen && (
        <ComplimentModal
          show={complimentModalOpen}
          modalToggle={toggleComplimentModal}
          profileUser={card}
        />
      )}
      {verificationModalOpen && (
        <VerificationModal
          show={verificationModalOpen}
          onClose={() => setVerificationModalOpen(false)}
          profileUser={card}
        />
      )}
      {premiumModalOpen && (
        <PremiumModal
          option="messages"
          show={premiumModalOpen}
          onClose={() => setPremiumModalOpen(false)}
        />
      )}
      {loading ? (
        <div
          className="desktop-chat-v2-loading-indicator"
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ClipLoader size="18px" color="#b5b5b5" />
        </div>
      ) : (
        <>
          <div className="desktop-chat-v2-header">
            <div className="desktop-chat-v2-image-wrapper">
              <Media image={mainImage} />
              {/* <Image
                publicId={
                  mainImage
                    ? getImgName(mainImage)
                    : "placeholder-user-image-thumbnail_vankru.png"
                }
                version={mainImage ? getImgVersion(mainImage) : "1590045309"}
                resource_type={
                  mainImage ? (checkGIF(mainImage) ? "video" : "image") : null
                }
                cloud_name="sugarbae"
                className="desktop-chat-v2-image"
                fluid
                onClick={imageClickHandler}
              >
                <Transformation
                  height="200"
                  video_sampling="20"
                  delay="250"
                  duration="10"
                  effect="loop"
                  quality="auto"
                  flags="lossy"
                />
              </Image> */}
            </div>
            <div className="desktop-chat-v2-user-info-wrapper">
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {isVerified && (
                    <BootstrapImage
                      src={verifiedIcon}
                      className="desktop-chat-verified-icon"
                      fluid
                    />
                  )}
                  <h1 className="desktop-chat-v2-username">
                    {card.username}{' '}
                    <span className="desktop-chat-v2-age">{card.age}</span>
                  </h1>
                </div>
                <div>
                  <span className="desktop-chat-v2-photo-count">
                    {photoCount} photos
                  </span>
                </div>
              </div>

              <div className="desktop-chat-v2-match-text">{matchText}</div>
            </div>
            <div className="desktop-chat-v2-actions-wrapper">
              <div className="desktop-chat-v2-menu-button">
                <i className="far fa-ellipsis-h" />
              </div>
            </div>
          </div>
          <div className="desktop-chat-v2-convo-wrapper">
            <>
              {messages.length < 1 ? (
                <div className="desktop-chat-v2-no-messages-wrapper">
                  <NoMessagesMatch
                    chatid={props.chatid}
                    match={matchData}
                    toggleComplimentModal={toggleComplimentModal}
                  />
                </div>
              ) : (
                <div className="desktop-chat-v2-convo">
                  <div className="desktop-chat-top-spacer" />
                  {messages.map((message, index) => {
                    let consecutive = false;
                    let timeStamp = false;
                    let matchStamp = false;
                    let matchDate = '';
                    const { isRead } = message;
                    let isLast = false;
                    let showReadReceipt = false;
                    const adjustedIndex = index + 1;
                    let received = false;

                    if (message.sender === context.user._id) {
                      received = false;
                    } else {
                      received = true;
                    }

                    if (messages.length === adjustedIndex) {
                      isLast = true;
                    }

                    if (
                      isLast &&
                      message.sender.toString() === context.user._id.toString()
                    ) {
                      showReadReceipt = true;
                    }

                    if (index > 0) {
                      const previousIndex = index - 1;
                      const previousMessage = messages[previousIndex];
                      const date = moment(message.createdAt);
                      const previousMessageTime = moment(
                        previousMessage.createdAt
                      );
                      const difference = date.diff(
                        previousMessageTime,
                        'hours'
                      );

                      if (difference > 5) {
                        timeStamp = true;
                      }

                      if (previousMessage.sender === message.sender) {
                        consecutive = true;
                      }
                    } else if (matchData === null || matchData === undefined) {
                      matchStamp = false;
                      matchDate = '';
                    } else {
                      matchStamp = true;
                      matchDate = moment(matchData.date).calendar();
                    }

                    const data = {
                      consecutive,
                      timeStamp,
                      matchStamp,
                      matchDate,
                      isRead,
                      isLast,
                      showReadReceipt,
                      received,
                    };

                    return (
                      <Message
                        message={message}
                        data={data}
                        user={context.user}
                        card={card}
                      />
                    );
                  })}
                </div>
              )}
              <div className="desktop-chat-bottom-spacer" />
              <div ref={bottomRef} />
            </>
          </div>
          <div className="desktop-chat-v2-input">
            <Form
              onSubmit={submitMessageHandler}
              onClick={messageAbilityCheck}
              className="desktop-chat-v2-form"
            >
              <InputGroup className="desktop-chat-v2-inputgroup">
                <FormControl
                  placeholder="Type a message"
                  className="desktop-chat-v2-form-control"
                  onChange={sendMessageChangeHandler}
                  value={sendMessage}
                  disabled={chatDisabled}
                />

                <InputGroup.Append>
                  {context.user.gender === 'male' && (
                    <Button
                      variant="link"
                      onClick={toggleComplimentModal}
                      className="chat-v2-compliment-btn"
                      // disabled={disabledSendButton}
                    >
                      <i className="far fa-gift compliment-btn-icon" />
                    </Button>
                  )}

                  <Button
                    variant="primary"
                    type="submit"
                    className={
                      disabledSendButton
                        ? 'chat-v2-send-btn-disabled'
                        : 'chat-v2-send-btn'
                    }
                    disabled={disabledSendButton}
                  >
                    <i className="far fa-paper-plane" />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          </div>
        </>
      )}
    </div>
  );
}

export default withRouter(DesktopChat);
