import React from "react";
import { Button, Image } from "react-bootstrap";
import "./headerstyles.scss";
import logo from "../../assets/logos/sugarbae-home-icon.png";
import { Link } from "react-router-dom";

function ProfileHeader(props) {
  const toggleSettings = (e) => {
    props.toggleSettings();
  };

  const toggleEditProfile = (e) => {
    props.toggleEditProfile();
  };

  return (
    <div className="header-container">
      <div className="header-section-left">
        <Link to="/app/profile/settings">
          <Button className="header-button">
            <i className="far fa-cog header-settings-icon"></i>
          </Button>
        </Link>
      </div>
      <div className="header-section-center">
        <Image src={logo} className="header-logo" />
      </div>
      <div className="header-section-right">
        <Link to="/app/profile/edit">
          <Button className="header-button">
            <i className="far fa-pen header-edit-icon"></i>
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default ProfileHeader;
