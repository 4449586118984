import React, { Component } from "react";
import Benefits from "../../../components/welcome/benefits/Benefits";

export class BenefitsPage extends Component {
  render() {
    let vh = window.innerHeight;
    var height = vh + "px";
    return (
      <div
        className="app-component"
        style={{ height: height, maxHeight: height }}
      >
        {" "}
        <Benefits />
      </div>
    );
  }
}

export default BenefitsPage;
