/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import DesktopNavV2 from "../desktopNavV2/DesktopNavV2";
import DesktopSidebarV2 from "../desktopSidebarV2/DesktopSidebarV2";
import "../desktopvsstyles.scss";
import { withRouter, useHistory } from "react-router-dom";
import AccountSettings from "./AccountSettings";
import Community from "./Community";
import Notifications from "./Notifications";
import Wallet from "./Wallet";
import ManageSubscription from "./ManageSubscription";

function DesktopSettingsV2(props) {
  const history = useHistory();
  const [tab, setTab] = useState(props.tab);

  useEffect(() => {
    setTab(props.tab);
  }, [props.tab]);

  let accountTabStyle = "desktop-v2-settings-tab";
  let walletTabStyle = "desktop-v2-settings-tab";
  let notificationsTabStyle = "desktop-v2-settings-tab";
  let communityTabStyle = "desktop-v2-settings-tab";
  let manageSubscriptionTabStyle = "desktop-v2-settings-tab";

  if (tab === "Account Settings") {
    accountTabStyle = "desktop-v2-settings-tab-active";
  } else if (tab === "Notifications") {
    notificationsTabStyle = "desktop-v2-settings-tab-active";
  } else if (tab === "Wallet") {
    walletTabStyle = "desktop-v2-settings-tab-active";
  } else if (tab === "Community") {
    communityTabStyle = "desktop-v2-settings-tab-active";
  } else if (tab === "Manage Subscription") {
    manageSubscriptionTabStyle = "desktop-v2-settings-tab-active";
  }

  return (
    <div className="desktop-v2-container">
      <DesktopSidebarV2 />
      <div className="desktop-v2-swipe-content">
        <DesktopNavV2 />
        <div className="desktop-settings-v2">
          <div className="desktop-v2-settings-tabs">
            <div
              className={accountTabStyle}
              onClick={() => history.push("/app/settings")}
            >
              Account Settings
            </div>
            {/* <div
              className={walletTabStyle}
              onClick={() => history.push("/app/settings/wallet")}
            >
              Wallet
            </div> */}
            <div
              className={notificationsTabStyle}
              onClick={() => history.push("/app/settings/notifications")}
            >
              Notifications
            </div>
            <div
              className={communityTabStyle}
              onClick={() => history.push("/app/settings/community")}
            >
              Community
            </div>
            <div
              className={manageSubscriptionTabStyle}
              onClick={() => history.push("/app/settings/managesubscription")}
            >
              Manage Subscription
            </div>
          </div>
          <div className="desktop-v2-settings-content">
            {tab === "Account Settings" && <AccountSettings />}
            {tab === "Community" && <Community />}
            {tab === "Manage Subscription" && <ManageSubscription />}
            {tab === "Notifications" && <Notifications />}
            {tab === "Wallet" && <Wallet />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(DesktopSettingsV2);
