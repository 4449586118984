import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import BottomScrollListener from "react-bottom-scroll-listener";

import MatchesHeader from "../headers/MatchesHeader";
import Likes from "../matches/matchesComponents/matchesTabs/tabs/Likes";
import Matches from "../matches/matchesComponents/matchesTabs/tabs/Matches";
import { UserContext } from "../../context/UserContext";
import LikesCard from "./matchesComponents/likesCard/LikesCard";
// import MatchCard from "./matchesComponents/matchCard/MatchCard";
import MatchCard from "./matchesComponentsV2/matchCard/MatchCard";
import { ViewLikes } from "../modals/viewLikes/ViewLikes";
import Boost from "./matchesComponentsV2/boost/Boost";
import ProfileV2 from "../profile/ProfileV2";

import "./matchesv2.scss";

function MatchesV2(props) {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [viewLikesModalOpen, setViewLikesModalOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [tab, setTab] = useState("likes");
  const [showProfile, setShowProfile] = useState(false);
  const [showUser, setShowUser] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (fetching && tab === "likes") {
      context.updateLikesAsRead();
    }
    setFetching(false);
  }, [context.likes]);

  const tabHandler = (props) => (e) => {
    setTab(props);
  };

  useEffect(() => {
    if (fetching && tab === "matches") {
      context.updateMatchesAsRead();
    }
    setFetching(false);
  }, [context.matches]);

  useEffect(() => {
    if (tab === "matches") {
      context.updateMatchesAsRead();
    } else if (tab === "likes") {
      context.updateLikesAsRead();
    }
  },[tab]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  },[refresh]);

  const handleScrollMatches = (e) => {
    if (!fetching && !context.maxMatches) {
      setFetching(true);
      context.checkMoreMatches();
    }
  };

  const handleScrollLikes = (e) => {
    if (!fetching && !context.maxLikes) {
      setFetching(true);
      context.checkMoreLikes();
    }
  };

  const likeHandler = (cardid) => {
    const temp = context.likes;
    temp.splice(cardid, 1);
    context.updateLikes(temp);
    setRefresh(true);
  };

  const dislikeHandler = (cardid) => {
    const temp = context.likes;
    temp.splice(cardid, 1);
    context.updateLikes(temp);
    setRefresh(true);
  };

  const toggleViewLikesModal = (e) => {
    if (viewLikesModalOpen) {
      setViewLikesModalOpen(false);
    } else {
      setViewLikesModalOpen(true);
    }
  };

  const toggleProfile = (user) => {
    setShowUser(user);
    setShowProfile(true);
  };

  const closeProfile = () => {
    setShowProfile(false);
    setShowUser(null);
  };

  return (
    <div className="matches-v2-component">
      {viewLikesModalOpen ? (
        <ViewLikes closeHandler={toggleViewLikesModal} />
      ) : null}
      {showProfile && (
        <ProfileV2
          profileUser={showUser}
          toggleProfile={closeProfile}
          hiddenAction={true}
        />
      )}
      <MatchesHeader />
      <div className="matches-v2-tabs">
        <div
          className={
            tab === "likes" ? "matches-v2-tab-active" : "matches-v2-tab"
          }
          onClick={tabHandler("likes")}
        >
          Likes
        </div>
        <div
          className={
            tab === "matches" ? "matches-v2-tab-active" : "matches-v2-tab"
          }
          onClick={tabHandler("matches")}
        >
          Matches
        </div>
      </div>
      {tab === "likes" && (
        <BottomScrollListener onBottom={handleScrollLikes} offset="1500">
          {(scrollRefLikes) => (
            <>
              {context.likes && context.likes.length > 0 ? (
                <div className="matches-v2-content-likes" ref={scrollRefLikes}>
                  {context.likes.map((like, index) => {
                    return (
                      <LikesCard
                        index={index}
                        like={like}
                        premium={context.user.premium}
                        likeHandler={likeHandler}
                        dislikeHandler={dislikeHandler}
                        viewLikesModalToggle={toggleViewLikesModal}
                        profileToggle={toggleProfile}
                      />
                    );
                  })}
                  {context.maxLikes && (
                    <div className="end-indicator">No more Likes.</div>
                  )}
                </div>
              ) : (
                <div ref={scrollRefLikes}>
                  <Boost
                    option="likes"
                    handleBoost={props.handleBoost}
                  />
                </div>
              )}
            </>
          )}
        </BottomScrollListener>
      )}
      {tab === "matches" && (
        <BottomScrollListener onBottom={handleScrollMatches} offset="1500">
          {(scrollRefMatches) => (
            <>
              {context.matches && context.matches.length > 0 ? (
                <div className="matches-v2-content-matches" ref={scrollRefMatches}>
                  {context.matches.map((match, index) => {
                    return (
                      <MatchCard
                        index={index}
                        card={match}
                        profileToggle={toggleProfile}
                      />
                    );
                  })}
                  {context.maxMatches && (
                    <div className="end-indicator">No more matches.</div>
                  )}
                </div>
              ) : (
                <div ref={scrollRefMatches}>
                  <Boost
                    option="matches"
                    handleBoost={props.handleBoost}
                  />
                </div>
              )}
            </>
          )}
        </BottomScrollListener>
      )}
    </div>
  );
}

export default withRouter(MatchesV2);
