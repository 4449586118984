import React, { Component } from "react";
import { Card, Image, Button } from "react-bootstrap";
import "./indicator.scss";
import boltGif from "../../../../../../assets/Images/matches/bolt-icon.gif";
import { submitBoost } from "../../../../../../helpers/swipeActions";
import BoostPop from "../../../../../swipe/swipeComponents/swipePops/BoostPop";
import BoostAlreadyActivePop from "../../../../../swipe/swipeComponents/swipePops/BoostAlreadyActivePop";
import BoostActivePop from "../../../../../swipe/swipeComponents/swipePops/BoostActivePop";
import { UserContext } from "../../../../../../context/UserContext";

export class NoLikesIndicator extends Component {
  static contextType = UserContext;

  state = {
    boostActivePop: false,
    boostInvalid: false,
    boostAlreadyActivePop: false,
  };

  handleBoost = async (e) => {
    var userid = this.context.user._id;
    const boost = await submitBoost(userid).catch((err) => {
      console.log(err);
    });
    var valid = boost.valid;
    var active = boost.active;
    if (valid) {
      this.setState({ boostActivePop: true });
      this.props.handleBoost();
    } else {
      if (active) {
        this.setState({ boostAlreadyActivePop: true });
      } else {
        this.setState({ boostInvalid: true });
      }
    }
  };

  popCloseHandler = (e) => {
    this.setState({
      boostActivePop: false,
      boostInvalid: false,
      boostAlreadyActivePop: false,
    });
  };
  render() {
    return (
      <Card className="tab-indicator-card">
        {this.state.boostInvalid ? (
          <BoostPop closeHandler={this.popCloseHandler} />
        ) : null}
        {this.state.boostActivePop ? (
          <BoostActivePop closeHandler={this.popCloseHandler} />
        ) : null}
        {this.state.boostAlreadyActivePop ? (
          <BoostAlreadyActivePop closeHandler={this.popCloseHandler} />
        ) : null}
        <Card.Body className="tab-indicator-card-body">
          <Image src={boltGif} className="no-matches-indicator-rocket-gif" />
          <h1 className="no-matches-title">Get More Likes</h1>
          <p className="tab-indicator-text">
            Boost your profile to get more likes.
          </p>
          <Button
            className="no-matches-boost-button"
            size="lg"
            onClick={this.handleBoost}
          >
            <i className="fas fa-bolt no-matches-bolt-icon"></i>
            Boost Your Profile
          </Button>
        </Card.Body>
      </Card>
    );
  }
}

export default NoLikesIndicator;
