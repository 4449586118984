/* eslint-disable no-else-return */
import React, { useState, useContext, useEffect } from 'react';
import './chat.scss';
import { Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import ClipLoader from 'react-spinners/ClipLoader';
import { withRouter, useHistory } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { api, socket } from '../../api';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../utils/getCloudinaryInfo';
import MessageFeed from './chatComponents/messageFeed/MessageFeed';
import VideoRoom from '../videoRoom/VideoRoom';
import ProfileV2 from '../profile/ProfileV2';
import NoMessagesMatch from './chatComponents/noMessagesMatch/NoMessagesMatch';
import ComplimentModal from '../modals/complimentModal/ComplimentModal';
import VerificationModal from '../modals/verificationModal/VerificationModal';
import PremiumModal from '../modals/premiumModal/PremiumModal';
import Media from '../../components/media/Media';

const moment = require('moment');

function Chat(props) {
  const { chatid } = props;
  const context = useContext(UserContext);
  const [card, setCard] = useState(null);
  const [user, setUser] = useState(null);
  const [match, setMatch] = useState(null);
  const [messages, setMessages] = useState([]);
  const [skip, setSkip] = useState(0);
  const [endOfMessages, setEndOfMessages] = useState(false);
  const [loading, setLoading] = useState(true);
  const [profileUser, setProfileUser] = useState(null);
  const [profileOpen, setProfileOpen] = useState(false);
  const [sendMessage, setSendMessage] = useState(null);
  const [disabledSendButton, setDisabledSendButton] = useState(true);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [messagesModalOpen, setMessagesModalOpen] = useState(false);
  const [safetyModalOpen, setSafetyModalOpen] = useState(false);
  const [safetyTipOpen, setSafetyTipOpen] = useState(false);
  const [isSpam, setIsSpam] = useState(false);
  const [spamTimeout, setSpamTimeout] = useState(null);
  const [isBanned, setIsBanned] = useState(false);
  const [spamDescription, setSpamDescription] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [previousIndex, setPreviousIndex] = useState(0);
  const [messaging, setMessaging] = useState(false);
  const [showVideoRoom, setShowVideoRoom] = useState(false);
  const [roomData, setRoomData] = useState({});
  const [fetching, setFetching] = useState(false);
  const [scrollDetected, setScrollDetected] = useState(false);
  const [complimentModalOpen, setComplimentModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);

  const [chatDisabled, setChatDisabled] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const chatDisabled = true;

    if (context.user.premium) {
      setChatDisabled(false);
    } else if (
      context.user.gender === 'female' &&
      context.user.verifications.email.status &&
      context.user.verifications.phone.status
    ) {
      setChatDisabled(false);
    }
  }, []);

  useEffect(() => {
    // fetch message data
    fetchData();
    context.updateMessageData(chatid, '', 'read');
    // unsubscribe from chat socket
    return () => {
      socket.offNewMessage(context.user.username);
      socket.offReadMessagesSuccess(context.user._id);
    };
  }, [chatid]);

  useEffect(() => {
    if (skip !== 0) {
      fetchData();
    }
  }, [skip]);

  const reportHandler = (e) => {
    setMenuOpen(false);
    setReportOpen(true);
  };

  const unmatchHandler = (e) => {
    setMenuOpen(false);
  };

  const menuToggle = (e) => {
    if (menuOpen) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }
  };

  const toggleComplimentModal = (e) => {
    if (complimentModalOpen) {
      setComplimentModalOpen(false);
    } else {
      setComplimentModalOpen(true);
    }
  };

  const fetchData = () => {
    const data = {
      userid: context.user._id,
      chatid,
      skip,
    };

    return api
      .fetchMessages(data)
      .then((res) => {
        if (res.status === 200) {
          const messageData = res.data.messages;
          const cardData = res.data.card;
          const userData = res.data.user;
          const matchData = res.data.match;

          if (userData.alerts.safetyTipOne) {
            setSafetyTipOpen(true);
          }

          if (!messageData && messageData.length < 15) {
            setEndOfMessages(true);
          }

          if (skip > 14) {
            setPreviousIndex(messageData.length);
          }

          const temp = messageData.concat(messages);
          setCard(cardData);
          setUser(userData);
          setMatch(matchData);
          setMessages(temp);
          setLoading(false);
          readMessages();
          if (skip === 0) {
            socketOn();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const videoCallHandler = async () => {
    const data = {
      receiver: card,
    };
    setRoomData(data);
    setShowVideoRoom(true);
  };

  const closeVideoRoom = () => {
    setShowVideoRoom(false);
    setRoomData({});
  };

  useEffect(() => {
    if (isBanned) {
      setTimeout(() => {
        localStorage.removeItem('auth');
        props.history.push('/');
      }, 5000);
    }
  }, [isBanned]);

  const socketOn = () => {
    // subscribe to chat socket
    socket.onNewMessage(context.user.username, (newMessage, warning) => {
      if (newMessage.rejectVideoCall) {
        setRoomData({
          rejected: true,
        });
      }
      if (newMessage.banned) {
        setIsBanned(true);
      } else if (newMessage.spam || newMessage.muted) {
        const description = newMessage.spam
          ? 'Your message was detected as spam and so lock messaging, please wait for a while.'
          : 'Your message contains unacceptable content and so lock messaging, please wait for a while.';
        setSpamDescription(description);
        setIsSpam(true);
        setSpamTimeout(newMessage.timeout);
      } else if (warning) {
        setShowWarning(true);
      }
      if (
        newMessage.senderusername === context.user.username ||
        newMessage.sender === chatid
      ) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setMessaging(true);
        // Mark message as read because the user is currently active
        readMessages();
      }
    });

    // subscribe to read messages socket
    socket.onReadMessagesSuccess(context.user._id, ({ receiverId }) => {
      if (receiverId === chatid) {
        setMessages((messages) => {
          const messagesRead = messages.map((message) => ({
            ...message,
            isRead: true,
          }));
          return messagesRead;
        });
      }
    });
  };

  const readMessages = async (e) => {
    const data = {
      senderId: chatid,
      receiverId: context.user._id,
    };

    socket.readMessages(data);
  };

  const closeProfileHandler = (e) => {
    setProfileOpen(false);
  };
  const imageClickHandler = (e) => {
    setProfileOpen(true);
  };
  const safetyModalToggle = (e) => {
    if (safetyModalOpen) {
      setSafetyModalOpen(false);
    } else {
      setSafetyModalOpen(true);
    }
  };

  const messageAbilityCheck = (e) => {
    if (chatDisabled) {
      if (context.user.gender === 'male') {
        setPremiumModalOpen(true);
      } else {
        setVerificationModalOpen(true);
      }
    }
  };

  const sendMessageChangeHandler = (e) => {
    setSendMessage(e.target.value);

    if (e.target.value.length > 0) {
      setDisabledSendButton(false);
    } else {
      setDisabledSendButton(true);
    }
  };

  const submitMessageHandler = async (e) => {
    e.preventDefault();

    if (!disabledSendButton) {
      setDisabledSendButton(true);
      const data = {
        sender: context.user.username,
        receiver: card.username,
        message: sendMessage,
      };
      socket.sendMessage(data);
      context.updateMessageData(props.chatid, sendMessage, 'send');
      setSendMessage('');
    }

    setMessaging(true);
  };

  const closeModalsHandler = (e) => {
    setMessagesModalOpen(false);
    setIsSpam(false);
    setVerificationModalOpen(false);
    setShowWarning(false);
  };

  const closeSafetyTipHandler = async (e) => {
    setSafetyTipOpen(false);

    const data = {
      userid: context.user._id,
    };

    await api
      .closeSafetyTip(data)
      .then((res) => {})
      .catch({});
  };

  const fetchMore = () => {
    setSkip(skip + 15);
  };

  const scrollHandler = () => {
    const div = document.getElementById('chat-v2-content');
    if (div.scrollTop === 0 && endOfMessages && !fetching) {
      setFetching(true);
      fetchMore();
    }

    if (div.scrollTop > 5) {
      setScrollDetected(true);
    } else {
      setScrollDetected(false);
    }
  };

  const backHandler = (e) => {
    history.goBack();
    // props.onClose();
  };

  const verificationHandler = (e) => {
    if (chatDisabled) {
      setVerificationModalOpen(true);
    } else {
      setVerificationModalOpen(false);
    }
  };

  const toggleVerificationModal = (e) => {
    if (verificationModalOpen) {
      setVerificationModalOpen(false);
    } else {
      setVerificationModalOpen(true);
    }
  };

  let mainImage = null;
  let verified = false;

  if (card) {
    var images = card.imgurl;
    const { verifications } = card;

    if (images) {
      if (images.length > 0) {
        mainImage = images[0];
      }
    }

    if (verifications) {
      if (verifications.email.status && verifications.phone.status) {
        verified = true;
      }
    }
  }

  let matchText = '';
  let isMatched = false;
  if (match) {
    isMatched = true;
    const date = moment(match.date);

    let difference = moment().diff(date, 'days');
    matchText = `${difference} days ago`;

    if (difference < 1) {
      difference = moment().diff(date, 'hours');
      matchText = `${difference} hours ago`;
    }

    if (difference < 1) {
      difference = moment().diff(date, 'minutes');
      matchText = `${difference} minutes ago`;
    }

    if (difference < 1) {
      difference = moment().diff(date, 'seconds');
      matchText = `${difference} seconds ago`;
    }
  }

  if (profileOpen) {
    return <ProfileV2 toggleProfile={closeProfileHandler} profileUser={card} />;
  }

  if (!card || loading) {
    return (
      <div className="chat-v2-component">
        <div className="chat-v2-header">
          <div className="chat-v2-header-section-left">
            <Button
              className="chat-v2-header-return-button"
              onClick={backHandler}
            >
              <i className="fas fa-chevron-left" />
            </Button>
          </div>
        </div>
        <div className="chat-v2-content">
          <div className="chat-v2-user-info-section">
            <div className="chat-v2-user-info-image-wrapper" />
            <div className="chat-v2-user-info-content-wrapper">
              <div className="chat-v2-user-info-content-top">
                <div className="chat-v2-user-info-username-age-wrapper">
                  <h2 className="chat-v2-user-info-username">
                    <span className="chat-v2-user-info-age" />
                  </h2>
                </div>
                <div className="chat-v2-photo-count-wrapper">
                  <h3 className="chat-v2-photo-count-text" />
                </div>
              </div>
              <div className="chat-v2-user-info-content-bottom">
                <p className="chat-v2-user-info-bottom-text" />
              </div>
            </div>
          </div>
          <div className="chat-v2-loading-wrapper">
            <ClipLoader loading={loading} />
          </div>
        </div>
        <div className="chat-v2-footer" />
      </div>
    );
  } else {
    return (
      <div className="chat-v2-component">
        {complimentModalOpen && (
          <ComplimentModal
            show={complimentModalOpen}
            modalToggle={toggleComplimentModal}
            profileUser={card}
          />
        )}
        {verificationModalOpen && (
          <VerificationModal
            show={verificationModalOpen}
            onClose={toggleVerificationModal}
            profileUser={card}
          />
        )}
        {showVideoRoom && (
          <VideoRoom roomData={roomData} endHandler={closeVideoRoom} />
        )}
        {premiumModalOpen && (
          <PremiumModal
            option="messages"
            show={premiumModalOpen}
            onClose={() => setPremiumModalOpen(false)}
          />
        )}
        <div
          className={
            scrollDetected ? 'chat-v2-header-scroll' : 'chat-v2-header'
          }
        >
          <div className="chat-v2-header-section-left">
            <Button
              className="chat-v2-header-return-button"
              onClick={backHandler}
            >
              <i className="fas fa-chevron-left" />
            </Button>
            {scrollDetected && (
              <div className="chat-v2-header-user-info">
                <div
                  className="chat-v2-header-user-info-image-wrapper"
                  onClick={imageClickHandler}
                >
                  <Media image={mainImage} />

                  {/* <Image
                    publicId={
                      mainImage
                        ? getImgName(mainImage)
                        : "placeholder-user-image-thumbnail_vankru.png"
                    }
                    version={
                      mainImage ? getImgVersion(mainImage) : "1590045309"
                    }
                    resource_type={
                      mainImage
                        ? checkGIF(mainImage)
                          ? "video"
                          : "image"
                        : null
                    }
                    cloud_name="sugarbae"
                    className="chat-v2-header-user-info-image"
                    fluid
                    onClick={imageClickHandler}
                  >
                    <Transformation
                      height="200"
                      video_sampling="20"
                      delay="250"
                      duration="10"
                      effect="loop"
                      quality="auto"
                      flags="lossy"
                    />
                  </Image> */}
                </div>
                <div className="chat-v2-header-user-info-username-wrapper">
                  <h4 className="chat-v2-header-user-info-username-text">
                    {card.username}
                  </h4>
                </div>
              </div>
            )}
          </div>
          <div className="chat-v2-header-section-right">
            {/* <Button
              className="chat-v2-header-call-button"
              onClick={() => videoCallHandler()}
            >
              <i className="fas fa-phone-alt"></i>
            </Button> */}
            {menuOpen && (
              <div className="chat-v2-menu">
                <div className="chat-v2-menu-item" onClick={reportHandler}>
                  Report User
                </div>
                <div className="chat-v2-menu-item" onClick={unmatchHandler}>
                  Unmatch
                </div>
              </div>
            )}

            <div className="chat-v2-header-menu-button" onClick={menuToggle}>
              <i className="far fa-ellipsis-h" />
            </div>
          </div>
        </div>
        <div
          className="chat-v2-content"
          id="chat-v2-content"
          onScroll={scrollHandler}
        >
          <div className="chat-v2-user-info-section">
            <div
              className="chat-v2-user-info-image-wrapper"
              onClick={imageClickHandler}
            >
              <Media image={mainImage} />
              {/* <Image
                publicId={
                  mainImage
                    ? getImgName(mainImage)
                    : 'placeholder-user-image-thumbnail_vankru.png'
                }
                version={mainImage ? getImgVersion(mainImage) : '1590045309'}
                resource_type={
                  mainImage ? (checkGIF(mainImage) ? 'video' : 'image') : null
                }
                cloud_name="sugarbae"
                className="chat-v2-user-info-image"
                fluid
                onClick={imageClickHandler}
              >
                <Transformation
                  height="200"
                  video_sampling="20"
                  delay="250"
                  duration="10"
                  effect="loop"
                  quality="auto"
                  flags="lossy"
                />
              </Image> */}
            </div>
            <div className="chat-v2-user-info-content-wrapper">
              <div className="chat-v2-user-info-content-top">
                <div className="chat-v2-user-info-username-age-wrapper">
                  {verified && (
                    <i className="fad fa-badge-check chat-v2-user-info-verification-icon" />
                  )}
                  <h2 className="chat-v2-user-info-username">
                    {card.username},{' '}
                    <span className="chat-v2-user-info-age">{card.age}</span>
                  </h2>
                </div>
                <div className="chat-v2-photo-count-wrapper">
                  <h3 className="chat-v2-photo-count-text">
                    {images.length} photos
                  </h3>
                </div>
              </div>
              <div className="chat-v2-user-info-content-bottom">
                <p className="chat-v2-user-info-bottom-text">
                  You matched {matchText}.
                </p>
              </div>
            </div>
          </div>
          {messages.length > 0 ? (
            <MessageFeed
              messages={messages}
              user={user}
              card={card}
              match={match}
              chatid={chatid}
              fetchMore={fetchMore}
              fetching={fetching}
              endOfMessages={endOfMessages}
              previousIndex={previousIndex}
              messaging={messaging}
              checkMessaging={() => setMessaging(false)}
            />
          ) : (
            <NoMessagesMatch
              chatid={chatid}
              match={match}
              toggleComplimentModal={toggleComplimentModal}
            />
          )}
        </div>
        <div className="chat-v2-footer">
          <Form
            onSubmit={submitMessageHandler}
            className="chat-v2-form"
            onClick={messageAbilityCheck}
          >
            <InputGroup className="chat-v2-inputgroup">
              <FormControl
                placeholder="Type a message"
                className="chat-v2-input"
                onChange={sendMessageChangeHandler}
                value={sendMessage}
                disabled={chatDisabled}
              />

              <InputGroup.Append>
                {context.user.gender === 'male' && (
                  <Button
                    variant="link"
                    onClick={toggleComplimentModal}
                    className="chat-v2-compliment-btn"
                  >
                    <i className="far fa-gift compliment-btn-icon" />
                  </Button>
                )}

                <Button
                  variant="primary"
                  type="submit"
                  className={
                    disabledSendButton
                      ? 'chat-v2-send-btn-disabled'
                      : 'chat-v2-send-btn'
                  }
                  disabled={disabledSendButton}
                >
                  <i className="far fa-paper-plane" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        </div>
      </div>
    );
  }
}

export default withRouter(Chat);
