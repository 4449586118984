import React, { useContext, useEffect, useState } from "react";
import "./about.scss";
import { UserContext } from "../../../../context/UserContext";
import { countUserInfo } from "../../../../helpers/CountBenefits";

function About(props) {
  const context = useContext(UserContext);
  const filters = context.user.filters;
  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchInfoCount();
  }, []);

  const fetchInfoCount = async (e) => {
    const countReturn = await countUserInfo(props.profileUser);
    setCount(countReturn);
  };

  const heightOptions = [
    `4' 0"`,
    `4' 1"`,
    `4' 2"`,
    `4' 3"`,
    `4' 4"`,
    `4' 5"`,
    `4' 6"`,
    `4' 7"`,
    `4' 8"`,
    `4' 9"`,
    `4' 10"`,
    `4' 11"`,
    `5' 0"`,
    `5' 1"`,
    `5' 2"`,
    `5' 3"`,
    `5' 4"`,
    `5' 5"`,
    `5' 6"`,
    `5' 7"`,
    `5' 8"`,
    `5' 9"`,
    `5' 10"`,
    `5' 11"`,
    `6' 1"`,
    `6' 2"`,
    `6' 3"`,
    `6' 4"`,
    `6' 5"`,
    `6' 6"`,
    `6' 7"`,
    `6' 8"`,
    `6' 9"`,
    `6' 10"`,
    `6' 11"`,
    `7' 0"`,
  ];

  var profileUser = props.profileUser;
  var lifestyle = profileUser.lifestyle;
  var headline = profileUser.headline;
  var headlinestatus = profileUser.headlinestatus;
  var showHeadline = false;

  if (headline) {
    if (headlinestatus === "approved" && headline.length > 0) {
      showHeadline = true;
    }
  }

  var relationshipStyle = "user-info-v2-item";
  var heightStyle = "user-info-v2-item";
  var bodytypeStyle = "user-info-v2-item";
  var smokingStyle = "user-info-v2-item";
  var ethnicityStyle = "user-info-v2-item";
  var drinkingStyle = "user-info-v2-item";
  var cannabisStyle = "user-info-v2-item";
  var childrenStyle = "user-info-v2-item";
  var exerciseStyle = "user-info-v2-item";
  var educationStyle = "user-info-v2-item";

  var relationshipIconStyle = "far fa-heart user-info-v2-icon";
  var heightIconStyle = "far fa-ruler-vertical user-info-v2-icon";
  var bodytypeIconStyle = "far fa-child user-info-v2-icon";
  var smokingIconStyle = "far fa-smoking user-info-v2-icon";
  var ethnicityIconStyle = "far fa-smile user-info-v2-icon";
  var drinkingIconStyle = "far fa-wine-glass user-info-v2-icon";
  var cannabisIconStyle = "far fa-cannabis user-info-v2-icon";
  var childrenIconStyle = "far fa-baby user-info-v2-icon";
  var exerciseIconStyle = "far fa-dumbbell user-info-v2-icon";
  var educationIconStyle = "far fa-graduation-cap user-info-v2-icon";

  if (profileUser.lifestyle.relationship && !props.isOwnProfile) {
    if (
      profileUser.lifestyle.relationship === "Single" &&
      filters.relationshipstatus.single
    ) {
      relationshipStyle = "user-info-v2-item-active";
      relationshipIconStyle = "far fa-heart user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.relationship === "Divorced" &&
      filters.relationshipstatus.divorced
    ) {
      relationshipStyle = "user-info-v2-item-active";
      relationshipIconStyle = "far fa-heart user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.relationship === "Separated" &&
      filters.relationshipstatus.separated
    ) {
      relationshipStyle = "user-info-v2-item-active";
      relationshipIconStyle = "far fa-heart user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.relationship === "Married But Looking" &&
      filters.relationshipstatus.marriedbutlooking
    ) {
      relationshipStyle = "user-info-v2-item-active";
      relationshipIconStyle = "far fa-heart user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.relationship === "Open Relationship" &&
      filters.relationshipstatus.openrelationship
    ) {
      relationshipStyle = "user-info-v2-item-active";
      relationshipIconStyle = "far fa-heart user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.relationship === "Widowed" &&
      filters.relationshipstatus.widowed
    ) {
      relationshipStyle = "user-info-v2-item-active";
      relationshipIconStyle = "far fa-heart user-info-v2-icon-active";
    }
  }

  if (profileUser.height && !props.isOwnProfile) {
    const index = heightOptions.findIndex(
      (height) => height === profileUser.height
    );
    if (
      index > -1 &&
      index >= filters.height[0] &&
      index <= filters.height[1]
    ) {
      heightStyle = "user-info-v2-item-active";
      heightIconStyle = "far fa-ruler-vertical user-info-v2-icon-active";
    }
  }

  if (profileUser.bodytype && !props.isOwnProfile) {
    if (profileUser.bodytype === "Slim" && filters.bodytype.slim) {
      bodytypeStyle = "user-info-v2-item-active";
      bodytypeIconStyle = "far fa-child user-info-v2-icon-active";
    } else if (profileUser.bodytype === "Fit" && filters.bodytype.fit) {
      bodytypeStyle = "user-info-v2-item-active";
      bodytypeIconStyle = "far fa-child user-info-v2-icon-active";
    } else if (profileUser.bodytype === "Average" && filters.bodytype.average) {
      bodytypeStyle = "user-info-v2-item-active";
      bodytypeIconStyle = "far fa-child user-info-v2-icon-active";
    } else if (profileUser.bodytype === "Curvy" && filters.bodytype.curvy) {
      bodytypeStyle = "user-info-v2-item-active";
      bodytypeIconStyle = "far fa-child user-info-v2-icon-active";
    } else if (
      profileUser.bodytype === "A Few Extra Pounds" &&
      filters.bodytype.afewextrapounds
    ) {
      bodytypeStyle = "user-info-v2-item-active";
      bodytypeIconStyle = "far fa-child user-info-v2-icon-active";
    } else if (
      profileUser.bodytype === "Full / Overweight" &&
      filters.bodytype.fullfigured
    ) {
      bodytypeStyle = "user-info-v2-item-active";
      bodytypeIconStyle = "far fa-child user-info-v2-icon-active";
    }
  }

  if (profileUser.lifestyle.smoking && !props.isOwnProfile) {
    if (
      profileUser.lifestyle.smoking === "Non Smoker" &&
      filters.smoking.nonsmoker
    ) {
      smokingStyle = "user-info-v2-item-active";
      smokingIconStyle = "far fa-smoking user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.smoking === "Light Smoker" &&
      filters.smoking.lightsmoker
    ) {
      smokingStyle = "user-info-v2-item-active";
      smokingIconStyle = "far fa-smoking user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.smoking === "Heavy Smoker" &&
      filters.smoking.heavysmoker
    ) {
      smokingStyle = "user-info-v2-item-active";
      smokingIconStyle = "far fa-smoking user-info-v2-icon-active";
    }
  }

  if (profileUser.lifestyle.drinking && !props.isOwnProfile) {
    if (
      profileUser.lifestyle.drinking === "Non Drinker" &&
      filters.drinking.nondrinker
    ) {
      drinkingStyle = "user-info-v2-item-active";
      drinkingIconStyle = "far fa-wine-glass user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.drinking === "Social Drinker" &&
      filters.drinking.socialdrinker
    ) {
      drinkingStyle = "user-info-v2-item-active";
      drinkingIconStyle = "far fa-wine-glass user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.drinking === "Heavy Drinker" &&
      filters.drinking.heavydrinker
    ) {
      drinkingStyle = "user-info-v2-item-active";
      drinkingIconStyle = "far fa-wine-glass user-info-v2-icon-active";
    }
  }

  if (profileUser.lifestyle.children && !props.isOwnProfile) {
    if (
      profileUser.lifestyle.children === "No Kids" &&
      filters.children.nokids
    ) {
      childrenStyle = "user-info-v2-item-active";
      childrenIconStyle = "far fa-baby user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.children === "1 Kids" &&
      filters.children.onekid
    ) {
      childrenStyle = "user-info-v2-item-active";
      childrenIconStyle = "far fa-baby user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.children === "2 Kids" &&
      filters.children.twokids
    ) {
      childrenStyle = "user-info-v2-item-active";
      childrenIconStyle = "far fa-baby user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.children === "3 Kids" &&
      filters.children.threekids
    ) {
      childrenStyle = "user-info-v2-item-active";
      childrenIconStyle = "far fa-baby user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.children === "4+ Kids" &&
      filters.children.fourpluskids
    ) {
      childrenStyle = "user-info-v2-item-active";
      childrenIconStyle = "far fa-baby user-info-v2-icon-active";
    }
  }

  if (profileUser.lifestyle.exercise && !props.isOwnProfile) {
    if (
      profileUser.lifestyle.exercise === "Sometimes" &&
      filters.exercise.sometimes
    ) {
      exerciseStyle = "user-info-v2-item-active";
      exerciseIconStyle = "far fa-dumbbell user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.exercise === "Never" &&
      filters.exercise.never
    ) {
      exerciseStyle = "user-info-v2-item-active";
      exerciseIconStyle = "far fa-dumbbell user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.exercise === "Regularly" &&
      filters.exercise.regularly
    ) {
      exerciseStyle = "user-info-v2-item-active";
      exerciseIconStyle = "far fa-dumbbell user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.exercise === "Often" &&
      filters.exercise.often
    ) {
      exerciseStyle = "user-info-v2-item-active";
      exerciseIconStyle = "far fa-dumbbell user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.exercise === "Daily" &&
      filters.exercise.daily
    ) {
      exerciseStyle = "user-info-v2-item-active";
      exerciseIconStyle = "far fa-dumbbell user-info-v2-icon-active";
    }
  }

  if (profileUser.lifestyle.education && !props.isOwnProfile) {
    if (
      profileUser.lifestyle.education === "High School" &&
      filters.education.highschool
    ) {
      educationStyle = "user-info-v2-item-active";
      educationIconStyle = "far fa-graduation-cap user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.education === "Some College" &&
      filters.education.somecollege
    ) {
      educationStyle = "user-info-v2-item-active";
      educationIconStyle = "far fa-graduation-cap user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.education === "Associates Degree" &&
      filters.education.associatesdegree
    ) {
      educationStyle = "user-info-v2-item-active";
      educationIconStyle = "far fa-graduation-cap user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.education === "Bachelors Degree" &&
      filters.education.bachelorsdegree
    ) {
      educationStyle = "user-info-v2-item-active";
      educationIconStyle = "far fa-graduation-cap user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.education === "Graduate Degree" &&
      filters.education.graduatedegree
    ) {
      educationStyle = "user-info-v2-item-active";
      educationIconStyle = "far fa-graduation-cap user-info-v2-icon-active";
    } else if (
      profileUser.lifestyle.education === "PhD / Post Doctoral" &&
      filters.education.phd
    ) {
      educationStyle = "user-info-v2-item-active";
    }
  }

  if (profileUser.ethnicity && !props.isOwnProfile) {
    if (
      profileUser.ethnicity === "White" ||
      profileUser.ethnicity === "White / Caucasion"
    ) {
      if (filters.ethnicity.white) {
        ethnicityStyle = "user-info-v2-item-active";
        ethnicityIconStyle = "far fa-smile user-info-v2-icon-active";
      }
    } else if (
      profileUser.ethnicity === "Black / African Decent" ||
      profileUser.ethnicity === "Black"
    ) {
      if (filters.ethnicity.black) {
        ethnicityStyle = "user-info-v2-item-active";
        ethnicityIconStyle = "far fa-smile user-info-v2-icon-active";
      }
    } else if (
      profileUser.ethnicity === "Latin / Hispanic" ||
      profileUser.ethnicity === "Hispanic"
    ) {
      if (filters.ethnicity.hispanic) {
        ethnicityStyle = "user-info-v2-item-active";
        ethnicityIconStyle = "far fa-smile user-info-v2-icon-active";
      }
    } else if (profileUser.ethnicity === "Asian") {
      if (filters.ethnicity.asian) {
        ethnicityStyle = "user-info-v2-item-active";
        ethnicityIconStyle = "far fa-smile user-info-v2-icon-active";
      }
    } else if (
      profileUser.ethnicity === "Indian" ||
      profileUser.ethnicity === "Native American"
    ) {
      if (filters.ethnicity.indian) {
        ethnicityStyle = "user-info-v2-item-active";
        ethnicityIconStyle = "far fa-smile user-info-v2-icon-active";
      }
    } else if (profileUser.ethnicity === "Middle Eastern") {
      if (filters.ethnicity.middleeastern) {
        ethnicityStyle = "user-info-v2-item-active";
        ethnicityIconStyle = "far fa-smile user-info-v2-icon-active";
      }
    } else if (
      profileUser.ethnicity === "Other" ||
      profileUser.ethnicity === "Mixed"
    ) {
      if (filters.ethnicity.other) {
        ethnicityStyle = "user-info-v2-item-active";
        ethnicityIconStyle = "far fa-smile user-info-v2-icon-active";
      }
    }
  }

  // To Show or not
  var showRelationship = false;

  if (lifestyle.relationship) {
    showRelationship = true;
  }

  var showHeight = false;
  if (profileUser.height) {
    showHeight = true;
  }

  var showBodytype = false;
  if (profileUser.bodytype) {
    showBodytype = true;
  }

  var showSmoking = false;
  if (lifestyle.smoking) {
    showSmoking = true;
  }

  var showEthnicity = false;
  if (profileUser.ethnicity) {
    showEthnicity = true;
  }

  var showDrinking = false;
  if (lifestyle.drinking) {
    showDrinking = true;
  }

  var showCannabis = false;
  if (lifestyle.cannabis) {
    showCannabis = true;
  }

  var showChildren = false;
  if (lifestyle.children) {
    showChildren = true;
  }

  var showExercise = false;
  if (lifestyle.exercise) {
    showExercise = true;
  }

  var showEducation = false;
  if (lifestyle.education) {
    showEducation = true;
  }

  var hide = false;
  var countHide = false;
  if (!count || count < 1) {
    countHide = true;
  }

  if (countHide && !showHeadline) {
    hide = true;
  }

  if (hide) {
    return null;
  } else {
    return (
      <div className="user-info-v2">
        <h3 className="benefits-title">About</h3>
        {showHeadline && (
          <p className="about-v2-content">{props.profileUser.headline}</p>
        )}
        {!countHide && (
          <div
            className={
              showHeadline
                ? "benefits-content-wrapper-v2"
                : "benefits-content-wrapper-nopadding"
            }
          >
            <div className="user-info-v2-content">
              {showRelationship && (
                <div className={relationshipStyle}>
                  <i className={relationshipIconStyle}></i>
                  <span className="user-info-v2-item-text">
                    {lifestyle.relationship}
                  </span>
                </div>
              )}
              {showHeight && (
                <div className={heightStyle}>
                  <i className={heightIconStyle}></i>
                  <span className="user-info-v2-item-text">
                    {profileUser.height}
                  </span>
                </div>
              )}
              {showBodytype && (
                <div className={bodytypeStyle}>
                  <i className={bodytypeIconStyle}></i>
                  <span className="user-info-v2-item-text">
                    {profileUser.bodytype}
                  </span>
                </div>
              )}
              {showSmoking && (
                <div className={smokingStyle}>
                  <i className={smokingIconStyle}></i>
                  <span className="user-info-v2-item-text">
                    {lifestyle.smoking}
                  </span>
                </div>
              )}
              {showEthnicity && (
                <div className={ethnicityStyle}>
                  <i className={ethnicityIconStyle}></i>
                  <span className="user-info-v2-item-text">
                    {profileUser.ethnicity}
                  </span>
                </div>
              )}
              {showDrinking && (
                <div className={drinkingStyle}>
                  <i className={drinkingIconStyle}></i>
                  <span className="user-info-v2-item-text">
                    {lifestyle.drinking}
                  </span>
                </div>
              )}
              {showCannabis && (
                <div className={cannabisStyle}>
                  <i className={cannabisIconStyle}></i>
                  <span className="user-info-v2-item-text">
                    {lifestyle.cannabis}
                  </span>
                </div>
              )}
              {showChildren && (
                <div className={childrenStyle}>
                  <i className={childrenIconStyle}></i>
                  <span className="user-info-v2-item-text">
                    {lifestyle.children}
                  </span>
                </div>
              )}
              {showExercise && (
                <div className={exerciseStyle}>
                  <i className={exerciseIconStyle}></i>
                  <span className="user-info-v2-item-text">
                    {lifestyle.exercise}
                  </span>
                </div>
              )}
              {showEducation && (
                <div className={educationStyle}>
                  <i className={educationIconStyle}></i>
                  <span className="user-info-v2-item-text">
                    {lifestyle.education}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default About;
