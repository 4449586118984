import React, { useState, useEffect } from 'react';
import './hometab.scss';
import { Button, Card } from 'react-bootstrap';
import topImage from '../../../../assets/Images/Headers/arrangement-hero-desktop.jpg';
import api from '../../../../api/api';
import ConversionsView from '../../../views/ConversionsView';
import SignupsView from '../../../views/SignupsView';
import EngagementView from '../../../views/EngagementView';
import TasksView from '../../../views/TasksView';
import ActiveUserView from '../../../views/ActiveUserView';
import ActiveSubView from '../../../views/ActiveSubView';

function HomeTab(props) {
  const [view, setView] = useState('conversions');

  const viewHandler = (props) => (e) => {
    if (props === 'conversions') {
      setView('conversions');
    } else if (props === 'signups') {
      setView('signups');
    } else if (props === 'engagement') {
      setView('engagement');
    } else if (props === 'tasks') {
      setView('tasks');
    } else if (props === 'activeUser') {
      setView('activeUser');
    } else if (props === 'activeSub') {
      setView('activeSub');
    } else if (props === 'runTask') {
      console.log('Inside Run Task');
      let data = {};
      return api
        .updateDatabase(data)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  var conversionButtonClass = 'homeNavbar-button';
  var signupButtonClass = 'homeNavbar-button';
  var engagementButtonClass = 'homeNavbar-button';
  var taskButtonClass = 'homeNavbar-button';

  if (view === 'conversions') {
    conversionButtonClass = 'homeNavbar-button-active';
  } else if (view === 'signups') {
    signupButtonClass = 'homeNavbar-button-active';
  } else if (view === 'engagement') {
    engagementButtonClass = 'homeNavbar-button-active';
  } else if (view === 'tasks') {
    taskButtonClass = 'homeNavbar-button-active';
  }

  const runTaskHandler = (e) => {
    return api
      .updateSchema()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const disapproveBannedChats = (e) => {
    return api
      .disapproveBannedChats()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const appHandler = (e) => {
    props.history.push('/app');
  };

  const fecthActiveSubscription = (e) => {
    return api
      .fecthActiveSubscription()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="admin-home-tab">
      <div className="admin-home-top-navigation">
        <Button
          className={conversionButtonClass}
          onClick={viewHandler('conversions')}
        >
          Conversions
        </Button>
        <Button className={signupButtonClass} onClick={viewHandler('signups')}>
          Sign Ups
        </Button>
        <Button
          className={engagementButtonClass}
          onClick={viewHandler('engagement')}
        >
          Engagement
        </Button>
        <Button className={taskButtonClass} onClick={viewHandler('tasks')}>
          Tasks
        </Button>
        <Button className={taskButtonClass} onClick={viewHandler('activeUser')}>
          Active User
        </Button>
        <Button className={taskButtonClass} onClick={viewHandler('activeSub')}>
          Active Subscriber
        </Button>
        <Button className={taskButtonClass} onClick={viewHandler('runTask')}>
          Run Task
        </Button>
      </div>
      <div className="admin-home-tab-content">
        {view === 'conversions' && <ConversionsView />}
        {view === 'signups' && <SignupsView />}
        {view === 'engagement' && <EngagementView />}
        {view === 'tasks' && <TasksView />}
        {view === 'activeUser' && <ActiveUserView />}
        {view === 'activeSub' && <ActiveSubView />}
      </div>
      <div className="test-button">
        <Button onClick={fecthActiveSubscription}>Test Button</Button>
      </div>
    </div>
  );
}

export default HomeTab;
