import React from 'react';
import { Image, Transformation } from 'cloudinary-react';
import moment from 'moment';

import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../../utils/getCloudinaryInfo';
import { api } from '../../../../../api';
import Media from '../../../../../components/media/Media';

import '../../../admincomponents.scss';

export const BugCard = (props) => {
  const { user } = props.bug;
  const mainImage = user.imgurl && user.imgurl.length !== 0 && user.imgurl[0];
  return (
    <div className="report-bug-card">
      <div className="bug-card-header">
        <Media image={mainImage} />

        {/* <Image
          publicId={
            mainImage
              ? getImgName(mainImage)
              : 'placeholder-user-image-thumbnail_vankru.png'
          }
          version={mainImage ? getImgVersion(mainImage) : '1590045309'}
          resource_type={
            mainImage ? (checkGIF(mainImage) ? 'video' : 'image') : null
          }
          cloud_name="sugarbae"
          className="bug-card-image"
        >
          <Transformation
            height="200"
            video_sampling="20"
            delay="250"
            duration="10"
            effect="loop"
            quality="auto"
            flags="lossy"
          />
        </Image> */}
        <div className="bug-card-userinfo">
          <p className="bug-card-username">{user.username}</p>
          <br />
          <p className="bug-card-date">
            {moment(props.bug.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
          </p>
        </div>
      </div>
      <div className="bug-card-category">{props.bug.category}</div>
      <div className="bug-card-screens">{'Screens:'}</div>
      <div className="bug-card-descrption">{props.bug.description}</div>
      <div className="bug-card-action">
        <span className="icon icon-report-check"></span>
        <p className="report-action-title">Resolve</p>
      </div>
    </div>
  );
};

export default BugCard;
