import React, { useState, useContext, useEffect } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import PhotoGridV2 from "../../photoGrid/PhotoGridV2";
import { UserContext } from "../../../context/UserContext";
import "../welcomev2.scss";
import bodytypeIcon from "../../../assets/Icons/bodytypeIcon.png";
import heightIcon from "../../../assets/Icons/heightIcon.svg";
import ethnicityIcon from "../../../assets/Icons/ethnicityIcon.png";
import "./physicaldetails.scss";
import { api } from "../../../api";

const PhysicalDetail = (props) => {
  const history = useHistory();
  const context = useContext(UserContext);
  const [showWarning, setShowWarning] = useState(false);
  const [advanceDisabled, setAdvanceDisabled] = useState(true);
  const [height, setHeight] = useState(context.user.height);
  const [bodytype, setBodytype] = useState(context.user.bodytype);
  const [ethnicity, setEthnicity] = useState(context.user.ethnicity);

  const heightChange = (e) => {
    setHeight(e.target.value);

    const data = {
      userid: context.user._id,
      height: e.target.value,
    };

    return api.updateProfileHeight(data).catch((err) => {
      console.log(err);
    });
  };

  const bodytypeChange = (e) => {
    setBodytype(e.target.value);

    const data = {
      userid: context.user._id,
      bodytype: e.target.value,
    };

    return api.updateBodytype(data).catch((err) => {
      console.log(err);
    });
  };

  const ethnicityChange = (e) => {
    setEthnicity(e.target.value);

    const data = {
      userid: context.user._id,
      ethnicity: e.target.value,
    };

    return api.updateEthnicity(data).catch((err) => {
      console.log(err);
    });
  };

  const skipHandler = () => {
    if (
      ethnicity !== null &&
      ethnicity !== undefined &&
      height !== null &&
      height !== undefined &&
      bodytype !== null &&
      bodytype !== undefined
    ) {
      history.push("/app/welcome/benefits");
    } else {
      setShowWarning(true);
    }
  };

  const confirmSkipHandler = (e) => {
    history.push("/app/welcome/benefits");
  };

  const isDisabled = false;

  let heightStyle = "physical-details-select";
  let bodytypeStyle = "physical-details-select";
  let ethnicityStyle = "physical-details-select";

  if (height !== null && height !== undefined) {
    heightStyle = "physical-details-select-active";
  }

  if (ethnicity !== null && ethnicity !== undefined) {
    ethnicityStyle = "physical-details-select-active";
  }

  if (bodytype !== null && bodytype !== undefined) {
    bodytypeStyle = "physical-details-select-active";
  }

  return (
    <div className="welcome-v2-container">
      {showWarning && (
        <div className="no-photos-warning">
          <div className="no-photos-innerwrap">
            <div
              className="no-photos-warning-overlay"
              onClick={() => {
                setShowWarning(false);
              }}
            />
            <div className="no-photos-warning-content">
              <div className="no-photos-content-warning">
                <i className="far fa-exclamation-triangle no-photos-warning-icon" />
                <h1 className="no-photos-warning-title">Warning</h1>
                <p className="no-photos-warning-subtitle">
                  Your profile will not receive as much attention if you don't
                  fill out your height, bodytype and ethnicity.
                </p>
              </div>
              <div
                className="no-photos-footer-button"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                Select Basic Info
              </div>
              <div
                className="no-photos-warning-footer"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                <span
                  className="no-photos-close-text"
                  onClick={confirmSkipHandler}
                >
                  Skip
                </span>
                <i className="far fa-times-circle" />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="welcome-v2-header">
        <div className="welcome-v2-title">
          <h1 className="welcome-v2-title-one">Your</h1>
          <h2 className="welcome-v2-title-two">Physical Details</h2>
        </div>
        <p className="welcome-v2-subtitle">
          Enter some basic info so other users know what you look like.
        </p>
      </div>
      <div className="welcome-v2-body">
        <Form className="physical-details-form">
          <Form.Group className="physical-details-form-group">
            <Image
              src={heightIcon}
              className="physical-details-form-image"
              fluid
            />
            <i className="fas fa-sort-down physical-details-dropdown-icon" />
            <Form.Control
              as="select"
              custom
              className={heightStyle}
              value={height}
              onChange={heightChange}
            >
              <option value="" disabled selected hidden>
                Your Height
              </option>
              <option>4' 0"</option>
              <option>4' 1"</option>
              <option>4' 2"</option>
              <option>4' 3"</option>
              <option>4' 4"</option>
              <option>4' 5"</option>
              <option>4' 6"</option>
              <option>4' 7"</option>
              <option>4' 8"</option>
              <option>4' 9"</option>
              <option>4' 10"</option>
              <option>4' 11"</option>
              <option>5' 0"</option>
              <option>5' 1"</option>
              <option>5' 2"</option>
              <option>5' 3"</option>
              <option>5' 4"</option>
              <option>5' 5"</option>
              <option>5' 6"</option>
              <option>5' 7"</option>
              <option>5' 8"</option>
              <option>5' 9"</option>
              <option>5' 10"</option>
              <option>5' 11"</option>
              <option>6' 1"</option>
              <option>6' 2"</option>
              <option>6' 3"</option>
              <option>6' 4"</option>
              <option>6' 5"</option>
              <option>6' 6"</option>
              <option>6' 7"</option>
              <option>6' 8"</option>
              <option>6' 9"</option>
              <option>6' 10"</option>
              <option>6' 11"</option>
              <option>7' 0"</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="physical-details-form-group">
            <Image
              src={bodytypeIcon}
              className="physical-details-form-image"
              fluid
            />
            <i className="fas fa-sort-down physical-details-dropdown-icon" />
            <Form.Control
              as="select"
              custom
              className={bodytypeStyle}
              value={bodytype}
              onChange={bodytypeChange}
            >
              <option value="" disabled selected hidden>
                Your Body Type
              </option>
              <option>Slim</option>
              <option>Fit</option>
              <option>Average</option>
              <option>Curvy</option>
              <option>A Few Extra Pounds</option>
              <option>Full / Overweight</option>
              <option>Other</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="physical-details-form-group">
            <Image
              src={ethnicityIcon}
              className="physical-details-form-image"
              fluid
            />
            <i className="fas fa-sort-down physical-details-dropdown-icon" />
            <Form.Control
              as="select"
              custom
              className={ethnicityStyle}
              value={ethnicity}
              onChange={ethnicityChange}
            >
              <option value="" disabled selected hidden>
                Your Ethnicity
              </option>
              <option>White / Caucasian</option>
              <option>Asian</option>
              <option>Black / African Decent</option>
              <option>Latin / Hispanic</option>
              <option>East Indian</option>
              <option>Middle Eastern</option>
              <option>Mixed</option>
              <option>Native American</option>
              <option>Pacific Islander</option>
              <option>Other</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </div>

      <div className="welcome-v2-footer">
        <div className="welcome-v2-button-skip" onClick={() => skipHandler()}>
          Skip
        </div>
        <div
          variant="danger"
          className="welcome-v2-button"
          disabled={isDisabled}
          onClick={() => history.push("/app/welcome/benefits")}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default withRouter(PhysicalDetail);
