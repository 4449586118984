import React, { useState, useEffect, useContext } from "react";
import "./desktopswipeactions.scss";
import { Image } from "react-bootstrap";
import undoIcon from "../../../../assets/Icons/undoIcon.png";
import dislikeIcon from "../../../../assets/Icons/dislikeIcon.png";
import likeIcon from "../../../../assets/Icons/likeIcon.png";
import complimentIcon from "../../../../assets/Icons/complimentIcon.png";
import { UserContext } from "../../../../context/UserContext";
import undoDisabledIcon from "../../../../assets/Icons/undoDisabledIcon.png";

function DesktopSwipeActions(props) {
  const context = useContext(UserContext);
  const { undoDisabled } = props;

  const undoHandler = (e) => {
    if (!undoDisabled) {
      props.handleUndo();
    }
  };

  const likeHandler = (e) => {
    props.handleLike();
  };

  const dislikeHandler = (e) => {
    props.handleDislike();
  };

  const complimentHandler = (e) => {
    props.handleCompliment();
  };

  return (
    <div className="desktop-swipe-actions-v2">
      <div className="desktop-swipe-v2-action-button" onClick={undoHandler}>
        {undoDisabled ? (
          <Image
            src={undoDisabledIcon}
            fluid
            className="desktop-swipe-v2-action-icon"
          />
        ) : (
          <Image
            src={undoIcon}
            fluid
            className="desktop-swipe-v2-action-icon"
          />
        )}
      </div>
      <div style={{ display: "flex" }}>
        <div
          className="desktop-swipe-v2-action-button"
          style={{ marginRight: "1rem" }}
          onClick={dislikeHandler}
        >
          <Image
            src={dislikeIcon}
            fluid
            className="desktop-swipe-v2-action-icon"
          />
        </div>
        <div
          className="desktop-swipe-v2-action-button"
          style={{ marginLeft: "1rem" }}
          onClick={likeHandler}
        >
          <Image
            src={likeIcon}
            fluid
            className="desktop-swipe-v2-action-icon"
          />
        </div>
      </div>
      {context.user.gender === "male" ? (
        <div
          className="desktop-swipe-v2-action-button-compliment"
          onClick={complimentHandler}
        >
          <Image
            src={complimentIcon}
            fluid
            className="desktop-swipe-v2-action-icon"
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default DesktopSwipeActions;
