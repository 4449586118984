import React from "react";

function DesktopAbout(props) {
  const { card } = props;
  let showHeadline = false;
  if (card.headline) {
    if (card.headlinestatus && card.headline.length > 0) {
      showHeadline = true;
    }
  }

  if (!showHeadline) {
    return null;
  }
  return (
    <div className="desktop-v2-about-container">
      <h1 className="desktop-v2-section-title">About</h1>
      <p className="desktop-v2-section-paragraph">{card.headline}</p>
    </div>
  );
}

export default DesktopAbout;
