import React from "react";
import PremiumCardMonthlyOnly from "../../premium/premiumComponents/premiumCard/PremiumCardMonthlyOnly";
import DesktopNav from "../desktopNav/DesktopNav";
import "./desktoppremium.scss";

function DesktopProfile() {
  return (
    <div className="desktop-premium">
      <DesktopNav />
      <div className="desktop-premium-content-container">
        <div className="desktop-premium-left-side">
          <PremiumCardMonthlyOnly />
        </div>
        <div className="desktop-premium-right-side">
          <div className="desktop-premium-right-innerwrap">
            <div className="desktop-premium-header">
              <div className="desktop-premium-header-wrap">
                <h1 className="desktop-premium-header-title">
                  Premium Benefits
                </h1>
              </div>
            </div>
            <div className="desktop-premium-right-content">
              <div className="desktop-premium-feature">
                <i className="fas fa-comments-alt desktop-premium-feature-icon"></i>
                <h4 className="desktop-premium-feature-title">
                  Unlimited Messaging
                </h4>
                <p className="desktop-premium-feature-subtitle">
                  Unlock unlimited messaging. Message users you have or have not
                  matched with.
                </p>
              </div>
              <div className="desktop-premium-feature">
                <i className="fas fa-heart desktop-premium-feature-icon"></i>
                <h4 className="desktop-premium-feature-title">
                  Unlimited Likes
                </h4>
                <p className="desktop-premium-feature-subtitle">
                  Swipe and send unlimited likes to get more attention.
                </p>
              </div>
              <div className="desktop-premium-feature">
                <i className="fas fa-undo-alt desktop-premium-feature-icon"></i>

                <h4 className="desktop-premium-feature-title">Undo Swipes</h4>
                <p className="desktop-premium-feature-subtitle">
                  Accidently disliked or liked a user? Unlock Undo's with
                  Premium.
                </p>
              </div>
              <div className="desktop-premium-feature">
                <i className="fas fa-bolt desktop-premium-feature-icon"></i>

                <h4 className="desktop-premium-feature-title">
                  Profile Boosts
                </h4>
                <p className="desktop-premium-feature-subtitle">
                  Get weekly Profile Boosts with Sugarbae Premium. Get More
                  Matches and Attention.
                </p>
              </div>
              <div className="desktop-premium-feature">
                <i className="fas fa-stars desktop-premium-feature-icon"></i>

                <h4 className="desktop-premium-feature-title">
                  Extra Superlikes
                </h4>
                <p className="desktop-premium-feature-subtitle">
                  Get 5 daily Superlikes with Premium, to attract users you
                  would really like to match with.
                </p>
              </div>
              <div className="desktop-premium-feature">
                <i className="fas fa-filter desktop-premium-feature-icon"></i>

                <h4 className="desktop-premium-feature-title">
                  Advanced Filters
                </h4>
                <p className="desktop-premium-feature-subtitle">
                  Unlock advanced search filters to find the exact users you are
                  looking for.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DesktopProfile;
