import React, { Component } from "react";
import "./welcome.scss";
import { Button, Image, Card } from "react-bootstrap";
import siteLogoWhite from "../../assets/logos/logo-horizontal-dark-background.png";
import welcomeImg from "../../assets/Images/welcome/welcome-image.jpg";
import { withRouter } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";

export class Welcome extends Component {
  advanceHandler = (e) => {
    this.props.history.push("/app/welcome/photos");
  };

  render() {
    return (
      <div className="welcome-component-container">
        <ProgressBar animated now={0} className="welcome-progress-bar" />

        <div className="welcome-content-wrapper">
          <Image src={siteLogoWhite} fluid className="welcome-site-logo" />
          <Card className="welcome-action-card">
            <Card.Img variant="top" src={welcomeImg} />
            <Card.Body className="welcome-action-card-body">
              <Card.Title className="welcome-action-card-title">
                Welcome to Sugarbae
              </Card.Title>
              <p className="welcome-action-card-subtitle">
                Ready to find your perfect match? Here's how it works...
              </p>
              <div className="welcome-action-item">
                <div className="welcome-action-icon-wrapper">
                  <i className="fas fa-heart welcome-action-icon-heart"></i>
                </div>
                <div className="welcome-action-text-wrapper">
                  <h2 className="welcome-action-title">Send Likes</h2>
                  <p className="welcome-action-subtitle">
                    Swipe right to like profiles, left to dislike.
                  </p>
                </div>
              </div>
              <div className="welcome-action-item">
                <div className="welcome-action-icon-wrapper">
                  <i className="fas fa-user-friends welcome-action-icon-match"></i>
                </div>
                <div className="welcome-action-text-wrapper">
                  <h2 className="welcome-action-title">Match</h2>
                  <p className="welcome-action-subtitle">
                    Match with users that also like you.
                  </p>
                </div>
              </div>
              <div className="welcome-action-item">
                <div className="welcome-action-icon-wrapper">
                  <i className="fas fa-comment-alt-lines welcome-action-icon-message"></i>
                </div>
                <div className="welcome-action-text-wrapper">
                  <h2 className="welcome-action-title">Chat & Meet</h2>
                  <p className="welcome-action-subtitle">
                    Instant chat with matches to plan your arrangement.
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="welcome-btn-wrap">
          <Button
            variant="primary"
            className="welcome-btn-getstarted"
            onClick={this.advanceHandler}
          >
            Let's Get Started!
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(Welcome);
