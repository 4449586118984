import React, { Component } from "react";
import { Button, Form, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import siteLogo from "../../../assets/logos/logo_black.png";
import "./contactform.scss";
import axios from "axios";
import LoadingSubmitNotification from "../../../components/notifications/LoadingSubmitNotification";
import loginCardBanner from "../../../assets/Images/Login Page/Login Card Banner/login-card-banner.jpg";

export class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      reasonInvalid: false,
      email: "",
      emailInvalid: false,
      username: "",
      usernameInvalid: false,
      message: "",
      messageInvalid: false,
      disabledBtn: true,
      loading: false,
      loadingSuccess: true,
    };
  }

  reasonChange = (e) => {
    this.setState({
      reason: e.target.value,
      reasonInvalid: false,
      disabledBtn: false,
    });
  };

  emailChange = (e) => {
    this.setState({
      email: e.target.value,
      emailInvalid: false,
      disabledBtn: false,
    });
  };

  usernameChange = (e) => {
    this.setState({
      username: e.target.value,
      usernameInvalid: false,
      disabledBtn: false,
    });
  };

  messageChange = (e) => {
    this.setState({
      message: e.target.value,
      messageInvalid: false,
      disabledBtn: false,
    });
  };

  submitHandler = async (e) => {
    e.preventDefault();
    if (this.state.reason === "") {
      this.setState({ reasonInvalid: true });
    }

    if (this.state.email === "") {
      this.setState({ emailInvalid: true });
    }

    if (this.state.username === "") {
      this.setState({ usernameInvalid: true });
    }

    if (this.state.message === "") {
      this.setState({ messageInvalid: true });
    }

    if (this.state.reason === "") {
      this.setState({ reasonInvalid: true });
    }

    if (
      this.state.reason !== "" &&
      this.state.email !== "" &&
      this.state.reason !== "" &&
      this.state.username !== "" &&
      this.state.message !== ""
    ) {
      this.setState({ disabledBtn: true }, async (e) => {
        const token = localStorage.getItem("auth");
        const baseurl = process.env.REACT_APP_API_URL;
        const url = baseurl + "/api/user/contactform";
        const config = {
          body: {
            reason: this.state.reason,
            email: this.state.email,
            username: this.state.username,
            message: this.state.message,
          },
        };

        await axios
          .post(url, config)
          .then((res) => {
            console.log("Inside Res" + res);
            this.setState({ loading: true }, () => {
              setTimeout(() => {
                this.setState({
                  loading: false,
                  reason: "",
                  username: "",
                  message: "",
                  email: "",
                });
              }, 2000);
            });
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  };

  render() {
    return (
      <div className="contact-form-section">
        {this.state.loading ? (
          <LoadingSubmitNotification
            loading={this.state.loading}
            success={this.state.loadingSuccess}
            loadingMsg={"Sending Message..."}
            successMsg={"Message Sent Successfully"}
          />
        ) : null}
        <Container className="contact-form-container">
          <Card className="contact-form-card">
            <Card.Img
              variant="top"
              className="login-form-top-img"
              src={loginCardBanner}
            />
            <Card.Title className="cf-title">
              <span className="cf-contact-span">Contact Us</span>
            </Card.Title>
            <Card.Body className="contact-form-card-body">
              <Form
                className="form-wrapper-contactpage"
                onSubmit={this.submitHandler}
              >
                <Form.Group controlId="reasonselect">
                  <Form.Label>Reason</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.reasonChange}
                    isInvalid={this.reasonInvalid}
                    value={this.state.reason}
                    className={
                      this.state.reasonInvalid ? "selectinvalid" : null
                    }
                  >
                    <option value="" disabled selected hidden>
                      Select Your Reason
                    </option>
                    <option>Support & General Inquires</option>
                    <option>Trouble Logging In or Creating Account</option>
                    <option>Advertising & Partnerships</option>
                    <option>Copyright Infringement</option>
                    <option>Job Opportunities</option>
                    <option>Report Abuse</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="email-contact">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    onChange={this.emailChange}
                    isInvalid={this.state.emailInvalid}
                    value={this.state.email}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Your Username</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={this.usernameChange}
                    isInvalid={this.state.usernameInvalid}
                    value={this.state.username}
                  />
                </Form.Group>
                <Form.Group controlId="support-message">
                  <Form.Label>Your Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    onChange={this.messageChange}
                    isInvalid={this.state.messageInvalid}
                    value={this.state.message}
                  />
                </Form.Group>
                <Button
                  type="submit"
                  className="contact-form-submit-btn"
                  disabled={this.state.disabledBtn}
                >
                  Send Message
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

export default ContactForm;
