import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Image, Button } from "react-bootstrap";
import whiteLogo from "../../../assets/logos/logo_white.png";

export class ArrangementCta extends Component {
  render() {
    return (
      <div className="join-now-cta-container">
        <div className="sd-cta-img-wrap">
          <Image className="sd-cta-img" src={whiteLogo} />
        </div>
        <h2 className="sd-cta-title">Try a New Dating Experience</h2>
        <p className="sd-cta-content">
          Here at Sugarbae, we ensure an experience that leads to finding a
          genuine, fulfilling, and emotionally meaningful connection.
        </p>
        <div className="sd-cta-btn-wrap">
          <Link to="/signup" className="sd-cta-link">
            <Button className="sd-cta-btn" variant="outline-light">
              Join Free
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default ArrangementCta;
