import React, { useEffect, useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import ReportBug from "../../../support/supportComponents/reportBugForm/ReportBug";
import ReportUser from "../../../support/supportComponents/reportUserForm/ReportUser";
import "./supportTab.scss";

function SupportTab() {
  const context = useContext(UserContext);

  const [faqTabs, setFaqTabs] = useState({
    cancelPremium: false,
    refund: false,
    reportUser: false,
    reportBug: false,
    other: false,
  });

  const [reportUserOpen, setReportUserOpen] = useState(false);
  const [reportBugOpen, setReportBugOpen] = useState(false);

  useEffect(() => {
    intercomFunction();

    return () => {
      var w = window;
      var ic = w.Intercom;
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    };
  }, []);

  const intercomFunction = (e) => {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/nnlt04yu";
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (document.readyState === "complete") {
        l();
      } else if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }

    window.Intercom("boot", {
      app_id: "nnlt04yu",
      username: context.user.username, // Full name
      email: context.user.email, // Email address
    });
    window.Intercom("update", {
      hide_default_launcher: false,
    });
  };

  const faqTabHandler = (props) => (e) => {
    if (props === "cancelPremium") {
      if (faqTabs.cancelPremium) {
        setFaqTabs({ ...faqTabs, cancelPremium: false });
      } else {
        setFaqTabs({ ...faqTabs, cancelPremium: true });
      }
    } else if (props === "refund") {
      if (faqTabs.refund) {
        setFaqTabs({ ...faqTabs, refund: false });
      } else {
        setFaqTabs({ ...faqTabs, refund: true });
      }
    } else if (props === "reportUser") {
      if (faqTabs.reportUser) {
        setFaqTabs({ ...faqTabs, reportUser: false });
      } else {
        setFaqTabs({ ...faqTabs, reportUser: true });
      }
    } else if (props === "reportBug") {
      if (faqTabs.reportBug) {
        setFaqTabs({ ...faqTabs, reportBug: false });
      } else {
        setFaqTabs({ ...faqTabs, reportBug: true });
      }
    } else if (props === "other") {
      if (faqTabs.other) {
        setFaqTabs({ ...faqTabs, other: false });
      } else {
        setFaqTabs({ ...faqTabs, other: true });
      }
    }
  };

  return (
    <div className="desktop-profile-tab-component">
      <div className="desktop-profile-tab-header">
        <h1 className="desktop-profile-tab-header-title">Support & FAQ</h1>
      </div>
      <div className="desktop-profile-tab-content-container">
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap-verifications">
            <h3 className="desktop-profile-tab-section-title-verifications">
              Contact Support
            </h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper-support">
            <p className="desktop-profile-tab-support-content">
              You can contact Sugarbae support at{" "}
              <strong>support@sugarbae.com</strong> or use the LiveChat icon in
              the bottom right hand corner of this page. <br></br>For support
              with billing and subscriptions, you can visit the{" "}
              <a
                className="ccbill-support-link"
                href="https://support.ccbill.com/"
              >
                CCBill Support Website.
              </a>
            </p>
          </div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap-verifications">
            <h3 className="desktop-profile-tab-section-title-verifications">
              Frequently Asked Questions
            </h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper-support">
            <div className="desktop-profile-faq-wrapper">
              <div
                className="desktop-profile-faq-item"
                onClick={faqTabHandler("cancelPremium")}
              >
                <div className="desktop-profile-faq-header">
                  <h4 className="desktop-profile-faq-title">
                    How do I cancel my Premium Subscription?
                  </h4>
                  {faqTabs.cancelPremium ? (
                    <i className="fas fa-chevron-up desktop-faq-down-icon"></i>
                  ) : (
                    <i className="fas fa-chevron-right desktop-faq-down-icon"></i>
                  )}
                </div>
                {faqTabs.cancelPremium && (
                  <div className="desktop-profile-faq-content-wrapper">
                    <p className="desktop-profile-faq-content">
                      You can cancel your Premium subscription on our desktop
                      app by navigating to Profile > Account and clicking
                      "Cancel Subscription".{" "}
                    </p>

                    <p className="desktop-profile-faq-content">
                      Having difficulty cancelling? You can contact us via Live
                      Chat on this page, or email us at support@sugarbae.com.
                      You can also cancel through CCBill by visiting the{" "}
                      <a
                        className="ccbill-support-link"
                        href="https://support.ccbill.com/"
                      >
                        CCBill support website.
                      </a>
                    </p>
                  </div>
                )}
              </div>
              <div
                className="desktop-profile-faq-item"
                onClick={faqTabHandler("refund")}
              >
                <div className="desktop-profile-faq-header">
                  <h4 className="desktop-profile-faq-title">
                    How do I request a refund?
                  </h4>
                  {faqTabs.refund ? (
                    <i className="fas fa-chevron-up desktop-faq-down-icon"></i>
                  ) : (
                    <i className="fas fa-chevron-right desktop-faq-down-icon"></i>
                  )}
                </div>
                {faqTabs.refund && (
                  <div className="desktop-profile-faq-content-wrapper">
                    <p className="desktop-profile-faq-content">
                      If you were charged incorrectly and would like to request
                      a refund, please message us using the LiveChat on this
                      page, or email <strong>support@sugarbae.com</strong>
                    </p>

                    <p className="desktop-profile-faq-content">
                      You can also contact CCBill support directly by visiting
                      the{" "}
                      <a
                        className="ccbill-support-link"
                        href="https://support.ccbill.com/"
                      >
                        CCBill support website.
                      </a>
                    </p>
                  </div>
                )}
              </div>
              <div
                className="desktop-profile-faq-item"
                onClick={faqTabHandler("reportUser")}
              >
                <div className="desktop-profile-faq-header">
                  <h4 className="desktop-profile-faq-title">
                    How do I report a User?
                  </h4>
                  {faqTabs.reportUser ? (
                    <i className="fas fa-chevron-up desktop-faq-down-icon"></i>
                  ) : (
                    <i className="fas fa-chevron-right desktop-faq-down-icon"></i>
                  )}
                </div>
                {faqTabs.reportUser && (
                  <div className="desktop-profile-faq-content-wrapper">
                    <p className="desktop-profile-faq-content">
                      If you encountered a user who breaks our terms of service,
                      please fill out the User report form on this page.
                    </p>

                    <p className="desktop-profile-faq-content">
                      You can also contact Sugarbae support via LiveChat or at{" "}
                      <strong>support@sugarbae.com.</strong> Please include
                      details about the User you are reporting, and any evidence
                      including screenshots of text conversions.
                    </p>
                  </div>
                )}
              </div>
              <div
                className="desktop-profile-faq-item"
                onClick={faqTabHandler("reportBug")}
              >
                <div className="desktop-profile-faq-header">
                  <h4 className="desktop-profile-faq-title">
                    How do I report a Bug?
                  </h4>
                  {faqTabs.reportBug ? (
                    <i className="fas fa-chevron-up desktop-faq-down-icon"></i>
                  ) : (
                    <i className="fas fa-chevron-right desktop-faq-down-icon"></i>
                  )}
                </div>
                {faqTabs.reportBug && (
                  <div className="desktop-profile-faq-content-wrapper">
                    <p className="desktop-profile-faq-content">
                      If you encountered an issue or bug with out application,
                      please submit the bug report form on this page.
                    </p>

                    <p className="desktop-profile-faq-content">
                      You can also contact Sugarbae support via LiveChat or at{" "}
                      <strong>support@sugarbae.com.</strong> Please include
                      details about the bug you are experiecing, and include
                      screenshots and/or videos.
                    </p>
                  </div>
                )}
              </div>
              <div
                className="desktop-profile-faq-item"
                onClick={faqTabHandler("other")}
              >
                <div className="desktop-profile-faq-header">
                  <h4 className="desktop-profile-faq-title">
                    I have another question or issue.
                  </h4>
                  {faqTabs.other ? (
                    <i className="fas fa-chevron-up desktop-faq-down-icon"></i>
                  ) : (
                    <i className="fas fa-chevron-right desktop-faq-down-icon"></i>
                  )}
                </div>
                {faqTabs.other && (
                  <div className="desktop-profile-faq-content-wrapper">
                    <p className="desktop-profile-faq-content">
                      For any other questions or inquiries, you can message Live
                      Chat support on this page or email us at{" "}
                      <strong>support@sugarbae.com</strong>
                    </p>

                    <p className="desktop-profile-faq-content">
                      For billing and subscription questions or issues, you can
                      contact{" "}
                      <a
                        className="ccbill-support-link"
                        href="https://support.ccbill.com/"
                      >
                        CCBill support.
                      </a>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap-verifications">
            <h3 className="desktop-profile-tab-section-title-verifications">
              Report a User
            </h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper-support">
            <ReportUser />
          </div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap-verifications">
            <h3 className="desktop-profile-tab-section-title-verifications">
              Report a Bug
            </h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper-support">
            <ReportBug />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportTab;
