import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";

function Ethnicity(props) {
  var filters = props.filters;
  const [ethnicity, setEthnicity] = useState(filters.ethnicity);

  const changeHandler = (e) => {
    var ethnicityCopy = ethnicity;

    if (e.target.value === "white") {
      if (ethnicityCopy.white) {
        ethnicityCopy.white = false;
      } else {
        ethnicityCopy.white = true;
      }
    } else if (e.target.value === "black") {
      if (ethnicityCopy.black) {
        ethnicityCopy.black = false;
      } else {
        ethnicityCopy.black = true;
      }
    } else if (e.target.value === "asian") {
      if (ethnicityCopy.asian) {
        ethnicityCopy.asian = false;
      } else {
        ethnicityCopy.asian = true;
      }
    } else if (e.target.value === "hispanic") {
      if (ethnicityCopy.hispanic) {
        ethnicityCopy.hispanic = false;
      } else {
        ethnicityCopy.hispanic = true;
      }
    } else if (e.target.value === "indian") {
      if (ethnicityCopy.indian) {
        ethnicityCopy.indian = false;
      } else {
        ethnicityCopy.indian = true;
      }
    } else if (e.target.value === "middleeastern") {
      if (ethnicityCopy.middleeastern) {
        ethnicityCopy.middleeastern = false;
      } else {
        ethnicityCopy.middleeastern = true;
      }
    } else if (e.target.value === "other") {
      if (ethnicityCopy.other) {
        ethnicityCopy.other = false;
      } else {
        ethnicityCopy.other = true;
      }
    }

    setEthnicity((prevState) => {
      return { ...prevState, ...ethnicityCopy };
    });
  };

  const saveHandler = (e) => {
    props.updateHandler(ethnicity);
  };

  return (
    <div className="filter-slider-component">
      <div className="filter-slider-overlay" onClick={props.onClose} />
      <div className="filter-slider-content">
        <div className="filter-slider-title-wrap">
          <i className="far fa-heart filter-sort-icon"></i>
          <h2 className="filter-slider-title">Ethnicity</h2>
        </div>
        <div className="filter-slider-wrapper">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"White"}
                type="checkbox"
                value="white"
                checked={ethnicity.white}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Black"}
                type="checkbox"
                value="black"
                checked={ethnicity.black}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Asian"}
                type="checkbox"
                value="asian"
                checked={ethnicity.asian}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Hispanic"}
                type="checkbox"
                value="hispanic"
                checked={ethnicity.hispanic}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Indian"}
                type="checkbox"
                value="indian"
                checked={ethnicity.indian}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Middle Eastern"}
                type="checkbox"
                value="middleeastern"
                checked={ethnicity.middleeastern}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Other"}
                type="checkbox"
                value="other"
                checked={ethnicity.other}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="filter-save-button-wrapper">
          <Button className="filter-save-button" onClick={saveHandler}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Ethnicity;
