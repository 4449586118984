import React, { useState, useContext, useRef, useEffect } from "react";
import "./editscreensstyles.scss";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import FullName from "./FullName";
import BirthDate from "./BirthDate";
import Gender from "./Gender";
import Address from "./Address";
var moment = require("moment");

function Identity(props) {
  const context = useContext(UserContext);
  const [editNameOpen, setEditNameOpen] = useState(false);
  const [birthDateOpen, setBirthDateOpen] = useState(false);
  const [genderOpen, setGenderOpen] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);
  const [verifyOpen, setVerifyOpen] = useState(false);

  const toggleTab = (e) => {
    props.toggleTab();
  };

  const toggleEditName = (e) => {
    if (editNameOpen) {
      setEditNameOpen(false);
    } else {
      setEditNameOpen(true);
    }
  };

  const toggleBirthDate = (e) => {
    if (birthDateOpen) {
      setBirthDateOpen(false);
    } else {
      setBirthDateOpen(true);
    }
  };

  const toggleGender = (e) => {
    if (genderOpen) {
      setGenderOpen(false);
    } else {
      setGenderOpen(true);
    }
  };

  const toggleAddress = (e) => {
    if (addressOpen) {
      setAddressOpen(false);
    } else {
      setAddressOpen(true);
    }
  };

  const verifyToggle = (e) => {
    if (verifyOpen) {
      setVerifyOpen(false);
    } else {
      setVerifyOpen(true);
    }
  };

  // Full Name
  var nameCompleted = false;
  var firstName = null;
  var middleName = null;
  var lastName = null;
  if (context.user.identity) {
    firstName = context.user.identity.firstName;
    middleName = context.user.identity.middleName;
    lastName = context.user.identity.lastName;

    if (firstName && lastName) {
      if (firstName.length > 0 && lastName.length > 0) {
        nameCompleted = true;
      }
    }
  }
  var fullName = firstName + " " + middleName + " " + lastName;

  // Birth Date
  var birthDateCompleted = false;
  var birthDate = null;
  var birthDateText = "";
  if (context.user.identity) {
    birthDate = context.user.identity.birthDate;
    if (birthDate) {
      birthDateText = moment(birthDate).format("YYYY-MM-DD").toString();
      birthDateCompleted = true;
    }
  }

  // Gender
  var genderCompleted = false;
  var genderText = "Male";

  if (context.user.identity) {
    if (context.user.identity.gender) {
      var gender = context.user.identity.gender;
      if (gender.male || gender.female || gender.other) {
        genderCompleted = true;
        if (gender.male) {
          genderText = "Male";
        } else if (gender.female) {
          genderText = "Female";
        } else if (gender.other) {
          genderText = "Other";
        }
      }
    }
  }

  var addressCompleted = false;

  var identityVerified = context.user.identity.verified;
  var infoCompleted = false;
  if (
    nameCompleted &&
    birthDateCompleted &&
    genderCompleted &&
    addressCompleted
  ) {
    infoCompleted = true;
  }

  var addressText = "51 Maher Ave, Toronto ON";

  if (editNameOpen) {
    return <FullName toggleSubTab={toggleEditName} />;
  } else if (birthDateOpen) {
    return <BirthDate toggleSubTab={toggleBirthDate} />;
  } else if (genderOpen) {
    return <Gender toggleSubTab={toggleGender} />;
  } else if (addressOpen) {
    return <Address toggleSubTab={toggleAddress} />;
  } else {
    return (
      <div className="edit-screen">
        <div className="edit-screen-header">
          <div className="edit-screen-header-section-left">
            <div
              className="edit-screen-header-return-button"
              onClick={toggleTab}
            >
              <i className="fas fa-chevron-left"></i>
            </div>
          </div>
          <div className="edit-screen-header-section-middle">
            <h1 className="edit-screen-header-title">Identity</h1>
          </div>
        </div>
        <div className="edit-screen-content">
          <div className="edit-screen-v2-section">
            <div className="edit-screen-v2-section-content">
              <div
                className="edit-screen-v2-section-row"
                onClick={toggleEditName}
              >
                <div className="edit-screen-v2-section-row-title-full-width">
                  Full Name
                </div>
                {!nameCompleted ? (
                  <div className="edit-screen-v2-section-row-content">
                    <div className="settings-v2-connect-button">Required</div>
                  </div>
                ) : (
                  <div className="edit-screen-v2-section-row-content">
                    {fullName}
                  </div>
                )}
                <div className="edit-screen-v2-section-row-action">
                  <i className="far fa-chevron-right  settings-v2-right-icon"></i>
                </div>
              </div>
              <div
                className="edit-screen-v2-section-row"
                onClick={toggleBirthDate}
              >
                <div className="edit-screen-v2-section-row-title-full-width">
                  Birth Date
                </div>
                {!birthDateCompleted ? (
                  <div className="edit-screen-v2-section-row-content">
                    <div className="settings-v2-connect-button">Required</div>
                  </div>
                ) : (
                  <div className="edit-screen-v2-section-row-content">
                    {birthDateText}
                  </div>
                )}
                <div className="edit-screen-v2-section-row-action">
                  <i className="far fa-chevron-right  settings-v2-right-icon"></i>
                </div>
              </div>
              <div
                className="edit-screen-v2-section-row"
                onClick={toggleBirthDate}
              >
                <div className="edit-screen-v2-section-row-title-full-width">
                  Verified
                </div>
                {!birthDateCompleted ? (
                  <div className="edit-screen-v2-section-row-content">
                    <div className="settings-v2-connect-button">Required</div>
                  </div>
                ) : (
                  <div className="edit-screen-v2-section-row-content">
                    {birthDateText}
                  </div>
                )}
                <div className="edit-screen-v2-section-row-action">
                  <i className="far fa-chevron-right  settings-v2-right-icon"></i>
                </div>
              </div>
              {/* <div
                className="edit-screen-v2-section-row"
                onClick={toggleGender}
              >
                <div className="edit-screen-v2-section-row-title-full-width">
                  Gender
                </div>
                {!genderCompleted ? (
                  <div className="edit-screen-v2-section-row-content">
                    <Button className="settings-v2-connect-button">
                      Required
                    </Button>
                  </div>
                ) : (
                  <div className="edit-screen-v2-section-row-content">
                    {genderText}
                  </div>
                )}
                <div className="edit-screen-v2-section-row-action">
                  <i className="far fa-chevron-right  settings-v2-right-icon"></i>
                </div>
              </div> */}
              {/* <div
                className="edit-screen-v2-section-row"
                onClick={toggleAddress}
              >
                <div className="edit-screen-v2-section-row-title-full-width">
                  Address
                </div>
                {!addressCompleted ? (
                  <div className="edit-screen-v2-section-row-content">
                    <Button className="settings-v2-connect-button">
                      Required
                    </Button>
                  </div>
                ) : (
                  <div className="edit-screen-v2-section-row-content">
                    {addressText}
                  </div>
                )}
                <div className="edit-screen-v2-section-row-action">
                  <i className="far fa-chevron-right  settings-v2-right-icon"></i>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {!identityVerified && infoCompleted && (
          <div className="edit-screen-cta-button-wrapper">
            <div
              className="edit-screen-cta-button-upgrade"
              size="lg"
              onClick={verifyToggle}
            >
              Verify Identity
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Identity;
