import React, { Component } from "react";
import { UserContext } from "../../../context/UserContext";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Premium from "../../../components/premium/Premium";
import PremiumSuccess from "../../../components/premium/premiumSuccess/PremiumSuccess";

export class PremiumSuccessPage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // window.dataLayer.push({ event: "Purchase" });

    let vh = window.innerHeight;
    var height = vh + "px";
    return (
      <div
        className="app-component"
        style={{ height: height, maxHeight: height }}
      >
        <PremiumSuccess />
      </div>
    );
  }
}

export default withRouter(PremiumSuccessPage);
