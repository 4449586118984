import React, { useState, useEffect, useContext, useRef } from "react";
import "../desktopprofiletabs.scss";
import { UserContext } from "../../../../context/UserContext";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Card,
  Form,
  Image,
  DropdownButton,
  Dropdown,
  Badge,
  Alert,
} from "react-bootstrap";
import EmailValidator from "email-validator";
import Axios from "axios";
import PasswordValidator from "password-validator";
import PhoneInput from "react-phone-input-2";
import "./settingstab.scss";
import { api } from "../../../../api";

var passwordSchema = new PasswordValidator();
var passwordCriteria = passwordSchema
  .is()
  .min(6)
  .is()
  .max(100)
  .has()
  .not()
  .spaces();

function SettingsTab(props) {
  const context = useContext(UserContext);

  const [email, setEmail] = useState(props.user.email);
  const [newEmail, setNewEmail] = useState("");
  const [emailInputDisabled, setEmailInputDisabled] = useState(true);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [emailErrorAlert, setEmailErrorAlert] = useState(false);
  const [emailDoesNotMatchError, setEmailDoesNotMatchError] = useState(false);
  const [emailChangeSuccess, setEmailChangeSuccess] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmEmailInvalid, setConfirmEmailInvalid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [passwordErrorAlert, setPasswordErrorAlert] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordInvalid, setNewPasswordInvalid] = useState(false);
  const [updatePasswordOpen, setUpdatePasswordOpen] = useState(false);
  const [updatePasswordSucccess, setUpdatePasswordSuccess] = useState(false);
  const [preferences, setPreferences] = useState(props.user.preferences);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false);
  const [updatePhoneNumberSuccess, setUpdatePhoneNumberSuccess] = useState(
    false
  );

  const [phoneInputDisabled, setPhoneInputDisabled] = useState(true);
  const [phoneNumberUpdateError, setPhoneNumberUpdateError] = useState(false);

  useEffect(() => {
    if (props.user.verifications.phone.number) {
      setPhoneNumber(props.user.verifications.phone.number);
    }
  }, []);

  const emailChange = (e) => {
    setNewEmail(e.target.value);
    setEmailInvalid(false);
    setEmailErrorAlert(false);
    setEmailDoesNotMatchError(false);
  };

  const confirmEmailChange = (e) => {
    setConfirmEmail(e.target.value);
    setEmailInvalid(false);
    setConfirmEmailInvalid(false);
    setEmailErrorAlert(false);
    setEmailDoesNotMatchError(false);
  };

  const passwordChange = (e) => {
    setPassword(e.target.value);
  };

  const newPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setPasswordInvalid(false);
    setPasswordErrorAlert(false);
    setNewPasswordInvalid(false);
  };

  const toggleEmailInputDisabled = (e) => {
    if (emailInputDisabled) {
      setEmailInputDisabled(false);
    } else {
      setEmailInputDisabled(true);
    }
  };

  const submitUpdateEmail = async (e) => {
    e.preventDefault();
    var emailValid = EmailValidator.validate(newEmail);

    if (emailValid) {
      if (newEmail !== confirmEmail) {
        setEmailDoesNotMatchError(true);
        setConfirmEmailInvalid(true);
        setEmailInvalid(true);
      } else if (password.length < 3) {
        setPasswordInvalid(true);
        setPasswordError(true);
      } else {
        const data = {
          userid: context.user._id,
          email: newEmail,
          password: password,
        };

        return api
          .updateEmail(data)
          .then((res) => {
            if (res.status === 200) {
              setEmailChangeSuccess(true);
              context.updateUserInfo("email", newEmail);
            }
          })
          .catch((err) => {
            console.log(err);
            setEmailInvalid(true);
            setConfirmEmailInvalid(true);
            setEmailErrorAlert(true);
            setPasswordInvalid(true);
            setPassword("");
          });
      }
    } else {
      setEmailInvalid(true);
      setConfirmEmailInvalid(true);
      setEmailErrorAlert(true);
      setPassword("");
    }
  };

  const submitUpdatePassword = async (e) => {
    e.preventDefault();

    if (
      !password ||
      password.length < 3 ||
      !newPassword ||
      !passwordCriteria.validate(newPassword)
    ) {
      setPasswordInvalid(true);
      setNewPasswordInvalid(true);
      setPasswordErrorAlert(true);
    } else {
      const data = {
        newpassword: newPassword,
        currentpassword: password,
        _id: context.user._id,
      };

      return api
        .updatePassword(data)
        .then((res) => {
          if (res.status === 200) {
            setUpdatePasswordSuccess(true);
          }
        })
        .catch((err) => {
          setPasswordInvalid(true);
          setNewPasswordInvalid(true);
          setPasswordErrorAlert(true);
        });
    }
  };

  const toggleUpdatePassword = (e) => {
    if (updatePasswordOpen) {
      setUpdatePasswordOpen(false);
    } else {
      setUpdatePasswordOpen(true);
    }
  };

  const preferencesChange = (action) => async (e) => {
    var preferencesCopy = preferences;
    if (action === "emailMessages") {
      if (preferencesCopy.email.messages) {
        preferencesCopy.email.messages = false;
      } else {
        preferencesCopy.email.messages = true;
      }
    } else if (action === "emailFavorites") {
      if (preferencesCopy.email.favorites) {
        preferencesCopy.email.favorites = false;
      } else {
        preferencesCopy.email.favorites = true;
      }
    } else if (action === "emailMatches") {
      if (preferencesCopy.email.matches) {
        preferencesCopy.email.matches = false;
      } else {
        preferencesCopy.email.matches = true;
      }
    } else if (action === "textMessages") {
      if (preferencesCopy.text.messages) {
        preferencesCopy.text.messages = false;
      } else {
        preferencesCopy.text.messages = true;
      }
    } else if (action === "textFavorites") {
      if (preferencesCopy.text.favorites) {
        preferencesCopy.text.favorites = false;
      } else {
        preferencesCopy.text.favorites = true;
      }
    } else if (action === "textMatches") {
      if (preferencesCopy.text.matches) {
        preferencesCopy.text.matches = false;
      } else {
        preferencesCopy.text.matches = true;
      }
    }

    setPreferences((previousPreferences) => {
      return {
        ...previousPreferences,
        preferencesCopy,
      };
    });

    const data = {
      userid: context.user._id,
      preferences: preferencesCopy,
    };

    return api
      .updatePreferences(data)
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {});
  };

  const phoneChangeHandler = (e) => {
    setPhoneNumber(e);
    setPhoneNumberInvalid(false);
    setPhoneNumberUpdateError(false);
  };

  const togglePhoneInputDisabled = (e) => {
    if (phoneInputDisabled) {
      setPhoneInputDisabled(false);
    } else {
      setPhoneInputDisabled(true);
    }
  };

  const updateNumberSubmit = async (e) => {
    e.preventDefault();
    const data = {
      userid: context.user._id,
      phoneNumber: phoneNumber,
    };

    return api
      .updatePhoneNumber(data)
      .then((res) => {
        if (res.status === 200) {
          setUpdatePhoneNumberSuccess(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setPhoneNumberInvalid(true);
        setPhoneNumberUpdateError(true);
      });
  };

  return (
    <div className="desktop-profile-tab-component">
      <div className="desktop-profile-tab-header">
        <h1 className="desktop-profile-tab-header-title">Account Settings</h1>
      </div>
      <div className="desktop-profile-tab-content-container">
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Email Address</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper-settings">
            {emailChangeSuccess ? (
              <Alert variant="success">
                Successfully updated your email address.{" "}
                <Link to="/app/profile/verifications">
                  Click here to verify your new email
                </Link>
              </Alert>
            ) : (
              <Form
                className="desktop-profile-form"
                onSubmit={submitUpdateEmail}
              >
                {emailErrorAlert && (
                  <Alert variant="danger">
                    Incorrect email or password. Please try again.
                  </Alert>
                )}
                <Form.Group className="desktop-profile-form-group">
                  {emailInputDisabled ? (
                    <Form.Control
                      type="email"
                      className="desktop-profile-form-input"
                      value={email}
                      onChange={emailChange}
                      disabled={true}
                      placeholder={email}
                    />
                  ) : (
                    <Form.Control
                      type="email"
                      className="desktop-profile-form-input"
                      value={newEmail}
                      onChange={emailChange}
                      disabled={emailInputDisabled}
                      placeholder="New email address"
                      isInvalid={emailInvalid}
                    />
                  )}
                </Form.Group>

                {!emailInputDisabled && (
                  <Form.Group className="desktop-profile-form-group">
                    <Form.Control
                      type="email"
                      className="desktop-profile-form-input"
                      value={confirmEmail}
                      onChange={confirmEmailChange}
                      placeholder="Confirm new email address"
                      isInvalid={confirmEmailInvalid}
                    />
                  </Form.Group>
                )}
                {!emailInputDisabled && (
                  <Form.Group className="desktop-profile-form-group">
                    <Form.Control
                      type="password"
                      className="desktop-profile-form-input"
                      value={password}
                      onChange={passwordChange}
                      placeholder="Your password"
                      isInvalid={passwordInvalid}
                    />
                  </Form.Group>
                )}
                {emailInputDisabled ? (
                  <div className="desktop-profile-edit-button-wrapper">
                    <Button
                      className="desktop-profile-edit-email-button"
                      variant="link"
                      onClick={toggleEmailInputDisabled}
                    >
                      Edit Email
                    </Button>
                  </div>
                ) : (
                  <div className="desktop-profile-edit-button-wrapper-submit">
                    <Button
                      className="desktop-profile-edit-cancel-button"
                      variant="link"
                      onClick={toggleEmailInputDisabled}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="desktop-profile-edit-submit-button"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </Form>
            )}
          </div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Phone Number</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper-settings">
            {updatePhoneNumberSuccess ? (
              <Alert variant="success">
                Successfully updated your phone number.{" "}
                <Link to="/app/profile/verifications">
                  Click here to verify your new number
                </Link>
              </Alert>
            ) : (
              <Form
                className="desktop-profile-form"
                onSubmit={updateNumberSubmit}
              >
                {phoneNumberUpdateError && (
                  <Alert variant="danger">
                    Error updating your phone number. Please try again{" "}
                  </Alert>
                )}
                <Form.Group className="desktop-profile-form-group">
                  <PhoneInput
                    country={"us"}
                    value={phoneNumber}
                    placeholer={phoneNumber}
                    onChange={phoneChangeHandler}
                    preferredCountries={["us", "ca", "gb"]}
                    disabled={phoneInputDisabled}
                  />
                </Form.Group>
                {phoneInputDisabled ? (
                  <div className="desktop-profile-edit-button-wrapper">
                    <Button
                      className="desktop-profile-edit-email-button"
                      variant="link"
                      onClick={togglePhoneInputDisabled}
                    >
                      Edit Number
                    </Button>
                  </div>
                ) : (
                  <div className="desktop-profile-edit-button-wrapper-submit">
                    <Button
                      className="desktop-profile-edit-cancel-button"
                      variant="link"
                      onClick={togglePhoneInputDisabled}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="desktop-profile-edit-submit-button"
                      type="submit"
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </Form>
            )}
          </div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Password</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper-settings">
            {updatePasswordSucccess ? (
              <Alert variant="success">
                Successfully updated your account password.{" "}
              </Alert>
            ) : (
              <div>
                {updatePasswordOpen ? (
                  <Form
                    className="desktop-profile-form"
                    onSubmit={submitUpdatePassword}
                  >
                    {passwordErrorAlert && (
                      <Alert variant="danger">
                        Incorrect password. Please try again. Password must be
                        6-100 characters long with no spaces.
                      </Alert>
                    )}
                    <Form.Group className="desktop-profile-form-group">
                      <Form.Label>Current Password</Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        isInvalid={passwordInvalid}
                        className="desktop-profile-form-input"
                        placeholder="Current password"
                        onChange={passwordChange}
                      />
                    </Form.Group>
                    <Form.Group className="desktop-profile-form-group">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        value={newPassword}
                        isInvalid={newPasswordInvalid}
                        className="desktop-profile-form-input"
                        placeholder="New password"
                        onChange={newPasswordChange}
                      />
                    </Form.Group>
                    <div className="desktop-profile-edit-button-wrapper-submit">
                      <Button
                        className="desktop-profile-edit-cancel-button"
                        variant="link"
                        onClick={toggleUpdatePassword}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="desktop-profile-edit-submit-button"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                ) : (
                  <Button
                    variant="link"
                    className="desktop-update-password-button"
                    onClick={toggleUpdatePassword}
                  >
                    Update Password
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Notifications</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper-settings">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Label>Email Notifications</Form.Label>
                <Form.Check
                  type="switch"
                  id="email-switchA"
                  label="When someone sends me a message"
                  checked={preferences.email.messages}
                  onChange={preferencesChange("emailMessages")}
                />
                <Form.Check
                  type="switch"
                  id="email-switchB"
                  label="When someone likes me"
                  checked={preferences.email.favorites}
                  onChange={preferencesChange("emailFavorites")}
                />
                <Form.Check
                  type="switch"
                  id="email-switchC"
                  label="When I get a new match"
                  checked={preferences.email.matches}
                  onChange={preferencesChange("emailMatches")}
                />
              </Form.Group>
              <Form.Group className="desktop-profile-form-group">
                <Form.Label>Text Notifications</Form.Label>
                <Form.Check
                  type="switch"
                  id="text-switchA"
                  label="When someone sends me a message"
                  checked={preferences.text.messages}
                  onChange={preferencesChange("textMessages")}
                />
                <Form.Check
                  type="switch"
                  id="text-switchB"
                  label="When someone likes me"
                  checked={preferences.text.favorites}
                  onChange={preferencesChange("textFavorites")}
                />
                <Form.Check
                  type="switch"
                  id="text-switchC"
                  label="When I get a new match"
                  checked={preferences.text.matches}
                  onChange={preferencesChange("textMatches")}
                />
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      <div className="desktop-profile-settings-footer">
        <div className="desktop-profile-settings-footer-innerwrap">
          <ul className="desktop-profile-settings-footer-list">
            <Link
              to="/app/report"
              className="desktop-profile-settings-footer-link"
            >
              <li className="desktop-profile-settings-footer-list-item">
                Help
              </li>
            </Link>
          </ul>
          <ul className="desktop-profile-settings-footer-list">
            <Link
              to="/app/privacy"
              className="desktop-profile-settings-footer-link"
            >
              <li className="desktop-profile-settings-footer-list-item">
                Privacy Policy
              </li>
            </Link>
          </ul>
          <ul className="desktop-profile-settings-footer-list">
            <Link
              to="/app/terms"
              className="desktop-profile-settings-footer-link"
            >
              <li className="desktop-profile-settings-footer-list-item">
                Terms of Service
              </li>
            </Link>
          </ul>
          {/* <ul className="desktop-profile-settings-footer-list">
            <Link
              to="/app/profile/account"
              className="desktop-profile-settings-footer-link"
            >
              <li className="desktop-profile-settings-footer-list-item">
                Manage Account
              </li>
            </Link>
          </ul> */}
        </div>
      </div>
    </div>
  );
}

export default withRouter(SettingsTab);
