import React, { useState, useEffect } from "react";
import { api } from "../../api";
import "./graphs.scss";
import {
  ComposedChart,
  BarChart,
  Bar,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
var moment = require("moment");

function Conversions(props) {
  //const [maleSignupData, setMaleSignupData] = useState([]);
  //const [femaleSignupData, setFemaleSignupData] = useState([]);
  const [transactionData, setConversionData] = useState([]); 
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    return api
      .fetchConversionData()
      .then((res) => {
        if (res.status === 200) {
          setConversionData(res.data.transactionData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderCustomAxisTick = ({ x, y, payload }) => {
    var date = payload.value;

    var formattedDate = moment(date).format("MMM DD").toString();

    return (
      <text x={x} y={y + 25} fill="#666" textAnchor="middle">
        {formattedDate}
      </text>
    );
  };
  return (
    <div className="graph-container">
      <div className="graph-header">
        <h1 className="graph-title">30 Days Daily Transaction by Count </h1>
      </div>
      <div className="graph-content-container">
        <div className="graph-content">
          <h2 className="graph-content-title">New Subscriptions, Rebill, Boost and Diamond Purchases </h2>
          <ComposedChart
            width={700}
            height={400}
            data={transactionData}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <XAxis dataKey="_id" tick={renderCustomAxisTick} />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation='right' />
            <Bar yAxisId="left" dataKey="newsub" fill="#3fb028" stackId = "a"/>
            <Bar yAxisId="left" dataKey="boost" fill="#28b087" stackId = "a"/>
            <Bar yAxisId="left" dataKey="diamond" fill="#608733" stackId = "a"/>
            <Line yAxisId="right" dataKey="rebill" stroke="#005eff"/>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

            <Tooltip />
          </ComposedChart>
        </div>
        <div className="graph-content">
          <h2 className="graph-content-title">Refund, Void and Chargeback </h2>
          <ComposedChart
            width={700}
            height={400}
            data={transactionData}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <XAxis dataKey="_id" tick={renderCustomAxisTick} />
            <YAxis yAxisId="left" />
            <Bar yAxisId="left" dataKey="refund" fill="#f08b4d" stackId = "a"/>
            <Bar yAxisId="left" dataKey="voided" fill="#ebad1c" stackId = "a"/>
            <Bar yAxisId="left" dataKey="chargeback" fill="#f0584d" stackId = "a"/>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />

            <Tooltip />
          </ComposedChart>
        </div>
      </div>
    </div>
  );
}

export default Conversions;
