import React, { useEffect, useState, useRef } from "react";
import { Image, Transformation } from "cloudinary-react";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";

import {
  getImgName,
  getImgVersion,
  checkGIF,
} from "../../../../../utils/getCloudinaryInfo";
import { api } from "../../../../../api"
import AdminMessage from "./AdminMessage";
import ChatUserInfo from "./ChatUserInfo";

import "../../../admincomponents.scss";

export const ChatView = props => {
  const [loading, setLoading] = useState(true);
  const [card, setCard] = useState(null);
  const [user, setUser] = useState(null);
  const [messages, setMessages] = useState([]);

  const bottomRef = useRef(null);

  useEffect(() => {
    const fetchData = () => {
      const data = {
        userid: props.chat.sender,
        chatid: props.chat.receiver,
        skip: 0,
      };

      return api
        .fetchMessages(data)
        .then((res) => {
          if (res.status === 200) {
            const { messages, card, user } = res.data;
            setCard(card);
            setUser(user);
            setMessages(messages);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
  },[]);

  useEffect(() => {
    if (messages.length > 0) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const closeHandler = () => {
    props.closeConversationHandler();
  };

  return (
    <div className="chat-view-panel">
      <div className="chat-view-header">
        <p className="chat-view-title">Coversation History</p>
        <div className="conversation-loading-spinner">
          <ClipLoader
            size={10}
            color={"#FFF"}
            loading={loading}
          />
        </div>
        <div className="chat-view-close" onClick={() => closeHandler()}>
          <span className="icon icon-large-close"></span>
        </div>
      </div>
      <div className="conv-users-info">
        <div className="conv-user-one">
          <ChatUserInfo user={props.chat.receiverUser} />
        </div>
        <div className="conv-user-two">
          <ChatUserInfo user={props.chat.senderUser} />
        </div>
      </div>
      <div className="conv-body">
        {messages.map((message, index) => {
          let timeStamp = false;
          let sent = false;

          if (message.senderusername === card.username ) {
            sent = true;
          }

          if (index > 0) {
            const prevIndex = index - 1;
            const prevMessage = messages[prevIndex];
            const date = moment(message.createdAt);
            const prevDate = moment(prevMessage.createdAt);
            const diff = date.diff(prevDate, "hours");

            if (diff > 5) {
              timeStamp = true;
            }
          }

          const data = {
            timeStamp,
            sent,
          };
          return (
            <AdminMessage message={message} data={data} user={user} card={card} />
          );
        })}
        <div ref={bottomRef} />
      </div>
    </div>
  )
};

export default ChatView;