import React, { useState, useEffect, useContext } from "react";
import "./desktopuserprofile.scss";
import DesktopNav from "../desktopNav/DesktopNav";
import premiumGif from "../../../assets/Images/welcome/premium-diamond.gif";
import { Image, Button, Badge } from "react-bootstrap";
import Axios from "axios";
import { UserContext } from "../../../context/UserContext";
import { Link, withRouter } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { Card } from "@material-ui/core";
import BasicInfoTab from "./basicInfoTab/BasicInfoTab";
import DescriptionsTab from "./descriptionsTab/DescriptionsTab";
import PhotosTab from "./photosTab/PhotosTab";
import VerificationsTab from "./verificationsTab/VerificationsTab";
import ProfileInfoTab from "./profileInfoTab/ProfileInfoTab";
import SettingsTab from "./settingsTab/SettingsTab";
import AccountTab from "./accountTab/AccountTab";
import SupportTab from "./supportTab/SupportTab";
import { api } from "../../../api";

function DesktopUserProfile(props) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("basicInformation");

  const context = useContext(UserContext);

  useEffect(() => {
    const url = props.location.pathname;

    if (url === "/app/profile") {
      setTab("basicInformation");
    } else if (url === "/app/profile/personal") {
      setTab("profileInformation");
    } else if (url === "/app/profile/photos") {
      setTab("photos");
    } else if (url === "/app/profile/descriptions") {
      setTab("descriptions");
    } else if (url === "/app/profile/verifications") {
      setTab("verifications");
    } else if (url === "/app/profile/settings") {
      setTab("settings");
    } else if (url === "/app/profile/account") {
      setTab("account");
    } else if (url === "/app/profile/support") {
      setTab("support");
    }

    const fetchData = async (e) => {
      const data = {
        userid: context.user._id,
      };

      return api
        .fetchOwnUser(data)
        .then((res) => {
          if (res.status === 200) {
            var user = res.data.user;
            setUser(user);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="desktop-user-profile-component">
        <DesktopNav />
        <div className="desktop-user-profile-container">
          <div className="desktop-user-profile-sidebar">
            <Link
              to="/app/profile"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "basicInformation"
                    ? "user-profile-sidebar-tab-active"
                    : "user-profile-sidebar-tab"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "basicInformation"
                        ? "user-profile-sidebar-tab-title-active"
                        : "user-profile-sidebar-tab-title"
                    }
                  >
                    Basic Information
                  </h2>
                </div>
              </div>
            </Link>
            <Link
              to="/app/profile/personal"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "profileInformation"
                    ? "user-profile-sidebar-tab-active sidebar-tab-profileInformation"
                    : "user-profile-sidebar-tab sidebar-tab-profileInformation"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "profileInformation"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-profileInformation-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-profileInformation-title"
                    }
                  >
                    Profile Information
                  </h2>
                </div>
              </div>
            </Link>{" "}
            <Link
              to="/app/profile/photos"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "photos"
                    ? "user-profile-sidebar-tab-active sidebar-tab-photos"
                    : "user-profile-sidebar-tab sidebar-tab-photos"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "photos"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-photos-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-photos-title"
                    }
                  >
                    Photos
                  </h2>
                </div>
              </div>
            </Link>
            <Link
              to="/app/profile/descriptions"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "descriptions"
                    ? "user-profile-sidebar-tab-active sidebar-tab-descriptions"
                    : "user-profile-sidebar-tab sidebar-tab-descriptions"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "descriptions"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-descriptions-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-descriptions-title"
                    }
                  >
                    Descriptions
                  </h2>
                </div>
              </div>
            </Link>{" "}
            <Link
              to="/app/profile/verifications"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "verifications"
                    ? "user-profile-sidebar-tab-active sidebar-tab-verifications"
                    : "user-profile-sidebar-tab sidebar-tab-verifications"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "verifications"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-verifications-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-verifications-title"
                    }
                  >
                    Verifications
                  </h2>
                </div>
              </div>
            </Link>
            <Link
              to="/app/profile/settings"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "settings"
                    ? "user-profile-sidebar-tab-active sidebar-tab-settings"
                    : "user-profile-sidebar-tab sidebar-tab-settings"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "settings"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-settings-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-settings-title"
                    }
                  >
                    Settings
                  </h2>
                </div>
              </div>
            </Link>
            <Link
              to="/app/profile/account"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "account"
                    ? "user-profile-sidebar-tab-active sidebar-tab-settings"
                    : "user-profile-sidebar-tab sidebar-tab-settings"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "account"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-settings-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-settings-title"
                    }
                  >
                    Account
                  </h2>
                </div>
              </div>
            </Link>
            <Link
              to="/app/profile/support"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "support"
                    ? "user-profile-sidebar-tab-active sidebar-tab-settings"
                    : "user-profile-sidebar-tab sidebar-tab-settings"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "support"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-settings-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-settings-title"
                    }
                  >
                    Support & FAQ
                  </h2>
                </div>
              </div>
            </Link>
          </div>{" "}
        </div>
      </div>
    );
  } else {
    var images = user.imgurl;
    var mainImage = null;
    if (images.length > 0) {
      mainImage = images[0];
    }

    return (
      <div className="desktop-user-profile-component">
        <DesktopNav />
        <div className="desktop-user-profile-container">
          <div className="desktop-user-profile-sidebar">
            <Link
              to="/app/profile"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "basicInformation"
                    ? "user-profile-sidebar-tab-active"
                    : "user-profile-sidebar-tab"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "basicInformation"
                        ? "user-profile-sidebar-tab-title-active"
                        : "user-profile-sidebar-tab-title"
                    }
                  >
                    Basic Information
                  </h2>
                </div>
              </div>
            </Link>
            <Link
              to="/app/profile/personal"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "profileInformation"
                    ? "user-profile-sidebar-tab-active sidebar-tab-profileInformation"
                    : "user-profile-sidebar-tab sidebar-tab-profileInformation"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "profileInformation"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-profileInformation-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-profileInformation-title"
                    }
                  >
                    Profile Information
                  </h2>
                </div>
              </div>
            </Link>{" "}
            <Link
              to="/app/profile/photos"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "photos"
                    ? "user-profile-sidebar-tab-active sidebar-tab-photos"
                    : "user-profile-sidebar-tab sidebar-tab-photos"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "photos"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-photos-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-photos-title"
                    }
                  >
                    Photos
                  </h2>
                </div>
              </div>
            </Link>
            <Link
              to="/app/profile/descriptions"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "descriptions"
                    ? "user-profile-sidebar-tab-active sidebar-tab-descriptions"
                    : "user-profile-sidebar-tab sidebar-tab-descriptions"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "descriptions"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-descriptions-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-descriptions-title"
                    }
                  >
                    Descriptions
                  </h2>
                </div>
              </div>
            </Link>{" "}
            <Link
              to="/app/profile/verifications"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "verifications"
                    ? "user-profile-sidebar-tab-active sidebar-tab-verifications"
                    : "user-profile-sidebar-tab sidebar-tab-verifications"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "verifications"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-verifications-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-verifications-title"
                    }
                  >
                    Verifications
                  </h2>
                </div>
              </div>
            </Link>
            <Link
              to="/app/profile/settings"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "settings"
                    ? "user-profile-sidebar-tab-active sidebar-tab-settings"
                    : "user-profile-sidebar-tab sidebar-tab-settings"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "settings"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-settings-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-settings-title"
                    }
                  >
                    Settings
                  </h2>
                </div>
              </div>
            </Link>
            <Link
              to="/app/profile/account"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "account"
                    ? "user-profile-sidebar-tab-active sidebar-tab-settings"
                    : "user-profile-sidebar-tab sidebar-tab-settings"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "account"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-settings-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-settings-title"
                    }
                  >
                    Account
                  </h2>
                </div>
              </div>
            </Link>
            <Link
              to="/app/profile/support"
              className="desktop-user-profile-sidebar-link"
            >
              <div
                className={
                  tab === "support"
                    ? "user-profile-sidebar-tab-active sidebar-tab-settings"
                    : "user-profile-sidebar-tab sidebar-tab-settings"
                }
              >
                <div className="user-profile-sidebar-tab-title-wrapper">
                  <h2
                    className={
                      tab === "support"
                        ? "user-profile-sidebar-tab-title-active sidebar-tab-settings-title"
                        : "user-profile-sidebar-tab-title sidebar-tab-settings-title"
                    }
                  >
                    Support & FAQ
                  </h2>
                </div>
              </div>
            </Link>
          </div>{" "}
          <div className="desktop-user-profile-content">
            {tab === "basicInformation" && <BasicInfoTab user={user} />}
            {tab === "profileInformation" && <ProfileInfoTab user={user} />}
            {tab === "photos" && <PhotosTab user={user} />}
            {tab === "descriptions" && <DescriptionsTab user={user} />}
            {tab === "verifications" && <VerificationsTab user={user} />}
            {tab === "settings" && <SettingsTab user={user} />}
            {tab === "account" && <AccountTab user={user} />}
            {tab === "support" && <SupportTab user={user} />}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DesktopUserProfile);
