import { urls } from './constants';
import { get, post } from './utils';

class APIManager {
  // ! Authentication (Auth.js)
  submitLogin = (data) => post(urls.SUBMIT_LOGIN, data);

  submitSignup = (data) => post(urls.SUBMIT_SIGNUP, data);

  submitRecaptcha = (data) => post(urls.SUBMIT_RECAPTCHA, data);

  checkEmail = (data) => post(urls.CHECK_EMAIL, data);

  checkUsername = (data) => post(urls.CHECK_USERNAME, data);

  fetchProtected = (params) => get(urls.FETCH_PROTECTED_URL, params);

  fetchNavbarData = (data) => post(urls.FETCH_NAVBAR_DATA, data);

  checkRiskIp = (data) => post(urls.PURCHASE_CHECK_RISK_IP, data);

  phoneLogin = (data) => post(urls.PHONE_LOGIN, data);

  phoneLoginVerify = (data) => post(urls.PHONE_LOGIN_VERIFY, data);

  phoneCheck = (data) => post(urls.PHONE_CHECK, data);

  // ! Subscription & Account Managementa
  cancelSubscription = (data) => post(urls.CANCEL_SUBSCRIPTION, data);

  manageSubscription = (data) => post(urls.MANAGE_SUBSCRIPTION, data);

  deactivateAccount = (data) => post(urls.DEACTIVATE_ACCOUNT, data);

  reactivateAccount = (data) => post(urls.REACTIVATE_ACCOUNT, data);

  resetPassword = (data) => post(urls.RESET_PASSWORD, data);

  validateResetToken = (data) => post(urls.VALIDATE_RESET_TOKEN, data);

  submitNewPassword = (data) => post(urls.SUBMIT_NEW_PASSWORD, data);

  updateEmail = (data) => post(urls.UPDATE_EMAIL, data);

  updatePassword = (data) => post(urls.UPDATE_PASSWORD, data);

  updatePhoneNumber = (data) => post(urls.UPDATE_PHONE_NUMBER, data);

  fetchEmail = (data) => post(urls.FETCH_EMAIL, data);

  fetchPhoneNumber = (data) => post(urls.FETCH_PHONE_NUMBER, data);

  connectSocialNetwork = (data) => post(urls.CONNECT_SOCIAL_NETWORK, data);

  getTwitterUserInfo = (data) => post(urls.GET_TWITTER_ACCOUNT_INFO, data);

  updateIdentity = (data) => post(urls.UPDATE_IDENTITY, data);

  updateIdKey = (data) => post(urls.UPDATE_IDKEY, data);

  saveVerificationVideo = (data) => post(urls.SAVE_VERIFICATION_VIDEO, data);

  // ! Verifications
  generateEmailToken = (data) => post(urls.GENERATE_EMAIL_TOKEN, data);

  validateEmailToken = (data) => post(urls.VALIDATE_EMAIL_TOKEN, data);

  generateSmsToken = (data) => post(urls.GENERATE_SMS_TOKEN, data);

  validateSmsToken = (data) => post(urls.VALIDATE_SMS_TOKEN, data);

  // !  Profile
  fetchChildren = (data) => post(urls.FETCH_CHILDREN, data);

  fetchDrinking = (data) => post(urls.FETCH_DRINKING, data);

  fetchEducation = (data) => post(urls.FETCH_EDUCATION, data);

  fetchExercise = (data) => post(urls.FETCH_EXERCISE, data);

  fetchSmoking = (data) => post(urls.FETCH_SMOKING, data);

  updateLifestyle = (data) => post(urls.UPDATE_LIFESTYLE, data);

  updateLifestyleFemale = (data) => post(urls.UPDATE_LIFESTYLE_FEMALE, data);

  fetchRelationshipStatus = (data) =>
    post(urls.FETCH_RELATIONSHIP_STATUS, data);

  fetchPhysicals = (data) => post(urls.FETCH_PHYSICALS, data);

  updateUsername = (data) => post(urls.UPDATE_USERNAME, data);

  updateGender = (data) => post(urls.UPDATE_GENDER, data);

  updateLocation = (data) => post(urls.UPDATE_LOCATION, data);

  updatePhysicals = (data) => post(urls.UPDATE_PHYSICALS, data);

  updateBioHeadline = (data) => post(urls.UPDATE_BIO_HEADLINE, data);

  updateChildren = (data) => post(urls.UPDATE_CHILDREN, data);

  updateSmoking = (data) => post(urls.UPDATE_SMOKING, data);

  updateDrinking = (data) => post(urls.UPDATE_DRINKING, data);

  updateCannabis = (data) => post(urls.UPDATE_CANNABIS, data);

  updateExercise = (data) => post(urls.UPDATE_EXERCISE, data);

  updatePreferences = (data) => post(urls.UPDATE_PREFERENCES, data);

  updateAdvancedFilters = (data) => post(urls.UPDATE_ADVANCED_FILTERS, data);

  updateBasicFilters = (data) => post(urls.UPDATE_BASIC_FILTERS, data);

  updateBodytype = (data) => post(urls.UPDATE_BODYTYPE, data);

  updateEthnicity = (data) => post(urls.UPDATE_ETHNICITY, data);

  updateEducation = (data) => post(urls.UPDATE_EDUCATION, data);

  updateRelationshipStatus = (data) =>
    post(urls.UPDATE_RELATIONSHIP_STATUS, data);

  updateNetworth = (data) => post(urls.UPDATE_NETWORTH, data);

  updateAnnualIncome = (data) => post(urls.UPDATE_ANNUAL_INCOME, data);

  updateHeadline = (data) => post(urls.UPDATE_HEADLINE, data);

  updateBio = (data) => post(urls.UPDATE_BIO, data);

  updateBenefits = (data) => post(urls.UPDATE_BENEFITS, data);

  updateAddress = (data) => post(urls.UPDATE_ADDRESS, data);

  updateProfileHeight = (data) => post(urls.UPDATE_PROFILE_HEIGHT, data);

  saveImages = (data) => post(urls.SAVE_IMAGES, data);

  savePrivateImages = (data) => post(urls.SAVE_PRIVATE_IMAGES, data);

  imageRekognition = (data) => post(urls.REKOGNITION_IMAGE, data);

  videoRekognitionText = (data) => post(urls.REKOGNITION_VIDEO_TEXT, data);

  videoRekognitionContent = (data) =>
    post(urls.REKOGNITION_VIDEO_CONTENT, data);

  fetchPremiumCheck = (data) => post(urls.PREMIUM_CHECK, data);

  fetchProfile = (data) => post(urls.FETCH_PROFILE, data);

  fetchOwnUser = (data) => post(urls.FETCH_OWN_USER, data);

  fetchBenefits = (data) => post(urls.FETCH_BENEFITS, data);

  fetchBio = (data) => post(urls.FETCH_BIO, data);

  fetchHeadline = (data) => post(urls.FETCH_HEADLINE, data);

  fetchProfileInfo = (data) => post(urls.FETCH_PROFILE_INFO, data);

  fetchImages = (data) => post(urls.FETCH_IMAGES, data);

  fetchVerificationStatus = (data) =>
    post(urls.FETCH_VERIFICATION_STATUS, data);

  fetchAccountSettings = (data) => post(urls.FETCH_ACCOUNT_SETTINGS, data);

  fetchPreferences = (data) => post(urls.FETCH_PREFERENCES, data);

  fetchProfileProgress = (data) => post(urls.FETCH_PROFILE_PROGRESS, data);

  fetchProfileInventory = (data) => post(urls.FETCH_PROFILE_INVENTORY, data);

  fetchPreferencesB = (data) => post(urls.FETCH_PREFERENCESB, data);

  fetchFilters = (data) => post(urls.FETCH_FILTERS, data);

  updateFilters = (data) => post(urls.UPDATE_FILTERS, data);

  updateDistance = (data) => post(urls.UPDATE_DISTANCE, data);

  updateAgeFilters = (data) => post(urls.UPDATE_AGE_FILTER, data);

  updateHidden = (data) => post(urls.UPDATE_HIDDEN, data);

  fetchDiamondCount = (data) => post(urls.FETCH_DIAMONDCOUNT, data);

  fetchAddressCoordinates = (data) =>
    post(urls.FETCH_ADDRESS_COORDINATES, data);

  fetchComplimentHistory = (data) => post(urls.FETCH_COMPLIMENT_HISTORY, data);

  updateBirthdate = (data) => post(urls.UPDATE_BIRTHDATE, data);

  uploadMedia = (data) => post(urls.UPLOAD_MEDIA, data);

  // video call
  makeVideoCall = () => post(urls.MAKE_VIDEO_CALL);

  getCallToken = (data) => post(urls.GET_CALL_TOKEN, data);

  // ! Swipe
  fetchSwipe = (data) => post(urls.FETCH_SWIPE_URL, data);

  swipeLike = (data) => post(urls.SWIPE_LIKE, data);

  swipeLikeCheck = (data) => post(urls.SWIPE_LIKE_CHECK, data);

  swipeDislike = (data) => post(urls.SWIPE_DISLIKE, data);

  swipeSuperlike = (data) => post(urls.SWIPE_SUPERLIKE, data);

  swipeSuperlikeCheck = (data) => post(urls.SWIPE_SUPERLIKE_CHECK, data);

  swipeUndo = (data) => post(urls.SWIPE_UNDO, data);

  swipeUndoCheck = (data) => post(urls.SWIPE_UNDO_CHECK, data);

  swipeRemoveLike = (data) => post(urls.SWIPE_REMOVELIKE, data);

  swipeBoost = (data) => post(urls.SWIPE_BOOST, data);

  swipeBoostV2 = (data) => post(urls.SWIPE_BOOST_V2, data);

  swipeNote = (data) => post(urls.SWIPE_NOTE, data);

  submitNote = (data) => post(urls.SUBMIT_NOTE, data);

  fetchMatchInfo = (data) => post(urls.FETCH_MATCH_INFO, data);

  fetchSwipeMatch = (data) => post(urls.SWIPE_MATCH, data);

  fetchBoost = (data) => post(urls.FETCH_BOOST, data);

  fetchBoostPop = (data) => post(urls.BOOST_POP, data);

  noteCheck = (data) => post(urls.NOTE_CHECK, data);

  likePop = (data) => post(urls.LIKE_POP, data);

  fetchNoteInvalidPop = (data) => post(urls.FETCH_NOTE_INVALID_POP, data);

  fetchSendNotePop = (data) => post(urls.FETCH_SEND_NOTE_POP, data);

  fetchSuperlikePopNoImage = (data) => post(urls.SUPERLIKE_POP_NOIMAGE, data);

  superlikePop = (data) => post(urls.SUPERLIKE_POP, data);

  fetchBoostData = (data) => post(urls.FETCH_BOOST_DATA, data);

  submitCompliment = (data) => post(urls.SUBMIT_COMPLIMENT, data);

  fetchComplimentIDs = () => post(urls.FETCH_COMPLIMENT_IDS);

  // ! Grid
  fetchGridNewest = (data) => post(urls.FETCH_GRID_NEWEST, data);

  fetchGridRecentlyActive = (data) =>
    post(urls.FETCH_GRID_RECENTLY_ACTIVE, data);

  fetchGridRecommended = (data) => post(urls.FETCH_GRID_RECOMMENDED, data);

  fetchSearchGrid = (data) => post(urls.FETCH_SEARCH_GRID, data);

  fetchHomeGrid = (data) => post(urls.FETCH_HOME_GRID, data);

  fetchDesktopGrid = (data) => post(urls.FETCH_DESKTOP_GRID, data);

  fetchLandingGrid = (data) => post(urls.FETCH_LANDING_GRID, data);

  // ! Matches
  fetchLikes = (data) => post(urls.FETCH_LIKES, data);

  fetchMatches = (data) => post(urls.FETCH_MATCHES, data);

  getOneUser = (data) => post(urls.GET_ONE_USER, data);

  getMatchCounter = (data) => post(urls.GET_UNREAD_MATCHES_COUNTER, data);

  getLikeCounter = (data) => post(urls.GET_UNREAD_LIKES_COUNTER, data);

  fetchLikedUsers = (data) => post(urls.FETCH_LIKED_USERS, data);

  checkRequest = (data) => post(urls.CHECK_REQUEST, data);

  requestPrivatePics = (data) => post(urls.REQUEST_PRIVATE_PHOTOS, data);

  getRequests = (data) => post(urls.GET_REQUEST, data);

  getApprovedRequests = (data) => post(urls.GET_APPROVED_REQUEST, data);

  handleRequest = (data) => post(urls.HANDLE_REQUEST, data);

  // ! Messages
  fetchMessages = (data) => post(urls.FETCH_MESSAGES_URL, data);

  fetchDesktopMessages = (data) => post(urls.FETCH_DESKTOP_MESSAGES, data);

  fetchChatQueue = (data) => post(urls.FETCH_CHAT_QUEUE, data);

  fetchChatQueueUpdate = (data) => post(urls.FETCH_CHAT_QUEUE_UPDATE, data);

  fetchChatMatches = (data) => post(urls.FETCH_CHAT_MATCHES, data);

  fetchSentMessages = (data) => post(urls.FETCH_SENT_MESSAGES, data);

  fetchLikesImage = (data) => post(urls.FETCH_LIKES_IMAGE, data);

  fetchMatchQueue = (data) => post(urls.FETCH_MATCH_QUEUE, data);

  updateMatchQueue = (data) => post(urls.UPDATE_MATCH_QUEUE, data);

  getMessageCounter = (data) => post(urls.GET_UNREAD_MESSAGES_COUNTER, data);

  // ! Member
  fetchUserProfile = (data) => post(urls.FETCH_USER_PROFILE, data);

  fetchUserBenefits = (data) => post(urls.FETCH_USER_BENEFITS, data);

  // ! Safety
  submitUnmatch = (data) => post(urls.SUBMIT_UNMATCH, data);

  submitReport = (data) => post(urls.SUBMIT_REPORT, data);

  submitBugReport = (data) => post(urls.SUBMIT_BUG_REPORT, data);

  submitUserReport = (data) => post(urls.SUBMIT_USER_REPORT, data);

  closeSafetyTip = (data) => post(urls.CLOSE_SAFETY_TIP_URL, data);

  // ! Purchase
  purchaseBoosts = (data) => post(urls.PURCHASE_BOOSTS, data);

  purchaseSuperlikes = (data) => post(urls.PURCHASE_SUPERLIKES, data);

  purchaseNotes = (data) => post(urls.PURCHASE_NOTES, data);

  purchaseDiamonds = (data) => post(urls.PURCHASE_DIAMONDS, data);

  // ! Admin
  updateSchema = (data) => post(urls.RUN_ADMIN_TASK, data);

  disapproveBannedChats = () => post(urls.DISAPPROVE_BANNED_CHATS);

  adminFetchChats = (data) => post(urls.ADMIN_FETCH_CHATS, data);

  adminReviewChats = (data) => post(urls.ADMIN_REVIEW_CHATS, data);

  fetchBotMessages = (data) => post(urls.FETCH_BOT_MESSAGES, data);

  fetchBotConvo = (data) => post(urls.FETCH_BOT_CONVO, data);

  updateBotChat = (data) => post(urls.UPDATE_BOT_CHAT, data);

  adminFetchImages = (data) => post(urls.FETCH_ADMIN_IMAGES, data);

  approveChat = (data) => post(urls.ADMIN_APPROVE_CHAT, data);

  declineChat = (data) => post(urls.ADMIN_DECLINE_CHAT, data);

  removeImage = (data) => post(urls.ADMIN_REMOVE_IMAGE, data);

  approveImage = (data) => post(urls.ADMIN_APPROVE_IMAGE, data);

  fetchReports = (data) => post(urls.ADMIN_FETCH_REPORTS, data);

  fetchbugs = (data) => post(urls.ADMIN_FETCH_BUGS, data);

  declineReport = (data) => post(urls.ADMIN_DECLINE_REPORT, data);

  submitBan = (data) => post(urls.SUBMIT_BAN, data);

  sendLikes = (data) => post(urls.SEND_LIKES, data);

  updateDatabase = (data) => post(urls.UPDATE_DATABASE);

  adminBanned = (data) => post(urls.ADMIN_BANNED, data);

  makePremium = (data) => post(urls.MAKE_PREMIUM, data);

  // ! Analytics
  calculateConversionData = () => post(urls.ADMIN_CALCULATE_CONVERSIONS);

  fetchSignupData = () => post(urls.FETCH_SIGNUP_DATA);

  fetchConversionData = () => post(urls.FETCH_CONVERSION_DATA);

  fetchTaskData = () => post(urls.FETCH_TASK_DATA);

  fetchEngagementData = () => post(urls.FETCH_ENGAGEMENT_DATA);

  createNewSession = (data) => post(urls.CREATE_NEW_SESSION, data);

  endSession = (data) => post(urls.END_SESSION, data);

  fetchChatMonitorData = () => post(urls.FETCH_CHAT_MONITOR_DATA);

  fetchActiveUserData = () => post(urls.FETCH_ACTIVE_USER_DATA);

  fecthActiveSubscription = () => post(urls.FETCH_ACTIVE_SUBSCRIPTION);
}

export default new APIManager();
