import React from "react";

function DesktopArchivedView() {
  return (
    <div>
      <span></span>
    </div>
  );
}

export default DesktopArchivedView;
