import React, { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import api from "../../../../api/api";
import ClipLoader from "react-spinners/ClipLoader";
import "./botstab.scss";
import BotMessageCard from "./botsTabComponents/BotMessageCard";

function BotsTab() {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetchChats();
  }, []);

  const refreshHandler = (e) => {
    setLoading(true);
    fetchChats();
  };

  const fetchChats = async (e) => {
    const data = {};
    return api
      .fetchBotMessages(data)
      .then((res) => {
        if (res.status === 200) {
          var data = res.data.messages;
          setMessages(data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card bg={"dark"} text={"white"} className="usertable-card">
      <Card.Header>
        <div className="card-header-wrap">
          <div className="card-header-leftside" id="card-header-table">
            <i className="fal fa-robot  icon-bot-focus"></i>
            <h4 className="card-header-title">Bot Activity</h4>
          </div>
          <div className="loading-spinner">
            <ClipLoader size={10} color={"#FFF"} loading={loading} />
          </div>
          <div className="bot-tab-refresh-button-wrapper">
            <Button className="bot-tab-refresh-button" onClick={refreshHandler}>
              Refresh
            </Button>
          </div>
        </div>
      </Card.Header>
      {loading ? (
        <Card.Body id="bottab-body">
          <div className="bottab-loading"></div>
        </Card.Body>
      ) : (
        <Card.Body id="bottab-body">
          {messages.map((message, index) => {
            return <BotMessageCard message={message} index={index} />;
          })}
        </Card.Body>
      )}
    </Card>
  );
}

export default BotsTab;
