import React, { Component } from "react";
import { Container, Image, Card, Button } from "react-bootstrap";

export class Features extends Component {
  render() {
    return (
      <div className="sd-belowgrid-content">
        <h3 className="sd-bg-title">Sugarbae Features</h3>
        <h4 className="sd-bg-subtitle">Over 1 million satisfied users</h4>
        <Container className="sd-bg-container">
          <div className="sd-bg-features-wrapper">
            <div className="sd-bg-features-col">
              <i className="fas fa-venus-double sd-bg-features-icon"></i>{" "}
              <h4 className="sd-bg-features-title">
                Successful & Attractive Members
              </h4>
              <p className="sd-bg-feature-description">
                Sugarbae members are more attractive and more successful than
                those on other dating apps. High quality members only.
              </p>
            </div>
            <div className="sd-bg-features-col">
              <i className="fab fa-hotjar sd-bg-features-icon"></i>
              <h4 className="sd-bg-features-title">Your Ideal Arrangement</h4>
              <p className="sd-bg-feature-description">
                Finally a dating experience where you can find what you are
                acctually looking for. Stop wasting time swiping on other apps
                and find exactly what you want in just minutes.
              </p>
            </div>
            <div className="sd-bg-features-col">
              <i className="fas fa-comment-alt-lines sd-bg-features-icon"></i>
              <h4 className="sd-bg-features-title">Real-Time Messaging</h4>
              <p className="sd-bg-feature-description">
                Within minutes you can setup your profile and begin messaging
                attractive females that are eager to find a man like you. Chat,
                discuss an arragement, and plan to meet in real life.
              </p>
            </div>
            <div className="sd-bg-features-col">
              <i className="fas fa-hand-pointer sd-bg-features-icon"></i>
              <h4 className="sd-bg-features-title">Choose Your Benefits</h4>
              <p className="sd-bg-feature-description">
                When creating your profile, you will select the specific dating
                benefits that you are looking for. Sugar dating is about finding
                your ideal arrangement, and cutting straight to the point with
                no games or delay inbetween.
              </p>
            </div>
            <div className="sd-bg-features-col">
              <i className="fas fa-badge-check sd-bg-features-icon"></i>
              <h4 className="sd-bg-features-title">
                Background Verified Profiles
              </h4>
              <p className="sd-bg-feature-description">
                Sugarbae takes fake accounts, scams, and catfishes very
                seriously. Over 65% of the profiles on Sugarbae are verified -
                meaning you know who you are talking to is real and their
                pictures are accurate.
              </p>
            </div>
            <div className="sd-bg-features-col">
              <i className="fas fa-gavel sd-bg-features-icon"></i>
              <h4 className="sd-bg-features-title">100% Free to Use</h4>
              <p className="sd-bg-feature-description">
                Signup and create your Sugarbae profile in just minutes, 100%
                free. No catches. No credit card required. Begin messaging users
                and find your ideal arrangement right now.
              </p>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default Features;
