import React from "react";

import "./profileinfo.scss";

const ProfileInfo = props => {
  const { profileUser } = props;

  return (
    <div className="profile-info-content">
      <div className="profile-info-option" onClick={() => props.editHandler("relationshipStatus")}>
        <i className="far fa-heart profile-info-v2-icon"></i>
        <span className="profile-info-v2-item-text">
          Relationship Status
        </span>
        <div className="profile-info-v2-row-action">
          {profileUser.lifestyle.relationship ? (
            <span className="profile-info-item">
              {profileUser.lifestyle.relationship}
            </span>
          ) : (
            <span className="profile-info-non-item">Select</span>
          )}
          <i className="fas fa-chevron-right profile-info-v2-right-icon"></i>
        </div>
      </div>
      <div className="profile-info-option" onClick={() => props.editHandler("height")}>
        <i className="far fa-ruler-vertical profile-info-v2-icon"></i>
        <span className="profile-info-v2-item-text">
          Height
        </span>
        <div className="profile-info-v2-row-action">
          {profileUser.height ? (
            <span className="profile-info-item">
              {profileUser.height}
            </span>
          ) : (
            <span className="profile-info-non-item">Select</span>
          )}
          <i className="fas fa-chevron-right profile-info-v2-right-icon"></i>
        </div>
      </div>
      <div className="profile-info-option" onClick={() => props.editHandler("bodytype")}>
        <i className="far fa-child profile-info-v2-icon"></i>
        <span className="profile-info-v2-item-text">
          Bodytype
        </span>
        <div className="profile-info-v2-row-action">
          {profileUser.bodytype ? (
            <span className="profile-info-item">
              {profileUser.bodytype}
            </span>
          ) : (
            <span className="profile-info-non-item">Select</span>
          )}
          <i className="fas fa-chevron-right profile-info-v2-right-icon"></i>
        </div>
      </div>
      <div className="profile-info-option" onClick={() => props.editHandler("ethinicity")}>
        <i className="far fa-smile profile-info-v2-icon"></i>
        <span className="profile-info-v2-item-text">
          Ethnicity
        </span>
        <div className="profile-info-v2-row-action">
          {profileUser.ethnicity ? (
            <span className="profile-info-item">
              {profileUser.ethnicity}
            </span>
          ) : (
            <span className="profile-info-non-item">Select</span>
          )}
          <i className="fas fa-chevron-right profile-info-v2-right-icon"></i>
        </div>
      </div>
      <div className="profile-info-option" onClick={() => props.editHandler("drinking")}>
        <i className="far fa-wine-glass profile-info-v2-icon"></i>
        <span className="profile-info-v2-item-text">
          Drinking
        </span>
        <div className="profile-info-v2-row-action">
          {profileUser.lifestyle.drinking ? (
            <span className="profile-info-item">
              {profileUser.lifestyle.drinking}
            </span>
          ) : (
            <span className="profile-info-non-item">Select</span>
          )}
          <i className="fas fa-chevron-right profile-info-v2-right-icon"></i>
        </div>
      </div>
      <div className="profile-info-option" onClick={() => props.editHandler("smoking")}>
        <i className="far fa-smoking profile-info-v2-icon"></i>
        <span className="profile-info-v2-item-text">
          Smoking
        </span>
        <div className="profile-info-v2-row-action">
          {profileUser.lifestyle.smoking ? (
            <span className="profile-info-item">
              {profileUser.lifestyle.smoking}
            </span>
          ) : (
            <span className="profile-info-non-item">Select</span>
          )}
          <i className="fas fa-chevron-right profile-info-v2-right-icon"></i>
        </div>
      </div>
      <div className="profile-info-option" onClick={() => props.editHandler("cannabis")}>
        <i className="far fa-cannabis profile-info-v2-icon"></i>
        <span className="profile-info-v2-item-text">
          Cannabis
        </span>
        <div className="profile-info-v2-row-action">
          {profileUser.lifestyle.cannabis ? (
            <span className="profile-info-item">
              {profileUser.lifestyle.cannabis}
            </span>
          ) : (
            <span className="profile-info-non-item">Select</span>
          )}
          <i className="fas fa-chevron-right profile-info-v2-right-icon"></i>
        </div>
      </div>
      <div className="profile-info-option" onClick={() => props.editHandler("education")}>
        <i className="far fa-graduation-cap profile-info-v2-icon"></i>
        <span className="profile-info-v2-item-text">
          Education
        </span>
        <div className="profile-info-v2-row-action">
          {profileUser.lifestyle.education ? (
            <span className="profile-info-item">
              {profileUser.lifestyle.education}
            </span>
          ) : (
            <span className="profile-info-non-item">Select</span>
          )}
          <i className="fas fa-chevron-right profile-info-v2-right-icon"></i>
        </div>
      </div>
      <div className="profile-info-option" onClick={() => props.editHandler("kids")}>
        <i className="far fa-baby profile-info-v2-icon"></i>
        <span className="profile-info-v2-item-text">
          Kids
        </span>
        <div className="profile-info-v2-row-action">
          {profileUser.lifestyle.children ? (
            <span className="profile-info-item">
              {profileUser.lifestyle.children}
            </span>
          ) : (
            <span className="profile-info-non-item">Select</span>
          )}
          <i className="fas fa-chevron-right profile-info-v2-right-icon"></i>
        </div>
      </div>
      <div className="profile-info-option" onClick={() => props.editHandler("exercise")}>
        <i className="far fa-dumbbell profile-info-v2-icon"></i>
        <span className="profile-info-v2-item-text">
          Exercise
        </span>
        <div className="profile-info-v2-row-action">
          {profileUser.lifestyle.exercise ? (
            <span className="profile-info-item">
              {profileUser.lifestyle.exercise}
            </span>
          ) : (
            <span className="profile-info-non-item">Select</span>
          )}
          <i className="fas fa-chevron-right profile-info-v2-right-icon"></i>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;