import React, { useState, useContext } from "react";
import "./desktopsliders.scss";
import { UserContext } from "../../../../../context/UserContext";

function SortBy(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [sortBy, setSortBy] = useState(filters.sortby);
  let recentlyActiveStyle = "filter-slider-v2-form-label";
  let newestStyle = "filter-slider-v2-form-label";
  let profileScoreStyle = "filter-slider-v2-form-label";

  if (sortBy === "Newest") {
    newestStyle = "filter-slider-v2-form-label-checked";
  } else if (sortBy === "Recently Active") {
    recentlyActiveStyle = "filter-slider-v2-form-label-checked";
  } else if (sortBy === "Profile Score") {
    profileScoreStyle = "filter-slider-v2-form-label-checked";
  }

  const changeHandler = (e) => {
    if (e.target.name === "Recently Active") {
      setSortBy("Recently Active");
    } else if (e.target.name === "Newest") {
      setSortBy("Newest");
    } else if (e.target.name === "Profile Score") {
      setSortBy("Profile Score");
    }
  };

  const resetHandler = (e) => {
    setSortBy("Recently Active");
  };

  const saveHandler = (e) => {
    props.updateHandler(sortBy);
  };

  return (
    <div className="desktop-v2-filters-content">
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.backHandler}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-icon">
              <i className="far fa-sort desktop-v2-filters-slider-icon" />
            </div>
            <div className="desktop-v2-filters-slider-title">Sort By</div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={resetHandler}
            >
              Reset
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <div className="filter-slider-v2-form-wrapper">
            <div className="filter-slider-v2-form">
              <div className="filter-slider-v2-form-group">
                <label className={recentlyActiveStyle}>
                  Recently Active
                  <input
                    type="checkbox"
                    className="filter-slider-v2-input-radio"
                    checked={sortBy === "Recently Active"}
                    onClick={changeHandler}
                    name="Recently Active"
                  />
                  <span className="filter-slider-v2-form-checkmark" />
                </label>
              </div>
              <div className="filter-slider-v2-form-group form-group-no-border">
                <label className={newestStyle}>
                  Newest
                  <input
                    type="checkbox"
                    className="filter-slider-v2-input-radio"
                    checked={sortBy === "Newest"}
                    onClick={changeHandler}
                    name="Newest"
                  />
                  <span className="filter-slider-v2-form-checkmark" />
                </label>
              </div>
              {/* <div className="filter-slider-v2-form-group form-group-no-border">
                <label className={profileScoreStyle}>
                  Profile Score
                  <input
                    type="checkbox"
                    className="filter-slider-v2-input-radio"
                    checked={sortBy === "Profile Score" ? true : false}
                    onClick={changeHandler}
                    name="Profile Score"
                  />
                  <span className="filter-slider-v2-form-checkmark"></span>
                </label>
              </div> */}
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-footer" onClick={saveHandler}>
          <div className="desktop-filter-slider-apply-button">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default SortBy;
