import React, { useState, useEffect } from "react";
import { api } from "../../api";
import "./graphs.scss";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
var moment = require("moment");

function DailySignupsAgeGroup(props) {
  //const [maleSignupData, setMaleSignupData] = useState([]);
  //const [femaleSignupData, setFemaleSignupData] = useState([]);
  const [signupAgeGroupData, setSignupData] = useState([]); 
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    return api
      .fetchSignupData()
      .then((res) => {
        if (res.status === 200) {
          //setMaleSignupData(res.data.maleSignupData);
          //setFemaleSignupData(res.data.femaleSignupData);
          setSignupData(res.data.signupAgeGroupData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderCustomAxisTick = ({ x, y, payload }) => {
    var date = payload.value;

    var formattedDate = moment(date).format("MMM DD").toString();

    return (
      <text x={x} y={y + 25} fill="#666" textAnchor="middle">
        {formattedDate}
      </text>
    );
  };
  return (
    <div className="graph-container">
      <div className="graph-header">
        <h1 className="graph-title"> Signups by Gender and Age Group </h1>
      </div>
      <div className="graph-content-container">
        <table>
          <tr>
            <div className="graph-content">
              <h2 className="graph-content-title">Male</h2>
              <BarChart
                width={500}
                height={200}
                data={signupAgeGroupData}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <Tooltip />
                <Bar dataKey="male" stackId="a" fill="#525cd1" />
                <XAxis dataKey="ageGroup" />
                <YAxis dataKey="female"/>
              </BarChart>
            </div>
          </tr>
          <tr>
            <div className="graph-content">
              <h2 className="graph-content-title">Female</h2>
              <BarChart
                width={500}
                height={200}
                data={signupAgeGroupData}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <Tooltip />
                <Bar dataKey="female" stackId="a" fill="#db2adb" stroke="#FF0000"/>

                <XAxis dataKey="ageGroup" />
                <YAxis dataKey="female"/>
              </BarChart>
            </div>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default DailySignupsAgeGroup;
