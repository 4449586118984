import React, { useContext } from "react";
import DesktopNavV2 from "../desktopNavV2/DesktopNavV2";
import DesktopSidebarV2 from "../desktopSidebarV2/DesktopSidebarV2";
import "../desktopvsstyles.scss";
import { UserContext } from "../../../../context/UserContext";
import DesktopSwipeV2 from "../desktopSwipeV2/DesktopSwipeV2";

function DesktopAppV2(props) {
  const context = useContext(UserContext);

  return (
    <div className="desktop-v2-container">
      <DesktopSidebarV2 />
      <div className="desktop-v2-content">
        <DesktopNavV2 />
        <div className="desktop-v2-content-container">
          <DesktopSwipeV2 />
        </div>
      </div>
    </div>
  );
}

export default DesktopAppV2;
