import React, { useState, useContext, useRef, useEffect } from "react";
import "./editscreensstyles.scss";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";

function FullName(props) {
  const context = useContext(UserContext);
  const [firstName, setFirstName] = useState(context.user.identity.firstName);
  const [middleName, setMiddleName] = useState(
    context.user.identity.middleName
  );
  const [lastName, setLastName] = useState(context.user.identity.lastName);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  const toggleTab = (e) => {
    props.toggleSubTab();
  };

  const firstNameChangeHandler = (e) => {
    setFirstName(e.target.value);

    if (e.target.value.length > 0 && lastName.length > 0) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  };

  const middleNameChangeHandler = (e) => {
    setMiddleName(e.target.value);
    if (lastName.length > 0 && firstName.length > 0) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  };

  const lastNameChangeHandler = (e) => {
    setLastName(e.target.value);
    if (e.target.value.length > 0 && firstName.length > 0) {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setSaveButtonDisabled(true);

    var data = {
      option: "name",
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
    };

    await api
      .updateIdentity(data)
      .then((res) => {
        if (res.status === 200) {
          context.updateUserInfo("identity", res.data.identity);
          toggleTab();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="edit-screen">
      <div className="edit-screen-header">
        <div className="edit-screen-header-section-left">
          <Button
            className="edit-screen-header-return-button"
            onClick={toggleTab}
          >
            <i className="fas fa-chevron-left"></i>
          </Button>
        </div>
        <div className="edit-screen-header-section-middle">
          <h1 className="edit-screen-header-title">Full Name</h1>
        </div>
      </div>
      <div className="edit-screen-content">
        <div className="settings-v2-section">
          <div className="settings-v2-section-content">
            <Form className="edit-screen-form">
              <Form.Group className="edit-screen-form-group">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  className="edit-screen-form-input"
                  name="firstName"
                  value={firstName}
                  onChange={firstNameChangeHandler}
                  required={true}
                  placeholder={"Enter your legal first name"}
                  size="lg"
                />
              </Form.Group>
              <Form.Group className="edit-screen-form-group">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control
                  type="text"
                  className="edit-screen-form-input"
                  name="middleName"
                  value={middleName}
                  onChange={middleNameChangeHandler}
                  required={false}
                  placeholder={"Enter your legal middle name"}
                  size="lg"
                />
              </Form.Group>
              <Form.Group className="edit-screen-form-group">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  className="edit-screen-form-input"
                  name="lastName"
                  value={lastName}
                  onChange={lastNameChangeHandler}
                  required={true}
                  placeholder={"Enter your legal last name"}
                  size="lg"
                />
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      <div className="edit-screen-cta-button-wrapper">
        <Button
          className="edit-screen-update-button"
          size="lg"
          onClick={toggleTab}
        >
          Back
        </Button>

        <Button
          className="edit-screen-cta-button-upgrade"
          size="lg"
          disabled={saveButtonDisabled}
          onClick={submitHandler}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}

export default FullName;
