import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";
var heights = [
  "4'0\"",
  "4'1\"",
  "4'2\"",
  "4'3\"",
  "4'4\"",
  "4'5\"",
  "4'6\"",
  "4'7\"",
  "4'8\"",
  "4'9\"",
  "4'10\"",
  "4'11\"",
  "5'0\"",
  "5'1\"",
  "5'2\"",
  "5'3\"",
  "5'4\"",
  "5'5\"",
  "5'6\"",
  "5'7\"",
  "5'8\"",
  "5'9\"",
  "5'10\"",
  "5'11\"",
  "6'0\"",
  "6'1\"",
  "6'2\"",
  "6'3\"",
  "6'4\"",
  "6'5\"",
  "6'6\"",
  "6'7\"",
  "6'8\"",
  "6'9\"",
  "6'10\"",
  "6'11\"",
  "7'0\"",
];

function Height(props) {
  var filters = props.filters;
  const [height, setHeight] = useState(filters.height);

  const changeHandler = (event, newValue) => {
    setHeight(newValue);
  };
  const commitHandler = (event, newValue) => {
    setHeight(newValue);
  };

  const saveHandler = (e) => {
    props.updateHandler(height);
  };

  var minHeight = heights[height[0]];
  var maxHeight = heights[height[1]];

  return (
    <div className="filter-slider-component">
      <div className="filter-slider-overlay" onClick={props.onClose} />
      <div className="filter-slider-content">
        <div className="filter-slider-title-wrap">
          <i className="far fa-ruler-vertical filter-sort-icon"></i>
          <h2 className="filter-slider-title">Height</h2>
        </div>
        <div className="filter-slider-wrapper">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <div className="advanced-search-filters-slider-wrapper">
                <h2 className="search-slider-title">
                  Between {minHeight} and {maxHeight}
                </h2>
                <div className="slider-innerwrap">
                  <Slider
                    min={0}
                    max={36}
                    value={height}
                    onChange={changeHandler}
                    onChangeCommitted={commitHandler}
                  />
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
        <div className="filter-save-button-wrapper">
          <Button className="filter-save-button" onClick={saveHandler}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Height;
