import React, { useContext, useState, useEffect } from "react";
import "./tabs.scss";
import Axios from "axios";

import { withRouter } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import LikesCard from "../../likesCard/LikesCard";
import NoLikesIndicator from "./indicator/NoLikesIndicator";
import { Button } from "react-bootstrap";
import BottomScrollListener from "react-bottom-scroll-listener";
import { motion } from "framer-motion";
import { ViewLikes } from "../../../../modals/viewLikes/ViewLikes";
import { api } from "../../../../../api";

export const Likes = props => {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [viewLikesModalOpen, setViewLikesModalOpen] = useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    context.updateLikesAsRead();
    setFetching(false);
  },[context.likes]);

  const toggleViewLikesModal = (e) => {
    if (viewLikesModalOpen) {
      setViewLikesModalOpen(false);
    } else {
      setViewLikesModalOpen(true);
    }
  };

  const handleScroll = (e) => {
    if (!fetching && !context.maxLikes) {
      setFetching(true);
      context.checkMoreLikes();
    }
  };

  const likeHandler = (cardid) => {
    const temp = context.likes;
    temp.splice(cardid, 1);
    context.updateLikes(temp);
  };

  const dislikeHandler = (cardid) => {
    const temp = context.likes;
    temp.splice(cardid, 1);
    context.updateLikes(temp);
  };

  const pageVariants = {
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: "100%",
    },
  };

  const pageTransition = {
    transition: "linear",
  };
  if (loading) {
    return false;
  } else {
    return (
      <BottomScrollListener
        onBottom={handleScroll}
        offset="1500"
      >
        {(scrollRef) => (
          <motion.div
            className="likes-component"
            ref={scrollRef}
            transition={pageTransition}
            initial="out"
            animate="in"
            exit="out"
            variants={pageVariants}
          >
            {context.likes.length > 0 ? (
              <div className="likes-component-innerwrap">
                {viewLikesModalOpen ? (
                  <ViewLikes closeHandler={toggleViewLikesModal} />
                ) : null}
                {!props.premium && (
                  <div className="see-likes-btn-wrap">
                    <Button
                      className="see-likes-cta-btn"
                      size="lg"
                      onClick={toggleViewLikesModal}
                    >
                      <i className="fas fa-eye see-likes-btn-icon"></i>
                      See who likes you
                    </Button>
                  </div>
                )}

                {context.likes.map((like, index) => {
                  return (
                    <LikesCard
                      index={index}
                      like={like}
                      premium={props.premium}
                      likeHandler={likeHandler}
                      dislikeHandler={dislikeHandler}
                      viewLikesModalToggle={toggleViewLikesModal}
                      profileToggle={props.profileToggle}
                    />
                  );
                })}
                {context.maxLikes && (
                  <div className="end-indicator">
                    No more Likes.
                  </div>
                )}
              </div>
            ) : (
              <div className="no-likes-wrapper">
                <NoLikesIndicator
                  handleBoost={() => {
                    props.handleBoost();
                  }}
                />
              </div>
            )}
          </motion.div>
        )}
      </BottomScrollListener>
    );
  }
}

export default withRouter(Likes);
