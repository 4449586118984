import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";

function Drinking(props) {
  var filters = props.filters;
  const [drinking, setDrinking] = useState(filters.drinking);

  const changeHandler = (e) => {
    var drinkingCopy = drinking;

    if (e.target.value === "nondrinker") {
      if (drinkingCopy.nondrinker) {
        drinkingCopy.nondrinker = false;
      } else {
        drinkingCopy.nondrinker = true;
      }
    } else if (e.target.value === "socialdrinker") {
      if (drinkingCopy.socialdrinker) {
        drinkingCopy.socialdrinker = false;
      } else {
        drinkingCopy.socialdrinker = true;
      }
    } else if (e.target.value === "heavydrinker") {
      if (drinkingCopy.heavydrinker) {
        drinkingCopy.heavydrinker = false;
      } else {
        drinkingCopy.heavydrinker = true;
      }
    }

    setDrinking((prevState) => {
      return { ...prevState, ...drinkingCopy };
    });
  };

  const saveHandler = (e) => {
    props.updateHandler(drinking);
  };

  return (
    <div className="filter-slider-component">
      <div className="filter-slider-overlay" onClick={props.onClose} />
      <div className="filter-slider-content">
        <div className="filter-slider-title-wrap">
          <i className="far fa-heart filter-sort-icon"></i>
          <h2 className="filter-slider-title">Drinking</h2>
        </div>
        <div className="filter-slider-wrapper">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Non Drinker"}
                type="checkbox"
                value="nondrinker"
                checked={drinking.nondrinker}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Social Drinker"}
                type="checkbox"
                value="socialdrinker"
                checked={drinking.socialdrinker}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Heavy Drinker"}
                type="checkbox"
                value="heavydrinker"
                checked={drinking.heavydrinker}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="filter-save-button-wrapper">
          <Button className="filter-save-button" onClick={saveHandler}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Drinking;
