import React, { Component } from "react";
import { UserContext } from "../../context/UserContext";
import { withRouter } from "react-router-dom";
import Search from "../../components/search/Search";
import SwipeMatch from "../../components/matches/SwipeMatch";
import DesktopMatches from "../../components/desktop/desktopMatches/DesktopMatches";
import MatchesV2 from "../../components/matches/MatchesV2";
import MobileNav from "../../components/navbar/MobileNav/MobileNav";
import DesktopMatchesV2 from "../../components/desktop/desktopV2/desktopMatchesV2/DesktopMatchesV2";

export class MatchPage extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {}

  handleBoost = (e) => {
    var userid = this.context.user._id;
    this.props.handleBoost(userid);
  };

  render() {
    let vh = window.innerHeight;
    let vw = window.innerWidth;

    var height = vh + "px";
    var url = this.props.location.pathname;

    if (vw >= 992) {
      return <DesktopMatchesV2 url={url} />;
    } else {
      return (
        <div className="app-component" style={{ height: height }}>
          <MatchesV2 handleBoost={this.props.handleBoost} />
          <MobileNav />
        </div>
      );
    }
  }
}

export default withRouter(MatchPage);
