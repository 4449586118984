import React, { useEffect, useContext, useState } from "react";
import "./welcomemodal.scss";
import { Modal, Card, Button, Image } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import welcomeImg from "../../../assets/Images/welcome/welcome-image.jpg";
import { Link } from "react-router-dom";
import numberOne from "../../../assets/Images/Numbers/number1.png";
import numberTwo from "../../../assets/Images/Numbers/number2.png";
import numberThree from "../../../assets/Images/Numbers/number3.png";

function WelcomeModal(props) {
  const [show, setShow] = useState(true);
  const [step, setStep] = useState(1);

  const closeHandler = (e) => {
    setShow(false);
  };

  const nextStepHandler = (e) => {
    if (step === 4) {
      setShow(false);
    } else {
      var stepCopy = step;
      stepCopy = stepCopy + 1;
      setStep(stepCopy);
    }
  };

  const previousStepHandler = (e) => {};

  return (
    <Modal size="lg" show={show} onHide={closeHandler}>
      <Card className="welcome-modal-card">
        <Card.Img variant="top" src={welcomeImg} />
        {step === 1 && (
          <Card.Body className="welcome-modal-card-body">
            <Card.Title className="welcome-modal-card-title">
              Welcome to Sugarbae!
            </Card.Title>
            <p className="welcome-modal-subtitle">Here is what to do next:</p>
            <div className="welcome-modal-card-content-wrapper">
              <div className="welcome-modal-card-step">
                <div className="welcome-step-image-wrapper">
                  <Image src={numberOne} className="welcome-step-image" />
                </div>
                <div className="welcome-step-content-wrapper">
                  <h4 className="welcome-step-title">
                    Review Safety Guidelines
                  </h4>
                  <p className="welcome-step-content">
                    Review important Sugar Dating safety tips & avoid scams.
                  </p>
                </div>
              </div>
              <div className="welcome-modal-card-step">
                <div className="welcome-step-image-wrapper">
                  <Image src={numberTwo} className="welcome-step-image" />
                </div>
                <div className="welcome-step-content-wrapper">
                  <h4 className="welcome-step-title">Setup Your Profile</h4>
                  <p className="welcome-step-content">
                    Learn how to use Sugarbae and find your perfect arrangement.
                  </p>
                </div>
              </div>
              <div className="welcome-modal-card-step">
                <div className="welcome-step-image-wrapper">
                  <Image src={numberThree} className="welcome-step-image" />
                </div>
                <div className="welcome-step-content-wrapper">
                  <h4 className="welcome-step-title">Swipe, Match, & Chat!</h4>
                  <p className="welcome-step-content">
                    Get started finding your perfect arrangement.
                  </p>
                </div>
              </div>
            </div>

            <div className="welcome-modal-card-button-wrapper">
              <Button
                className="welcome-modal-card-button"
                onClick={nextStepHandler}
                size="lg"
              >
                Next
              </Button>
            </div>
          </Card.Body>
        )}

        {step === 2 && (
          <Card.Body className="welcome-modal-card-body">
            <div className="welcome-modal-card-content-wrapper">
              <div className="welcome-modal-card-step">
                <div className="welcome-step-image-wrapper">
                  <Image src={numberOne} className="welcome-step-image" />
                </div>
                <div className="welcome-step-content-wrapper">
                  <h4 className="welcome-step-title">
                    Review Safety Guidelines
                  </h4>
                  <p className="welcome-step-content">
                    Review important Sugar Dating safety tips & avoid scams.
                  </p>
                </div>
              </div>
            </div>
            <div className="welcome-modal-safety-content-container">
              <h5 className="welcome-modal-safety-title">
                Watch out for scammers!
              </h5>
              <ul className="welcome-modal-safety-list">
                <li className="welcome-modal-safety-list-item">
                  Never send money or your bank account information.
                </li>
                <li className="welcome-modal-safety-list-item">
                  Report users that ask you to send money or bank information.
                </li>
                <li className="welcome-modal-safety-list-item">
                  Never send or receive cheques through the mail or online.
                </li>
                <li className="welcome-modal-safety-list-item">
                  Keep your chats on Sugarbae so we can identify scammers.
                </li>
              </ul>
              <p className="welcome-modal-safety-disclaimer">
                We take user safety very seriously and work hard to prevent
                scammers from using our platform. Common scams include fake
                user's that ask for your bank information, to send them money in
                return for more money back, or send you a fake cheque in the
                mail. Do NOT fall for these common scams.
              </p>
            </div>
            <div className="welcome-modal-card-button-wrapper">
              <Button
                className="welcome-modal-card-button"
                onClick={nextStepHandler}
                size="lg"
              >
                I Agree
              </Button>
            </div>
          </Card.Body>
        )}
        {step === 3 && (
          <Card.Body className="welcome-modal-card-body">
            <div className="welcome-modal-card-content-wrapper">
              <div className="welcome-modal-card-step">
                <div className="welcome-step-image-wrapper">
                  <Image src={numberTwo} className="welcome-step-image" />
                </div>
                <div className="welcome-step-content-wrapper">
                  <h4 className="welcome-step-title">Setup Your Profile</h4>
                  <p className="welcome-step-content">
                    Learn how to use Sugarbae and find your perfect arrangement.
                  </p>
                </div>
              </div>
            </div>
            <div className="welcome-modal-safety-content-container">
              <h5 className="welcome-modal-safety-title">
                Finish Your Profile:
              </h5>
              <ul className="welcome-modal-safety-list">
                <li className="welcome-modal-safety-list-item">
                  Add multiple photos of yourself.
                </li>
                <li className="welcome-modal-safety-list-item">
                  Complete profile headline & bio.
                </li>
                <li className="welcome-modal-safety-list-item">
                  Be upfront about your expectations.
                </li>
                <li className="welcome-modal-safety-list-item">
                  Be honest and respectful.
                </li>
              </ul>
              <p className="welcome-modal-safety-disclaimer">
                We found that user's who spend time fully completing their
                profile and uploading multiple photos get the most attention on
                Sugarbae.
              </p>
            </div>
            <div className="welcome-modal-card-button-wrapper">
              <Button
                className="welcome-modal-card-button"
                onClick={nextStepHandler}
                size="lg"
              >
                Got It!
              </Button>
            </div>
          </Card.Body>
        )}

        {step === 4 && (
          <Card.Body className="welcome-modal-card-body">
            <div className="welcome-modal-card-content-wrapper">
              <div className="welcome-modal-card-step">
                <div className="welcome-step-image-wrapper">
                  <Image src={numberThree} className="welcome-step-image" />
                </div>
                <div className="welcome-step-content-wrapper">
                  <h4 className="welcome-step-title">Swipe, Match, & Chat!</h4>
                  <p className="welcome-step-content">
                    Get started finding your perfect arrangement.
                  </p>
                </div>
              </div>
            </div>
            <div className="welcome-modal-safety-content-container">
              <h5 className="welcome-modal-safety-title">How it works:</h5>
              <ul className="welcome-modal-safety-list">
                <li className="welcome-modal-safety-list-item">
                  Send likes to user's you are interested in.
                </li>
                <li className="welcome-modal-safety-list-item">
                  Chat with user's you match with.
                </li>
                <li className="welcome-modal-safety-list-item">
                  Boost your profile for extra attention.
                </li>
                <li className="welcome-modal-safety-list-item">
                  Swipe right to like, left to dislike.
                </li>
              </ul>
              <p className="welcome-modal-safety-disclaimer">
                Enjoy your time on Sugarbae. We hope you find meaningfull
                arrangements that bring value to your life, like millions of our
                members. If you need help at any time you can contact
                support@sugarbae.com.
              </p>
            </div>
            <div className="welcome-modal-card-button-wrapper">
              <Button
                className="welcome-modal-card-button"
                onClick={nextStepHandler}
                size="lg"
              >
                Let's Get Started!
              </Button>
            </div>
          </Card.Body>
        )}
      </Card>
    </Modal>
  );
}

export default withRouter(WelcomeModal);
