import React, { Component } from 'react';
import './usercard.scss';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../utils/getCloudinaryInfo';
import Media from '../../components/media/Media';
import { UserContext } from '../../context/UserContext';

export class UserCardHome extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);

    this.state = {
      online: false,
    };
  }

  componentWillMount() {
    var currentTime = Date.now();
    var lastLogin = this.props.lastlogin;
    var myDate = new Date(lastLogin);
    var result = myDate.getTime();

    var difference = currentTime - result;

    if (difference < 3600000) {
      this.setState({ online: true });
    } else {
      this.setState({ online: false });
    }
  }

  render() {
    let imgurl = this.props.imgurl[0];
    var imgbg = null;

    if (this.props.videourl !== undefined && this.props.videourl.length > 0) {
      const videourl = this.props.videourl[0];
      imgbg = `${videourl.slice(0, -3)}gif`;
    } else if (
      this.props.imgurl[0] !== null &&
      this.props.imgurl[0] !== undefined
    ) {
      imgbg = imgurl;
    }

    return (
      <div
        className={this.props.premium ? 'usr-card-premium' : 'usr-card'}
        id="usr-card"
      >
        <Link to="/signup">
          {this.props.premium ? (
            <div className="uc-header">
              <div className="premium-indicator-cardwrap">
                <div className="premium-user-text-wrap">
                  <div className="premium-text-uc">Premium</div>
                  <div className="premium-text-uc-second">Member</div>
                </div>
                <div className="premium-icon-wrapper">
                  <i className="fas fa-gem premium-gem-icon-uc"></i>
                </div>
              </div>
            </div>
          ) : null}

          <div
            className={
              this.props.premium ? 'ucf-fullwrap-premium' : 'ucf-fullwrap'
            }
          >
            <div className="uc-footer">
              <div className="ucf-wrap">
                <div className="ucf-left">
                  <div className="card-username">
                    {this.props.verified ? (
                      <span>
                        <i className="fas fa-shield-check verified-badge-icon-card"></i>{' '}
                      </span>
                    ) : null}
                    {this.props.username}
                  </div>
                  <div className="card-location">{this.props.user.address}</div>
                </div>
                <div className="ucf-right">
                  {this.state.online ? (
                    <div className="activity-status-cardwrap">
                      <div className="activity-status-innerwrap-card">
                        <i className="fas fa-circle card-user-online-icon"></i>{' '}
                        <span className="card-active-text">Online</span>
                      </div>
                    </div>
                  ) : null}
                  <div className="card-age">{this.props.user.age}</div>
                </div>
              </div>
            </div>
            <div className="uc-gradient"></div>

            <Media image={imgbg} />
            {/* <Image
              publicId={getImgName(imgbg)}
              version={getImgVersion(imgbg)}
              resource_type={
                imgbg ? (checkGIF(imgbg) ? "video" : "image") : null
              }
              cloud_name="sugarbae"
              className="usr-img"
            >
              <Transformation
                width="615"
                height="820"
                crop="fill"
                effect="loop"
                fetchFormat="auto"
                quality="auto"
                flags="lossy"
              />
            </Image> */}
          </div>
        </Link>
      </div>
    );
  }
}

export default withRouter(UserCardHome);
