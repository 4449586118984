import React, { useState, useContext, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import "./wallet.scss";
import diamondFlatIcon from "../../assets/Icons/diamond-shape.svg";
import dollarSign from "../../assets/Icons/dollar-sign.png";
import { api } from "../../api";
import wallet from "../../assets/Icons/wallet.png";
import suitcase from "../../assets/Icons/suitcase.png";
import box from "../../assets/Icons/box.png";
import { UserContext } from "../../context/UserContext";
import greyDiamond from "../../assets/Icons/greyDiamond.png";
import dollarSignGrey from "../../assets/Icons/dollarSignGrey.png";

function RedeemDiamonds(props) {
  const context = useContext(UserContext);
  const history = useHistory();
  const [diamondPackage, setDiamondPackage] = useState(0);
  const [purchaseOpen, setPurchaseOpen] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [femaleBalance, setFemaleBalance] = useState(null);

  const withdrawlHandler = (e) => {};

  const backHandler = (e) => {
    history.goBack();
  };

  const fetchFemaleCount = async (e) => {
    const data = {
      userid: context.user._id,
    };

    return api
      .fetchDiamondCount(data)
      .then((res) => {
        if (res.status === 200) {
          setFemaleBalance(res.data.diamondCount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (context.user.gender === "female") {
      fetchFemaleCount();
    }
  }, [context.user.gender, fetchFemaleCount]);

  let dollarValue = 0;
  const exchangeRate = 0.678;

  if (femaleBalance > 0) {
    dollarValue = femaleBalance * exchangeRate;
  }

  return (
    <div className="wallet-component">
      <div className="wallet-header">
        <div className="wallet-header-left">
          <div className="wallet-return-button" onClick={backHandler}>
            <i className="fas fa-chevron-left" />
          </div>
        </div>
        <div className="wallet-header-middle">
          <h1 className="wallet-header-title">Redeem Diamonds</h1>
        </div>
      </div>
      <div className="wallet-content">
        <div className="wallet-exchange">
          <div className="wallet-exchange-center">
            <div className="wallet-redeem-equals">
              <i className="far fa-equals" />
            </div>
            <div className="wallet-redeem-conversion">
              <div className="wallet-redeem-conversion-section">
                <Image
                  src={greyDiamond}
                  className="exchange-grey-diamond-icon"
                  fluid
                />
                <span className="wallet-redeem-number">1</span>
              </div>
              <span className="redeem-equals-icon">=</span>
              <div className="wallet-redeem-conversion-section">
                <Image
                  src={dollarSignGrey}
                  className="exchange-grey-dollar-icon"
                  fluid
                />
                <span className="wallet-redeem-number">0.678</span>
              </div>
            </div>
          </div>
          <div className="wallet-exchange-container">
            <div className="wallet-exchange-wrapper">
              <Image
                src={diamondFlatIcon}
                className="wallet-balance-icon"
                fluid
              />
              <h1 className="wallet-exchange-text">{femaleBalance}</h1>
            </div>
          </div>
          <div className="wallet-exchange-container-bottom">
            <div className="wallet-exchange-wrapper">
              <Image src={dollarSign} className="wallet-balance-icon" fluid />
              <h1 className="wallet-exchange-text">{dollarValue}</h1>
            </div>
          </div>
        </div>
        <div className="edit-screen-status-wrapper">
          <i className="far fa-exclamation-circle number-alert-icon" />
          <span>You need to verifiy your ID</span>
        </div>
      </div>
      <div className="wallet-footer">
        <div
          variant="danger"
          className="wallet-footer-button"
          onClick={withdrawlHandler}
        >
          Withdrawal
        </div>
      </div>
    </div>
  );
}

export default withRouter(RedeemDiamonds);
