import React from "react";
import Wallet from "../../components/wallet/Wallet";

function WalletPage() {
  let vh = window.innerHeight;
  var vw = window.innerWidth;
  var height = vh + "px";
  var width = vw + "px";
  return (
    <div
      className="app-component"
      style={{
        height: height,
        width: width,
        maxHeight: height,
        maxWidth: width,
      }}
    >
      <Wallet />
    </div>
  );
}

export default WalletPage;
