// TODO: implement more "proper" state management when the global state data increases
// Reference: https://react.christmas/2019/7

import React, { useEffect, useState, createContext } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";
import axios from "axios";
import moment from "moment";

import { api, socket } from "../api";

export const UserContext = createContext();

const initialUserData = {
  _id: "",
  email: "",
  premium: false,
  isAdmin: false,
  username: "",
  profileurl: "",
  verified: false,
  gender: "",
  address: "",
  likedusers: [],
  stripeid: null,
  plan: null,
  banned: false,
  loaded: false,
  verifications: null,
  imgurl: [],
  age: null,
  filters: null,
  hidden: false,
  preferences: null,
  indentity: null,
  inventory: null,
  location: {},
  identity: {
    birthDate: null,
  },
};

const initialSwipeData = {
  cards: [],
  likedByUsers: [],
  superlikedByUsers: [],
  noteUsers: [],
  index: 0,
  loading: true,
};

const initialBoostData = {
  boosted: false,
  boostExpirationDate: null,
};

const initialScrollPositions = {
  search: 0,
  swipe: 0,
  messages: 0,
};

function UserProvider(props) {
  const [user, setUser] = useState(initialUserData);
  const [swipeData, setSwipeData] = useState(initialSwipeData);
  const [boostData, setBoostData] = useState(initialBoostData);
  const [scrollPositions, setScrollPositions] = useState(
    initialScrollPositions
  );
  const [diamondCount, setDiamondCount] = useState(null);
  const [needReload, setNeedReload] = useState(false);
  const [messageData, setMessageData] = useState([]);
  const [matchedIDs, setMatchedIDs] = useState([]);
  const [complimentIDs, setComplimentIDs] = useState([]);
  const [messageSkip, setMessageSkip] = useState(0);
  const [endMessages, setEndMessages] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const [matchSkip, setMatchSkip] = useState(0);
  const [endMatches, setEndMatches] = useState(false);
  const [matchesCount, setMatchesCount] = useState(0);
  const [matches, setMatches] = useState([]);
  const [matchesSkip, setMatchesSkip] = useState(0);
  const [maxMatches, setMaxMatches] = useState(false);
  const [matchesRefresh, setMatchesRefresh] = useState(false);
  const [likes, setLikes] = useState([]);
  const [likesSkip, setLikesSkip] = useState(0);
  const [maxLikes, setMaxLikes] = useState(false);
  const [likesRefresh, setLikesRefresh] = useState(false);
  const [searches, setSearches] = useState([]);
  const [spotlight, setSpotlight] = useState([]);
  const [searchesLoading, setSearchesLoading] = useState(true);
  const [searchesSkip, setSearchesSkip] = useState(0);
  const [likedUsers, setLikedUsers] = useState([]);
  const [superlikedUsers, setSuperlikedUsers] = useState([]);
  const [endSearches, setEndSearches] = useState(false);
  const [sortby, setSortby] = useState(false);
  const [desktopSearchLoading, setDesktopSearchLoading] = useState(true);
  const [desktopSearches, setDesktopSearches] = useState([]);
  const [desktopSearchesSkip, setDesktopSearchesSkip] = useState(0);
  const [desktopLikedUsers, setDesktopLikedUsers] = useState([]);
  const [desktopSuperlikedUsers, setDesktopSuperlikedUsers] = useState([]);
  const [endDesktopSearches, setEndDesktopSearches] = useState(false);
  const [desktopSortby, setDesktopSortby] = useState(false);
  const [likesCounter, setLikesCounter] = useState(0);
  const [messageCounter, setMessageCounter] = useState(0);
  const [desktopRecentData, setDesktopRecentData] = useState(null);
  const [desktopNewestData, setDesktopNewestData] = useState(null);
  const [desktopRecommendedData, setDesktopRecommendedData] = useState(null);
  const [messageQueueSortby, setMessageQueueSortby] = useState("All Messages");
  const [messageQueueFiltering, setMessageQueueFiltering] = useState(false);
  const [messageQueueLoading, setMessageQueueLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [requestsSkip, setRequestsSkip] = useState(0);
  const [endRequests, setEndRequests] = useState(false);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [approvedRequestsSkip, setApprovedRequestsSkep] = useState(0);
  const [endApprovedRequests, setEndApprovedRequests] = useState(false);

  useEffect(() => {
    setUser({ ...user, loaded: false });
    if (!localStorage.getItem("auth")) {
      return props.history.push("/login");
    }
    loadContext();
  }, []);

  useEffect(() => {
    if (messageSkip !== 0) {
      loadMessageData(user._id);
    }
  }, [messageSkip]);

  useEffect(() => {
    if (matchSkip !== 0) {
      loadMatchData(user._id);
    }
  }, [matchSkip]);

  useEffect(() => {
    if (matchesSkip !== 0) {
      loadMatches(user._id);
    }
  }, [matchesSkip]);

  useEffect(() => {
    if (likesSkip !== 0) {
      loadLikes(user._id);
    }
  }, [likesSkip]);

  useEffect(() => {
    if (matchesRefresh) {
      loadMatches(user._id);
      setMatchesRefresh(false);
    }
  }, [matchesRefresh]);

  useEffect(() => {
    if (likesRefresh) {
      loadLikes(user._id);
      setLikesRefresh(false);
    }
  }, [likesRefresh]);

  useEffect(() => {
    if (sortby) {
      loadSearches(user._id);
      setSortby(false);
    }
  }, [sortby]);

  useEffect(() => {
    if (searchesSkip !== 0) {
      loadSearches(user._id);
    }
  }, [searchesSkip]);

  useEffect(() => {
    if (desktopSortby) {
      loadDesktopSearches(user._id);
      setDesktopSortby(false);
    }
  }, [desktopSortby]);

  useEffect(() => {
    if (desktopSearchesSkip !== 0) {
      loadDesktopSearches(user._id);
    }
  }, [desktopSearchesSkip]);

  useEffect(() => {
    if (requestsSkip !== 0) {
      loadRequests(user._id);
    }
  }, [requestsSkip]);

  useEffect(() => {
    if (approvedRequestsSkip !== 0) {
      loadApprovedRequests(user._id);
    }
  }, [approvedRequestsSkip]);

  useEffect(() => {
    if (messageQueueFiltering) {
      loadMessageData(user._id);
      setMessageQueueFiltering(false);
    }
  }, [messageQueueFiltering]);

  const loadContext = async () => {
    let ipAddress = null;
    await axios
      .get("https://json.geoiplookup.io", {
        withCredentials: false,
      })
      .then((res) => {
        ipAddress = res.data.ip;
      })
      .catch((err) => {
        console.log(err);
      });

    const params = {
      ipAddress,
    };
    const userid = await api
      .fetchProtected(params)
      .then((res) => {
        setUser({
          ...res.data.user,
          loaded: true,
        });
        setBoostData(res.data.boostData);
        ReactGA.set({ userId: res.data.user.username });

        return res.data.user._id;
      })
      .catch((err) => {
        localStorage.removeItem("auth");
        props.history.push("/login");
      });

    getCounters(userid);
    loadMessageData(userid);
    loadMatchData(userid);
    loadMatches(userid);
    loadLikes(userid);
    loadSearches(userid);
    loadDesktopSearches(userid);
    loadDesktopData(userid);
    loadDiamondCount(userid);
    loadRequests(userid);
    loadApprovedRequests(userid);

    return loadSwipeData(userid);
  };

  const updateScrollPosition = (option, value) => {
    const tempScrollPositions = scrollPositions;

    if (option === "search") {
      if (value) {
        tempScrollPositions.search = value;
      }
    } else if (option === "messages") {
      if (value) {
        tempScrollPositions.messages = value;
      }
    }

    setScrollPositions((prevState) => ({
      ...prevState,
      ...tempScrollPositions,
    }));
  };

  if (user.banned) {
    localStorage.removeItem("auth");
    props.history.push("/login");
  }

  const loadDiamondCount = async (userid) => {
    const data = {
      userid,
    };

    api
      .fetchDiamondCount(data)
      .then((res) => {
        if (res.status === 200) {
          setDiamondCount(res.data.diamondCount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadRequests = (userid) => {
    const data = {
      userid,
      skip: requestsSkip,
    };

    return api
      .getRequests(data)
      .then((res) => {
        if (res.data.requests) {
          const newData = requests.concat(res.data.requests);
          setRequests(newData);
          if (res.data.requests.length < 12) {
            setEndRequests(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMoreRequests = () => {
    setRequestsSkip(requests.length);
  };

  const loadApprovedRequests = (userid) => {
    const data = {
      userid,
      skip: approvedRequestsSkip,
    };

    return api
      .getApprovedRequests(data)
      .then((res) => {
        if (res.data.approvedRequests) {
          const newData = approvedRequests.concat(res.data.approvedRequests);
          setApprovedRequests(newData);
          if (res.data.approvedRequests.length < 12) {
            setEndApprovedRequests(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMoreApprovedRequests = () => {
    setApprovedRequestsSkep(approvedRequests.length);
  };

  const getCounters = async (userid) => {
    let matchesCounter = 0;
    let likesCounter = 0;
    let messagesCounter = 0;

    const data = {
      userid,
    };
    const matchCounterPromise = api
      .getMatchCounter(data)
      .then((res) => {
        matchesCounter = res.data.counter;
      })
      .catch((err) => {
        console.log(err);
      });

    const likeCounterPromise = api
      .getLikeCounter(data)
      .then((res) => {
        likesCounter = res.data.counter;
      })
      .catch((err) => {
        console.log(err);
      });

    const messageCounterPromise = api
      .getMessageCounter(data)
      .then((res) => {
        messagesCounter = res.data.counter;
      })
      .catch((err) => {
        console.log(err);
      });
    const counterPromises = [
      matchCounterPromise,
      likeCounterPromise,
      messageCounterPromise,
    ];

    await Promise.all(counterPromises);
    setLikesCounter(matchesCounter + likesCounter);
    setMessageCounter(messagesCounter);
  };

  const loadDesktopData = async (userid) => {
    const data = {
      userid,
    };

    const getRecentPromise = api
      .fetchGridRecentlyActive(data)
      .then((res) => {
        if (res.status === 200) {
          const { users } = res.data;
          setDesktopRecentData(users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const getNewestPromise = api
      .fetchGridNewest(data)
      .then((res) => {
        if (res.status === 200) {
          const { users } = res.data;
          setDesktopNewestData(users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const getRecommendedPromise = api
      .fetchGridRecommended(data)
      .then((res) => {
        if (res.status === 200) {
          const { users } = res.data;
          setDesktopRecommendedData(users);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    const getDesktopDataPromises = [
      getRecentPromise,
      getNewestPromise,
      getRecommendedPromise,
    ];

    await Promise.all(getDesktopDataPromises);
  };

  const loadDesktopSearches = (userid) => {
    const data = {
      userid,
      skip: desktopSearchesSkip,
    };

    return api
      .fetchDesktopGrid(data)
      .then((res) => {
        if (res.status === 200) {
          const { cards } = res.data;
          const temp = desktopSearches.concat(cards);
          const tempLikedUsers = desktopLikedUsers.concat(res.data.likedusers);
          const tempSuperlikedUsers = desktopSuperlikedUsers.concat(
            res.data.superlikedUsers
          );
          if (cards.length < 15) {
            setEndDesktopSearches(true);
          }
          setDesktopSearches(temp);
          setDesktopLikedUsers(tempLikedUsers);
          setDesktopSuperlikedUsers(tempSuperlikedUsers);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sortbyDesktopSearches = () => {
    setDesktopSearchLoading(true);
    setDesktopSearches([]);
    setDesktopLikedUsers([]);
    setDesktopSuperlikedUsers([]);
    setDesktopSearchesSkip(0);
    setEndDesktopSearches(false);
    setDesktopSortby(true);
  };

  const getMoreDesktopSearches = () => {
    setDesktopSearchesSkip(desktopSearchesSkip + 15);
  };

  const loadSearches = (userid) => {
    const loadedUserIDs = [];

    searches &&
      searches.length !== 0 &&
      searches.map((search) => {
        loadedUserIDs.push(search._id.toString());
      });

    const data = {
      userid,
      skip: searchesSkip,
      loadedUsers: loadedUserIDs,
    };

    return api
      .fetchSearchGrid(data)
      .then((res) => {
        const { users, likedUsers, superlikedUsers, spotlightUsers } = res.data;
        const temp = searches.concat(users);
        const likedUsersTemp = likedUsers.concat(likedUsers);
        // const spotlightTemp = spotlight.concat(spotlightUsers);
        if (users && users.length < 50) {
          setEndSearches(true);
        }

        setSpotlight(spotlightUsers);
        setSearches(temp);
        setSearchesLoading(false);
        setLikedUsers(likedUsersTemp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sortbySearches = () => {
    setSearchesLoading(true);
    setSearchesSkip(0);
    setEndSearches(false);
    setSearches([]);
    setLikedUsers([]);
    setSuperlikedUsers([]);
    setSpotlight([]);
    setSortby(true);
  };

  const getMoreSearches = () => {
    setSearchesSkip(searchesSkip + 50);
  };

  const loadMatches = (userid) => {
    const data = {
      userid,
      skip: matchesSkip,
    };

    return api
      .fetchMatches(data)
      .then((res) => {
        const newMatches = res.data.matches;
        const temp = matches.concat(newMatches);
        setMatches(temp);
        if (newMatches.length < 9) {
          setMaxMatches(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkMoreMatches = () => {
    setMatchesSkip(matchesSkip + 9);
  };

  const loadLikes = (userid) => {
    const data = {
      userid,
      skip: likesSkip,
    };

    return api
      .fetchLikes(data)
      .then((res) => {
        const newLikes = res.data.likes;
        const temp = likes.concat(newLikes);
        setLikes(temp);
        if (newLikes.length < 12) {
          setMaxLikes(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkMoreLikes = () => {
    setLikesSkip(likesSkip + 12);
  };

  const loadMatchData = (userid) => {
    const data = {
      userid,
      page: matchSkip,
    };

    return api
      .fetchMatchQueue(data)
      .then((res) => {
        const { matches, matchCount } = res.data;
        const temp = matchData.concat(matches);
        setMatchesCount(matchCount);
        setMatchData(temp);
        if (matches.length < 20) {
          setEndMatches(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMoreMatches = () => {
    setMatchSkip(matchSkip + 1);
  };

  const loadMessageData = (userid) => {
    const data = {
      userid,
      skip: messageSkip,
      filter: messageQueueSortby,
    };

    return api
      .fetchChatQueue(data)
      .then((res) => {
        const { messages, matchedIDs, complimentIDs } = res.data;
        const newData = messageData.concat(messages);
        setMessageData(newData);
        setMatchedIDs(matchedIDs);
        setComplimentIDs(complimentIDs);
        if (messages.length < 12) {
          setEndMessages(true);
        }
        setMessageQueueLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadSwipeData = (userid) => {
    const data = { userid };
    setSwipeData(initialSwipeData);
    return api.fetchSwipe(data).then((res) => {
      setSwipeData({
        ...res.data,
        index: 0,
        loading: false,
      });
      setNeedReload(false);
    });
  };

  const getMoreMessage = () => {
    setMessageSkip(messageSkip + 12);
  };

  const updateMatches = () => {
    setMatches([]);
    setMatchesSkip(0);
    setMaxMatches(false);
    setMatchesRefresh(true);
  };

  const updateLikes = (currentlikes) => {
    if (currentlikes) {
      setLikes(currentlikes);
    } else {
      setLikes([]);
      setLikesSkip(0);
      setMaxLikes(false);
      setLikesRefresh(true);
    }
  };

  const updateMatchesAsRead = () => {
    let count = 0;
    matches.map((match) => {
      if (!match.isRead) {
        const index = matchData.findIndex((item) => item._id === match._id);
        if (index > -1) {
          matchData[index].isRead = true;
        }
        socket.checkMatch({
          senderId: match.sender,
          receiverId: match.receiver,
        });
        match.isRead = true;
        count += 1;
      }
      return match;
    });
    setMatches(matches);
    setMatchData(matchData);
    setLikesCounter(likesCounter - count);
  };

  const updateLikesAsRead = () => {
    let count = 0;
    likes.map((like) => {
      if (!like.isRead) {
        like.isRead = true;
        socket.checkLike({ senderId: like.sender, receiverId: user._id });
        count += 1;
      }
      return like;
    });
    setLikes(likes);
    setLikesCounter(likesCounter - count);
  };

  const updataMatchData = (chatid, action) => {
    if (action !== "match") {
      const index = matchData.findIndex((match) => match.userId === chatid);
      const temp = matchData;
      if (action === "read" && index > -1) {
        temp[index].isRead = true;
        const matchIndex = matches.findIndex(
          (item) => item._id === temp[index]._id
        );
        if (matchIndex > -1) {
          matches[matchIndex].isRead = true;
          setMatches(matches);
        }
        setMatchData(temp);
        setLikesCounter(likesCounter - 1);
      } else if (action === "unmatch" && index > -1) {
        temp.splice(index, 1);
        setMatchData(temp);
      }
    } else if (action === "match") {
      api
        .getOneUser({ chatid })
        .then((res) => {
          const newMatch = {
            date: moment.utc(moment()).format(),
            isRead: false,
            messaged: false,
            user: res.data,
            userId: res.data._id,
          };
          const temp = [newMatch];
          const newMatchData = temp.concat(matchData);
          setMatchData(newMatchData);
          setLikesCounter(likesCounter + 1);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateComplimentIDs = async (e) =>
    api
      .fetchComplimentIDs()
      .then((res) => {
        if (res.status === 200) {
          setComplimentIDs(res.data.complimentIDs);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  const updateMessageData = async (chatid, message, action, compliment) => {
    if (action === "unmatch") {
      const index = messageData.findIndex(
        (item) => item.sender === chatid || item.receiver === chatid
      );
      if (index > -1) {
        const temp = messageData;
        temp.splice(index, 1);
        return setMessageData(temp);
      }
    } else if (action === "send") {
      const index = messageData.findIndex(
        (item) => item.sender === chatid || item.receiver === chatid
      );
      if (index > -1) {
        const newMessage = {
          ...messageData[index],
          message,
          isRead: true,
          sender: user._id,
          receiver: chatid,
          _id: [user._id, chatid],
        };
        messageData.splice(index, 1);
        messageData.unshift(newMessage);
        if (compliment) {
          updateComplimentIDs();
          loadDiamondCount();
        }
        return setMessageData(messageData);
      }
      return api.getOneUser({ chatid }).then((res) => {
        const newMessage = {
          createdAt: moment.utc(moment()).format(),
          isRead: true,
          message,
          receiver: chatid,
          sender: user._id,
          user: res.data,
          _id: [user._id, chatid],
        };
        messageData.unshift(newMessage);
        if (compliment) {
          updateComplimentIDs();
          loadDiamondCount();
        }
        return setMessageData(messageData);
      });
    } else if (action === "receive") {
      const index = messageData.findIndex(
        (item) => item.sender === chatid || item.receiver === chatid
      );
      if (index > -1) {
        const checkUrl = window.location.href.split("/");
        let isRead = false;
        if (checkUrl[checkUrl.length - 2] === "messages") {
          isRead = true;
        }
        const newMessage = {
          ...messageData[index],
          message,
          isRead,
          sender: chatid,
          receiver: user._id,
          _id: [chatid, user._id],
        };

        if (messageData[index] && messageData[index].message === message) {
          return null;
        }
        if (!isRead && messageData[index].isRead) {
          setMessageCounter((messageCounter) => messageCounter + 1);
        }
        messageData.splice(index, 1);
        messageData.unshift(newMessage);
        return setMessageData(messageData);
      }
      return api.getOneUser({ chatid }).then((res) => {
        const newMessage = {
          createdAt: moment.utc(moment()).format(),
          isRead: false,
          message,
          receiver: user._id,
          sender: chatid,
          user: res.data,
          _id: [chatid, user._id],
        };
        const checkIndex = messageData.findIndex(
          (item) => item.sender === chatid || item.receiver === chatid
        );
        if (
          messageData[checkIndex] &&
          messageData[checkIndex].message === message
        ) {
          return null;
        }
        setMessageCounter(messageCounter + 1);
        messageData.unshift(newMessage);
        return setMessageData(messageData);
      });
    } else if (action === "read") {
      const index = messageData.findIndex(
        (item) => item.sender === chatid || item.receiver === chatid
      );
      if (index > -1) {
        const newMessage = {
          ...messageData[index],
          isRead: true,
        };
        if (messageData[index].isRead === false) {
          setMessageCounter(messageCounter - 1);
        }
        messageData.splice(index, 1, newMessage);
        return setMessageData(messageData);
      }
    }
  };

  const updateUserInfo = (option, value) => {
    let tempUser = user;
    if (option === "email") {
      tempUser.email = value;
    } else if (option === "socials") {
      if (value === "google") {
        tempUser.socials.google = true;
      } else if (value === "facebook") {
        tempUser.socials.facebook = true;
      } else if (value === "twitter") {
        tempUser.socials.twitter = true;
      } else if (value === "linkedin") {
        tempUser.socials.linkedin = true;
      } else if (value === "instagram") {
        tempUser.socials.instagram = true;
      }
    } else if (option === "verifications") {
      tempUser.verifications = value;
    } else if (option === "identity") {
      tempUser.identity = value;
    } else if (option === "all") {
      tempUser = value;
    } else if (option === "subscription") {
      tempUser.subscription = value;
    } else if (option === "address") {
      tempUser.address = value;
    } else if (option === "location") {
      tempUser.location.coordinates = value;
    } else if (option === "age") {
      tempUser.age = value;
    } else if (option === "inventory") {
      tempUser.inventory = value;
    }
    setUser({ ...tempUser, tempUser });
  };

  const updateLikedUsers = (data) => {
    setLikedUsers(data);
  };

  const updateSuperlikedUsers = (data) => {
    setSuperlikedUsers(data);
  };

  const updateMessageQueueSortby = (option) => {
    setMessageData([]);
    setMessageSkip(0);
    setEndMessages(false);
    setMessageQueueSortby(option);
    setMessageQueueLoading(true);
    setMessageQueueFiltering(true);
  };

  const setSwipeIndex = (index) => {
    if (index === swipeData.cards.length) {
      loadSwipeData(user._id);
    } else {
      setSwipeData((prevSwipeData) => ({
        ...prevSwipeData,
        index,
      }));
    }
  };

  const setRefresh = (value) => {
    setNeedReload(value);
  };

  const updateSwipe = (e) => {
    setSwipeData({ loading: true });
    loadSwipeData();
  };

  const updateBoostData = (e) => {
    setBoostData(e);
  };

  const updateRequests = (value) => {
    setRequests(value);
  };

  const updateApprovedRequests = (value) => {
    setApprovedRequests(value);
  };

  const updateHidden = async (e) => {
    const tempUser = user;

    if (e !== null || e !== undefined) {
      tempUser.hidden = e;
    }

    setUser(tempUser);

    const data = {
      hidden: e,
    };

    await api.updateHidden(data).catch((err) => {
      console.log(err);
    });
    setSearchesLoading(true);
    setSearchesSkip(0);
    setEndSearches(false);
    setSearches([]);
    setLikedUsers([]);
    setSuperlikedUsers([]);
    setSpotlight([]);
    // setSortby(true);
    loadSearches(tempUser._id);
    updateSwipe();
  };

  const updateFilters = async (e) => {
    const tempUser = user;
    let tempFilters = tempUser.filters;
    if (e) {
      tempFilters = e;
      tempUser.filters = tempFilters;
    }
    setUser(tempUser);

    const data = {
      filters: tempFilters,
    };

    await api
      .updateFilters(data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });

    setSearchesLoading(true);
    setSearchesSkip(0);
    setEndSearches(false);
    setSearches([]);
    setLikedUsers([]);
    setSuperlikedUsers([]);
    setSpotlight([]);
    setSortby(true);
    updateSwipe();
  };

  const updatePreferences = (e) => {
    const tempUser = user;
    let tempPreferences = tempUser.preferences;
    if (e) {
      tempPreferences = e;
      tempUser.preferences = tempPreferences;
    }

    setUser(tempUser);

    const data = {
      preferences: tempUser.preferences,
    };

    return api
      .updatePreferences(data)
      .then((res) => {})
      .catch((err) => {});
  };

  const removeBannedUserFromContext = (e) => {
    const tempSearches = searches;
    const tempSpotlight = spotlight;
    const tempSwipeData = swipeData;
    const swipeCards = tempSwipeData.cards;

    const result = tempSearches.filter((search) => search._id !== e);
    const resultB = tempSpotlight.filter((x) => x._id !== e);
    const resultC = swipeCards.filter((y) => y._id !== e);

    tempSwipeData.cards = resultC;

    setSearches(result);
    setSpotlight(resultB);
    setSwipeData((prevState) => ({ ...prevState, tempSwipeData }));
  };

  if (user.loaded === true) {
    const value = {
      user,
      swipeData,
      boostData,
      diamondCount,
      scrollPositions,
      messageData,
      needReload,
      loadContext,
      endMessages,
      matchData,
      endMatches,
      matchesCount,
      matches,
      maxMatches,
      likes,
      maxLikes,
      searches,
      spotlight,
      searchesLoading,
      likedUsers,
      superlikedUsers,
      endSearches,
      desktopSearches,
      desktopSearchLoading,
      desktopLikedUsers,
      desktopSuperlikedUsers,
      endDesktopSearches,
      likesCounter,
      messageCounter,
      desktopRecentData,
      desktopNewestData,
      desktopRecommendedData,
      messageQueueSortby,
      messageQueueLoading,
      requests,
      endRequests,
      approvedRequests,
      endApprovedRequests,
      setSwipeIndex,
      setRefresh,
      loadMessageData,
      getMoreMessage,
      updateMessageData,
      getMoreMatches,
      updataMatchData,
      checkMoreMatches,
      updateMatches,
      updateMatchesAsRead,
      checkMoreLikes,
      updateLikes,
      updateLikesAsRead,
      updateUserInfo,
      updateLikedUsers,
      updateSuperlikedUsers,
      sortbySearches,
      getMoreSearches,
      sortbyDesktopSearches,
      getMoreDesktopSearches,
      updateSwipe,
      updateFilters,
      updateBoostData,
      updateMessageQueueSortby,
      updateHidden,
      updatePreferences,
      loadDiamondCount,
      updateScrollPosition,
      updateRequests,
      getMoreRequests,
      updateApprovedRequests,
      getMoreApprovedRequests,
      removeBannedUserFromContext,
      matchedIDs,
      complimentIDs,
    };

    return (
      <UserContext.Provider value={value}>
        {props.children}
      </UserContext.Provider>
    );
  }
  return <div />;
}

export default withRouter(UserProvider);
