import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./swipeable.scss";

const DragDirection = {
  UP: 1,
  DOWN: 2,
  LEFT: 3,
  RIGHT: 4,
  UNKNOWN: 5,
};

class Swipeable extends PureComponent {
  constructor(props) {
    super(props);

    this.requestedAnimationFrame = null;
    this.wrapper = null;

    this.previousSwipeDistancePercent = 0;

    this.resetState();
  }

  resetState = () => {
    this.dragStartPoint = { x: -1, y: -1 };
    this.dragDirection = DragDirection.UNKNOWN;
    this.left = 0;
    this.previousSwipeDistancePercent = 0;
  };

  get dragHorizontalDirectionThreshold() {
    return this.props.swipeStartThreshold;
  }

  get dragVerticalDirectionThreshold() {
    return this.props.scrollStartThreshold;
  }

  componentDidMount() {
    this.wrapper.addEventListener("mousedown", this.handleDragStartMouse);

    this.wrapper.addEventListener("touchstart", this.handleDragStartTouch);
    this.wrapper.addEventListener("touchend", this.handleDragEndTouch);
    this.wrapper.addEventListener("touchmove", this.handleTouchMove, {
      capture: true,
      passive: false,
    });
  }

  componentWillUnmount() {
    if (this.requestedAnimationFrame) {
      cancelAnimationFrame(this.requestedAnimationFrame);

      this.requestedAnimationFrame = null;
    }

    this.wrapper.removeEventListener("mousedown", this.handleDragStartMouse);

    this.wrapper.removeEventListener("touchstart", this.handleDragStartTouch);
    this.wrapper.removeEventListener("touchend", this.handleDragEndTouch);
    this.wrapper.removeEventListener("touchmove", this.handleTouchMove, {
      capture: true,
      passive: false,
    });
  }

  handleDragStartMouse = (event) => {
    window.addEventListener("mouseup", this.handleDragEndMouse);
    window.addEventListener("mousemove", this.handleMouseMove);

    this.wrapper.addEventListener("mouseup", this.handleDragEndMouse);
    this.wrapper.addEventListener("mousemove", this.handleMouseMove);

    this.handleDragStart(event);
  };

  handleDragStartTouch = (event) => {
    window.addEventListener("touchend", this.handleDragEndTouch);

    const touch = event.targetTouches[0];
    this.handleDragStart(touch);
  };

  handleDragStart = ({ clientX, clientY }) => {
    this.resetState();
    this.dragStartPoint = { x: clientX, y: clientY };

    this.scheduleUpdatePosition();
  };

  handleMouseMove = (event) => {
    if (this.dragStartedWithinItem()) {
      const { clientX, clientY } = event;

      this.setDragDirection(clientX, clientY);

      if (this.isSwiping()) {
        event.stopPropagation();
        event.preventDefault();

        this.left = clientX - this.dragStartPoint.x;
        this.scheduleUpdatePosition();
      }
    }
  };

  handleTouchMove = (event) => {
    if (this.dragStartedWithinItem()) {
      const { clientX, clientY } = event.targetTouches[0];

      this.setDragDirection(clientX, clientY);

      if (!event.cancelable) {
        return;
      }

      if (this.isSwiping()) {
        event.stopPropagation();
        event.preventDefault();

        this.left = clientX - this.dragStartPoint.x;
        this.scheduleUpdatePosition();
      }
    }
  };

  handleDragEndMouse = () => {
    window.removeEventListener("mouseup", this.handleDragEndMouse);
    window.removeEventListener("mousemove", this.handleMouseMove);

    if (this.wrapper) {
      this.wrapper.removeEventListener("mouseup", this.handleDragEndMouse);
      this.wrapper.removeEventListener("mousemove", this.handleMouseMove);
    }

    this.handleDragEnd();
  };

  handleDragEndTouch = () => {
    window.removeEventListener("touchend", this.handleDragEndTouch);

    this.handleDragEnd();
  };

  handleDragEnd = () => {
    const { left, props } = this;
    const { threshold } = props;

    let actionTriggered = false;

    if (this.isSwiping()) {
      if (left < this.wrapper.offsetWidth * threshold * -1) {
        actionTriggered = true;
        this.handleSwipedLeft();
      } else if (left > this.wrapper.offsetWidth * threshold) {
        if (props.enableLike) {
          actionTriggered = true;
          this.handleSwipedRight();
        } else {
          props.checkEnableLike(true);
        }
      }

      if (!actionTriggered && this.props.onSwipeReturn) {
        this.props.onSwipeReturn();
      }

      if (this.props.onSwipeEnd) {
        this.props.onSwipeEnd();
      }
    }

    this.resetState();
  };

  dragStartedWithinItem = () => {
    const { x, y } = this.dragStartPoint;

    return x !== -1 && y !== -1;
  };

  setDragDirection = (x, y) => {
    if (this.dragDirection === DragDirection.UNKNOWN) {
      const { x: startX, y: startY } = this.dragStartPoint;
      const horizontalDistance = Math.abs(x - startX);
      const verticalDistance = Math.abs(y - startY);

      if (
        horizontalDistance <= this.dragHorizontalDirectionThreshold &&
        verticalDistance <= this.dragVerticalDirectionThreshold
      ) {
        return;
      }

      const angle = Math.atan2(y - startY, x - startX);
      const octant = Math.round((8 * angle) / (2 * Math.PI) + 8) % 8;

      switch (octant) {
        case 0:
          if (horizontalDistance > this.dragHorizontalDirectionThreshold) {
            this.dragDirection = DragDirection.RIGHT;
          }
          break;
        case 1:
        case 2:
        case 3:
          if (verticalDistance > this.dragVerticalDirectionThreshold) {
            this.dragDirection = DragDirection.DOWN;
          }
          break;
        case 4:
          if (horizontalDistance > this.dragHorizontalDirectionThreshold) {
            this.dragDirection = DragDirection.LEFT;
          }
          break;
        case 5:
        case 6:
        case 7:
          if (verticalDistance > this.dragVerticalDirectionThreshold) {
            this.dragDirection = DragDirection.UP;
          }
          break;
        default:
      }

      if (this.props.onSwipeStart && this.isSwiping()) {
        this.props.onSwipeStart();
      }
    }
  };

  isSwiping = () => {
    const { blockSwipe } = this.props;
    const horizontalDrag =
      this.dragDirection === DragDirection.LEFT ||
      this.dragDirection === DragDirection.RIGHT;

    return !blockSwipe && this.dragStartedWithinItem() && horizontalDrag;
  };

  scheduleUpdatePosition = () => {
    if (this.requestedAnimationFrame) {
      return;
    }

    this.requestedAnimationFrame = requestAnimationFrame(() => {
      this.requestedAnimationFrame = null;

      this.updatePosition();
    });
  };

  updatePosition = () => {
    if (this.isSwiping()) {
      const wrapperWidth = this.wrapper.offsetWidth;
      let swipeDistancePercent = this.previousSwipeDistancePercent;

      let swipeDistance = Math.max(
        -wrapperWidth,
        Math.min(wrapperWidth, this.left)
      );

      if (wrapperWidth !== 0) {
        swipeDistancePercent = swipeDistance / wrapperWidth;
      }

      if (this.previousSwipeDistancePercent !== swipeDistancePercent) {
        this.props.onSwipeProgress(swipeDistance);
        this.previousSwipeDistancePercent = swipeDistancePercent;
      }

    }
  };

  handleSwipedLeft = () => {
    if (this.props.onSwipedLeft) {
      this.props.onSwipedLeft();
    }
  };

  handleSwipedRight = () => {
    if (this.props.onSwipedRight) {
      this.props.onSwipedRight();
    }
  };

  // This can be called through ref to this component
  scrollToTop = () => {
    this.wrapper.scrollTop = 0;
  };

  bindWrapper = (ref) => (this.wrapper = ref);

  render() {
    const { children, className, returning, transform, style } = this.props;

    return (
      <div
        ref={this.bindWrapper}
        className={`swipeable ${className} ${
          returning ? "swipeable--return" : ""
        }`}
        style={{ ...style, transform }}
      >
        {children}
      </div>
    );
  }
}

Swipeable.propTypes = {
  blockSwipe: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
  scrollStartThreshold: PropTypes.number,
  swipeStartThreshold: PropTypes.number,
  threshold: PropTypes.number,

  onSwipeProgress: PropTypes.func,
  onSwipedLeft: PropTypes.func,
  onSwipedRight: PropTypes.func,
  onSwipeReturn: PropTypes.func,
  onSwipeStart: PropTypes.func,
  onSwipeEnd: PropTypes.func,
};

Swipeable.defaultProps = {
  className: "",
  style: {},
  scrollStartThreshold: 10,
  swipeStartThreshold: 10,
  threshold: 0.4,
};

export default Swipeable;
