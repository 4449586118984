import React, { Component } from "react";
import AdminNavbar from "../../admincomponents/adminNavbar/AdminNavbar";
import { UserContext } from "../../../context/UserContext";
import { withRouter } from "react-router-dom";
import BotsCard from "../../admincomponents/botsCard/BotsCard";

export class Bots extends Component {
  static contextType = UserContext;

  componentWillMount() {
    if (!this.context.user.isAdmin) {
      this.props.history.push("/app");
    }
  }
  render() {
    return (
      <div>
        <AdminNavbar />
        <BotsCard />
      </div>
    );
  }
}

export default withRouter(Bots);
