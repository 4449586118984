import React, { useState, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import PhotoGridV2 from "../../photoGrid/PhotoGridV2";
import { UserContext } from "../../../context/UserContext";
import "./photosv2styles.scss";
import { useHistory, withRouter } from "react-router-dom";

import "../welcomev2.scss";

const PhotosV2 = (props) => {
  const history = useHistory();
  const context = useContext(UserContext);

  const [showGuideline, setShowGuideline] = useState(false);
  const [hasImage, setHasImage] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [advanceDisabled, setAdvanceDisabled] = useState(true);

  const onCheck = () => {
    setHasImage(true);
  };

  const skipHandler = () => {
    if (
      context.user.imgurl.length > 0 ||
      context.user.privatePhotos.length > 0
    ) {
      props.nextStep();
    } else {
      setShowWarning(true);
    }
  };

  const confirmSkipHandler = (e) => {
    history.push("/app/welcome/physicals");
  };

  const vh = window.innerHeight;
  const vw = window.innerWidth;
  let maxHeight = null;
  if (vw > 991) {
    maxHeight = `${vh * 0.5}px`;
  }

  const photoContainerStyle = {
    maxHeight,
  };

  return (
    <div className="welcome-v2-container">
      {showWarning && (
        <div className="no-photos-warning">
          <div className="no-photos-innerwrap">
            <div
              className="no-photos-warning-overlay"
              onClick={() => {
                setShowWarning(false);
              }}
            />
            <div className="no-photos-warning-content">
              <div className="no-photos-content-warning">
                <i className="far fa-exclamation-triangle no-photos-warning-icon" />
                <h1 className="no-photos-warning-title">Warning</h1>
                <p className="no-photos-warning-subtitle">
                  Your Profile will be hidden if you don't upload at least one
                  public or private photo or video.
                </p>
              </div>
              <div
                className="no-photos-footer-button"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                Upload Photos
              </div>
              <div
                className="no-photos-warning-footer"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                <span
                  className="no-photos-close-text"
                  onClick={confirmSkipHandler}
                >
                  Skip
                </span>
                <i className="far fa-times-circle" />
              </div>
            </div>
          </div>
        </div>
      )}

      {showGuideline && (
        <div className="no-photos-warning">
          <div className="no-photos-innerwrap">
            <div
              className="no-photos-warning-overlay"
              onClick={() => {
                setShowGuideline(false);
              }}
            />
            <div className="no-photos-warning-content">
              <div className="no-photos-content">
                <h1 className="no-photos-title">Photo Guidelines</h1>
                <ul className="no-photos-list">
                  <li className="no-photos-list-item">
                    No nudity or sexualy explicit photos.
                  </li>
                  <li className="no-photos-list-item">
                    No photos containing or depicting illegal content.
                  </li>
                  <li className="no-photos-list-item">
                    No photos including children.
                  </li>
                  <li className="no-photos-list-item">
                    Cannot include contact information or social media handles.
                  </li>
                  <li className="no-photos-list-item">
                    Photos must include yourself.
                  </li>
                </ul>
                <p className="no-photos-subtitle">
                  * Uploading content that violates these guidelines can result
                  in your account being banned.
                </p>
              </div>
              <div
                className="no-photos-warning-footer"
                onClick={() => {
                  setShowGuideline(false);
                }}
              >
                <span className="no-photos-close-text">Close</span>
                <i className="far fa-times-circle" />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="welcome-v2-header">
        <div className="welcome-v2-title">
          <h1 className="welcome-v2-title-one">Upload</h1>
          <h2 className="welcome-v2-title-two">Photo & Video</h2>
        </div>
        <p className="welcome-v2-subtitle">
          Upload photos or short videos of you so ther users know what you look
          like.{" "}
          <span
            className="welcome-v2-photo-guideline"
            onClick={() => setShowGuideline(true)}
          >
            Guidelines
          </span>
          <i className="far fa-exclamation-circle welcome-v2-guideline-icon" />
        </p>
      </div>
      <div className="welcome-v2-photo-body" style={photoContainerStyle}>
        <PhotoGridV2 profileUser={context.user} ownProfile onboarding />
      </div>

      <div className="welcome-v2-footer">
        <div className="welcome-v2-button-skip" onClick={() => skipHandler()}>
          Skip
        </div>
        <div className="welcome-v2-button" onClick={() => props.nextStep()}>
          Next
        </div>
      </div>
    </div>
  );
};

export default withRouter(PhotosV2);
