import React, { useState, useEffect, useContext } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Button, Image as IconImage, Alert } from 'react-bootstrap';

import { UserContext } from '../../../context/UserContext';
import { api } from '../../../api';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../utils/getCloudinaryInfo';
import Media from '../../../components/media/Media';

function PublicTab(props) {
  return (
    <div>
      {props.expanded ? (
        <div className="profile-v2-photo-grid-content">
          {props.images.map((image, index) => {
            return (
              <div className="profile-v2-photo-wrapper">
                <Media image={image} />
                {/* <Image
                  publicId={
                    image
                      ? getImgName(image)
                      : "larger-placeholder-user-image_vvdghq.png"
                  }
                  version={image ? getImgVersion(image) : "1590046357"}
                  resource_type={
                    image ? (checkGIF(image) ? "video" : "image") : null
                  }
                  effect="loop"
                  cloud_name="sugarbae"
                  className="profile-v2-photo"
                  onClick={() => props.showImage(index)}
                >
                  <Transformation
                    height="200"
                    video_sampling="20"
                    delay="250"
                    duration="10"
                    effect="loop"
                    quality="auto"
                    flags="lossy"
                  />
                </Image> */}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="profile-v2-photo-grid-content">
          {props.images.map((image, index) => {
            var count = props.images.length;
            var remaining = count - 5;
            var remainingText = '+ ' + remaining.toString();
            if (index > 5) {
              return null;
            } else {
              if (index === 5 && props.images.length > 6) {
                return (
                  <div
                    className="profile-v2-photo-wrapper"
                    onClick={props.expandHandler}
                  >
                    <div className="profile-v2-photo-overlay">
                      {remainingText}
                    </div>
                    {/* <Image
                      publicId={
                        image
                          ? getImgName(image)
                          : 'larger-placeholder-user-image_vvdghq.png'
                      }
                      version={image ? getImgVersion(image) : '1590046357'}
                      resource_type={
                        image ? (checkGIF(image) ? 'video' : 'image') : null
                      }
                      effect="loop"
                      cloud_name="sugarbae"
                      className="profile-v2-photo"
                      onClick={() => props.showImage(index)}
                    >
                      <Transformation
                        height="200"
                        video_sampling="20"
                        delay="250"
                        duration="10"
                        effect="loop"
                        quality="auto"
                        flags="lossy"
                      />
                    </Image> */}

                    <Media image={image} />
                  </div>
                );
              } else {
                return (
                  <div
                    className="profile-v2-photo-wrapper"
                    onClick={() => props.showImage(index)}
                  >
                    <Media image={image} />
                    {/* <Image
                      publicId={
                        image
                          ? getImgName(image)
                          : 'larger-placeholder-user-image_vvdghq.png'
                      }
                      version={image ? getImgVersion(image) : '1590046357'}
                      resource_type={
                        image ? (checkGIF(image) ? 'video' : 'image') : null
                      }
                      effect="loop"
                      cloud_name="sugarbae"
                      className="profile-v2-photo"
                    >
                      <Transformation
                        height="200"
                        video_sampling="20"
                        delay="250"
                        duration="10"
                        effect="loop"
                        quality="auto"
                        flags="lossy"
                      />
                    </Image> */}
                  </div>
                );
              }
            }
          })}
        </div>
      )}
    </div>
  );
}

export default PublicTab;
