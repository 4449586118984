import React, { useState, useEffect } from "react";
import { api } from "../../api";
//import DailySignups from "../graphs/DailySignups";
import Conversions from "../graphs/Conversions";
import ConversionsByAmount from "../graphs/ConversionsByAmount";
import "./views.scss";

function ConversionsView(props) {
  return (
    <div className="view-container">
      <div className="view-row">
        <Conversions />
      </div>
      <div className="view-row">
        <ConversionsByAmount />
      </div>
    </div>
  );
}

export default ConversionsView;
