import React, { useState, useEffect, useContext } from "react";
import Switch from "@material-ui/core/Switch";
import { UserContext } from "../../../../../context/UserContext";
import PremiumModal from "../../../../modals/premiumModal/PremiumModal";

function Plus(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;

  const [premiumModalOpen, setPremiumModalOpen] = useState(false);

  const togglePremiumModal = (e) => {
    if (premiumModalOpen) {
      setPremiumModalOpen(false);
    } else {
      setPremiumModalOpen(true);
    }
  };

  const privateToggle = (e) => {
    if (context.user.premium) {
      if (context.user.hidden) {
        context.updateHidden(false);
      } else {
        context.updateHidden(true);
      }
    } else {
      setPremiumModalOpen(true);
    }
  };

  const verifiedToggle = (e) => {
    if (context.user.premium) {
      if (filters.verified) {
        filters.verified = false;
        context.updateFilters(filters);
      } else {
        filters.verified = true;
        context.updateFilters(filters);
      }
    } else {
      setPremiumModalOpen(true);
    }
  };

  const resetAllHandler = (e) => {
    if (context.user.premium) {
      filters.verified = false;
      context.updateFilters(filters);
      context.updateHidden(false);
    } else {
      setPremiumModalOpen(true);
    }
  };

  return (
    <div className="desktop-v2-filters-content">
      {premiumModalOpen && (
        <PremiumModal
          onClose={togglePremiumModal}
          show={premiumModalOpen}
          option="advancedFilters"
        />
      )}
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.backHandler}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-title">
              Advanced Filters
            </div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={resetAllHandler}
            >
              Reset All
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <div className="filters-v2-section-row">
            <div className="filters-v2-section-row-icon">
              <i className="far fa-eye-slash" />
            </div>
            <div className="filters-v2-section-row-title">
              Private Browsing Mode
            </div>
            <div className="filters-v2-section-row-content-plus">
              <Switch
                checked={context.user.hidden}
                onChange={privateToggle}
                name="private"
                className="plus-settings-switch"
              />
            </div>
          </div>
          <div className="filters-v2-section-row">
            <div className="filters-v2-section-row-icon">
              <i className="fas fa-badge-check" />
            </div>
            <div className="filters-v2-section-row-title">
              Verified Profiles Only
            </div>
            <div className="filters-v2-section-row-content-plus">
              <Switch
                checked={filters.verified}
                onChange={verifiedToggle}
                name="verified"
                className="plus-settings-switch"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plus;
