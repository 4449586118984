import React, { useContext, useState } from "react";
import { UserContext } from "../../../../context/UserContext";
import logo from "../../../../assets/logos/sugarbae-home-icon.png";
import { Button, Image } from "react-bootstrap";
import "../userprofilev2componentstyles.scss";
import bolt from "../../../../assets/Icons/bolt.svg";
var moment = require("moment");

function Boost(props) {
  const context = useContext(UserContext);

  const toggleBoost = (e) => {
    props.toggleBoost();
  };

  var expirationDate = context.boostData.boostExpirationDate;
  var now = moment();
  var dateType = "hours";
  if (expirationDate) {
    expirationDate = moment(expirationDate);
    var difference = expirationDate.diff(now, "hours");
    if (difference < 1) {
      difference = expirationDate.diff(now, "minutes");
      dateType = "minutes";
      if (difference < 1) {
        difference = expirationDate.diff(now, "seconds");
        dateType = "seconds";
      }
    }
  }

  return (
    <div className="user-profile-v2-component-boost" onClick={toggleBoost}>
      <div className="profile-component-section">
        <div className="user-profile-v2-icon-wrapper">
          <Image src={bolt} className="user-profile-diamond-icon" fluid />
        </div>
        {context.boostData.boosted ? (
          <h4 className="verification-progress-title-boost">Boosted</h4>
        ) : (
          <h4 className="verification-progress-title-boost">Boost</h4>
        )}
        {context.boostData.boosted && (
          <h1 className="verifications-progress-number-boost">
            {difference}
            {dateType === "hours" && (
              <span style={{ marginLeft: "5px" }}>hours</span>
            )}
            {dateType === "minutes" && (
              <span style={{ marginLeft: "5px" }}>minutes</span>
            )}
            {dateType === "seconds" && (
              <span style={{ marginLeft: "5px" }}>seconds</span>
            )}
          </h1>
        )}
      </div>
    </div>
  );
}

export default Boost;
