import React, { Component } from "react";
import "../modals.scss";
import {
  Button,
  Card,
  Image,
  Modal,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Axios from "axios";
import { UserContext } from "../../../context/UserContext";
import { withRouter } from "react-router-dom";
import { api } from "../../../api";

export class SendNotePop extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      show: true,
      loading: true,
      image: "",
      username: "",
      message: "",
      messageInvalid: false,
      disabledBtn: false,
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData = async (e) => {
    var cardid = this.props.cardid;

    const data = {
      userid: this.context.user._id,
      cardid: cardid,
    };

    return api
      .fetchSendNotePop(data)
      .then((res) => {
        if (res.status === 200) {
          var image = res.data.image;
          var username = res.data.username;
          this.setState({
            image: image,
            username: username,
            loading: false,
          });
        }
      })
      .catch((err) => {});
  };

  closeHandler = (e) => {
    this.setState({ show: false }, () => {
      this.props.closeHandler();
    });
  };

  linkClickHandler = (e) => {
    this.props.history.push("/app/premium");
  };

  textChangeHandler = (e) => {
    this.setState({ message: e.target.value, messageInvalid: false });
  };

  likeTypeToggle = (props) => (e) => {
    this.setState({ liketype: props });
  };

  submitHandler = async (e) => {
    e.preventDefault();
    if (this.state.message.length > 0) {
      this.setState({ show: false });
      var message = this.state.message;
      this.props.submitHandler(message);
    } else {
      this.setState({ messageInvalid: true });
    }
  };

  render() {
    return (
      <Modal size="lg" show={this.state.show} onHide={this.closeHandler}>
        <Modal.Body className="swipe-pop-modal-body-note">
          <Image
            className="swipe-pop-modal-body-image"
            src={this.state.image}
            roundedCircle
          />
          <h2 className="swipe-pop-body-title">
            Send a note to {this.state.username}!
          </h2>
          <div className="swipe-pop-body-countdown-wrapper"></div>
          <p className="swipe-pop-body-subtitle">
            Send a note to {this.state.username} to get their attention!
          </p>
          <Card className="swipe-pop-features-card">
            <Card.Body className="swipe-pop-features-card-body">
              <Form onSubmit={this.submitHandler}>
                <InputGroup size="lg" className="chat-card-inputgroup">
                  <FormControl
                    placeholder="Type a message"
                    className="chat-card-input"
                    onChange={this.textChangeHandler}
                    value={this.state.message}
                    isInvalid={this.state.messageInvalid}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="primary"
                      type="submit"
                      className="chat-card-send-btn"
                    >
                      Send
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer className="swipe-pop-footer">
          <Button
            className="swipe-pop-close-btn"
            onClick={this.closeHandler}
            size="lg"
            variant="link"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(SendNotePop);
