import React, { useState, useEffect, useContext } from "react";
import { Image, Transformation } from "cloudinary-react";
import { Button, Image as IconImage, Alert } from "react-bootstrap";

import { UserContext } from "../../context/UserContext";
import { api } from "../../api";
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from "../../utils/getCloudinaryInfo";
import PhotoView from "../photoView/PhotoView";

import "./photogridv2.scss";
import PublicTab from "./photoGridComponents/PublicTab";
import PrivateTab from "./photoGridComponents/PrivateTab";
import OwnProfile from "./photoGridComponents/OwnProfile";

function PhotoGridV2(props) {
  const context = useContext(UserContext);
  const [expanded, setExpanded] = useState(false);
  const [enableView, setEnableView] = useState(false);
  const [viewIndex, setViewIndex] = useState(null);
  const [tab, setTab] = useState("public");
  const [requested, setRequested] = useState(false);
  const [requestApproved, setRequestApproved] = useState(false);
  const [uploadClick, setUploadClick] = useState(false);

  useEffect(() => {
    const checkRequest = async () => {
      const data = {
        cardid: props.profileUser._id,
      };

      await api
        .checkRequest(data)
        .then((res) => {
          const { request } = res.data;
          if (context.user._id === props.profileUser._id) {
            setRequested(true);
            setRequestApproved(true);
          } else if (request) {
            setRequested(true);
            setRequestApproved(request.approved);
          }
        })
        .catch(() => {});
    };

    checkRequest();
  }, []);

  const expandHandler = (e) => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  const requestHandler = () => {
    const data = {
      cardid: props.profileUser._id,
    };

    return api
      .requestPrivatePics(data)
      .then((res) => {
        if (res.status === 200) {
          setRequested(true);
        }
      })
      .catch(() => {});
  };

  const showImage = (props) => {
    setViewIndex(props);
    setEnableView(true);
  };

  const closeImage = () => {
    setEnableView(false);
  };

  var images = props.profileUser.imgurl;
  //   var images = [];
  var privateImages = props.profileUser.privatePhotos;
  var privatePhotoCount = 0;

  if (privateImages) {
    privatePhotoCount = privateImages.length;
  }

  var ownProfile = false;
  if (props.ownProfile) {
    ownProfile = props.ownProfile;
  }

  useEffect(() => {
    setUploadClick(props.uploadClick);
  }, [props.uploadClick]);

  return (
    <div className="profile-v2-photo-grid">
      {enableView && (
        <PhotoView
          images={tab === "public" ? images : privateImages}
          index={viewIndex}
          closeHandler={closeImage}
        />
      )}
      <div className="profile-v2-photo-grid-header">
        <div
          className={
            tab === "public"
              ? "profile-v2-photo-grid-header-tab-active"
              : "profile-v2-photo-grid-header-tab"
          }
          onClick={() => setTab("public")}
        >
          Public
        </div>
        <div
          className={
            tab === "private"
              ? "profile-v2-photo-grid-header-tab-active"
              : "profile-v2-photo-grid-header-tab"
          }
          onClick={() => setTab("private")}
        >
          Private ({privatePhotoCount})
        </div>
      </div>

      {ownProfile ? (
        <OwnProfile
          tab={tab}
          uploadClick={uploadClick}
          gallery={props.gallery}
          showImage={showImage}
          onboarding={props.onboarding}
        />
      ) : (
        <div className="photo-grid-v2-content">
          {tab === "public" && (
            <>
              {images.length > 0 ? (
                <PublicTab
                  expanded={expanded}
                  images={images}
                  showImage={showImage}
                  expandHandler={expandHandler}
                />
              ) : (
                <div className="profile-v2-photo-grid-content">
                  <div className="profile-v2-no-photos-innerwrap">
                    <p className="profile-v2-no-photos-text">
                      This user has no public photos.
                    </p>
                  </div>
                </div>
              )}
            </>
          )}

          {tab === "private" && (
            <>
              {privateImages && privateImages.length > 0 ? (
                <PrivateTab
                  requestApproved={requestApproved}
                  privateImages={privateImages}
                  showImage={showImage}
                  requestHandler={requestHandler}
                  privatePhotoCount={privatePhotoCount}
                  requested={requested}
                />
              ) : (
                <div className="profile-v2-photo-grid-content">
                  <div className="profile-v2-no-photos-innerwrap">
                    <p className="profile-v2-no-photos-text">
                      This user has no private photos.
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default PhotoGridV2;
