import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./searchfiltersbar.scss";

export class SearchFiltersBar extends Component {
  openHandler = (e) => {
    this.props.openHandler();
  };
  render() {
    return (
      <div className="search-filters-bar-component">
        <Link to="/app" className="search-filters-link">
          <Button className="search-filters-btn-home">
            <i className="fas fa-home-alt search-filters-icon"></i>
            Home
          </Button>
        </Link>
        <Button className="search-filters-btn" onClick={this.openHandler}>
          <i className="fas fa-sliders-h search-filters-icon"></i>
          Filters
        </Button>
      </div>
    );
  }
}

export default SearchFiltersBar;
