import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import "../admincomponents.scss";

export class JobsAPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userTotal: null,
      botTotal: null,
      createdToday: null,
      loading: false
    };
  }

  fetchData = async e => {
    const token = localStorage.getItem("auth");
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + "/api/admin/updateschema";
    const config = {
      headers: { auth: token }
    };

    await Axios.post(url, config)
      .then(res => {
        console.log(res.data);
      })
      .catch(err => {
        console.log("Error Fetching Users");
      });
  };

  render() {
    return (
      <Card bg={"dark"} text={"white"} style={{ width: "18rem" }}>
        <Card.Header>
          <div className="card-header-wrap">
            <div className="card-header-leftside">
              <h4 className="card-header-title">JOBS API</h4>
            </div>
            <div className="card-header-rightside">
              {this.state.loading ? (
                <ClipLoader
                  size={10}
                  color={"#fff"}
                  loading={this.state.loading}
                />
              ) : (
                <Button variant="outline-light" onClick={this.fetchData}>
                  Fetch Jobs
                </Button>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Card.Text>Jobss: </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

export default JobsAPI;
