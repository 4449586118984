import React from "react";

function EditUsername() {
  return (
    <div>
      <span>Edit username</span>
    </div>
  );
}

export default EditUsername;
