import React, { useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";
import heightIcon from "../../../assets/Icons/heightIcon.svg";

function AgeRange(props) {
  var filters = props.filters;
  const [age, setAge] = useState(filters.age);

  const changeHandler = (event, newValue) => {
    if (newValue[0] > 80) {
      newValue[0] = 80;
    } else if (newValue[0] < 18) {
      newValue[0] = 18;
    }

    if (newValue[1] > 80) {
      newValue[1] = 80;
    } else if (newValue[1] < 18) {
      newValue[1] = 18;
    }
    setAge(newValue);
  };
  const commitHandler = (event, newValue) => {
    if (newValue[0] > 80) {
      newValue[0] = 80;
    } else if (newValue[0] < 18) {
      newValue[0] = 18;
    }

    if (newValue[1] > 80) {
      newValue[1] = 80;
    } else if (newValue[1] < 18) {
      newValue[1] = 18;
    }
    setAge(newValue);
  };

  const saveHandler = (e) => {
    props.updateHandler(age);
  };

  const resetHandler = (e) => {
    setAge([18, 80]);
  };

  return (
    <div className="filter-slider-v2">
      <div className="filter-slider-v2-overlay" onClick={props.onClose} />

      <div className="filter-slider-v2-content">
        <div className="filter-slider-v2-close-bar" />
        <div className="filter-slider-v2-pop">
          <div className="filter-slider-v2-header">
            <div className="filter-slider-v2-header-section">
              <Image src={heightIcon} className="filter-v2-icon" fluid />
              <span className="filter-slider-v2-title">Age Range</span>
            </div>
            <div className="filter-slider-v2-header-section">
              <span
                className="filter-slider-v2-reset-text"
                onClick={resetHandler}
              >
                Reset
              </span>
            </div>
          </div>
          <div className="filter-slider-v2-form-wrapper">
            <h1 className="filter-slider-v2-slider-title">
              Between <span style={{ fontWeight: "700" }}>{age[0]}</span> and
              <span style={{ fontWeight: "700" }}> {age[1]}</span>
            </h1>
            <div className="filter-slider-v2-slider-wrapper">
              <Slider
                min={17}
                max={88}
                value={age}
                onChange={changeHandler}
                onChangeCommitted={commitHandler}
                valueLabelDisplay={"on"}
              />
            </div>
          </div>

          <div className="filter-slider-v2-footer">
            <div className="filter-slider-v2-apply-btn" onClick={saveHandler}>
              Apply
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgeRange;
