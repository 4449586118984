import React, { useState, useEffect, useContext } from 'react';
import '../desktopprofiletabs.scss';
import Axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import { Link, withRouter } from 'react-router-dom';
import PlacesAutocomplete from 'react-places-autocomplete';
import Slider from '@material-ui/core/Slider';
import { Button, Card, Form, Badge } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import './basicinfotab.scss';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import profileProgressCalculator from '../../../../helpers/profileProgressCalculator';
import profileInventoryCalculator from '../../../../helpers/profileInventoryCalculator';

import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { api } from '../../../../api';
import Media from '../../../../components/media/Media';

function BasicInfoTab(props) {
  const [age, setAge] = useState([18, 80]);
  const context = useContext(UserContext);
  const [distance, setDistance] = useState(props.user.filters.distance);
  const [address, setAddress] = useState(props.user.address);
  const [changingAddress, setChangingAddress] = useState('');
  const [networth, setNetWorth] = useState(props.user.lifestyle.networth);
  const [annualIncome, setAnnualIncome] = useState(
    props.user.lifestyle.annualincome
  );
  const [profileProgress, setProfileProgress] = useState(0);
  const [nextStep, setNextStep] = useState('');
  const [stepLink, setStepLink] = useState('/app/profile');

  const [superlikeInventory, setSuperlikeInventory] = useState(null);
  const [boostInventory, setBoostInventory] = useState(null);
  const [superlikePopOpen, setSuperlikePopOpen] = useState(false);
  const [boostPopOpen, setBoostPopOpen] = useState(false);

  useEffect(() => {
    const calculateProgress = async (e) => {
      const result = await profileProgressCalculator(props.user);

      setProfileProgress(result.progress);
      setNextStep(result.nextStep);
      setStepLink(result.stepLink);
    };

    const calculateInventory = async (e) => {
      const inventoryResult = await profileInventoryCalculator(props.user);

      setSuperlikeInventory(inventoryResult.superlikeInventory);
      setBoostInventory(inventoryResult.boostInventory);
    };

    calculateProgress();
    calculateInventory();

    if (props.user.filters.age) {
      setAge([props.user.filters.age[0], props.user.filters.age[1]]);
    }
  }, []);

  const ageChange = (event, newValue) => {
    setAge(newValue);
  };
  const ageSelect = async (event, newValue) => {
    setAge(newValue);

    const data = {
      userid: context.user._id,
      age: newValue,
    };

    return api
      .updateAgeFilters(data)
      .then((res) => {})
      .catch((err) => {});
  };

  const distanceChange = (event, newValue) => {
    setDistance(newValue);
  };

  const distanceSelect = (event, newValue) => {
    setDistance(newValue);
    const data = {
      userid: context.user._id,
      distance: newValue,
    };

    return api
      .updateDistance(data)
      .then((res) => {})
      .catch((err) => {});
  };

  const addressChange = (addressValue) => {
    setChangingAddress(addressValue);
  };

  const addressSelect = async (addressValue) => {
    setAddress(addressValue);
    setChangingAddress(addressValue);

    const data = {
      userid: context.user._id,
      address: addressValue,
    };

    return api
      .updateAddress(data)
      .then((res) => {})
      .catch((err) => {});
  };

  const networthChange = async (e) => {
    setNetWorth(e.target.value);

    const data = {
      userid: context.user._id,
      networth: e.target.value,
    };

    return api
      .updateNetworth(data)
      .then((res) => {})
      .catch((err) => {});
  };
  const annaulIncomeChange = async (e) => {
    setAnnualIncome(e.target.value);

    const data = {
      userid: context.user._id,
      annualincome: e.target.value,
    };

    return api
      .updateAnnualIncome(data)
      .then((res) => {})
      .catch((err) => {});
  };

  const logoutHandler = (e) => {
    localStorage.removeItem('auth');
    props.history.push('/');
  };

  const toggleSuperlikePop = (e) => {
    if (superlikePopOpen) {
      setSuperlikePopOpen(false);
    } else {
      setSuperlikePopOpen(true);
    }
  };

  const toggleBoostPop = (e) => {
    if (boostPopOpen) {
      setBoostPopOpen(false);
    } else {
      setBoostPopOpen(true);
    }
  };

  const searchOptions = {
    types: ['(cities)'],
  };

  var images = props.user.imgurl;
  var mainImage = null;
  if (images.length > 0) {
    mainImage = images[0];
  }

  return (
    <div className="desktop-profile-tab-component">
      <div className="desktop-profile-summary-container">
        <div className="desktop-profile-summary-image-wrapper">
          {mainImage ? (
            <Media image={mainImage} />
          ) : (
            // <Image
            //   publicId={getImgName(mainImage)}
            //   version={getImgVersion(mainImage)}
            //   resource_type={checkGIF(mainImage) ? "video" : "image"}
            //   effect="loop"
            //   cloud_name="sugarbae"
            //   className="desktop-profile-summary-main-image"
            // >
            //   <Transformation
            //     height="200"
            //     video_sampling="20"
            //     delay="250"
            //     duration="10"
            //     effect="loop"
            //     quality="auto"
            //     flags="lossy"
            //   />
            // </Image>
            <div className="desktop-profile-summary-no-images-placeholder">
              <i className="far fa-user desktop-profile-summary-no-images-placeholder-icon"></i>
              <p className="desktop-profile-summary-no-images-title">
                Upload images to get more likes & matches.
              </p>
            </div>
          )}
          <Link
            to="/app/profile/photos"
            className="desktop-profile-summary-no-images-link"
          >
            <Button className="desktop-profile-summary-add-photos-button">
              <i className="far fa-camera desktop-profile-summary-add-photos-icon"></i>
              Add / Edit Photos
            </Button>
          </Link>
        </div>
        <div className="desktop-profile-summary-content-container">
          <div className="desktop-profile-summary-user-info-wrapper">
            <div className="desktop-profile-summary-user-info-section">
              <h2 className="desktop-profile-summary-username">
                {props.user.username}
              </h2>
              <p className="desktop-profile-summary-age-location">
                {props.user.age} • {props.user.address}
              </p>
            </div>
            <div className="desktop-profile-summary-user-info-section">
              {props.user.premium ? (
                <div className="desktop-profile-summary-premium-indicator-wrapper">
                  <Badge
                    className="desktop-profile-summary-premium-badge"
                    variant="warning"
                  >
                    Premium Member
                  </Badge>
                </div>
              ) : (
                <div className="desktop-profile-summary-premium-indicator-wrapper">
                  <Link
                    to="/app/premium"
                    className="desktop-profile-summary-premium-link"
                  >
                    <Button
                      className="desktop-profile-summary-premium-cta-button"
                      size="lg"
                    >
                      <i className="far fa-gem desktop-profile-summary-premium-cta-icon"></i>
                      Go Premium
                    </Button>
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div className="desktop-profile-summary-body-wrapper">
            <div className="desktop-profile-summary-progress-wrapper">
              <h3 className="desktop-profile-summary-section-title">
                Profile Progress
              </h3>
              <div className="desktop-profile-progress-wrapper">
                <CircularProgressbar
                  value={profileProgress}
                  text={`${profileProgress}%`}
                  className="desktop-profile-progress-circle"
                />
                <div className="desktop-profile-progress-next-step-wrapper">
                  {profileProgress !== 100 && nextStep !== 'Finished' && (
                    <p className="desktop-profile-progress-next-step-text">
                      Next Step:{' '}
                    </p>
                  )}
                  <Link to={stepLink}>
                    <Button
                      size="sm"
                      className="desktop-profile-progress-next-step-button"
                    >
                      {nextStep}
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            {props.user.premium ? (
              <div className="desktop-profile-summary-inventory-wrapper">
                <h3 className="desktop-profile-summary-section-title">
                  Profile Inventory
                </h3>
                <div className="desktop-profile-summary-inventory-innerwrap">
                  <div className="desktop-profile-summary-inventory-section">
                    <div className="desktop-profile-summary-inventory-section-left">
                      <i className="fas fa-star desktop-profile-summary-inventory-superlike-icon"></i>
                      <span className="desktop-profile-summary-inventory-section-title">
                        Superlikes:
                      </span>
                    </div>
                    <div className="desktop-profile-summary-inventory-section-right">
                      <span className="desktop-profile-summary-inventory-counter">
                        {superlikeInventory}
                      </span>
                    </div>
                  </div>
                  <div className="desktop-profile-summary-inventory-section">
                    <div className="desktop-profile-summary-inventory-section-left">
                      <i className="fas fa-bolt desktop-profile-summary-inventory-boost-icon"></i>
                      <span className="desktop-profile-summary-inventory-section-title">
                        Profile Boosts:
                      </span>
                    </div>
                    <div className="desktop-profile-summary-inventory-section-right">
                      <span className="desktop-profile-summary-inventory-counter">
                        {boostInventory}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="desktop-profile-summary-inventory-cta-wrapper">
                  <Button
                    className="desktop-profile-summary-inventory-cta-button"
                    onClick={toggleSuperlikePop}
                  >
                    <i className="fas fa-star desktop-profile-summary-inventory-superlike-icon-cta"></i>
                    <span className="desktop-profile-summary-inventory-cta-button-text">
                      Get Superlikes
                    </span>
                  </Button>
                  <Button
                    className="desktop-profile-summary-inventory-cta-button"
                    onClick={toggleBoostPop}
                  >
                    <i className="fas fa-bolt desktop-profile-summary-inventory-boost-icon-cta"></i>
                    <span className="desktop-profile-summary-inventory-cta-button-text">
                      Get Boosts
                    </span>
                  </Button>
                </div>
              </div>
            ) : (
              <div className="desktop-profile-summary-inventory-wrapper">
                <h3 className="desktop-profile-summary-section-title">
                  Unlock Premium
                </h3>
              </div>
            )}

            <div className="desktop-profile-summary-quicklinks-wrapper">
              <h3 className="desktop-profile-summary-section-title">
                Quick Links
              </h3>
              <ul className="desktop-profile-summary-quicklinks-list">
                <Link
                  to="/app"
                  className="desktop-profile-summary-quicklinks-link"
                >
                  <li className="desktop-profile-summary-quicklinks-list-item">
                    Home
                  </li>
                </Link>
                <Link
                  to="/app/profile/settings"
                  className="desktop-profile-summary-quicklinks-link"
                >
                  <li className="desktop-profile-summary-quicklinks-list-item">
                    Settings
                  </li>
                </Link>
                <Link
                  to="/app/profile/photos"
                  className="desktop-profile-summary-quicklinks-link"
                >
                  <li className="desktop-profile-summary-quicklinks-list-item">
                    Photos
                  </li>
                </Link>
                <Link
                  to="/app/messages"
                  className="desktop-profile-summary-quicklinks-link"
                >
                  <li className="desktop-profile-summary-quicklinks-list-item">
                    Messages
                  </li>
                </Link>
              </ul>
              <div className="desktop-profile-summary-logout-wrapper">
                <Button
                  className="desktop-profile-summary-logout-button"
                  onClick={logoutHandler}
                >
                  <i className="far fa-sign-out-alt desktop-profile-summary-logout-icon"></i>
                  Logout
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="desktop-profile-tab-header">
        <h1 className="desktop-profile-tab-header-title">Basic Information</h1>
      </div>
      <div className="desktop-profile-tab-content-container">
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">Location</h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Label className="desktop-profile-form-label">
                  Choose Your City
                </Form.Label>

                <PlacesAutocomplete
                  value={changingAddress}
                  onChange={addressChange}
                  onSelect={addressSelect}
                  searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="discovery-filters-location-input-wrapper">
                      <Form.Control
                        {...getInputProps({
                          placeholder: address,
                          className: 'search-filters-location-input',
                        })}
                      />

                      <div className="discovery-filters-dropdown-card">
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? 'suggestion-item--active'
                            : 'suggestion-item';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: '#fafafa',
                                cursor: 'pointer',
                              }
                            : {
                                backgroundColor: '#ffffff',
                                cursor: 'pointer',
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper"></div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">
              Preferred Ages
            </h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Label className="desktop-profile-form-label">
                  {age[1] === 80 ? (
                    <span>
                      {age[0]} - {age[1]}+
                    </span>
                  ) : (
                    <span>
                      {age[0]} - {age[1]}
                    </span>
                  )}
                </Form.Label>
                <Slider
                  min={18}
                  max={80}
                  value={age}
                  onChange={ageChange}
                  onChangeCommitted={ageSelect}
                  className="basic-info-slider"
                />
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper">
            <p className="desktop-profile-tab-section-description-text">
              Select your preferred age range for better profile
              recommendations.
            </p>
          </div>
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap">
            <h3 className="desktop-profile-tab-section-title">
              Preferred Distance
            </h3>
          </div>
          <div className="desktop-profile-tab-section-form-wrapper">
            <Form className="desktop-profile-form">
              <Form.Group className="desktop-profile-form-group">
                <Form.Label className="desktop-profile-form-label">
                  {distance === 200 ? (
                    <span>Up to {distance}+ miles away</span>
                  ) : (
                    <span>Up to {distance} miles away</span>
                  )}
                </Form.Label>
                <Slider
                  min={5}
                  max={200}
                  value={distance}
                  onChange={distanceChange}
                  onChangeCommitted={distanceSelect}
                  className="basic-info-slider"
                />
              </Form.Group>
            </Form>
          </div>
          <div className="desktop-profile-tab-section-description-wrapper">
            <p className="desktop-profile-tab-section-description-text">
              Select your preferred distance range for better profile
              recommendations.
            </p>
          </div>
        </div>
      </div>
      {props.user.gender === 'male' && (
        <div className="desktop-profile-tab-header">
          <h1 className="desktop-profile-tab-header-title">
            Financial Information
          </h1>
        </div>
      )}
      {props.user.gender === 'male' && (
        <div className="desktop-profile-tab-content-container">
          <div className="desktop-profile-tab-section">
            <div className="desktop-profile-tab-section-title-wrap">
              <h3 className="desktop-profile-tab-section-title">Net Worth</h3>
            </div>
            <div className="desktop-profile-tab-section-form-wrapper">
              <Form className="desktop-profile-form">
                <Form.Group className="desktop-profile-form-group">
                  <Form.Control
                    as="select"
                    className="desktop-profile-form-input"
                    onChange={networthChange}
                    value={networth}
                  >
                    <option>$100,000</option>
                    <option>$250,000</option>
                    <option>$500,000</option>
                    <option>$750,000</option>
                    <option>$1 million</option>
                    <option>$2 million</option>
                    <option>$5 million</option>
                    <option>$10 million</option>
                    <option>$50 million</option>
                    <option>$100 million</option>
                    <option>More than $100 million</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </div>
            <div className="desktop-profile-tab-section-description-wrapper"></div>
          </div>
          <div className="desktop-profile-tab-section">
            <div className="desktop-profile-tab-section-title-wrap">
              <h3 className="desktop-profile-tab-section-title">
                Annual Income
              </h3>
            </div>
            <div className="desktop-profile-tab-section-form-wrapper">
              <Form className="desktop-profile-form">
                <Form.Group className="desktop-profile-form-group">
                  <Form.Control
                    as="select"
                    className="desktop-profile-form-input"
                    onChange={annaulIncomeChange}
                    value={annualIncome}
                  >
                    <option>Less than $100,000</option>
                    <option>$100,000</option>
                    <option>$125,000</option>
                    <option>$150,000</option>
                    <option>$175,000</option>
                    <option>$200,000</option>
                    <option>$250,000</option>
                    <option>$300,000</option>
                    <option>$400,000</option>
                    <option>$500,000</option>
                    <option>$1 Million</option>
                    <option>More than $1 Million</option>
                  </Form.Control>
                </Form.Group>
              </Form>
            </div>
            <div className="desktop-profile-tab-section-description-wrapper"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(BasicInfoTab);
