import React, { Component } from "react";
import UserTable from "../../admincomponents/userTable/UserTableNew";
import AdminNavbar from "../../admincomponents/adminNavbar/AdminNavbar";
import { UserContext } from "../../../context/UserContext";
import { withRouter } from "react-router-dom";

export class Users extends Component {
  static contextType = UserContext;

  componentWillMount() {
    if (!this.context.user.isAdmin) {
      this.props.history.push("/app");
    }
  }
  render() {
    return (
      <div>
        <AdminNavbar />
        <UserTable />
      </div>
    );
  }
}

export default withRouter(Users);
