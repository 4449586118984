import React from "react";

function Wallet() {
  return (
    <div>
      <span>Wallet</span>
    </div>
  );
}

export default Wallet;
