import React from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Image as NoneImage } from 'react-bootstrap';

import male_user from '../../../../../assets/Images/adminSidebar/male_user.png';
import female_user from '../../../../../assets/Images/adminSidebar/female_user.png';
import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../../../utils/getCloudinaryInfo';
import RiskScore from '../../../userTable/userTableElements/RiskScore';
import Media from '../../../../../components/media/Media';

export const ChatUserInfo = (props) => {
  const { user } = props;
  const { imgurl, username, risk, gender } = user;

  return (
    <div className="chat-user-info">
      {imgurl && imgurl.length !== 0 && imgurl[0] ? (
        <Media image={imgurl[0]} />
      ) : (
        // <Image
        //   publicId={getImgName(imgurl[0])}
        //   version={getImgVersion(imgurl[0])}
        //   resource_type={checkGIF(imgurl[0]) ? "video" : "image"}
        //   effect="loop"
        //   cloud_name="sugarbae"
        //   className="img-userinfo"
        // >
        //   <Transformation
        //     height="200"
        //     video_sampling="20"
        //     delay="250"
        //     duration="10"
        //     effect="loop"
        //   />
        // </Image>
        <div className="none-image-container">
          <NoneImage
            className="none-user-image"
            src={gender === 'male' ? male_user : female_user}
          />
        </div>
      )}
      <div className="userinfo-top">
        <p className="userinfo-username">{username}</p>
        <div className="userinfo-riskscore">
          <p className="userinfo-riskscore-title">Risk Score:</p>
          <RiskScore riskScore={risk ? Math.trunc(risk.score * 100) : 0} />
        </div>
      </div>
    </div>
  );
};

export default ChatUserInfo;
