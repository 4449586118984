import React, { useContext } from "react";
import "../settingsv2styles.scss";
import { UserContext } from "../../../../../context/UserContext";
import { withRouter, useHistory } from "react-router-dom";

function Community(props) {
  let history = useHistory();
  const context = useContext(UserContext);

  const supportHandler = (e) => {
    history.push("/app/support");
  };
  const privacyHandler = (e) => {
    history.push("/app/privacy");
  };

  return (
    <div className="settings-v2-section">
      <h1 className="settings-v2-section-title">Community</h1>
      <div className="settings-v2-section-content">
        <div className="settings-v2-section-row" onClick={supportHandler}>
          <div className="settings-v2-section-row-icon">
            <i className="far fa-question-circle"></i>
          </div>
          <div className="settings-v2-section-row-title-full-width">
            Help & Support
          </div>

          <div className="settings-v2-section-row-action">
            <i className="fas fa-chevron-right settings-v2-right-icon"></i>
          </div>
        </div>
        <div className="settings-v2-section-row" onClick={privacyHandler}>
          <div className="settings-v2-section-row-icon">
            <i className="far fa-lock"></i>
          </div>
          <div className="settings-v2-section-row-title-full-width">
            Privacy Policy
          </div>

          <div className="settings-v2-section-row-action">
            <i className="fas fa-chevron-right settings-v2-right-icon"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Community);
