import React, { Component } from "react";
import "./boostcountdown.scss";
import { UserContext } from "../../context/UserContext";
import { ProgressBar } from "react-bootstrap";
import Countdown, { zeroPad } from "react-countdown";
var moment = require("moment");

export class BoostCountdown extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {}

  render() {
    var boost = this.props.boost;
    var boostExpiryDate = moment(boost.date);
    var height = this.props.height;
    var width = this.props.width;

    var active = boost.active;

    if (moment().isAfter(boostExpiryDate)) {
      active = false;
    }

    var countdownHeight = height * 0.1 * 0.7 + "px";
    var countdownWidth = width * 0.5 - 26 + "px";

    var countdownStyle = {
      top: countdownHeight,
      left: countdownWidth,
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
      if (completed) {
        return null;
      } else {
        return (
          <div className="countdown-innerwrap">
            <i className="fas fa-bolt countdown-boost-icon-left"></i>
            <span>
              {zeroPad(minutes)}:{zeroPad(seconds)}
            </span>
            <i className="fas fa-bolt countdown-boost-icon-right"></i>
          </div>
        );
      }
    };

    if (active) {
      return (
        // <div className="boost-countdown-component" style={countdownStyle}>
        //   <Countdown
        //     date={new Date(boost.date)}
        //     className="boost-countdown"
        //     renderer={renderer}
        //   ></Countdown>
        // </div>

        <div className="boost-wrapper">
          <div className="boost-wrapper-top">
            <ProgressBar animated now={100} className="boost-progress-bar" />
            <span className="boost-wrapper-text">Boost Active!</span>
          </div>
          <div className="boost-wrapper-left"></div>
          <div className="boost-wrapper-right"></div>
          <div className="boost-wrapper-bottom">
            <ProgressBar animated now={100} className="boost-progress-bar" />
            <span className="boost-wrapper-text-bottom">Keep Swiping!</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default BoostCountdown;
