import axios from 'axios';

const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

function getFullUrl(url) {
  return `${baseUrl}/${url}`;
}

function getToken() {
  return localStorage.getItem('auth');
}

export const get = (url, params = {}) => {
  const fullUrl = getFullUrl(url);
  const config = {
    headers: { auth: getToken() },
    params,
    withCredentials: true,
  };
  return axios.get(fullUrl, config);
};

export const post = (url, data) => {
  const fullUrl = getFullUrl(url);
  const payload = {
    auth: getToken(),
    data,
  };
  const config = {
    withCredentials: true,
    headers: { auth: getToken() },
  };
  return axios.post(fullUrl, payload, config);
};
