import React, { useState, useEffect, useContext } from 'react';
import './desktoplikestab.scss';
import Axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import { Image as IconImage, Button, Toast } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import ClipLoader from 'react-spinners/ClipLoader';
import { submitLike, submitDislike } from '../../../../helpers/swipeActions';
import unlockIcon from '../../../../assets/Images/matches/unlock-icon.gif';
import ViewLikes from '../../../modals/viewLikes/ViewLikes';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import Media from '../../../../components/media/Media';

function DesktopLikesTab(props) {
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [matchAlert, setMatchAlert] = useState({ open: false, card: null });
  const [viewLikesModalOpen, setViewLikesModalOpen] = useState(false);
  const [currentLikes, setCurrentLikes] = useState([]);

  const context = useContext(UserContext);

  useEffect(() => {
    context.updateLikesAsRead();
    setFetching(false);
  }, [context.likes]);

  const handleScroll = (e) => {
    if (!fetching && !context.maxLikes) {
      setFetching(true);
      context.checkMoreLikes();
    }
  };

  const handleLike = (card) => async (e) => {
    var likesState = context.likes;

    const index = likesState.findIndex(
      (like) => like.user._id.toString() === card._id.toString()
    );

    if (index > -1) {
      likesState.splice(index, 1);
      context.updateLikes(likesState);
    }

    const like = await submitLike(context.user._id, card._id).catch((err) => {
      console.log(err);
    });

    if (like.match) {
      context.updateMatches();
      setMatchAlert({
        open: true,
        card: card,
      });
    }
  };

  const handleDislike = (card) => async (e) => {
    var userid = context.user._id;
    var likesState = context.likes;

    const index = likesState.findIndex(
      (like) => like.user._id.toString() === card._id.toString()
    );

    if (index > -1) {
      likesState.splice(index, 1);
      context.updateLikes(likesState);
      setCurrentLikes(likesState);
    }

    const dislike = await submitDislike(userid, card._id).catch((err) => {
      console.log(err);
    });
  };

  const toggleMatchAlert = (e) => {
    setMatchAlert({
      open: false,
      card: null,
    });
  };

  const viewLikesToggle = (e) => {
    if (viewLikesModalOpen) {
      setViewLikesModalOpen(false);
    } else {
      setViewLikesModalOpen(true);
    }
  };

  useBottomScrollListener(handleScroll);

  if (loading) {
    return (
      <div className="desktop-likes-tab-loading-wrapper">
        <ClipLoader size={35} />
      </div>
    );
  } else {
    var matchMessageUrl = '';
    if (matchAlert.open && matchAlert.card) {
      matchMessageUrl = '/app/messages/' + matchAlert.card.profileurl;
    }
    return (
      <div className="desktop-likes-tab">
        {viewLikesModalOpen && <ViewLikes closeHandler={viewLikesToggle} />}
        {matchAlert.open && (
          <Toast
            show={matchAlert.open}
            onClose={toggleMatchAlert}
            autohide
            delay={3000}
            className="desktop-likes-match-toast"
          >
            <Toast.Header className="desktop-likes-match-toast-header">
              <div className="desktop-likes-match-toast-header-content">
                <div className="desktop-likes-match-toast-header-title-wrapper">
                  <h4 className="desktop-likes-match-toast-title">
                    You matched with {matchAlert.card.username}
                  </h4>
                </div>
              </div>
            </Toast.Header>
            <Toast.Body className="desktop-likes-match-toast-body">
              <Media image={matchAlert.card.imgurl[0]} />
              {/* <Image
                publicId={getImgName(matchAlert.card.imgurl[0])}
                version={getImgVersion(matchAlert.card.imgurl[0])}
                resource_type={
                  checkGIF(matchAlert.card.imgurl[0]) ? 'video' : 'image'
                }
                effect="loop"
                cloud_name="sugarbae"
                className="desktop-likes-match-toast-image"
              >
                <Transformation
                  height="200"
                  video_sampling="20"
                  delay="250"
                  duration="10"
                  effect="loop"
                  quality="auto"
                  flags="lossy"
                />
              </Image> */}
              <div className="desktop-likes-match-toast-body-content">
                <p className="desktop-likes-match-toast-subtitle">
                  Get the conversation started
                </p>
                <Link
                  to={matchMessageUrl}
                  className="desktop-likes-match-toast-link"
                >
                  <Button className="desktop-likes-match-toast-button">
                    Send a Message
                  </Button>
                </Link>
              </div>
            </Toast.Body>
          </Toast>
        )}

        {context.likes.length > 0 ? (
          <div className="desktop-likes-content-container">
            {context.likes.map((like, index) => {
              var images = like.user.imgurl;
              var verifications = like.user.verifications;
              var mainImage = null;
              if (images.length > 0) {
                mainImage = images[0];
              }
              var verified = false;
              if (verifications.phone.status && verifications.email.status) {
                verified = true;
              }

              var imageCount = images.length;
              var profileUrl = '/app/member/' + like.user.profileurl;

              if (context.user.premium) {
                return (
                  <div className="desktop-likes-card">
                    <Link to={profileUrl} className="desktop-likes-card-link">
                      <div className="desktop-likes-card-body">
                        <div className="desktop-likes-card-user-info-wrapper">
                          <div className="desktop-user-card-info-section">
                            <div className="desktop-likes-card-username-wrap">
                              <h4 className="desktop-likes-card-username">
                                {like.user.username}
                              </h4>
                              {verified && (
                                <i className="fad fa-badge-check desktop-likes-card-verified-icon"></i>
                              )}
                            </div>
                          </div>
                          <div className="desktop-likes-card-info-section">
                            <div className="desktop-likes-card-age-location-wrap">
                              <span className="desktop-likes-card-age-location">
                                {like.user.age} • {like.user.address}
                              </span>
                            </div>
                            <div className="desktop-likes-card-image-count-wrap">
                              <i className="fas fa-camera desktop-likes-card-camera-icon"></i>
                              <span className="desktop-likes-card-image-count">
                                {imageCount}
                              </span>
                            </div>
                          </div>
                        </div>
                        {mainImage ? (
                          <Media image={mainImage} />
                        ) : (
                          // <Image
                          //   publicId={getImgName(mainImage)}
                          //   getImgVersion={getImgVersion(mainImage)}
                          //   resource_type={
                          //     checkGIF(mainImage) ? 'video' : 'image'
                          //   }
                          //   effect="loop"
                          //   cloud_name="sugarbae"
                          //   className="desktop-likes-card-image"
                          // >
                          //   <Transformation
                          //     height="200"
                          //     video_sampling="20"
                          //     delay="250"
                          //     duration="10"
                          //     effect="loop"
                          //     quality="auto"
                          //     flags="lossy"
                          //   />
                          // </Image>
                          <div className="desktop-likes-card-image-placeholder">
                            <i className="far fa-user desktop-likes-card-placeholder-icon"></i>
                          </div>
                        )}
                      </div>
                    </Link>
                    <div className="desktop-likes-card-footer">
                      <div className="desktop-likes-card-actions-wrapper">
                        <Button
                          className="desktop-likes-card-action-button"
                          onClick={handleDislike(like.user)}
                        >
                          <i className="fas fa-times desktop-likes-card-icon-dislike"></i>
                        </Button>
                        <Button
                          className="desktop-likes-card-action-button"
                          onClick={handleLike(like.user)}
                        >
                          <i className="fas fa-heart desktop-likes-card-icon-like"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="desktop-likes-card" onClick={viewLikesToggle}>
                    <div className="desktop-likes-card-unlock-wrapper">
                      <div className="desktop-likes-card-unlock-innerwrap">
                        <IconImage
                          src={unlockIcon}
                          className="desktop-likes-card-unlock-icon"
                        />
                        <h3 className="desktop-likes-card-unlock-title">
                          Unlock
                        </h3>
                      </div>
                    </div>
                    <div className="desktop-likes-card-user-info-wrapper">
                      <div className="desktop-likes-card-info-section">
                        <div className="desktop-likes-card-age-location-wrap">
                          <span className="desktop-likes-card-age-location">
                            {like.user.age} • {like.user.address}
                          </span>
                        </div>
                        <div className="desktop-likes-card-image-count-wrap">
                          <i className="fas fa-camera desktop-likes-card-camera-icon"></i>
                          <span className="desktop-likes-card-image-count">
                            {imageCount}
                          </span>
                        </div>
                      </div>
                    </div>
                    {mainImage ? (
                      <Media image={mainImage} />
                    ) : (
                      // <Image
                      //   publicId={getImgName(mainImage)}
                      //   version={getImgVersion(mainImage)}
                      //   resource_type={checkGIF(mainImage) ? 'video' : 'image'}
                      //   effect="loop"
                      //   cloud_name="sugarbae"
                      //   className="desktop-likes-card-image-blur"
                      // >
                      //   <Transformation
                      //     height="200"
                      //     video_sampling="20"
                      //     delay="250"
                      //     duration="10"
                      //     effect="loop"
                      //     quality="auto"
                      //     flags="lossy"
                      //   />
                      // </Image>
                      <div className="desktop-likes-card-image-placeholder-blur">
                        <i className="far fa-user desktop-likes-card-placeholder-icon"></i>
                      </div>
                    )}
                  </div>
                );
              }
            })}
            {fetching && (
              <div className="messages-bottom-indicator">
                <ClipLoader size={35} />
              </div>
            )}
            {context.maxLikes && (
              <div className="messages-bottom-indicator">
                <div className="end-of-messages-innerwrap">
                  <h4 className="end-of-messages-title">No more likes!</h4>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="desktop-no-likes-container">
            <div className="desktop-no-likes-innerwrap">
              <i className="fas fa-empty-set desktop-no-likes-icon"></i>
              <h2 className="desktop-no-likes-title">You have no likes!</h2>
              <p className="desktop-no-likes-subtitle">
                Keep liking users to receive more likes and matches.
              </p>
              <Link to="/app/search">
                <Button className="desktop-no-likes-button" size="lg">
                  <i className="far fa-search no-likes-search-icon"></i>
                  Search
                </Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(DesktopLikesTab);
