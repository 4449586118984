import Axios from 'axios';

export const uploadMedia = async (file, userid, tab) => {
  let fd = new FormData();
  fd.append('file', file);

  const baseUrl = `${process.env.REACT_APP_API_URL}/api`;

  let fullUrl = baseUrl + '/profile/uploadMedia';

  if (tab === 'private') {
    fullUrl = baseUrl + '/profile/uploadPrivateMedia';
  }

  let token = localStorage.getItem('auth');

  let headers = {
    Authorization: `token ${token}`,
    tab: tab,
  };

  let images = [];

  await Axios.post(fullUrl, fd, {
    headers: {
      Authorization: token,
    },
  })
    .then((res) => {
      images = res.data.images;
    })
    .catch((err) => console.log(err));

  return { images };
};
