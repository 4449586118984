import React, { useContext } from "react";
import "../settingsv2styles.scss";
import { Button } from "react-bootstrap";
import { UserContext } from "../../../../../context/UserContext";

function AccountSettings(props) {
  const context = useContext(UserContext);
  const { identity } = context.user;

  const toggleTab = (name) => (e) => {
    props.toggleTab(name);
  };

  let emailVerified = false;
  let phoneVerified = false;
  let identityVerified = false;
  let noIdentityInfo = true;
  const identityText = "";
  let fullName = "";

  if (identity.verified) {
    identityVerified = true;
  }

  if (identity.firstName || identity.lastName || identity.middleName) {
    noIdentityInfo = false;

    if (identity.firstName) {
      if (identity.firstName.length > 0) {
        fullName = `${identity.firstName} `;
      }
    }
    if (identity.middleName) {
      if (identity.middleName.length > 0) {
        fullName = `${fullName + identity.middleName} `;
      }
    }
    if (identity.lastName) {
      if (identity.lastName.length > 0) {
        fullName += identity.lastName;
      }
    }
  }

  if (context.user.verifications.email.status) {
    emailVerified = true;
  }

  if (context.user.verifications.phone.status) {
    phoneVerified = true;
  }

  let noPhoneNumber = true;
  let noEmail = true;

  if (context.user.email) {
    noEmail = false;
  }
  if (
    context.user.verifications.phone.number !== null &&
    context.user.verifications.phone.number !== undefined
  ) {
    if (context.user.verifications.phone.number.length > 5) {
      noPhoneNumber = false;
    }
  }

  return (
    <div className="settings-v2-section">
      <h1 className="settings-v2-section-title">Account Settings</h1>
      <div className="settings-v2-section-content">
        <div className="settings-v2-section-row" onClick={toggleTab("phone")}>
          <div className="settings-v2-section-row-icon">
            <i className="far fa-mobile" />
          </div>
          <div className="settings-v2-section-row-title">Phone Number</div>
          {noPhoneNumber ? (
            <div className="settings-v2-section-row-content">
              <Button className="settings-v2-connect-button">Required</Button>
            </div>
          ) : (
            <div className="settings-v2-section-row-content">
              {context.user.verifications.phone.number}
            </div>
          )}
          <div className="settings-v2-section-row-action">
            {phoneVerified ? (
              <i className="far fa-check-circle  settings-v2-verified-icon" />
            ) : (
              <i className="far fa-exclamation-circle settings-v2-attention-icon" />
            )}
          </div>
        </div>
        <div className="settings-v2-section-row" onClick={toggleTab("email")}>
          <div className="settings-v2-section-row-icon">
            <i className="far fa-envelope" />
          </div>
          <div className="settings-v2-section-row-title">Email</div>
          {noEmail ? (
            <div className="settings-v2-section-row-content">
              <Button className="settings-v2-connect-button">Required</Button>
            </div>
          ) : (
            <div className="settings-v2-section-row-content">
              {context.user.email}
            </div>
          )}

          <div className="settings-v2-section-row-action">
            {emailVerified ? (
              <i className="far fa-check-circle  settings-v2-verified-icon" />
            ) : (
              <i className="far fa-exclamation-circle settings-v2-attention-icon" />
            )}
          </div>
        </div>
        {/* <div
          className="settings-v2-section-row"
          onClick={toggleTab("identity")}
        >
          <div className="settings-v2-section-row-icon">
            <i className="far fa-id-card" />
          </div>
          <div className="settings-v2-section-row-title">Identity</div>
          {noIdentityInfo ? (
            <div className="settings-v2-section-row-content">
              <Button className="settings-v2-connect-button">Required</Button>
            </div>
          ) : (
            <div className="settings-v2-section-row-content">{fullName}</div>
          )}

          <div className="settings-v2-section-row-action">
            <i className="far fa-exclamation-circle settings-v2-attention-icon" />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default AccountSettings;
