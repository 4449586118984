import React, { useState, useEffect, useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Table,
  Card,
  Button,
  Modal,
  Form,
  Alert,
  Badge,
  Image as NoneImage,
} from 'react-bootstrap';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Image, Transformation } from 'cloudinary-react';

import Axios from 'axios';
import Select from 'react-dropdown-select';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import Media from '../../../components/media/Media';

import RiskScore from './userTableElements/RiskScore';
import ChatHistory from './userTableElements/ChatHistory';
import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../utils/getCloudinaryInfo';
import { api } from '../../../api';
import male_user from '../../../assets/Images/adminSidebar/male_user.png';
import female_user from '../../../assets/Images/adminSidebar/female_user.png';

import '../admincomponents.scss';

export const UserTable = (props) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    sortby: 'Newest',
    bots: false,
    nonbots: true,
    male: true,
    female: true,
    username: '',
    email: '',
    subscriptionid: '',
    address: '',
    banned: false,
    nonBanned: true,
    premium: false,
    nonPremium: false,
    emailVerified: false,
    phoneVerified: false,
    hasImages: false,
  });
  const [skip, setSkip] = useState(0);
  const [showFilters, setShowFilters] = useState(false);
  const [address, setAddress] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showUserBio, setShowUserBio] = useState(false);
  const [showSubscription, setShowSubscription] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [editHeadlineContent, setEditHeadlineContent] = useState(false);
  const [editBioContent, setEditBioContent] = useState(false);
  const [page, setPage] = useState('1');
  const [pages, setPages] = useState([
    {
      value: '1',
      label: '1',
    },
    {
      value: '2',
      label: '2',
    },
    {
      value: '3',
      label: '3',
    },
  ]);
  const [count, setCount] = useState(0);
  const [action, setAction] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [showSearchOptions, setShowSearchOptions] = useState(false);
  const [searchOption, setSearchOption] = useState('username');
  const [searchPlaceholder, setSearchPlaceholder] =
    useState('Search username...');
  const [showChatHistory, setShowChatHistory] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const options = [
    {
      value: 'first',
      label: 'Newest',
    },
    {
      value: 'second',
      label: 'Recent active',
    },
  ];
  const searchOptions = {
    types: ['(cities)'],
  };

  useEffect(() => {
    fetchData();
  }, [skip]);

  const fetchData = async () => {
    setLoading(true);
    const token = localStorage.getItem('auth');
    const baseurl = process.env.REACT_APP_API_URL;
    const url = `${baseurl}/api/admin/users`;
    const config = {
      headers: { auth: token },
      filters,
      skip,
    };

    return await Axios.post(url, config)
      .then((res) => {
        setUsers(res.data.users);
        setCount(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log('Error Fetching Users');
      });
  };

  const refresh = () => {
    setShowFilters(false);
    setPage('1');
    setSkip(0);
    fetchData();
  };

  const prevPage = () => {
    if (skip !== 0) {
      setSkip(skip - 12);
      setPage(String(Number(page) - 1));
    }
  };

  const nextPage = () => {
    if (users.length > 11) {
      setSkip(skip + 12);
      setPage(String(Number(page) + 1));
    }
  };

  const onPageChange = (value) => {
    if (value) {
      const newPage = Number(value[0].value);
      const tempSkip = (newPage - 1) * 12;
      setPage(String(newPage));
      setSkip(tempSkip);
    }
  };

  const checkFilters = (show) => {
    setShowFilters(show);
  };

  const handleAddressChange = (address) => {
    setAddress(address);
  };

  const handleAddressSelect = (address) => {
    setAddress(address);
    setFilters({
      ...filters,
      address,
    });
  };

  const selectUser = (index) => {
    setSelectedUser(index + 1);
    if (!showChatHistory) {
      setShowProfile(true);
    }
    setShowFilters(false);
    setShowAccountInfo(false);
    setShowUserBio(false);
    setShowUserInfo(false);
    setShowSubscription(false);
    setShowImages(true);
    setEditBioContent(false);
    setEditHeadlineContent(false);
  };

  const enableShowAccountInfo = () => {
    if (showAccountInfo) {
      setShowAccountInfo(false);
    } else {
      setShowAccountInfo(true);
    }
    setShowUserBio(false);
    setShowUserInfo(false);
    setShowSubscription(false);
    setShowImages(false);
    setEditBioContent(false);
    setEditHeadlineContent(false);
  };

  const enableShowUserInfo = () => {
    setShowAccountInfo(false);
    setShowUserBio(false);
    if (showUserInfo) {
      setShowUserInfo(false);
    } else {
      setShowUserInfo(true);
    }
    setShowSubscription(false);
    setShowImages(false);
    setEditBioContent(false);
    setEditHeadlineContent(false);
  };

  const enableShowUserBio = () => {
    setShowAccountInfo(false);
    if (showUserBio) {
      setShowUserBio(false);
    } else {
      setShowUserBio(true);
    }
    setShowUserInfo(false);
    setShowSubscription(false);
    setShowImages(false);
    setEditBioContent(false);
    setEditHeadlineContent(false);
  };

  const enableShowSubscription = () => {
    setShowAccountInfo(false);
    setShowUserBio(false);
    setShowUserInfo(false);
    if (showSubscription) {
      setShowSubscription(false);
    } else {
      setShowSubscription(true);
    }
    setShowImages(false);
    setEditBioContent(false);
    setEditHeadlineContent(false);
  };

  const enableShowImages = () => {
    setShowAccountInfo(false);
    setShowUserBio(false);
    setShowUserInfo(false);
    setShowSubscription(false);
    if (showImages) {
      setShowImages(false);
    } else {
      setShowImages(true);
    }
    setEditBioContent(false);
    setEditHeadlineContent(false);
  };

  const editHeadlineHandler = () => {
    setEditHeadlineContent(true);
  };

  const editBioHandler = () => {
    setEditBioContent(true);
  };

  const onChangeHandler = (e) => {
    e.preventDefault();
    if (searchOption === 'username') {
      setFilters({
        ...filters,
        username: e.target.value,
      });
    } else if (searchOption === 'email') {
      setFilters({
        ...filters,
        email: e.target.value,
      });
    } else if (searchOption === 'subscriptionid') {
      setFilters({
        ...filters,
        subscriptionid: e.target.value,
      });
    }
  };

  const maleChangeHandler = (e) => {
    setFilters({
      ...filters,
      male: e.target.checked,
    });
  };

  const femaleChangeHandler = (e) => {
    setFilters({
      ...filters,
      female: e.target.checked,
    });
  };

  const emailVerifiedChangeHandler = (e) => {
    setFilters({
      ...filters,
      emailVerified: e.target.checked,
    });
  };

  const phoneVerifiedChangeHandler = (e) => {
    setFilters({
      ...filters,
      phoneVerified: e.target.checked,
    });
  };

  const premiumChangeHandler = (e) => {
    setFilters({
      ...filters,
      premium: e.target.checked,
    });
  };

  const nonPremiumChangeHandler = (e) => {
    setFilters({
      ...filters,
      nonPremium: e.target.checked,
    });
  };

  const bannedChangeHandler = (e) => {
    setFilters({
      ...filters,
      banned: e.target.checked,
    });
  };

  const nonBannedChangeHandler = (e) => {
    setFilters({
      ...filters,
      nonBanned: e.target.checked,
    });
  };

  const imagesChangeHandler = (e) => {
    setFilters({
      ...filters,
      hasImages: e.target.checked,
    });
  };

  const resetFilter = () => {
    setAddress('');
    setFilters({
      sortby: 'Newest',
      bots: false,
      nonbots: true,
      male: true,
      female: true,
      username: '',
      email: '',
      subscriptionid: '',
      address: '',
      banned: false,
      nonBanned: false,
      premium: false,
      nonPremium: false,
      emailVerified: false,
      phoneVerified: false,
      hasImages: false,
    });
  };

  const viewProfileHandler = () => {
    const profileUrl = `/app/member/${users[selectedUser - 1].profileurl}`;
    const win = window.open(profileUrl, '_blank');
    if (win != null) {
      win.focus();
    }
  };

  const sendLikesHandler = (e) => {
    const userid = users[selectedUser - 1]._id;
    console.log(userid);
    const data = {
      userid,
    };

    return api.sendLikes(data).catch((err) => {
      console.log(err);
    });
  };

  const onSortbyChange = (values) => {
    if (values) {
      const sortbyOption =
        values[0].value === 'first' ? 'Newest' : 'Recently Active';
      setFilters({
        ...filters,
        sortby: sortbyOption,
      });
    }
  };

  const purchasePeriod = (user) => {
    const { createdAt } = user;
    const { startDate } = user.subscription;

    if (!startDate) {
      return null;
    }
    const difference = moment(
      moment.duration(moment(startDate).diff(moment(createdAt)) + 25200000)
    );
    const { days, hours, minutes } = difference._i._data;
    if (days !== 0) {
      return `${days} days`;
    }
    if (hours !== 0) {
      return `${hours} hours`;
    }
    if (minutes !== 0) {
      return `${minutes} mins`;
    }
    return null;
  };

  const approveHandler = (option) => {
    const token = localStorage.getItem('auth');
    const url = `${process.env.REACT_APP_API_URL}/api/admin/biostatus`;

    let config = {};
    if (option === 'headline') {
      config = {
        headers: { auth: token },
        user: users[selectedUser - 1],
        action: 'approveheadline',
      };
    } else {
      config = {
        headers: { auth: token },
        user: users[selectedUser - 1],
        action: 'approvebio',
      };
    }

    return Axios.post(url, config)
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log('Error Submitting Action');
      });
  };

  const rejectHandler = (option) => {
    const token = localStorage.getItem('auth');
    const url = `${process.env.REACT_APP_API_URL}/api/admin/biostatus`;

    let config = {};
    if (option === 'headline') {
      config = {
        headers: { auth: token },
        user: users[selectedUser - 1],
        action: 'rejectheadline',
      };
    } else {
      config = {
        headers: { auth: token },
        user: users[selectedUser - 1],
        action: 'rejectbio',
      };
    }

    return Axios.post(url, config)
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log('Error Submitting Action');
      });
  };

  const actionToggleHandler = (values) => {
    const { action } = values;
    if (action === 'close') {
      setAction(null);
      setConfirmModalOpen(false);
    } else {
      setAction(action);
      setConfirmModalOpen(true);
      if (action === 'removeimage') {
        setImage(values.image);
      }
    }
  };

  const searchOptionShow = () => {
    setShowSearchOptions(!showSearchOptions);
  };

  const toggleSearchOption = (option) => {
    setSearchOption(option);
    if (option === 'username') {
      setSearchPlaceholder('Search username...');
    } else if (option === 'email') {
      setSearchPlaceholder('Search email...');
    } else if (option === 'subscriptionid') {
      setSearchPlaceholder('Search subscriptionID...');
    }
    setFilters({
      ...filters,
      username: '',
      email: '',
      subscriptionid: '',
    });
    setShowSearchOptions(false);
  };

  const submitHandler = async () => {
    const token = localStorage.getItem('auth');
    const baseurl = process.env.REACT_APP_API_URL;
    let url = null;
    let config = null;

    if (action === 'banuser') {
      let data = {
        user: users[selectedUser - 1],
        banned: true,
      };
      api
        .adminBanned(data)
        .then((res) => {
          setAlertOpen(true);
        })
        .catch((err) => console.log(err));
    } else if (action === 'unbanuser') {
      let data = {
        user: users[selectedUser - 1],
        banned: false,
      };
      api
        .adminBanned(data)
        .then((res) => {
          setAlertOpen(true);
        })
        .catch((err) => console.log(err));
    } else if (action === 'cancelpremium') {
      let data = {
        user: users[selectedUser - 1],
      };
      api
        .cancelSubscription(data)
        .then((res) => {
          setAlertOpen(true);
        })
        .catch((err) => console.log(err));
    } else if (action === 'deactivate') {
      let data = {
        user: users[selectedUser - 1],
      };
      api
        .deactivateAccount(data)
        .then((res) => {
          setAlertOpen(true);
        })
        .catch((err) => console.log(err));
    } else if (action === 'reactivate') {
      let data = {
        user: users[selectedUser - 1],
      };
      api
        .reactivateAccount(data)
        .then((res) => {
          setAlertOpen(true);
        })
        .catch((err) => console.log(err));
    } else if (action === 'makepremium') {
      let data = {
        user: users[selectedUser - 1],
      };
      api
        .makePremium(data)
        .then((res) => {
          setAlertOpen(true);
        })
        .catch((err) => console.log(err));
    } else if (action === 'removeimage') {
      let data = {
        user: users[selectedUser - 1],
        image,
      };

      api
        .removeImage(data)
        .then((res) => {
          setAlertOpen(true);
        })
        .catch((err) => console.log(err));
    }
  };

  const keyHandler = (e) => {
    if (e.key === 'Enter') {
      fetchData();
    }
  };

  const toggleChatHistory = () => {
    setShowProfile(false);
    setShowChatHistory(true);
  };

  const closeProfile = () => {
    setShowProfile(false);
    setSelectedUser(null);
  };

  const closeChatHistory = () => {
    setSelectedUser(null);
    setShowChatHistory(false);
  };

  useEffect(() => {
    const pageCount = Math.ceil(count / 12);
    const tempPages = [];
    for (let i = 1; i < pageCount + 1; i++) {
      tempPages.push({
        value: String(i),
        label: String(i),
      });
    }

    setPages(tempPages);
  }, [count]);

  useEffect(() => {
    fetchData();
  }, [filters.sortby]);

  useEffect(() => {
    if (alertOpen) {
      setTimeout(() => {
        setAlertOpen(false);
        setConfirmModalOpen(false);
        setImage(null);
        fetchData();
      }, 2000);
    }
  }, [alertOpen]);

  return (
    <Card bg="dark" text="white" className="usertable-card">
      {selectedUser && confirmModalOpen ? (
        <Modal
          show={confirmModalOpen}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirm Action: {action}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {alertOpen ? (
              <h4>Action has done successfully.</h4>
            ) : (
              <h4>User: {users[selectedUser - 1].username}</h4>
            )}
          </Modal.Body>
          {!alertOpen && (
            <Modal.Footer className="modal-footer-innerwrap">
              <Button
                variant="secondary"
                onClick={() => actionToggleHandler({ action: 'close' })}
              >
                Close
              </Button>
              {action === 'banuser' ? (
                <Button onClick={() => submitHandler()} variant="danger">
                  Ban User
                </Button>
              ) : null}
              {action === 'unbanuser' ? (
                <Button onClick={() => submitHandler()} variant="success">
                  Unban User
                </Button>
              ) : null}
              {action === 'cancelpremium' ? (
                <Button onClick={() => submitHandler()} variant="primary">
                  Cancel Subscription
                </Button>
              ) : null}
              {action === 'deactivate' ? (
                <Button onClick={() => submitHandler()} variant="primary">
                  Deactivate User
                </Button>
              ) : null}
              {action === 'reactivate' ? (
                <Button onClick={() => submitHandler()} variant="primary">
                  Reactivate User
                </Button>
              ) : null}
              {action === 'makepremium' ? (
                <Button onClick={() => submitHandler()} variant="primary">
                  Make Premium
                </Button>
              ) : null}
              {action === 'approvebio' ? (
                <Button onClick={() => submitHandler()} variant="success">
                  Approve Bio
                </Button>
              ) : null}
              {action === 'rejectbio' ? (
                <Button onClick={() => submitHandler()} variant="danger">
                  Reject Bio
                </Button>
              ) : null}
              {action === 'approveheadline' ? (
                <Button onClick={() => submitHandler()} variant="success">
                  Approve Headline
                </Button>
              ) : null}
              {action === 'rejectheadline' ? (
                <Button onClick={() => submitHandler()} variant="danger">
                  Reject Headline
                </Button>
              ) : null}
              {action === 'removeimage' ? (
                <Button onClick={() => submitHandler()} variant="danger">
                  Remove Image
                </Button>
              ) : null}
            </Modal.Footer>
          )}
        </Modal>
      ) : null}

      <Card.Header>
        <div className="card-header-wrap">
          <div className="card-header-leftside" id="card-header-table">
            <span className="icon icon-moderate-users" />
            <h4 className="card-header-title">Moderate Users</h4>
          </div>
          <div className="loading-spinner">
            <ClipLoader size={10} color="#FFF" loading={loading} />
          </div>
          <div
            className="input-icons"
            style={showFilters ? { opacity: '20%' } : null}
          >
            <div onClick={() => searchOptionShow()}>
              {showSearchOptions ? (
                <i className="fas fa-angle-up inner-icon" />
              ) : (
                <i className="fas fa-angle-down inner-icon" />
              )}
            </div>
            <input
              className="input-field"
              type="text"
              minLength="5"
              value={
                searchOption === 'username'
                  ? filters.username
                  : searchOption === 'email'
                  ? filters.email
                  : filters.subscriptionid
              }
              placeholder={searchPlaceholder}
              onChange={onChangeHandler}
              onKeyDown={keyHandler}
            />
          </div>
          <div
            style={showFilters ? { opacity: '20%' } : null}
            className="select-sortby"
          >
            <Select
              searchable={false}
              values={[options.find((opt) => opt.value === 'first')]}
              options={options}
              onChange={(values) => onSortbyChange(values)}
              className="usertable-sortby"
            />
          </div>
          {showSearchOptions && (
            <div className="search-option-dropdown">
              <div
                className="search-option"
                onClick={() => toggleSearchOption('username')}
                style={
                  searchOption === 'username' ? { background: '#4F545C' } : null
                }
              >
                Username
              </div>
              <div
                className="search-option"
                onClick={() => toggleSearchOption('email')}
                style={
                  searchOption === 'email' ? { background: '#4F545C' } : null
                }
              >
                Email
              </div>
              <div
                className="search-option"
                onClick={() => toggleSearchOption('subscriptionid')}
                style={
                  searchOption === 'subscriptionid'
                    ? { background: '#4F545C' }
                    : null
                }
              >
                Subscription ID
              </div>
            </div>
          )}
          {!showFilters ? (
            <div
              className="usertable-filter-container"
              onClick={() => checkFilters(true)}
            >
              <span className="icon icon-filter" />
            </div>
          ) : (
            <div
              className="usertable-close-filter-container"
              onClick={() => checkFilters(false)}
            >
              <span className="icon icon-close" />
            </div>
          )}
          {showFilters && (
            <div className="usertable-filter-panel">
              <div className="filter-panel-location">
                <Form.Group>
                  <Form.Label className="location-form-label">
                    Location:
                  </Form.Label>

                  <PlacesAutocomplete
                    value={address}
                    onChange={handleAddressChange}
                    onSelect={handleAddressSelect}
                    searchOptions={searchOptions}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="sf-location-input-wrapper">
                        <Form.Control
                          size="lg"
                          // isInvalid={this.state.addressInvalid}
                          {...getInputProps({
                            placeholder: 'Choose city',
                            className: 'filter-panel-location',
                          })}
                        />
                        <Form.Control.Feedback type="invalid">
                          Invalid location. Please select your city from the
                          dropdown menu.
                        </Form.Control.Feedback>

                        <div className="sf-dropdown-card">
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? 'location-suggestion-item--active'
                              : 'location-suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  height: '30px',
                                  backgroundColor: '#6F737A',
                                  cursor: 'pointer',
                                }
                              : {
                                  height: '30px',
                                  backgroundColor: '#202225',
                                  cursor: 'pointer',
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Form.Group>
              </div>
              <hr className="filter-panel-divider" />
              <div className="filter-panel-options">
                {/* <div className="filter-one-option">
                  <span className="icon icon-user-type" />
                  {"  User type:"}
                  <br />
                  <div className="filter">
                    <input
                      type="checkbox"
                      className="filter-checkbox"
                      checked={filters.bots}
                      onChange={botsChangeHandler}
                    />
                    <label className="filter-checkbox-label">Bots</label>
                    <br />
                    <input
                      type="checkbox"
                      className="filter-checkbox"
                      checked={filters.nonbots}
                      onChange={realUserChangeHandler}
                    />
                    <label className="filter-checkbox-label">Real Users</label>
                    <br />
                  </div>
                </div> */}
                <div className="filter-one-option">
                  <span className="icon icon-genders" />
                  {'  Gender:'}
                  <br />
                  <div className="filter">
                    <input
                      type="checkbox"
                      className="filter-checkbox"
                      checked={filters.male}
                      onChange={maleChangeHandler}
                    />
                    <label className="filter-checkbox-label">Male</label>
                    <br />
                    <input
                      type="checkbox"
                      className="filter-checkbox"
                      checked={filters.female}
                      onChange={femaleChangeHandler}
                    />
                    <label className="filter-checkbox-label">Female</label>
                    <br />
                  </div>
                </div>
                <div className="filter-one-option">
                  <span className="icon icon-premium-filter" />
                  {'  Premium:'}
                  <br />
                  <div className="filter">
                    <input
                      type="checkbox"
                      className="filter-checkbox"
                      disabled={filters.nonPremium}
                      checked={filters.premium}
                      onChange={premiumChangeHandler}
                    />
                    <label
                      className={
                        filters.nonPremium
                          ? 'filter-checkbox-label-disabled'
                          : 'filter-checkbox-label'
                      }
                    >
                      True
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      className="filter-checkbox"
                      disabled={filters.premium}
                      checked={filters.nonPremium}
                      onChange={nonPremiumChangeHandler}
                    />
                    <label
                      className={
                        filters.premium
                          ? 'filter-checkbox-label-disabled'
                          : 'filter-checkbox-label'
                      }
                    >
                      False
                    </label>
                    <br />
                  </div>
                </div>
                <div className="filter-one-option">
                  <span className="icon icon-verification" />
                  {'  Verifications:'}
                  <br />
                  <div className="filter">
                    <input
                      type="checkbox"
                      className="filter-checkbox"
                      checked={filters.emailVerified}
                      onChange={emailVerifiedChangeHandler}
                    />
                    <label className="filter-checkbox-label">
                      Email Verified
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      className="filter-checkbox"
                      checked={filters.phoneVerified}
                      onChange={phoneVerifiedChangeHandler}
                    />
                    <label className="filter-checkbox-label">
                      Phone Verified
                    </label>
                    <br />
                  </div>
                </div>
                <div className="filter-one-option">
                  <span className="icon icon-user-banned" />
                  {'  Banned:'}
                  <br />
                  <div className="filter">
                    <input
                      type="checkbox"
                      className="filter-checkbox"
                      disabled={filters.nonBanned}
                      checked={filters.banned}
                      onChange={bannedChangeHandler}
                    />
                    <label
                      className={
                        filters.nonBanned
                          ? 'filter-checkbox-label-disabled'
                          : 'filter-checkbox-label'
                      }
                    >
                      Banned
                    </label>
                    <br />
                    <input
                      type="checkbox"
                      className="filter-checkbox"
                      disabled={filters.banned}
                      checked={filters.nonBanned}
                      onChange={nonBannedChangeHandler}
                    />
                    <label
                      className={
                        filters.banned
                          ? 'filter-checkbox-label-disabled'
                          : 'filter-checkbox-label'
                      }
                    >
                      Not Banned
                    </label>
                    <br />
                  </div>
                </div>
                <div className="filter-one-option">
                  <span className="icon icon-has-images" />
                  {'  Images:'}
                  <br />
                  <div className="filter">
                    <input
                      type="checkbox"
                      className="filter-checkbox"
                      checked={filters.hasImages}
                      onChange={imagesChangeHandler}
                    />
                    <label className="filter-checkbox-label">Has Images</label>
                    <br />
                  </div>
                </div>
              </div>
              <hr className="filter-panel-divider" />
              <div className="filter-panel-footer">
                <div className="filter-option" onClick={() => resetFilter()}>
                  <span className="icon icon-trash" />
                  <label className="footer-option-title">Reset Filters</label>
                </div>
                <div className="filter-option" onClick={() => refresh()}>
                  <span className="icon icon-apply-filter" />
                  <label className="footer-option-title">Apply Filters</label>
                </div>
              </div>
            </div>
          )}
          {showProfile && (
            <div className="userinfo-drawer">
              <div className="userinfo-drawer-header">
                {users &&
                users[selectedUser - 1] &&
                users[selectedUser - 1].imgurl[0] ? (
                  <Media image={users[selectedUser - 1].imgurl[0]} />
                ) : (
                  // <Image
                  //   publicId={getImgName(users[selectedUser - 1].imgurl[0])}
                  //   version={getImgVersion(users[selectedUser - 1].imgurl[0])}
                  //   resource_type={
                  //     checkGIF(users[selectedUser - 1].imgurl[0])
                  //       ? 'video'
                  //       : 'image'
                  //   }
                  //   effect="loop"
                  //   cloud_name="sugarbae"
                  //   className="img-userinfo"
                  // >
                  //   <Transformation
                  //     height="200"
                  //     video_sampling="20"
                  //     delay="250"
                  //     duration="10"
                  //     effect="loop"
                  //   />
                  // </Image>
                  <div className="none-image-container">
                    <NoneImage
                      className="none-user-image"
                      src={
                        users[selectedUser - 1].gender === 'male'
                          ? male_user
                          : female_user
                      }
                    />
                  </div>
                )}
                <div className="userinfo-top">
                  <p className="userinfo-username">
                    {users[selectedUser - 1].username}
                  </p>
                  <div className="userinfo-riskscore">
                    <p className="userinfo-riskscore-title">Risk Score:</p>
                    <RiskScore
                      riskScore={
                        users[selectedUser - 1].risk &&
                        Math.trunc(users[selectedUser - 1].risk.score * 100)
                      }
                    />
                  </div>
                </div>
                <div
                  className="profile-drawer-close"
                  onClick={() => closeProfile()}
                >
                  <span className="icon icon-close" />
                </div>
              </div>
              <div className="userinfo-drawer-body">
                <div
                  className={
                    showAccountInfo
                      ? 'userinfo-drawer-body-option-active'
                      : 'userinfo-drawer-body-option'
                  }
                  onClick={() => enableShowAccountInfo()}
                >
                  <label className="userinfo-body-option-title">
                    Account Information
                  </label>
                  <span
                    className={
                      showAccountInfo
                        ? 'icon icon-arrow-down userinfo-arrow'
                        : 'icon icon-arrow-right userinfo-arrow'
                    }
                  />
                </div>
                <hr className="userinfo-body" />
                {showAccountInfo && (
                  <div className="accountinfo-detail">
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-account-username" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Username:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].username}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-account-email" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Email:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].email}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-account-phone" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Phone:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].verifications.phone.number}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-account-bot" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Bot:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].bot ? 'True' : 'False'}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-account-phone-status" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Phone Status:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].verifications.phone.status
                            ? 'True'
                            : 'False'}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-account-email-status" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Mail Status:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].verifications.email.status
                            ? 'True'
                            : 'False'}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-account-signup" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Signup Date:</p>
                        <p className="account-detail-content">
                          {moment(users[selectedUser - 1].createdAt).format(
                            'LLL'
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-account-last" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Last Login:</p>
                        <p className="account-detail-content">
                          {moment(users[selectedUser - 1].lastlogin).format(
                            'LLL'
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-account-deactivated" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Deactivated:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].deactivate
                            ? 'True'
                            : 'False'}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-account-ip" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Ip Address:</p>
                        <p className="account-detail-content">{`${
                          users[selectedUser - 1].ipaddress[
                            users[selectedUser - 1].ipaddress.length - 1
                          ].ipaddress
                        } (Risk: ${
                          users[selectedUser - 1].ipaddress[
                            users[selectedUser - 1].ipaddress.length - 1
                          ].riskScore
                        })`}</p>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={
                    showUserInfo
                      ? 'userinfo-drawer-body-option-active'
                      : 'userinfo-drawer-body-option'
                  }
                  onClick={() => enableShowUserInfo()}
                >
                  <label className="userinfo-body-option-title">
                    User Information
                  </label>
                  <span
                    className={
                      showUserInfo
                        ? 'icon icon-arrow-down userinfo-arrow'
                        : 'icon icon-arrow-right userinfo-arrow'
                    }
                  />
                </div>
                <hr className="userinfo-body" />
                {showUserInfo && (
                  <div className="accountinfo-detail">
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-user-gender" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Gender:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].gender === 'male'
                            ? 'Male'
                            : 'Female'}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-user-home" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Address:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].address}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-user-age" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Age:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].age}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-user-height" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Height:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].height}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-user-bodytype" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Bodytype:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].bodytype}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-user-ethnicity" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Ethnicity:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].ethnicity}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={
                    showUserBio
                      ? 'userinfo-drawer-body-option-active'
                      : 'userinfo-drawer-body-option'
                  }
                  onClick={() => enableShowUserBio()}
                >
                  <label className="userinfo-body-option-title">User Bio</label>
                  <span
                    className={
                      showUserBio
                        ? 'icon icon-arrow-down userinfo-arrow'
                        : 'icon icon-arrow-right userinfo-arrow'
                    }
                  />
                </div>
                <hr className="userinfo-body" />
                {showUserBio && (
                  <div className="accountinfo-detail">
                    <div className="bio-option">
                      <div className="bio-option-header">
                        <p className="bio-option-title">Headline:</p>
                        {users[selectedUser - 1].headlinestatus ===
                          'pending' && (
                          <div className="pending-box">
                            <span className="icon icon-pending" />
                            <p className="pending-style">Pending</p>
                          </div>
                        )}
                        {users[selectedUser - 1].headlinestatus ===
                          'approved' && (
                          <div className="no-pending-box">
                            <span className="icon icon-approved" />
                            <p className="pending-style">Approved</p>
                          </div>
                        )}
                        {users[selectedUser - 1].headlinestatus ===
                          'rejected' && (
                          <div className="no-pending-box">
                            <span className="icon icon-rejected" />
                            <p className="pending-style">Rejected</p>
                          </div>
                        )}
                      </div>
                      {editHeadlineContent ? (
                        <textarea className="bio-content" />
                      ) : (
                        <div className="bio-wrapper">
                          <div className="bio-option-content">
                            {users[selectedUser - 1].headline}
                          </div>
                          <div
                            className="bio-option-edit"
                            onClick={() => editHeadlineHandler()}
                          >
                            <span className="icon icon-edit" />
                          </div>
                        </div>
                      )}
                      <div className="bio-option-footer">
                        <div
                          className="bio-option-button"
                          onClick={() => rejectHandler('headline')}
                        >
                          <p>Decline</p>
                        </div>
                        <div
                          className="bio-option-button-approve"
                          onClick={() => approveHandler('headline')}
                        >
                          <p>Approve</p>
                        </div>
                      </div>
                    </div>
                    <div className="bio-option">
                      <div className="bio-option-header">
                        <p className="bio-option-title">Bio:</p>
                        {users[selectedUser - 1].biostatus === 'pending' && (
                          <div className="pending-box-bio">
                            <span className="icon icon-pending" />
                            <p className="pending-style">Pending</p>
                          </div>
                        )}
                        {users[selectedUser - 1].biostatus === 'approved' && (
                          <div className="no-pending-box-bio">
                            <span className="icon icon-approved" />
                            <p className="pending-style">Approved</p>
                          </div>
                        )}
                        {users[selectedUser - 1].biostatus === 'rejected' && (
                          <div className="no-pending-box-bio">
                            <span className="icon icon-rejected" />
                            <p className="pending-style">Rejected</p>
                          </div>
                        )}
                      </div>
                      {editBioContent ? (
                        <textarea className="bio-content" />
                      ) : (
                        <div className="bio-wrapper">
                          <div className="bio-option-content">
                            {users[selectedUser - 1].bio}
                          </div>
                          <div
                            className="bio-option-edit"
                            onClick={() => editBioHandler()}
                          >
                            <span className="icon icon-edit" />
                          </div>
                        </div>
                      )}
                      <div className="bio-option-footer">
                        <div
                          className="bio-option-button"
                          onClick={() => rejectHandler('bio')}
                        >
                          <p>Decline</p>
                        </div>
                        <div
                          className="bio-option-button-approve"
                          onClick={() => approveHandler('bio')}
                        >
                          <p>Approve</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={
                    showSubscription
                      ? 'userinfo-drawer-body-option-active'
                      : 'userinfo-drawer-body-option'
                  }
                  onClick={() => enableShowSubscription()}
                >
                  <label className="userinfo-body-option-title">
                    Subscription Information
                  </label>
                  <span
                    className={
                      showSubscription
                        ? 'icon icon-arrow-down userinfo-arrow'
                        : 'icon icon-arrow-right userinfo-arrow'
                    }
                  />
                </div>
                <hr className="userinfo-body" />
                {showSubscription && (
                  <div className="accountinfo-detail">
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-subscription-purchase-date" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">
                          Purchase Date:
                        </p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].subscription.startDate &&
                            moment(
                              users[selectedUser - 1].subscription.startDate
                            )
                              .add(7, 'h')
                              .format('LLL')}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-subscription-purchase-time" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">
                          Time to Purchase:
                        </p>
                        <p className="account-detail-content">
                          {purchasePeriod(users[selectedUser - 1])}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-subscription-cycle" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">Biling Cycle:</p>
                        <p className="account-detail-content">
                          {users[selectedUser - 1].subscription.plan}
                        </p>
                      </div>
                    </div>
                    <div className="accountinfo-detail-option">
                      <div className="detail-option-container">
                        <span className="icon icon-subscription-credit" />
                      </div>
                      <div className="account-detail-description">
                        <p className="account-detail-subtitle">
                          Payment Method:
                        </p>
                        <p className="account-detail-content">
                          {
                            users[selectedUser - 1].subscription.cardInfo
                              .paymentType
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={
                    showImages
                      ? 'userinfo-drawer-body-option-active'
                      : 'userinfo-drawer-body-option'
                  }
                  onClick={() => enableShowImages()}
                >
                  <label className="userinfo-body-option-title">Images</label>
                  <span
                    className={
                      showImages
                        ? 'icon icon-arrow-down userinfo-arrow'
                        : 'icon icon-arrow-right userinfo-arrow'
                    }
                  />
                </div>
                {showImages && (
                  <div className="accountinfo-detail">
                    <div className="image-panel">
                      {users[selectedUser - 1].imgurl.map((image) => (
                        <div className="image-container">
                          <Media image={image} />

                          {/* <Image
                            publicId={getImgName(image)}
                            version={getImgVersion(image)}
                            resource_type={checkGIF(image) ? 'video' : 'image'}
                            effect="loop"
                            cloud_name="sugarbae"
                            className="userinfo-table"
                          >
                            <Transformation
                              height="200"
                              video_sampling="20"
                              delay="250"
                              duration="10"
                              effect="loop"
                            />
                          </Image> */}
                          <label
                            className="image-delete-option"
                            onClick={() =>
                              actionToggleHandler({
                                action: 'removeimage',
                                image,
                              })
                            }
                          >
                            Delete
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="userinfo-drawer-footer">
                <div
                  className="userinfo-footer-option"
                  onClick={() => viewProfileHandler()}
                >
                  <span className="icon icon-view-profile" />
                  <label className="profile-more-option">View Profile</label>
                </div>
                <div
                  className="userinfo-footer-option"
                  onClick={() => sendLikesHandler()}
                >
                  <span className="icon icon-view-profile" />
                  <label className="profile-more-option">Send Likes</label>
                </div>
                {users[selectedUser - 1].premium ? (
                  <div
                    className="userinfo-footer-option"
                    onClick={() =>
                      actionToggleHandler({ action: 'cancelpremium' })
                    }
                  >
                    <span className="icon icon-cancel-premium" />
                    <label className="profile-more-option">
                      Cancel Premium
                    </label>
                    <br />
                  </div>
                ) : (
                  <div
                    className="userinfo-footer-option"
                    onClick={() =>
                      actionToggleHandler({ action: 'makepremium' })
                    }
                  >
                    <span className="icon icon-make-premium" />
                    <label className="profile-more-option">Make Premium</label>
                    <br />
                  </div>
                )}
                <div
                  className="userinfo-footer-option"
                  onClick={() => toggleChatHistory()}
                >
                  <span className="icon icon-chat-history" />
                  <label className="profile-more-option">Chat History</label>
                  <br />
                </div>
                {users[selectedUser - 1].banned ? (
                  <div
                    className="userinfo-footer-option"
                    onClick={() => actionToggleHandler({ action: 'unbanuser' })}
                  >
                    <span className="icon icon-unban-user" />
                    <label className="profile-more-option">Unban User</label>
                    <br />
                  </div>
                ) : (
                  <div
                    className="userinfo-footer-option"
                    onClick={() => actionToggleHandler({ action: 'banuser' })}
                  >
                    <span className="icon icon-ban-user" />
                    <label className="profile-more-option">Ban User</label>
                    <br />
                  </div>
                )}
                {users[selectedUser - 1].deactivate ? (
                  <div
                    className="userinfo-footer-option"
                    onClick={() =>
                      actionToggleHandler({ action: 'reactivate' })
                    }
                  >
                    <span className="icon icon-reactivate-user" />
                    <label className="profile-more-option">
                      Reactivate Account
                    </label>
                  </div>
                ) : (
                  <div
                    className="userinfo-footer-option"
                    onClick={() =>
                      actionToggleHandler({ action: 'deactivate' })
                    }
                  >
                    <span className="icon icon-deactivate-user" />
                    <label className="profile-more-option">
                      Deactivate Account
                    </label>
                  </div>
                )}
              </div>
            </div>
          )}
          {users.length === 0 && (
            <div className="usertable-no-result">
              <span className="icon icon-no-result" />
              <div className="no-result-description">
                <div className="no-result-option">
                  <span className="icon icon-no-result-word" />
                  <label className="no-result-option-sub-description">
                    Try to check if the letters are correct
                  </label>
                </div>
                <div className="no-result-option">
                  <span className="icon icon-no-result-search" />
                  <label className="no-result-option-sub-description">
                    Try to search by email/subscriptionID
                  </label>
                </div>
              </div>
            </div>
          )}
          {showChatHistory && (
            <ChatHistory
              user={users[selectedUser - 1]}
              closeHandler={closeChatHistory}
            />
          )}
        </div>
      </Card.Header>
      <Card.Body
        id="usertable-container"
        style={showFilters ? { opacity: '20%' } : null}
      >
        <Table responsive striped borderless hover variant="dark">
          <thead>
            <tr>
              <th />
              <th />
              <th>Username</th>
              <th>Email</th>
              <th>Address</th>
              <th>Gender</th>
              <th>Risk Score</th>
              <th>Verifications</th>
              <th>Premium</th>
              <th>Subscription</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user, index) => {
                const mainImage = user.imgurl[0];
                return (
                  <tr
                    className="user-info-row"
                    style={
                      selectedUser && selectedUser !== index + 1
                        ? { filter: `blur(3px)` }
                        : null
                    }
                    onClick={() => selectUser(index)}
                  >
                    <td>{(page - 1) * 12 + index + 1}</td>
                    <td>
                      {mainImage ? (
                        <div id="row-image-wrapper">
                          <Media image={mainImage} />
                        </div>
                      ) : (
                        // <Image
                        //   publicId={getImgName(mainImage)}
                        //   version={getImgVersion(mainImage)}
                        //   resource_type={
                        //     checkGIF(mainImage) ? 'video' : 'image'
                        //   }
                        //   effect="loop"
                        //   cloud_name="sugarbae"
                        //   className="img-table"
                        // >
                        //   <Transformation
                        //     height="200"
                        //     video_sampling="20"
                        //     delay="250"
                        //     duration="10"
                        //     effect="loop"
                        //   />
                        // </Image>
                        <div className="none-image-container">
                          <NoneImage
                            className="none-user-image"
                            src={
                              user.gender === 'male' ? male_user : female_user
                            }
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      <div className="table-user-info">{user.username}</div>
                    </td>
                    <td>
                      <div className="table-user-info">{user.email}</div>
                    </td>
                    <td>
                      <div className="table-user-info">{user.address}</div>
                    </td>
                    <td>
                      <div className="table-user-info">
                        {user.gender === 'male' ? (
                          <span className="icon icon-gender-male" />
                        ) : (
                          <span className="icon icon-gender-female" />
                        )}
                      </div>
                    </td>
                    <td>
                      <RiskScore
                        riskScore={
                          user.risk && Math.trunc(user.risk.score * 100)
                        }
                      />
                    </td>
                    <td>
                      {user.verifications.phone.status ? (
                        <span className="icon icon-phone-active" />
                      ) : (
                        <span className="icon icon-phone" />
                      )}
                      {user.verifications.email.status ? (
                        <span className="icon icon-email-active" />
                      ) : (
                        <span className="icon icon-email" />
                      )}
                    </td>
                    <td>
                      {user.premium ? (
                        <span className="icon icon-premium-active" />
                      ) : (
                        <span className="icon icon-premium" />
                      )}
                    </td>
                    <td>
                      {user.subscription.active ? (
                        <span className="subscription-active">Active</span>
                      ) : (
                        <span className="subscription-none-active">
                          None-Active
                        </span>
                      )}
                    </td>
                    <td onClick={() => selectUser(index)}>
                      <span className="icon icon-more-option" />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        <div className="card-footer-wrap">
          <div className="usertable-all-amounts">{`Results: ${count}`}</div>
          <div className="usertable-pagination">
            <div className="arrow-panel" onClick={() => prevPage()}>
              <span className="icon icon-prev" />
            </div>
            <div className="arrow-panel" onClick={() => nextPage()}>
              <span className="icon icon-next" />
            </div>
            <div className="pages-title">{`Pages: `}</div>
            <div>
              {pages.length ? (
                <Select
                  searchable={false}
                  values={[pages.find((opt) => opt.value === page)]}
                  options={pages}
                  dropdownPosition="top"
                  onChange={(values) => onPageChange(values)}
                  className="usertable-pagination-current-page"
                />
              ) : null}
            </div>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default withRouter(UserTable);
