import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import "../userinfocards.scss";

export class FinancialInfo extends Component {
  static contextType = UserContext;

  render() {
    var card = this.props.card;
    var gender = card.gender;
    var annualincome = card.lifestyle.annualincome;
    var networth = card.lifestyle.networth;
    var job = card.lifestyle.job;
    var jobtitle = job.jobtitle;

    var showIncome = false;
    var showNetworth = false;
    var showJobtitle = false;

    if (annualincome !== null && annualincome !== undefined) {
      showIncome = true;
    }
    if (networth !== null && networth !== undefined) {
      showNetworth = true;
    }
    if (jobtitle !== null && jobtitle !== undefined) {
      showJobtitle = true;
    }

    if (!showNetworth && !showIncome && !showJobtitle) {
      return null;
    } else if (gender === "female") {
      return null;
    } else {
      return (
        <Card className="user-info-card">
          <Card.Header className="user-info-card-header">
            <Card.Title className="user-info-card-header-title">
              <i className="far fa-sack-dollar user-info-header-icon"></i>
              Financial Info
            </Card.Title>
          </Card.Header>
          <Card.Body className="user-info-card-body">
            {showNetworth && (
              <div className="user-info-section">
                <h4 className="user-info-section-title">Net Worth: </h4>
                <p className="user-info-section-text">{networth}</p>
              </div>
            )}
            {showIncome && (
              <div
                className={
                  showJobtitle
                    ? "user-info-section"
                    : "user-info-section-bottom"
                }
              >
                <h4 className="user-info-section-title">Annual Income: </h4>
                <p className="user-info-section-text">{annualincome}</p>
              </div>
            )}
            {showJobtitle && (
              <div className="user-info-section-bottom">
                <h4 className="user-info-section-title">Job Title: </h4>
                <p className="user-info-section-text">{jobtitle}</p>
              </div>
            )}
          </Card.Body>
        </Card>
      );
    }
  }
}

export default FinancialInfo;
