import React, { useState, useEffect } from "react";
import { api } from "../../api";
import Tasks from "../graphs/Tasks";
import "./views.scss";

function TasksView(props) {
  return (
    <div className="view-container">
      <div className="view-row">
        <Tasks/>
      </div>
    </div>
  );
}

export default TasksView;
