import React, { Component } from "react";
import { Button } from "react-bootstrap";
import "./matchestabs.scss";
import Likes from "./tabs/Likes";
import Matches from "./tabs/Matches";

import Axios from "axios";
import { UserContext } from "../../../../context/UserContext";
import Profile from "../../../profile/Profile";

export class MatchesTabs extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      premium: false,
      loading: false,
      tab: "matches",
      profileOpen: false,
      cardid: "",
    };
  }

  componentWillMount() {
    var url = this.props.url;
    if (url) {
      if (url.includes("likes")) {
        this.setState({ tab: "likes" });
      }
    }
  }

  toggleTabHandler = (props) => (e) => {
    this.setState({ tab: props });
  };

  profileToggle = (e) => {
    if (this.state.profileOpen) {
      this.setState({ profileOpen: false, cardid: "" });
    } else {
      this.setState({ profileOpen: true, cardid: e });
    }
  };

  render() {
    if (this.state.loading) {
      return null;
    } else if (this.state.profileOpen) {
      return (
        <Profile cardid={this.state.cardid} closeHandler={this.profileToggle} />
      );
    } else {
      return (
        <div className="matches-tabs-component">
          <div className="matches-tabs-wrapper">
            <Button
              className={
                this.state.tab === "matches"
                  ? "matches-tab-btn-active-left"
                  : "matches-tab-btn-inactive-left"
              }
              size="lg"
              onClick={this.toggleTabHandler("matches")}
            >
              Matches
            </Button>
            <Button
              className={
                this.state.tab === "likes"
                  ? "matches-tab-btn-active-right"
                  : "matches-tab-btn-inactive-right"
              }
              size="lg"
              onClick={this.toggleTabHandler("likes")}
            >
              Likes
            </Button>
          </div>
          {this.state.tab === "matches" ? (
            <Matches profileToggle={this.profileToggle} />
          ) : null}
          {this.state.tab === "likes" ? (
            <Likes
              premium={this.context.user.premium}
              handleBoost={() => {
                this.props.handleBoost();
              }}
              profileToggle={this.profileToggle}
            />
          ) : null}
        </div>
      );
    }
  }
}

export default MatchesTabs;
