import React from "react";
import "./aboutstyles.scss";
import { Image } from "react-bootstrap";
import relationshipIcon from "../../../../assets/Icons/profile/heartIcon.png";
import bodytypeIcon from "../../../../assets/Icons/profile/bodytypeIcon.png";
import childrenIcon from "../../../../assets/Icons/profile/childrenIcon.png";
import drinkingIcon from "../../../../assets/Icons/profile/drinkingIcon.png";
import educationIcon from "../../../../assets/Icons/profile/educationIcon.png";
import ethnicityIcon from "../../../../assets/Icons/profile/ethnicityIcon.png";
import exerciseIcon from "../../../../assets/Icons/profile/exerciseIcon.png";
import heightIcon from "../../../../assets/Icons/profile/heightIcon.png";
import smokingIcon from "../../../../assets/Icons/profile/smokingIcon.png";

function DesktopPersonalInfo(props) {
  const { card } = props;
  const { lifestyle } = card;
  let relationshipText = "-";
  let heightText = "-";
  let bodytypeText = "-";
  let ethnicityText = "-";
  let drinkingText = "-";
  let smokingText = "-";
  let educationText = "-";
  let kidsText = "-";
  let exerciseText = "-";

  if (lifestyle.relationship) {
    relationshipText = lifestyle.relationship;
  }

  if (card.height) {
    heightText = card.height;
  }

  if (card.bodytype) {
    bodytypeText = card.bodytype;
  }

  if (card.ethnicity) {
    ethnicityText = card.ethnicity;
  }

  if (lifestyle.drinking) {
    drinkingText = lifestyle.drinking;
  }

  if (lifestyle.smoking) {
    smokingText = lifestyle.smoking;
  }

  if (lifestyle.education) {
    educationText = lifestyle.education;
  }

  if (lifestyle.children) {
    kidsText = lifestyle.children;
  }

  if (lifestyle.exercise) {
    exerciseText = lifestyle.exercise;
  }

  return (
    <div className="desktop-v2-about-container">
      <h1 className="desktop-v2-section-title">Personal Info</h1>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image
              src={relationshipIcon}
              className="desktop-v2-about-row-icon"
            />
          </div>
          <h2 className="desktop-v2-about-row-title">Relationship Status</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          <span className="desktop-v2-about-row-content">
            {relationshipText}
          </span>
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={heightIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Height</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          <span className="desktop-v2-about-row-content">{heightText}</span>
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={bodytypeIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Bodytype</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          <span className="desktop-v2-about-row-content">{bodytypeText}</span>
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={ethnicityIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Ethnicity</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          <span className="desktop-v2-about-row-content">{ethnicityText}</span>
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={drinkingIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Drinking</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          <span className="desktop-v2-about-row-content">{drinkingText}</span>
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={smokingIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Smoking</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          <span className="desktop-v2-about-row-content">{smokingText}</span>
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={educationIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Education</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          <span className="desktop-v2-about-row-content">{educationText}</span>
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={childrenIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Kids</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          <span className="desktop-v2-about-row-content">{kidsText}</span>
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={exerciseIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Exercise</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          <span className="desktop-v2-about-row-content">{exerciseText}</span>
        </div>
      </div>
    </div>
  );
}

export default DesktopPersonalInfo;
