import React from "react";
import { withRouter } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import logo from "../../assets/logos/google.png";
import "./GoogleLogin.scss";

const GoogleLoginComponent = props => {
  const clientID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
  const onSuccess = (res) => {
    props.onCheck({info: res.profileObj, case: "google"});
    
  };

  const onFailure = (res) => {
    console.log("failure: ", res);
    props.history.push("/signup");
  };

  return (
    <GoogleLogin
      clientId={clientID}
      onSuccess={onSuccess}
      onFailure={onFailure}
      className="btnGoogle-signin"
      icon={false}
    >
      <img src={logo} className="google-logo" />
      {props.isSignIn ? <span>{"   Continue with Google"}</span> : <span>{"   Signup with Google"}</span>}                                                        
    </GoogleLogin>
  );
};

export default withRouter(GoogleLoginComponent);