import React, { Component } from "react";
import "./swipepops.scss";
import { Button, Card, Image, Modal } from "react-bootstrap";
import Axios from "axios";
import { UserContext } from "../../../../context/UserContext";
import Countdown from "react-countdown";
import { withRouter } from "react-router-dom";
import { api } from "../../../../api";

export class LikePop extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      show: true,
      premium: false,
      loading: true,
      image: "",
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData = async (e) => {
    var cardid = this.props.cardid;

    const data = {
      userid: this.context.user._id,
      cardid: cardid,
    };

    return api
      .likePop(data)
      .then((res) => {
        if (res.status === 200) {
          var premium = res.data.premium;
          var image = res.data.card.image;
          var username = res.data.card.username;
          var date = res.data.date;
          this.setState({
            premium: premium,
            image: image,
            username: username,
            date: date,
            loading: false,
          });
        }
      })
      .catch((err) => {});
  };

  closeHandler = (e) => {
    this.setState({ show: false }, () => {
      this.props.closeHandler();
    });
  };

  carouselSelect = (selectedIndex, e) => {
    this.setState({ carouselIndex: selectedIndex });
  };

  linkClickHandler = (e) => {
    this.props.history.push("/app/premium");
  };

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <Modal size="lg" show={this.state.show} onHide={this.closeHandler}>
          <Modal.Body className="swipe-pop-modal-body-likes">
            <div className="swipe-pop-body-premium-title-wrap">
              <i className="fas fa-gem swipe-pop-premium-icon"></i>
              <span className="swipe-pop-body-premium-title">Go Premium</span>
            </div>
            <Image
              className="swipe-pop-modal-body-image"
              src={this.state.image}
              roundedCircle
            />
            <h2 className="swipe-pop-body-title">Out of Likes!</h2>
            <div className="swipe-pop-body-countdown-wrapper">
              <Countdown
                date={new Date(this.state.date)}
                className="swipe-pop-timer"
                daysInHours="true"
              ></Countdown>
            </div>
            <p className="swipe-pop-body-subtitle">
              Go Premium and get <strong>unlimited likes</strong> and much more.
            </p>
            <Card className="swipe-pop-features-card">
              <Card.Body className="swipe-pop-features-card-body">
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-heart swipe-pop-feature-icon-heart"></i>
                  <p className="swipe-pop-feature-text">Unlimited Likes</p>
                </div>
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-stars swipe-pop-feature-icon-stars"></i>
                  <p className="swipe-pop-feature-text">Extra Super Likes</p>
                </div>
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-bolt swipe-pop-feature-icon-bolt"></i>
                  <p className="swipe-pop-feature-text">Profile Boosts</p>
                </div>
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-eye swipe-pop-feature-icon-eye"></i>
                  <p className="swipe-pop-feature-text">View Profile Likes</p>
                </div>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer className="swipe-pop-footer">
            <Button
              className="swipe-pop-premium-btn"
              size="lg"
              onClick={this.linkClickHandler}
            >
              Continue
            </Button>
            <Button
              className="swipe-pop-close-btn"
              onClick={this.closeHandler}
              size="lg"
              variant="link"
            >
              No Thanks
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
}

export default withRouter(LikePop);
