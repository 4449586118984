import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import Axios from "axios";
import { UserContext } from "../../../context/UserContext";
import { Alert, Form, Button, Card } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#495057",

      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      fontWeight: "400",
      border: "1px solid #ced4da",
      "::placeholder": {
        color: "#495057",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

class CheckoutFormAdmin extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = { loading: false, secret: null, success: false };
  }

  handleSubmit = async (event) => {
    console.log("inside handle submit");
    this.setState({ loading: true });
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      console.log("Stripe Has Not Loaded");
      return;
    }

    var secret = this.state.secret;

    console.log(secret);

    const result = await stripe
      .confirmCardPayment(secret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
      .catch((err) => {
        console.log(err);
      });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.log("there was an error");
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        this.setState({ loading: false, success: true });

        console.log("Payment Successful");
      }
    }
  };

  fetchPaymentIntent = async (e) => {
    this.setState({ success: false });
    console.log("fetching payment intent");
    const token = localStorage.getItem("auth");
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + "/api/admin/paymentintent";
    const config = {
      headers: { auth: token },
    };
    await Axios.post(url, config)
      .then((res) => {
        console.log(res.data.secret);
        this.setState({ secret: res.data.secret }, () => {
          console.log(this.state.secret);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hundredPayments = async (e) => {
    var i = 0;

    for (var i = 0; i < 25; i++) {
      console.log("Submitting Payment");
      await this.fetchPaymentIntent();
      await this.handleSubmit();
      console.log("Completed Loop");
    }
  };

  componentWillMount() {
    this.fetchPaymentIntent();
  }

  render() {
    const { stripe } = this.props;

    return (
      <Card bg={"light"} text={"dark"} style={{ width: "100%" }}>
        {this.state.success ? (
          <Alert
            variant="success"
            onClose={() => this.setState({ success: false })}
            dismissible
          >
            <Alert.Heading>Successful Payment.</Alert.Heading>
          </Alert>
        ) : null}
        <Card.Header>
          <div className="card-header-wrap">
            <div className="card-header-leftside">
              <h4 className="card-header-title">Submit A Payment</h4>
            </div>
            <div className="card-header-rightside">
              {this.state.loading ? (
                <ClipLoader
                  size={10}
                  color={"#fff"}
                  loading={this.state.loading}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <Button variant="outline-dark" onClick={this.fetchPaymentIntent}>
            Fetch PI
          </Button>
          <Button variant="outline-dark" onClick={this.hundredPayments}>
            Run 100 Payments
          </Button>
        </Card.Header>
        <Card.Body>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </Card.Body>
        <Card.Footer>
          <Button variant="primary" onClick={this.handleSubmit}>
            Submit
          </Button>
        </Card.Footer>
      </Card>
    );
  }
}

export default class InjectedCheckoutFormAdmin extends React.Component {
  render() {
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <CheckoutFormAdmin stripe={stripe} elements={elements} />
        )}
      </ElementsConsumer>
    );
  }

  // export default function InjectedCheckoutForm() {
  //   return (
  //     <ElementsConsumer plan={this.props.plan}>
  //       {({ stripe, elements }) => (
  //         <CheckoutForm stripe={stripe} elements={elements} />
  //       )}
  //     </ElementsConsumer>
  //   );
}
