import React, { useState, useContext } from "react";
import "../modalv2styles.scss";
import { Modal, Button, Image, Form } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import Boost from "../../matches/matchesComponentsV2/boost/Boost";
import { UserContext } from "../../../context/UserContext";
import getmore from "../../../assets/Images/matches/getmore.png";
import { submitBoostV2 } from "../../../helpers/swipeActions";
import { api } from "../../../api";

function BoostModal(props) {
  const context = useContext(UserContext);
  const [show, setShow] = useState(props.show);
  const [boostActivePop, setBoostActivePop] = useState(false);
  const [boostAlreadyActivePop, setBoostAlreadyActivePop] = useState(false);
  const [boostInvalid, setBoostInvalid] = useState(false);
  const [packageSelected, setPackageSelected] = useState(2);
  const [boostActivated, setBoostActivated] = useState(false);
  const [loading, setLoading] = useState(false);

  const { cards } = context.swipeData;
  const imageOne = cards && cards.length > 0 && cards[0].imgurl[0];
  const imageTwo = cards && cards.length > 0 && cards[1].imgurl[0];
  const imageThree = cards && cards.length > 0 && cards[2].imgurl[0];

  const closeHandler = (e) => {
    setShow(false);
    props.onClose();
  };

  const handleBoost = async (e) => {
    setLoading(true);
    let planText = "packageA";
    if (packageSelected === 2) {
      planText = "packageB";
    } else if (packageSelected === 3) {
      planText = "packageC";
    }

    const payload = {
      plan: planText,
    };
    let purchaseSuccess = false;
    const buyBoost = await api
      .purchaseBoosts(payload)
      .then((res) => {
        if (res.status === 200) {
          purchaseSuccess = res.data.purchaseSuccess;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (purchaseSuccess) {
      const data = {
        packageSelected,
      };

      const boost = await submitBoostV2(data).catch((err) => {
        console.log(err);
      });

      if (boost) {
        context.updateBoostData(boost);
        if (boost.boosted) {
          setBoostActivated(true);
        }
      }
    } else {
      const { email } = context.user;
      const urlPackageA = `https://api.ccbill.com/wap-frontflex/flexforms/4e578830-264f-469b-a5dd-723342336604?Email=${email}`;
      const urlPackageB = `https://api.ccbill.com/wap-frontflex/flexforms/29104f56-bf7d-4653-9744-99a72b50de11?Email=${email}`;
      const urlPackageC = `https://api.ccbill.com/wap-frontflex/flexforms/aa2ff8da-fc33-4223-b198-2a0a1562f49a?Email=${email}`;

      if (packageSelected === 1) {
        window.location.replace(urlPackageA);
      } else if (packageSelected === 2) {
        window.location.replace(urlPackageB);
      } else if (packageSelected === 3) {
        window.location.replace(urlPackageC);
      }
    }
  };

  const togglePackage = (number) => (e) => {
    setPackageSelected(number);
  };

  let mainImage = null;
  const images = context.user.imgurl;
  if (images) {
    if (images.length > 0) {
      mainImage = images[0];
    }
  }

  let optionOneStyle = "boost-package-select-group";
  let optionTwoStyle = "boost-package-select-group";
  let optionThreeStyle = "boost-package-select-group";

  if (packageSelected === 1) {
    optionOneStyle = "boost-package-select-group-active";
  } else if (packageSelected === 2) {
    optionTwoStyle = "boost-package-select-group-active";
  } else if (packageSelected === 3) {
    optionThreeStyle = "boost-package-select-group-active";
  }

  return (
    <Modal show={show} className="modal-v2" onHide={closeHandler} centered>
      {loading && (
        <div className="premium-checkout-loading-overlay">
          <ClipLoader color="white" />
        </div>
      )}
      {boostActivated ? (
        <Modal.Body className="modal-v2-body">
          <div className="boost-active-popup-modal">
            <div className="boost-modal-header">
              <Image src={imageOne} className="boost-active-back-image" />
              <Image src={mainImage} className="boost-active-front-image" />
              <Image src={imageThree} className="boost-active-back-image" />
              <Image src={getmore} className="boost-active-badge" />
            </div>
            <label className="boost-active-title">
              Your Profile is Boosted!
            </label>
            <br />
            <label className="boost-active-description">
              You successfully activated Profile Boost.
            </label>
            <br />
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body className="modal-v2-body">
          <div className="boost-active-popup-modal">
            <div className="boost-modal-header">
              <Image src={imageOne} className="boost-active-back-image" />
              <Image src={mainImage} className="boost-active-front-image" />
              <Image src={imageThree} className="boost-active-back-image" />
              <Image src={getmore} className="boost-active-badge" />
            </div>
            <label className="boost-active-title">Boost Your Profile.</label>
            <br />
            <label className="boost-active-description">
              Boost your profile to get more attention.
            </label>
            <br />
          </div>
          <div className="boost-package-select-wrapper">
            <Form className="boost-package-select-form">
              <Form.Group className={optionOneStyle} onClick={togglePackage(1)}>
                <i className="far fa-calendar boost-package-icon" />
                <p
                  className={
                    packageSelected === 1
                      ? "boost-package-subtitle-active"
                      : "boost-package-subtitle"
                  }
                >
                  1 Day
                </p>
                <h1
                  className={
                    packageSelected === 1
                      ? "boost-package-price-active"
                      : "boost-package-price"
                  }
                >
                  $7.99
                </h1>
              </Form.Group>
              <Form.Group className={optionTwoStyle} onClick={togglePackage(2)}>
                <i className="far fa-calendar boost-package-icon" />
                <p
                  className={
                    packageSelected === 2
                      ? "boost-package-subtitle-active"
                      : "boost-package-subtitle"
                  }
                >
                  3 Days
                </p>
                <h1
                  className={
                    packageSelected === 2
                      ? "boost-package-price-active"
                      : "boost-package-price"
                  }
                >
                  $12.99
                </h1>
              </Form.Group>
              <Form.Group
                className={optionThreeStyle}
                onClick={togglePackage(3)}
              >
                <i className="far fa-calendar boost-package-icon" />
                <p
                  className={
                    packageSelected === 3
                      ? "boost-package-subtitle-active"
                      : "boost-package-subtitle"
                  }
                >
                  7 Days
                </p>
                <h1
                  className={
                    packageSelected === 3
                      ? "boost-package-price-active"
                      : "boost-package-price"
                  }
                >
                  $27.99
                </h1>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer className="modal-v2-footer">
        {!boostActivated && (
          <div className="modal-action-button" onClick={() => handleBoost()}>
            Boost Your Profile
          </div>
        )}

        <div className="modal-close-button" onClick={closeHandler}>
          Close
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default BoostModal;
