import React, { useState, useEffect } from "react";
import { api } from "../../api";
import "./graphs.scss";
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
var moment = require("moment");

function ActiveSubAgeGroup(props) {
  //const [maleSignupData, setMaleSignupData] = useState([]);
  //const [femaleSignupData, setFemaleSignupData] = useState([]);
  const [activeSubData, setActiveSubData] = useState([]); 
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    return api
      .fecthActiveSubscription()
      .then((res) => {
        if (res.status === 200) {
          //setMaleSignupData(res.data.maleSignupData);
          //setFemaleSignupData(res.data.femaleSignupData);
          setActiveSubData(res.data.activeSubData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderCustomAxisTick = ({ x, y, payload }) => {
    var date = payload.value;

    var formattedDate = moment(date).format("MMM DD").toString();

    return (
      <text x={x} y={y + 25} fill="#666" textAnchor="middle">
        {formattedDate}
      </text>
    );
  };
  return (
    <div className="graph-container">
      <div className="graph-header">
        <h1 className="graph-title"> Subscriber by Gender and Age Group </h1>
      </div>
      <div className="graph-content-container">
        <table>
          <tr>
            <div className="graph-content">
              <h2 className="graph-content-title">Male</h2>
              <BarChart
                width={500}
                height={200}
                data={activeSubData}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <Tooltip />
                <Bar dataKey="male" stackId="a" fill="#525cd1" />
                <XAxis dataKey="ageGroup" />
                <YAxis dataKey="female"/>
              </BarChart>
            </div>
          </tr>
          <tr>
            <div className="graph-content">
              <h2 className="graph-content-title">Female</h2>
              <BarChart
                width={500}
                height={200}
                data={activeSubData}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                <Tooltip />
                <Bar dataKey="female" stackId="a" fill="#db2adb" stroke="#FF0000"/>
                <XAxis dataKey="ageGroup" />
                <YAxis dataKey="female"/>
              </BarChart>
            </div>
          </tr>
        </table>
        <div>
          <PieChart 
            width={400} 
            height={400}
            data={activeSubData}>
          <Pie dataKey="male" isAnimationActive={true} data={activeSubData} cx={200} cy={200} outerRadius={80} fill="#FF0000" label />
          <Pie dataKey="female" data={activeSubData} cx={500} cy={200} innerRadius={40} outerRadius={80} fill="#FF0000" />
          <Tooltip />
          </PieChart>
        </div>
      </div>
    </div>
  );
}

export default ActiveSubAgeGroup;
