import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";

function Relationship(props) {
  var filters = props.filters;
  const [relationshipStatus, setRelationshipStatus] = useState(
    filters.relationshipstatus
  );

  const changeHandler = (e) => {
    var relationshipStatusCopy = relationshipStatus;

    if (e.target.value === "single") {
      if (relationshipStatusCopy.single) {
        relationshipStatusCopy.single = false;
      } else {
        relationshipStatusCopy.single = true;
      }
    } else if (e.target.value === "divorced") {
      if (relationshipStatusCopy.divorced) {
        relationshipStatusCopy.divorced = false;
      } else {
        relationshipStatusCopy.divorced = true;
      }
    } else if (e.target.value === "separated") {
      if (relationshipStatusCopy.separated) {
        relationshipStatusCopy.separated = false;
      } else {
        relationshipStatusCopy.separated = true;
      }
    } else if (e.target.value === "marriedbutlooking") {
      if (relationshipStatusCopy.marriedbutlooking) {
        relationshipStatusCopy.marriedbutlooking = false;
      } else {
        relationshipStatusCopy.marriedbutlooking = true;
      }
    } else if (e.target.value === "openrelationship") {
      if (relationshipStatusCopy.openrelationship) {
        relationshipStatusCopy.openrelationship = false;
      } else {
        relationshipStatusCopy.openrelationship = true;
      }
    } else if (e.target.value === "widowed") {
      if (relationshipStatusCopy.widowed) {
        relationshipStatusCopy.widowed = false;
      } else {
        relationshipStatusCopy.widowed = true;
      }
    }

    setRelationshipStatus((prevState) => {
      return { ...prevState, ...relationshipStatusCopy };
    });
  };

  const saveHandler = (e) => {
    props.updateHandler(relationshipStatus);
  };

  return (
    <div className="filter-slider-component">
      <div className="filter-slider-overlay" onClick={props.onClose} />
      <div className="filter-slider-content">
        <div className="filter-slider-title-wrap">
          <i className="far fa-heart filter-sort-icon"></i>
          <h2 className="filter-slider-title">Relationship Status</h2>
        </div>
        <div className="filter-slider-wrapper">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Single"}
                type="checkbox"
                value="single"
                checked={relationshipStatus.single}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Divorced"}
                type="checkbox"
                value="divorced"
                checked={relationshipStatus.divorced}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Separated"}
                type="checkbox"
                value="separated"
                checked={relationshipStatus.separated}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Married But Looking"}
                type="checkbox"
                value="marriedbutlooking"
                checked={relationshipStatus.marriedbutlooking}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Open Relationship"}
                type="checkbox"
                value="openrelationship"
                checked={relationshipStatus.openrelationship}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Widowed"}
                type="checkbox"
                value="widowed"
                checked={relationshipStatus.widowed}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="filter-save-button-wrapper">
          <Button className="filter-save-button" onClick={saveHandler}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Relationship;
