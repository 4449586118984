import React, { useState, useContext } from "react";
import "./profilev2footer.scss";
import { Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { ReportModal } from "../../../modals/reportModal/ReportModal";
import { api } from "../../../../api";
import { UserContext } from "../../../../context/UserContext";

function ProfileV2Footer(props) {
  const context = useContext(UserContext);

  const [abuseModalOpen, setAbuseModalOpen] = useState(false);
  const [banConfirmation, setBanConfirmation] = useState(false);

  const banHandler = (e) => {
    setBanConfirmation(true);
  };

  const submitBan = async (e) => {
    const data = {
      banUserId: props.profileUser._id,
    };

    if (context.user.isAdmin) {
      return api
        .submitBan(data)
        .then((res) => {
          if (res.status === 200) {
            props.banHandler(props.profileUser._id);
          }
        })
        .catch((err) => {});
    }
  };

  const toggleComplimentModal = (e) => {
    props.modalToggle();
  };

  const isAdmin = context.user.isAdmin;

  const abuseToggle = (e) => {
    if (abuseModalOpen) {
      setAbuseModalOpen(false);
    } else {
      setAbuseModalOpen(true);
    }
  };

  const reportHandler = (e) => {
    props.reportHandler();
  };

  var footerStyle = "profile-v2-footer-section";

  if (props.location.pathname === "/app") {
    footerStyle = "profile-v2-footer-section-actions";
  }

  return (
    <div className={footerStyle}>
      {abuseModalOpen && (
        <ReportModal
          closeHandler={abuseToggle}
          reportHandler={reportHandler}
          card={props.profileUser}
        />
      )}
      {props.isOwnProfile ? (
        <Link to="/app/profile/edit">
          <div className="profile-v2-footer-header">
            <div className="profile-v2-footer-header-innerwrap">
              <i className="fas fa-pencil profile-footer-gift-icon"></i>
              <h4 className="profile-v2-footer-title">Edit Your Profile</h4>
            </div>
          </div>
        </Link>
      ) : (
        <div
          className="profile-v2-footer-header"
          onClick={toggleComplimentModal}
        >
          <div className="profile-v2-footer-header-innerwrap">
            <i className="far fa-gift profile-footer-gift-icon"></i>
            <h4 className="profile-v2-footer-title">Send a compliment</h4>
          </div>
        </div>
      )}
      {!props.isOwnProfile && (
        <div className="profile-v2-footer-content" onClick={abuseToggle}>
          <h3 className="profile-v2-abuse-title">Report Abuse</h3>
        </div>
      )}
      {isAdmin && (
        <div className="profile-v2-footer-content">
          {banConfirmation ? (
            <Button variant="danger" onClick={submitBan}>
              Confirm Ban
            </Button>
          ) : (
            <>
              {props.profileUser.banned ? (
                <h3 className="profile-v2-abuse-title">User Already Banned</h3>
              ) : (
                <h3 className="profile-v2-abuse-title" onClick={banHandler}>
                  Ban this User
                </h3>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default withRouter(ProfileV2Footer);
