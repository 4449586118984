import React from "react";
import "./home.scss";
import { Image } from "react-bootstrap";
import homeLogo from "../../assets/logos/homeLogo.png";
import { withRouter, useHistory } from "react-router-dom";

function Home(props) {
  let history = useHistory();
  const phoneContinueHandler = (e) => {
    history.push("/phone");
  };
  const emailContinueHandler = (e) => {
    history.push("/email");
  };

  return (
    <div className="home-v2">
      <div className="home-v2-content">
        <div className="home-v2-header">
          <Image src={homeLogo} fluid className="home-v2-header-logo" />
          <p className="home-v2-header-subtitle">Dating with benefits</p>
        </div>
        <div className="home-v2-actions-container">
          <div
            className="home-v2-continue-button-phone"
            onClick={phoneContinueHandler}
          >
            Continue with phone number
          </div>
          <div
            className="home-v2-continue-button-email"
            onClick={emailContinueHandler}
          >
            Continue with email
          </div>
          <div className="home-v2-continue-with-container">
            <p className="home-v2-continue-text">Or continue with:</p>
            <div className="home-v2-social-login-wrapper">
              <div className="home-v2-social-login-btn">
                <i className="fab fa-facebook home-v2-facebook-icon" />
              </div>
              <div className="home-v2-social-login-btn">
                <i className="fab fa-google home-v2-facebook-icon" />
              </div>
            </div>
            <p className="home-v2-continue-subtitle">
              We will never post anything to Facebook.
            </p>
          </div>
          <div className="home-v2-disclaimer-container">
            <p className="home-v2-disclaimer-text">
              By clicking continue you agree to our{" "}
              <span className="home-v2-disclaimer-red">Terms & Conditions</span>{" "}
              and our{" "}
              <span className="home-v2-disclaimer-red">Privacy Policy</span>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Home);
