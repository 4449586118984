import React, { useEffect, useState, useRef } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Image as NoneImage } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import AdminMessage from '../../newDashboard/chatTab/chatTabNewComponents/AdminMessage';
import RiskScore from './RiskScore';
import male_user from '../../../../assets/Images/adminSidebar/male_user.png';
import female_user from '../../../../assets/Images/adminSidebar/female_user.png';
import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { api } from '../../../../api';
import Media from '../../../../components/media/Media';
import '../../admincomponents.scss';
import moment from 'moment';

export const ChatHistory = (props) => {
  const { user } = props;
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [queue, setQueue] = useState([]);
  const [chattingUser, setChattingUser] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(true);
  const [card, setCard] = useState(null);
  const [msgUser, setMsgUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [endQueue, setEndQueue] = useState(false);

  const bottomRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (chattingUser !== null) {
      setLoadingMessage(true);
      fetchMessage();
    }
  }, [chattingUser]);

  useEffect(() => {
    if (messages.length > 0) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  useEffect(() => {
    if (skip !== 0) {
      setLoading(true);
      fetchData();
    }
  }, [skip]);

  const fetchData = () => {
    const data = {
      userid: user._id,
      skip,
    };
    return api
      .fetchChatQueue(data)
      .then((res) => {
        const { messages } = res.data;
        const temp = queue.concat(messages);
        setQueue(temp);
        if (temp.length !== 0) {
          setChattingUser(0);
        } else {
          setLoadingMessage(false);
        }
        if (messages.length < 12) {
          setEndQueue(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMessage = () => {
    const data = {
      userid: user._id,
      chatid: queue[chattingUser].user._id,
      skip: 0,
    };

    return api.fetchMessages(data).then((res) => {
      const { messages, card, user } = res.data;
      setCard(card);
      setMsgUser(user);
      setMessages(messages);
      setLoadingMessage(false);
    });
  };

  const handleScroller = () => {
    if (!endQueue) {
      setSkip(skip + 12);
    }
  };

  const scrollRef = useBottomScrollListener(handleScroller);

  return (
    <div className="chat-history">
      <div className="chat-message-queue">
        <div className="chat-history-header">
          {user.imgurl[0] ? (
            <Media image={user.imgurl[0]} />
          ) : (
            // <Image
            //   publicId={getImgName(user.imgurl[0])}
            //   version={getImgVersion(user.imgurl[0])}
            //   resource_type={checkGIF(user.imgurl[0]) ? "video" : "image"}
            //   effect="loop"
            //   cloud_name="sugarbae"
            //   className="img-userinfo"
            // >
            //   <Transformation
            //     height="200"
            //     video_sampling="20"
            //     delay="250"
            //     duration="10"
            //     effect="loop"
            //   />
            // </Image>
            <div className="none-image-container">
              <NoneImage
                className="none-user-image"
                src={user.gender === 'male' ? male_user : female_user}
              />
            </div>
          )}
          <div className="userinfo-top">
            <p className="userinfo-username">{user.username}</p>
          </div>
          <div className="fetch-loading-spinner">
            <ClipLoader size={10} color={'#FFF'} loading={loading} />
          </div>
          <div
            className="chat-history-close"
            onClick={() => props.closeHandler()}
          >
            <span className="icon icon-close"></span>
          </div>
        </div>
        <div className="chat-queue-body" ref={scrollRef}>
          {queue.length !== 0 &&
            queue.map((message, index) => {
              const chattingUser = message.user;
              return (
                <div
                  className="queue-option"
                  onClick={() => setChattingUser(index)}
                >
                  {chattingUser.imgurl[0] ? (
                    <Media image={chattingUser.imgurl[0]} />
                  ) : (
                    // <Image
                    //   publicId={getImgName(chattingUser.imgurl[0])}
                    //   version={getImgVersion(chattingUser.imgurl[0])}
                    //   resource_type={
                    //     checkGIF(chattingUser.imgurl[0]) ? 'video' : 'image'
                    //   }
                    //   effect="loop"
                    //   cloud_name="sugarbae"
                    //   className="img-userinfo"
                    // >
                    //   <Transformation
                    //     height="200"
                    //     video_sampling="20"
                    //     delay="250"
                    //     duration="10"
                    //     effect="loop"
                    //   />
                    // </Image>
                    <div className="none-image-container">
                      <NoneImage
                        className="none-user-image"
                        src={
                          chattingUser.gender === 'male'
                            ? male_user
                            : female_user
                        }
                      />
                    </div>
                  )}
                  <div className="queue-info">
                    <p className="queue-option-name">{chattingUser.username}</p>
                    <br />
                    <p className="queue-option-message">{message.message}</p>
                  </div>
                  <div className="queue-date-part">
                    <p className="queue-date-">
                      {moment(message.createdAt).format('L')}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="chat-message-history">
        <div className="chat-message-loading-spinner">
          <ClipLoader size={10} color={'#FFF'} loading={loadingMessage} />
        </div>
        {messages.map((message, index) => {
          let timeStamp = false;
          let sent = false;

          if (message.senderusername !== card.username) {
            sent = true;
          }

          if (index > 0) {
            const prevIndex = index - 1;
            const prevMessage = messages[prevIndex];
            const date = moment(message.createdAt);
            const prevDate = moment(prevMessage.createdAt);
            const diff = date.diff(prevDate, 'hours');

            if (diff > 5) {
              timeStamp = true;
            }
          }

          const data = {
            timeStamp,
            sent,
          };
          return (
            <AdminMessage
              message={message}
              data={data}
              user={msgUser}
              card={card}
            />
          );
        })}
        <div ref={bottomRef} />
      </div>
      {chattingUser !== null && (
        <div className="chat-partner-profile">
          <div className="userinfo-drawer-header">
            {queue[chattingUser] && queue[chattingUser].user.imgurl[0] ? (
              <Media image={chattingUser.user.imgurl[0]} />
            ) : (
              // <Image
              //   publicId={getImgName(queue[chattingUser].user.imgurl[0])}
              //   version={getImgVersion(queue[chattingUser].user.imgurl[0])}
              //   resource_type={
              //     checkGIF(queue[chattingUser].user.imgurl[0])
              //       ? 'video'
              //       : 'image'
              //   }
              //   effect="loop"
              //   cloud_name="sugarbae"
              //   className="img-userinfo"
              // >
              //   <Transformation
              //     height="200"
              //     video_sampling="20"
              //     delay="250"
              //     duration="10"
              //     effect="loop"
              //   />
              // </Image>
              <div className="none-image-container">
                <NoneImage
                  className="none-user-image"
                  src={
                    queue[chattingUser].user.gender === 'male'
                      ? male_user
                      : female_user
                  }
                />
              </div>
            )}
            <div className="userinfo-top">
              <p className="userinfo-username">
                {queue[chattingUser].user.username}
              </p>
              <div className="userinfo-riskscore">
                <p className="userinfo-riskscore-title">Risk Score:</p>
                <RiskScore
                  riskScore={
                    (queue[chattingUser].user.risk &&
                      Math.trunc(queue[chattingUser].user.risk.score * 100)) ||
                    0
                  }
                />
              </div>
            </div>
          </div>
          <div className="chatting-user-info">
            <div className="accountinfo-detail-option">
              <div className="detail-option-container">
                <span className="icon icon-account-username"></span>
              </div>
              <div className="account-detail-description">
                <p className="account-detail-subtitle">Username:</p>
                <p className="account-detail-content">
                  {queue[chattingUser].user.username}
                </p>
              </div>
            </div>
            <div className="accountinfo-detail-option">
              <div className="detail-option-container">
                <span className="icon icon-account-email"></span>
              </div>
              <div className="account-detail-description">
                <p className="account-detail-subtitle">Email:</p>
                <p className="account-detail-content">
                  {queue[chattingUser].user.email}
                </p>
              </div>
            </div>
            <div className="accountinfo-detail-option">
              <div className="detail-option-container">
                <span className="icon icon-user-home"></span>
              </div>
              <div className="account-detail-description">
                <p className="account-detail-subtitle">Address:</p>
                <p className="account-detail-content">
                  {queue[chattingUser].user.address}
                </p>
              </div>
            </div>
            <div className="accountinfo-detail-option">
              <div className="detail-option-container">
                <span className="icon icon-user-gender"></span>
              </div>
              <div className="account-detail-description">
                <p className="account-detail-subtitle">Gender:</p>
                <p className="account-detail-content">
                  {queue[chattingUser].user.gender === 'male'
                    ? 'Male'
                    : 'Female'}
                </p>
              </div>
            </div>
            <div className="accountinfo-detail-option">
              <div className="detail-option-container">
                <span className="icon icon-user-age"></span>
              </div>
              <div className="account-detail-description">
                <p className="account-detail-subtitle">Age:</p>
                <p className="account-detail-content">
                  {queue[chattingUser].user.age}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatHistory;
