import React, { useEffect, useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import { withRouter, Route, Switch } from "react-router-dom";
import "./newdashboard.scss";
import Sidebar from "../../admincomponents/newDashboard/Sidebar";
import ChatTab from "../../admincomponents/newDashboard/chatTab/ChatTabNew";
import UsersTab from "../../admincomponents/newDashboard/usersTab/UsersTab";
import ImagesTab from "../../admincomponents/newDashboard/imagesTab.js/ImagesTab";
import HomeTab from "../../admincomponents/newDashboard/homeTab/HomeTab";
import BotsTab from "../../admincomponents/newDashboard/botsTab/BotsTab";
import ReportTab from "../../admincomponents/newDashboard/reportTab/ReportTab";

function NewDashboard(props) {
  const context = useContext(UserContext);

  useEffect(() => {
    if (!context.user.isAdmin) {
      props.history.push("/app");
    }
    return () => {};
  }, []);

  const tabChangeHandler = (e) => {
    if (e === "users") {
      props.history.push("/app/admin/users");
    } else if (e === "chat") {
      props.history.push("/app/admin/chats");
    } else if (e === "images") {
      props.history.push("/app/admin/images");
    } else if (e === "home") {
      props.history.push("/app/admin");
    } else if (e === "bots") {
      props.history.push("/app/admin/bots");
    } else if (e === "reports") {
      props.history.push("/app/admin/reports");
    }
  };

  return (
    <div className="dashboard-component">
      <div className="dashboard-sidebar">
        <Sidebar tabChange={tabChangeHandler} />
      </div>
      
      <div className="dashboard-main-area">
        <Switch>
          <Route
            path={`${props.match.path}`}
            exact
            render={() => (
                <HomeTab />
            )}
          />
          <Route
            path={`${props.match.path}/users`}
            exact
            render={() => (
                <UsersTab />
            )}
          />
          <Route
            path={`${props.match.path}/chats`}
            exact
            render={() => (
                <ChatTab />
            )}
          />
          <Route
            path={`${props.match.path}/images`}
            exact
            render={() => (
                <ImagesTab />
            )}
          />
          <Route
            path={`${props.match.path}/reports`}
            exact
            render={() => (
                <ReportTab />
            )}
          />
          <Route
            path={`${props.match.path}/bots`}
            exact
            render={() => (
                <BotsTab />
            )}
          />
        </Switch>
      </div>
    </div>
  );
}

export default withRouter(NewDashboard);
