import React, { useState, useEffect, useContext, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import "../supportcomponents.scss";
import { UserContext } from "../../../../context/UserContext";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { api } from "../../../../api";

function ReportUser(props) {
  const context = useContext(UserContext);
  const fileInput = useRef(null);

  const [reportDescription, setReportDescription] = useState("");
  const [reportCategory, setReportCategory] = useState("");
  const [username, setUsername] = useState("");
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);

  const [successfulSubmit, setSuccessfulSubmit] = useState(false);

  var imageTypes = [
    "image/webp",
    "image/heif",
    "image/heic",
    "image/jpg",
    "image/jpe",
    "image/jpeg",
    "image/png",
    "image/pdf",
    "image/jp2",
    "image/wdp",
    "image/jxr",
    "image/hdp",
    "image/svg",
    "image/tif",
    "image/tiff",
  ];

  var videoTypes = [
    "image/gif",
    "video/flv",
    "video/avi",
    "video/m3u8",
    "video/ts",
    "video/m2ts",
    "video/mts",
    "video/mov",
    "video/mkv",
    "video/mp4",
    "video/mpeg",
    "video/mpd",
    "video/webm",
    "video/wmv",
  ];

  const descriptionHandler = (e) => {
    setReportDescription(e.target.value);

    if (e.target.value.length > 10) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const data = {
      userid: context.user._id,
      reportDescription: reportDescription,
      images: images,
      reportCategory: reportCategory,
      username: username,
    };

    return api
      .submitUserReport(data)
      .then((res) => {
        if (res.status === 200) {
          setSuccessfulSubmit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fileChangeHandler = async (e) => {
    const files = e.target.files;
    const filesArray = [];
    for (const key of Object.keys(files)) {
      filesArray.push(files);
    }
    const length = filesArray.length;
    var count = 0;
    setUploading(true);

    const uploaders = filesArray.map(async (file, index) => {
      var fd = new FormData();
      var filetype = file[index].type;
      var url = "";
      fd.append("file", file[index]);
      fd.append("api_key", "323661161668594");
      fd.append("timestamp", (Date.now() / 1000) | 0);

      if (imageTypes.includes(filetype)) {
        url = "https://api.cloudinary.com/v1_1/sugarbae/image/upload";
        fd.append("upload_preset", "txv5v2xj");
      }

      if (videoTypes.includes(filetype)) {
        url = "https://api.cloudinary.com/v1_1/sugarbae/video/upload";
        fd.append("upload_preset", "om70jgxq");
      }

      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
        },
      };

      await Axios.post(url, fd, options, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      })
        .then(async (res) => {
          count = count + 1;
          var imgurl = res.data.secure_url;
          if (videoTypes.includes(filetype)) {
            imgurl = imgurl.slice(0, -3);
            imgurl = imgurl + "gif";
            const firstHalf = imgurl.slice(0, 49);
            const secondHalf = imgurl.slice(49);
            const settings = "vs_20,dl_250,h_200,e_loop,du_10/";
            const fullUrl = firstHalf + settings + secondHalf;
            imgurl = fullUrl.slice(0, -3) + "gif";
          }

          setImages((prevImages) => [...prevImages, imgurl]);

          if (count === length) {
            setUploading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setUploading(false);
        });
    });
  };

  const reportCategoryHandler = (e) => {
    setReportCategory(e.target.value);
  };

  const usernameHandler = (e) => {
    setUsername(e.target.value);
  };

  if (successfulSubmit) {
    return (
      <Form className="support-form">
        <div className="support-form-success-wrapper">
          <h4 className="support-form-success-title">Report submitted.</h4>
          <p className="support-form-success-subtitle">
            Thanks for your feedback. We will investigate this user ASAP.
          </p>
          <div className="support-form-success-icon-wrapper">
            <i className="fas fa-check-circle support-form-success-icon"></i>
          </div>
        </div>
      </Form>
    );
  } else {
    return (
      <Form onSubmit={submitHandler} className="support-form">
        {uploading && (
          <div className="support-form-uploading-overlay">
            <ClipLoader color="#fff" />
          </div>
        )}
        <Form.Group className="support-form-group">
          <Form.Control as="select" onChange={reportCategoryHandler}>
            <option value="" disabled selected hidden>
              Reason for Reporting
            </option>{" "}
            <option>Scammer</option>
            <option>Fake Profile</option>
            <option>Under Age</option>
            <option>Rude / Agressive</option>
            <option>Illegal Activity</option>
            <option>Other</option>
          </Form.Control>
        </Form.Group>
        <Form.Group className="support-form-group">
          <Form.Label className="support-form-label">Username:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Other user's username"
            value={username}
            onChange={usernameHandler}
          />
        </Form.Group>
        <Form.Group className="support-form-group">
          <Form.Label className="support-form-label">Details:</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            value={reportDescription}
            className="support-form-input"
            placeholder="Describe the issue here and upload photos / screenshots below"
            onChange={descriptionHandler}
          />
        </Form.Group>
        <Form.Group className="support-form-group">
          <label for="support-file-input" className="support-file-input-label">
            <input
              type="file"
              multiple
              className="support-file-input"
              name="support-file-input"
              onChange={fileChangeHandler}
              id="support-file-input"
              ref={fileInput}
              accept=".gif,.webp,.heif,.heic,.jpg,.jpe,.jpeg,.png,.pdf,.jp2,.wdp,.jxr,.hdp,,.svg,.tif,.tiff,.flv,.avi,.m3u8,.ts,.m2ts,.mts,.mov,.mkv,.mp4,.mpeg,mpd,.webm,.wmv"
            />
          </label>
        </Form.Group>
        <Button
          type="submit"
          className="support-form-submit-button"
          disabled={submitButtonDisabled}
        >
          Report User
        </Button>
      </Form>
    );
  }
}

export default ReportUser;
