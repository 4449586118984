import React, { useState, useEffect, useContext, useRef } from 'react';
import './desktopswipev2.scss';
import { Image, Transformation } from 'cloudinary-react';
import { Image as BootstrapImage } from 'react-bootstrap';
import * as Scroll from 'react-scroll';
import ClipLoader from 'react-spinners/ClipLoader';
import { UserContext } from '../../../../context/UserContext';

import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import About from '../../../profile/profileV2Components/about/About';
import LookingFor from '../../../profile/profileV2Components/lookingFor/LookingFor';
import Verifications from '../../../profile/profileV2Components/verifications/Verifications';
import Socials from '../../../profile/profileV2Components/socials/Socials';
import PhotoGridV2 from '../../../photoGrid/PhotoGridV2';
import verificationIcon from '../../../../assets/Icons/badge_verification.svg';
import DesktopSwipeActions from './DesktopSwipeActions';
import {
  submitDislike,
  submitLike,
  submitUndo,
} from '../../../../helpers/swipeActions';
import ComplimentModal from '../../../modals/complimentModal/ComplimentModal';
import PremiumModal from '../../../modals/premiumModal/PremiumModal';
import Media from '../../../../components/media/Media';

function DesktopSwipeV2(props) {
  const context = useContext(UserContext);
  const [likeStampOpen, setLikeStampOpen] = useState(false);
  const [dislikeStampOpen, setDislikeStampOpen] = useState(false);
  const [undoStampOpen, setUndoStampOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [likeInvalid, setLikeInvalid] = useState(false);
  const [matchOpen, setMatchOpen] = useState(false);
  const [matchUser, setMatchUser] = useState(null);
  const [lastAction, setLastAction] = useState(null);
  const [undoDisabled, setUndoDisabled] = useState(true);
  const [undoInvalid, setUndoInvalid] = useState(false);
  const [complimentOpen, setComplimentOpen] = useState(false);

  const profileRef = useRef(null);

  const { swipeData } = context;
  const { setSwipeIndex } = context;
  const { cards } = swipeData;
  const { index } = swipeData;
  const { loading } = swipeData;

  const { Element } = Scroll;

  const card = cards[index];
  let image = null;
  let count = 0;
  if (card) {
    const images = card.imgurl;
    image = images[0];
    count = images.length;
  }

  let verified = true;

  if (!loading) {
    if (card.verifications.email.status && card.verifications.phone.status) {
      verified = true;
    }
  }

  const handleUndo = async (e) => {
    const tempCard = card;
    const cardid = tempCard._id;
    const newIndex = index - 1;

    setUndoStampOpen(true);
    setSubmitting(true);

    const undo = await submitUndo(context.user._id, cardid).catch((err) => {
      console.log(err);
    });

    const { valid } = undo;
    const { match } = undo;
    if (valid) {
      if (lastAction === 'like') {
        const tempLikedUsers = context.likedUsers;
        const indexB = tempLikedUsers.indexOf(cardid);
        if (indexB > -1) {
          tempLikedUsers.splice(indexB, 1);
          context.updateLikedUsers(tempLikedUsers);
        }
      }
      setSwipeIndex(newIndex);
      setUndoDisabled(true);
    } else {
      setUndoInvalid(true);
    }

    setUndoStampOpen(false);
    setSubmitting(false);
  };

  const handleLike = async (e) => {
    const tempCard = card;
    const cardid = tempCard._id;
    const newIndex = index + 1;
    setLikeStampOpen(true);
    setSubmitting(true);

    const like = await submitLike(context.user._id, cardid).catch((err) => {
      console.log(err);
    });

    const { valid } = like;
    const { match } = like;
    if (valid) {
      if (match) {
        setMatchOpen(true);
        setMatchUser(tempCard);
      }

      if (newIndex !== card.length) {
        setLastAction('like');
        setUndoDisabled(false);
      }
      setSwipeIndex(newIndex);
      const tempLikedUsers = context.likedUsers;
      if (!tempLikedUsers.includes(tempCard._id)) {
        tempLikedUsers.push(tempCard._id);
        context.updateLikedUsers(tempLikedUsers);
      }
    } else {
      setLikeInvalid(true);
    }

    setLikeStampOpen(false);
    setSubmitting(false);
  };

  const handleDislike = async (e) => {
    const tempCard = card;
    const cardid = tempCard._id;
    const newIndex = index + 1;

    setDislikeStampOpen(true);
    setSubmitting(true);

    await submitDislike(context.user._id, cardid);

    if (newIndex !== card.length) {
      setLastAction('dislike');
      setUndoDisabled(false);
    }
    setSwipeIndex(newIndex);
    const tempLikedUsers = context.likedUsers;
    const indexB = tempLikedUsers.indexOf(cardid);
    if (indexB > -1) {
      tempLikedUsers.splice(indexB, 1);
      context.updateLikedUsers(tempLikedUsers);
    }

    setDislikeStampOpen(false);
    setSubmitting(false);
  };

  const handleCompliment = (e) => {
    setComplimentOpen(true);
  };

  const modalToggle = (e) => {
    setComplimentOpen(false);
  };

  const sentComplimentHandler = (e) => {
    const newIndex = index + 1;
    setSwipeIndex(newIndex);
  };

  if (loading) {
    return (
      <div className="desktop-swipe-v2">
        <div className="desktop-swipe-v2-innerwrap">
          <div className="desktop-swipe-v2-loading-indicator">
            <ClipLoader size={18} color="#b5b5b5" />
          </div>
          <div className="desktop-swipe-v2-image-container-placeholder" />
          <div className="desktop-swipe-v2-profile-container-placeholder" />
        </div>
      </div>
    );
  }
  return (
    <div className="desktop-swipe-v2">
      {undoInvalid && (
        <PremiumModal
          option="undo"
          onClose={() => setUndoInvalid(false)}
          show={undoInvalid}
        />
      )}
      {complimentOpen && (
        <ComplimentModal
          show={complimentOpen}
          modalToggle={modalToggle}
          profileUser={card}
          success={sentComplimentHandler}
        />
      )}
      <div className="desktop-swipe-v2-innerwrap">
        <div className="desktop-swipe-v2-image-container">
          <div className="search-user-card-image-count-wrapper">
            <i className="far fa-camera image-count-camera-icon" />
            <span className="search-user-card-image-count">
              {count.toString()}
            </span>
          </div>

          <Media image={image} />
          {/* <Image
            publicId={
              image
                ? getImgName(image)
                : "larger-placeholder-user-image_vvdghq.png"
            }
            version={image ? getImgVersion(image) : "1590046357"}
            resource_type={image ? (checkGIF(image) ? "video" : "image") : null}
            effect="loop"
            cloud_name="sugarbae"
            className="desktop-main-image"
            fluid
          >
            <Transformation
              height="200"
              video_sampling="20"
              delay="250"
              duration="10"
              effect="loop"
              quality="auto"
              flags="lossy"
            />
          </Image> */}
          <DesktopSwipeActions
            handleLike={handleLike}
            handleDislike={handleDislike}
            handleCompliment={handleCompliment}
            handleUndo={handleUndo}
            undoDisabled={undoDisabled}
          />
        </div>
        <div className="desktop-swipe-v2-profile-container" ref={profileRef}>
          <div className="desktop-swipe-v2-profile-info">
            <div className="desktop-swipe-v2-profile-title-wrapper">
              {verified && (
                <BootstrapImage
                  src={verificationIcon}
                  className="verification-icon-swipe-v2-desktop"
                  fluid
                />
              )}
              <h1 className="desktop-swipe-v2-profile-title">
                {card.username},{' '}
                <span className="desktop-swipe-v2-profile-age">{card.age}</span>
              </h1>
            </div>
            <div>
              <p className="desktop-swipe-v2-profile-address">{card.address}</p>
            </div>
          </div>
          <About profileUser={card} />
          <LookingFor profileUser={card} />
          <PhotoGridV2 profileUser={card} />
          {/* <Verifications profileUser={card} />
          <Socials profileUser={card} /> */}
        </div>
      </div>
    </div>
  );
}

export default DesktopSwipeV2;
