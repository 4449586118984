import React, { useState, useContext, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import EmailVerifyV2 from "./EmailVerifyV2";
import PhoneVerifyV2 from "./PhoneVerifyV2";
import { UserContext } from "../../context/UserContext";
import IdVerify from "./IdVerify";
import "./verification.scss";

function VerificationModal(props) {
  const context = useContext(UserContext);
  const history = useHistory();
  const [verifications, setVerifications] = useState({
    email: context.user.verifications.email.status,
    phone: context.user.verifications.phone.status,
    id: context.user.verifications.id.status,
  });
  const [emailOpen, setEmailOpen] = useState(false);
  const [phoneOpen, setPhoneOpen] = useState(false);
  const [idOpen, setIdOpen] = useState(false);

  useEffect(() => {}, []);

  const emailSuccessHandler = (e) => {
    const tempVerifications = verifications;
    tempVerifications.email = true;

    setVerifications((prevState) => ({ ...prevState, ...tempVerifications }));

    setEmailOpen(false);
  };

  const phoneSuccessHandler = (e) => {
    const tempVerifications = verifications;
    tempVerifications.phone = true;

    setVerifications((prevState) => ({ ...prevState, ...tempVerifications }));

    setPhoneOpen(false);
  };

  const idSuccessHandler = (e) => {
    const tempVerifications = verifications;
    tempVerifications.id = true;

    setVerifications((prevState) => ({ ...prevState, ...tempVerifications }));

    setIdOpen(false);
  };

  const advanceHandler = (e) => {
    if (!verifications.email) {
      setEmailOpen(true);
    } else if (!verifications.phone) {
      setPhoneOpen(true);
    } else if (!verifications.id) {
      setIdOpen(true);
    }
  };

  const emailToggle = (e) => {
    if (emailOpen) {
      setEmailOpen(false);
    } else {
      setEmailOpen(true);
    }
  };

  const phoneToggle = (e) => {
    if (phoneOpen) {
      setPhoneOpen(false);
    } else {
      setPhoneOpen(true);
    }
  };

  const idToggle = (e) => {
    if (idOpen) {
      setIdOpen(false);
    } else {
      setIdOpen(true);
    }
  };

  const closeHandler = (e) => {
    if (props.desktop) {
      props.closeHandler();
    } else if (emailOpen || phoneOpen || idOpen) {
      setEmailOpen(false);
      setPhoneOpen(false);
      setIdOpen(false);
    } else {
      history.goBack();
    }
  };

  let showNextButton = false;
  if (verifications.email || verifications.phone || verifications.id) {
    showNextButton = true;
  }
  if (!props.show) {
    return null;
  }
  return (
    <div className="desktop-v2-filters">
      <div className="desktop-v2-filters-container">
        <div
          className="desktop-v2-filters-popup"
          style={{ position: "relative" }}
        >
          {emailOpen && (
            <div className="verification-component">
              <div className="verification-header">
                <h2 className="verification-pre-title">Confirm</h2>
                <h1 className="verification-title">Your Email</h1>
                <p className="verification-subtitle">
                  Help us keep Sugarbae safe by verifying your email address.
                </p>
                <Button
                  className="verification-header-close-button"
                  variant="link"
                  onClick={closeHandler}
                >
                  <i className="fal fa-times" />
                </Button>
              </div>
              <div className="verification-content">
                <EmailVerifyV2 handleSuccess={emailSuccessHandler} />
              </div>
            </div>
          )}
          {phoneOpen && (
            <div className="verification-component">
              <div className="verification-header">
                <h2 className="verification-pre-title">Confirm</h2>
                <h1 className="verification-title">Your Phone Number</h1>
                <p className="verification-subtitle">
                  Help us keep Sugarbae safe by verifying your phone number.
                </p>
                <Button
                  className="verification-header-close-button"
                  variant="link"
                  onClick={closeHandler}
                >
                  <i className="fal fa-times" />
                </Button>
              </div>
              <div className="verification-content">
                <PhoneVerifyV2 handleSuccess={phoneSuccessHandler} />
              </div>
            </div>
          )}
          {idOpen && (
            <div className="verification-component">
              <div className="verification-header">
                <h2 className="verification-pre-title">Confirm</h2>
                <h1 className="verification-title">Your Identity</h1>
                <p className="verification-subtitle">
                  Help us keep Sugarbae safe by verifying your personal
                  information.
                </p>
                <Button
                  className="verification-header-close-button"
                  variant="link"
                  onClick={closeHandler}
                >
                  <i className="fal fa-times" />
                </Button>
              </div>
              <div className="verification-content-scroll">
                <IdVerify
                  handleSuccess={idSuccessHandler}
                  toggleClose={() => {
                    setIdOpen(false);
                  }}
                />
              </div>
            </div>
          )}
          {!idOpen && !phoneOpen && !emailOpen && (
            <div className="verification-component">
              <div className="verification-header">
                <h2 className="verification-pre-title">Be safe</h2>
                <h1 className="verification-title">Verify yourself</h1>
                <p className="verification-subtitle">
                  Help us keep Sugarbae safe by verifying your email, phone
                  number, and personal information.
                </p>
                <Button
                  className="verification-header-close-button"
                  variant="link"
                  onClick={closeHandler}
                >
                  <i className="fal fa-times" />
                </Button>
              </div>
              <div className="verification-content">
                <div className="verification-item" onClick={emailToggle}>
                  <div className="verification-item-icon">
                    <i
                      className={
                        verifications.email
                          ? "far fa-check-circle verification-icon-verified"
                          : "far fa-envelope verification-icon"
                      }
                    />
                  </div>
                  <div className="verification-item-content">
                    <h1 className="verification-item-title">Email Address</h1>
                    <p
                      className={
                        verifications.email
                          ? "verification-item-subtitle-verified"
                          : "verification-item-subtitle"
                      }
                    >
                      {verifications.email ? "Verified" : "Required"}
                    </p>
                  </div>
                  <div className="verification-item-action" />
                </div>
                <div className="verification-item" onClick={phoneToggle}>
                  <div className="verification-item-icon">
                    <i
                      className={
                        verifications.phone
                          ? "far fa-check-circle verification-icon-verified"
                          : "far fa-phone verification-icon"
                      }
                    />
                  </div>
                  <div className="verification-item-content">
                    <h1 className="verification-item-title">Phone Number</h1>
                    <p
                      className={
                        verifications.phone
                          ? "verification-item-subtitle-verified"
                          : "verification-item-subtitle"
                      }
                    >
                      {verifications.phone ? "Verified" : "Required"}
                    </p>
                  </div>
                  <div className="verification-item-action" />
                </div>
                <div className="verification-item" onClick={idToggle}>
                  <div className="verification-item-icon">
                    <i
                      className={
                        verifications.id
                          ? "far fa-check-circle verification-icon-verified"
                          : "far fa-id-badge verification-icon"
                      }
                    />
                  </div>
                  <div className="verification-item-content">
                    <h1 className="verification-item-title">
                      Identity Verification
                    </h1>
                    <p
                      className={
                        verifications.id
                          ? "verification-item-subtitle-verified"
                          : "verification-item-subtitle"
                      }
                    >
                      {verifications.id ? "Verified" : "Required"}
                    </p>
                  </div>
                  <div className="verification-item-action" />
                </div>
              </div>
              <div className="verification-footer">
                <Button
                  className="verification-footer-button"
                  variant="danger"
                  onClick={advanceHandler}
                >
                  {showNextButton ? "Continue" : "Get Started"}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div
          className="desktop-v2-filters-overlay"
          onClick={() => {
            console.log("props close");
            props.closeHandler();
          }}
        />
      </div>
    </div>
  );
}

export default withRouter(VerificationModal);
