import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button, Form, Alert, Container, Card } from "react-bootstrap";
import Axios from "axios";
import resetPasswordBanner from "../../../assets/Images/Reset Password/reset-password-banner.jpg";
import { Link } from "react-router-dom";
import "./newpassword.scss";
import EmailValidator from "email-validator";
import ClipLoader from "react-spinners/ClipLoader";
import PasswordValidator from "password-validator";
import { api } from "../../../api";

var passwordSchema = new PasswordValidator();

var passwordCriteria = passwordSchema
  .is()
  .min(6)
  .is()
  .max(100)
  .has()
  .not()
  .spaces();

function NewPassword(props) {
  const [loading, setLoading] = useState(true);
  const [tokenValid, setTokenValid] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [passwordMatchError, setPasswordMatchError] = useState(false);

  const [successfulReset, setSuccessfulReset] = useState(false);
  const [resetError, setResetError] = useState(false);

  useEffect(() => {
    console.log(props.token);
    validateResetToken();
  }, []);

  const validateResetToken = async (e) => {
    const data = {
      token: props.token,
    };

    await api
      .validateResetToken(data)
      .then((res) => {
        if (res.status === 200) {
          setTokenValid(true);
          setLoading(false);
        } else {
          setTokenValid(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(true);
        setTokenValid(false);
        setLoading(false);
      });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    if (password === confirmPassword) {
      setPasswordMatchError(false);
      var passwordValid = passwordCriteria.validate(password);

      if (passwordValid) {
        setPasswordInvalid(false);

        const data = {
          password: password,
          token: props.token,
        };

        return api
          .submitNewPassword(data)
          .then((res) => {
            if (res.status === 200) {
              setSuccessfulReset(true);
            }
          })
          .catch((err) => {
            setResetError(true);
            setSubmitDisabled(false);
          });
      } else {
        setPasswordInvalid(true);
        setSubmitDisabled(false);
      }
    } else {
      setPasswordMatchError(true);
      setSubmitDisabled(false);
    }
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);

    if (e.target.value.length > 5 && confirmPassword.length > 5) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  const confirmPasswordChangeHandler = (e) => {
    setConfirmPassword(e.target.value);

    if (password.length > 5 && e.target.value.length > 5) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  };

  if (loading) {
    return (
      <div className="reset-password-section">
        <Container className="reset-password-container">
          <Card className="reset-password-form-card">
            <Card.Img
              variant="top"
              className="reset-password-form-top-img"
              src={resetPasswordBanner}
            />
            <Card.Title className="reset-password-card-title">
              <span className="reset-password-title-span">New Password</span>
            </Card.Title>
            <Card.Body className="reset-password-form-card-body">
              <ClipLoader />
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  } else if (tokenValid) {
    return (
      <div className="reset-password-section">
        <Container className="reset-password-container">
          {successfulReset ? (
            <Card className="reset-password-form-card">
              <Card.Img
                variant="top"
                className="reset-password-form-top-img"
                src={resetPasswordBanner}
              />

              <Card.Body className="reset-password-form-card-body">
                <Alert variant="success">
                  You have successfully updated your password. Please login with
                  your new password by clicking the button below.
                </Alert>
                <Link to="/login">
                  <Button className="new-password-form-btn" size="lg">
                    Login
                  </Button>
                </Link>
              </Card.Body>
            </Card>
          ) : (
            <Card className="reset-password-form-card">
              <Card.Img
                variant="top"
                className="reset-password-form-top-img"
                src={resetPasswordBanner}
              />
              <Card.Title className="reset-password-card-title">
                <span className="reset-password-title-span">New Password</span>
              </Card.Title>
              <Card.Body className="reset-password-form-card-body">
                {passwordMatchError && (
                  <Alert variant="danger">
                    Error: Password's do not match.
                  </Alert>
                )}

                {passwordInvalid && (
                  <Alert variant="danger">
                    Password must be 6-100 characters with no spaces.
                  </Alert>
                )}
                <Form onSubmit={submitHandler} className="new-password-form">
                  <Form.Group controlId="newpassword">
                    <Form.Label className="new-password-form-label">
                      Create Your New Password.
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="New password"
                      isInvalid={passwordInvalid}
                      onChange={passwordChangeHandler}
                      className="new-password-form-input"
                      size="lg"
                    />
                  </Form.Group>
                  <Form.Group controlId="newpassword-confirm">
                    <Form.Label>Confirm Your New Password.</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm your new password"
                      isInvalid={passwordInvalid}
                      onChange={confirmPasswordChangeHandler}
                      className="new-password-form-input"
                      size="lg"
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className="new-password-form-btn"
                    disabled={submitDisabled}
                    size="lg"
                  >
                    Create New Password
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          )}
        </Container>
      </div>
    );
  } else {
    return (
      <div className="reset-password-section">
        <Container className="reset-password-container">
          <Card className="reset-password-form-card">
            <Card.Img
              variant="top"
              className="reset-password-form-top-img"
              src={resetPasswordBanner}
            />
            <Card.Title className="reset-password-card-title">
              <span className="reset-password-title-span">New Password</span>
            </Card.Title>
            <Card.Body className="reset-password-form-card-body">
              <Alert variant="danger">
                Your password reset link expired or is invalid.
              </Alert>
            </Card.Body>
            <div className="login-below-form-container">
              <p className="lp-signup-text">
                <Link to="/reset-password" className="lp-signup-link">
                  Resend Password Reset Email
                </Link>
              </p>
              <p className="lp-signup-text">
                Having difficulties?{" "}
                <Link to="/contact" className="lp-signup-link">
                  Contact Support
                </Link>
              </p>
            </div>
          </Card>
        </Container>
      </div>
    );
  }
}

export default withRouter(NewPassword);
