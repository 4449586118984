import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Video from 'twilio-video';
import { Button } from 'react-bootstrap';
import { Image as BootstrapImage } from 'react-bootstrap';
import { motion } from 'framer-motion';

import { UserContext } from '../../context/UserContext';
import { api, socket } from '../../api';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../utils/getCloudinaryInfo';
import logo from '../../assets/logos/icon-only-white.png';
import Media from '../../components/media/Media';

import './videoRoom.scss';

export const VideoRoom = (props) => {
  const context = useContext(UserContext);
  const { roomData } = props;
  const [showVideo, setShowVideo] = useState(false);
  const [showWaiting, setShowWaiting] = useState(false);
  const [callRequest, setCallRequest] = useState(false);
  const [caller, setCaller] = useState({});
  const [callerImage, setCallerImage] = useState(null);
  const [roomName, setRoomName] = useState('');
  const [room, setRoom] = useState(null);
  const [tracks, setTracks] = useState(null);

  const location = useLocation();
  const pathName = location.pathname;
  const checkUrl = pathName.includes('/app/messages');

  useEffect(() => {
    if (roomData.receiver) {
      videoCallHandler();
    } else if (roomData.rejected) {
      setShowWaiting(false);
    } else if (roomData.roomName) {
      setCaller(roomData.caller);
      if (roomData.caller.imgurl && roomData.caller.imgurl.length !== 0) {
        setCallerImage(roomData.caller.imgurl[0]);
      } else {
        setCallerImage(
          'https://res.cloudinary.com/sugarbae/image/upload/v1590045309/placeholder-user-image-thumbnail_vankru.png'
        );
      }
      setRoomName(roomData.roomName);
      setCallRequest(true);
    }
  }, [roomData]);

  const videoCallHandler = async () => {
    setShowWaiting(true);
    let videoInfo = null;
    let token = null;
    await api
      .makeVideoCall()
      .then((res) => {
        videoInfo = res.data.room;
        token = res.data.token;
      })
      .catch((err) => {
        console.log(err);
      });
    const { uniqueName } = videoInfo;
    const data = {
      receiver: roomData.receiver,
      caller: context.user,
      roomName: uniqueName,
    };
    await Video.connect(token, { name: uniqueName })
      .then((room) => {
        console.log('connected', room.name);
        setRoom(room);
        setTracks(room.localParticipant.tracks);
        room.participants.forEach(participantConnected);
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        room.once('disconnected', (error) =>
          room.participants.forEach(participantDisconnected)
        );
      })
      .catch((err) => {
        console.log(err);
      });
    socket.makeVideoCall(data);
  };

  const participantConnected = (participant) => {
    setShowVideo(true);
    console.log('Participant connected', participant.identity);
    const div = document.getElementById('video-panel');

    participant.on('trackSubscribed', (track) => trackSubscribed(div, track));
    participant.on('trackUnsubscribed', trackUnsubscribed);

    participant.tracks.forEach((publication) => {
      if (publication.isSubscribed) {
        trackSubscribed(div, publication.track);
      }
    });
  };

  const participantDisconnected = (participant) => {
    console.log('Participant disconnected', participant.identity);
    setRoom(null);
    document.getElementById('video-panel').innerHTML = '';
    setShowWaiting(false);
    setShowVideo(false);
    setCallRequest(false);
    setRoomName('');
  };

  const trackSubscribed = (div, track) => {
    div.appendChild(track.attach());
  };

  const trackUnsubscribed = (track) => {
    track.detach().forEach((element) => element.remove());
  };

  const endCallHandler = () => {
    if (room) {
      room.disconnect();
    }
    props.endHandler();
  };

  const connectVideoCall = async () => {
    let token = null;
    const data = {
      roomName,
    };
    await api
      .getCallToken(data)
      .then((res) => {
        token = res.data.token;
      })
      .catch((err) => {
        console.log(err);
      });

    await Video.connect(token, { name: roomName })
      .then((room) => {
        console.log('connected', room.participants);
        setRoom(room);
        setTracks(room.localParticipant.tracks);
        room.participants.forEach(participantConnected);
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
        room.once('disconnected', (error) =>
          room.participants.forEach(participantDisconnected)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const rejectCallHandler = () => {
    const data = {
      caller: caller.username,
    };
    socket.rejectVideoCall(data);
    setCallRequest(false);
    setRoomName('');
    setCaller({});
  };

  useEffect(() => {
    if (!showVideo && tracks) {
      tracks.forEach((publication) => {
        publication.track.stop();
      });
      props.endHandler();
    }
  }, [showVideo]);

  useEffect(() => {
    if (!showWaiting && room) {
      room.disconnect();
      props.endHandler();
    }
  }, [showWaiting]);

  var mainImage = null;
  if (props.roomData) {
    if (props.roomData.receiver) {
      var receiver = props.roomData.receiver;
      if (receiver.imgurl) {
        mainImage = receiver.imgurl[0];
      }
    }
  }

  return (
    <div className="video-chat-component">
      {showWaiting && (
        <div className="video-call-standby">
          <div className="video-call-standby-innerwrap">
            <div className="video-call-standby-header">
              <div className="video-call-standby-logo-image-wrapper">
                <BootstrapImage src={logo} className="video-call-logo" fluid />
              </div>
            </div>
            <div className="video-call-standby-content">
              <div className="video-call-standby-user-image-wrapper">
                <Media image={mainImage} />
                {/* <Image
                  publicId={
                    mainImage
                      ? getImgName(mainImage)
                      : "larger-placeholder-user-image_vvdghq.png"
                  }
                  version={mainImage ? getImgVersion(mainImage) : "1590046357"}
                  resource_type={
                    mainImage ? (checkGIF(mainImage) ? "video" : "image") : null
                  }
                  effect="loop"
                  cloud_name="sugarbae"
                  className="video-call-standby-user-image"
                  fluid
                >
                  <Transformation
                    height="200"
                    video_sampling="20"
                    delay="250"
                    duration="10"
                    effect="loop"
                    quality="auto"
                    flags="lossy"
                  />
                </Image> */}
              </div>
              <div className="video-call-standby-text-wrapper">
                <h4 className="video-call-standby-username-text">
                  {props.roomData.receiver.username}
                </h4>
                <p className="video-call-standby-subtitle-text">
                  You are calling...
                </p>
              </div>
            </div>
            <div className="video-call-standby-footer">
              <Button className="video-cancel" onClick={() => endCallHandler()}>
                <i className="far fa-phone-slash"></i>
              </Button>
            </div>
          </div>
          <Media image={mainImage} />
          {/* <Image
            publicId={
              mainImage
                ? getImgName(mainImage)
                : 'larger-placeholder-user-image_vvdghq.png'
            }
            version={mainImage ? getImgVersion(mainImage) : '1590046357'}
            resource_type={
              mainImage ? (checkGIF(mainImage) ? 'video' : 'image') : null
            }
            effect="loop"
            cloud_name="sugarbae"
            className="video-call-standby-user-image-background"
            fluid
          >
            <Transformation
              height="200"
              video_sampling="20"
              delay="250"
              duration="10"
              effect="loop"
              quality="auto"
              flags="lossy"
            />
          </Image> */}
        </div>
      )}
      {showVideo && (
        <div>
          <div id="video-panel"></div>
          <Button className="video-disconnect" onClick={() => endCallHandler()}>
            disconnect
          </Button>
        </div>
      )}
      {callRequest && checkUrl && (
        <div className="video-call-request">
          {`Call by ${caller.username}`}
          <div className="video-call-buttons">
            <Button
              className="video-receive"
              onClick={() => connectVideoCall()}
            >
              Receive
            </Button>
            <Button
              variant="danger"
              className="video-reject"
              onClick={() => rejectCallHandler()}
            >
              Reject
            </Button>
          </div>
        </div>
      )}
      {callRequest && !checkUrl && (
        <motion.div
          className="video-call-notification"
          initial={{ opacity: 0, y: '-100%' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: '-100%' }}
        >
          <div>
            <BootstrapImage
              src={callerImage}
              className="video-call-notification-bar-image"
            />
          </div>
          <label className="video-caller-name">{`${caller.username} calling you...`}</label>
          <div className="video-call-notification-buttons">
            <Button variant="success" onClick={() => connectVideoCall()}>
              Receive
            </Button>
            <Button
              variant="danger"
              className="video-notification-reject"
              onClick={() => rejectCallHandler()}
            >
              Reject
            </Button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default VideoRoom;
