import React, { Component } from "react";
import { UserContext } from "../../context/UserContext";
import { withRouter } from "react-router-dom";
import DesktopSearch from "../../components/desktop/desktopSearch/DesktopSearch";
import NewSearch from "../../components/search/NewSearch";
import { SwipeNav } from "../../components/navbar/SwipeNav";
import Search from "../../components/search/Search";
import MobileNav from "../../components/navbar/MobileNav/MobileNav";
import SearchHeader from "../../components/headers/SearchHeader";
import DesktopSearchV2 from "../../components/desktop/desktopV2/desktopSearchV2/DesktopSearchV2";

export class SearchPage extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    var userid = this.context.user._id;
    this.props.handleBoost(userid);
  }

  handleBoost = (e) => {
    var userid = this.context.user._id;
    this.props.handleBoost(userid);
  };

  render() {
    let vh = window.innerHeight;
    var vw = window.innerWidth;

    var height = vh + "px";

    if (vw >= 992) {
      return <DesktopSearchV2 />;
    } else {
      return (
        <div
          className="app-component"
          style={{ height: height, maxHeight: height }}
        >
          {/* <SwipeNav /> */}
          {/* <Search /> */}
          <NewSearch />
          <MobileNav />
        </div>
      );
    }
  }
}

export default withRouter(SearchPage);
