import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import {
  Table,
  Card,
  Button,
  Modal,
  Form,
  Alert,
  Badge,
} from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import Select from "react-dropdown-select";

import { api } from "../../../../api";
import { UserContext } from "../../../../context/UserContext";
import ChatCard from "./chatTabNewComponents/ChatCard";
import ChatView from "./chatTabNewComponents/ChatView";
import ChatUserInfo from "./chatTabNewComponents/ChatUserInfo";

import "../../admincomponents.scss";
import { connect } from "socket.io-client";

export const ChatTabeNew = props => {
  const context = useContext(UserContext);
  const initialState = {
    allChats: true,
    username: "",
    email: "",
    subscriptionid: "",
    reviewed: false,
    nonReviewed: false,
    approved: false,
    nonApproved: false,
  };

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialState);
  const [skip, setSkip] = useState(0);
  const [chats, setChats] = useState([]);
  const [page, setPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [showSearchOptions, setShowSearchOptions] = useState(false);
  const [searchOption, setSearchOption] = useState("username");
  const [searchPlaceholder, setSearchPlaceholder] = useState("Search username...");
  const [showConversation, setShowConversation] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [updateStatus, setUpdateStatus] = useState({
    index: null,
    status: null,
  });
  const [fetching, setFetching] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    fetchData();
  },[]);

  const fetchData = () => {
    const data = {
      userid: context.user._id,
      filters,
      skip,
    };

    return api
      .adminFetchChats(data)
      .then((res) => {
        setChats(res.data.cards);
        setLoading(false);
        setFetching(false);
        setRefresh(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const prevPage = () => {
    if (page > 1) {
      setFetching(true);
      setSkip(skip - 9);
      setPage(page - 1);
    }
  };

  const nextPage = () => {
    setFetching(true);
    setSkip(skip + 9);
    setPage(page + 1);
  };

  const searchOptionShow = () => {
    setShowSearchOptions(!showSearchOptions);
  };

  const toggleSearchOption = (option) => {
    setSearchOption(option);
    if (option === "username") {
      setSearchPlaceholder("Search username...");
    } else if (option === "email") {
      setSearchPlaceholder("Search email...");
    }
    setFilters({
      ...filters,
      username: "",
      email: "",
      subscriptionid: "",
    })
    setShowSearchOptions(false);
  };

  const onChangeHandler = (e) => {
    e.preventDefault();
    if (searchOption === "username") {
      setFilters({
        ...filters,
        username: e.target.value,
      });
    } else if (searchOption === "email") {
      setFilters({
        ...filters,
        email: e.target.value,
      });
    } else if (searchOption === "subscriptionid") {
      setFilters({
        ...filters,
        subscriptionid: e.target.value,
      });
    }
  };

  const keyHandler = (e) => {
    if (e.key === "Enter") {
      setLoading(true);
      fetchData();
    }
  };

  const checkFilters = (show) => {
    setShowFilters(show);
  };

  const showConversationHandler = (index) => {
    setShowConversation(true);
    setSelectedChat(index + 1);
  };

  const closeConversationHandler = () => {
    setShowConversation(false);
    setSelectedChat(null);
  };

  const showChatInfo = (index) => {
    setShowConversation(false);
    setSelectedChat(index + 1);
  };

  const resetFilter = () => {
    setFilters(initialState);
  };

  const nonReviewedOption = (e) => {
    setFilters({
      ...filters,
      allChats: false,
      nonReviewed: e.target.checked,
      reviewed: false,
    });
  };

  const reviewedOption = (e) => {
    setFilters({
      ...filters,
      allChats: false,
      nonReviewed: false,
      reviewed: e.target.checked,
    });
  };

  const approvedOption = (e) => {
    setFilters({
      ...filters,
      allChats: false,
      approved: e.target.checked,
      nonApproved: false,
    });
  };

  const nonApprovedOption = (e) => {
    setFilters({
      ...filters,
      allChats: false,
      nonApproved: e.target.checked,
      approved: false,
    });
  };

  const applyFilters = () => {
    setShowFilters(false);
    setPage(1);
    setSkip(0);
    setLoading(true);
    fetchData();
  };

  const updateChatStatus = (index, status) => {
    let tempChats = chats;
    if (status === "approved") {
      tempChats[index].reviewed = true;
      tempChats[index].approved = true;
      setChats(tempChats);
    } else if (status === "declined") {
      tempChats[index].reviewed = true;
      tempChats[index].approved = false;
      setChats(tempChats);
    }
    setUpdateStatus({index, status});
  };

  const refreshData = () => {
    setFilters(initialState);
    setLoading(true);
    setRefresh(true);
  };

  useEffect(() => {
    if (refresh === true) {
      fetchData();
    }
  },[refresh]);

  useEffect(() => {
    if (updateStatus.index !== null && updateStatus.status !== null) {
      setUpdateStatus({
        index: null,
        status: null,
      });
    }
  },[updateStatus]);

  useEffect(() => {
    fetchData();
  },[skip]);

  return (
    <Card bg={"dark"} text={"white"} className="usertable-card">
      <Card.Header>
        <div className="card-header-wrap">
          <div className="card-header-leftside" id="card-header-table">
            <span class="icon icon-moderate-chats"></span>
            <h4 className="card-header-title">Moderate Chats</h4>
          </div>
          <div className="loading-spinner">
            <ClipLoader
              size={10}
              color={"#FFF"}
              loading={loading}
            />
          </div>
          <div className="loading-spinner">
            <ClipLoader
              size={10}
              color={"#FFF"}
              loading={fetching}
            />
          </div>
        </div>
        <div className="refresh-chat" style={showFilters ? {opacity: "20%"} : null} onClick={() => refreshData()}>
          <span className="icon icon-refresh"></span>
        </div>
        <div class="input-icons" style={showFilters ? {opacity: "20%", top: "20px", right: "150px"} : {top: "20px", right: "150px"}}>
          <div onClick={() => searchOptionShow()}>
            {showSearchOptions ? (
              <i class="fas fa-angle-up inner-icon"></i>
            ): (
              <i class="fas fa-angle-down inner-icon"></i>
            )}
          </div>
          <input
            class="input-field"
            type="text"
            minlength="5"
            value={searchOption === "username" ? filters.username : searchOption === "email" ? filters.email : filters.subscriptionid}
            placeholder={searchPlaceholder}
            onChange={onChangeHandler}
            onKeyDown={keyHandler}
          />
        </div>
        {showSearchOptions && (
          <div className="chat-search-option-dropdown" style={{right: "150px"}}>
            <div className="search-option" onClick={() => toggleSearchOption("username")} style={searchOption === "username" ? {background: "#4F545C"} : null}>
              Username
            </div>
            <div className="search-option" onClick={() => toggleSearchOption("email")} style={searchOption === "email" ? {background: "#4F545C"} : null}>
              Email
            </div>
          </div>
        )}
        {!showFilters ? (
          <div className="usertable-filter-container" style={{top: "20px", right: "70px"}} onClick={() => checkFilters(true)} >
            <span className="icon icon-filter"></span>
          </div>
        ) : (
          <div className="usertable-close-filter-container" style={{top: "20px", right: "70px"}} onClick={() => checkFilters(false)} >
            <span className="icon icon-close"></span>
          </div>
        )}
        {showFilters && (
          <div className="chat-filters-panel">
            <div className="filter-header">
              <div className="filter-one-option">
                <span className="icon icon-settings"></span>
                {"  Show Chats:"}<br></br>
                <div className="filter">
                  <input type="checkbox" className="filter-checkbox" checked={filters.allChats} onChange={resetFilter} />
                  <label className="filter-checkbox-label" >All Chats</label><br/>
                </div>
              </div>
            </div>
            <hr className="chat-filter-panel-divider" />
            <div className="chat-filter-panel-options">
              <div className="filter-one-option">
                <div className="filter">
                  <input type="checkbox" className="filter-checkbox" checked={filters.nonReviewed} onChange={nonReviewedOption} />
                  <label className="filter-checkbox-label" >Needs Review</label><br/>
                  <input type="checkbox" className="filter-checkbox" checked={filters.reviewed} onChange={reviewedOption} />
                  <label className="filter-checkbox-label" >Reviewed</label><br/>
                </div>
              </div>
              <div className="filter-one-option">
                <div className="filter">
                  <input type="checkbox" className="filter-checkbox" checked={filters.approved} onChange={approvedOption} />
                  <label className="filter-checkbox-label" >Approved</label><br/>
                  <input type="checkbox" className="filter-checkbox" checked={filters.nonApproved} onChange={nonApprovedOption} />
                  <label className="filter-checkbox-label" >Not Approved</label><br/>
                </div>
              </div>
            </div>
            <hr className="chat-filter-panel-divider" />
            <div className="chat-filter-panel-footer">
              <div className="chat-filter-option" style={{marginRight: "50px"}} onClick={() => resetFilter()}>
                <span className="icon icon-trash"></span>
                <label className="footer-option-title">{"Reset Filters"}</label>
              </div>
              <div className="chat-filter-option" onClick={() => applyFilters()}>
                <span className="icon icon-apply-filter"></span>
                <label className="footer-option-title">{"Apply Filters"}</label>
              </div>
            </div>
          </div>
        )}
        {showConversation && selectedChat && (
          <ChatView
            chat={chats[selectedChat - 1]}
            closeConversationHandler={closeConversationHandler}
          />
        )}
        {!showConversation && selectedChat && (
          <div className="userinfo-drawer">
            <div className="userinfo-drawer-header">
              <p className="chat-info-title">Participants</p>
              <div className="profile-drawer-close" onClick={() => setSelectedChat(null)}>
                <span className="icon icon-close"></span>
              </div>
            </div>
            <ChatUserInfo user={chats[selectedChat - 1].senderUser} />
            <ChatUserInfo user={chats[selectedChat - 1].receiverUser} />
          </div>
        )}
      </Card.Header>
      <Card.Body className="chat-panel-body">
        <div className="chat-panel" style={showFilters ? {opacity: "20%"} : null}>
          {!loading && chats && chats.length !== 0 && chats.map((chat, index) => {
            return (
              <div style={selectedChat && selectedChat !== index + 1 ? { filter: `blur(3px)` } : null}>
                <ChatCard
                  index={index}
                  chat={chat}
                  showConversationHandler={showConversationHandler}
                  showChatInfo={showChatInfo}
                  updateStatus={updateChatStatus}
                />
              </div>
            );
          })}
        </div>
      </Card.Body>
      <Card.Footer style={{position: "fixed", bottom: 0, width: "100%", background: "#343a40", zIndex: 2}}>
        <div className="chat-card-footer-wrap">
          <div className="usertable-pagination">
            <div className="arrow-panel" onClick={() => prevPage()}>
              <span className="icon icon-prev"></span>
            </div>
            <div className="arrow-panel" onClick={() => nextPage()}>
              <span className="icon icon-next"></span>
            </div>
            <div className="pages-title">
              {`Pages: `}
            </div>
            <div className="chats-current-page">
              {`${page}`}
            </div>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

export default ChatTabeNew;