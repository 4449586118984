import React, { useState, useEffect, useContext } from "react";
import "./desktopmessages.scss";
import { Link, withRouter } from "react-router-dom";
import DesktopNav from "../desktopNav/DesktopNav";
import Axios from "axios";
import { UserContext } from "../../../context/UserContext";
import ClipLoader from "react-spinners/ClipLoader";
import { Image, Button, Form, Alert, Badge } from "react-bootstrap";
import MessagesModal from "../../modals/messagesModal/MessagesModal";
import DesktopInboxView from "./desktopInboxView/DesktopInboxView";
import DesktopSentView from "./desktopSentView/DesktopSentView";
import DesktopMatchesView from "./desktopMatchesView/DesktopMatchesView";
import DesktopArchivedView from "./desktopArchivedView/DesktopArchivedView";
import DesktopChat from "./desktopChat/DesktopChat";

function DesktopMessages(props) {
  const [view, setView] = useState("inbox");

  const toggleView = (viewName) => {
    if (props.chatid) {
      props.history.push({
        pathname: "/app/messages",
      });
    } else {
      if (viewName === "inbox") {
        if (view !== "inbox") {
          setView("inbox");
        }
      } else if (viewName === "sent") {
        if (view !== "sent") {
          setView("sent");
        }
      } else if (viewName === "matches") {
        if (view !== "matches") {
          setView("matches");
        }
      } else if (viewName === "archived") {
        if (view !== "archived") {
          setView("archived");
        }
      }
    }
  };

  useEffect(() => {
    if (props.chatid) {
      setView("chat");
    }
  }, [props.chatid]);

  return (
    <div className="desktop-messages-component">
      <DesktopNav />
      <div className="desktop-messages-container">
        <div className="desktop-messages-sidebar">
          <div
            className={
              view === "inbox"
                ? "messages-sidebar-tab-active"
                : "messages-sidebar-tab"
            }
            onClick={() => toggleView("inbox")}
          >
            <div className="messages-sidebar-tab-title-wrapper">
              <h2
                className={
                  view === "inbox"
                    ? "messages-sidebar-tab-title-active"
                    : "messages-sidebar-tab-title"
                }
              >
                Inbox
              </h2>
            </div>
            {/* <div className="messages-sidebar-counter-wrapper">
              <Badge
                variant="danger"
                className={
                  view === "inbox"
                    ? "messages-sidebar-inbox-counter-badge-active"
                    : "messages-sidebar-inbox-counter-badge"
                }
              >
                100
              </Badge>
            </div> */}
          </div>
          <div
            className={
              view === "sent"
                ? "messages-sidebar-tab-active sidebar-tab-sent"
                : "messages-sidebar-tab sidebar-tab-sent"
            }
            onClick={() => toggleView("sent")}
          >
            <div className="messages-sidebar-tab-title-wrapper">
              <h2
                className={
                  view === "sent"
                    ? "messages-sidebar-tab-title-active sidebar-tab-sent-title"
                    : "messages-sidebar-tab-title sidebar-tab-sent-title"
                }
              >
                Sent
              </h2>
            </div>
          </div>
          <div
            className={
              view === "matches"
                ? "messages-sidebar-tab-active sidebar-tab-matches"
                : "messages-sidebar-tab sidebar-tab-matches"
            }
            onClick={() => toggleView("matches")}
          >
            <div className="messages-sidebar-tab-title-wrapper">
              <h2
                className={
                  view === "matches"
                    ? "messages-sidebar-tab-title-active sidebar-tab-matches-title"
                    : "messages-sidebar-tab-title sidebar-tab-matches-title"
                }
              >
                Matches
              </h2>
            </div>
          </div>
          <div
            className={
              view === "archived"
                ? "messages-sidebar-tab-active sidebar-tab-archived"
                : "messages-sidebar-tab sidebar-tab-archived"
            }
            onClick={() => toggleView("archived")}
          >
            <div className="messages-sidebar-tab-title-wrapper">
              <h2
                className={
                  view === "archived"
                    ? "messages-sidebar-tab-title-active sidebar-tab-archived-title"
                    : "messages-sidebar-tab-title sidebar-tab-archived-title"
                }
              >
                Archived
              </h2>
            </div>
          </div>
        </div>
        <div className="desktop-messages-inbox">
          {view === "chat" && <DesktopChat chatid={props.chatid} />}
          {view === "inbox" && <DesktopInboxView />}
          {view === "matches" && <DesktopMatchesView />}
          {view === "sent" && <DesktopSentView />}
          {view === "archived" && <DesktopArchivedView />}
        </div>
      </div>
    </div>
  );
}

export default withRouter(DesktopMessages);
