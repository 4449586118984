import React, { useState } from "react";
import { motion } from "framer-motion";
import "./notificationbar.scss";
import { Image } from "react-bootstrap";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { useSwipeable, Swipeable } from "react-swipeable";

function NotificationBar(props) {
  const notificationOpen = props.notificationOpen;
  const notificationType = props.notificationType;
  const data = props.data;
  const vw = window.innerWidth;
  const vh = window.innerHeight;
  const location = useLocation();
  const pathname = location.pathname;

  const handlers = useSwipeable({
    onSwipedUp: (eventData) => props.closeHandler(),
  });

  const imageStyle = {
    width: vw * 0.2 + "px",
    height: vh * 0.1 * 0.8 + "px",
  };

  if (notificationOpen) {
    const timer = setTimeout(() => {
      props.closeHandler();
    }, 4000);
  }

  const redirectHandler = (type) => (e) => {
    props.closeHandler();
    if (type === "message") {
      props.history.push("/app/messages");
    } else if (type === "match") {
      props.history.push("/app/matches");
    } else if (type === "like") {
      props.history.push("/app/likes");
    }
  };

  var showNotification = true;

  if (pathname.includes("/app/welcome")) {
    showNotification = false;
  }

  if (vw > 992) {
    showNotification = false;
  }

  if (notificationOpen) {
    if (
      notificationType === "message" &&
      pathname !== "/app/messages" &&
      showNotification
    ) {
      var image = data.senderimage;
      var username = data.senderusername;

      if (image === null || image === undefined) {
        image =
          "https://res.cloudinary.com/sugarbae/image/upload/v1590045309/placeholder-user-image-thumbnail_vankru.png";
      }

      return (
        <motion.div
          className="notification-bar-component"
          onClick={redirectHandler("message")}
          initial={{ opacity: 0, y: "-100%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "-100%" }}
          {...handlers}
        >
          <div className="notification-bar-innerwrap">
            <div className="notification-bar-image-wrapper">
              <Image
                src={image}
                className="notification-bar-image"
                style={imageStyle}
              />
            </div>
            <div className="notification-bar-content-wrapper">
              <h1 className="notification-bar-title">New Message!</h1>
              <p className="notification-bar-subtitle">{data.message}</p>
            </div>
            <div className="notification-bar-icon-wrapper">
              <i className="fas fa-chevron-right notification-bar-right-icon"></i>
            </div>
          </div>
          <div className="notification-bar-swipe-tab" />
        </motion.div>
      );
    } else if (
      notificationType === "match" &&
      pathname !== "/app/matches" &&
      showNotification
    ) {
      var image = data.imgurl[0];
      var username = data.username;
      if (image === null || image === undefined) {
        image =
          "https://res.cloudinary.com/sugarbae/image/upload/v1590045309/placeholder-user-image-thumbnail_vankru.png";
      }

      return (
        <motion.div
          className="notification-bar-component"
          onClick={redirectHandler("match")}
          initial={{ opacity: 0, y: "-100%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "-100%" }}
          {...handlers}
        >
          <div className="notification-bar-innerwrap">
            <div className="notification-bar-image-wrapper">
              <Image
                src={image}
                className="notification-bar-image"
                style={imageStyle}
              />
            </div>
            <div className="notification-bar-content-wrapper">
              <h1 className="notification-bar-title">New Match!</h1>
              <p className="notification-bar-subtitle">
                You Matched With {username}
              </p>
            </div>
            <div className="notification-bar-icon-wrapper">
              <i className="fas fa-chevron-right notification-bar-right-icon"></i>
            </div>
          </div>
          <div className="notification-bar-swipe-tab" />
        </motion.div>
      );
    } else if (
      notificationType === "like" &&
      pathname !== "/app/matches" &&
      showNotification
    ) {
      var superlike = data.superlike;
      var premium = data.premium;
      var image = data.sender.imgurl[0];
      var username = data.sender.username;
      if (image === null || image === undefined) {
        image =
          "https://res.cloudinary.com/sugarbae/image/upload/v1590045309/placeholder-user-image-thumbnail_vankru.png";
      }

      return (
        <motion.div
          className="notification-bar-component"
          onClick={redirectHandler("like")}
          initial={{ opacity: 0, y: "-100%" }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: "-100%" }}
          {...handlers}
        >
          <div className="notification-bar-innerwrap">
            <div className="notification-bar-image-wrapper">
              <Image
                src={image}
                className={
                  premium
                    ? "notification-bar-image"
                    : "notification-bar-image-blur"
                }
                style={imageStyle}
              />
            </div>
            <div className="notification-bar-content-wrapper">
              <h1 className="notification-bar-title">
                {superlike ? (
                  <i className="fas fa-star notification-superlike-icon"></i>
                ) : (
                  <i className="fas fa-heart notification-like-icon"></i>
                )}
                {superlike ? "New Superlike!" : "New Like!"}
              </h1>
              {premium ? (
                <p className="notification-bar-subtitle">
                  {superlike ? "Superliked" : "Liked"} by {username}
                </p>
              ) : (
                <p className="notification-bar-subtitle">
                  You were {superlike ? "Superliked!" : "Liked!"}
                </p>
              )}
            </div>
            <div className="notification-bar-icon-wrapper">
              <i className="fas fa-chevron-right notification-bar-right-icon"></i>
            </div>
          </div>
          <div className="notification-bar-swipe-tab" />
        </motion.div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}

export default withRouter(NotificationBar);
