import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./loginform.scss";
import axios from "axios";
import { withRouter } from "react-router-dom";
import EmailValidator from "email-validator";
import ReactGA from "react-ga";
import { Container, Form, Button, Alert, Card } from "react-bootstrap";
import Recaptcha from "react-google-invisible-recaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import { FacebookLogin, GoogleLogin } from "../auth";
import ReactivateLogin from "./loginReactivate";
import loginCardBanner from "../../assets/Images/Login Page/Login Card Banner/login-card-banner.jpg";
import { api } from "../../api";

export class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      email: "",
      emailInvalid: false,
      password: "",
      passwordInvalid: false,
      error: false,
      bannedError: false,
      oauth: false,
      reactivateLogin: false,
      passRecaptcha: true,
      passChallenge: false,
      recapchaWargning: false,
    };
  }

  getProfileInfo = (personalInfo) => {
    this.setState(this.getInitialState);
    if (personalInfo.case === "google") {
      const { email, googleId } = personalInfo.info;
      if (email && googleId) {
        this.setState({
          email,
          password: googleId,
          oauth: true,
        });
      }
    } else if (personalInfo.case === "facebook") {
      const { email, id } = personalInfo.info;
      if (email && id) {
        this.setState({
          email,
          password: id,
          oauth: true,
        });
      }
    }
    if (personalInfo.info.email) {
      if (this.state.passRecaptcha) {
        this.recaptcha.execute();
      } else if (!this.state.passRecaptcha && this.state.passChallenge) {
        this.loginHandler();
      } else {
        this.setState({ recapchaWargning: true });
      }
    } else {
      this.recaptcha.reset();
    }
  };

  emailChangeHandler = (e) => {
    this.setState({ email: e.target.value.toLowerCase() });
  };

  passwordChangeHandler = (e) => {
    this.setState({ password: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();

    if (this.state.email.length < 1) {
      this.recaptcha.reset();
      this.setState({
        error: true,
        emailInvalid: true,
        email: "",
        password: "",
      });
    } else if (!EmailValidator.validate(this.state.email)) {
      this.recaptcha.reset();
      this.setState({
        error: true,
        emailInvalid: true,
        email: "",
        password: "",
      });
    } else if (this.state.password.length < 1) {
      this.recaptcha.reset();
      this.setState({
        error: true,
        passwordInvalid: true,
        email: "",
        password: "",
      });
    } else {
      if (this.state.passRecaptcha) {
        this.recaptcha.execute();
      } else if (!this.state.passRecaptcha && this.state.passChallenge) {
        this.loginHandler();
      } else {
        this.setState({ recapchaWargning: true });
      }
    }
  };

  onResolved = () => {
    const baseurl = process.env.REACT_APP_API_URL;
    const recaptchaUrl = baseurl + "/api/auth/recaptcha";
    const config = {
      response: this.recaptcha.getResponse(),
    };

    return axios.post(recaptchaUrl, config).then((res) => {
      const { score } = res.data;
      if (score > 0.5) {
        this.loginHandler();
      } else {
        this.setState({ passRecaptcha: false });
      }
    });
  };

  loginHandler = async () => {
    var ipAddress = null;
    await axios.get("https://json.geoiplookup.io", {
      withCredentials: false,
    })
      .then((res) => {
        ipAddress = res.data.ip;
      })
      .catch((err) => {
        console.log(err);
      });

    const data = {
      email: this.state.email,
      password: this.state.password,
      oauth: this.state.oauth,
      ipAddress,
    };

    return api
      .submitLogin(data)
      .then((res) => {
        if (res.status === 200) {
          var banned = res.data.banned;
          var isAdmin = res.data.isAdmin;
          var deactivate = res.data.deactivate;

          if (banned) {
            this.setState({ bannedError: true, email: "", password: "" });
          } else if (deactivate) {
            this.setState({ reactivateLogin: true });
          } else {
            localStorage.setItem("auth", res.data.token);
            ReactGA.event({
              category: "Login/Signup",
              action: "Successful Login",
            });
            ReactGA.set({ userId: this.state.email });

            if (isAdmin) {
              this.props.history.push("/app/admin");
            } else {
              this.props.history.push("/app");
            }
          }
        }
      })
      .catch((err) => {
        this.setState({
          error: true,
          emailInvalid: true,
          passwordInvalid: true,
          password: "",
        });
        ReactGA.event({
          category: "Login/Signup",
          action: "Failed Login",
        });

        if (window.gapi) {
          const auth2 = window.gapi.auth2.getAuthInstance();
          if (auth2 != null) {
            auth2.signOut().then(auth2.disconnect());
          }
        }

        if (window.FB) {
          const token = window.FB.getAccessToken();
          if (token != null) {
            window.FB.logout();
          }
        }
      });
  };

  onchange = (value) => {
    if (value) {
      this.setState({ passChallenge: true, recapchaWargning: false });
    }
  };

  closeReactivateModal = () => {
    this.setState({ reactivateLogin: false });
  };

  render() {
    if (this.state.reactivateLogin) {
      return (
        <ReactivateLogin
          email={this.state.email}
          password={this.state.password}
          oauth={this.state.oauth}
          submitLogin={this.submitHandler}
          closeReactivateModal={this.closeReactivateModal}
        />
      );
    } else {
      return (
        <div className="login-section-container">
          <Container className="login-form-container">
            <Card className="login-form-card">
              <Card.Img
                variant="top"
                className="login-form-top-img"
                src={loginCardBanner}
              />
              <Card.Title className="lp-title">
                <span className="lg-login-link">Login</span>
              </Card.Title>
              <Card.Body className="login-form-card-body">
                <Form className="login-form" onSubmit={this.submitHandler}>
                  {this.state.bannedError ? (
                    <Alert variant="danger" className="lp-error-alert">
                      Your account has been banned.
                    </Alert>
                  ) : null}
                  {this.state.error ? (
                    <Alert variant="danger" className="lp-error-alert">
                      Incorrect Email & Password Combination.<br></br>
                      <Link
                        to="/reset-password"
                        className="lp-forgot-password-link"
                      >
                        Click here to reset your password.
                      </Link>
                    </Alert>
                  ) : null}
                  <Form.Group>
                    <Form.Label className="login-form-label">Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Your Email Address"
                      onChange={this.emailChangeHandler}
                      value={this.state.email}
                      className="login-form-input"
                      size="lg"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="login-form-label">
                      Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter Your Password"
                      onChange={this.passwordChangeHandler}
                      value={this.state.password}
                      className="login-form-input"
                      size="lg"
                    />
                    <div className="lp-forgot-password-wrapper">
                      <Link to="/reset-password">
                        <Button
                          variant="link"
                          className="lp-forgot-password-link"
                        >
                          Forgot Password
                        </Button>
                      </Link>
                    </div>
                  </Form.Group>
                  {this.state.recapchaWargning && (
                    <Alert variant="danger" className="lp-error-alert">
                      Please confirm you are not robot.
                    </Alert>
                  )}
                  {!this.state.passRecaptcha && (
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_SITE_KEY}
                      onChange={this.onchange}
                    />
                  )}
                  <Button
                    variant="primary"
                    type="submit"
                    className="login-form-btn"
                    size="lg"
                  >
                    Login
                  </Button>
                  <div className="divider">
                    <span className="divider-title">OR</span>
                  </div>
                  <FacebookLogin
                    isSignIn={true}
                    onCheck={this.getProfileInfo}
                  />
                  <GoogleLogin isSignIn={true} onCheck={this.getProfileInfo} />
                </Form>
                <div className="login-below-form-container">
                  <p className="lp-signup-text">
                    Don't have an account?{" "}
                    <Link to="/signup" className="lp-signup-link">
                      Signup Now
                    </Link>
                  </p>

                  <p className="lp-disclaimer-text">
                    By continuing you agree to Sugarbae's{" "}
                    <Link to="/terms">Terms</Link> and{" "}
                    <Link to="/privacy">Privacy </Link>
                    Policy. Promoting illegal commercial activities (such as
                    escorting or prostitution) is strictly prohibited. If you
                    are an ESCORT, DO NOT use this website.{" "}
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Container>
          <Recaptcha
            ref={(ref) => (this.recaptcha = ref)}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            onResolved={this.onResolved}
          />
        </div>
      );
    }
  }
}

export default withRouter(LoginForm);
