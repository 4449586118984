import React, { Component } from "react";
import "./swipenav.scss";
import logo from "../../assets/logos/logo-horizontal-dark-background.png";
import sugarbaeHomeIcon from "../../assets/logos/sugarbae-home-icon.png";
import { Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import MessageCounter from "./navComponents/counters/MessageCounter";
import MatchCounter from "./navComponents/counters/MatchCounter";
import ProfileCounter from "./navComponents/counters/ProfileCounter";
import { UserContext } from "../../context/UserContext";
import { withRouter } from "react-router-dom";
import { api } from "../../api";

export class SwipeNav extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      boost: {
        status: false,
        date: null,
      },
      premium: false,
    };
  }
  componentWillMount() {
    this.fetchData();
  }

  fetchData = async (e) => {
    const cardid = this.props.cardid;

    const data = {
      userid: this.context.user._id,
    };

    return api
      .fetchNavbarData(data)
      .then((res) => {
        if (res.status === 200) {
          var boost = res.data.boost;
          var premium = res.data.premium;
          var banned = res.data.banned;
          if (banned) {
            localStorage.removeItem("auth");
            this.props.history.push("/");
          }
          this.setState({ boost: boost, premium: premium });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    var vw = window.innerWidth;
    var width = vw + "px";

    if (vw > 992) {
      return (
        <div className="swipe-nav" style={{ maxWidth: width }}>
          <div className="swipe-nav-innerwrap-desktop">
            <div className="swipe-nav-desktop-logo-wrapper">
              <Link to="/app" className="swipe-nav-desktop-logo-link">
                <Image
                  src={logo}
                  className="swipe-nav-desktop-logo"
                  alt="Sugarbae Logo"
                />
              </Link>
            </div>
            <div className="swipe-nav-desktop-sections-wrapper">
              <div className="swipe-nav-desktop-section">
                <Link to="/app/search" className="swipe-nav-link">
                  <Button className="swipe-nav-btn">
                    <i className="fas fa-search swipe-nav-icon-desktop"></i>
                    Search
                  </Button>
                </Link>
              </div>
              <div className="swipe-nav-desktop-section">
                <Link to="/app/matches" className="swipe-nav-link">
                  <Button className="swipe-nav-btn">
                    <i className="fas fa-heart swipe-nav-icon-desktop">
                      <MatchCounter />
                    </i>
                    Matches
                  </Button>
                </Link>
              </div>
              <div className="swipe-nav-desktop-section">
                <Link to="/app/messages" className="swipe-nav-link">
                  <Button className="swipe-nav-btn">
                    <i className="fas fa-comment-alt-lines swipe-nav-icon-desktop">
                      <MessageCounter />
                    </i>
                    Messages
                  </Button>
                </Link>
              </div>
              <div className="swipe-nav-desktop-section">
                <Link to="/app/profile" className="swipe-nav-link">
                  <Button className="swipe-nav-btn">
                    <i className="fas fa-user swipe-nav-icon-desktop">
                      {/* <ProfileCounter /> */}
                    </i>
                    Profile
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={this.context.user.premium ? "swipe-nav" : "swipe-nav"}>
          <div className="swipe-nav-innerwrap">
            <div className="swipe-nav-left">
              <Link to="/app/profile" className="swipe-nav-link">
                <Button className="swipe-nav-btn">
                  <i className="fas fa-user swipe-nav-icon">
                    {/* <ProfileCounter /> */}
                  </i>
                </Button>
              </Link>
              <Link to="/app/search" className="swipe-nav-link">
                <Button className="swipe-nav-btn">
                  <i className="fas fa-search swipe-nav-icon"></i>
                </Button>
              </Link>
            </div>
            <Link to="/app" className="swipe-nav-logo-link">
              <div className="swipe-nav-center">
                <Image
                  className="swipe-nav-logo"
                  src={sugarbaeHomeIcon}
                  alt="Sugarbae Logo"
                />
              </div>
            </Link>
            <div className="swipe-nav-right">
              <Link to="/app/matches" className="swipe-nav-link">
                <Button className="swipe-nav-btn">
                  <i className="fas fa-heart swipe-nav-icon">
                    <MatchCounter />
                  </i>
                </Button>
              </Link>
              <Link to="/app/messages" className="swipe-nav-link">
                <Button className="swipe-nav-btn">
                  <i className="fas fa-comment-alt-lines swipe-nav-icon">
                    <MessageCounter />
                  </i>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(SwipeNav);
