import React, { useState, useEffect } from "react";
import { api } from "../../api";
import DailySignups from "../graphs/DailySignups";
import DailySignupsAgeGroup from "../graphs/DailySignupsAgeGroup";
import "./views.scss";

function SignupsView(props) {
  return (
    <div className="view-container">
      <table>
        <td>
          <div className="view-row">
            <DailySignups />
          </div>
        </td>
        <td>
          <div className="view-row">
            <DailySignupsAgeGroup />
          </div>
        </td>
      </table>
    </div>
  );
}

export default SignupsView;
