import React, { useState, useEffect } from "react";
import "./landinggrid.scss";
import axios from "axios";
import { Button } from "react-bootstrap";
import { api } from "../../../api";
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from "../../../utils/getCloudinaryInfo";
import { Image, Transformation } from "cloudinary-react";

function LandingGrid(props) {
  const [loading, setLoading] = useState(true);
  const [fetchingLocation, setFetchingLocation] = useState(true);
  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState(null);
  const [city, setCity] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    if (!fetchingLocation) {
      fetchUsers();
    }
  }, [coordinates]);

  const fetchLocation = async (e) => {
    await axios
      .get("https://json.geoiplookup.io", {
        withCredentials: false,
      })
      .then((res) => {
        setFetchingLocation(false);
        setCountry(res.data.country_name);
        setRegion(res.data.region);
        setCity(res.data.city);
        setCoordinates([res.data.longitude, res.data.latitude]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUsers = async (e) => {
    const data = {
      city: city,
      country: country,
      region: region,
      coordinates: coordinates,
      gender: "female",
    };

    return api
      .fetchLandingGrid(data)
      .then((res) => {
        if (res.status === 200) {
          setUsers(res.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cardClickHandler = (e) => {
    props.cardClickHandler();
  };

  if (users.length === 0) {
    return <div></div>;
  } else {
    return (
      <div className="landing-grid-component">
        {!fetchingLocation && (
          <div className="landing-grid-header">
            <h3 className="landing-grid-title">
              {city}, {region}
            </h3>
            <p className="landing-grid-subtitle">Females Online Near You:</p>
          </div>
        )}

        {loading ? (
          <div className="landing-grid-content"></div>
        ) : (
          <div className="landing-grid-content">
            {users.map((user, index) => {
              if (user.imgurl.length > 0) {
                var image = user.imgurl[0];
              } else {
                var image = null;
              }
              return (
                <div
                  className="landing-grid-user-card"
                  onClick={cardClickHandler}
                >
                  <Image
                    publicId={getImgName(image)}
                    version={getImgVersion(image)}
                    resource_type={checkGIF(image) ? "video" : "image"}
                    effect="loop"
                    cloud_name="sugarbae"
                    className="landing-grid-card-image"
                    fluid
                  >
                    <Transformation
                      height="200"
                      video_sampling="20"
                      delay="250"
                      duration="10"
                      effect="loop"
                      quality="auto"
                      flags="lossy"
                    />
                  </Image>
                  <div className="landing-grid-user-card-info">
                    <div className="landing-grid-user-card-age-username-wrap">
                      <p className="landing-grid-user-card-username">
                        {user.username}, {user.age}
                      </p>
                      <p className="landing-grid-user-card-age-username">
                        {user.address}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {!loading && (
          <div className="landing-grid-footer">
            <Button
              className="landing-grid-footer-button"
              onClick={cardClickHandler}
            >
              View More
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default LandingGrid;
