import React, { useState, useEffect, useContext } from "react";
import { Image } from "react-bootstrap";
import relationshipIcon from "../../../../assets/Icons/profile/heartIcon.png";
import bodytypeIcon from "../../../../assets/Icons/profile/bodytypeIcon.png";
import childrenIcon from "../../../../assets/Icons/profile/childrenIcon.png";
import drinkingIcon from "../../../../assets/Icons/profile/drinkingIcon.png";
import educationIcon from "../../../../assets/Icons/profile/educationIcon.png";
import ethnicityIcon from "../../../../assets/Icons/profile/ethnicityIcon.png";
import exerciseIcon from "../../../../assets/Icons/profile/exerciseIcon.png";
import heightIcon from "../../../../assets/Icons/profile/heightIcon.png";
import smokingIcon from "../../../../assets/Icons/profile/smokingIcon.png";
import { api } from "../../../../api";
import { UserContext } from "../../../../context/UserContext";

const heights = [
  "4' 0\"",
  "4' 1\"",
  "4' 2\"",
  "4' 3\"",
  "4' 4\"",
  "4' 5\"",
  "4' 6\"",
  "4' 7\"",
  "4' 8\"",
  "4' 9\"",
  "4' 10\"",
  "4' 11\"",
  "5' 0\"",
  "5' 1\"",
  "5' 2\"",
  "5' 3\"",
  "5' 4\"",
  "5' 5\"",
  "5' 6\"",
  "5' 7\"",
  "5' 8\"",
  "5' 9\"",
  "5' 10\"",
  "5' 11\"",
  "6' 0\"",
  "6' 1\"",
  "6' 2\"",
  "6' 3\"",
  "6' 4\"",
  "6' 5\"",
  "6' 6\"",
  "6' 7\"",
  "6' 8\"",
  "6' 9\"",
  "6' 10\"",
  "6' 11\"",
  "7' 0\"",
];

function DesktopUserPersonalInfo(props) {
  const context = useContext(UserContext);

  const [editOpen, setEditOpen] = useState(false);
  const [showSelects, setShowSelects] = useState({
    relationship: true,
    height: true,
    bodytype: true,
    ethnicity: true,
    drinking: true,
    smoking: true,
    education: true,
    kids: true,
    exercise: true,
  });
  const card = context.user;
  const { lifestyle } = card;
  const [texts, setTexts] = useState({
    relationship: "-",
    height: "-",
    bodytype: "-",
    ethnicity: "-",
    drinking: "-",
    smoking: "-",
    education: "-",
    kids: "-",
    exercise: "-",
  });
  const toggleSelects = (e) => {
    const tempSelects = showSelects;
    if (e === "relationship") {
      if (tempSelects.relationship) {
        tempSelects.relationship = false;
      } else {
        tempSelects.relationship = true;
      }
    } else if (e === "height") {
      if (tempSelects.height) {
        tempSelects.height = false;
      } else {
        tempSelects.height = true;
      }
    } else if (e === "bodytype") {
      if (tempSelects.bodytype) {
        tempSelects.bodytype = false;
      } else {
        tempSelects.bodytype = true;
      }
    } else if (e === "ethnicity") {
      if (tempSelects.ethnicity) {
        tempSelects.ethnicity = false;
      } else {
        tempSelects.ethnicity = true;
      }
    } else if (e === "drinking") {
      if (tempSelects.drinking) {
        tempSelects.drinking = false;
      } else {
        tempSelects.drinking = true;
      }
    } else if (e === "smoking") {
      if (tempSelects.smoking) {
        tempSelects.smoking = false;
      } else {
        tempSelects.smoking = true;
      }
    } else if (e === "education") {
      if (tempSelects.education) {
        tempSelects.education = false;
      } else {
        tempSelects.education = true;
      }
    } else if (e === "kids") {
      if (tempSelects.kids) {
        tempSelects.kids = false;
      } else {
        tempSelects.kids = true;
      }
    } else if (e === "exercise") {
      if (tempSelects.exercise) {
        tempSelects.exercise = false;
      } else {
        tempSelects.exercise = true;
      }
    }
    setShowSelects({ ...showSelects }, tempSelects);
  };

  const updateRelationship = async (e) => {
    const { value } = e.target;
    const data = {
      userid: context.user._id,
      relationshipStatus: value.toString(),
    };
    await api.updateRelationshipStatus(data).then(() => {
      const tempUser = context.user;
      tempUser.lifestyle.relationship = value;
      context.updateUserInfo("all", tempUser);

      const tempTexts = texts;
      tempTexts.relationship = value;
      setTexts({ ...texts }, tempTexts);
      toggleSelects("relationship");
    });
  };

  const updateHeight = async (e) => {
    const { value } = e.target;
    const data = {
      userid: context.user._id,
      height: value,
    };
    await api.updateProfileHeight(data).then(() => {
      const tempUser = context.user;
      tempUser.height = value;
      context.updateUserInfo("all", tempUser);

      const tempTexts = texts;
      tempTexts.height = value;
      setTexts({ ...texts }, tempTexts);
      toggleSelects("height");
    });
  };

  const updateBodytype = async (e) => {
    const { value } = e.target;
    const data = {
      userid: context.user._id,
      bodytype: value,
    };
    await api.updateBodytype(data).then(() => {
      const tempUser = context.user;
      tempUser.bodytype = value;
      context.updateUserInfo("all", tempUser);

      const tempTexts = texts;
      tempTexts.bodytype = value;
      setTexts({ ...texts }, tempTexts);
      toggleSelects("bodytype");
    });
  };
  const updateEthnicity = async (e) => {
    const { value } = e.target;
    const data = {
      userid: context.user._id,
      ethnicity: value,
    };
    await api.updateEthnicity(data).then(() => {
      const tempUser = context.user;
      tempUser.ethnicity = value;
      context.updateUserInfo("all", tempUser);

      const tempTexts = texts;
      tempTexts.ethnicity = value;
      setTexts({ ...texts }, tempTexts);
      toggleSelects("ethnicity");
    });
  };
  const updateSmoking = async (e) => {
    const { value } = e.target;
    const data = {
      userid: context.user._id,
      smoking: value,
    };
    await api.updateSmoking(data).then(() => {
      const tempUser = context.user;
      tempUser.lifestyle.smoking = value;
      context.updateUserInfo("all", tempUser);

      const tempTexts = texts;
      tempTexts.smoking = value;
      setTexts({ ...texts }, tempTexts);
      toggleSelects("smoking");
    });
  };
  const updateDrinking = async (e) => {
    const { value } = e.target;
    const data = {
      userid: context.user._id,
      drinking: value,
    };
    await api.updateDrinking(data).then(() => {
      const tempUser = context.user;
      tempUser.lifestyle.drinking = value;
      context.updateUserInfo("all", tempUser);

      const tempTexts = texts;
      tempTexts.drinking = value;
      setTexts({ ...texts }, tempTexts);
      toggleSelects("drinking");
    });
  };
  const updateEducation = async (e) => {
    const { value } = e.target;
    const data = {
      userid: context.user._id,
      education: value,
    };
    await api.updateEducation(data).then(() => {
      const tempUser = context.user;
      tempUser.lifestyle.education = value;
      context.updateUserInfo("all", tempUser);

      const tempTexts = texts;
      tempTexts.education = value;
      setTexts({ ...texts }, tempTexts);
      toggleSelects("education");
    });
  };
  const updateKids = async (e) => {
    const { value } = e.target;
    const data = {
      userid: context.user._id,
      children: value,
    };
    await api.updateChildren(data).then(() => {
      const tempUser = context.user;
      tempUser.lifestyle.children = value;
      context.updateUserInfo("all", tempUser);

      const tempTexts = texts;
      tempTexts.kids = value;
      setTexts({ ...texts }, tempTexts);
      toggleSelects("kids");
    });
  };
  const updateExercise = async (e) => {
    const { value } = e.target;
    const data = {
      userid: context.user._id,
      exercise: value,
    };
    await api.updateExercise(data).then(() => {
      const tempUser = context.user;
      tempUser.lifestyle.exercise = value;
      context.updateUserInfo("all", tempUser);

      const tempTexts = texts;
      tempTexts.exercise = value;
      setTexts({ ...texts }, tempTexts);
      toggleSelects("exercise");
    });
  };

  useEffect(() => {
    const tempSelects = showSelects;
    const tempTexts = texts;
    if (lifestyle.relationship) {
      tempTexts.relationship = lifestyle.relationship;
      tempSelects.relationship = false;
    }

    if (card.height) {
      tempTexts.height = card.height;
      tempSelects.height = false;
    }

    if (card.bodytype) {
      tempTexts.bodytype = card.bodytype;
      tempSelects.bodytype = false;
    }

    if (card.ethnicity) {
      tempTexts.ethnicity = card.ethnicity;
      tempSelects.ethnicity = false;
    }

    if (lifestyle.drinking) {
      tempTexts.drinking = lifestyle.drinking;
      tempSelects.drinking = false;
    }

    if (lifestyle.smoking) {
      tempTexts.smoking = lifestyle.smoking;
      tempSelects.smoking = false;
    }

    if (lifestyle.education) {
      tempTexts.education = lifestyle.education;
      tempSelects.education = false;
    }

    if (lifestyle.children) {
      tempTexts.kids = lifestyle.children;
      tempSelects.kids = false;
    }

    if (lifestyle.exercise) {
      tempTexts.exercise = lifestyle.exercise;
      tempSelects.exercise = false;
    }

    setTexts({ ...texts }, tempTexts);
    setShowSelects({ ...showSelects }, tempSelects);
  }, []);

  return (
    <div className="desktop-v2-about-container">
      <h1 className="desktop-v2-section-title">Personal Info</h1>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image
              src={relationshipIcon}
              className="desktop-v2-about-row-icon"
            />
          </div>
          <h2 className="desktop-v2-about-row-title">Relationship Status</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          {showSelects.relationship ? (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <select
                name="relationship"
                id="relationship"
                onChange={updateRelationship}
                className="desktop-v2-user-info-select"
              >
                <option
                  value=""
                  disabled
                  selected
                  hidden
                  className="desktop-v2-user-info-select-option"
                >
                  Select
                </option>
                <option>Single</option>
                <option>Divorced</option>
                <option>Separated</option>
                <option>Married But Looking</option>
                <option>Open Relationship</option>
                <option>Widowed</option>
              </select>
            </div>
          ) : (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <div
                className="desktop-v2-user-info-edit-button"
                onClick={() => toggleSelects("relationship")}
              >
                <i className="far fa-pen user-info-edit-icon" />
              </div>
              <p
                className="desktop-v2-about-row-content"
                style={{ marginBottom: 0 }}
              >
                {texts.relationship}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={heightIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Height</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          {showSelects.height ? (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <select
                name="height"
                id="height"
                onChange={updateHeight}
                className="desktop-v2-user-info-select"
              >
                <option
                  value=""
                  disabled
                  selected
                  hidden
                  className="desktop-v2-user-info-select-option"
                >
                  Select
                </option>
                {heights.map((height, index) => (
                  <option>{height}</option>
                ))}
              </select>
            </div>
          ) : (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <div
                className="desktop-v2-user-info-edit-button"
                onClick={() => toggleSelects("height")}
              >
                <i className="far fa-pen user-info-edit-icon" />
              </div>
              <p
                className="desktop-v2-about-row-content"
                style={{ marginBottom: 0 }}
              >
                {texts.height}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={bodytypeIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Bodytype</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          {showSelects.bodytype ? (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <select
                name="bodytype"
                id="bodytype"
                onChange={updateBodytype}
                className="desktop-v2-user-info-select"
              >
                <option
                  value=""
                  disabled
                  selected
                  hidden
                  className="desktop-v2-user-info-select-option"
                >
                  Select
                </option>
                <option>Slim</option>
                <option>Fit</option>
                <option>Average</option>
                <option>Curvy</option>
                <option>A Few Extra Pounds</option>
                <option>Full / Overweight</option>
                <option>Other</option>
              </select>
            </div>
          ) : (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <div
                className="desktop-v2-user-info-edit-button"
                onClick={() => toggleSelects("bodytype")}
              >
                <i className="far fa-pen user-info-edit-icon" />
              </div>
              <p
                className="desktop-v2-about-row-content"
                style={{ marginBottom: 0 }}
              >
                {texts.bodytype}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={ethnicityIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Ethnicity</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          <span className="desktop-v2-about-row-content">
            {showSelects.ethnicity ? (
              <div className="desktop-v2-about-row-user-action-wrapper">
                <select
                  name="ethnicity"
                  id="ethnicity"
                  onChange={updateEthnicity}
                  className="desktop-v2-user-info-select"
                >
                  <option
                    value=""
                    disabled
                    selected
                    hidden
                    className="desktop-v2-user-info-select-option"
                  >
                    Select
                  </option>
                  <option>White / Caucasian</option>
                  <option>Asian</option>
                  <option>Black / African Decent</option>
                  <option>Latin / Hispanic</option>
                  <option>East Indian</option>
                  <option>Middle Eastern</option>
                  <option>Mixed</option>
                  <option>Native American</option>
                  <option>Pacific Islander</option>
                  <option>Other</option>
                </select>
              </div>
            ) : (
              <div className="desktop-v2-about-row-user-action-wrapper">
                <div
                  className="desktop-v2-user-info-edit-button"
                  onClick={() => toggleSelects("ethnicity")}
                >
                  <i className="far fa-pen user-info-edit-icon" />
                </div>
                <p
                  className="desktop-v2-about-row-content"
                  style={{ marginBottom: 0 }}
                >
                  {texts.ethnicity}
                </p>
              </div>
            )}
          </span>
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={drinkingIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Drinking</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          {showSelects.drinking ? (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <select
                name="drinking"
                id="drinking"
                onChange={updateDrinking}
                className="desktop-v2-user-info-select"
              >
                <option
                  value=""
                  disabled
                  selected
                  hidden
                  className="desktop-v2-user-info-select-option"
                >
                  Select
                </option>
                <option>Non Drinker</option>
                <option>Social Drinker</option>
                <option>Heavy Drinker</option>
              </select>
            </div>
          ) : (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <div
                className="desktop-v2-user-info-edit-button"
                onClick={() => toggleSelects("drinking")}
              >
                <i className="far fa-pen user-info-edit-icon" />
              </div>
              <p
                className="desktop-v2-about-row-content"
                style={{ marginBottom: 0 }}
              >
                {texts.drinking}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={smokingIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Smoking</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          {showSelects.smoking ? (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <select
                name="smoking"
                id="smoking"
                onChange={updateSmoking}
                className="desktop-v2-user-info-select"
              >
                <option
                  value=""
                  disabled
                  selected
                  hidden
                  className="desktop-v2-user-info-select-option"
                >
                  Select
                </option>
                <option>Non Smoker</option>
                <option>Light Smoker</option>
                <option>Heavy Smoker</option>
              </select>
            </div>
          ) : (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <div
                className="desktop-v2-user-info-edit-button"
                onClick={() => toggleSelects("smoking")}
              >
                <i className="far fa-pen user-info-edit-icon" />
              </div>
              <p
                className="desktop-v2-about-row-content"
                style={{ marginBottom: 0 }}
              >
                {texts.smoking}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={educationIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Education</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          <span className="desktop-v2-about-row-content">
            {showSelects.education ? (
              <div className="desktop-v2-about-row-user-action-wrapper">
                <select
                  name="education"
                  id="education"
                  onChange={updateEducation}
                  className="desktop-v2-user-info-select"
                >
                  <option
                    value=""
                    disabled
                    selected
                    hidden
                    className="desktop-v2-user-info-select-option"
                  >
                    Select
                  </option>
                  <option>High School</option>
                  <option>Some College</option>
                  <option>Associates Degree</option>
                  <option>Bachelors Degree</option>
                  <option>Graduate Degree</option>
                  <option>PhD / Post Doctoral</option>
                </select>
              </div>
            ) : (
              <div className="desktop-v2-about-row-user-action-wrapper">
                <div
                  className="desktop-v2-user-info-edit-button"
                  onClick={() => toggleSelects("education")}
                >
                  <i className="far fa-pen user-info-edit-icon" />
                </div>
                <p
                  className="desktop-v2-about-row-content"
                  style={{ marginBottom: 0 }}
                >
                  {texts.education}
                </p>
              </div>
            )}
          </span>
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={childrenIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Kids</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          {showSelects.kids ? (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <select
                name="kids"
                id="kids"
                onChange={updateKids}
                className="desktop-v2-user-info-select"
              >
                <option
                  value=""
                  disabled
                  selected
                  hidden
                  className="desktop-v2-user-info-select-option"
                >
                  Select
                </option>
                <option>No Kids</option>
                <option>1 Kid</option>
                <option>2 Kids</option>
                <option>3 Kids</option>
                <option>4+ Kids</option>
              </select>
            </div>
          ) : (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <div
                className="desktop-v2-user-info-edit-button"
                onClick={() => toggleSelects("kids")}
              >
                <i className="far fa-pen user-info-edit-icon" />
              </div>
              <p
                className="desktop-v2-about-row-content"
                style={{ marginBottom: 0 }}
              >
                {texts.kids}
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="desktop-v2-about-row">
        <div className="desktop-v2-about-row-section">
          <div className="desktop-v2-about-row-icon-wrapper">
            <Image src={exerciseIcon} className="desktop-v2-about-row-icon" />
          </div>
          <h2 className="desktop-v2-about-row-title">Exercise</h2>
        </div>
        <div className="desktop-v2-about-row-section">
          {showSelects.exercise ? (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <select
                name="exercise"
                id="exercise"
                onChange={updateExercise}
                className="desktop-v2-user-info-select"
              >
                <option
                  value=""
                  disabled
                  selected
                  hidden
                  className="desktop-v2-user-info-select-option"
                >
                  Select
                </option>
                <option>Never</option>
                <option>Sometimes</option>
                <option>Regularly</option>
                <option>Often</option>
                <option>Daily</option>
              </select>
            </div>
          ) : (
            <div className="desktop-v2-about-row-user-action-wrapper">
              <div
                className="desktop-v2-user-info-edit-button"
                onClick={() => toggleSelects("exercise")}
              >
                <i className="far fa-pen user-info-edit-icon" />
              </div>
              <p
                className="desktop-v2-about-row-content"
                style={{ marginBottom: 0 }}
              >
                {texts.exercise}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DesktopUserPersonalInfo;
