import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { GoogleLogin } from "react-google-login";

import { api } from "../../api";
import { UserContext } from "../../context/UserContext";

import "./style.scss";

const Google = props => {
  const context = useContext(UserContext);
  const clientID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
  const onSuccess = (res) => {
    if (res.tt.$t === context.user.email) {
      const data = {
        option: "google",
      };
      return api.connectSocialNetwork(data)
        .then(() => {
          context.updateUserInfo("socials", "google");
          props.onCheck();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      props.inValid();
    }
  };

  const onFailure = (res) => {
    console.log("failure: ", res);
  };

  return (
    <GoogleLogin
      clientId={clientID}
      onSuccess={onSuccess}
      onFailure={onFailure}
      className="google-connect-button"
      icon={false}
    >
      Connect
    </GoogleLogin>
  );
};

export default withRouter(Google);