import React from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Image as NoneImage } from 'react-bootstrap';

import male_user from '../../../../../assets/Images/adminSidebar/male_user.png';
import female_user from '../../../../../assets/Images/adminSidebar/female_user.png';
import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../../../utils/getCloudinaryInfo';
import RiskScore from '../../../userTable/userTableElements/RiskScore';
import Media from '../../../../../components/media/Media';

import '../../../admincomponents.scss';

export const ReportUsers = (props) => {
  const { senderUser, receiverUser } = props.report;
  const senderImage =
    senderUser.imgurl && senderUser.imgurl.length !== 0 && senderUser.imgurl[0];
  const receiverImage =
    receiverUser.imgurl &&
    receiverUser.imgurl.length !== 0 &&
    receiverUser.imgurl[0];

  return (
    <div className="report-users-info">
      <div className="report-users-info-title">
        Users
        <span
          className="icon icon-close report-close"
          onClick={() => props.closeInfoHandler()}
        ></span>
      </div>
      <div className="report-userinfo-drawer-header">
        {senderImage ? (
          <Media image={senderImage} />
        ) : (
          // <Image
          //   publicId={getImgName(senderImage)}
          //   version={getImgVersion(senderImage)}
          //   resource_type={checkGIF(senderImage) ? "video" : "image"}
          //   effect="loop"
          //   cloud_name="sugarbae"
          //   className="img-userinfo"
          // >
          //   <Transformation
          //     height="200"
          //     video_sampling="20"
          //     delay="250"
          //     duration="10"
          //     effect="loop"
          //   />
          // </Image>
          <div className="none-image-container">
            <NoneImage
              className="none-user-image"
              src={senderUser.gender === 'male' ? male_user : female_user}
            />
          </div>
        )}
        <div className="userinfo-top">
          <p className="userinfo-username">{senderUser.username}</p>
          <div className="userinfo-riskscore">
            <p className="userinfo-riskscore-title">Risk Score:</p>
            <RiskScore
              riskScore={
                senderUser.risk ? Math.trunc(senderUser.risk.score * 100) : 0
              }
            />
          </div>
        </div>
      </div>
      <div className="report-userinfo-drawer-header">
        {receiverImage ? (
          <Media image={receiverImage} />
        ) : (
          // <Image
          //   publicId={getImgName(receiverImage)}
          //   version={getImgVersion(receiverImage)}
          //   resource_type={checkGIF(receiverImage) ? 'video' : 'image'}
          //   effect="loop"
          //   cloud_name="sugarbae"
          //   className="img-userinfo"
          // >
          //   <Transformation
          //     height="200"
          //     video_sampling="20"
          //     delay="250"
          //     duration="10"
          //     effect="loop"
          //   />
          // </Image>
          <div className="none-image-container">
            <NoneImage
              className="none-user-image"
              src={receiverUser.gender === 'male' ? male_user : female_user}
            />
          </div>
        )}
        <div className="userinfo-top">
          <p className="userinfo-username">{receiverUser.username}</p>
          <div className="userinfo-riskscore">
            <p className="userinfo-riskscore-title">Risk Score:</p>
            <RiskScore
              riskScore={
                receiverUser.risk
                  ? Math.trunc(receiverUser.risk.score * 100)
                  : 0
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportUsers;
