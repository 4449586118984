import React, { useState, useEffect, useContext } from "react";
import { Image } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";
import textIcon from "../../../../assets/Icons/notifications/textIcon.png";
import textRedIcon from "../../../../assets/Icons/notifications/textRedIcon.png";
import emailIcon from "../../../../assets/Icons/notifications/emailIcon.png";
import emailRedIcon from "../../../../assets/Icons/notifications/emailRedIcon.png";

function Notifications(props) {
  const context = useContext(UserContext);
  const [preferences, setPreferences] = useState(context.user.preferences);

  const toggleNotifications = (e) => {
    const preferencesCopy = preferences;
    const { email } = preferencesCopy;
    const { text } = preferencesCopy;

    if (e === "textMessages") {
      if (text.messages) {
        text.messages = false;
      } else {
        text.messages = true;
      }
    } else if (e === "emailMessages") {
      if (email.messages) {
        email.messages = false;
      } else {
        email.messages = true;
      }
    } else if (e === "textVisits") {
      if (text.visits) {
        text.visits = false;
      } else {
        text.visits = true;
      }
    } else if (e === "emailVisits") {
      if (email.visits) {
        email.visits = false;
      } else {
        email.visits = true;
      }
    } else if (e === "textMatches") {
      if (text.matches) {
        text.matches = false;
      } else {
        text.matches = true;
      }
    } else if (e === "emailMatches") {
      if (email.matches) {
        email.matches = false;
      } else {
        email.matches = true;
      }
    } else if (e === "emailFavorites") {
      if (email.favorites) {
        email.favorites = false;
      } else {
        email.favorites = true;
      }
    } else if (e === "textFavorites") {
      if (text.favorites) {
        text.favorites = false;
      } else {
        text.favorites = true;
      }
    }

    preferencesCopy.email = email;
    preferencesCopy.text = text;

    setPreferences((prevState) => ({ ...prevState, ...preferencesCopy }));

    context.updatePreferences(preferencesCopy);
  };

  return (
    <div className="desktop-v2-settings-content">
      <div className="desktop-v2-settings-row">
        <div className="desktop-v2-settings-row-innerwrap">
          <div className="desktop-v2-settings-row-section">
            <span className="desktop-v2-settings-row-title">
              When Someone sends me a message
            </span>
          </div>
          <div className="desktop-v2-settings-row-section">
            <div
              className="desktop-v2-settings-notification-button"
              onClick={() => toggleNotifications("textMessages")}
            >
              <Image
                src={preferences.text.messages ? textRedIcon : textIcon}
                fluid
                className="desktop-v2-settings-notification-icon"
              />
            </div>
            <div
              className="desktop-v2-settings-notification-button"
              onClick={() => toggleNotifications("emailMessages")}
            >
              <Image
                src={preferences.email.messages ? emailRedIcon : emailIcon}
                fluid
                className="desktop-v2-settings-notification-icon"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="desktop-v2-settings-row">
        <div className="desktop-v2-settings-row-innerwrap">
          <div className="desktop-v2-settings-row-section">
            <span className="desktop-v2-settings-row-title">
              When someone views my profile
            </span>
          </div>
          <div className="desktop-v2-settings-row-section">
            <div
              className="desktop-v2-settings-notification-button"
              onClick={() => toggleNotifications("textVisits")}
            >
              <Image
                src={preferences.text.visits ? textRedIcon : textIcon}
                fluid
                className="desktop-v2-settings-notification-icon"
              />
            </div>
            <div
              className="desktop-v2-settings-notification-button"
              onClick={() => toggleNotifications("emailVisits")}
            >
              <Image
                src={preferences.email.visits ? emailRedIcon : emailIcon}
                fluid
                className="desktop-v2-settings-notification-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="desktop-v2-settings-row">
        <div className="desktop-v2-settings-row-innerwrap">
          <div className="desktop-v2-settings-row-section">
            <span className="desktop-v2-settings-row-title">
              When someone likes me
            </span>
          </div>
          <div className="desktop-v2-settings-row-section">
            <div
              className="desktop-v2-settings-notification-button"
              onClick={() => toggleNotifications("textFavorites")}
            >
              <Image
                src={preferences.text.favorites ? textRedIcon : textIcon}
                fluid
                className="desktop-v2-settings-notification-icon"
              />
            </div>
            <div
              className="desktop-v2-settings-notification-button"
              onClick={() => toggleNotifications("emailFavorites")}
            >
              <Image
                src={preferences.email.favorites ? emailRedIcon : emailIcon}
                fluid
                className="desktop-v2-settings-notification-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="desktop-v2-settings-row">
        <div className="desktop-v2-settings-row-innerwrap">
          <div className="desktop-v2-settings-row-section">
            <span className="desktop-v2-settings-row-title">
              When I get a new match
            </span>
          </div>
          <div className="desktop-v2-settings-row-section">
            <div
              className="desktop-v2-settings-notification-button"
              onClick={() => toggleNotifications("textMatches")}
            >
              <Image
                src={preferences.text.matches ? textRedIcon : textIcon}
                fluid
                className="desktop-v2-settings-notification-icon"
              />
            </div>
            <div
              className="desktop-v2-settings-notification-button"
              onClick={() => toggleNotifications("emailMatches")}
            >
              <Image
                src={preferences.email.matches ? emailRedIcon : emailIcon}
                fluid
                className="desktop-v2-settings-notification-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notifications;
