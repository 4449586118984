import React from 'react';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import './spotlightcard.scss';
import Media from '../../../media/Media';

function SpotlightCard(props) {
  var images = props.user.imgurl;
  var mainImage = null;
  if (images.length > 0) {
    mainImage = images[0];
  }

  const toggleProfile = (e) => {
    props.onClick(props.user);
  };
  return <Media image={mainImage} />;
}

export default SpotlightCard;
