import React, { Component } from "react";
import { Button, Card, Image, ProgressBar, Alert } from "react-bootstrap";
import Axios from "axios";
import BounceLoader from "react-spinners/BounceLoader";
import { arrayMove } from "react-sortable-hoc";
import S3 from "react-aws-s3";

import { UserContext } from "../../../../../context/UserContext";
import PhotoContainer from "./PhotoContainer";

import "./photogrid.scss";
import { api } from "../../../../../api";

var imageTypes = [
  "image/webp",
  "image/heif",
  "image/heic",
  "image/jpg",
  "image/jpe",
  "image/jpeg",
  "image/png",
  "image/pdf",
  "image/jp2",
  "image/wdp",
  "image/jxr",
  "image/hdp",
  "image/svg",
  "image/tif",
  "image/tiff",
];

var videoTypes = [
  "image/gif",
  "video/flv",
  "video/avi",
  "video/m3u8",
  "video/ts",
  "video/m2ts",
  "video/mts",
  "video/mov",
  "video/mkv",
  "video/mp4",
  "video/mpeg",
  "video/mpd",
  "video/webm",
  "video/wmv",
  "video/x-matroska",
  "video/quicktime",
];

const config = {
  bucketName: process.env.REACT_APP_AWS_S3_BUCKET,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEYID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESSKEY,
};

const ReactS3Client = new S3(config);

export class PhotoGrid extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      images: [],
      extraImages: [],
      files: [],
      uploading: false,
      uploadPercentage: 0,
      index: 0,
      length: 0,
      isAccepted: true,
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  fetchData = async (e) => {
    const token = localStorage.getItem("auth");
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + "/api/upload/getimages";
    const data = {
      userid: this.context.user._id,
    };

    return api
      .fetchImages(data)
      .then((res) => {
        var images = res.data.images;
        var extraImages = [];

        if (images.length > 6) {
          extraImages = images.slice(6);
          images = images.slice(0, 6);
        }

        this.setState({ images: images, extraImages: extraImages });
      })
      .catch((err) => {});
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  fileChange = async (e) => {
    if (this.props.option === "welcome" && this.state.images.length === 0) {
      this.props.onCheck();
    }
    const files = e.target.files;
    if (files.length === 0) return;
    const filesArray = [];
    for (const key of Object.keys(files)) {
      filesArray.push(files);
    }
    const length = filesArray.length;
    var count = 0;

    this.setState(
      {
        uploading: true,
        length: length,
        uploadPercentage: 0,
        index: 0,
        isAccepted: true,
      },
      async () => {
        const uploaders = filesArray.map(async (file, index) => {
          var fd = new FormData();
          var filetype = file[index].type;
          if (file[index].name.slice(-3) === "flv") {
            filetype = "video/flv";
          } else if (file[index].name.slice(-3) === "mkv") {
            filetype = "video/mkv";
          } else if (file[index].name.slice(-3) === "flv") {
            filetype = "video/mov";
          }
          var url = "";
          fd.append("file", file[index]);
          fd.append("api_key", "323661161668594");
          fd.append("timestamp", (Date.now() / 1000) | 0);

          if (imageTypes.includes(filetype)) {
            var bytes = "";
            await this.getBase64(file[index]).then((data) => {
              bytes = data;
            });

            var success = true;
            await api
              .imageRekognition(bytes.split(",")[1])
              .then((res) => {
                var { data } = res;
                if (data === "unacceptable") {
                  success = false;
                  this.setState({ isAccepted: false });
                }
              })
              .catch((err) => console.log(err));

            if (!success) {
              this.setState({ uploading: false });
              return;
            }
            url = "https://api.cloudinary.com/v1_1/sugarbae/image/upload";
            fd.append("upload_preset", "txv5v2xj");
          }

          if (videoTypes.includes(filetype)) {
            url = "https://api.cloudinary.com/v1_1/sugarbae/video/upload";
            fd.append("upload_preset", "om70jgxq");
          }

          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let percent = Math.floor((loaded * 100) / total);
              this.setState({ uploadPercentage: percent, index: index });
            },
            withCredentials: false,
          };

          await Axios.post(url, fd, options, {
            headers: { "X-Requested-With": "XMLHttpRequest" },
          })
            .then(async (res) => {
              count = count + 1;
              var imgurl = res.data.secure_url;
              if (videoTypes.includes(filetype)) {
                imgurl = imgurl.slice(0, -3);
                imgurl = imgurl + "gif";
                const firstHalf = imgurl.slice(0, 49);
                const secondHalf = imgurl.slice(49);
                const settings = "vs_20,dl_250,h_200,e_loop,du_10/";
                const fullUrl = firstHalf + settings + secondHalf;
                imgurl = fullUrl.slice(0, -3) + "gif";

                // detect text and check content of video
                let isPassed = true;
                let keyText = null;
                let keyContent = null;
                const uploadTextVideoPromise = ReactS3Client.uploadFile(
                  file[index],
                  `${this.context.user._id}text`
                )
                  .then((res) => (keyText = res.key))
                  .catch((err) => console.error(err));
                const uploadContentVideoPromise = ReactS3Client.uploadFile(
                  file[index],
                  `${this.context.user._id}content`
                )
                  .then((res) => (keyContent = res.key))
                  .catch((err) => console.error(err));
                const uploadPromises = [
                  uploadTextVideoPromise,
                  uploadContentVideoPromise,
                ];
                await Promise.all(uploadPromises);

                const dataKey = {
                  key: keyText,
                  imgurl,
                  userid: this.context.user._id,
                };
                const dataContent = {
                  key: keyContent,
                  imgurl,
                  userid: this.context.user._id,
                };
                const detectTextPromise = api
                  .videoRekognitionText(dataKey)
                  .then((res) => {
                    if (!res.data.isPassed) {
                      isPassed = false;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                const detectModerationPromise = api
                  .videoRekognitionContent(dataContent)
                  .then((res) => {
                    if (!res.data.isPassed) {
                      isPassed = false;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                const checkVideoPromise = [
                  detectTextPromise,
                  detectModerationPromise,
                ];
                await Promise.all(checkVideoPromise);

                if (!isPassed) {
                  return this.setState({ isAccepted: false, uploading: false });
                }
              }

              var images = this.state.images;
              var extraImages = this.state.extraImages;
              var uploading = true;

              if (images.length > 5) {
                extraImages.push(imgurl);
              } else {
                images.push(imgurl);
              }

              if (count === length) {
                uploading = false;
              }

              this.setState(
                {
                  images: images,
                  extraImages: extraImages,
                  uploading: uploading,
                },
                () => {
                  this.saveImages();
                }
              );
            })
            .catch((err) => {
              console.log(err);
            });
        });
      }
    );
  };

  fillAmount = (images) => {
    const array = Array(6).fill(null);
    images.map((item, index) => {
      array[index] = item;
      return item;
    });

    return array;
  };

  photoRemoveHandler = (imgUrl) => {
    var images = this.state.images;
    var extraImages = this.state.extraImages;
    var index = images.indexOf(imgUrl);
    images.splice(index, 1);

    if (extraImages.length > 0) {
      var shiftImage = extraImages.shift();
      images.push(shiftImage);
    }

    this.setState({ images: images, extraImages: extraImages }, () => {
      this.saveImages();
    });
  };

  extraPhotoRemoveHandler = (props) => (e) => {
    var images = this.state.extraImages;
    var index = images.indexOf(props);
    images.splice(index, 1);
    this.setState({ extraImages: images }, () => {
      this.saveImages();
    });
  };

  saveImages = async (e) => {
    var images = this.state.images.concat(this.state.extraImages);
    const data = {
      userid: this.context.user._id,
      images: images,
    };

    return api
      .saveImages(data)
      .then((res) => {})
      .catch((err) => {});
  };

  uploadClickHandler = (e) => {
    this.fileInput.click();
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState({ images: arrayMove(this.state.images, oldIndex, newIndex) });
    this.saveImages();
  };

  render() {
    const showImages = this.fillAmount(this.state.images);
    return (
      <Card className="photo-grid-component">
        {!this.state.isAccepted && (
          <Alert variant="danger">
            Some of your images(videos) were not approved. It must not contain
            text or adult content.
          </Alert>
        )}
        <Card.Body className="photo-grid-body">
          {this.state.uploading ? (
            <div className="photo-grid-uploading-container">
              <Card className="upload-progress-card">
                <Card.Body className="upload-progress-card-body">
                  <BounceLoader size="20" loading={this.state.uploading} />
                  <h4 className="upload-progress-title">Uploading...</h4>
                  <ProgressBar
                    animated
                    now={this.state.uploadPercentage}
                    label={this.state.uploadPercentage}
                    className="upload-progress-bar"
                  />
                </Card.Body>
              </Card>
            </div>
          ) : null}
          <input
            type="file"
            accept=".gif,.webp,.heif,.heic,.jpg,.jpe,.jpeg,.png,.pdf,.jp2,.wdp,.jxr,.hdp,,.svg,.tif,.tiff,.flv,.avi,.m3u8,.ts,.m2ts,.mts,.mov,.mkv,.mp4,.mpeg,mpd,.webm,.wmv"
            multiple
            onChange={this.fileChange}
            className="photo-grid-input"
            name="photo-input"
            id="photo-input"
            ref={(fileInput) => (this.fileInput = fileInput)}
          />
          <PhotoContainer
            images={showImages}
            removeHandler={this.photoRemoveHandler}
            uploadHandler={this.uploadClickHandler}
            onSortEnd={this.onSortEnd}
            axis={"xy"}
            pressDelay={1}
          />
          {this.state.extraImages.length > 0 ? (
            <div className="photo-grid-body">
              {this.state.extraImages.map((img, index) => {
                return (
                  <label
                    for="photo-input"
                    className="photo-grid-label"
                    onClick={this.labelClick}
                  >
                    <Card className="photo-upload-card">
                      <Image className="photo-upload-image" src={img} />
                      <Button
                        className="photo-upload-btn"
                        onClick={this.extraPhotoRemoveHandler(img)}
                      >
                        <i className="far fa-minus photo-upload-icon"></i>
                      </Button>
                    </Card>
                  </label>
                );
              })}
            </div>
          ) : null}
        </Card.Body>
        <Card.Footer className="photo-grid-footer">
          <p className="photogrid-footer-text">
            Hold, drag and drop to reorder your photos.
          </p>
        </Card.Footer>
      </Card>
    );
  }
}

export default PhotoGrid;
