import React, { useEffect, useState } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Badge } from 'react-bootstrap';

import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../../../utils/getCloudinaryInfo';
import { api } from '../../../../../api';

import '../../../admincomponents.scss';
import Media from '../../../../../components/media/Media';

export const ImageCard = (props) => {
  const { users, index } = props;
  const { imgurl } = users[index];
  const mainImage = imgurl[0];
  const [approved, setApproved] = useState(false);

  useEffect(() => {
    const check = users[index].approvedimgurl.findIndex(
      (img) => img === mainImage
    );
    if (check > -1) {
      setApproved(true);
    }
  }, [index]);

  const removeHandler = () => {
    const data = {
      user: users[index],
      image: mainImage,
    };

    return api
      .removeImage(data)
      .then(() => {
        let temp = users;
        if (temp[index].imgurl.length === 1) {
          temp.splice(index, 1);
        } else {
          temp[index].imgurl.splice(0, 1);
        }
        setApproved(false);
        props.declineHandler(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const approveHandler = () => {
    if (approved) {
      return null;
    }
    const data = {
      user: users[index],
      image: mainImage,
    };

    return api
      .approveImage(data)
      .then(() => {
        let temp = users;
        temp[index].approvedimgurl.push(mainImage);
        props.declineHandler(temp);
        setApproved(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="image-card">
      {approved && (
        <Badge variant="success" className="image-card-badge">
          Approved
        </Badge>
      )}

      <div onClick={() => props.selectHandler(props.index)}>
        <Media image={mainImage} />
      </div>

      {/* <Image
        publicId={getImgName(mainImage)}
        version={getImgVersion(mainImage)}
        resource_type={checkGIF(mainImage) ? "video" : "image"}
        effect="loop"
        cloud_name="sugarbae"
        className="img-card"
        onClick={() => props.selectHandler(props.index)}
      >
        <Transformation
          height="200"
          video_sampling="20"
          delay="250"
          duration="10"
          effect="loop"
        />
      </Image> */}
      <div className="image-card-footer">
        <div className="image-card-decline" onClick={() => removeHandler()}>
          <i className="fas fa-times"></i>
        </div>
        <div className="image-card-approve" onClick={() => approveHandler()}>
          <i className="fas fa-thumbs-up"></i>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
