import React, { useEffect, useRef, useState, useContext } from "react";
import { Image, Transformation } from "cloudinary-react";

import MainImage from "../mainImage/MainImage";
import LookingFor from "../../../profile/profileV2Components/lookingFor/LookingFor";
import PhotoGrid from "../../../profile/profileV2Components/photoGrid/PhotoGrid";
import Verifications from "../../../profile/profileV2Components/verifications/Verifications";
import Socials from "../../../profile/profileV2Components/socials/Socials";

import likeStamp from "../../../../assets/stamps/like-stamp-circle.png";
import dislikeStamp from "../../../../assets/stamps/dislike-stamp-circle.png";
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from "../../../../utils/getCloudinaryInfo";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";
import ProgressBar from "../../../progressBar";

import Swipeable from "../../Swipeable";

import "./swipeprofile.scss";
import About from "../../../profile/profileV2Components/about/About";
import { Button } from "react-bootstrap";
import ProfileV2Footer from "../../../profile/profileV2Components/footer/ProfileV2Footer";
import ComplimentModal from "../../../modals/complimentModal/ComplimentModal";
import PhotoGridV2 from "../../../photoGrid/PhotoGridV2";

const THRESHOLD = 0.25;
const MAX_ANGLE = 20;
const OUT_OF_SIGHT_FACTOR = 2;

export const SwipeProfile = (props) => {
  const context = useContext(UserContext);

  const scrollContainerRef = useRef();

  const [enableLike, setEnableLike] = useState(true);
  const [swipeProgress, setSwipeProgress] = useState(0);
  const [returning, setReturning] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [complimentModalOpen, setComplimentModalOpen] = useState(false);

  useEffect(() => {
    const checkLikesLimit = async () => {
      const data = {
        userid: context.user._id,
        cardid: null,
      };

      return await api
        .swipeLikeCheck(data)
        .then((res) => {
          setEnableLike(res.data.valid);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    checkLikesLimit();
  }, []);
  // TODO: uncomment this if we need to show ScrollProgress

  // const scrollProgressHandler = (e) => {
  //   var scrollContainer = document.getElementById("scroll-container");
  //   var scrollHeight = scrollContainer.scrollHeight;
  //   var clientHeight = scrollContainer.clientHeight;
  //   var subtractedHeight = scrollHeight - clientHeight;
  //   var scrollTop = scrollContainer.scrollTop;
  //   var scrollProgress = (scrollTop / subtractedHeight) * 100;
  //   // this.setState({ scrollProgress: scrollProgress });
  //   setScrollProgress(scrollProgress);
  // };

  useEffect(() => {
    scrollContainerRef.current.wrapper.scrollTop = 0;
    props.setScrolled(false);
  }, [props.card]);

  const likedByUsers = props.likedByUsers;
  const superlikedByUsers = props.superlikedByUsers;
  const noteUsers = props.noteUsers;

  const cardid = props.card._id;

  let likedBanner = false;
  let superlikedBanner = false;
  let noteBanner = false;
  let borderStyle = "";

  if (props.currentIndex === props.cardIndex) {
    if (noteUsers.includes(cardid)) {
      noteBanner = true;
      likedBanner = false;
      superlikedBanner = false;
    } else if (superlikedByUsers.includes(cardid)) {
      noteBanner = false;
      likedBanner = false;
      superlikedBanner = true;
    } else if (likedByUsers.includes(cardid)) {
      noteBanner = false;
      likedBanner = true;
      superlikedBanner = false;
    }

    if (noteBanner) {
      borderStyle = "5px solid #f75590";
    } else if (superlikedBanner) {
      borderStyle = "5px solid #32b5ff";
    } else if (likedBanner) {
      borderStyle = "5px solid #55eac4";
    }
  }

  const imgurl = props.card.imgurl;
  const length = imgurl.length;

  // Swipe motion

  let wrapperWidth = window.innerWidth;
  if (scrollContainerRef.current) {
    wrapperWidth = scrollContainerRef.current.wrapper.offsetWidth;
  }
  const rotate = (swipeProgress / wrapperWidth) * MAX_ANGLE; // 20 means maximum degrees
  const likeStampOpacity = Math.min(
    1,
    Math.max(0, swipeProgress) / (wrapperWidth * THRESHOLD)
  );
  const dislikeStampOpacity = Math.min(
    1,
    Math.abs(Math.min(0, swipeProgress)) / (wrapperWidth * THRESHOLD)
  );

  const likeOverlayOpacity = likeStampOpacity / 2;
  const dislikeOverlayOpactiy = dislikeStampOpacity / 2;

  const handleSwipeStart = () => {
    setReturning(false);
  };

  const handleSwipedLeft = () => {
    setTimeout(() => {
      props.handleDislike(true, props.currentIndex);
    }, 100);

    setReturning(true);
    setSwipeProgress(-wrapperWidth * OUT_OF_SIGHT_FACTOR);
  };

  const handleSwipedRight = () => {
    setTimeout(() => {
      props.handleLike(true, props.currentIndex);
    }, 100);

    setReturning(true);
    setSwipeProgress(wrapperWidth * OUT_OF_SIGHT_FACTOR);
  };

  const handleSwipeReturn = () => {
    setReturning(true);
    setSwipeProgress(0);
  };

  const scrollHandler = () => {
    const scrollTop = scrollContainerRef.current.wrapper.scrollTop;
    const height =
      scrollContainerRef.current.wrapper.scrollHeight -
      scrollContainerRef.current.wrapper.offsetHeight;
    setScrollProgress((scrollTop / height) * 100);

    if (scrollTop > 25) {
      props.setScrolled(true);
    } else {
      props.setScrolled(false);
    }
  };

  const modalToggle = (e) => {
    if (complimentModalOpen) {
      setComplimentModalOpen(false);
    } else {
      setComplimentModalOpen(true);
    }
  };

  const reportHandler = (e) => {
    handleSwipedLeft();
  };

  const banHandler = (e) => {
    // handleSwipedLeft();
    context.removeBannedUserFromContext(e);
  };

  return (
    <div onScroll={scrollHandler} className="swipe-profile-component-x">
      {complimentModalOpen && (
        <ComplimentModal
          show={complimentModalOpen}
          modalToggle={modalToggle}
          profileUser={props.card}
        />
      )}

      <img
        src={likeStamp}
        alt="Like"
        className={`like-stamp-image ${returning ? "swipeable--return" : ""}`}
        style={{
          ...props.likeStampPosition,
          transform: `translateX(${swipeProgress}px)`,
          opacity: likeStampOpacity,
        }}
      />
      <img
        src={dislikeStamp}
        alt="Dislike"
        className={`dislike-stamp-image ${
          returning ? "swipeable--return" : ""
        }`}
        style={{
          ...props.dislikeStampPosition,
          transform: `translateX(${swipeProgress}px)`,
          opacity: dislikeStampOpacity,
        }}
      />
      <ProgressBar percent={scrollProgress} />
      <Swipeable
        className="swipe-profile-container"
        // style={{ border: borderStyle }}
        blockSwipe={props.submitting}
        onSwipeStart={handleSwipeStart}
        onSwipeProgress={setSwipeProgress}
        onSwipedLeft={handleSwipedLeft}
        onSwipedRight={handleSwipedRight}
        onSwipeReturn={handleSwipeReturn}
        checkEnableLike={props.checkEnableLike}
        enableLike={enableLike}
        returning={returning}
        threshold={THRESHOLD}
        transform={`translateX(${swipeProgress}px) rotate(${rotate}deg)`}
        ref={scrollContainerRef}
      >
        <div
          className="swipe-like-overlay"
          style={{ opacity: likeOverlayOpacity }}
        />
        <div
          className="swipe-dislike-overlay"
          style={{ opacity: dislikeOverlayOpactiy }}
        />
        <MainImage
          card={props.card}
          noActions={false}
          submitting={props.submitting}
        />

        <div className="swipe-profile-container-innerwrap">
          <div className="swipe-profile-inner-innerwrap">
            <About profileUser={props.card} />
            <LookingFor profileUser={props.card} />
            <PhotoGridV2 profileUser={props.card} />
            {/* <Verifications profileUser={props.card} />
            <Socials profileUser={props.card} /> */}
            <ProfileV2Footer
              profileUser={props.card}
              modalToggle={modalToggle}
              reportHandler={reportHandler}
              banHandler={banHandler}
            />
          </div>
        </div>
      </Swipeable>
    </div>
  );
};

export default SwipeProfile;
