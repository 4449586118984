import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";

import { Image } from "react-bootstrap";
import WelcomeV2 from "../../components/welcome/welcomeV2/WelcomeV2";
import PhotosV2 from "../../components/welcome/photosV2/PhotosV2";
import PhysicalDetail from "../../components/welcome/physicalDetail/PhysicalDetail";
import BenefitsV2 from "../../components/welcome/benefitsV2/BenefitsV2";
import About from "../../components/welcome/about/About";
import LookingFor from "../../components/welcome/lookingFor/LookingFor";
import Premium from "../../components/premium/Premium";
import "./welcomepagev2.scss";
import homeLogo from "../../assets/logos/homeLogo.png";

const WelcomePageV2 = (props) => {
  const history = useHistory();
  const { pathname } = history.location;

  const [step, setStep] = useState(1);
  const [screen, setScreen] = useState("");

  const vh = window.innerHeight;
  const vw = window.innerWidth;
  const height = `${vh}px`;

  const nextStep = () => {
    if (pathname === "/app/welcome/photos") {
      history.push("/app/welcome/physicals");
    }
  };

  useEffect(() => {
    if (
      pathname === "/app/welcome" ||
      pathname === "/app/welcome/2" ||
      pathname === "/app/welcome/3" ||
      pathname === "/app/welcome/4"
    ) {
      setScreen("welcome");
    } else if (pathname === "/app/welcome/photos") {
      setScreen("photos");
    } else if (pathname === "/app/welcome/physicals") {
      setScreen("physicals");
    } else if (pathname === "/app/welcome/benefits") {
      setScreen("benefits");
    } else if (pathname === "/app/welcome/lookingfor") {
      setScreen("lookingfor");
    } else if (pathname === "/app/welcome/about") {
      setScreen("about");
    }
  }, [pathname]);

  if (vw > 991) {
    return (
      <div className="welcome-page-v2-component">
        <div className="welcome-page-v2-header">
          <Image src={homeLogo} className="welcome-page-v2-logo" fluid />
        </div>
        <div className="welcome-page-v2-content">
          {screen === "welcome" && <WelcomeV2 nextStep={nextStep} />}
          {screen === "photos" && <PhotosV2 nextStep={nextStep} />}
          {screen === "physicals" && <PhysicalDetail nextStep={nextStep} />}
          {screen === "benefits" && <BenefitsV2 nextStep={nextStep} />}
          {screen === "about" && <About />}
          {screen === "lookingfor" && <LookingFor />}
          {screen === "premium" && <Premium />}
        </div>
      </div>
    );
  }

  return (
    <div className="app-component" style={{ height, maxHeight: height }}>
      {screen === "welcome" && <WelcomeV2 nextStep={nextStep} />}
      {screen === "photos" && <PhotosV2 nextStep={nextStep} />}
      {screen === "physicals" && <PhysicalDetail nextStep={nextStep} />}
      {screen === "benefits" && <BenefitsV2 nextStep={nextStep} />}
      {screen === "about" && <About />}
      {screen === "lookingfor" && <LookingFor />}
      {screen === "premium" && <Premium />}
    </div>
  );
};

export default withRouter(WelcomePageV2);
