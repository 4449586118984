import React, { useState, useEffect, useContext } from "react";
import { Image, Button } from "react-bootstrap";
import { UserContext } from "../../../context/UserContext";

import placeholder from "../../../../src/assets/Images/premiumFeaturesSlider/slide-one-unlimited-likes.jpg";
import stepOneImageMale from "../../../assets/Images/welcome/stepOneImageMale.png";
import { withRouter, useHistory } from "react-router-dom";
import maleOnboardingOne from "../../../assets/Images/welcome/male/maleOnboardingOne.png";
import maleOnboardingTwo from "../../../assets/Images/welcome/male/maleOnboardingTwo.png";
import maleOnboardingThree from "../../../assets/Images/welcome/male/maleOnboardingThree.png";
import maleOnboardingFour from "../../../assets/Images/welcome/male/maleOnboardingFour.png";
import femaleOnboardingOne from "../../../assets/Images/welcome/male/femaleOnboardingOne.png";
import femaleOnboardingTwo from "../../../assets/Images/welcome/male/femaleOnboardingTwo.png";
import femaleOnboardingThree from "../../../assets/Images/welcome/male/femaleOnboardingThree.png";
import femaleOnboardingFour from "../../../assets/Images/welcome/male/femaleOnboardingFour.png";
import maleIconOne from "../../../assets/Images/welcome/male/maleIconOne.png";
import maleIconTwo from "../../../assets/Images/welcome/male/maleIconTwo.png";
import maleIconThree from "../../../assets/Images/welcome/male/maleIconThree.png";
import maleIconFour from "../../../assets/Images/welcome/male/maleIconFour.png";
import femaleIconOne from "../../../assets/Images/welcome/male/femaleIconOne.png";
import femaleIconTwo from "../../../assets/Images/welcome/male/femaleIconTwo.png";
import femaleIconThree from "../../../assets/Images/welcome/male/femaleIconThree.png";
import femaleIconFour from "../../../assets/Images/welcome/male/femaleIconFour.png";

import "../welcomev2.scss";

const WelcomeV2 = (props) => {
  const context = useContext(UserContext);
  let history = useHistory();
  const [step, setStep] = useState(1);

  let pathname = history.location.pathname;

  let indicatorOne = "welcome-v2-indicator";
  let indicatorTwo = "welcome-v2-indicator";
  let indicatorThree = "welcome-v2-indicator";
  let indicatorFour = "welcome-v2-indicator";

  if (step === 1) {
    indicatorOne = "welcome-v2-indicator-active";
  } else if (step === 2) {
    indicatorTwo = "welcome-v2-indicator-active";
  } else if (step === 3) {
    indicatorThree = "welcome-v2-indicator-active";
  } else if (step === 4) {
    indicatorFour = "welcome-v2-indicator-active";
  }

  const nextStep = () => {
    if (step === 1) {
      history.push("/app/welcome/2");
    } else if (step === 2) {
      history.push("/app/welcome/3");
    } else if (step === 3) {
      history.push("/app/welcome/4");
    } else if (step === 4) {
      history.push("/app/welcome/photos");
    }
  };

  useEffect(() => {
    if (pathname === "/app/welcome") {
      setStep(1);
    } else if (pathname === "/app/welcome/2") {
      setStep(2);
    } else if (pathname === "/app/welcome/3") {
      setStep(3);
    } else if (pathname === "/app/welcome/4") {
      setStep(4);
    }
  }, [pathname]);

  return (
    <div className="welcome-v2-container">
      <div className="welcome-v2-header">
        <div className="welcome-v2-title">
          <h1 className="welcome-v2-title-one">Welcome to</h1>
          <h2 className="welcome-v2-title-two">Sugarbae</h2>
        </div>
        <p className="welcome-v2-subtitle">Ready to find your perfect match?</p>
        <p className="welcome-v2-subtitle">Here's how it works...</p>
      </div>
      <div className="welcome-v2-body">
        {context.user.gender === "male" ? (
          <div className="welcome-v2-main-image">
            {step === 1 && (
              <Image src={maleOnboardingOne} className="welcome-v2-image" />
            )}
            {step === 2 && (
              <Image src={maleOnboardingTwo} className="welcome-v2-image" />
            )}
            {step === 3 && (
              <Image src={maleOnboardingThree} className="welcome-v2-image" />
            )}
            {step === 4 && (
              <Image src={maleOnboardingFour} className="welcome-v2-image" />
            )}
          </div>
        ) : (
          <div className="welcome-v2-main-image">
            {step === 1 && (
              <Image src={femaleOnboardingOne} className="welcome-v2-image" />
            )}
            {step === 2 && (
              <Image src={femaleOnboardingTwo} className="welcome-v2-image" />
            )}
            {step === 3 && (
              <Image src={femaleOnboardingThree} className="welcome-v2-image" />
            )}
            {step === 4 && (
              <Image src={femaleOnboardingFour} className="welcome-v2-image" />
            )}
          </div>
        )}

        <div className="welcome-v2-content">
          {step === 1 && (
            <div className="welcome-v2-description">
              {context.user.gender === "male" ? (
                <Image src={maleIconOne} className="welcome-step-icon" />
              ) : (
                <Image src={femaleIconOne} className="welcome-step-icon" />
              )}
              {context.user.gender === "male" ? (
                <div className="welcome-v2-description-body">
                  <h1 className="welcome-v2-description-title">
                    40x More Attention
                  </h1>
                  <p className="welcome-v2-description-text">
                    On Sugarbae, Male users get 40x more attention than other
                    dating apps
                  </p>
                </div>
              ) : (
                <div className="welcome-v2-description-body">
                  <h1 className="welcome-v2-description-title">Get Benefits</h1>
                  <p className="welcome-v2-description-text">
                    On Sugarbae, Women get benefits for interacting with
                    successful men
                  </p>
                </div>
              )}
            </div>
          )}
          {step === 2 && (
            <div className="welcome-v2-description">
              {context.user.gender === "male" ? (
                <Image src={maleIconTwo} className="welcome-step-icon" />
              ) : (
                <Image src={femaleIconTwo} className="welcome-step-icon" />
              )}
              {context.user.gender === "male" ? (
                <div className="welcome-v2-description-body">
                  <h1 className="welcome-v2-description-title">Be Ready</h1>
                  <p className="welcome-v2-description-text">
                    To attract female members, complete your profile, upload
                    photos, and answer messages quickly
                  </p>
                </div>
              ) : (
                <div className="welcome-v2-description-body">
                  <h1 className="welcome-v2-description-title">Cash Gifts</h1>
                  <p className="welcome-v2-description-text">
                    Men send you virtual diamonds you can redeem for cash gifts
                  </p>
                </div>
              )}
            </div>
          )}
          {step === 3 && (
            <div className="welcome-v2-description">
              {context.user.gender === "male" ? (
                <Image src={maleIconThree} className="welcome-step-icon" />
              ) : (
                <Image src={femaleIconThree} className="welcome-step-icon" />
              )}
              {context.user.gender === "male" ? (
                <div className="welcome-v2-description-body">
                  <h1 className="welcome-v2-description-title">High Rank</h1>
                  <p className="welcome-v2-description-text">
                    The more diamonds you send, the higher your profile will
                    rank and more women will see you
                  </p>
                </div>
              ) : (
                <div className="welcome-v2-description-body">
                  <h1 className="welcome-v2-description-title">
                    Shine like a diamond
                  </h1>
                  <p className="welcome-v2-description-text">
                    Complete your profile, upload photos, and send lots of likes
                    to get more attention
                  </p>
                </div>
              )}
            </div>
          )}
          {step === 4 && (
            <div className="welcome-v2-description">
              {context.user.gender === "male" ? (
                <Image src={maleIconFour} className="welcome-step-icon" />
              ) : (
                <Image src={femaleIconFour} className="welcome-step-icon" />
              )}
              {context.user.gender === "male" ? (
                <div className="welcome-v2-description-body">
                  <h1 className="welcome-v2-description-title">
                    Do not hesitate
                  </h1>
                  <p className="welcome-v2-description-text">
                    Be respectful, safe, and send lots of compliments!
                  </p>
                </div>
              ) : (
                <div className="welcome-v2-description-body">
                  <h1 className="welcome-v2-description-title">Be Active</h1>
                  <p className="welcome-v2-description-text">
                    The more messages and likes you send, the more Diamonds you
                    will earn!
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="welcome-v2-footer">
        <div className="welcome-v2-steps">
          <div className={indicatorOne}></div>
          <div className={indicatorTwo}></div>
          <div className={indicatorThree}></div>
          <div className={indicatorFour}></div>
        </div>
        <Button
          variant="danger"
          className="welcome-v2-button"
          onClick={() => nextStep()}
        >
          {step === 1 && "Got it!"}
          {step === 2 && "Next"}
          {step === 3 && "Continue"}
          {step === 4 && "Setup Profile"}
        </Button>
      </div>
    </div>
  );
};

export default withRouter(WelcomeV2);
