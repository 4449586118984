import React, { useContext, useEffect, useState } from "react";
import "./lookingfor.scss";
import { UserContext } from "../../../../context/UserContext";
import { Button } from "react-bootstrap";
import { countBenefits } from "../../../../helpers/CountBenefits";

function LookingFor(props) {
  const context = useContext(UserContext);
  const [expanded, setExpanded] = useState(false);
  const [benefitCount, setBenefitCount] = useState(null);

  useEffect(() => {
    fetchBenefits();
  }, []);

  const fetchBenefits = async (e) => {
    var count = await countBenefits(props.profileUser.benefits);
    setBenefitCount(count);
  };
  var benefits = null;

  if (props.profileUser) {
    var benefits = props.profileUser.benefits;
  }

  var showExpandButton = false;
  var benefitsContentStyle = "benefits-content";
  if (benefitCount > 5) {
    showExpandButton = true;
  }

  const toggleExpanded = (e) => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
    }
  };

  if (expanded) {
    showExpandButton = false;
    benefitsContentStyle = "benefits-content-expanded";
  }

  var bio = props.profileUser.bio;
  var biostatus = props.profileUser.biostatus;
  var showBio = false;

  var hide = false;
  var countHide = false;

  if (benefitCount < 1 || !benefitCount || !benefits) {
    countHide = true;
  }

  if (bio) {
    if (bio.length > 0 && biostatus === "approved") {
      showBio = true;
    }
  }

  if (!showBio && countHide) {
    hide = true;
  }

  const benefitsStyle = props.isEdit
    ? {
        ActiveLifestyle: true,
        AllEthnicities: true,
        Attentive: true,
        Discretion: true,
        EmotionalConnection: true,
        FineDining: true,
        FlexibleSchedule: true,
        FriendsWithBenefits: true,
        Investor: true,
        LifeOfLeisure: true,
        LongTerm: true,
        LuxuryLifestyle: true,
        MarriageMinded: true,
        Mentorship: true,
        Monogamous: true,
        NoStringsAttached: true,
        NonMonogamous: true,
        OpenRelationship: true,
        PassportReady: true,
        Platonic: true,
        Romance: true,
        ShowsAndEntertainment: true,
        TravelToYou: true,
        TravelWithMe: true,
        Vacations: true,
      }
    : {};
  if (!props.isOwnProfile) {
    const cardBenefits = props.profileUser.benefits;
    const ownBenefits = context.user.benefits;
    if (cardBenefits.ActiveLifestyle && ownBenefits.ActiveLifestyle) {
      benefitsStyle.ActiveLifestyle = true;
    }
    if (cardBenefits.AllEthnicities && ownBenefits.AllEthnicities) {
      benefitsStyle.AllEthnicities = true;
    }
    if (cardBenefits.Attentive && ownBenefits.Attentive) {
      benefitsStyle.Attentive = true;
    }
    if (cardBenefits.Discretion && ownBenefits.Discretion) {
      benefitsStyle.Discretion = true;
    }
    if (cardBenefits.EmotionalConnection && ownBenefits.EmotionalConnection) {
      benefitsStyle.EmotionalConnection = true;
    }
    if (cardBenefits.FineDining && ownBenefits.FineDining) {
      benefitsStyle.FineDining = true;
    }
    if (cardBenefits.FlexibleSchedule && ownBenefits.FlexibleSchedule) {
      benefitsStyle.FlexibleSchedule = true;
    }
    if (cardBenefits.FriendsWithBenefits && ownBenefits.FriendsWithBenefits) {
      benefitsStyle.FriendsWithBenefits = true;
    }
    if (cardBenefits.Investor && ownBenefits.Investor) {
      benefitsStyle.Investor = true;
    }
    if (cardBenefits.LifeOfLeisure && ownBenefits.LifeOfLeisure) {
      benefitsStyle.LifeOfLeisure = true;
    }
    if (cardBenefits.LongTerm && ownBenefits.LongTerm) {
      benefitsStyle.LongTerm = true;
    }
    if (cardBenefits.LuxuryLifestyle && ownBenefits.LuxuryLifestyle) {
      benefitsStyle.LuxuryLifestyle = true;
    }
    if (cardBenefits.MarriageMinded && ownBenefits.MarriageMinded) {
      benefitsStyle.MarriageMinded = true;
    }
    if (cardBenefits.Mentorship && ownBenefits.Mentorship) {
      benefitsStyle.Mentorship = true;
    }
    if (cardBenefits.Monogamous && ownBenefits.Monogamous) {
      benefitsStyle.Monogamous = true;
    }
    if (cardBenefits.NoStringsAttached && ownBenefits.NoStringsAttached) {
      benefitsStyle.NoStringsAttached = true;
    }
    if (cardBenefits.NonMonogamous && ownBenefits.NonMonogamous) {
      benefitsStyle.NonMonogamous = true;
    }
    if (cardBenefits.OpenRelationship && ownBenefits.OpenRelationship) {
      benefitsStyle.OpenRelationship = true;
    }
    if (cardBenefits.PassportReady && ownBenefits.PassportReady) {
      benefitsStyle.PassportReady = true;
    }
    if (cardBenefits.Platonic && ownBenefits.Platonic) {
      benefitsStyle.Platonic = true;
    }
    if (cardBenefits.Romance && ownBenefits.Romance) {
      benefitsStyle.Romance = true;
    }
    if (
      cardBenefits.ShowsAndEntertainment &&
      ownBenefits.ShowsAndEntertainment
    ) {
      benefitsStyle.ShowsAndEntertainment = true;
    }
    if (cardBenefits.TravelToYou && ownBenefits.TravelToYou) {
      benefitsStyle.TravelToYou = true;
    }
    if (cardBenefits.TravelWithMe && ownBenefits.TravelWithMe) {
      benefitsStyle.TravelWithMe = true;
    }
    if (cardBenefits.Vacations && ownBenefits.Vacations) {
      benefitsStyle.Vacations = true;
    }
  }

  if (hide) {
    return null;
  } else {
    return (
      <div className="benefits-component">
        {!props.isEdit && <h3 className="benefits-title">Looking For</h3>}
        {showBio && <p className="about-v2-content">{props.profileUser.bio}</p>}
        {benefits && !countHide && (
          <div
            className={
              showBio
                ? "benefits-content-wrapper-v2"
                : "benefits-content-wrapper-nopadding"
            }
          >
            <div className={benefitsContentStyle}>
              {benefits.ActiveLifestyle && (
                <div
                  className={
                    benefitsStyle.ActiveLifestyle
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Active Lifestyle
                </div>
              )}
              {benefits.EmotionalConnection && (
                <div
                  className={
                    benefitsStyle.EmotionalConnection
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Emotional Connection
                </div>
              )}
              {benefits.FlexibleSchedule && (
                <div
                  className={
                    benefitsStyle.FlexibleSchedule
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Flexible Schedule
                </div>
              )}
              {benefits.Attentive && (
                <div
                  className={
                    benefitsStyle.Attentive
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Attentive
                </div>
              )}
              {benefits.LongTerm && (
                <div
                  className={
                    benefitsStyle.LongTerm
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Long Term
                </div>
              )}
              {benefits.AllEthnicities && (
                <div
                  className={
                    benefitsStyle.AllEthnicities
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  All Ethnicities
                </div>
              )}
              {benefits.Discretion && (
                <div
                  className={
                    benefitsStyle.Discretion
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Discretion
                </div>
              )}
              {benefits.FineDining && (
                <div
                  className={
                    benefitsStyle.FineDining
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Fine Dining
                </div>
              )}
              {benefits.FriendsWithBenefits && (
                <div
                  className={
                    benefitsStyle.FriendsWithBenefits
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Friends With Benefits
                </div>
              )}
              {benefits.Investor && (
                <div
                  className={
                    benefitsStyle.Investor
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Investor
                </div>
              )}
              {benefits.LifeOfLeisure && (
                <div
                  className={
                    benefitsStyle.LifeOfLeisure
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Life of Leisure
                </div>
              )}
              {benefits.LuxuryLifestyle && (
                <div
                  className={
                    benefitsStyle.LuxuryLifestyle
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Luxury Lifestyle
                </div>
              )}
              {benefits.MarriageMinded && (
                <div
                  className={
                    benefitsStyle.MarriageMinded
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Marriage Minded
                </div>
              )}
              {benefits.Mentorship && (
                <div
                  className={
                    benefitsStyle.Mentorship
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Mentorship
                </div>
              )}
              {benefits.Monogamous && (
                <div
                  className={
                    benefitsStyle.Monogamous
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Monogamous
                </div>
              )}
              {benefits.NoStringsAttached && (
                <div
                  className={
                    benefitsStyle.NoStringsAttached
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  No Strings Attached
                </div>
              )}
              {benefits.NonMonogamous && (
                <div
                  className={
                    benefitsStyle.NonMonogamous
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Non Monogamous
                </div>
              )}
              {benefits.OpenRelationship && (
                <div
                  className={
                    benefitsStyle.OpenRelationship
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Open Relationship
                </div>
              )}
              {benefits.PassportReady && (
                <div
                  className={
                    benefitsStyle.PassportReady
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Passport Ready
                </div>
              )}
              {benefits.Platonic && (
                <div
                  className={
                    benefitsStyle.Platonic
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Platonic
                </div>
              )}
              {benefits.Romance && (
                <div
                  className={
                    benefitsStyle.Romance
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Romance
                </div>
              )}
              {benefits.ShowsAndEntertainment && (
                <div
                  className={
                    benefitsStyle.ShowsAndEntertainment
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Shows and Entertainment
                </div>
              )}
              {benefits.TravelToYou && (
                <div
                  className={
                    benefitsStyle.TravelToYou
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Travel to You
                </div>
              )}
              {benefits.TravelWithMe && (
                <div
                  className={
                    benefitsStyle.TravelWithMe
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Travel with Me
                </div>
              )}
              {benefits.Vacations && (
                <div
                  className={
                    benefitsStyle.Vacations
                      ? "benefits-item-active"
                      : "benefits-item"
                  }
                >
                  Vacations
                </div>
              )}
            </div>
          </div>
        )}
        {showExpandButton && (
          <Button className="benefits-show-all-btn" onClick={toggleExpanded}>
            Show All
          </Button>
        )}
        {expanded && (
          <Button className="benefits-show-all-btn" onClick={toggleExpanded}>
            Close
          </Button>
        )}
      </div>
    );
  }
}

export default LookingFor;
