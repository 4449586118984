import React, { useContext } from 'react';
import './desktopmessagecard.scss';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import { UserContext } from '../../../../context/UserContext';
import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import Media from '../../../../components/media/Media';

var moment = require('moment');

function DesktopMessageCard(props) {
  const context = useContext(UserContext);

  var message = props.message;
  var card = message.user;
  var image = card.imgurl[0];

  var isSender = false;

  if (context.user._id.toString() === message.sender.toString()) {
    isSender = true;
  }

  var lastMessageDateText = '';
  var messageDate = moment(message.createdAt);
  var timeDifference = moment().diff(messageDate, 'hours');

  if (timeDifference > 24) {
    timeDifference = moment().diff(messageDate, 'days');
    lastMessageDateText = 'about ' + timeDifference + ' days ago';
  } else if (timeDifference < 1) {
    timeDifference = moment().diff(messageDate, 'minutes');
    if (timeDifference < 1) {
      timeDifference = moment().diff(messageDate, 'seconds');
      if (timeDifference < 10) {
        lastMessageDateText = 'Just now';
      } else {
        lastMessageDateText = 'about ' + timeDifference + ' seconds ago';
      }
    } else {
      lastMessageDateText = 'about ' + timeDifference + ' minutes ago';
    }
  } else {
    lastMessageDateText = 'about ' + timeDifference + ' hours ago';
  }

  var profileurl = '/app/member/' + card.profileurl;
  var chaturl = '/app/messages/' + card.profileurl;

  return (
    <div className="desktop-message-card-component">
      <Link to={chaturl} className="message-card-chat-link">
        <div className="desktop-message-card-innerwrap">
          <div className="desktop-message-card-leftside">
            <div className="desktop-message-card-indicator-wrapper">
              {!isSender && !message.isRead && (
                <i className="fas fa-circle desktop-message-unread-indicator-icon"></i>
              )}

              {isSender && (
                <div>
                  {message.isRead ? (
                    <div>
                      {context.user.premium ? (
                        <i className="fas fa-check desktop-message-read-indicator-icon"></i>
                      ) : (
                        <i className="fas fa-reply desktop-message-sent-indicator-icon"></i>
                      )}
                    </div>
                  ) : (
                    <i className="fas fa-reply desktop-message-sent-indicator-icon"></i>
                  )}
                </div>
              )}
            </div>
            <Link to={profileurl}>
              <div className="desktop-message-card-image-wrapper">
                {image ? (
                  <Media image={image} />
                ) : (
                  // <Image
                  //   publicId={getImgName(image)}
                  //   version={getImgVersion(image)}
                  //   resource_type={checkGIF(image) ? "video" : "image"}
                  //   effect="loop"
                  //   cloud_name="sugarbae"
                  //   className="desktop-message-card-image"
                  // >
                  //   <Transformation
                  //     height="200"
                  //     video_sampling="20"
                  //     delay="250"
                  //     duration="10"
                  //     effect="loop"
                  //     quality="auto"
                  //     flags="lossy"
                  //   />
                  // </Image>
                  <div className="desktop-message-card-image-placeholder">
                    <i className="far fa-user desktop-message-card-placeholder-icon"></i>
                  </div>
                )}
              </div>
            </Link>
            <div className="desktop-message-card-content-wrapper">
              <div className="desktop-message-card-content-title-wrapper">
                <h3 className="desktop-message-card-content-title">
                  {card.username}
                </h3>
                <p className="desktop-message-card-content-time">
                  {lastMessageDateText}
                </p>
              </div>
              <div className="desktop-message-card-location-wrapper">
                <p className="desktop-message-card-location">{card.address}</p>
              </div>
              <div className="desktop-message-card-message-wrapper">
                <p className="desktop-message-card-message">
                  {message.message}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <div className="desktop-message-card-rightside">
        <Button variant="link" className="desktop-message-card-archive-button">
          <i className="fal fa-times archive-icon"></i>
        </Button>
      </div>
    </div>
  );
}

export default withRouter(DesktopMessageCard);
