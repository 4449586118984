import React, { useState } from "react";
import "./filterslidersv2.scss";
import { Form } from "react-bootstrap";

function SortBy(props) {
  const { filters } = props;
  const [sortBy, setSortBy] = useState(filters.sortby);

  let recentlyActiveStyle = "filter-slider-v2-form-label";
  let newestStyle = "filter-slider-v2-form-label";
  let profileScoreStyle = "filter-slider-v2-form-label";

  if (sortBy === "Newest") {
    newestStyle = "filter-slider-v2-form-label-checked";
  } else if (sortBy === "Recently Active") {
    recentlyActiveStyle = "filter-slider-v2-form-label-checked";
  } else if (sortBy === "Profile Score") {
    profileScoreStyle = "filter-slider-v2-form-label-checked";
  }

  const changeHandler = (e) => {
    if (e.target.name === "Recently Active") {
      setSortBy("Recently Active");
    } else if (e.target.name === "Newest") {
      setSortBy("Newest");
    } else if (e.target.name === "Profile Score") {
      setSortBy("Profile Score");
    }
  };

  const resetHandler = (e) => {
    setSortBy("Recently Active");
  };

  const saveHandler = (e) => {
    props.updateHandler(sortBy);
  };
  return (
    <div className="filter-slider-v2">
      <div className="filter-slider-v2-overlay" onClick={props.onClose} />

      <div className="filter-slider-v2-content">
        <div className="filter-slider-v2-close-bar" />
        <div className="filter-slider-v2-pop">
          <div className="filter-slider-v2-header">
            <div className="filter-slider-v2-header-section">
              <i className="far fa-heart sort-v2-heart-icon" />
              <span className="filter-slider-v2-title">Sort By</span>
            </div>
            <div className="filter-slider-v2-header-section">
              <span
                className="filter-slider-v2-reset-text"
                onClick={resetHandler}
              >
                Reset
              </span>
            </div>
          </div>
          <div className="filter-slider-v2-form-wrapper">
            <div className="filter-slider-v2-form">
              <div className="filter-slider-v2-form-group">
                <label className={recentlyActiveStyle}>
                  Recently Active
                  <input
                    type="checkbox"
                    className="filter-slider-v2-input-radio"
                    checked={sortBy === "Recently Active"}
                    onClick={changeHandler}
                    name="Recently Active"
                  />
                  <span className="filter-slider-v2-form-checkmark" />
                </label>
              </div>
              <div className="filter-slider-v2-form-group form-group-no-border">
                <label className={newestStyle}>
                  Newest
                  <input
                    type="checkbox"
                    className="filter-slider-v2-input-radio"
                    checked={sortBy === "Newest"}
                    onClick={changeHandler}
                    name="Newest"
                  />
                  <span className="filter-slider-v2-form-checkmark" />
                </label>
              </div>
              {/* <div className="filter-slider-v2-form-group form-group-no-border">
                <label className={profileScoreStyle}>
                  Profile Score
                  <input
                    type="checkbox"
                    className="filter-slider-v2-input-radio"
                    checked={sortBy === "Profile Score" ? true : false}
                    onClick={changeHandler}
                    name="Profile Score"
                  />
                  <span className="filter-slider-v2-form-checkmark"></span>
                </label>
              </div> */}
            </div>
          </div>
          <div className="filter-slider-v2-footer">
            <div className="filter-slider-v2-apply-btn" onClick={saveHandler}>
              Apply
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SortBy;
