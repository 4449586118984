import React from "react";
import homeLogo from "../../assets/logos/homeLogo.png";
import { Image } from "react-bootstrap";
import PhoneLogin from "../../components/home/logins/PhoneLogin";

function PhoneLoginPage() {
  const vh = window.innerHeight;
  const vw = window.innerWidth;

  if (vw > 991) {
    return (
      <div className="welcome-page-v2-component">
        <div className="welcome-page-v2-header">
          <Image src={homeLogo} className="welcome-page-v2-logo" fluid />
        </div>
        <div className="welcome-page-v2-content">
          <PhoneLogin />
        </div>
      </div>
    );
  } else {
    return <PhoneLogin />;
  }
}

export default PhoneLoginPage;
