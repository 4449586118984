import React, { Component } from "react";
import { Image } from "react-bootstrap";

import { UserContext } from "../../context/UserContext";

import undoStamp from "../../assets/stamps/undo-stamp.png";
import sentNoteStamp from "../../assets/stamps/sentnote-stamp.png";
import likeStamp from "../../assets/stamps/like-stamp-circle.png";
import dislikeStamp from "../../assets/stamps/dislike-stamp-circle.png";
import superlikeStamp from "../../assets/stamps/superlike-stamp.png";

import {
  submitLike,
  submitDislike,
  submitSuperlike,
  submitUndo,
  submitBoost,
} from "../../helpers/swipeActions";

import NoteInvalidModal from "../modals/noteInvalidModal/NoteInvalidModal";
import SwipeNav from "../navbar/SwipeNav";
import LogoSpinner from "../spinners/LogoSpinner";

import SwipeProfile from "./swipeComponents/swipeProfile/SwipeProfile";
import Actions from "./swipeComponents/actions/Actions";
import Match from "./swipeComponents/match/Match";
import SuperlikePop from "./swipeComponents/swipePops/SuperlikePop";
import LikePop from "./swipeComponents/swipePops/LikePop";
import BoostPop from "./swipeComponents/swipePops/BoostPop";
import UndoPop from "./swipeComponents/swipePops/UndoPop";
import BoostAlreadyActivePop from "./swipeComponents/swipePops/BoostAlreadyActivePop";
import BoostActivePop from "./swipeComponents/swipePops/BoostActivePop";
import SearchFilters from "../search/searchComponents/searchFilters/SearchFilters";

import "./swipe.scss";
import { api } from "../../api";
import MobileNav from "../navbar/MobileNav/MobileNav";
import SwipeHeader from "../headers/SwipeHeader";
import AdvancedSearchFilters from "../search/searchComponents/advancedSearchFilters/AdvancedSearchFilters";
import Filters from "../filters/Filters";
import ClipLoader from "react-spinners/ClipLoader";
import PremiumModal from "../modals/premiumModal/PremiumModal";

const BUFFER_CARDS = 5;

export class Swipe extends Component {
  static contextType = UserContext;

  state = {
    lastAction: "",
    match: false,
    matchid: "",
    likeStamp: false,
    likeInvalid: false,
    dislikeStamp: false,
    superlikeStamp: false,
    superlikeInvalid: false,
    undoStamp: false,
    undoInvalid: false,
    boostInvalid: false,
    boostActivePop: false,
    boostAlreadyActivePop: false,
    undoDisabled: true,
    submitting: false,
    noteInvalid: false,
    noteStamp: false,
    filtersOpen: false,
    advancedFiltersOpen: false,
    matchesOpen: false,
    matchUser: null,
    scrolled: false,
  };

  componentDidMount() {
    if (this.context.needReload) {
      const len = this.context.swipeData.cards.length;
      this.context.setSwipeIndex(len);
    }
  }

  actionsHandler = (action) => {
    switch (action) {
      case "like":
        this.handleLike();
        break;
      case "dislike":
        this.handleDislike();
        break;
      case "boost":
        this.handleBoost();
        break;
      case "undo":
        this.handleUndo();
        break;
      case "superlike":
        this.handleSuperlike();
        break;
      default:
        return;
    }
  };

  handleLike = async (isSwipe = false, swipeIndex = -1) => {
    const { swipeData, setSwipeIndex, user } = this.context;
    const { index, cards, likedByUsers } = swipeData;

    const currentIndex = swipeIndex > -1 ? swipeIndex : index;

    const userid = user._id;
    const card = cards[currentIndex];
    const cardid = cards[currentIndex]._id;
    const newIndex = currentIndex + 1;
    const isLikedByUser = likedByUsers.includes(cardid);

    if (!isSwipe) {
      this.setState({ likeStamp: true, submitting: true });
    } else if (isLikedByUser) {
      this.setState({ submitting: true });
    } else {
      setSwipeIndex(newIndex);
    }

    const like = await submitLike(userid, cardid).catch((err) => {
      console.log(err);
    });

    const valid = like.valid;
    const match = like.match;

    if (valid) {
      if (match) {
        this.setState({ match: true, matchid: cardid, matchUser: card });
      }
      if (newIndex !== cards.length) {
        this.setState({
          lastAction: "like",
          undoDisabled: false,
        });
      }
      if (!isSwipe || isLikedByUser) {
        setSwipeIndex(newIndex);
      }
      var tempLikedUsers = this.context.likedUsers;

      if (!tempLikedUsers.includes(card._id)) {
        tempLikedUsers.push(card._id);
        this.context.updateLikedUsers(tempLikedUsers);
      }
    } else {
      this.setState({ likeInvalid: true });
    }
    this.setState({ likeStamp: false, submitting: false });
  };

  handleSuperlike = async () => {
    const { swipeData, setSwipeIndex, user } = this.context;
    const { index, cards } = swipeData;

    const userid = user._id;
    const card = cards[index];
    const cardid = cards[index]._id;
    const newIndex = index + 1;

    this.setState({ superlikeStamp: true, submitting: true });

    const superlike = await submitSuperlike(userid, cardid).catch((err) => {
      console.log(err);
    });

    const valid = superlike.valid;
    const match = superlike.match;

    if (valid) {
      if (match) {
        this.setState({ match: true, matchid: cardid, matchUser: card });
      }
      if (newIndex !== cards.length) {
        this.setState({
          lastAction: "superlike",
          undoDisabled: false,
        });
      }
      setSwipeIndex(newIndex);
    } else {
      this.setState({ superlikeInvalid: true });
    }
    this.setState({ superlikeStamp: false, submitting: false });
  };

  handleDislike = async (isSwipe = false, swipeIndex = -1) => {
    const { swipeData, setSwipeIndex, user } = this.context;
    const { index, cards } = swipeData;

    const currentIndex = swipeIndex > -1 ? swipeIndex : index;

    const userid = user._id;
    const cardid = cards[currentIndex]._id;
    const newIndex = currentIndex + 1;

    if (!isSwipe) {
      this.setState({ dislikeStamp: true, submitting: true });
    } else {
      setSwipeIndex(newIndex);
    }

    await submitDislike(userid, cardid);

    if (!isSwipe) {
      setSwipeIndex(newIndex);
    }

    if (newIndex !== cards.length) {
      this.setState({
        lastAction: "dislike",
        undoDisabled: false,
      });
    }
    var tempLikedUsers = this.context.likedUsers;
    const indexB = tempLikedUsers.indexOf(cardid);

    if (indexB > -1) {
      tempLikedUsers.splice(indexB, 1);
      this.context.updateLikedUsers(tempLikedUsers);
    }

    this.setState({ dislikeStamp: false, submitting: false });
  };

  handleBoost = async () => {
    const userid = this.context.user._id;

    const boost = await submitBoost(userid).catch((err) => {
      console.log(err);
    });

    const valid = boost.valid;
    const active = boost.active;
    if (valid) {
      this.setState({ boostActivePop: true });
      this.props.handleBoost();
    } else {
      if (active) {
        this.setState({ boostAlreadyActivePop: true });
      } else {
        this.setState({ boostInvalid: true });
      }
    }
  };

  handleUndo = async (e) => {
    const { swipeData, setSwipeIndex, user } = this.context;
    const { index, cards } = swipeData;

    const userid = user._id;
    const lastCardIndex = index - 1;
    const cardid = cards[lastCardIndex]._id;
    const action = this.state.lastAction;

    this.setState({ undoStamp: true, submitting: true });

    const undo = await submitUndo(userid, cardid, action).catch((err) => {
      console.log(err);
    });

    if (undo.valid) {
      if (action === "like") {
        var tempLikedUsers = this.context.likedUsers;
        const indexB = tempLikedUsers.indexOf(cardid);

        if (indexB > -1) {
          tempLikedUsers.splice(indexB, 1);
          this.context.updateLikedUsers(tempLikedUsers);
        }
      }

      setSwipeIndex(lastCardIndex);
      this.setState({ undoDisabled: true });
    } else {
      this.setState({ undoInvalid: true });
    }

    this.setState({ undoStamp: false, submitting: false });
  };

  handleNote = async (message) => {
    const { swipeData, setSwipeIndex, user } = this.context;
    const { index, cards } = swipeData;

    const userid = user._id;
    const cardid = cards[index]._id;

    this.setState({ submitting: true, noteStamp: true });

    let valid = false;
    const textMessage = message;

    const data = {
      userid,
    };

    await api
      .noteCheck(data)
      .then((res) => {
        if (res.status === 200) {
          valid = res.data.valid;
        }
      })
      .catch((err) => {});

    if (valid) {
      const data = {
        userid,
        cardid: cardid,
        message: textMessage,
      };

      await api
        .submitNote(data)
        .then((res) => {
          if (res.status === 200) {
            const newIndex = index + 1;
            setSwipeIndex(newIndex);
            if (newIndex !== cards.length) {
              this.setState({
                lastAction: "like",
                undoDisabled: false,
              });
            }
          }
        })
        .catch((err) => {});
    } else {
      this.setState({ noteInvalid: true });
    }
    this.setState({ submitting: false, noteStamp: false });
  };

  popCloseHandler = (e) => {
    this.setState({
      superlikeInvalid: false,
      likeInvalid: false,
      boostInvalid: false,
      undoInvalid: false,
      boostAlreadyActivePop: false,
      boostActivePop: false,
      noteInvalid: false,
    });
  };

  closeMatchHandler = (e) => {
    this.setState({ match: false, matchUser: null });
  };

  toggleFilters = (e) => {
    if (this.state.filtersOpen) {
      this.setState({ filtersOpen: false });
    } else {
      this.setState({ filtersOpen: true });
    }
  };

  updateHandler = (e) => {
    this.setState({ undoDisabled: true });
    this.context.updateSwipe();
  };

  toggleMatches = (e) => {
    if (this.state.matchesOpen) {
      this.setState({ matchesOpen: false });
    } else {
      this.setState({ matchesOpen: true });
    }
  };

  advancedHandler = (e) => {
    if (this.state.advancedFiltersOpen) {
      this.setState({ advancedFiltersOpen: false });
    } else {
      this.setState({ advancedFiltersOpen: true });
    }
  };

  setScrolled = (e) => {
    this.setState({ scrolled: e });
  };

  render() {
    const { swipeData } = this.context;
    const {
      index,
      cards,
      likedByUsers,
      superlikedByUsers,
      noteUsers,
      loading,
    } = swipeData;

    const vh = window.innerHeight;
    const vw = window.innerWidth;

    const top = vh * 0.17 + "px";
    const left = vw * 0.16 + "px";
    const superlikeBottom = vh / 3.5 + "px";
    const superlikeLeft = vw / 4 + "px";

    const likeStampPosition = {
      top: top,
      left: left,
      zIndex: "3",
    };
    const dislikeStampPosition = {
      top: top,
      right: left,
      zIndex: "3",
    };
    const superlikeStampPosition = {
      bottom: superlikeBottom,
      left: superlikeLeft,
      zIndex: "3",
    };

    if (vw > 992) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <SwipeNav />
          {this.state.superlikeInvalid ? (
            <SuperlikePop
              closeHandler={this.popCloseHandler}
              cardid={cards[index]}
            />
          ) : null}
          {this.state.likeInvalid ? (
            <LikePop
              closeHandler={this.popCloseHandler}
              cardid={cards[index]}
            />
          ) : null}
          {this.state.boostInvalid ? (
            <BoostPop
              closeHandler={this.popCloseHandler}
              cardid={cards[index]}
            />
          ) : null}
          {this.state.undoInvalid ? (
            <PremiumModal
              onClose={this.popCloseHandler}
              show={this.state.undoInvalid}
              option={"undo"}
            />
          ) : null}
          {this.state.boostActivePop ? (
            <BoostActivePop
              closeHandler={this.popCloseHandler}
              cardid={cards[index]}
            />
          ) : null}
          {this.state.boostAlreadyActivePop ? (
            <BoostAlreadyActivePop closeHandler={this.popCloseHandler} />
          ) : null}
          {this.state.noteInvalid ? (
            <NoteInvalidModal
              closeHandler={this.popCloseHandler}
              cardid={cards[index]._id}
            />
          ) : null}
          {this.state.match ? (
            <Match
              matchid={this.state.matchid}
              closeMatchHandler={this.closeMatchHandler}
              matchUser={this.state.matchUser}
            />
          ) : null}
        </div>
      );
    }

    return (
      <div style={{ width: "100%", height: "100%" }}>
        {this.state.filtersOpen && (
          <Filters
            closeHandler={this.toggleFilters}
            updateHandler={this.updateHandler}
            advancedHandler={this.advancedHandler}
          />
        )}
        {this.state.advancedFiltersOpen && (
          <AdvancedSearchFilters
            closeHandler={this.advancedHandler}
            updateHandler={this.updateHandler}
          />
        )}

        <SwipeHeader
          toggleFilters={this.toggleFilters}
          actionsHandler={this.actionsHandler}
          undoDisabled={this.state.undoDisabled}
          toggleMatches={this.toggleMatches}
        />
        {this.state.likeStamp ? (
          <Image
            src={likeStamp}
            className="like-stamp-image"
            style={likeStampPosition}
          />
        ) : null}
        {this.state.dislikeStamp ? (
          <Image
            src={dislikeStamp}
            className="dislike-stamp-image"
            style={dislikeStampPosition}
          />
        ) : null}
        {this.state.superlikeStamp ? (
          <Image
            src={superlikeStamp}
            className="superlike-stamp-image"
            style={superlikeStampPosition}
          />
        ) : null}
        {this.state.noteStamp ? (
          <Image
            src={sentNoteStamp}
            className="superlike-stamp-image"
            style={superlikeStampPosition}
          />
        ) : null}
        {this.state.undoStamp ? (
          <Image
            src={undoStamp}
            className="undo-stamp-image"
            style={likeStampPosition}
          />
        ) : null}
        {this.state.superlikeInvalid ? (
          <SuperlikePop
            closeHandler={this.popCloseHandler}
            cardid={cards[index]}
          />
        ) : null}
        {this.state.likeInvalid ? (
          <LikePop closeHandler={this.popCloseHandler} cardid={cards[index]} />
        ) : null}
        {this.state.boostInvalid ? (
          <BoostPop closeHandler={this.popCloseHandler} />
        ) : null}
        {this.state.undoInvalid ? (
          <UndoPop closeHandler={this.popCloseHandler} cardid={cards[index]} />
        ) : null}
        {this.state.boostActivePop ? (
          <BoostActivePop
            closeHandler={this.popCloseHandler}
            cardid={cards[index]}
          />
        ) : null}
        {this.state.boostAlreadyActivePop ? (
          <BoostAlreadyActivePop closeHandler={this.popCloseHandler} />
        ) : null}
        {this.state.noteInvalid ? (
          <NoteInvalidModal
            closeHandler={this.popCloseHandler}
            cardid={cards[index]._id}
          />
        ) : null}
        {this.state.match ? (
          <Match
            matchid={this.state.matchid}
            closeMatchHandler={this.closeMatchHandler}
            matchUser={this.state.matchUser}
          />
        ) : null}

        {loading ? (
          <div className="swipe-component-x">
            <div className="fetching-container">
              <ClipLoader />
            </div>
          </div>
        ) : (
          <div className="swipe-component-x">
            {cards
              .slice(index, index + BUFFER_CARDS)
              .reverse()
              .map((card, idx) => (
                <SwipeProfile
                  card={card}
                  cardIndex={index + BUFFER_CARDS - 1 - idx}
                  currentIndex={index}
                  key={card._id}
                  likeStamp={this.state.likeStamp}
                  likeStampPosition={likeStampPosition}
                  dislikeStamp={this.state.dislikeStamp}
                  dislikeStampPosition={dislikeStampPosition}
                  likedByUsers={likedByUsers}
                  superlikedByUsers={superlikedByUsers}
                  noteUsers={noteUsers}
                  submitting={this.state.submitting}
                  handleLike={this.handleLike}
                  handleDislike={this.handleDislike}
                  handleNote={this.handleNote}
                  checkEnableLike={(isValid) => {
                    this.setState({ likeInvalid: isValid });
                  }}
                  setScrolled={this.setScrolled}
                />
              ))}
          </div>
        )}

        {!loading && (
          <Actions
            actionsHandler={this.actionsHandler}
            undoDisabled={this.state.undoDisabled}
            scrolled={this.state.scrolled}
          />
        )}

        <MobileNav />
      </div>
    );
  }
}

export default Swipe;
