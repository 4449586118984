import React, { useContext, useState, useEffect } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { UserContext } from '../../context/UserContext';
import { api } from '../../api';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../utils/getCloudinaryInfo';
import { withRouter, useHistory } from 'react-router-dom';
import './requests.scss';
import People from './People';
import complimentIcon from '../../assets/Images/compliment/gifticon.png';
import { Image as BootstrapImage } from 'react-bootstrap';
import Media from '../../components/media/Media';

function Requests(props) {
  const context = useContext(UserContext);
  const [requests, setRequests] = useState(context.requests);
  const [fetching, setFetching] = useState(false);
  const [refresh, setRefresh] = useState(false);
  let history = useHistory();
  const approveHandler = (requestid, index) => {
    const data = {
      requestid,
      option: 'approve',
    };

    return api
      .handleRequest(data)
      .then(() => {
        const temp = context.approvedRequests;
        temp.unshift(requests[index]);
        requests.splice(index, 1);
        context.updateRequests(requests);
        context.updateApprovedRequests(temp);
        setRequests(requests);
        setRefresh(true);
      })
      .catch(() => {});
  };

  const declineHandler = (requestid, index) => {
    const data = {
      requestid,
      option: 'decline',
    };

    return api
      .handleRequest(data)
      .then(() => {
        requests.splice(index, 1);
        context.updateRequests(requests);
        setRequests(requests);
        setRefresh(true);
      })
      .catch(() => {});
  };

  const handleScroll = () => {
    if (!context.endRequests && !fetching) {
      setFetching(true);
      context.getMoreRequests();
    }
  };

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setFetching(false);
    setRequests(context.requests);
  }, [context.requests]);

  const backHandler = (e) => {
    history.goBack();
  };

  var showPeople = false;
  if (props.location.pathname.includes('people')) {
    showPeople = true;
  }

  const scrollRef = useBottomScrollListener(handleScroll);
  let vh = window.innerHeight;
  var vw = window.innerWidth;
  var height = vh + 'px';
  var width = vw + 'px';

  return (
    <div
      className="app-component"
      style={{
        height: height,
        width: width,
        maxHeight: height,
        maxWidth: width,
      }}
    >
      {showPeople ? (
        <People />
      ) : (
        <div className="requests-component">
          <div className="requests-header">
            <div className="requests-header-left">
              <div className="requests-return-button" onClick={backHandler}>
                <i className="fas fa-chevron-left"></i>
              </div>
            </div>
            <div className="requests-header-middle">
              <h1 className="requests-header-title">Requests</h1>
            </div>
          </div>

          {!requests || requests.length < 1 ? (
            <div className="requests-content">
              <div className="no-diamond-history-container">
                <BootstrapImage
                  src={complimentIcon}
                  className="no-diamond-history-image"
                  fluid
                />
                <h2 className="no-diamond-history-title">No Requests Yet</h2>
                <p className="no-diamond-history-subtitle">
                  When users request to view your private photos, they will
                  appear here.
                </p>
              </div>
            </div>
          ) : (
            <div className="requests-content" ref={scrollRef}>
              {requests &&
                requests.map((item, index) => {
                  const { imgurl, username, age, address } = item.user;
                  const mainImage = imgurl.length > 0 ? imgurl[0] : null;
                  const requestId = item._id;
                  return (
                    <div className="edit-profile-v2-private-request">
                      <div className="edit-profile-v2-request-image-wrapper">
                        <Media image={mainImage} />
                        {/* <Image
                          publicId={
                            mainImage
                              ? getImgName(mainImage)
                              : "placeholder-user-image-thumbnail_vankru.png"
                          }
                          version={
                            mainImage ? getImgVersion(mainImage) : "1590045309"
                          }
                          resource_type={
                            mainImage
                              ? checkGIF(mainImage)
                                ? "video"
                                : "image"
                              : null
                          }
                          effect="loop"
                          cloud_name="sugarbae"
                          className="edit-profile-v2-request-image"
                        >
                          <Transformation
                            height="200"
                            video_sampling="20"
                            delay="250"
                            duration="10"
                            effect="loop"
                            quality="auto"
                            flags="lossy"
                          />
                        </Image> */}
                      </div>
                      <div className="edit-profile-v2-request-info">
                        <h1 className="edit-profile-v2-request-username">{`${username}, ${age}`}</h1>
                        <label className="edit-profile-v2-request-user-address">
                          {address}
                        </label>
                      </div>
                      <div className="edit-profile-v2-request-action">
                        <i
                          className="far fa-check-circle edit-profile-v2-request-approve-icon"
                          onClick={() => approveHandler(requestId, index)}
                        ></i>
                        <i
                          className="far fa-times-circle edit-profile-v2-request-decline-icon"
                          onClick={() => declineHandler(requestId, index)}
                        ></i>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default withRouter(Requests);
