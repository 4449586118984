import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button, Form, Alert, Container, Card } from "react-bootstrap";
import axios from "axios";
import resetPasswordBanner from "../../assets/Images/Reset Password/reset-password-banner.jpg";
import { Link } from "react-router-dom";
import "./resetpassword.scss";
import EmailValidator from "email-validator";
import { api } from "../../api";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);

  const emailChangeHandler = (e) => {
    setEmail(e.target.value.toLowerCase());
    var isEmailValid = EmailValidator.validate(e.target.value.toLowerCase());
    if (isEmailValid) {
      setSubmitButtonDisabled(false);
    } else {
      setSubmitButtonDisabled(true);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    var isEmailValid = EmailValidator.validate(email);
    if (isEmailValid) {
      const data = {
        email: email,
      };

      return api
        .resetPassword(data)
        .then((res) => {
          if (res.status === 200) {
            setSuccessfulSubmit(true);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setEmailInvalid(true);
    }
  };

  if (successfulSubmit) {
    return (
      <div className="reset-password-section">
        <Container className="reset-password-container">
          <Card className="reset-password-form-card">
            <Card.Img
              variant="top"
              className="reset-password-form-top-img"
              src={resetPasswordBanner}
            />
            <Card.Title className="reset-password-card-title">
              <span className="reset-password-title-span">Reset Password</span>
            </Card.Title>
            <Card.Body className="reset-password-form-card-body">
              <Alert variant="success">
                We have emailed you with instructions to reset your password (if
                your email exists in our system). Check your email, including
                your spam and promotions folder for instructions on how to reset
                your account password. <br></br> <br></br>If you cannot access
                your email, or didn't receive the email, please{" "}
                <Link to="/contact" className="lp-contact-link">
                  contact support
                </Link>
                .
              </Alert>
              <div className="login-below-form-container">
                <p className="lp-signup-text">
                  <Link to="/login" className="lp-signup-link">
                    Back to Login
                  </Link>
                </p>
                <p className="lp-signup-text">
                  Having difficulties?{" "}
                  <Link to="/contact" className="lp-signup-link">
                    Contact Support
                  </Link>
                </p>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  } else {
    return (
      <div className="reset-password-section">
        <Container className="reset-password-container">
          <Card className="reset-password-form-card">
            <Card.Img
              variant="top"
              className="reset-password-form-top-img"
              src={resetPasswordBanner}
            />
            <Card.Title className="reset-password-card-title">
              <span className="reset-password-title-span">Reset Password</span>
            </Card.Title>
            <Card.Body className="reset-password-form-card-body">
              <Form onSubmit={submitHandler} className="forgot-password-form">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label className="reset-password-form-label">
                    Forgot your password?
                  </Form.Label>
                  <Form.Control
                    type="email"
                    size="lg"
                    placeholder="Enter email"
                    isInvalid={emailInvalid}
                    onChange={emailChangeHandler}
                    value={email}
                    className="reset-password-form-input"
                  />
                  <Form.Text className="text-muted">
                    Enter your account email to reset your password.
                  </Form.Text>
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit"
                  className="reset-password-form-btn"
                  size="lg"
                  disabled={submitButtonDisabled}
                >
                  Send Instructions
                </Button>
              </Form>
              <div className="login-below-form-container">
                <p className="lp-signup-text">
                  Having difficulties?{" "}
                  <Link to="/contact" className="lp-signup-link">
                    Contact Support
                  </Link>
                </p>
              </div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
