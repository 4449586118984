import React from "react";
import DesktopNav from "../../desktop/desktopNav/DesktopNav";

function SupportDesktop() {
  return (
    <div>
      <DesktopNav />
      <span></span>
    </div>
  );
}

export default SupportDesktop;
