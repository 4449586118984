import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import "./userprofile.css";
import UserProfileV2 from "../../components/userProfile/UserProfileV2";
import { UserContext } from "../../context/UserContext";
import DesktopUserProfile from "../../components/desktop/desktopUserProfile/DesktopUserProfile";
import MobileNav from "../../components/navbar/MobileNav/MobileNav";
import DesktopUserProfileV2 from "../../components/desktop/desktopV2/desktopUserProfileV2/DesktopUserProfileV2";

export class UserProfilePage extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    const userid = this.context.user._id;
    this.props.handleBoost(userid);
  }

  handleBoost = (e) => {
    const userid = this.context.user._id;
    this.props.handleBoost(userid);
  };

  render() {
    const vh = window.innerHeight;
    const vw = window.innerWidth;
    const height = `${vh}px`;
    const url = this.props.location.pathname;

    if (vw >= 992) {
      return <DesktopUserProfileV2 />;
    }
    return (
      <div className="app-component" style={{ height, maxHeight: height }}>
        <UserProfileV2 url={url} option={this.props.option} />
        <MobileNav />
      </div>
    );
  }
}

export default withRouter(UserProfilePage);
