import React from 'react';
import { Image, Button, Dropdown } from 'react-bootstrap';

function Media(props) {
  let image = props.image;

  return (
    <Image
      src={image}
      width="100%"
      height="100%"
      style={{ objectFit: 'cover' }}
      fluid
    />
  );
}

export default Media;
