import Axios from "axios";
import api from "../api/api";

const profileInventoryCalculator = async function (user) {
  var superlikeInventory = 2;
  var boostInventory = 5;

  const data = {
    userid: user._id,
  };

  await api
    .fetchProfileInventory(data)
    .then((res) => {
      if (res.status === 200) {
        var inventory = res.data.inventory;
        superlikeInventory = inventory.superlikes;
        boostInventory = inventory.boosts;
      }
    })
    .catch((err) => {});

  return { superlikeInventory, boostInventory };
};

export default profileInventoryCalculator;
