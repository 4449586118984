import React, { useState, useContext, useEffect } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import PhotoGridV2 from "../../photoGrid/PhotoGridV2";
import { UserContext } from "../../../context/UserContext";
import "../welcomev2.scss";
import bodytypeIcon from "../../../assets/Icons/bodytypeIcon.png";
import heightIcon from "../../../assets/Icons/heightIcon.svg";
import ethnicityIcon from "../../../assets/Icons/ethnicityIcon.png";
import { api } from "../../../api";

const About = (props) => {
  const history = useHistory();
  const context = useContext(UserContext);
  const [showWarning, setShowWarning] = useState(false);
  const [advanceDisabled, setAdvanceDisabled] = useState(true);
  const [headline, setHeadline] = useState(context.user.headline);
  const [remainingCharacters, setRemainingCharacters] = useState(300);

  useEffect(() => {
    const limit = 300;
    let remaining = 300;
    if (headline !== null && headline !== undefined) {
      remaining = 300 - headline.length;
      if (remaining < 0) {
        remaining = 0;
      }
    }

    setRemainingCharacters(remaining);
  }, [headline]);

  const nextHandler = (e) => {
    if (context.user.headline !== headline) {
      const data = {
        userid: context.user._id,
        headline,
      };

      return api
        .updateHeadline(data)
        .then((res) => {
          const tempUser = context.user;
          tempUser.headline = headline;
          context.updateUserInfo("all", tempUser);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    history.push({
      pathname: "/app/premium",
      state: { onboarding: true },
    });
  };

  const headlineChange = (e) => {
    if (e.target.value.length <= 300) {
      setHeadline(e.target.value);
    }
  };

  const skipHandler = () => {
    if (headline !== null && headline !== undefined && headline.length > 0) {
      history.push({
        pathname: "/app/premium",
        state: { onboarding: true },
      });
    } else {
      setShowWarning(true);
    }
  };

  const confirmSkipHandler = (e) => {
    history.push({
      pathname: "/app/premium",
      state: { onboarding: true },
    });
  };

  const isDisabled = false;
  let remainingCharactersStyle = "remaining-characters";
  if (remainingCharacters < 50) {
    remainingCharactersStyle = "remaining-characters-alert";
  }

  return (
    <div className="welcome-v2-container">
      {showWarning && (
        <div className="no-photos-warning">
          <div className="no-photos-innerwrap">
            <div
              className="no-photos-warning-overlay"
              onClick={() => {
                setShowWarning(false);
              }}
            />
            <div className="no-photos-warning-content">
              <div className="no-photos-content-warning">
                <i className="far fa-exclamation-triangle no-photos-warning-icon" />
                <h1 className="no-photos-warning-title">Warning</h1>
                <p className="no-photos-warning-subtitle">
                  Your profile will not receive as much attention if you don't
                  tell people about you.
                </p>
              </div>
              <div
                className="no-photos-footer-button"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                Complete About You
              </div>
              <div
                className="no-photos-warning-footer"
                onClick={() => {
                  setShowWarning(false);
                }}
              >
                <span
                  className="no-photos-close-text"
                  onClick={confirmSkipHandler}
                >
                  Skip
                </span>
                <i className="far fa-times-circle" />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="welcome-v2-header">
        <div className="welcome-v2-title">
          <h1 className="welcome-v2-title-one">Tell us</h1>
          <h2 className="welcome-v2-title-two">About the real you</h2>
        </div>
        <p className="welcome-v2-subtitle">
          Enter a breif description about yourself. What do you do for fun? What
          is your lifestyle like? Do you work or in school? Etc.
        </p>
      </div>
      <div className="welcome-v2-body">
        <Form className="looking-for-form">
          <Form.Group className="looking-for-form-group">
            <Form.Control
              as="textarea"
              row={5}
              className="looking-for-text-input"
              onChange={headlineChange}
              value={headline}
              placeholder="Describe what you are looking for"
            />
          </Form.Group>
          <Form.Label className="looking-for-label">
            <span className={remainingCharactersStyle}>
              {remainingCharacters}
            </span>{" "}
            characters
          </Form.Label>
        </Form>
      </div>

      <div className="welcome-v2-footer">
        <div className="welcome-v2-button-skip" onClick={() => skipHandler()}>
          Skip
        </div>
        <div
          variant="danger"
          className="welcome-v2-button"
          disabled={isDisabled}
          onClick={nextHandler}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default withRouter(About);
