import React, { useState, useEffect, useContext } from 'react';
import ProfileHeader from '../headers/ProfileHeader';
import './userprofilev2.scss';
import { UserContext } from '../../context/UserContext';
import { Button } from 'react-bootstrap';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../utils/getCloudinaryInfo';
import { Image, Transformation } from 'cloudinary-react';
import Settings from './settings/Settings';
import VerificationsProgress from './userProfileV2Components/verificationsProgress/VerificationsProgress';
import Boost from './userProfileV2Components/boost/Boost';
import ProfileV2 from '../profile/ProfileV2';
import EditProfileV2 from './editProfile/EditProfileV2';
import PremiumFeatures from '../carousels/PremiumFeatures';
import BoostModal from '../modals/boostModal/BoostModal';
import Diamonds from './userProfileV2Components/diamonds/Diamonds';
import BoostStatsModal from '../modals/boostStatsModal/BoostStatsModal';
import PremiumModal from '../modals/premiumModal/PremiumModal';
import Media from '../../components/media/Media';

function UserProfileV2(props) {
  const context = useContext(UserContext);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [boostOpen, setBoostOpen] = useState(false);
  const [boostStatsOpen, setBoostStatsOpen] = useState(false);
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);

  useEffect(() => {
    var option = props.option;

    if (option === 'settings') {
      setSettingsOpen(true);
    } else {
      setSettingsOpen(false);
    }

    if (option === 'edit') {
      setEditProfileOpen(true);
    } else {
      setEditProfileOpen(false);
    }
  }, []);

  const premiumCloseHandler = (e) => {
    setPremiumModalOpen(false);
  };

  const toggleSettings = (e) => {
    if (settingsOpen) {
      setSettingsOpen(false);
    } else {
      setSettingsOpen(true);
    }
  };

  const toggleEditProfile = (e) => {
    if (editProfileOpen) {
      setEditProfileOpen(false);
    } else {
      setEditProfileOpen(true);
    }
  };

  const toggleProfile = (e) => {
    if (profileOpen) {
      setProfileOpen(false);
    } else {
      setProfileOpen(true);
    }
  };

  const toggleBoost = (e) => {
    if (context.user.premium) {
      if (context.boostData.boosted) {
        if (boostStatsOpen) {
          setBoostStatsOpen(false);
        } else {
          setBoostStatsOpen(true);
        }
      } else {
        if (boostOpen) {
          setBoostOpen(false);
        } else {
          setBoostOpen(true);
        }
      }
    } else {
      setPremiumModalOpen(true);
    }
  };

  var images = context.user.imgurl;
  var mainImage = null;
  if (images) {
    if (images.length > 0) {
      mainImage = images[0];
    }
  }

  if (settingsOpen) {
    return <Settings settingsToggle={toggleSettings} />;
  } else if (editProfileOpen) {
    return <EditProfileV2 toggleEditProfile={toggleEditProfile} />;
  } else {
    return (
      <div className="user-profile-v2">
        {profileOpen && (
          <ProfileV2 toggleProfile={toggleProfile} profileUser={context.user} />
        )}
        {boostOpen && <BoostModal onClose={toggleBoost} show={boostOpen} />}
        {boostStatsOpen && (
          <BoostStatsModal onClose={toggleBoost} show={boostStatsOpen} />
        )}

        <ProfileHeader
          toggleSettings={toggleSettings}
          toggleEditProfile={toggleEditProfile}
        />

        {premiumModalOpen && (
          <PremiumModal
            option="boost"
            show={premiumModalOpen}
            onClose={premiumCloseHandler}
          />
        )}
        <div className="user-profile-v2-content">
          <div className="user-profile-user-preview-section">
            <div className="user-profile-user-image-wrapper">
              <Media image={mainImage} />
              {/* <Image
                publicId={
                  !!mainImage
                    ? getImgName(mainImage)
                    : "placeholder-user-image-thumbnail_vankru.png"
                }
                version={!!mainImage ? getImgVersion(mainImage) : "1590045309"}
                resource_type={
                  mainImage ? (checkGIF(mainImage) ? "video" : "image") : null
                }
                cloud_name="sugarbae"
                className="user-profile-v2-image"
              >
                <Transformation
                  height="200"
                  video_sampling="20"
                  delay="250"
                  duration="10"
                  effect="loop"
                  quality="auto"
                  flags="lossy"
                />
              </Image> */}
            </div>
            <div className="user-profile-user-preview-info">
              <h2 className="user-profile-username-age">
                {context.user.username},{' '}
                <span className="user-profile-age">{context.user.age}</span>
              </h2>
              <p className="user-profile-address">{context.user.address}</p>
            </div>
            <div className="user-profile-view-profile-section">
              <Button
                className="user-profile-view-profile-button"
                onClick={toggleProfile}
              >
                View Profile
              </Button>
            </div>
          </div>

          <div className="user-profile-user-info-section">
            <div className="user-profile-v2-info-box">
              <Diamonds />
            </div>
            <div className="user-profile-v2-info-box">
              <VerificationsProgress toggleEditProfile={toggleEditProfile} />
            </div>
            <div className="user-profile-v2-info-box">
              <Boost toggleBoost={toggleBoost} />
            </div>
          </div>
          {!context.user.premium && <PremiumFeatures />}
        </div>
      </div>
    );
  }
}

export default UserProfileV2;
