import React, { useState, useEffect, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import "./basicinfov2.scss";
import { UserContext } from "../../../../../context/UserContext";
import EditUsername from "../sliders/EditUsername";
import EditAge from "../sliders/EditAge";
import EditGender from "../sliders/EditGender";
import EditLocation from "../sliders/EditLocation";

const moment = require("moment");

function BasicInfo(props) {
  const context = useContext(UserContext);
  const [editUsernameOpen, setEditUsernameOpen] = useState(false);
  const [editAgeOpen, setEditAgeOpen] = useState(false);
  const [editGenderOpen, setEditGenderOpen] = useState(false);
  const [editLocationOpen, setEditLocationOpen] = useState(false);
  const [birthdate, setBirthdate] = useState(context.user.identity.birthdate);

  const history = useHistory();

  useEffect(() => {
    if (
      context.user.identity.birthDate === null ||
      context.user.identity.birthDate === undefined
    ) {
      const userAge = context.user.age + 1;

      const userBirthday = moment()
        .subtract(userAge, "years")
        .format("YYYY-MM-DD");
      setBirthdate(userBirthday);
    } else {
      const formattedBirthdate = moment(context.user.identity.birthDate).format(
        "YYYY-MM-DD"
      );
      setBirthdate(formattedBirthdate);
    }
  }, [context.user.identity.birthDate]);

  const backHandler = (e) => {
    history.goBack();
  };

  const toggleUsername = (e) => {
    setEditUsernameOpen(true);
  };

  return (
    <div className="edit-screen" style={{ position: "relative" }}>
      {editUsernameOpen && <EditUsername />}
      {editAgeOpen && <EditAge onClose={() => setEditAgeOpen(false)} />}
      {editGenderOpen && <EditGender />}
      {editLocationOpen && (
        <EditLocation onClose={() => setEditLocationOpen(false)} />
      )}

      <div className="edit-screen-header">
        <div className="edit-screen-header-section-left">
          <div
            className="edit-screen-header-return-button"
            onClick={backHandler}
          >
            <i className="fas fa-chevron-left" />
          </div>
        </div>
        <div className="edit-screen-header-section-middle">
          <h1 className="edit-screen-header-title">Basic Info</h1>
        </div>
      </div>
      <div className="basic-info-content">
        {/* <div className="basic-info-row" onClick={toggleUsername}>
          <h1 className="basic-info-title">Username</h1>
          <div className="basic-info-action">
            <p className="basic-info-content-text">{context.user.username}</p>
            <i className="fas fa-chevron-right right-grey-chevron" />
          </div>
        </div> */}
        <div
          className="basic-info-row"
          onClick={() => setEditLocationOpen(true)}
        >
          <h1 className="basic-info-title">Location</h1>
          <div className="basic-info-action">
            <p className="basic-info-content-text">{context.user.address}</p>
            <i className="fas fa-chevron-right right-grey-chevron" />
          </div>
        </div>
        <div
          className="basic-info-row no-bottom-border"
          onClick={() => setEditAgeOpen(true)}
        >
          <h1 className="basic-info-title">Birthdate</h1>
          <div className="basic-info-action">
            <p className="basic-info-content-text">{birthdate}</p>
            <i className="fas fa-chevron-right right-grey-chevron" />
          </div>
        </div>
        {/* <div className="basic-info-row">
          <h1 className="basic-info-title">Gender</h1>
          <div className="basic-info-action">
            <p className="basic-info-content-text">{context.user.gender}</p>
            <i className="fas fa-chevron-right right-grey-chevron" />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default withRouter(BasicInfo);
