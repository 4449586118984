import React from "react";
import { useState } from "react";
import { Button, Image } from "react-bootstrap";
import homenavIcon from "../../../assets/logos/sugarbae-home-icon.png";
import "./homenav.scss";
import twitterIcon from "../../../assets/Icons/twitter-icon.svg";
import instagramIcon from "../../../assets/Icons/instagram-icon.svg";
import { Link, withRouter } from "react-router-dom";

function HomeNav(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = (e) => {
    var open = true;
    if (menuOpen) {
      open = false;
    }
    setMenuOpen(open);
  };

  var homeNavStyle = "home-nav";

  if (menuOpen) {
    return (
      <div className="home-nav-open">
        <div className="home-nav">
          <div className="home-nav-innerwrap">
            <div className="home-nav-icon-wrapper">
              <Link to="/">
                <Image src={homenavIcon} className="home-nav-icon" />
              </Link>
            </div>
            <div className="home-nav-menu-wrapper">
              <Button className="home-nav-menu-button" onClick={toggleMenu}>
                {menuOpen ? (
                  <i className="fal fa-times home-nav-menu-icon"></i>
                ) : (
                  <i className="fal fa-bars home-nav-menu-icon"></i>
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className="home-nav-dropdown">
          <nav className="home-nav-dropdown-nav">
            <Link to="/login" className="home-nav-dropdown-link">
              Login
            </Link>
            <Link to="/signup" className="home-nav-dropdown-link">
              Signup
            </Link>
            <Link to="/faq" className="home-nav-dropdown-link dropdown-spacer">
              FAQ
            </Link>
            <Link to="/contact" className="home-nav-dropdown-link">
              Contact
            </Link>
            <Link to="/about" className="home-nav-dropdown-link">
              About
            </Link>
          </nav>
          <div className="home-nav-dropdown-social-wrapper">
            <Link to="/" className="home-nav-dropdown-social-link">
              <Image
                src={twitterIcon}
                className="home-nav-dropdown-social-icon"
              />
            </Link>
            <Link to="/" className="home-nav-dropdown-social-link">
              <Image
                src={instagramIcon}
                className="home-nav-dropdown-social-icon"
              />
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={homeNavStyle}>
        <div className="home-nav-innerwrap">
          <div className="home-nav-icon-wrapper">
            <Link to="/">
              <Image src={homenavIcon} className="home-nav-icon" />
            </Link>
          </div>
          <div className="home-nav-menu-wrapper">
            <Button className="home-nav-menu-button" onClick={toggleMenu}>
              {menuOpen ? (
                <i className="fal fa-times home-nav-menu-icon"></i>
              ) : (
                <i className="fal fa-bars home-nav-menu-icon"></i>
              )}
            </Button>
          </div>
          <div className="home-nav-desktop-links">
            <nav className="home-nav-desktop-nav">
              <Link to="/login" className="home-nav-desktop-link">
                Login
              </Link>
              <Link to="/signup" className="home-nav-desktop-link">
                Signup
              </Link>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(HomeNav);
