import React, { Component } from "react";
import "./app.scss";
import { UserContext } from "../../context/UserContext";
import { withRouter } from "react-router-dom";
import Swipe from "../../components/swipe/Swipe";
import WelcomeModal from "../../components/modals/welcomeModal/WelcomeModal";
import DesktopAppV2 from "../../components/desktop/desktopV2/desktopAppV2/DesktopAppV2";

export class App extends Component {
  static contextType = UserContext;

  state = {
    welcomeModalOpen: false,
  };

  componentWillMount() {
    var userid = this.context.user._id;
    if (this.props.location.state) {
      this.setState({
        welcomeModalOpen: this.props.location.state.welcomeModalOpen,
      });
    }
    this.props.handleBoost(userid);
  }

  handleBoost = (e) => {
    var userid = this.context.user._id;
    this.props.handleBoost(userid);
  };

  closeModalHandler = (e) => {
    this.setState({ welcomeModalOpen: false });
  };

  render() {
    let vh = window.innerHeight;
    var vw = window.innerWidth;
    var height = vh + "px";
    var width = vw + "px";

    if (vw >= 992) {
      return <DesktopAppV2 />;
    } else {
      return (
        <div
          className="app-component"
          style={{
            height: height,
            width: width,
            maxHeight: height,
            maxWidth: width,
          }}
        >
          {this.state.welcomeModalOpen ? (
            <WelcomeModal closeHandler={this.closeModalHandler} />
          ) : null}
          <Swipe handleBoost={this.handleBoost} />
        </div>
      );
    }
  }
}

export default withRouter(App);
