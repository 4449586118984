import React, { useState, useEffect, useContext } from "react";
import "./desktopsearchfilters.scss";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";
import { UserContext } from "../../../../context/UserContext";
import PlacesAutocomplete from "react-places-autocomplete";
import { Form, Button } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import { api } from "../../../../api";
var heights = [
  "4'0\"",
  "4'1\"",
  "4'2\"",
  "4'3\"",
  "4'4\"",
  "4'5\"",
  "4'6\"",
  "4'7\"",
  "4'8\"",
  "4'9\"",
  "4'10\"",
  "4'11\"",
  "5'0\"",
  "5'1\"",
  "5'2\"",
  "5'3\"",
  "5'4\"",
  "5'5\"",
  "5'6\"",
  "5'7\"",
  "5'8\"",
  "5'9\"",
  "5'10\"",
  "5'11\"",
  "6'0\"",
  "6'1\"",
  "6'2\"",
  "6'3\"",
  "6'4\"",
  "6'5\"",
  "6'6\"",
  "6'7\"",
  "6'8\"",
  "6'9\"",
  "6'10\"",
  "6'11\"",
  "7'0\"",
];

function DesktopSearchFilters(props) {
  const context = useContext(UserContext);
  const [filters, setFilters] = useState(props.filters);
  const [address, setAddress] = useState(context.user.address);
  const [distance, setDistance] = useState(props.filters.distance);
  const [age, setAge] = useState([18, 80]);
  const [height, setHeight] = useState(props.filters.height);
  const [tabsOpen, setTabsOpen] = useState({
    age: true,
    ethnicity: true,
    height: true,
    bodytype: true,
    education: false,
    relationship: false,
    children: false,
    smoking: false,
    drinking: false,
    cannabis: false,
    exercise: false,
  });
  const [saveFiltersTrigger, setSaveFiltersTrigger] = useState(0);

  useEffect(() => {
    if (saveFiltersTrigger === 0) {
      var tabsOpenCopy = {
        age: true,
        ethnicity: true,
        height: true,
        bodytype: true,
        education: false,
        relationship: false,
        children: false,
        smoking: false,
        drinking: false,
        cannabis: false,
        exercise: false,
      };
      var ethnicityCount = Object.values(filters.ethnicity).filter(
        (item) => item === true
      ).length;
      var bodytypeCount = Object.values(filters.bodytype).filter(
        (item) => item === true
      ).length;
      var educationCount = Object.values(filters.education).filter(
        (item) => item === true
      ).length;
      var relationshipCount = Object.values(filters.relationshipstatus).filter(
        (item) => item === true
      ).length;
      var childrenCount = Object.values(filters.children).filter(
        (item) => item === true
      ).length;
      var smokingCount = Object.values(filters.smoking).filter(
        (item) => item === true
      ).length;
      var drinkingCount = Object.values(filters.drinking).filter(
        (item) => item === true
      ).length;
      var exerciseCount = Object.values(filters.exercise).filter(
        (item) => item === true
      ).length;

      if (ethnicityCount > 0) {
        tabsOpenCopy.ethnicity = true;
      }
      if (bodytypeCount > 0) {
        tabsOpenCopy.bodytype = true;
      }
      if (educationCount > 0) {
        tabsOpenCopy.education = true;
      }
      if (relationshipCount > 0) {
        tabsOpenCopy.relationshipstatus = true;
      }
      if (childrenCount > 0) {
        tabsOpenCopy.children = true;
      }
      if (smokingCount > 0) {
        tabsOpenCopy.smoking = true;
      }
      if (drinkingCount > 0) {
        tabsOpenCopy.drinking = true;
      }
      if (exerciseCount > 0) {
        tabsOpenCopy.exercise = true;
      }

      setTabsOpen(tabsOpenCopy);

      if (props.filters.age) {
        setAge([props.filters.age[0], props.filters.age[1]]);
      }
    }

    const saveFilters = async (e) => {
      const data = {
        user: context.user._id,
        filters: filters,
      };

      return api
        .updateFilters(data)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    };

    if (saveFiltersTrigger !== 0) {
      saveFilters();
    }
  }, [saveFiltersTrigger]);

  const handleAddressChange = (e) => {
    setAddress(e);
  };

  const handleAddressSelect = async (e) => {
    setAddress(e);

    const data = {
      userid: context.user._id,
      address: e,
    };

    return api
      .updateAddress(data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const distanceChange = (event, newValue) => {
    setDistance(newValue);
  };

  const distanceSelect = (event, newValue) => {
    var filtersCopy = filters;
    setDistance(newValue);

    filtersCopy.distance = newValue;

    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });
    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const ageChange = (event, newValue) => {
    setAge(newValue);
  };

  const ageSelect = (event, newValue) => {
    var filtersCopy = filters;

    setAge(newValue);

    filtersCopy.age = newValue;
    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });

    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const heightChange = (event, newValue) => {
    setHeight(newValue);
  };

  const heightSelect = (event, newValue) => {
    var filtersCopy = filters;

    setHeight(newValue);

    filtersCopy.height = newValue;

    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });

    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const bodytypeToggle = (name) => (e) => {
    var filtersCopy = filters;
    if (name === "slim") {
      if (filtersCopy.bodytype.slim) {
        filtersCopy.bodytype.slim = false;
      } else {
        filtersCopy.bodytype.slim = true;
      }
    } else if (name === "muscular") {
      if (filtersCopy.bodytype.muscular) {
        filtersCopy.bodytype.muscular = false;
      } else {
        filtersCopy.bodytype.muscular = true;
      }
    } else if (name === "fullfigured") {
      if (filtersCopy.bodytype.fullfigured) {
        filtersCopy.bodytype.fullfigured = false;
      } else {
        filtersCopy.bodytype.fullfigured = true;
      }
    } else if (name === "fit") {
      if (filtersCopy.bodytype.fit) {
        filtersCopy.bodytype.fit = false;
      } else {
        filtersCopy.bodytype.fit = true;
      }
    } else if (name === "curvy") {
      if (filtersCopy.bodytype.curvy) {
        filtersCopy.bodytype.curvy = false;
      } else {
        filtersCopy.bodytype.curvy = true;
      }
    } else if (name === "average") {
      if (filtersCopy.bodytype.average) {
        filtersCopy.bodytype.average = false;
      } else {
        filtersCopy.bodytype.average = true;
      }
    } else if (name === "afewextrapounds") {
      if (filtersCopy.bodytype.afewextrapounds) {
        filtersCopy.bodytype.afewextrapounds = false;
      } else {
        filtersCopy.bodytype.afewextrapounds = true;
      }
    }

    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });
    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const sortByToggle = (e) => {
    var filtersCopy = filters;

    filtersCopy.sortby = e.target.value;

    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });
    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const ethnicityToggle = (name) => (e) => {
    var filtersCopy = filters;
    if (name === "asian") {
      if (filtersCopy.ethnicity.asian) {
        filtersCopy.ethnicity.asian = false;
      } else {
        filtersCopy.ethnicity.asian = true;
      }
    } else if (name === "black") {
      if (filtersCopy.ethnicity.black) {
        filtersCopy.ethnicity.black = false;
      } else {
        filtersCopy.ethnicity.black = true;
      }
    } else if (name === "hispanic") {
      if (filtersCopy.ethnicity.hispanic) {
        filtersCopy.ethnicity.hispanic = false;
      } else {
        filtersCopy.ethnicity.hispanic = true;
      }
    } else if (name === "indian") {
      if (filtersCopy.ethnicity.indian) {
        filtersCopy.ethnicity.indian = false;
      } else {
        filtersCopy.ethnicity.indian = true;
      }
    } else if (name === "middleeastern") {
      if (filtersCopy.ethnicity.middleeastern) {
        filtersCopy.ethnicity.middleeastern = false;
      } else {
        filtersCopy.ethnicity.middleeastern = true;
      }
    } else if (name === "white") {
      if (filtersCopy.ethnicity.white) {
        filtersCopy.ethnicity.white = false;
      } else {
        filtersCopy.ethnicity.white = true;
      }
    } else if (name === "other") {
      if (filtersCopy.ethnicity.other) {
        filtersCopy.ethnicity.other = false;
      } else {
        filtersCopy.ethnicity.other = true;
      }
    }

    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });
    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const educationToggle = (name) => (e) => {
    var filtersCopy = filters;
    if (name === "highschool") {
      if (filtersCopy.education.highschool) {
        filtersCopy.education.highschool = false;
      } else {
        filtersCopy.education.highschool = true;
      }
    } else if (name === "somecollege") {
      if (filtersCopy.education.somecollege) {
        filtersCopy.education.somecollege = false;
      } else {
        filtersCopy.education.somecollege = true;
      }
    } else if (name === "associatesdegree") {
      if (filtersCopy.education.associatesdegree) {
        filtersCopy.education.associatesdegree = false;
      } else {
        filtersCopy.education.associatesdegree = true;
      }
    } else if (name === "bachelorsdegree") {
      if (filtersCopy.education.bachelorsdegree) {
        filtersCopy.education.bachelorsdegree = false;
      } else {
        filtersCopy.education.bachelorsdegree = true;
      }
    } else if (name === "graduatedegree") {
      if (filtersCopy.education.graduatedegree) {
        filtersCopy.education.graduatedegree = false;
      } else {
        filtersCopy.education.graduatedegree = true;
      }
    } else if (name === "phd") {
      if (filtersCopy.education.phd) {
        filtersCopy.education.phd = false;
      } else {
        filtersCopy.education.phd = true;
      }
    }

    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });
    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const relationshipToggle = (name) => (e) => {
    var filtersCopy = filters;
    if (name === "single") {
      if (filtersCopy.relationshipstatus.single) {
        filtersCopy.relationshipstatus.single = false;
      } else {
        filtersCopy.relationshipstatus.single = true;
      }
    } else if (name === "openrelationship") {
      if (filtersCopy.relationshipstatus.openrelationship) {
        filtersCopy.relationshipstatus.openrelationship = false;
      } else {
        filtersCopy.relationshipstatus.openrelationship = true;
      }
    } else if (name === "divorced") {
      if (filtersCopy.relationshipstatus.divorced) {
        filtersCopy.relationshipstatus.divorced = false;
      } else {
        filtersCopy.relationshipstatus.divorced = true;
      }
    } else if (name === "marriedbutlooking") {
      if (filtersCopy.relationshipstatus.marriedbutlooking) {
        filtersCopy.relationshipstatus.marriedbutlooking = false;
      } else {
        filtersCopy.relationshipstatus.marriedbutlooking = true;
      }
    } else if (name === "separated") {
      if (filtersCopy.relationshipstatus.separated) {
        filtersCopy.relationshipstatus.separated = false;
      } else {
        filtersCopy.relationshipstatus.separated = true;
      }
    } else if (name === "widowed") {
      if (filtersCopy.relationshipstatus.widowed) {
        filtersCopy.relationshipstatus.widowed = false;
      } else {
        filtersCopy.relationshipstatus.widowed = true;
      }
    }

    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });
    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const childrenToggle = (name) => (e) => {
    var filtersCopy = filters;
    if (name === "nokids") {
      if (filtersCopy.children.nokids) {
        filtersCopy.children.nokids = false;
      } else {
        filtersCopy.children.nokids = true;
      }
    } else if (name === "onekid") {
      if (filtersCopy.children.onekid) {
        filtersCopy.children.onekid = false;
      } else {
        filtersCopy.children.onekid = true;
      }
    } else if (name === "twokids") {
      if (filtersCopy.children.twokids) {
        filtersCopy.children.twokids = false;
      } else {
        filtersCopy.children.twokids = true;
      }
    } else if (name === "threekids") {
      if (filtersCopy.children.threekids) {
        filtersCopy.children.threekids = false;
      } else {
        filtersCopy.children.threekids = true;
      }
    } else if (name === "fourpluskids") {
      if (filtersCopy.children.fourpluskids) {
        filtersCopy.children.fourpluskids = false;
      } else {
        filtersCopy.children.fourpluskids = true;
      }
    }

    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });
    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const smokingToggle = (name) => (e) => {
    var filtersCopy = filters;
    if (name === "nonsmoker") {
      if (filtersCopy.smoking.nonsmoker) {
        filtersCopy.smoking.nonsmoker = false;
      } else {
        filtersCopy.smoking.nonsmoker = true;
      }
    } else if (name === "lightsmoker") {
      if (filtersCopy.smoking.lightsmoker) {
        filtersCopy.smoking.lightsmoker = false;
      } else {
        filtersCopy.smoking.lightsmoker = true;
      }
    } else if (name === "heavysmoker") {
      if (filtersCopy.smoking.heavysmoker) {
        filtersCopy.smoking.heavysmoker = false;
      } else {
        filtersCopy.smoking.heavysmoker = true;
      }
    }

    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });
    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const drinkingToggle = (name) => (e) => {
    var filtersCopy = filters;
    if (name === "nondrinker") {
      if (filtersCopy.drinking.nondrinker) {
        filtersCopy.drinking.nondrinker = false;
      } else {
        filtersCopy.drinking.nondrinker = true;
      }
    } else if (name === "socialdrinker") {
      if (filtersCopy.drinking.socialdrinker) {
        filtersCopy.drinking.socialdrinker = false;
      } else {
        filtersCopy.drinking.socialdrinker = true;
      }
    } else if (name === "heavydrinker") {
      if (filtersCopy.drinking.heavydrinker) {
        filtersCopy.drinking.heavydrinker = false;
      } else {
        filtersCopy.drinking.heavydrinker = true;
      }
    }

    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });
    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const exerciseToggle = (name) => (e) => {
    var filtersCopy = filters;
    if (name === "daily") {
      if (filtersCopy.exercise.daily) {
        filtersCopy.exercise.daily = false;
      } else {
        filtersCopy.exercise.daily = true;
      }
    } else if (name === "often") {
      if (filtersCopy.exercise.often) {
        filtersCopy.exercise.often = false;
      } else {
        filtersCopy.exercise.often = true;
      }
    } else if (name === "regularly") {
      if (filtersCopy.exercise.regularly) {
        filtersCopy.exercise.regularly = false;
      } else {
        filtersCopy.exercise.regularly = true;
      }
    } else if (name === "sometimes") {
      if (filtersCopy.exercise.sometimes) {
        filtersCopy.exercise.sometimes = false;
      } else {
        filtersCopy.exercise.sometimes = true;
      }
    } else if (name === "never") {
      if (filtersCopy.exercise.never) {
        filtersCopy.exercise.never = false;
      } else {
        filtersCopy.exercise.never = true;
      }
    }

    setFilters((prevFilters) => {
      return { ...prevFilters, filtersCopy };
    });
    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const toggleTab = (tabName) => (e) => {
    var tabsOpenCopy = tabsOpen;
    if (tabName === "age") {
      if (tabsOpenCopy.age) {
        tabsOpenCopy.age = false;
      } else {
        tabsOpenCopy.age = true;
      }
    } else if (tabName === "ethnicity") {
      if (tabsOpenCopy.ethnicity) {
        tabsOpenCopy.ethnicity = false;
      } else {
        tabsOpenCopy.ethnicity = true;
      }
    } else if (tabName === "height") {
      if (tabsOpenCopy.height) {
        tabsOpenCopy.height = false;
      } else {
        tabsOpenCopy.height = true;
      }
    } else if (tabName === "bodytype") {
      if (tabsOpenCopy.bodytype) {
        tabsOpenCopy.bodytype = false;
      } else {
        tabsOpenCopy.bodytype = true;
      }
    } else if (tabName === "education") {
      if (tabsOpenCopy.education) {
        tabsOpenCopy.education = false;
      } else {
        tabsOpenCopy.education = true;
      }
    } else if (tabName === "relationship") {
      if (tabsOpenCopy.relationship) {
        tabsOpenCopy.relationship = false;
      } else {
        tabsOpenCopy.relationship = true;
      }
    } else if (tabName === "children") {
      if (tabsOpenCopy.children) {
        tabsOpenCopy.children = false;
      } else {
        tabsOpenCopy.children = true;
      }
    } else if (tabName === "smoking") {
      if (tabsOpenCopy.smoking) {
        tabsOpenCopy.smoking = false;
      } else {
        tabsOpenCopy.smoking = true;
      }
    } else if (tabName === "drinking") {
      if (tabsOpenCopy.drinking) {
        tabsOpenCopy.drinking = false;
      } else {
        tabsOpenCopy.drinking = true;
      }
    } else if (tabName === "cannabis") {
      if (tabsOpenCopy.cannabis) {
        tabsOpenCopy.cannabis = false;
      } else {
        tabsOpenCopy.cannabis = true;
      }
    } else if (tabName === "exercise") {
      if (tabsOpenCopy.exercise) {
        tabsOpenCopy.exercise = false;
      } else {
        tabsOpenCopy.exercise = true;
      }
    }

    setTabsOpen((prevTabsOpen) => {
      return { ...prevTabsOpen, tabsOpenCopy };
    });
  };

  const resetFilters = (e) => {
    var filtersCopy = filters;
    filtersCopy.distance = 200;
    filtersCopy.age = [18, 80];
    filtersCopy.height = [0, 36];
    filtersCopy.sortby = "Recently Active";
    filtersCopy.address = context.user.address;
    filtersCopy.distance = 200;
    filtersCopy.photosvideos = {
      photos: true,
      videos: false,
      verified: false,
    };
    filtersCopy.ethnicity = {
      white: false,
      black: false,
      asian: false,
      hispanic: false,
      indian: false,
      middleeastern: false,
      other: false,
    };
    filtersCopy.bodytype = {
      slim: false,
      fit: false,
      muscular: false,
      average: false,
      afewextrapounds: false,
      curvy: false,
      fullfigured: false,
    };
    filtersCopy.drinking = {
      nondrinker: false,
      socialdrinker: false,
      heavydrinker: false,
    };
    filtersCopy.smoking = {
      nonsmoker: false,
      lightsmoker: false,
      heavysmoker: false,
    };
    filtersCopy.exercise = {
      never: false,
      sometimes: false,
      regularly: false,
      often: false,
      daily: false,
    };
    filtersCopy.children = {
      nokids: false,
      onekid: false,
      twokids: false,
      threekids: false,
      fourpluskids: false,
    };
    filtersCopy.relationshipstatus = {
      single: false,
      divorced: false,
      separated: false,
      marriedbutlooking: false,
      openrelationship: false,
      widowed: false,
    };
    filtersCopy.education = {
      highschool: false,
      somecollege: false,
      associatesdegree: false,
      bachelorsdegree: false,
      graduatedegree: false,
      phd: false,
    };

    setDistance(filtersCopy.distance);
    setAge(filtersCopy.age);
    setHeight(filtersCopy.height);
    setAddress(filtersCopy.address);

    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        filtersCopy,
      };
    });
    setSaveFiltersTrigger(saveFiltersTrigger + 1);
  };

  const searchSubmitHandler = (e) => {
    props.searchSubmit();
  };

  const searchOptions = {
    types: ["(cities)"],
  };

  var minHeight = heights[height[0]];
  var maxHeight = heights[height[1]];

  return (
    <div className="desktop-search-filters-component">
      <div className="desktop-search-header">
        <h2 className="desktop-search-header-title">New Search</h2>
        <div className="desktop-search-header-button-wrapper">
          <Button
            className="desktop-search-button"
            size="lg"
            onClick={searchSubmitHandler}
          >
            Search
          </Button>
          <Button
            className="desktop-reset-filters-button"
            onClick={resetFilters}
          >
            Reset Filters
          </Button>
        </div>
      </div>
      <Form className="desktop-search-filters-sidebar-form">
        <div className="desktop-search-section">
          <h4 className="desktop-search-section-title">Sort By</h4>
          <div className="desktop-search-section-form-wrapper">
            <Form.Group className="desktop-search-section-form-group">
              <Form.Control
                as="select"
                onChange={sortByToggle}
                value={filters.sortby}
              >
                <option>Recently Active</option>
                <option>Newest</option>
              </Form.Control>
            </Form.Group>
          </div>
        </div>
        <div className="desktop-search-section-no-border">
          <h4 className="desktop-search-section-title">Location</h4>
          <div className="desktop-search-section-form-wrapper">
            <Form.Group className="desktop-search-section-form-group">
              <PlacesAutocomplete
                value={address}
                onChange={handleAddressChange}
                onSelect={handleAddressSelect}
                searchOptions={searchOptions}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="search-filters-location-input-wrapper">
                    <Form.Control
                      {...getInputProps({
                        placeholder: address,
                        className: "search-filters-location-input",
                      })}
                    />

                    <div className="search-filters-dropdown-card">
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Form.Group>
          </div>
        </div>
        <div className="desktop-search-section">
          <h4 className="desktop-search-section-title">Distance</h4>
          <div className="desktop-search-section-form-wrapper">
            <Form.Group className="desktop-search-section-form-group">
              <Form.Label className="desktop-search-section-label">
                {distance === 200 ? (
                  <span>Up to {distance}+ miles away</span>
                ) : (
                  <span>Up to {distance} miles away</span>
                )}
              </Form.Label>
              <Slider
                min={5}
                max={200}
                value={distance}
                onChange={distanceChange}
                onChangeCommitted={distanceSelect}
              />
            </Form.Group>
          </div>
        </div>
        <div className="desktop-search-section">
          <div className="desktop-search-section-title-wrapper">
            <h4 className="desktop-search-section-title">Age</h4>
            <Button
              className="desktop-search-section-toggle-button"
              onClick={toggleTab("age")}
            >
              {tabsOpen.age ? (
                <i className="fas fa-chevron-up desktop-search-section-toggle-icon"></i>
              ) : (
                <i className="fas fa-chevron-down desktop-search-section-toggle-icon"></i>
              )}
            </Button>
          </div>
          {tabsOpen.age && (
            <div className="desktop-search-section-form-wrapper">
              <Form.Group className="desktop-search-section-form-group">
                <Form.Label className="desktop-search-section-label">
                  {age[1] === 80 ? (
                    <span>
                      {age[0]} - {age[1]}+
                    </span>
                  ) : (
                    <span>
                      {age[0]} - {age[1]}
                    </span>
                  )}
                </Form.Label>
                <Slider
                  min={18}
                  max={80}
                  value={age}
                  onChange={ageChange}
                  onChangeCommitted={ageSelect}
                />
              </Form.Group>
            </div>
          )}
        </div>
        <div className="desktop-search-section">
          <div className="desktop-search-section-title-wrapper">
            <h4 className="desktop-search-section-title">Ethnicity</h4>
            <Button
              className="desktop-search-section-toggle-button"
              onClick={toggleTab("ethnicity")}
            >
              {tabsOpen.ethnicity ? (
                <i className="fas fa-chevron-up desktop-search-section-toggle-icon"></i>
              ) : (
                <i className="fas fa-chevron-down desktop-search-section-toggle-icon"></i>
              )}
            </Button>
          </div>
          {tabsOpen.ethnicity && (
            <div className="desktop-search-section-form-wrapper">
              <Form.Group className="desktop-search-section-form-group-checkboxes">
                <Form.Check
                  type="checkbox"
                  label="Asian"
                  checked={filters.ethnicity.asian}
                  className="desktop-search-filters-checkbox"
                  onChange={ethnicityToggle("asian")}
                  id="ethnicity-checkbox-asian"
                />
                <Form.Check
                  type="checkbox"
                  label="Black"
                  checked={filters.ethnicity.black}
                  className="desktop-search-filters-checkbox"
                  onChange={ethnicityToggle("black")}
                  id="ethnicity-checkbox-black"
                />
                <Form.Check
                  type="checkbox"
                  label="Hispanic"
                  checked={filters.ethnicity.hispanic}
                  className="desktop-search-filters-checkbox"
                  onChange={ethnicityToggle("hispanic")}
                  id="ethnicity-checkbox-hispanic"
                />
                <Form.Check
                  type="checkbox"
                  label="Indian"
                  checked={filters.ethnicity.indian}
                  className="desktop-search-filters-checkbox"
                  onChange={ethnicityToggle("indian")}
                  id="ethnicity-checkbox-indian"
                />
                <Form.Check
                  type="checkbox"
                  label="Middle Eastern"
                  checked={filters.ethnicity.middleeastern}
                  className="desktop-search-filters-checkbox"
                  onChange={ethnicityToggle("middleeastern")}
                  id="ethnicity-checkbox-middleeastern"
                />
                <Form.Check
                  type="checkbox"
                  label="White"
                  checked={filters.ethnicity.white}
                  className="desktop-search-filters-checkbox"
                  onChange={ethnicityToggle("white")}
                  id="ethnicity-checkbox-white"
                />
                <Form.Check
                  type="checkbox"
                  label="Other"
                  checked={filters.ethnicity.other}
                  className="desktop-search-filters-checkbox"
                  onChange={ethnicityToggle("other")}
                  id="ethnicity-checkbox-other"
                />
              </Form.Group>
            </div>
          )}
        </div>
        <div className="desktop-search-section">
          <div className="desktop-search-section-title-wrapper">
            <h4 className="desktop-search-section-title">Height</h4>
            <Button
              className="desktop-search-section-toggle-button"
              onClick={toggleTab("height")}
            >
              {tabsOpen.height ? (
                <i className="fas fa-chevron-up desktop-search-section-toggle-icon"></i>
              ) : (
                <i className="fas fa-chevron-down desktop-search-section-toggle-icon"></i>
              )}
            </Button>
          </div>
          {tabsOpen.height && (
            <div className="desktop-search-section-form-wrapper">
              <Form.Group className="desktop-search-section-form-group">
                <Form.Label>
                  {" "}
                  Between {minHeight} and {maxHeight}
                </Form.Label>
                <Slider
                  min={0}
                  max={36}
                  value={height}
                  onChange={heightChange}
                  onChangeCommitted={heightSelect}
                />
              </Form.Group>
            </div>
          )}
        </div>
        <div className="desktop-search-section">
          <div className="desktop-search-section-title-wrapper">
            <h4 className="desktop-search-section-title">Bodytype</h4>
            <Button
              className="desktop-search-section-toggle-button"
              onClick={toggleTab("bodytype")}
            >
              {tabsOpen.bodytype ? (
                <i className="fas fa-chevron-up desktop-search-section-toggle-icon"></i>
              ) : (
                <i className="fas fa-chevron-down desktop-search-section-toggle-icon"></i>
              )}
            </Button>
          </div>
          {tabsOpen.bodytype && (
            <div className="desktop-search-section-form-wrapper">
              <Form.Group className="desktop-search-section-form-group-checkboxes">
                <Form.Check
                  type="checkbox"
                  label="Slim"
                  checked={filters.bodytype.slim}
                  className="desktop-search-filters-checkbox"
                  onChange={bodytypeToggle("slim")}
                  id="bodytype-checkbox-slim"
                />
                <Form.Check
                  type="checkbox"
                  label="Muscular"
                  checked={filters.bodytype.muscular}
                  className="desktop-search-filters-checkbox"
                  onChange={bodytypeToggle("muscular")}
                  id="bodytype-checkbox-muscular"
                />
                <Form.Check
                  type="checkbox"
                  label="Full Figured"
                  checked={filters.bodytype.fullfigured}
                  className="desktop-search-filters-checkbox"
                  onChange={bodytypeToggle("fullfigured")}
                  id="bodytype-checkbox-fullfigured"
                />
                <Form.Check
                  type="checkbox"
                  label="Fit"
                  checked={filters.bodytype.fit}
                  className="desktop-search-filters-checkbox"
                  onChange={bodytypeToggle("fit")}
                  id="bodytype-checkbox-fit"
                />
                <Form.Check
                  type="checkbox"
                  label="Curvy"
                  checked={filters.bodytype.curvy}
                  className="desktop-search-filters-checkbox"
                  onChange={bodytypeToggle("curvy")}
                  id="bodytype-checkbox-curvy"
                />
                <Form.Check
                  type="checkbox"
                  label="Average"
                  checked={filters.bodytype.average}
                  className="desktop-search-filters-checkbox"
                  onChange={bodytypeToggle("average")}
                  id="bodytype-checkbox-average"
                />
                <Form.Check
                  type="checkbox"
                  label="A Few Extra Pounds"
                  checked={filters.bodytype.afewextrapounds}
                  className="desktop-search-filters-checkbox"
                  onChange={bodytypeToggle("afewextrapounds")}
                  id="bodytype-checkbox-afewextrapounds"
                />
              </Form.Group>
            </div>
          )}
        </div>
        <div className="desktop-search-section">
          <div className="desktop-search-section-title-wrapper">
            <h4 className="desktop-search-section-title">Education</h4>
            <Button
              className="desktop-search-section-toggle-button"
              onClick={toggleTab("education")}
            >
              {tabsOpen.education ? (
                <i className="fas fa-chevron-up desktop-search-section-toggle-icon"></i>
              ) : (
                <i className="fas fa-chevron-down desktop-search-section-toggle-icon"></i>
              )}
            </Button>
          </div>
          {tabsOpen.education && (
            <div className="desktop-search-section-form-wrapper">
              <Form.Group className="desktop-search-section-form-group-checkboxes">
                <Form.Check
                  type="checkbox"
                  label="High School"
                  checked={filters.education.highschool}
                  className="desktop-search-filters-checkbox"
                  onChange={educationToggle("highschool")}
                  id="education-checkbox-highschool"
                />
                <Form.Check
                  type="checkbox"
                  label="Some College"
                  checked={filters.education.somecollege}
                  className="desktop-search-filters-checkbox"
                  onChange={educationToggle("somecollege")}
                  id="education-checkbox-somecollege"
                />
                <Form.Check
                  type="checkbox"
                  label="Associates Degree"
                  checked={filters.education.associatesdegree}
                  className="desktop-search-filters-checkbox"
                  onChange={educationToggle("associatesdegree")}
                  id="education-checkbox-associatesdegree"
                />
                <Form.Check
                  type="checkbox"
                  label="Bachelors Degree"
                  checked={filters.education.bachelorsdegree}
                  className="desktop-search-filters-checkbox"
                  onChange={educationToggle("bachelorsdegree")}
                  id="education-checkbox-bachelorsdegree"
                />
                <Form.Check
                  type="checkbox"
                  label="Graduates Degree"
                  checked={filters.education.graduatedegree}
                  className="desktop-search-filters-checkbox"
                  onChange={educationToggle("graduatedegree")}
                  id="education-checkbox-graduatedegree"
                />
                <Form.Check
                  type="checkbox"
                  label="PhD"
                  checked={filters.education.phd}
                  className="desktop-search-filters-checkbox"
                  onChange={educationToggle("phd")}
                  id="education-checkbox-phd"
                />
              </Form.Group>
            </div>
          )}
        </div>
        <div className="desktop-search-section">
          <div className="desktop-search-section-title-wrapper">
            <h4 className="desktop-search-section-title">Relationship</h4>
            <Button
              className="desktop-search-section-toggle-button"
              onClick={toggleTab("relationship")}
            >
              {tabsOpen.relationship ? (
                <i className="fas fa-chevron-up desktop-search-section-toggle-icon"></i>
              ) : (
                <i className="fas fa-chevron-down desktop-search-section-toggle-icon"></i>
              )}
            </Button>
          </div>
          {tabsOpen.relationship && (
            <div className="desktop-search-section-form-wrapper">
              <Form.Group className="desktop-search-section-form-group-checkboxes">
                <Form.Check
                  type="checkbox"
                  label="Single"
                  checked={filters.relationshipstatus.single}
                  className="desktop-search-filters-checkbox"
                  onChange={relationshipToggle("single")}
                  id="relationshipstatus-checkbox-single"
                />
                <Form.Check
                  type="checkbox"
                  label="Open Relationship"
                  checked={filters.relationshipstatus.openrelationship}
                  className="desktop-search-filters-checkbox"
                  onChange={relationshipToggle("openrelationship")}
                  id="relationshipstatus-checkbox-openrelationship"
                />
                <Form.Check
                  type="checkbox"
                  label="Divorced"
                  checked={filters.relationshipstatus.divorced}
                  className="desktop-search-filters-checkbox"
                  onChange={relationshipToggle("divorced")}
                  id="relationshipstatus-checkbox-divorced"
                />
                <Form.Check
                  type="checkbox"
                  label="Married But Looking"
                  checked={filters.relationshipstatus.marriedbutlooking}
                  className="desktop-search-filters-checkbox"
                  onChange={relationshipToggle("marriedbutlooking")}
                  id="relationshipstatus-checkbox-marriedbutlooking"
                />
                <Form.Check
                  type="checkbox"
                  label="Separated"
                  checked={filters.relationshipstatus.separated}
                  className="desktop-search-filters-checkbox"
                  onChange={relationshipToggle("separated")}
                  id="relationshipstatus-checkbox-separated"
                />
                <Form.Check
                  type="checkbox"
                  label="Widowed"
                  checked={filters.relationshipstatus.widowed}
                  className="desktop-search-filters-checkbox"
                  onChange={relationshipToggle("widowed")}
                  id="relationshipstatus-checkbox-widowed"
                />
              </Form.Group>
            </div>
          )}
        </div>
        <div className="desktop-search-section">
          <div className="desktop-search-section-title-wrapper">
            <h4 className="desktop-search-section-title">Children</h4>
            <Button
              className="desktop-search-section-toggle-button"
              onClick={toggleTab("children")}
            >
              {tabsOpen.children ? (
                <i className="fas fa-chevron-up desktop-search-section-toggle-icon"></i>
              ) : (
                <i className="fas fa-chevron-down desktop-search-section-toggle-icon"></i>
              )}
            </Button>
          </div>
          {tabsOpen.children && (
            <div className="desktop-search-section-form-wrapper">
              <Form.Group className="desktop-search-section-form-group-checkboxes">
                <Form.Check
                  type="checkbox"
                  label="No Kids"
                  checked={filters.children.nokids}
                  className="desktop-search-filters-checkbox"
                  onChange={childrenToggle("nokids")}
                  id="children-checkbox-nokids"
                />
                <Form.Check
                  type="checkbox"
                  label="One Kid"
                  checked={filters.children.onekid}
                  className="desktop-search-filters-checkbox"
                  onChange={childrenToggle("onekid")}
                  id="children-checkbox-onekid"
                />
                <Form.Check
                  type="checkbox"
                  label="2 Kids"
                  checked={filters.children.twokids}
                  className="desktop-search-filters-checkbox"
                  onChange={childrenToggle("twokids")}
                  id="children-checkbox-twokids"
                />
                <Form.Check
                  type="checkbox"
                  label="3 Kids"
                  checked={filters.children.threekids}
                  className="desktop-search-filters-checkbox"
                  onChange={childrenToggle("threekids")}
                  id="children-checkbox-threekids"
                />
                <Form.Check
                  type="checkbox"
                  label="4+ Kids"
                  checked={filters.children.fourpluskids}
                  className="desktop-search-filters-checkbox"
                  onChange={childrenToggle("fourpluskids")}
                  id="children-checkbox-fourpluskids"
                />
              </Form.Group>
            </div>
          )}
        </div>
        <div className="desktop-search-section">
          <div className="desktop-search-section-title-wrapper">
            <h4 className="desktop-search-section-title">Smoking</h4>
            <Button
              className="desktop-search-section-toggle-button"
              onClick={toggleTab("smoking")}
            >
              {tabsOpen.smoking ? (
                <i className="fas fa-chevron-up desktop-search-section-toggle-icon"></i>
              ) : (
                <i className="fas fa-chevron-down desktop-search-section-toggle-icon"></i>
              )}
            </Button>
          </div>
          {tabsOpen.smoking && (
            <div className="desktop-search-section-form-wrapper">
              <Form.Group className="desktop-search-section-form-group-checkboxes">
                <Form.Check
                  type="checkbox"
                  label="Non Smoker"
                  checked={filters.smoking.nonsmoker}
                  className="desktop-search-filters-checkbox"
                  onChange={smokingToggle("nonsmoker")}
                  id="smoking-checkbox-nonsmoker"
                />
                <Form.Check
                  type="checkbox"
                  label="Light Smoker"
                  checked={filters.smoking.lightsmoker}
                  className="desktop-search-filters-checkbox"
                  onChange={smokingToggle("lightsmoker")}
                  id="smoking-checkbox-lightsmoker"
                />
                <Form.Check
                  type="checkbox"
                  label="Heavy Smoker"
                  checked={filters.smoking.heavysmoker}
                  className="desktop-search-filters-checkbox"
                  onChange={smokingToggle("heavysmoker")}
                  id="smoking-checkbox-heavysmoker"
                />
              </Form.Group>
            </div>
          )}
        </div>
        <div className="desktop-search-section">
          <div className="desktop-search-section-title-wrapper">
            <h4 className="desktop-search-section-title">Drinking</h4>
            <Button
              className="desktop-search-section-toggle-button"
              onClick={toggleTab("drinking")}
            >
              {tabsOpen.drinking ? (
                <i className="fas fa-chevron-up desktop-search-section-toggle-icon"></i>
              ) : (
                <i className="fas fa-chevron-down desktop-search-section-toggle-icon"></i>
              )}
            </Button>
          </div>
          {tabsOpen.drinking && (
            <div className="desktop-search-section-form-wrapper">
              <Form.Group className="desktop-search-section-form-group-checkboxes">
                <Form.Check
                  type="checkbox"
                  label="Non Drinker"
                  checked={filters.drinking.nonsmoker}
                  className="desktop-search-filters-checkbox"
                  onChange={drinkingToggle("nondrinker")}
                  id="drinking-checkbox-nondrinker"
                />
                <Form.Check
                  type="checkbox"
                  label="Social Drinker"
                  checked={filters.drinking.lightsmoker}
                  className="desktop-search-filters-checkbox"
                  onChange={drinkingToggle("socialdrinker")}
                  id="drinking-checkbox-socialdrinker"
                />
                <Form.Check
                  type="checkbox"
                  label="Heavy Drinker"
                  checked={filters.drinking.heavydrinker}
                  className="desktop-search-filters-checkbox"
                  onChange={drinkingToggle("heavydrinker")}
                  id="drinking-checkbox-heavydrinker"
                />
              </Form.Group>
            </div>
          )}
        </div>
        <div className="desktop-search-section">
          <div className="desktop-search-section-title-wrapper">
            <h4 className="desktop-search-section-title">Exercise</h4>
            <Button
              className="desktop-search-section-toggle-button"
              onClick={toggleTab("exercise")}
            >
              {tabsOpen.exercise ? (
                <i className="fas fa-chevron-up desktop-search-section-toggle-icon"></i>
              ) : (
                <i className="fas fa-chevron-down desktop-search-section-toggle-icon"></i>
              )}
            </Button>
          </div>
          {tabsOpen.exercise && (
            <div className="desktop-search-section-form-wrapper">
              <Form.Group className="desktop-search-section-form-group-checkboxes">
                <Form.Check
                  type="checkbox"
                  label="Daily"
                  checked={filters.exercise.daily}
                  className="desktop-search-filters-checkbox"
                  onChange={exerciseToggle("daily")}
                  id="exercise-checkbox-daily"
                />
                <Form.Check
                  type="checkbox"
                  label="Often"
                  checked={filters.exercise.often}
                  className="desktop-search-filters-checkbox"
                  onChange={exerciseToggle("often")}
                  id="exercise-checkbox-often"
                />
                <Form.Check
                  type="checkbox"
                  label="Regularly"
                  checked={filters.exercise.regularly}
                  className="desktop-search-filters-checkbox"
                  onChange={exerciseToggle("regularly")}
                  id="exercise-checkbox-regularly"
                />
                <Form.Check
                  type="checkbox"
                  label="Sometimes"
                  checked={filters.exercise.sometimes}
                  className="desktop-search-filters-checkbox"
                  onChange={exerciseToggle("sometimes")}
                  id="exercise-checkbox-sometimes"
                />
                <Form.Check
                  type="checkbox"
                  label="Never"
                  checked={filters.exercise.never}
                  className="desktop-search-filters-checkbox"
                  onChange={exerciseToggle("never")}
                  id="exercise-checkbox-never"
                />
              </Form.Group>
            </div>
          )}
        </div>
      </Form>
    </div>
  );
}

export default withRouter(DesktopSearchFilters);
