import React, { useContext } from 'react';
import { Image as BootstrapImage } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import { useHistory, withRouter } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import matchIcon from '../../../../assets/Icons/match-white.png';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import Media from '../../../../components/media/Media';

function DesktopNewMatches(props) {
  const context = useContext(UserContext);
  const history = useHistory();

  const profileHandler = (e) => {
    const url = `/app/member/${e}`;
    history.push(url);
  };

  if (context.matchData.length > 0) {
    return (
      <div>
        <h1 className="desktop-queue-match-title">New Matches</h1>
        <div className="desktop-queue-match-container">
          {context.matchData.map((match) => {
            let mainImage = null;
            if (match.user.imgurl.length > 0) {
              mainImage = match.user.imgurl[0];
            }

            return (
              <div
                className="desktop-queue-image-wrapper"
                onClick={() => profileHandler(match.user._id)}
              >
                <div key={match.id} className="desktop-match-indicator-v2">
                  <BootstrapImage
                    src={matchIcon}
                    fluid
                    className="desktop-match-indicator-icon"
                  />
                </div>

                <Media image={mainImage} />
                {/* <Image
                  publicId={
                    mainImage
                      ? getImgName(mainImage)
                      : 'placeholder-user-image-thumbnail_vankru.png'
                  }
                  version={mainImage ? getImgVersion(mainImage) : '1590045309'}
                  resource_type={
                    mainImage ? (checkGIF(mainImage) ? 'video' : 'image') : null
                  }
                  cloud_name="sugarbae"
                  className="desktop-match-image"
                  fluid
                >
                  <Transformation
                    height="200"
                    video_sampling="20"
                    delay="250"
                    duration="10"
                    effect="loop"
                    quality="auto"
                    flags="lossy"
                  />
                </Image> */}
              </div>
            );
          })}
          <div className="desktop-match-end-spacer" />
        </div>
      </div>
    );
  }

  return null;
}

export default withRouter(DesktopNewMatches);
