import React, { useState, useEffect } from "react";
import { api } from "../../api";
import "./graphs.scss";
import {
  ComposedChart,
  BarChart,
  Bar,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
var moment = require("moment");

function Conversions(props) {
  //const [maleSignupData, setMaleSignupData] = useState([]);
  //const [femaleSignupData, setFemaleSignupData] = useState([]);
  const [transactionData, setConversionData] = useState([]); 
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    return api
      .fetchConversionData()
      .then((res) => {
        if (res.status === 200) {
          setConversionData(res.data.transactionData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderCustomAxisTick = ({ x, y, payload }) => {
    var date = payload.value;

    var formattedDate = moment(date).format("MMM DD").toString();

    return (
      <text x={x} y={y + 25} fill="#666" textAnchor="middle">
        {formattedDate}
      </text>
    );
  };
  return (
    <div className="graph-container">
      <div className="graph-header">
        <h1 className="graph-title">30 Days Daily Transaction by Amount </h1>
      </div>
      <div className="graph-content-container">
        <div className= "graph-content">
          <h2 className="graph-content-title">All Transactions  </h2>
          <h3 className="graph-content-title">Conversions postive, Deduction negative  </h3>
          <ComposedChart
            width={700}
            height={400}
            data={transactionData}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <XAxis dataKey="_id" tick={renderCustomAxisTick} />
            <YAxis yAxisId="left" />
            <Bar yAxisId="left" dataKey="newsubAmount" fill="#3fb028" stackId = "a"/>
            <Bar yAxisId="left" dataKey="rebillAmount" fill="#00c3ff" stackId = "a"/>
            <Bar yAxisId="left" dataKey="boostAmount" fill="#28b087" stackId = "a"/>
            <Bar yAxisId="left" dataKey="diamondAmount" fill="#608733" stackId = "a"/>
            <Bar yAxisId="left" dataKey="refundAmount" fill="#f08b4d" stackId = "a"/>
            <Bar yAxisId="left" dataKey="chargebackAmount" fill="#f0584d" stackId = "a"/>
            <Bar yAxisId="left" dataKey="voidedAmount" fill="#ebad1c" stackId = "a"/>
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <Tooltip />
          </ComposedChart>
          </div>
        </div>

    </div>
  );
}

export default Conversions;
