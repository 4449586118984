import React, { useState, useEffect, useContext } from 'react';
import './desktopsearchresults.scss';
import { Button } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import Axios from 'axios';
import {
  submitLike,
  submitSuperlike,
  submitUndo,
} from '../../../../helpers/swipeActions';
import SuperlikePop from '../../../swipe/swipeComponents/swipePops/SuperlikePop';
import LikePop from '../../../swipe/swipeComponents/swipePops/LikePop';
import UndoPop from '../../../swipe/swipeComponents/swipePops/UndoPop';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import Media from '../../../../components/media/Media';

var moment = require('moment');

function DesktopSearchCard(props) {
  const context = useContext(UserContext);

  const [liked, setLiked] = useState(props.isLiked);
  const [superliked, setSuperliked] = useState(props.isSuperliked);

  const [likePopOpen, setLikePopOpen] = useState(false);
  const [superlikePopOpen, setSuperlikePopOpen] = useState(false);
  const [undoPopOpen, setUndoPopOpen] = useState(false);

  const handleLike = async (e) => {
    if (!liked) {
      setLiked(true);

      const like = await submitLike(context.user._id, props.card._id).catch(
        (err) => {
          console.log(err);
        }
      );

      if (!like.valid) {
        setLiked(false);
        setLikePopOpen(true);
      }
    } else {
      handleUndo('like');
    }
  };
  const handleSuperlike = async (e) => {
    if (!superliked) {
      setSuperliked(true);

      const superlike = await submitSuperlike(
        context.user._id,
        props.card._id
      ).catch((err) => {
        console.log(err);
      });

      if (!superlike.valid) {
        setSuperliked(false);
        setSuperlikePopOpen(true);
      }
    } else {
      handleUndo('superlike');
    }
  };

  const handleUndo = async (e) => {
    var action = e;

    if (action === 'like') {
      setLiked(false);

      const undo = await submitUndo(
        context.user._id,
        props.card._id,
        action
      ).catch((err) => {
        console.log(err);
      });

      if (!undo.valid) {
        setLiked(true);
        setUndoPopOpen(true);
      }
    } else if (action === 'superlike') {
      setSuperliked(false);

      const undo = await submitUndo(
        context.user._id,
        props.card._id,
        action
      ).catch((err) => {
        console.log(err);
      });

      if (!undo.valid) {
        setSuperliked(true);
        setUndoPopOpen(true);
      }
    }
  };

  const popCloseHandler = (e) => {
    setLikePopOpen(false);
    setSuperlikePopOpen(false);
    setUndoPopOpen(false);
  };

  var images = props.card.imgurl;
  var mainImage = null;
  if (images.length > 0) {
    mainImage = images[0];
  }
  var imageCount = images.length;
  var verifications = props.card.verifications;
  var verified = false;
  if (verifications.email.status && verifications.phone.status) {
    verified = true;
  }
  var lastlogin = moment(props.card.lastlogin);
  var lastHour = moment().subtract(5, 'hours');
  var online = lastlogin.isAfter(lastHour);
  var profileUrl = '/app/member/' + props.card.profileurl;

  return (
    <div className="desktop-search-card">
      {likePopOpen && (
        <LikePop closeHandler={popCloseHandler} cardid={props.card} />
      )}
      {superlikePopOpen && (
        <SuperlikePop closeHandler={popCloseHandler} cardid={props.card} />
      )}
      {undoPopOpen && (
        <UndoPop closeHandler={popCloseHandler} cardid={props.card} />
      )}

      <Link
        to={profileUrl}
        className="desktop-search-card-image-wrap desktop-search-card-link"
      >
        {mainImage ? (
          <Media image={mainImage} />
        ) : (
          // <Image
          //   publicId={getImgName(mainImage)}
          //   version={getImgVersion(mainImage)}
          //   resource_type={checkGIF(mainImage) ? "video" : "image"}
          //   effect="loop"
          //   cloud_name="sugarbae"
          //   className="desktop-search-card-image"
          // >
          //   <Transformation
          //     height="200"
          //     video_sampling="20"
          //     delay="250"
          //     duration="10"
          //     effect="loop"
          //     quality="auto"
          //     flags="lossy"
          //   />
          // </Image>
          <div className="desktop-search-card-image-placeholder">
            <i className="far fa-user desktop-search-card-placeholder-icon"></i>
          </div>
        )}
        <div className="desktop-search-card-image-footer">
          <div className="desktop-search-card-image-footer-count-indicator">
            <i className="fas fa-camera desktop-search-user-card-camera-icon"></i>
            <span className="desktop-search-user-card-image-count">
              {imageCount}
            </span>
          </div>
        </div>
      </Link>
      <Link
        to={profileUrl}
        className="desktop-search-card-user-info-wrap desktop-search-card-link"
      >
        <div className="desktop-search-card-header-wrapper">
          <div className="desktop-search-card-username-wrap">
            <h3 className="desktop-search-card-username">
              {props.card.username}
            </h3>
            {verified && (
              <i className="fad fa-badge-check desktop-search-card-verified-icon"></i>
            )}
          </div>
          {online && (
            <div className="desktop-search-card-online-indicator">
              <i className="fas fa-circle desktop-search-card-online-icon"></i>{' '}
              <span className="desktop-search-card-online-text">Online</span>
            </div>
          )}
        </div>
        <div className="desktop-search-card-headline-age-address-wrap">
          <p className="desktop-search-card-headline">
            {props.card.headlinestatus === 'approved' &&
              props.card.headline &&
              props.card.headline}
          </p>
          <p className="desktop-search-card-age-location">
            {props.card.age} • {props.card.address}
          </p>
        </div>
        <div className="desktop-search-card-user-details-wrap">
          <div className="desktop-search-card-user-detail-row">
            <div className="desktop-search-card-user-detail-section">
              <p className="desktop-search-card-user-detail-title">Height</p>
              <p className="desktop-search-card-user-detail-text">
                {props.card.height ? props.card.height : '-'}
              </p>
            </div>
            <div className="desktop-search-card-user-detail-section">
              <p className="desktop-search-card-user-detail-title">Ethnicity</p>
              <p className="desktop-search-card-user-detail-text">
                {props.card.ethnicity ? props.card.ethnicity : '-'}
              </p>
            </div>
          </div>
          <div className="desktop-search-card-user-detail-row">
            <div className="desktop-search-card-user-detail-section">
              <p className="desktop-search-card-user-detail-title">Bodytype</p>
              <p className="desktop-search-card-user-detail-text">
                {props.card.bodytype ? props.card.bodytype : '-'}
              </p>
            </div>
            {props.card.gender === 'male' && (
              <div className="desktop-search-card-user-detail-section">
                <p className="desktop-search-card-user-detail-title">
                  Net Worth
                </p>
                <p className="desktop-search-card-user-detail-text">
                  {props.card.lifestyle.networth
                    ? props.card.lifestyle.networth
                    : '-'}
                </p>
              </div>
            )}
          </div>
        </div>
      </Link>
      <div className="desktop-search-card-actions-wrap">
        {!superliked && (
          <Button
            className="desktop-search-card-action-button"
            onClick={handleLike}
          >
            <i
              className={
                liked
                  ? 'fas fa-heart desktop-search-card-like-icon-checked'
                  : 'far fa-heart desktop-search-card-like-icon'
              }
            ></i>
          </Button>
        )}
        {!liked && (
          <Button
            className="desktop-search-card-action-button"
            onClick={handleSuperlike}
          >
            <i
              className={
                superliked
                  ? 'fas fa-star desktop-search-card-superlike-icon-checked'
                  : 'far fa-star desktop-search-card-superlike-icon'
              }
            ></i>
          </Button>
        )}
      </div>
    </div>
  );
}

export default withRouter(DesktopSearchCard);
