import React, { Component } from "react";
import HomeNav from "../../../components/navbar/HomeNav/HomeNav";
import HomeFooter from "../../../components/footers/homeFooter/HomeFooter";
import ContactForm from "../../../components/forms/contactForm/ContactForm";
import MetaTags from "react-meta-tags";

export class ContactPage extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="home-page-container">
        <MetaTags>
          <title>
            Contact Us | Sugarbae Support Available 24/7 | Sugarbae.com
          </title>{" "}
          <meta
            name="description"
            content="Contact Sugarbae for membership support, press, job openings, affiliate partnerships and more. Available via email, phone, and our contact form."
          />
        </MetaTags>
        <HomeNav />
        <ContactForm />
        <HomeFooter />
      </div>
    );
  }
}

export default ContactPage;
