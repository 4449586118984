import React, { useState, useContext } from "react";
import "./desktopsliders.scss";
import { Image, Form } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import { UserContext } from "../../../../../context/UserContext";

function Ethnicity(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [ethnicity, setEthnicity] = useState(filters.ethnicity);

  const changeHandler = (e) => {
    const ethnicityCopy = ethnicity;

    if (e.target.value === "white") {
      if (ethnicityCopy.white) {
        ethnicityCopy.white = false;
      } else {
        ethnicityCopy.white = true;
      }
    } else if (e.target.value === "black") {
      if (ethnicityCopy.black) {
        ethnicityCopy.black = false;
      } else {
        ethnicityCopy.black = true;
      }
    } else if (e.target.value === "asian") {
      if (ethnicityCopy.asian) {
        ethnicityCopy.asian = false;
      } else {
        ethnicityCopy.asian = true;
      }
    } else if (e.target.value === "hispanic") {
      if (ethnicityCopy.hispanic) {
        ethnicityCopy.hispanic = false;
      } else {
        ethnicityCopy.hispanic = true;
      }
    } else if (e.target.value === "indian") {
      if (ethnicityCopy.indian) {
        ethnicityCopy.indian = false;
      } else {
        ethnicityCopy.indian = true;
      }
    } else if (e.target.value === "middleeastern") {
      if (ethnicityCopy.middleeastern) {
        ethnicityCopy.middleeastern = false;
      } else {
        ethnicityCopy.middleeastern = true;
      }
    } else if (e.target.value === "other") {
      if (ethnicityCopy.other) {
        ethnicityCopy.other = false;
      } else {
        ethnicityCopy.other = true;
      }
    }

    setEthnicity((prevState) => ({ ...prevState, ...ethnicityCopy }));
  };

  const saveHandler = (e) => {
    props.updateHandler(ethnicity);
  };

  const resetHandler = (e) => {};

  return (
    <div className="desktop-v2-filters-content">
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.onClose}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-icon">
              <i className="far fa-smile filter-sort-icon" />
            </div>
            <div className="desktop-v2-filters-slider-title">Ethnicity</div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={resetHandler}
            >
              Reset
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="White"
                type="checkbox"
                value="white"
                checked={ethnicity.white}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Black"
                type="checkbox"
                value="black"
                checked={ethnicity.black}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Asian"
                type="checkbox"
                value="asian"
                checked={ethnicity.asian}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Hispanic"
                type="checkbox"
                value="hispanic"
                checked={ethnicity.hispanic}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Indian"
                type="checkbox"
                value="indian"
                checked={ethnicity.indian}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Middle Eastern"
                type="checkbox"
                value="middleeastern"
                checked={ethnicity.middleeastern}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Other"
                type="checkbox"
                value="other"
                checked={ethnicity.other}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="desktop-filter-slider-footer" onClick={saveHandler}>
          <div className="desktop-filter-slider-apply-button">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default Ethnicity;
