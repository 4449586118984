import React, { useContext, useState, useEffect } from "react";
import DesktopNavV2 from "../desktopNavV2/DesktopNavV2";
import DesktopSidebarV2 from "../desktopSidebarV2/DesktopSidebarV2";
import "../desktopvsstyles.scss";
import { UserContext } from "../../../../context/UserContext";
import SearchUserCardV2 from "../../../search/searchComponentsV2/searchUserCardV2/SearchUserCardV2";
import { useHistory, withRouter } from "react-router-dom";
import BottomScrollListener from "react-bottom-scroll-listener";
import ClipLoader from "react-spinners/ClipLoader";
import DesktopFiltersV2 from "../desktopFiltersV2/DesktopFiltersV2";
import PerfectScrollbar from "react-perfect-scrollbar";

function DesktopSearchV2(props) {
  const history = useHistory();
  const context = useContext(UserContext);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);

  useEffect(() => {
    setScrollLoading(false);
  }, [context.searches]);

  const toggleProfile = (e) => {
    const cardid = e._id;
    const url = `/app/member/${cardid}`;
    history.push(url);
  };

  const handleScroll = (e) => {
    if (!context.endSearches && !scrollLoading) {
      setScrollLoading(true);
      context.getMoreSearches();
    }
  };

  const toggleFilters = (e) => {
    if (filtersOpen) {
      setFiltersOpen(false);
    } else {
      setFiltersOpen(true);
    }
  };

  let noResults = false;
  if (context.searches.length === 0) {
    noResults = true;
  }

  const placeholders = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return (
    <div className="desktop-v2-container">
      {filtersOpen && <DesktopFiltersV2 toggleFilters={toggleFilters} />}
      <DesktopSidebarV2 />
      <div className="desktop-v2-content">
        <DesktopNavV2 toggleFilters={toggleFilters} />
        <div className="desktop-v2-content-container">
          {context.searchesLoading ? (
            <div className="desktop-v2-search-results">
              {placeholders.map((placeholder, index) => (
                <div className="desktop-search-placeholder-card" />
              ))}
            </div>
          ) : (
            <BottomScrollListener onBottom={handleScroll} offset={2000}>
              {(scrollRef) => (
                <div className="desktop-v2-search-results" ref={scrollRef}>
                  {noResults ? (
                    <div className="desktop-v2-search-results" />
                  ) : (
                    context.searches.map((user, index) => (
                      <SearchUserCardV2
                        user={user}
                        index={index}
                        onClick={toggleProfile}
                        finishedScrolling
                      />
                    ))
                  )}
                  {scrollLoading && (
                    <div
                      style={{
                        width: "100%",
                        padding: "1.5rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ClipLoader size="large" />
                    </div>
                  )}
                </div>
              )}
            </BottomScrollListener>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(DesktopSearchV2);
