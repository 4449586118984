import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";

function Smoking(props) {
  var filters = props.filters;
  const [smoking, setSmoking] = useState(filters.smoking);

  const changeHandler = (e) => {
    var smokingCopy = smoking;

    if (e.target.value === "nonsmoker") {
      if (smokingCopy.nonsmoker) {
        smokingCopy.nonsmoker = false;
      } else {
        smokingCopy.nonsmoker = true;
      }
    } else if (e.target.value === "lightsmoker") {
      if (smokingCopy.lightsmoker) {
        smokingCopy.lightsmoker = false;
      } else {
        smokingCopy.lightsmoker = true;
      }
    } else if (e.target.value === "heavysmoker") {
      if (smokingCopy.heavysmoker) {
        smokingCopy.heavysmoker = false;
      } else {
        smokingCopy.heavysmoker = true;
      }
    }

    setSmoking((prevState) => {
      return { ...prevState, ...smokingCopy };
    });
  };

  const saveHandler = (e) => {
    props.updateHandler(smoking);
  };

  return (
    <div className="filter-slider-component">
      <div className="filter-slider-overlay" onClick={props.onClose} />
      <div className="filter-slider-content">
        <div className="filter-slider-title-wrap">
          <i className="far fa-heart filter-sort-icon"></i>
          <h2 className="filter-slider-title">Smoking</h2>
        </div>
        <div className="filter-slider-wrapper">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Non Smoker"}
                type="checkbox"
                value="nonsmoker"
                checked={smoking.nonsmoker}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Light Smoker"}
                type="checkbox"
                value="lightsmoker"
                checked={smoking.lightsmoker}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Heavy Smoker"}
                type="checkbox"
                value="heavysmoker"
                checked={smoking.heavysmoker}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="filter-save-button-wrapper">
          <Button className="filter-save-button" onClick={saveHandler}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Smoking;
