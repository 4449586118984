import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./signupform.scss";
import { withRouter } from "react-router-dom";
import EmailValidator from "email-validator";
import PasswordValidator from "password-validator";
import ReactGA from "react-ga";
import {
  Container,
  Form,
  Button,
  Alert,
  Card,
  Col,
  ProgressBar,
} from "react-bootstrap";
import Recaptcha from "react-google-invisible-recaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { FacebookLogin, GoogleLogin } from "../auth";
import HomeFooter from "../footers/homeFooter/HomeFooter";
import HomeNav from "../navbar/HomeNav/HomeNav";
import loginCardBanner from "../../assets/Images/Login Page/Login Card Banner/login-card-banner.jpg";
import PlacesAutocomplete from "react-places-autocomplete";
import { api } from "../../api";

const passwordSchema = new PasswordValidator();

passwordSchema.is().min(6).is().max(100).has().not().spaces();

const Joi = require("@hapi/joi");
const usernameschema = Joi.object({
  username: Joi.string().alphanum().min(3).max(30).required(),
});

export class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      email: "",
      emailInvalid: false,
      password: "",
      passwordInvalid: false,
      username: "",
      usernameInvalid: false,
      stepsOpen: false,
      step: "gender",
      gender: "",
      genderInvalid: false,
      age: "",
      ageInvalid: false,
      address: "",
      changingAddress: "",
      addressInvalid: false,
      error: false,
      riskError: false,
      disabledBtn: false,
      progress: 20,
      passRecaptcha: true,
      passChallenge: false,
      recaptchaWarning: false,
    };
  }

  getProfileInfo = (personalInfo) => {
    this.setState(this.getInitialState);
    if (personalInfo.case === "google") {
      const { email, googleId } = personalInfo.info;
      if (email && googleId) {
        this.setState({
          email,
          password: googleId,
          stepsOpen: true,
        });
      }
    } else if (personalInfo.case === "facebook") {
      const { email, id } = personalInfo.info;
      if (email && id) {
        this.setState({
          email,
          password: id,
          stepsOpen: true,
        });
      }
    }
  };

  genderChangeHandler = (e) => {
    this.setState({ gender: e.target.value, genderInvalid: false });
    setTimeout(() => {
      this.setState({ step: "age", progress: 40 });
    }, 400);
  };

  ageChangeHandler = (e) => {
    var age = e.target.value;
    if (age < 99) {
      this.setState({ age: e.target.value, ageInvalid: false }, () => {
        if (this.state.age.length === 2) {
          if (age > 17 && age < 99) {
            setTimeout(() => {
              this.setState({ step: "location", progress: 60 });
            }, 400);
          } else {
            this.setState({ ageInvalid: true, age: null });
          }
        }
      });
    }
  };

  emailChangeHandler = (e) => {
    this.setState({ email: e.target.value.toLowerCase(), emailInvalid: false });
  };

  passwordChangeHandler = (e) => {
    this.setState({ password: e.target.value, passwordInvalid: false });
  };

  usernameChangeHandler = (e) => {
    this.setState({
      username: e.target.value.toLowerCase(),
      usernameInvalid: false,
    });
  };

  handleAddressChange = (address) => {
    this.setState({ changingAddress: address });
  };

  handleAddressSelect = (address) => {
    this.setState({
      address: address,
      changingAddress: address,
      addressInvalid: false,
    });
    setTimeout(() => {
      this.setState({ step: "username", progress: 80 });
    }, 400);
  };

  currentAccountLogout = () => {
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 != null) {
        auth2.signOut().then(auth2.disconnect());
      }
    }

    if (window.FB) {
      const token = window.FB.getAccessToken();
      if (token != null) {
        window.FB.logout();
      }
    }
  };

  checkNumberCount = () => {
    var { username } = this.state;
    var count = 0;

    for (var index in username) {
      if (!isNaN(username[index])) {
        count = count + 1;
      }
    }

    if (count > 3) {
      return false;
    } else {
      return true;
    }
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ disabledBtn: true }, async (e) => {
      var emailValid = false;
      var passwordValid = false;
      var usernameValid = false;
      var ageValid = false;
      var genderValid = false;
      var addressValid = false;

      var usernameCheck = usernameschema.validate({
        username: this.state.username,
      });

      var countNumberCheck = this.checkNumberCount();

      if (
        this.state.email.length < 1 ||
        !EmailValidator.validate(this.state.email)
      ) {
        this.setState({ emailInvalid: true });
      } else {
        emailValid = true;
      }

      if (
        this.state.password.length < 6 ||
        !passwordSchema.validate(this.state.password)
      ) {
        this.setState({ passwordInvalid: true });
      } else {
        passwordValid = true;
      }

      if (
        this.state.username.length < 3 ||
        usernameCheck.error ||
        !countNumberCheck
      ) {
        this.setState({ usernameInvalid: true });
      } else {
        usernameValid = true;
      }
      if (this.state.gender === "male" || this.state.gender === "female") {
        genderValid = true;
      } else {
        this.setState({ genderInvalid: true });
      }

      if (
        this.state.age > 99 ||
        this.state.age < 18 ||
        this.state.age === "" ||
        this.state.age === null ||
        this.state.age === undefined
      ) {
        this.setState({ ageInvalid: true });
      } else {
        ageValid = true;
      }

      if (this.state.address.length < 1) {
        this.setState({ addressInvalid: true });
      } else {
        addressValid = true;
      }

      if (
        emailValid === true &&
        genderValid === true &&
        passwordValid === true &&
        ageValid === true &&
        usernameValid === true &&
        addressValid === true
      ) {
        this.setState({ step: "username", progress: 100 });
        if (this.state.passRecaptcha) {
          this.recaptcha.execute();
        } else if (!this.state.passRecaptcha && this.state.passChallenge) {
          this.signupHandler();
        } else {
          this.setState({ recapchaWargning: true });
        }
      } else {
        this.recaptcha.reset();
        this.setState({ disabledBtn: false });
      }
    });
  };

  onResolved = () => {
    const datab = {
      response: this.recaptcha.getResponse(),
    };

    return api.submitRecaptcha(datab).then((res) => {
      const { score } = res.data;
      if (score > 0.5) {
        this.signupHandler();
      } else {
        this.setState({ passRecaptcha: false });
      }
    });
  };

  signupHandler = async () => {
    var ipAddress = null;
    await axios.get("https://json.geoiplookup.io", {
      withCredentials: false,
    })
      .then((res) => {
        ipAddress = res.data.ip;
      })
      .catch((err) => {
        console.log(err);
      });

    const data = {
      email: this.state.email,
      password: this.state.password,
      username: this.state.username,
      gender: this.state.gender,
      address: this.state.address,
      age: this.state.age,
      ipAddress,
    };

    return api
      .submitSignup(data)
      .then(async (res) => {
        if (res.status === 200) {
          window.dataLayer.push({ event: "Signup" });

          if (this.state.gender === "male") {
            ReactGA.event({
              category: "Login/Signup",
              action: "Successful Signup",
              label: "male signup",
            });
          }

          if (this.state.gender === "female") {
            ReactGA.event({
              category: "Login/Signup",
              action: "Successful Signup",
              label: "female signup",
            });
          }

          ReactGA.set({ userId: this.state.username });
          localStorage.setItem("auth", res.data);
          this.props.history.push("/app/welcome");
        } else {
          this.setState({ error: true, disabledBtn: false });
        }
      })
      .catch((err) => {
        this.currentAccountLogout();
        ReactGA.event({
          category: "Login/Signup",
          action: "Failed Signup",
        });
        if (err.response && err.response.data === "unacceptable") {
          this.setState({
            error: false,
            riskError: true,
            disabledBtn: false,
            stepsOpen: false,
          });
        } else {
          this.setState({
            error: true,
            riskError: false,
            disabledBtn: false,
            stepsOpen: false,
          });
        }
      });
  };

  handleBack = (e) => {
    var step = this.state.step;
    if (step === "gender") {
      this.setState({ stepsOpen: false });
    } else if (step === "age") {
      this.setState({ step: "gender" });
    } else if (step === "location") {
      this.setState({ step: "age" });
    } else if (step === "username") {
      this.setState({ step: "location" });
    }
  };

  onchange = (value) => {
    if (value) {
      this.setState({ passChallenge: true, recapchaWargning: false, disabledBtn: false });
    }
  };

  componentWillMount() {
    var email = this.props.match.params.email;
    if (email) {
      this.setState({ email: email });
    }
  }

  componentDidMount() {
    this.currentAccountLogout();
  }

  render() {
    const searchOptions = {
      types: ["(cities)"],
    };

    if (!this.state.stepsOpen) {
      return (
        <div className="signup-section-container">
          <Container className="signup-form-container">
            <Card className="signup-form-card">
              <Card.Img
                variant="top"
                className="signup-form-top-img"
                src={loginCardBanner}
              />
              <Card.Title className="sp-title">
                <span className="sg-login-link">Signup</span>
              </Card.Title>
              <p className="sg-subtitlea">
                100% <span className="sg-subtitle-free">Free</span> Signup.
              </p>
              <p className="sg-subtitle">Create your account to get started.</p>
              <Card.Body className="signup-form-card-body">
                <Form className="signup-form" onSubmit={this.submitHandler}>
                  {this.state.error ? (
                    <Alert variant="danger" className="sp-error-alert">
                      There was an error creating your account. Email or
                      username are already in use. Please try again.
                    </Alert>
                  ) : null}
                  {this.state.riskError ? (
                    <Alert variant="danger" className="sp-error-alert">
                      Signup failed. You are not able to create an account at
                      this time.
                    </Alert>
                  ) : null}
                  <Form.Group>
                    <Form.Label className="signup-form-label">Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="E.g johndoe@gmail.com"
                      onChange={this.emailChangeHandler}
                      value={this.state.email}
                      className="signup-form-input"
                      size="lg"
                      isInvalid={this.state.emailInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      Invalid Email
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="signup-form-label">
                      Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Minimum length: 6 characters"
                      onChange={this.passwordChangeHandler}
                      value={this.state.password}
                      className="signup-form-input"
                      size="lg"
                      isInvalid={this.state.passwordInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      Invalid Password. Minimum length: 6 characters. No spaces.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="signup-form-label">
                      Username
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Visible by all members"
                      onChange={this.usernameChangeHandler}
                      value={this.state.username}
                      className="signup-form-input"
                      size="lg"
                      isInvalid={this.state.usernameInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      Invalid Username. Must be at least 3 characters long. No
                      special characters allowed. Max length 30 characters. Must
                      contains less 3 numbers.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Row>
                    <Col>
                      <Form.Group>
                        <Form.Label className="signup-form-label">
                          Gender
                        </Form.Label>
                        <div className="sf-gender-input-wrapper">
                          <div className="sf-gender-btn">
                            <label className="" for="gender1">
                              <Form.Control
                                className="sf-gender-input"
                                type="radio"
                                name="gender"
                                id="gender1"
                                value="male"
                                onChange={this.genderChangeHandler}
                              />
                              <div className="sf-radioinputbtn">Male</div>
                            </label>
                          </div>
                          <div className="sf-gender-btn">
                            <label className="" for="gender2">
                              <Form.Control
                                className="sf-gender-input"
                                type="radio"
                                name="gender"
                                id="gender2"
                                value="female"
                                onChange={this.genderChangeHandler}
                              />

                              <div className="sf-radioinputbtn">Female</div>
                            </label>
                          </div>
                        </div>
                        {this.state.genderInvalid ? (
                          <div className="sf-gender-invalid">
                            Please select your gender.
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label className="signup-form-label">
                          Age
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="age"
                          size="lg"
                          placeholder="18+"
                          className="signup-form-input"
                          value={this.state.age}
                          onChange={this.ageChangeHandler}
                          min="18"
                          max="99"
                          isInvalid={this.state.ageInvalid}
                        />
                        <Form.Control.Feedback type="invalid">
                          Invalid age. Must be at least 18 years old. Max age is
                          99.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Form.Group>
                    <Form.Label className="signup-form-label">
                      Location
                    </Form.Label>

                    <PlacesAutocomplete
                      value={this.state.changingAddress}
                      onChange={this.handleAddressChange}
                      onSelect={this.handleAddressSelect}
                      searchOptions={searchOptions}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div className="sf-location-input-wrapper">
                          <Form.Control
                            size="lg"
                            isInvalid={this.state.addressInvalid}
                            {...getInputProps({
                              placeholder: "Enter Your City Here",
                              className: "signup-form-input",
                            })}
                          />
                          <Form.Control.Feedback type="invalid">
                            Invalid location. Please select your city from the
                            dropdown menu.
                          </Form.Control.Feedback>

                          <div className="sf-dropdown-card">
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Form.Group>
                  {this.state.recapchaWargning && (
                    <Alert variant="danger" className="lp-error-alert">
                      Please confirm you are not robot.
                    </Alert>
                  )}
                  {!this.state.passRecaptcha && (
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_SITE_KEY}
                      onChange={this.onchange}
                    />
                  )}
                  <Button
                    variant="primary"
                    type="submit"
                    className="signup-form-btn"
                    size="lg"
                    disabled={this.state.disabledBtn}
                  >
                    Create Account
                  </Button>
                  <div className="divider">
                    <span className="divider-title">OR</span>
                  </div>
                  <FacebookLogin onCheck={this.getProfileInfo} />
                  <GoogleLogin onCheck={this.getProfileInfo} />
                </Form>
                <div className="signup-below-form-container">
                  <p className="sp-login-text">
                    Already have an account?{" "}
                    <Link to="/login" className="sp-login-link">
                      Login Now
                    </Link>
                  </p>
                  <div className="sf-signup-agreement-wrapper">
                    <p className="sf-signup-agreement">
                      By creating an account on Sugarbae, you agree to our{" "}
                      <Link to="/terms">Terms </Link>
                      and <Link to="/privacy">Privacy Policy</Link>. Promiting
                      illegal commercial activity (such as escorting or
                      prostitution) is strictly prohibited. If you are an
                      ESCORT, DO NOT use this website.
                    </p>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Container>
          <Recaptcha
            ref={(ref) => (this.recaptcha = ref)}
            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
            onResolved={this.onResolved}
          />
        </div>
      );
    } else {
      return (
        <div className="landing-page-container-form">
          <div className="landing-page-header">
            <ProgressBar
              animated
              now={this.state.progress}
              className="landing-page-progress-bar"
            />

            {this.state.step === "gender" && (
              <div className="landing-page-step-form">
                <h2 className="landing-page-step-form-title">
                  Choose Your Gender:
                </h2>

                <div
                  className={
                    this.state.gender === "male"
                      ? "landing-select-active"
                      : "landing-select"
                  }
                >
                  <label className="" for="gender1">
                    <Form.Control
                      className="sf-gender-input"
                      type="radio"
                      name="gender"
                      id="gender1"
                      value="male"
                      onChange={this.genderChangeHandler}
                    />
                    <div>
                      {this.state.gender === "male" && (
                        <i className="fas fa-check-circle landing-select-check-icon"></i>
                      )}
                      I am Male
                    </div>
                  </label>
                </div>
                <div
                  className={
                    this.state.gender === "female"
                      ? "landing-select-active"
                      : "landing-select"
                  }
                >
                  <label className="" for="gender2">
                    <Form.Control
                      className="sf-gender-input"
                      type="radio"
                      name="gender"
                      id="gender2"
                      value="female"
                      onChange={this.genderChangeHandler}
                    />
                    <div>
                      {this.state.gender === "female" && (
                        <i className="fas fa-check-circle landing-select-check-icon"></i>
                      )}
                      I am Female
                    </div>
                  </label>
                </div>
              </div>
            )}
            {this.state.step === "age" && (
              <div className="landing-page-step-form">
                <h2 className="landing-page-step-form-title-age">
                  Verify Your Age
                </h2>
                <p className="age-input-subtitle">
                  Must Be 18+ to use Sugarbae
                </p>

                <Form.Control
                  type="number"
                  name="age"
                  maxLength="2"
                  size="lg"
                  placeholder="18+"
                  className="landing-form-age-input"
                  value={this.state.age}
                  onChange={this.ageChangeHandler}
                  min="18"
                  max="99"
                  isInvalid={this.state.ageInvalid}
                />
              </div>
            )}
            {this.state.step === "location" && (
              <div className="landing-page-step-form">
                <h2 className="landing-page-step-form-title-age">
                  Enter Your Location
                </h2>
                <p className="age-input-subtitle">
                  Type your city and select from dropdown.
                </p>

                <PlacesAutocomplete
                  value={this.state.changingAddress}
                  onChange={this.handleAddressChange}
                  onSelect={this.handleAddressSelect}
                  searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="sf-location-input-wrapper">
                      <Form.Control
                        size="lg"
                        isInvalid={this.state.addressInvalid}
                        {...getInputProps({
                          placeholder: "Enter Your City Here",
                          className: "landing-form-location-input",
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Invalid location. Please select your city from the
                        dropdown menu.
                      </Form.Control.Feedback>

                      <div className="sf-dropdown-card">
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            )}
            {this.state.step === "username" && (
              <div className="landing-page-step-form">
                <p className="landing-page-last-step-text">Last Step!</p>
                <h2 className="landing-page-step-form-title">
                  Create Your Username
                </h2>
                <Form.Control
                  type="text"
                  placeholder="Visible by all members"
                  onChange={this.usernameChangeHandler}
                  value={this.state.username}
                  className="landing-form-location-input"
                  size="lg"
                  isInvalid={this.state.usernameInvalid}
                />
                <Form.Control.Feedback type="invalid">
                  Invalid Username. Must be at least 3 characters long. No
                  special characters allowed. Max length 30 characters.
                </Form.Control.Feedback>
                {this.state.recapchaWargning && (
                  <Alert variant="danger" className="lp-error-alert">
                    Please confirm you are not robot.
                  </Alert>
                )}
                {!this.state.passRecaptcha && (
                  <div className="landing-page-recaptcha">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_SITE_KEY}
                      onChange={this.onchange}
                    />
                  </div>
                )}
                <Button
                  variant="primary"
                  onClick={this.submitHandler}
                  className="signup-form-btn"
                  size="lg"
                  disabled={this.state.disabledBtn}
                >
                  Create Your Account
                  <i className="fas fa-chevron-circle-right landing-page-signup-button-icon"></i>
                </Button>
              </div>
            )}
          </div>
          <div className="landing-page-back-wrapper">
            <div className="landing-page-back-button" onClick={this.handleBack}>
              <i className="fas fa-chevron-circle-left landing-page-back-icon"></i>
              <span className="landing-page-back-text">Back</span>
            </div>
            <Recaptcha
              ref={(ref) => (this.recaptcha = ref)}
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
              onResolved={this.onResolved}
            />
          </div>
        </div>
      );
    }
  }
}

export default withRouter(SignupForm);
