import React, { Component } from "react";
import {
  Image,
  Form,
  Button,
  Alert,
  ProgressBar,
  Modal,
  Card,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import PlacesAutocomplete from "react-places-autocomplete";
import EmailValidator from "email-validator";
import Axios from "axios";
import PasswordValidator from "password-validator";
import ReactGA from "react-ga";
import { geocodeByAddress } from "react-places-autocomplete";
import Recaptcha from "react-google-invisible-recaptcha";
import ReCAPTCHA from "react-google-recaptcha";

import bannerImage from "../../assets/Images/Login Page/Login Card Banner/login-card-banner.jpg";
import { withRouter } from "react-router-dom";
import landingGIF from "../../assets/Videos/ezgif-3-fb9d05ec3be8_m5m9ic.gif";
import api from "../../api/api";
import desktopMP4 from "../../assets/Videos/homeback_video_background_desktop_final.mp4";
import desktopWEBM from "../../assets/Videos/homeback_video_background_desktop_final_x6sbv1.webm";
import nytLogo from "../../assets/Images/Landing/OtherLogos/nyt-logo_srkaqz.png";
import cnnLogo from "../../assets/Images/Landing/OtherLogos/cnn-logo_kkvhdf.png";
import peopleLogo from "../../assets/Images/Landing/OtherLogos/people-logo_eoqagv.png";
import huffLogo from "../../assets/Images/Landing/OtherLogos/huff-logo_gj2qgx.png";
import logo from "../../assets/logos/logo-horizontal-dark-background.png";
import LandingGrid from "./landingComponents/LandingGrid";

import "./landingpage.scss";

const passwordSchema = new PasswordValidator();

passwordSchema.is().min(6).is().max(100).has().not().spaces();

const Joi = require("@hapi/joi");
const usernameSchema = Joi.object({
  username: Joi.string().alphanum().min(3).max(30).required(),
});

export class SugarbaeLanding extends Component {
  state = {
    email: "",
    emailInvalid: false,
    stepsOpen: false,
    step: "gender",
    gender: "",
    address: "",
    changingAddress: "",
    addressInvalid: false,
    age: null,
    ageInvalid: false,
    username: "",
    usernameInvalid: false,
    usernameExistsError: false,
    password: "",
    confirmPassword: "",
    passwordInvalid: false,
    passwordNotMatchError: false,
    progress: 20,
    emailModalOpen: false,
    signupButtonDisabled: false,
    passRecaptcha: true,
    passChallenge: false,
    recaptchaWarning: false,
  };

  emailSubmitHandler = async (e) => {
    e.preventDefault();
    var email = this.state.email;
    var valid = EmailValidator.validate(email);

    if (valid) {
      const baseurl = process.env.REACT_APP_API_URL;
      const url = baseurl + "/api/auth/checkEmail";
      const data = {
        email: email,
      };

      return api
        .checkEmail(data)
        .then((res) => {
          if (res.status === 200) {
            var valid = res.data.valid;
            if (valid) {
              this.setState({ stepsOpen: true });
            } else {
              this.setState({ emailExistsError: true, emailInvalid: true });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({ emailInvalid: true });
    }
  };

  emailChangeHandler = (e) => {
    this.setState({ email: e.target.value.toLowerCase(), emailInvalid: false });
  };

  genderSelectHandler = (props) => (e) => {
    this.setState({ gender: props });
    setTimeout(() => {
      this.setState({ step: "age", progress: 40 });
    }, 400);
  };

  ageChangeHandler = (e) => {
    var age = e.target.value;
    if (age < 99) {
      this.setState({ age: e.target.value, ageInvalid: false }, () => {
        if (this.state.age.length === 2) {
          if (age > 17 && age < 99) {
            setTimeout(() => {
              this.setState({ step: "location", progress: 60 });
            }, 400);
          } else {
            this.setState({ ageInvalid: true, age: null });
          }
        }
      });
    }
  };

  handleAddressChange = (e) => {
    this.setState({ changingAddress: e, addressInvalid: false });
  };
  handleAddressSelect = async (e) => {
    var address = e;
    var valid = false;

    if (address.length > 0) {
      await geocodeByAddress(address)
        .then((res) => {
          if (res === "ZERO_RESULTS") {
            valid = false;
          } else {
            address = res[0].formatted_address;
            valid = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });

      if (valid) {
        this.setState({
          address: address,
          changingAddress: address,
          addressInvalid: false,
        });
        setTimeout(() => {
          this.setState({ step: "usernamepassword", progress: 80 });
        }, 400);
      } else {
        this.setState({ addressInvalid: true });
      }
    } else {
      this.setState({ addressInvalid: true });
    }
  };

  checkNumberCount = () => {
    var { username } = this.state;
    var count = 0;

    for (var index in username) {
      if (!isNaN(username[index])) {
        count = count + 1;
      }
    }

    if (count > 3) {
      return false;
    } else {
      return true;
    }
  };

  usernameChangeHandler = (e) => {
    this.setState({
      username: e.target.value.toLowerCase(),
      usernameInvalid: false,
      usernameExistsError: false,
    });
  };

  passwordChangeHandler = (e) => {
    this.setState({
      password: e.target.value,
      passwordInvalid: false,
      passwordNotMatchError: false,
    });
  };

  confirmPasswordChangeHandler = (e) => {
    this.setState({
      confirmPassword: e.target.value,
      passwordInvalid: false,
      passwordNotMatchError: false,
    });
  };

  signupSubmit = (e) => {
    e.preventDefault();
    this.setState({ signupButtonDisabled: true }, async (e) => {
      var email = this.state.email;
      var age = this.state.age;
      var gender = this.state.gender;
      var address = this.state.address;
      var username = this.state.username.toLowerCase().trim();
      var password = this.state.password;
      var confirmPassword = this.state.confirmPassword;

      var usernameValid = usernameSchema.validate({ username: username });

      var usernameExists = true;

      var passwordValid = passwordSchema.validate(password);
      var passwordMatch = false;
      if (password === confirmPassword) {
        passwordMatch = true;
      } else {
        this.setState({ passwordNotMatchError: true });
      }

      if (!passwordValid) {
        this.setState({ passwordInvalid: true });
      }

      const countCheck = this.checkNumberCount();
      if (!countCheck) {
        this.setState({ usernameInvalid: true });
      }

      if (!usernameValid.error && countCheck) {
        const data = {
          username: username,
        };

        await api
          .checkUsername(data)
          .then((res) => {
            if (res.status === 200) {
              var valid = res.data.valid;
              if (valid) {
                usernameExists = false;
              } else {
                this.setState({
                  usernameExistsError: true,
                  signupButtonDisabled: false,
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              usernameInvalid: true,
              signupButtonDisabled: false,
            });
          });
      } else {
        this.setState({ usernameInvalid: true, signupButtonDisabled: false });
      }

      if (
        !usernameValid.error &&
        !usernameExists &&
        passwordValid &&
        passwordMatch
      ) {
        this.setState({ progress: 100 });
        if (this.state.passRecaptcha) {
          this.recaptcha.execute();
        } else if (!this.state.passRecaptcha && this.state.passChallenge) {
          this.signupHandler();
        } else {
          this.setState({ recapchaWargning: true });
        }
      } else {
        this.recaptcha.reset();
        this.setState({ signupButtonDisabled: false });
      }
    });
  };

  onResolved = () => {
    const datab = {
      response: this.recaptcha.getResponse(),
    };

    return api.submitRecaptcha(datab).then((res) => {
      const { score } = res.data;
      if (score > 0.5) {
        this.signupHandler();
      } else {
        this.setState({ passRecaptcha: false });
      }
    });
  };

  onchange = (value) => {
    if (value) {
      this.setState({
        passChallenge: true,
        recapchaWargning: false,
        signupButtonDisabled: false,
      });
    }
  };

  signupHandler = async () => {
    const { email, password, username, gender, address, age } = this.state;
    const data = {
      email,
      password,
      username,
      gender,
      address,
      age,
    };

    return await api
      .submitSignup(data)
      .then((res) => {
        if (res.status === 200) {
          window.dataLayer.push({ event: "Signup" });

          if (gender === "male") {
            ReactGA.event({
              category: "Login/Signup",
              action: "Successful Signup",
              label: "male signup",
            });
          }

          if (gender === "female") {
            ReactGA.event({
              category: "Login/Signup",
              action: "Successful Signup",
              label: "female signup",
            });
          }

          ReactGA.set({ userId: username });
          localStorage.setItem("auth", res.data);
          this.props.history.push({
            pathname: "/app",
            state: { welcomeModalOpen: true },
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ signupButtonDisabled: false });
      });
  };

  handleBack = (e) => {
    var step = this.state.step;
    if (step === "gender") {
      this.setState({ stepsOpen: false });
    } else if (step === "age") {
      this.setState({ step: "gender" });
    } else if (step === "location") {
      this.setState({ step: "age" });
    } else if (step === "usernamepassword") {
      this.setState({ step: "location" });
    }
  };

  cardClickHandler = (e) => {
    this.setState({ emailModalOpen: true });
  };

  render() {
    const { pageName } = this.props;
    let pageInfo = {};
    switch (pageName) {
      case "women":
        pageInfo = {
          pageTitle: "Find Successful Men Near You.",
          pageSubtitle: "100% Free to Join",
          pageGIFTitle: "Sugar Dating",
          pageGIFSubtitle: "Where successful Men meet attractive Women.",
          pageFirstFeatTitle: "Chat with Successful Members",
          pageFirstFeatDescription:
            "Join for free and begin matching and chatting with successful men in your area.",
          pageThirdFeatTitle: "Over 1 Million Active Users",
          pageThirdFeatDescription:
            "Millions of Women have found successful arrangements using Sugarbae. Your perfect match is online now.",
          pageDescription: `"Millions of Woman are joining Sugarbae to meet wealthy, attractive men. It's safe, secure and free to join."`,
        };
        break;
      case "sugar-daddy":
        pageInfo = {
          pageTitle: "The #1 Sugar Daddy Dating App.",
          pageSubtitle: "Get Spoiled By Wealthy Men.",
          pageGIFTitle: "Sugar Dating",
          pageGIFSubtitle: "Where successful Men meet attractive Women.",
          pageFirstFeatTitle: "Find a Sugar Daddy In Minutes",
          pageFirstFeatDescription:
            "Join for free and begin matching and chatting with Sugar Daddies in your area.",
          pageThirdFeatTitle: "Over 1 Million Active Users",
          pageThirdFeatDescription:
            "Millions of Women have found successful arrangements using Sugarbae. Your perfect match is online now.",
          pageDescription: `"Millions of Woman are joining Sugarbae to meet wealthy, attractive men. It's safe, secure and free to join."`,
        };
        break;
      case "sugar-baby":
        pageInfo = {
          pageTitle: "Find the Perfect Sugar Baby.",
          pageSubtitle: "Meet, Match, & Chat with Young Attractive Women.",
          pageGIFTitle: "Sugar Dating",
          pageGIFSubtitle: "Try a new approach to dating.",
          pageFirstFeatTitle: "Get More Dates & Matches",
          pageFirstFeatDescription:
            "Join for free and begin matching and chatting with attractive Woman in your area today.",
          pageThirdFeatTitle: "The Online Dating Shortcut",
          pageThirdFeatDescription:
            "Find your perfect match in just minutes. Over 1 million active members online now.",
          pageDescription: `"Millions of Men are joining Sugarbae to meet attractive women in their area. It's safe, secure and free to join."`,
        };
        break;
      case "dating":
        pageInfo = {
          pageTitle: "The Fastest Way to Meet Attractive Singles.",
          pageSubtitle: "Over 5 Million Women Online.",
          pageGIFTitle: "Dating, with Benefits",
          pageGIFSubtitle: "Try a new approach to dating.",
          pageFirstFeatTitle: "Match, Chat, & Meet",
          pageFirstFeatDescription:
            "Join for free and begin matching and chatting with attractive singles near you.",
          pageThirdFeatTitle: "Over 1 Million Active Users",
          pageThirdFeatDescription:
            "Find your perfect match in just minutes. Over 1 million active members online now.",
          pageDescription: `"Millions of people are joining Sugarbae to chat & meet with attractive singles in their area. It's safe, secure and free to join."`,
        };
        break;
      default:
        pageInfo = {
          pageTitle: "Find The Perfect Arrangement.",
          pageSubtitle: "Join the #1 Sugar Dating App",
          pageGIFTitle: "Dating, with Benefits",
          pageGIFSubtitle: "Try a new approach to dating.",
          pageFirstFeatTitle: "Find Your Perfect Match In Minutes",
          pageFirstFeatDescription:
            "Join for free and begin matching and chatting with attractive & successful members in your area.",
          pageThirdFeatTitle: "Over 1 Million Active Users",
          pageThirdFeatDescription:
            "Find your perfect match in just minutes. Over 1 million active members online now.",
          pageDescription: `"Millions of people are joining Sugarbae for a mutually beneficial dating experience. It's safe, secure and free to join."`,
        };
    }
    const searchOptions = {
      types: ["(cities)"],
    };

    if (this.state.stepsOpen) {
      return (
        <div className="landing-page-container-form">
          <div className="landing-page-header">
            <ProgressBar
              animated
              now={this.state.progress}
              className="landing-page-progress-bar"
            />

            {this.state.step === "gender" && (
              <div className="landing-page-step-form">
                <h2 className="landing-page-step-form-title">
                  Choose Your Gender:
                </h2>
                <div
                  className={
                    this.state.gender === "male"
                      ? "landing-select-active"
                      : "landing-select"
                  }
                  onClick={this.genderSelectHandler("male")}
                >
                  {this.state.gender === "male" && (
                    <i className="fas fa-check-circle landing-select-check-icon"></i>
                  )}
                  I am Male
                </div>
                <div
                  className={
                    this.state.gender === "female"
                      ? "landing-select-active"
                      : "landing-select"
                  }
                  onClick={this.genderSelectHandler("female")}
                >
                  {this.state.gender === "female" && (
                    <i className="fas fa-check-circle landing-select-check-icon"></i>
                  )}
                  I am Female
                </div>
              </div>
            )}
            {this.state.step === "age" && (
              <div className="landing-page-step-form">
                <h2 className="landing-page-step-form-title-age">
                  Verify Your Age
                </h2>
                <p className="age-input-subtitle">
                  Must Be 18+ to use Sugarbae
                </p>

                <Form.Control
                  type="number"
                  name="age"
                  maxLength="2"
                  size="lg"
                  placeholder="18+"
                  className="landing-form-age-input"
                  value={this.state.age}
                  onChange={this.ageChangeHandler}
                  min="18"
                  max="99"
                  isInvalid={this.state.ageInvalid}
                />
              </div>
            )}
            {this.state.step === "location" && (
              <div className="landing-page-step-form">
                <h2 className="landing-page-step-form-title-age">
                  Enter Your Location
                </h2>
                <p className="age-input-subtitle">
                  Type your city and select from dropdown.
                </p>

                <PlacesAutocomplete
                  value={this.state.changingAddress}
                  onChange={this.handleAddressChange}
                  onSelect={this.handleAddressSelect}
                  searchOptions={searchOptions}
                  highlightFirstSuggestion={true}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className="sf-location-input-wrapper">
                      <Form.Control
                        size="lg"
                        isInvalid={this.state.addressInvalid}
                        {...getInputProps({
                          placeholder: "Enter Your City Here",
                          className: "landing-form-location-input",
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Invalid location. Please select your city from the
                        dropdown menu.
                      </Form.Control.Feedback>

                      <div className="sf-dropdown-card">
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            )}
            {this.state.step === "usernamepassword" && (
              <div className="landing-page-step-form">
                <p className="landing-page-last-step-text">Last Step!</p>
                <h2 className="landing-page-step-form-title">
                  Create Your Username & Password
                </h2>
                <Form onSubmit={this.signupSubmit}>
                  <Form.Group>
                    <Form.Label className="landing-page-form-label">
                      Username
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Visible by all members"
                      onChange={this.usernameChangeHandler}
                      value={this.state.username}
                      className="signup-form-input"
                      size="lg"
                      isInvalid={this.state.usernameInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      Invalid Username. Must be between 3-30 Characters. No
                      spaces or special characters allowed. Must contains less 3
                      numbers.
                    </Form.Control.Feedback>

                    {this.state.usernameExistsError && (
                      <Alert variant="danger">
                        Username already exists. Please choose another username.
                      </Alert>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="landing-page-form-label">
                      Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Minimum length: 6 characters"
                      onChange={this.passwordChangeHandler}
                      value={this.state.password}
                      className="signup-form-input"
                      size="lg"
                      isInvalid={this.state.passwordInvalid}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="landing-page-form-label">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm Your Password"
                      onChange={this.confirmPasswordChangeHandler}
                      value={this.state.confirmPassword}
                      className="signup-form-input"
                      size="lg"
                      isInvalid={this.state.passwordInvalid}
                    />
                    <Form.Control.Feedback type="invalid">
                      Invalid Password. Minimum length: 6 characters. No spaces.
                    </Form.Control.Feedback>
                    {this.state.passwordNotMatchError && (
                      <Alert variant="danger">
                        Passwords Do Not Match. Please Confirm Your Password.
                      </Alert>
                    )}
                  </Form.Group>
                  {this.state.recapchaWargning && (
                    <Alert variant="danger" className="lp-error-alert">
                      Please confirm you are not robot.
                    </Alert>
                  )}
                  {!this.state.passRecaptcha && (
                    <ReCAPTCHA
                      sitekey={
                        process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_SITE_KEY
                      }
                      onChange={this.onchange}
                    />
                  )}
                  <Button
                    type="submit"
                    className="landing-page-signup-submit-button"
                    size="lg"
                    disabled={this.state.signupButtonDisabled}
                  >
                    Create Your Account
                    <i className="fas fa-chevron-circle-right landing-page-signup-button-icon"></i>
                  </Button>
                </Form>
              </div>
            )}
          </div>
          <div className="landing-page-back-wrapper">
            <div className="landing-page-back-button" onClick={this.handleBack}>
              <i className="fas fa-chevron-circle-left landing-page-back-icon"></i>
              <span className="landing-page-back-text">Back</span>
            </div>
            <Recaptcha
              ref={(ref) => (this.recaptcha = ref)}
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
              onResolved={this.onResolved}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="landing-page-container">
          <Modal
            show={this.state.emailModalOpen}
            onHide={() => {
              this.setState({ emailModalOpen: false });
            }}
            centered
            className="landing-page-modal"
          >
            <Modal.Body className="landing-page-email-modal-body">
              <Card className="landing-page-step-card">
                <Card.Img
                  variant="top"
                  className="landing-page-top-img"
                  src={bannerImage}
                />
              </Card>
              <h1 className="landing-page-modal-title">100% Free Signup</h1>

              <p className="landing-page-modal-subtitle">
                Enter Your Email To Get Started:
              </p>
              <Form
                className="landing-page-form-modal"
                onSubmit={this.emailSubmitHandler}
              >
                {this.state.emailExistsError && (
                  <Alert variant="danger" className="landing-page-alert">
                    Invalid Email. Enter a different email and try again.
                  </Alert>
                )}
                <Form.Group className="landing-page-form-group-modal">
                  <Form.Control
                    type="email"
                    placeholder="Enter your email address"
                    className="landing-page-input"
                    onChange={this.emailChangeHandler}
                    size="lg"
                    isInvalid={this.state.emailInvalid}
                    value={this.state.email}
                  />
                  <Button
                    type="submit"
                    className="landing-page-submit-button-modal"
                    size="lg"
                  >
                    Get Started Free
                  </Button>
                </Form.Group>
                <p className="landing-page-form-subtitle-popup">
                  Safe, Secure & Private. Your email wont be shared with anyone.
                </p>
              </Form>
            </Modal.Body>
          </Modal>
          <div className="landing-page-header">
            <Image src={logo} className="landing-page-logo" />
            <h1 className="landing-page-title">{pageInfo.pageTitle}</h1>
            <p className="landing-page-subtitle">{pageInfo.pageSubtitle}</p>
            <Form
              className="landing-page-form"
              onSubmit={this.emailSubmitHandler}
            >
              {this.state.emailExistsError && (
                <Alert variant="danger" className="landing-page-alert">
                  Invalid Email. Enter a different email and try again.
                </Alert>
              )}
              <Form.Group className="landing-page-form-group">
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  className="landing-page-input"
                  onChange={this.emailChangeHandler}
                  size="lg"
                  isInvalid={this.state.emailInvalid}
                  value={this.state.email}
                />
                <Button
                  type="submit"
                  className="landing-page-submit-button"
                  size="lg"
                >
                  Join Free
                </Button>
              </Form.Group>
              <p className="landing-page-form-subtitle">
                100% Free Signup. Safe, Secure & Private. Over 1 Million Members
                Online Now. Find Your Perfect Arrangement Today.
              </p>
            </Form>
          </div>
          <div className="landing-page-content">
            {pageName === "dating" || pageName === "sugar-baby" ? (
              <LandingGrid cardClickHandler={this.cardClickHandler} />
            ) : (
              <div className="landing-page-gif-wrapper">
                <Image src={landingGIF} className="landing-page-gif" />
                <div className="landing-page-gif-content">
                  <div className="landing-page-gif-content-innerwrap">
                    <h4 className="landing-page-gif-title">
                      {pageInfo.pageGIFTitle}
                    </h4>
                    <p className="landing-page-gif-subtitle">
                      {pageInfo.pageGIFSubtitle}
                    </p>
                  </div>
                  <i className="far fa-angle-down landing-page-down-icon"></i>
                </div>

                <div className="banner-video-container-desktop">
                  <video
                    id="banner-video-desktop"
                    loop
                    autoPlay
                    muted
                    playsInline
                  >
                    <source
                      src={desktopMP4}
                      type="video/mp4"
                      id="banner-video-desktop-src"
                    />
                    <source
                      src={desktopWEBM}
                      type="video/webm"
                      id="banner-video-desktop-src"
                    />
                  </video>
                </div>
              </div>
            )}

            <div className="landing-page-features">
              <div className="landing-page-feature-item">
                <i className="far fa-gifts landing-page-feature-icon"></i>

                <h3 className="landing-page-feature-title">
                  {pageInfo.pageFirstFeatTitle}
                </h3>
                <p className="landing-page-feature-description">
                  {pageInfo.pageFirstFeatDescription}
                </p>
              </div>
              <div className="landing-page-feature-item">
                <i className="far fa-badge-check landing-page-feature-icon"></i>

                <h3 className="landing-page-feature-title">
                  Verified & Real Profiles
                </h3>
                <p className="landing-page-feature-description">
                  We take safety & security very seriously. Over 50% of members
                  are verified so you know they are real.
                </p>
              </div>
              <div className="landing-page-feature-item">
                <i className="far fa-users landing-page-feature-icon"></i>
                <h3 className="landing-page-feature-title">
                  {pageInfo.pageThirdFeatTitle}
                </h3>
                <p className="landing-page-feature-description">
                  {pageInfo.pageThirdFeatDescription}
                </p>
              </div>
            </div>

            <div className="landing-page-cta">
              <p className="landing-page-cta-quote">
                {pageInfo.pageDescription}
              </p>
              {/* <cite className="landing-page-quote-cite">
                New York Times, 2020
              </cite> */}
              <Button
                className="landing-page-cta-button"
                onClick={() => {
                  this.setState({ emailModalOpen: true });
                }}
              >
                Get Started Free
              </Button>
            </div>

            <div className="landing-page-footer">
              <Link to="/terms" className="landing-page-footer-link">
                Terms of Service
              </Link>
              <Link to="/terms" className="landing-page-footer-link">
                Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(SugarbaeLanding);
