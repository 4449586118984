import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Image, Button } from "react-bootstrap";
import whiteLogo from "../../../assets/logos/logo_white.png";
import "./joinnowcta.scss";

export class JoinNowCta extends Component {
  render() {
    return (
      <div className="join-now-cta-container">
        <div className="sd-cta-img-wrap">
          <Image className="sd-cta-img" src={whiteLogo} />
        </div>
        <h2 className="sd-cta-title">
          Join Now and Find the Arrangement of Your Dreams
        </h2>
        <p className="sd-cta-content">
          Whether you’re a sugar daddy looking to find and spoil a gorgeous
          lady, or a sugar baby looking to get connected to a wealthy gentleman,
          Sugarbae is the perfect place for you! Don’t take our word for it. Try
          it today and see for yourself.
        </p>
        <div className="sd-cta-btn-wrap">
          <Link to="/signup" className="sd-cta-link">
            <Button className="sd-cta-btn" variant="outline-light">
              Join Free
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default JoinNowCta;
