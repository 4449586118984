import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";

function Kids(props) {
  var filters = props.filters;
  const [children, setChildren] = useState(filters.children);

  const changeHandler = (e) => {
    var childrenCopy = children;

    if (e.target.value === "nokids") {
      if (childrenCopy.nokids) {
        childrenCopy.nokids = false;
      } else {
        childrenCopy.nokids = true;
      }
    } else if (e.target.value === "onekid") {
      if (childrenCopy.onekid) {
        childrenCopy.onekid = false;
      } else {
        childrenCopy.onekid = true;
      }
    } else if (e.target.value === "twokids") {
      if (childrenCopy.twokids) {
        childrenCopy.twokids = false;
      } else {
        childrenCopy.twokids = true;
      }
    } else if (e.target.value === "threekids") {
      if (childrenCopy.threekids) {
        childrenCopy.threekids = false;
      } else {
        childrenCopy.threekids = true;
      }
    } else if (e.target.value === "fourpluskids") {
      if (childrenCopy.fourpluskids) {
        childrenCopy.fourpluskids = false;
      } else {
        childrenCopy.fourpluskids = true;
      }
    }

    setChildren((prevState) => {
      return { ...prevState, ...childrenCopy };
    });
  };

  const saveHandler = (e) => {
    props.updateHandler(children);
  };

  return (
    <div className="filter-slider-component">
      <div className="filter-slider-overlay" onClick={props.onClose} />
      <div className="filter-slider-content">
        <div className="filter-slider-title-wrap">
          <i className="far fa-heart filter-sort-icon"></i>
          <h2 className="filter-slider-title">Kids</h2>
        </div>
        <div className="filter-slider-wrapper">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"No Kids"}
                type="checkbox"
                value="nokids"
                checked={children.nokids}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"One Kid"}
                type="checkbox"
                value="onekid"
                checked={children.onekid}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Two Kids"}
                type="checkbox"
                value="twokids"
                checked={children.twokids}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Three Kids"}
                type="checkbox"
                value="threekids"
                checked={children.threekids}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Four Plus Kids"}
                type="checkbox"
                value="fourpluskids"
                checked={children.fourpluskids}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="filter-save-button-wrapper">
          <Button className="filter-save-button" onClick={saveHandler}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Kids;
