import React, { useState, useContext } from 'react';
import { Image as BootstrapImage } from 'react-bootstrap';
import { UserContext } from '../../../context/UserContext';
import approvedRequestImage from '../../../assets/Images/requests/approvedRequestImage.png';
import requestsImage from '../../../assets/Images/requests/requestsImage.png';
import './photorequestmodal.scss';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../utils/getCloudinaryInfo';
import { withRouter, useHistory } from 'react-router-dom';
import { api } from '../../../api';
import { Image, Transformation } from 'cloudinary-react';
import Media from '../../../components/media/Media';

function PhotoRequestModal(props) {
  const context = useContext(UserContext);
  const [approvedRequests, setApprovedRequests] = useState(
    context.approvedRequests
  );
  const [requests, setRequests] = useState(context.requests);
  const [refresh, setRefresh] = useState(false);

  const approveHandler = (requestid, index) => {
    const data = {
      requestid,
      option: 'approve',
    };

    return api
      .handleRequest(data)
      .then(() => {
        const temp = context.approvedRequests;
        temp.unshift(requests[index]);
        requests.splice(index, 1);
        context.updateRequests(requests);
        context.updateApprovedRequests(temp);
        setRequests(requests);
        setRefresh(true);
      })
      .catch(() => {});
  };

  const declineHandler = (requestid, index) => {
    const data = {
      requestid,
      option: 'decline',
    };

    return api
      .handleRequest(data)
      .then(() => {
        requests.splice(index, 1);
        context.updateRequests(requests);
        setRequests(requests);
        setRefresh(true);
      })
      .catch(() => {});
  };

  const declineHandlerApproved = (requestid, index) => {
    const data = {
      requestid,
      option: 'decline',
    };

    return api
      .handleRequest(data)
      .then(() => {
        approvedRequests.splice(index, 1);
        context.updateApprovedRequests(approvedRequests);
        setApprovedRequests(approvedRequests);
        setRefresh(true);
      })
      .catch(() => {});
  };

  return (
    <div className="desktop-v2-filters">
      <div className="desktop-v2-filters-container">
        <div className="desktop-v2-filters-popup">
          <div className="desktop-v2-filters-header">
            <div className="desktop-v2-filter-title-wrapper">
              <span className="desktop-v2-filters-popup-title">
                {props.option === 'people' && 'Access'}
                {props.option === 'requests' && 'Requests'}
              </span>
            </div>
            <div
              className="desktop-v2-filter-close-button"
              onClick={props.closeHandler}
            >
              <i className="far fa-times" />
            </div>
          </div>
          {props.option === 'people' && (
            <div className="desktop-v2-filters-content">
              {!approvedRequests || approvedRequests.length < 1 ? (
                <div className="desktop-v2-request-none-container">
                  <BootstrapImage
                    src={approvedRequestImage}
                    className="desktop-v2-request-none-image"
                  />
                  <h1 className="desktop-v2-request-none-title">
                    Private Photo Access
                  </h1>
                  <p className="desktop-v2-request-none-subtitle">
                    Manage who has access to your private photos. No users
                    currently have access.
                  </p>
                </div>
              ) : (
                <div className="desktop-v2-request-content-container">
                  {approvedRequests &&
                    approvedRequests.map((item, index) => {
                      const { imgurl, username, age, address } = item.user;
                      const mainImage = imgurl.length > 0 ? imgurl[0] : null;
                      const requestId = item._id;
                      return (
                        <div className="edit-profile-v2-private-request">
                          <div className="edit-profile-v2-request-image-wrapper">
                            <Media image={mainImage} />

                            {/* <Image
                              publicId={
                                mainImage
                                  ? getImgName(mainImage)
                                  : "placeholder-user-image-thumbnail_vankru.png"
                              }
                              version={
                                mainImage
                                  ? getImgVersion(mainImage)
                                  : "1590045309"
                              }
                              resource_type={
                                mainImage
                                  ? checkGIF(mainImage)
                                    ? "video"
                                    : "image"
                                  : null
                              }
                              effect="loop"
                              cloud_name="sugarbae"
                              className="edit-profile-v2-request-image"
                            >
                              <Transformation
                                height="200"
                                video_sampling="20"
                                delay="250"
                                duration="10"
                                effect="loop"
                                quality="auto"
                                flags="lossy"
                              />
                            </Image> */}
                          </div>
                          <div className="edit-profile-v2-request-info">
                            <h1 className="edit-profile-v2-request-username">{`${username}, ${age}`}</h1>
                            <label className="edit-profile-v2-request-user-address">
                              {address}
                            </label>
                          </div>
                          <div className="edit-profile-v2-request-action">
                            <i
                              className="far fa-trash-alt edit-profile-v2-request-decline-icon"
                              onClick={() =>
                                declineHandlerApproved(requestId, index)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          )}
          {props.option === 'requests' && (
            <div className="desktop-v2-filters-content">
              {!requests || requests.length < 1 ? (
                <div className="desktop-v2-request-none-container">
                  <BootstrapImage
                    src={requestsImage}
                    className="desktop-v2-request-none-image"
                  />
                  <h1 className="desktop-v2-request-none-title">
                    No Requests Yet
                  </h1>
                  <p className="desktop-v2-request-none-subtitle">
                    When users request to view your private photos, they will
                    appear here.
                  </p>
                </div>
              ) : (
                <div className="desktop-v2-request-content-container">
                  {requests &&
                    requests.map((item, index) => {
                      const { imgurl, username, age, address } = item.user;
                      const mainImage = imgurl.length > 0 ? imgurl[0] : null;
                      const requestId = item._id;
                      return (
                        <div className="edit-profile-v2-private-request">
                          <div className="edit-profile-v2-request-image-wrapper">
                            <Media image={mainImage} />
                            {/* <Image
                              publicId={
                                mainImage
                                  ? getImgName(mainImage)
                                  : 'placeholder-user-image-thumbnail_vankru.png'
                              }
                              version={
                                mainImage
                                  ? getImgVersion(mainImage)
                                  : '1590045309'
                              }
                              resource_type={
                                mainImage
                                  ? checkGIF(mainImage)
                                    ? 'video'
                                    : 'image'
                                  : null
                              }
                              effect="loop"
                              cloud_name="sugarbae"
                              className="edit-profile-v2-request-image"
                            >
                              <Transformation
                                height="200"
                                video_sampling="20"
                                delay="250"
                                duration="10"
                                effect="loop"
                                quality="auto"
                                flags="lossy"
                              />
                            </Image> */}
                          </div>
                          <div className="edit-profile-v2-request-info">
                            <h1 className="edit-profile-v2-request-username">{`${username}, ${age}`}</h1>
                            <label className="edit-profile-v2-request-user-address">
                              {address}
                            </label>
                          </div>
                          <div className="edit-profile-v2-request-action">
                            <i
                              className="far fa-check-circle edit-profile-v2-request-approve-icon"
                              onClick={() => approveHandler(requestId, index)}
                            />
                            <i
                              className="far fa-times-circle edit-profile-v2-request-decline-icon"
                              onClick={() => declineHandler(requestId, index)}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className="desktop-v2-filters-overlay"
          onClick={props.closeHandler}
        />
      </div>
    </div>
  );
}

export default PhotoRequestModal;
