import React, { useState } from "react";
import { Image } from "react-bootstrap";
import helpIcon from "../../../../assets/Icons/community/helpIcon.png";
import privacyIcon from "../../../../assets/Icons/community/privacyIcon.png";
import termsIcon from "../../../../assets/Icons/community/termsIcon.png";
import Help from "./Help";
import Privacy from "./Privacy";
import Terms from "./Terms";

function Community() {
  const [helpOpen, setHelpOpen] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);

  if (helpOpen) {
    return <Help backHandler={() => setHelpOpen(false)} />;
  }

  if (privacyOpen) {
    return <Privacy backHandler={() => setPrivacyOpen(false)} />;
  }

  if (termsOpen) {
    return <Terms backHandler={() => setTermsOpen(false)} />;
  }

  return (
    <div className="desktop-v2-settings-content">
      <div
        className="desktop-v2-settings-row"
        style={{ cursor: "pointer" }}
        onClick={() => setHelpOpen(true)}
      >
        <div className="desktop-v2-settings-row-innerwrap">
          <div>
            <div className="desktop-v2-settings-row-section">
              <Image
                src={helpIcon}
                className="desktop-v2-settings-help-icon"
                fluid
              />
              <span className="desktop-v2-settings-row-title">
                Help & Support
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="desktop-v2-settings-row"
        style={{ cursor: "pointer" }}
        onClick={() => setPrivacyOpen(true)}
      >
        <div className="desktop-v2-settings-row-innerwrap">
          <div>
            <div className="desktop-v2-settings-row-section">
              <Image
                src={privacyIcon}
                className="desktop-v2-settings-help-icon"
                fluid
              />
              <span className="desktop-v2-settings-row-title">
                Privacy Policy
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className="desktop-v2-settings-row"
        style={{ cursor: "pointer" }}
        onClick={() => setTermsOpen(true)}
      >
        <div className="desktop-v2-settings-row-innerwrap">
          <div>
            <div className="desktop-v2-settings-row-section">
              <Image
                src={termsIcon}
                className="desktop-v2-settings-help-icon"
                fluid
              />
              <span className="desktop-v2-settings-row-title">
                Terms of Service
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Community;
