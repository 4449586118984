import React, { useState, useContext, useEffect } from "react";
import "../modalv2styles.scss";
import { Modal, Button, Image, Form } from "react-bootstrap";
import Boost from "../../../components/matches/matchesComponentsV2/boost/Boost";
import { UserContext } from "../../../context/UserContext";
import getmore from "../../../assets/Images/matches/getmore.png";
import { submitBoost } from "../../../helpers/swipeActions";
import { api } from "../../../api";
import ClipLoader from "react-spinners/ClipLoader";

var moment = require("moment");

function BoostStatsModal(props) {
  const context = useContext(UserContext);
  const [show, setShow] = useState(props.show);
  const [likeCount, setLikeCount] = useState(null);
  const [matchCount, setMatchCount] = useState(null);
  const [complimentCount, setComplimentCount] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBoostData();
  }, []);

  const fetchBoostData = async (e) => {
    const data = {};

    await api
      .fetchBoostData(data)
      .then((res) => {
        if (res.status === 200) {
          setLikeCount(res.data.likeCount);
          setMatchCount(res.data.matchCount);
          setComplimentCount(res.data.complimentCount);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeHandler = (e) => {
    setShow(false);
    props.onClose();
  };

  const { cards } = context.swipeData;
  const imageOne = cards && cards.length > 0 && cards[0].imgurl[0];
  const imageTwo = cards && cards.length > 0 && cards[1].imgurl[0];
  const imageThree = cards && cards.length > 0 && cards[2].imgurl[0];

  var mainImage = null;
  var images = context.user.imgurl;
  if (images) {
    if (images.length > 0) {
      mainImage = images[0];
    }
  }

  var expirationDate = context.boostData.boostExpirationDate;
  var now = moment();
  var dateType = "hours";
  if (expirationDate) {
    expirationDate = moment(expirationDate);
    var difference = expirationDate.diff(now, "hours");
    if (difference < 1) {
      difference = expirationDate.diff(now, "minutes");
      dateType = "minutes";
      if (difference < 1) {
        difference = expirationDate.diff(now, "seconds");
        dateType = "seconds";
      }
    }
  }

  return (
    <Modal
      show={show}
      className="modal-v2"
      onHide={closeHandler}
      centered={true}
    >
      <Modal.Body className="modal-v2-body">
        <div className="boost-active-popup-modal">
          <div className="boost-modal-header">
            <Image src={imageOne} className="boost-active-back-image" />
            <Image src={mainImage} className="boost-active-front-image" />
            <Image src={imageThree} className="boost-active-back-image" />
            <Image src={getmore} className="boost-active-badge" />
          </div>
          <label className="boost-active-title">Your Profile Is Boosted!</label>
          <br />
          <label className="boost-active-description">
            {difference}
            {dateType === "hours" && (
              <span style={{ marginLeft: "5px" }}>hours remaining</span>
            )}
            {dateType === "minutes" && (
              <span style={{ marginLeft: "5px" }}>minutes remaining</span>
            )}
            {dateType === "seconds" && (
              <span style={{ marginLeft: "5px" }}>seconds remaining</span>
            )}
          </label>
          <br />
        </div>
        <div className="boost-package-select-wrapper">
          <div className="boost-package-select-form">
            <div className="boost-package-select-group">
              <i className="far fa-heart boost-package-icon"></i>
              <p className="boost-package-subtitle">Likes</p>
              {loading ? (
                <ClipLoader size="14px" />
              ) : (
                <h1 className="boost-package-price">{likeCount}</h1>
              )}
            </div>
            <div className="boost-package-select-group">
              <i className="far fa-user-friends boost-package-icon"></i>
              <p className="boost-package-subtitle">Matches</p>
              {loading ? (
                <ClipLoader size="14px" />
              ) : (
                <h1 className="boost-package-price">{matchCount}</h1>
              )}
            </div>
            <div className="boost-package-select-group">
              <i className="far fa-gift boost-package-icon"></i>
              <p className="boost-package-subtitle">Compliments</p>
              {loading ? (
                <ClipLoader size="14px" />
              ) : (
                <h1 className="boost-package-price">{complimentCount}</h1>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="modal-v2-footer">
        <Button className="modal-close-button" onClick={closeHandler}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BoostStatsModal;
