import React, { Component } from "react";
import LoginForm from "../../components/loginform/LoginForm";
import ValidateToken from "../../helpers/ValidateToken";
import MetaTags from "react-meta-tags";
import HomeNav from "../../components/navbar/HomeNav/HomeNav";
import HomeFooter from "../../components/footers/homeFooter/HomeFooter";

export class Login extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);

    if (ValidateToken() !== false) return this.props.history.push("/app");
  }
  render() {
    return (
      <div className="home-page-container">
        <HomeNav />
        <MetaTags>
          <title>
            Sugarbae Login | Find A Sugar Daddy or Sugar Baby | Sugarbae
          </title>{" "}
          <meta
            name="description"
            content="Login to your Sugarbae account and find your ideal arrangement today. Over 1 million active users worldwide. Find a Sugar Daddy or Sugar Baby online now."
          />
        </MetaTags>
        <LoginForm />
        <HomeFooter />
      </div>
    );
  }
}

export default Login;
