import React, { useState } from "react";
import { Button, Form, Image } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";
import distanceIcon from "../../../assets/Icons/distanceIcon.png";

function Distance(props) {
  var filters = props.filters;
  const [distance, setDistance] = useState(filters.distance);
  const changeHandler = (event, newValue) => {
    if (newValue > 500) {
      newValue = 500;
    } else if (newValue < 5) {
      newValue = 5;
    }

    setDistance(newValue);
  };
  const commitHandler = (event, newValue) => {
    if (newValue > 500) {
      newValue = 500;
    } else if (newValue < 5) {
      newValue = 5;
    }

    setDistance(newValue);
  };

  const saveHandler = (e) => {
    props.updateHandler(distance);
  };

  const resetHandler = (e) => {
    setDistance(500);
  };

  return (
    <div className="filter-slider-v2">
      <div className="filter-slider-v2-overlay" onClick={props.onClose} />

      <div className="filter-slider-v2-content">
        <div className="filter-slider-v2-close-bar" />
        <div className="filter-slider-v2-pop">
          <div className="filter-slider-v2-header">
            <div className="filter-slider-v2-header-section">
              <Image src={distanceIcon} className="filter-v2-icon" fluid />
              <span className="filter-slider-v2-title">Maximum Distance</span>
            </div>
            <div className="filter-slider-v2-header-section">
              <span
                className="filter-slider-v2-reset-text"
                onClick={resetHandler}
              >
                Reset
              </span>
            </div>
          </div>
          <div className="filter-slider-v2-form-wrapper">
            <h1 className="filter-slider-v2-slider-title">
              Up to <span style={{ fontWeight: "700" }}>{distance}</span>{" "}
              kilometers
            </h1>
            <div className="filter-slider-v2-slider-wrapper">
              <Slider
                min={-10}
                max={590}
                step={5}
                value={distance}
                onChange={changeHandler}
                onChangeCommitted={commitHandler}
                valueLabelDisplay={"on"}
              />
            </div>
          </div>

          <div className="filter-slider-v2-footer">
            <div className="filter-slider-v2-apply-btn" onClick={saveHandler}>
              Apply
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Distance;
