import React, { useState, useContext, useEffect } from "react";
import "./desktopuserstyles.scss";
import { Image as BootstrapImage } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";

function DesktopUserAbout(props) {
  const context = useContext(UserContext);

  const { card } = props;
  const [editOpen, setEditOpen] = useState(false);
  const [headline, setHeadline] = useState(context.user.headline);

  const changeHandler = (e) => {
    setHeadline(e.target.value);
  };

  const saveHandler = (e) => {
    e.preventDefault();
    if (headline !== context.user.headline) {
      const data = {
        userid: context.user._id,
        headline,
      };
      api
        .updateHeadline(data)
        .then(() => {
          const tempUser = context.user;
          tempUser.headline = headline;
          context.updateUserInfo("all", tempUser);
        })
        .catch(() => {});
    }

    setEditOpen(false);
  };

  useEffect(() => {
    if (
      context.user.headline === null ||
      context.user.headline === undefined ||
      context.user.headline.length < 1
    ) {
      setEditOpen(true);
    }
  }, []);

  return (
    <div className="desktop-v2-about-container">
      <div className="desktop-v2-section-title-wrapper">
        <h1 className="desktop-v2-section-title">About Me</h1>

        {editOpen ? (
          <div
            className="desktop-v2-section-edit-button"
            onClick={() => {
              setHeadline(context.user.headline);
              setEditOpen(false);
            }}
          >
            <i className="far fa-times user-info-edit-icon" />
          </div>
        ) : (
          <div
            className="desktop-v2-section-edit-button"
            onClick={() => setEditOpen(true)}
          >
            <i className="far fa-pen user-info-edit-icon" />
          </div>
        )}
      </div>
      {editOpen ? (
        <form className="desktop-v2-user-info-form" onSubmit={saveHandler}>
          <textarea
            className="desktop-v2-user-info-form-input"
            type="text"
            onChange={changeHandler}
            rows="3"
            value={headline}
            placeholder="Describe a little bit about you"
          />
          <input
            type="submit"
            value="Save"
            className="desktop-v2-user-info-save-button"
          />
        </form>
      ) : (
        <p className="desktop-v2-section-paragraph">{headline}</p>
      )}
    </div>
  );
}

export default DesktopUserAbout;
