import React, { useState, useContext } from "react";
import "./desktopsliders.scss";
import Slider from "@material-ui/core/Slider";
import { Image } from "react-bootstrap";
import { UserContext } from "../../../../../context/UserContext";
import distanceIcon from "../../../../../assets/Icons/distanceIcon.png";

function Distance(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [distance, setDistance] = useState(filters.distance);
  const changeHandler = (event, newValue) => {
    if (newValue > 500) {
      newValue = 500;
    } else if (newValue < 5) {
      newValue = 5;
    }

    setDistance(newValue);
  };

  const commitHandler = (event, newValue) => {
    if (newValue > 500) {
      newValue = 500;
    } else if (newValue < 5) {
      newValue = 5;
    }

    setDistance(newValue);
  };

  const saveHandler = (e) => {
    props.updateHandler(distance);
  };

  const resetHandler = (e) => {
    setDistance(500);
  };

  return (
    <div className="desktop-v2-filters-content">
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.backHandler}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-icon">
              <Image src={distanceIcon} className="filter-v2-icon" fluid />
            </div>
            <div className="desktop-v2-filters-slider-title">
              Maximum Distance
            </div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={resetHandler}
            >
              Reset
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <div className="desktop-filter-slider-v2-form-wrapper">
            <h1 className="filter-slider-v2-slider-title">
              Up to <span style={{ fontWeight: "700" }}>{distance}</span>{" "}
              kilometers
            </h1>
            <div className="filter-slider-v2-slider-wrapper">
              <Slider
                min={-10}
                max={590}
                step={5}
                value={distance}
                onChange={changeHandler}
                onChangeCommitted={commitHandler}
                valueLabelDisplay="on"
              />
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-footer" onClick={saveHandler}>
          <div className="desktop-filter-slider-apply-button">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default Distance;
