import React, { Component } from "react";
import Offer from "../../../components/welcome/offer/Offer";

export class OfferPage extends Component {
  render() {
    let vh = window.innerHeight;
    var height = vh + "px";
    return (
      <div
        className="app-component"
        style={{ height: height, maxHeight: height }}
      >
        {" "}
        <Offer />
      </div>
    );
  }
}

export default OfferPage;
