import React, { useRef, useState, useContext } from 'react';
import { Card } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';

import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../../utils/getCloudinaryInfo';

import Swipeable from '../../../../swipe/Swipeable';
import { Image as BootstrapImage } from 'react-bootstrap';
import matchIcon from '../../../../../assets/Icons/match-white.png';
import { UserContext } from '../../../../../context/UserContext';
import giftIcon from '../../../../../assets/Icons/box.png';
import Media from '../../../../../components/media/Media';

var moment = require('moment');

const MessageQueueItem = ({ id, chatToggle, message, userId }) => {
  const context = useContext(UserContext);

  const [swipeProgress, setSwipeProgress] = useState(0);
  const [returning, setReturning] = useState(false);
  const cardRef = useRef();

  let notification = true;
  if (message.sender === userId || message.isRead) {
    notification = false;
  }

  const imgurl = message.user.imgurl.length > 0 ? message.user.imgurl[0] : null;

  const handleSwipeStart = () => {
    if (swipeProgress === 0) {
      setReturning(false);
    }
  };

  const updateSwipeProgress = (progress) => {
    setSwipeProgress(Math.min(0, progress));
  };

  const handleSwipedLeft = () => {
    const endProgress = -cardRef.current.wrapper.offsetWidth / 4 - 1;

    if (swipeProgress < endProgress) {
      setReturning(true);
    }

    setSwipeProgress(endProgress);
  };

  const handleSwipeReturn = () => {
    setReturning(true);
    setSwipeProgress(0);
  };

  let isMatched = false;
  if (context.matchedIDs.includes(message.user._id)) {
    isMatched = true;
  }

  let isOnline = false;
  let lastLogin = message.user.lastlogin;
  if (lastLogin) {
    var oneHourAgo = moment().subtract(5, 'hours');

    if (moment(lastLogin).isAfter(oneHourAgo)) {
      isOnline = true;
    }
  }

  let isVerified = false;
  if (
    context.user.verifications.email.status &&
    context.user.verifications.phone.status
  ) {
    isVerified = true;
  }

  let isCompliment = false;
  if (context.complimentIDs.includes(message.user._id)) {
    isCompliment = true;
  }

  return (
    <Swipeable
      ref={cardRef}
      onSwipeStart={handleSwipeStart}
      onSwipeProgress={updateSwipeProgress}
      onSwipedLeft={handleSwipedLeft}
      onSwipeReturn={handleSwipeReturn}
      returning={returning}
      transform={`translateX(${swipeProgress}px)`}
      threshold={0.25}
    >
      <Card className="message-queue-card" onClick={() => chatToggle(id)}>
        <div className="message-queue-image-wrapper">
          <Media image={imgurl} />
          {/* <Image
            publicId={
              imgurl
                ? getImgName(imgurl)
                : "placeholder-user-image-thumbnail_vankru.png"
            }
            version={imgurl ? getImgVersion(imgurl) : "1590045309"}
            resource_type={
              imgurl ? (checkGIF(imgurl) ? "video" : "image") : null
            }
            effect="loop"
            cloud_name="sugarbae"
            className="message-queue-image"
          >
            <Transformation
              height="200"
              video_sampling="20"
              delay="250"
              duration="10"
              effect="loop"
              quality="auto"
              flags="lossy"
            />
          </Image> */}
          {isOnline && (
            <div className="message-queue-online-indicator">
              <i className="fad fa-circle message-queue-online-icon" />
            </div>
          )}

          {/* {!notification ? null : (
            <div className="message-queue-unread-message-icon-wrapper">
              <i className="fad fa-circle isread-icon"></i>
            </div>
          )} */}

          {isMatched && (
            <div className="message-queue-match-indicator">
              <BootstrapImage
                src={matchIcon}
                fluid
                className="match-indicator-icon"
              />
            </div>
          )}
        </div>
        <div className="message-queue-info">
          <h4 className="message-queue-username">
            {isVerified && (
              <i className="fas fa-check-circle message-queue-verified" />
            )}
            {message.user.username},{' '}
            <span className="message-queue-age">{message.user.age}</span>
          </h4>
          <p
            className={
              notification
                ? 'message-card-message'
                : 'message-card-message-read'
            }
          >
            {message.message}
          </p>
          <div className="message-queue-indicator-wrapper">
            {isCompliment && (
              <div className="message-queue-compliment-indicator">
                <BootstrapImage
                  src={giftIcon}
                  className="message-queue-compliment-icon"
                  fluid
                />
              </div>
            )}
            {notification && (
              <div className="message-queue-unread-indicator">
                <i className="fas fa-circle message-queue-unread-icon" />
              </div>
            )}
          </div>
        </div>
      </Card>
    </Swipeable>
  );
};

export default MessageQueueItem;
