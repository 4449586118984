import React, { useContext } from "react";
import "../settingsv2styles.scss";
import { UserContext } from "../../../../../context/UserContext";

function NotificationSettings(props) {
  const context = useContext(UserContext);

  const toggleTab = (name) => (e) => {
    props.toggleTab(name);
  };

  return (
    <div className="settings-v2-section">
      <h1 className="settings-v2-section-title">Notification Settings</h1>
      <div className="settings-v2-section-content">
        <div
          className="settings-v2-section-row"
          onClick={toggleTab("emailNotifications")}
        >
          <div className="settings-v2-section-row-icon">
            <i className="far fa-bell"></i>
          </div>
          <div className="settings-v2-section-row-title-full-width">
            Email Notifications
          </div>

          <div className="settings-v2-section-row-action">
            <i className="fas fa-chevron-right settings-v2-right-icon"></i>
          </div>
        </div>
        <div
          className="settings-v2-section-row"
          onClick={toggleTab("textNotifications")}
        >
          <div className="settings-v2-section-row-icon">
            <i className="far fa-bell"></i>
          </div>
          <div className="settings-v2-section-row-title-full-width">
            Text Notifications
          </div>

          <div className="settings-v2-section-row-action">
            <i className="fas fa-chevron-right settings-v2-right-icon"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationSettings;
