import React, { Component } from "react";

import { UserContext } from "../../../../context/UserContext";

import "./counters.scss";

export class MatchCounter extends Component {
  static contextType = UserContext;
  render() {
    return (
      <div className="counter-component">
        {this.context.likesCounter > 0 && (
          <i className="fad fa-circle counter-icon"></i>
        )}
      </div>
    );
  }
}

export default MatchCounter;
