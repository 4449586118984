import React, { useState, useContext, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete from "react-places-autocomplete";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";

function DesktopUserLocation(props) {
  const context = useContext(UserContext);
  const [editOpen, setEditOpen] = useState(false);
  const [showMapPreview, setShowMapPreview] = useState(props.showMapPreview);
  const [address, setAddress] = useState(context.user.address);
  const [changingAddress, setChangingAddress] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  const saveHandler = async (e) => {
    e.preventDefault();

    const data = {
      userid: context.user.id,
      address,
    };

    await api
      .updateLocation(data)
      .then((res) => {
        if (res.status === 200) {
          context.updateUserInfo("address", address);
          props.updateHandler(address);
          setEditOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeHandler = (e) => {
    setChangingAddress(e);
  };
  const commitHandler = async (e) => {
    setAddress(e);
    setChangingAddress(e);
  };

  const searchOptions = {
    types: ["(cities)"],
  };

  return (
    <div className="desktop-v2-profile-user-content-section">
      <div className="desktop-v2-section-title-wrapper">
        <h1 className="desktop-v2-section-title">My Location</h1>

        {editOpen ? (
          <div
            className="desktop-v2-section-edit-button"
            onClick={() => setEditOpen(false)}
          >
            <i className="far fa-times user-info-edit-icon" />
          </div>
        ) : (
          <div
            className="desktop-v2-section-edit-button"
            onClick={() => setEditOpen(true)}
          >
            <i className="far fa-pen user-info-edit-icon" />
          </div>
        )}
      </div>
      {editOpen ? (
        <form
          className="desktop-v2-user-info-form-location"
          onSubmit={saveHandler}
        >
          <PlacesAutocomplete
            value={changingAddress}
            onChange={changeHandler}
            onSelect={commitHandler}
            searchOptions={searchOptions}
            shouldFetchSuggestions
            highlightFirstSuggestion
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <>
                <input
                  className="desktop-v2-user-info-form-input"
                  type="text"
                  placeholder="Enter your city"
                  onChange={changeHandler}
                  {...getInputProps({
                    placeholder: "Enter your city",
                  })}
                />
                <div className="desktop-location-dropdown-wrapper">
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? "location-suggestion-item-active"
                      : "location-suggestion-item";

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                        })}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="far fa-map-marker-alt location-suggestion-icon" />
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </PlacesAutocomplete>
          <input
            type="submit"
            value="Save"
            className="desktop-v2-user-info-save-button"
          />
        </form>
      ) : (
        <div className="desktop-v2-profile-user-location-map-wrapper">
          {props.showMapPreview ? (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCoSbKzFFYZ2HnK-jOZwIqdhwp7Aq4fu4I",
              }}
              defaultCenter={{
                lat: props.previewCoordinates[1],
                lng: props.previewCoordinates[0],
              }}
              defaultZoom={11}
              options={{
                panControl: false,
                mapTypeControl: false,
                scrollwheel: false,
                disableDefaultUI: true,
              }}
              defaultOptions={{
                fullscreenControl: false,
              }}
            />
          ) : (
            <div className="desktop-v2-profile-user-location-map-placeholder" />
          )}
        </div>
      )}
    </div>
  );
}

export default DesktopUserLocation;
