import React, { useState, useContext, useEffect } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Image as BootstrapImage } from 'react-bootstrap';
import { withRouter, useHistory } from 'react-router-dom';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { submitLike, submitDislike } from '../../../../helpers/swipeActions';
import { UserContext } from '../../../../context/UserContext';
import cardLike from '../../../../assets/Icons/actions/cardLike.png';
import cardDislike from '../../../../assets/Icons/actions/cardDislike.png';
import Media from '../../../../components/media/Media';

function DesktopLikesCard(props) {
  const context = useContext(UserContext);
  const [refresh, setRefresh] = useState(false);

  const history = useHistory();
  const images = props.like.user.imgurl;
  let image = null;
  if (images.length > 0) {
    image = props.like.user.imgurl[0];
  }

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  const profileToggle = (e) => {
    const url = `/app/member/${props.like.user._id}`;
    history.push(url);
  };
  const handleLike = async (e) => {
    const like = await submitLike(context.user._id, props.like.user._id).catch(
      (err) => {
        console.log(err);
      }
    );
    if (like.valid) {
      const temp = context.likes;
      temp.splice(props.like.user._id, 1);
      context.updateLikes(temp);
      setRefresh(true);
    }
    if (like.match) {
      context.updateMatches();
      props.handleMatch(props.like.user);
    }
  };
  const handleDislike = async (e) => {
    const dislike = await submitDislike(
      context.user._id,
      props.like.user._id
    ).catch((err) => {
      console.log(err);
    });

    const temp = context.likes;
    temp.splice(props.like.user._id, 1);
    context.updateLikes(temp);
    setRefresh(true);
  };

  return (
    <div className="desktop-v2-likes-card">
      <div
        className="desktop-v2-likes-card-image-wrapper"
        onClick={profileToggle}
      >
        <Media image={image} />
        {/* <Image
          publicId={
            image
              ? getImgName(image)
              : "larger-placeholder-user-image_vvdghq.png"
          }
          version={image ? getImgVersion(image) : "1590046357"}
          resource_type={image ? (checkGIF(image) ? "video" : "image") : null}
          cloud_name="sugarbae"
          className="desktop-v2-likes-card-image"
        >
          <Transformation
            height="675"
            width="540"
            video_sampling="20"
            delay="250"
            duration="10"
            effect="loop"
            quality="auto"
            flags="lossy"
          />
        </Image> */}
        <div className="desktop-v2-likes-card-user-info-wrapper">
          <h1 className="desktop-v2-likes-card-username">
            {props.like.user.username},{' '}
            <span className="desktop-v2-likes-card-age">
              {props.like.user.age}
            </span>
          </h1>
        </div>
      </div>
      <div className="desktop-v2-likes-card-footer">
        <div
          className="desktop-v2-likes-card-action-button"
          onClick={handleDislike}
          style={{ borderRight: '1px solid #f4f4f4' }}
        >
          <BootstrapImage
            src={cardDislike}
            className="desktop-v2-likes-card-action-icon"
          />
        </div>
        <div
          className="desktop-v2-likes-card-action-button"
          onClick={handleLike}
        >
          <BootstrapImage
            src={cardLike}
            className="desktop-v2-likes-card-action-icon"
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(DesktopLikesCard);
