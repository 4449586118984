import React, { useState, useContext, useRef, useEffect } from "react";
import { Image, Button, Form, InputGroup } from "react-bootstrap";
import ReactCodeInput from "react-verification-code-input";
import EmailValidator from "email-validator";
import PasswordValidator from "password-validator";
import PhoneInput from "react-phone-input-2";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";
import "react-phone-input-2/lib/style.css";

function EditPhone(props) {
  const context = useContext(UserContext);
  const [number, setNumber] = useState(context.user.verifications.phone.number);
  const [disabled, setDisabled] = useState(false);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [verifyOpen, setVerifyOpen] = useState(false);
  const [numberExistsError, setNumberExistsError] = useState(false);
  const [numberInvalidError, setNumberInvalidError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [validateLoading, setValidateLoading] = useState(false);
  const [validationCode, setValidationCode] = useState("");
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
  const [sendAgainSuccess, setSendAgainSuccess] = useState(false);

  useEffect(() => {
    if (props.option === "verification") {
      sendSmsCode();
      setVerifyOpen(true);
    }

    let noPhoneNumber = true;
    if (
      context.user.verifications.phone.number !== null &&
      context.user.verifications.phone.number !== undefined
    ) {
      if (context.user.verifications.phone.number.length > 5) {
        noPhoneNumber = false;
      }
    }

    if (noPhoneNumber) {
      setNumber("");
    } else if (number.length < 17) {
      if (number !== context.user.verifications.phone.number) {
        setSubmitDisabled(true);
      }
    } else {
      setSubmitDisabled(false);
    }
  }, []);

  const validationCodeChange = async (e) => {
    setVerifyError(false);
    setValidationCode(e);

    if (e.length === 6) {
      setValidateLoading(true);

      const data = {
        validationcode: e,
      };

      await api
        .validateSmsToken(data)
        .then((res) => {
          if (res.status === 200) {
            context.updateUserInfo("verifications", res.data.verifications);
            setVerifySuccess(true);
            verifyHandler();
          }
        })
        .catch((err) => {
          console.log(err);
          setValidateLoading(false);
          setVerifyError(true);
        });
    }
  };

  const resetErrors = (e) => {
    setSendAgainSuccess(false);
    setVerifyButtonDisabled(false);
    setNumberInvalid(false);
    setNumberExistsError(false);
    setNumberInvalidError(false);
  };

  const changeHandler = (e) => {
    setNumber(e);
    setNumberInvalid(false);

    if (e.length < 17) {
      setSubmitDisabled(true);
    } else if (number !== context.user.verifications.phone.number) {
      setSubmitDisabled(false);
    }
  };

  const sendAgainHandler = (e) => {
    sendSmsCode();
    setSendAgainSuccess(true);
  };

  const verifyHandler = (e) => {
    if (verifyOpen) {
      resetErrors();
      setDisabled(true);
      setVerifyOpen(false);
      props.editPhoneOpenToggle();
      props.verifyPhoneOpenToggle();
    } else {
      resetErrors();
      setVerifyButtonDisabled(true);
      sendSmsCode();
      setVerifyOpen(true);
    }
  };

  const sendSmsCode = async (e) => {
    const data = {
      user: context.user._id,
      phonenumber: number,
    };

    return api
      .generateSmsToken(data)
      .then((res) => {
        if (res.status === 200) {
          context.updateUserInfo("verifications", res.data.verifications);
          props.refresh();
        }
      })
      .catch((err) => {});
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    if (number !== context.user.verifications.phone.number) {
      setSubmitDisabled(true);
      setLoading(true);
      verifyHandler();
    }
  };

  const backHandler = (e) => {
    if (props.option === "verification") {
      props.verifyPhoneOpenToggle();
    } else if (verifyOpen) {
      setVerifyOpen(false);
    } else {
      props.editPhoneOpenToggle();
    }
  };
  return (
    <div className="desktop-v2-settings-content">
      <div className="desktop-v2-settings-content-header">
        <div className="desktop-v2-settings-back-button" onClick={backHandler}>
          <i
            className="far fa-long-arrow-alt-left"
            style={{ marginRight: "5px" }}
          />
          <span>Back</span>
        </div>
        {verifyOpen ? (
          <h1 className="desktop-v2-settings-content-header-title">
            Verify Phone Number
          </h1>
        ) : (
          <h1 className="desktop-v2-settings-content-header-title">
            Update Phone Number
          </h1>
        )}
      </div>

      {verifyError && (
        <div className="desktop-v2-settings-edit-row">
          <div className="edit-screen-status-wrapper">
            <i className="far fa-exclamation-circle number-alert-icon" />
            <span>The verification code you entered is incorrect</span>
          </div>
        </div>
      )}

      {verifyOpen ? (
        <div className="desktop-v2-settings-edit-row">
          <Form className="desktop-v2-settings-form-edit">
            <Form.Group className="desktop-v2-settings-form-group-verification">
              <Form.Label className="desktop-v2-settings-form-label">
                Enter the 6 digit verification code we texted you:
              </Form.Label>
              <ReactCodeInput
                onChange={validationCodeChange}
                loading={validateLoading}
                disabled={validateLoading}
                fieldWidth="16%"
                className="verification-code-input-v2"
              />
              <div className="edit-screen-button-wrapper">
                {sendAgainSuccess ? (
                  <span className="new-code-sent-text">New code sent</span>
                ) : (
                  <Button
                    className="edit-screen-send-again-button"
                    onClick={sendAgainHandler}
                  >
                    Send again
                  </Button>
                )}
              </div>
            </Form.Group>
          </Form>
        </div>
      ) : (
        <div className="desktop-v2-settings-edit-row">
          <Form
            className="desktop-v2-settings-form-edit"
            onSubmit={updateHandler}
          >
            <div className="desktop-v2-settings-form-edit-innerwrap">
              <Form.Group className="desktop-v2-settings-form-group-edit-phone">
                <Form.Label className="desktop-v2-settings-form-label">
                  Enter your phone number:
                </Form.Label>
                {number !== null && number !== undefined && (
                  <PhoneInput
                    country="us"
                    value={number}
                    onChange={changeHandler}
                    preferredCountries={["us", "ca", "gb"]}
                    disabled={disabled}
                  />
                )}

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingTop: "1.5rem",
                  }}
                >
                  <Button
                    type="submit"
                    className="desktop-v2-settings-form-edit-submit"
                    disabled={submitDisabled}
                  >
                    Submit
                  </Button>
                </div>
              </Form.Group>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}

export default EditPhone;
