import React from "react";
import "./desktopsidebarv2.scss";
import { Image as BootstrapImage } from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import desktopNavLogo from "../../../../assets/logos/horizontal-whitebg-3x.png";
import userIcon from "../../../../assets/Icons/navbar/user.png";
import userIconActive from "../../../../assets/Icons/navbar/user-active.png";
import searchIcon from "../../../../assets/Icons/navbar/search.png";
import searchIconActive from "../../../../assets/Icons/navbar/search-active.png";
import swipeIcon from "../../../../assets/Icons/navbar/swipe.png";
import swipeIconActive from "../../../../assets/Icons/navbar/swipe-active.png";
import messageIcon from "../../../../assets/Icons/navbar/message.png";
import messageIconActive from "../../../../assets/Icons/navbar/message-active.png";
import likesIcon from "../../../../assets/Icons/navbar/likes.png";
import likesIconActive from "../../../../assets/Icons/navbar/likes-active.png";

import DesktopSpotlight from "../desktopSearchV2/desktopSpotlight/DesktopSpotlight";

function DesktopSidebarV2(props) {
  const history = useHistory();
  const { pathname } = props.location;

  return (
    <div className="desktop-v2-sidebar">
      <div className="desktop-v2-sidebar-logo-container">
        <BootstrapImage
          src={desktopNavLogo}
          className="desktop-v2-sidebar-logo"
          fluid
        />
      </div>
      <div className="desktop-v2-sidebar-nav">
        <div
          className="desktop-v2-sidebar-nav-item"
          onClick={() => {
            history.push("/app/search");
          }}
        >
          {pathname === "/app/search" ? (
            <BootstrapImage
              src={searchIconActive}
              className="desktop-v2-sidebar-nav-icon"
              fluid
            />
          ) : (
            <BootstrapImage
              src={searchIcon}
              className="desktop-v2-sidebar-nav-icon"
              fluid
            />
          )}
          {pathname === "/app/search" ? (
            <span className="desktop-v2-sidebar-nav-title-active">Search</span>
          ) : (
            <span className="desktop-v2-sidebar-nav-title">Search</span>
          )}
        </div>
        <div
          className="desktop-v2-sidebar-nav-item"
          onClick={() => {
            history.push("/app");
          }}
        >
          {pathname === "/app" ? (
            <BootstrapImage
              src={swipeIconActive}
              className="desktop-v2-sidebar-nav-icon"
              fluid
            />
          ) : (
            <BootstrapImage
              src={swipeIcon}
              className="desktop-v2-sidebar-nav-icon"
              fluid
            />
          )}
          {pathname === "/app" ? (
            <span className="desktop-v2-sidebar-nav-title-active">Swipe</span>
          ) : (
            <span className="desktop-v2-sidebar-nav-title">Swipe</span>
          )}
        </div>
        <div
          className="desktop-v2-sidebar-nav-item"
          onClick={() => {
            history.push("/app/messages");
          }}
        >
          {pathname === "/app/messages" ? (
            <BootstrapImage
              src={messageIconActive}
              className="desktop-v2-sidebar-nav-icon"
              fluid
            />
          ) : (
            <BootstrapImage
              src={messageIcon}
              className="desktop-v2-sidebar-nav-icon"
              fluid
            />
          )}
          {pathname === "/app/messages" ? (
            <span className="desktop-v2-sidebar-nav-title-active">
              Messages
            </span>
          ) : (
            <span className="desktop-v2-sidebar-nav-title">Messages</span>
          )}
        </div>
        <div
          className="desktop-v2-sidebar-nav-item"
          onClick={() => {
            history.push("/app/matches");
          }}
        >
          {pathname === "/app/matches" ? (
            <BootstrapImage
              src={likesIconActive}
              className="desktop-v2-sidebar-nav-icon"
              fluid
            />
          ) : (
            <BootstrapImage
              src={likesIcon}
              className="desktop-v2-sidebar-nav-icon"
              fluid
            />
          )}
          {pathname === "/app/matches" ? (
            <span className="desktop-v2-sidebar-nav-title-active">
              Likes/Matches
            </span>
          ) : (
            <span className="desktop-v2-sidebar-nav-title">Likes/Matches</span>
          )}
        </div>
        <div
          className="desktop-v2-sidebar-nav-item"
          onClick={() => {
            history.push("/app/profile");
          }}
        >
          {pathname === "/app/profile" ? (
            <BootstrapImage
              src={userIconActive}
              className="desktop-v2-sidebar-nav-icon"
              fluid
            />
          ) : (
            <BootstrapImage
              src={userIcon}
              className="desktop-v2-sidebar-nav-icon"
              fluid
            />
          )}
          {pathname === "/app/profile" ? (
            <span className="desktop-v2-sidebar-nav-title-active">Profile</span>
          ) : (
            <span className="desktop-v2-sidebar-nav-title">Profile</span>
          )}
        </div>
      </div>
      <div className="desktop-sidebar-spotlight">
        <h1 className="desktop-sidebar-spotlight-title">Featured Profiles</h1>
        <DesktopSpotlight />
      </div>
    </div>
  );
}

export default withRouter(DesktopSidebarV2);
