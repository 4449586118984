import React, { useState, useEffect } from "react";
import { api } from "../../api";
import "./graphs.scss";
import {
  BarChart,
  Bar,
  Cell,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
var moment = require("moment");

function ActiveUser(props) {
  //const [maleSignupData, setMaleSignupData] = useState([]);
  //const [femaleSignupData, setFemaleSignupData] = useState([]);
  const [activeUser, setActiveUserData] = useState([]); 
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    return api
      .fetchActiveUserData()
      .then((res) => {
        if (res.status === 200) {
          setActiveUserData(res.data.activeUser);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderCustomAxisTick = ({ x, y, payload }) => {
    var date = payload.value;

    var formattedDate = moment(date).format("MMM DD").toString();

    return (
      <text x={x} y={y + 25} fill="#666" textAnchor="middle">
        {formattedDate}
      </text>
    );
  };
  return (
    <div className="graph-container">
      <div className="graph-header">
        <h1 className="graph-title">Active User by Last Login Date and Gender </h1>
      </div>
      <div className="graph-content-container">
        <div className="graph-content">
          <h2 className="graph-content-title"></h2>
          <BarChart
            width={800}
            height={400}
            data={activeUser}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Tooltip />
            <Bar dataKey="female" stackId="a" fill="#db2adb" />
            <Bar dataKey="male" stackId="a" fill="#525cd1" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="_id" tick={renderCustomAxisTick} />
            <YAxis />
          </BarChart>
        </div>
      </div>
    </div>
  );
}

export default ActiveUser;
