import React from "react";
import "./profileactions.scss";

function ProfileActions(props) {
  const chatHandler = (e) => {
    props.chatHandler();
  };

  const likeHandler = (e) => {
    props.likeHandler();
  };
  const undoHandler = (e) => {
    props.undoHandler();
  };
  const superlikeHandler = (e) => {
    props.superlikeHandler();
  };

  return (
    <div className="profile-actions-component">
      <div className="profile-card-button" onClick={() => chatHandler()}>
        <i className="fas fa-comment-alt-lines profile-card-actions-btn-icon-message"></i>
      </div>
      {props.superlikeStatus || props.likeStatus ? (
        <div className="profile-card-button">
          <i className="fas fa-star profile-card-actions-btn-icon-superlike-disabled"></i>
        </div>
      ) : (
        <div className="profile-card-button" onClick={() => superlikeHandler()}>
          <i className="fas fa-star profile-card-actions-btn-icon-superlike"></i>
        </div>
      )}

      {props.superlikeStatus || props.likeStatus ? (
        <div className="profile-card-button" onClick={() => undoHandler()}>
          <i className="fas fa-undo profile-card-actions-btn-icon-undo"></i>
        </div>
      ) : (
        <div className="profile-card-button" onClick={() => likeHandler()}>
          <i className="fas fa-heart profile-card-actions-btn-icon-like"></i>
        </div>
      )}
    </div>
  );
}

export default ProfileActions;
