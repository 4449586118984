import React, { Component } from "react";
import "./swipepops.scss";
import { Button, Card, Modal, Alert } from "react-bootstrap";
import Axios from "axios";
import { UserContext } from "../../../../context/UserContext";
import { withRouter } from "react-router-dom";
import BoostPurchaseOptions from "../../../planPicker/BoostPurchaseOptions";
import ClipLoader from "react-spinners/ClipLoader";
import { api } from "../../../../api";

export class BoostPop extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      show: true,
      premium: false,
      loading: true,
      image: "",
      confirmationOpen: false,
      plan: "packageB",
      cardInfo: null,
      confirmPurchaseDisabled: false,
      checkoutLoading: false,
      purchaseSuccess: false,
      failedPurchase: false,
      date: null,
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  linkClickHandler = (e) => {
    this.props.history.push("/app/premium");
  };

  fetchData = async (e) => {
    const token = localStorage.getItem("auth");
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + "/api/swipe/boostpop";
    const data = {
      userid: this.context.user._id,
    };

    return api
      .fetchBoostPop(data)
      .then((res) => {
        if (res.status === 200) {
          var premium = res.data.premium;
          var date = res.data.date;
          var subscription = res.data.subscription;
          var cardInfo = subscription.cardInfo;
          this.setState({
            premium: premium,

            date: date,
            cardInfo: cardInfo,

            loading: false,
          });
        }
      })
      .catch((err) => {});
  };

  closeHandler = (e) => {
    this.setState({ show: false }, () => {
      this.props.closeHandler();
    });
  };

  checkoutHandler = (e) => {
    this.setState({ confirmationOpen: true });
  };

  updatePlanHandler = (e) => {
    this.setState({ plan: e });
  };

  confirmPurchaseHandler = async (e) => {
    this.setState(
      { confirmPurchaseDisabled: true, checkoutLoading: true },
      async (e) => {
        const data = {
          userid: this.context.user._id,
          plan: this.state.plan,
        };

        return api
          .purchaseBoosts(data)
          .then((res) => {
            if (res.status === 200) {
              var purchaseSuccess = res.data.purchaseSuccess;
              if (purchaseSuccess) {
                this.setState({
                  purchaseSuccess: true,
                  checkoutLoading: false,
                });
              } else {
                this.setState({
                  purchaseSuccess: false,
                  checkoutLoading: false,
                  failedPurchase: true,
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  changePaymentMethodHandler = (e) => {
    var email = this.context.user.email;
    var plan = this.state.plan;
    var packageAUrl =
      "https://api.ccbill.com/wap-frontflex/flexforms/da6098cc-58f9-4ce5-95f8-bb2840990661?Email=" +
      email;
    var packageBUrl =
      "https://api.ccbill.com/wap-frontflex/flexforms/62045b90-9c87-4acd-9d83-0b3ac9dd8391?Email=" +
      email;
    var packageCUrl =
      "https://api.ccbill.com/wap-frontflex/flexforms/85e9276d-fca2-4ca1-b812-d52f04bccf05?Email=" +
      email;

    if (plan === "packageA") {
      window.location.replace(packageAUrl);
    } else if (plan === "packageB") {
      window.location.replace(packageBUrl);
    } else if (plan === "packageC") {
      window.location.replace(packageCUrl);
    }
  };

  render() {
    if (this.state.loading) {
      return null;
    } else if (this.state.premium) {
      var cardInfo = this.state.cardInfo;
      if (cardInfo) {
        var lastFour = cardInfo.lastFour;
        var cardType = cardInfo.cardType;
        var paymentType = cardInfo.paymentType;
      }
      return (
        <Modal size="lg" show={this.state.show} onHide={this.closeHandler}>
          {this.state.checkoutLoading ? (
            <div className="checkout-loading-overlay">
              <ClipLoader color="white" />
            </div>
          ) : null}
          <Modal.Body className="swipe-pop-modal-body-boost">
            <div className="swipe-pop-icon">
              <i className="fas fa-bolt swipe-pop-main-icon-bolt"></i>{" "}
            </div>
            <h2 className="swipe-pop-body-title">Out Of Boosts!</h2>
            <div className="swipe-pop-body-countdown-wrapper"></div>
            <p className="swipe-pop-body-subtitle">
              Be a <strong>top profile</strong> in your area for 60 minutes to
              get more matches.
            </p>
            <Card className="swipe-pop-features-card">
              {this.state.purchaseSuccess ? (
                <Card.Body className="swipe-pop-features-card-body">
                  <Alert variant="success">
                    Successful Purchase! Your Boosts have been added to your
                    account and are now ready to use!
                  </Alert>
                </Card.Body>
              ) : (
                <Card.Body className="swipe-pop-features-card-body">
                  {this.state.failedPurchase ? (
                    <Alert variant="danger">
                      Failed Purchase! The attempt to charge your card on file
                      failed. Please update your card information to complete
                      your purchase.
                    </Alert>
                  ) : (
                    <div style={{ width: "100%" }}>
                      {this.state.confirmationOpen ? (
                        <div className="confirm-purchase-wrapper">
                          {this.state.plan === "packageC" ? (
                            <div className="confirm-purchase">
                              <div className="confirm-purchase-wrap">
                                <h3 className="confirm-purchase-number">20</h3>
                                <p className="confirm-purchase-subtitle">
                                  Boosts
                                </p>
                              </div>
                              <div className="confirm-purchase-wrap-price">
                                <h3 className="confirm-purchase-price">
                                  $55 total
                                </h3>
                              </div>
                              {lastFour !== null ? (
                                <div className="confirm-purchase-wrap-paymentinfo">
                                  <p className="confirm-purchase-payment-info">
                                    Card Ending With {lastFour}
                                  </p>
                                </div>
                              ) : null}
                              {lastFour !== null ? (
                                <div
                                  className="confirm-purchase-new-card-wrap"
                                  onClick={this.changePaymentMethodHandler}
                                >
                                  <p className="confirm-purchase-new-card-text">
                                    Use a different card
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                          {this.state.plan === "packageB" ? (
                            <div className="confirm-purchase">
                              <div className="confirm-purchase-wrap">
                                <h3 className="confirm-purchase-number">10</h3>
                                <p className="confirm-purchase-subtitle">
                                  Boosts
                                </p>
                              </div>
                              <div className="confirm-purchase-wrap-price">
                                <h3 className="confirm-purchase-price">
                                  $35 total
                                </h3>
                              </div>
                              {lastFour !== null ? (
                                <div className="confirm-purchase-wrap-paymentinfo">
                                  <p className="confirm-purchase-payment-info">
                                    Card Ending With {lastFour}
                                  </p>
                                </div>
                              ) : null}
                              {lastFour !== null ? (
                                <div
                                  className="confirm-purchase-new-card-wrap"
                                  onClick={this.changePaymentMethodHandler}
                                >
                                  <p className="confirm-purchase-new-card-text">
                                    Use a different card
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                          {this.state.plan === "packageA" ? (
                            <div className="confirm-purchase">
                              <div className="confirm-purchase-wrap">
                                <h3 className="confirm-purchase-number">1</h3>
                                <p className="confirm-purchase-subtitle">
                                  Boost
                                </p>
                              </div>
                              <div className="confirm-purchase-wrap-price">
                                <h3 className="confirm-purchase-price">
                                  $5.49 total
                                </h3>
                              </div>
                              {lastFour !== null ? (
                                <div className="confirm-purchase-wrap-paymentinfo">
                                  <p className="confirm-purchase-payment-info">
                                    Card Ending With {lastFour}
                                  </p>
                                </div>
                              ) : null}
                              {lastFour !== null ? (
                                <div
                                  className="confirm-purchase-new-card-wrap"
                                  onClick={this.changePaymentMethodHandler}
                                >
                                  <p className="confirm-purchase-new-card-text">
                                    Use a different card
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <BoostPurchaseOptions
                          updatePlan={this.updatePlanHandler}
                        />
                      )}
                    </div>
                  )}
                </Card.Body>
              )}
            </Card>
          </Modal.Body>
          {this.state.purchaseSuccess ? (
            <Modal.Footer className="swipe-pop-footer">
              <Button
                className="swipe-pop-boost-btn"
                size="lg"
                onClick={this.closeHandler}
              >
                Continue
              </Button>
            </Modal.Footer>
          ) : (
            <Modal.Footer className="swipe-pop-footer">
              {this.state.failedPurchase ? (
                <div className="swipe-pop-footer">
                  <Button
                    className="swipe-pop-update-card-btn"
                    size="lg"
                    onClick={this.changePaymentMethodHandler}
                  >
                    Update Credit Card
                    <i className="fas fa-check-circle confirm-purchase-icon"></i>
                  </Button>

                  <Button
                    className="swipe-pop-close-btn"
                    onClick={this.closeHandler}
                    size="lg"
                    variant="link"
                  >
                    No Thanks
                  </Button>
                </div>
              ) : (
                <div className="swipe-pop-footer">
                  {this.state.confirmationOpen ? (
                    <Button
                      className="swipe-pop-boost-btn"
                      size="lg"
                      onClick={this.confirmPurchaseHandler}
                      disabled={this.state.confirmPurchaseDisabled}
                    >
                      Confirm Purchase
                      <i className="fas fa-check-circle confirm-purchase-icon"></i>
                    </Button>
                  ) : (
                    <Button
                      className="swipe-pop-boost-btn"
                      size="lg"
                      onClick={this.checkoutHandler}
                    >
                      Get Boosts
                    </Button>
                  )}

                  <Button
                    className="swipe-pop-close-btn"
                    onClick={this.closeHandler}
                    size="lg"
                    variant="link"
                  >
                    No Thanks
                  </Button>
                </div>
              )}
            </Modal.Footer>
          )}
        </Modal>
      );
    } else {
      return (
        <Modal size="lg" show={this.state.show} onHide={this.closeHandler}>
          <Modal.Body className="swipe-pop-modal-body-boost">
            <div className="swipe-pop-body-premium-title-wrap">
              <i className="fas fa-gem swipe-pop-premium-icon"></i>
              <span className="swipe-pop-body-premium-title">Go Premium</span>
            </div>
            <div className="swipe-pop-icon">
              <i className="fas fa-bolt swipe-pop-main-icon-bolt"></i>{" "}
            </div>
            <h2 className="swipe-pop-body-title">Boost Your Profile!</h2>
            <div className="swipe-pop-body-countdown-wrapper"></div>
            <p className="swipe-pop-body-subtitle">
              Go Premium and unlock <strong>profile boosts</strong> to get up to
              50x more attention.
            </p>
            <Card className="swipe-pop-features-card">
              <Card.Body className="swipe-pop-features-card-body">
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-bolt swipe-pop-feature-icon-bolt"></i>
                  <p className="swipe-pop-feature-text">Profile Boosts</p>
                </div>
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-heart swipe-pop-feature-icon-heart"></i>
                  <p className="swipe-pop-feature-text">Unlimited Likes</p>
                </div>
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-stars swipe-pop-feature-icon-stars"></i>
                  <p className="swipe-pop-feature-text">Extra Super Likes</p>
                </div>
                <div className="swipe-pop-feature-wrapper">
                  <i className="fas fa-eye swipe-pop-feature-icon-eye"></i>
                  <p className="swipe-pop-feature-text">View Profile Likes</p>
                </div>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer className="swipe-pop-footer">
            <Button
              className="swipe-pop-premium-btn"
              size="lg"
              onClick={this.linkClickHandler}
            >
              Continue
            </Button>
            <Button
              className="swipe-pop-close-btn"
              onClick={this.closeHandler}
              size="lg"
              variant="link"
            >
              No Thanks
            </Button>
          </Modal.Footer>
        </Modal>
      );
    }
  }
}

export default withRouter(BoostPop);
