import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import ReactGA from "react-ga";
import { Container, Button, Card, Alert } from "react-bootstrap";

import HomeNav from "../navbar/HomeNav/HomeNav";
import loginCardBanner from "../../assets/Images/Login Page/Login Card Banner/login-card-banner.jpg";
import { api } from "../../api";

import "./loginform.scss";

export const ReactivateLogin = (props) => {
  const [isReactivated, setIsReactivated] = useState(false);

  const cancelReactivate = () => {
    props.closeReactivateModal();
  };

  const loginNow = () => {
    const data = {
      email: props.email,
      password: props.password,
      oauth: props.oauth,
    };

    return api
      .submitLogin(data)
      .then((res) => {
        if (res.status === 200) {
          var isAdmin = res.data.isAdmin;
          var banned = res.data.banned;
          var deactivate = res.data.deactivate;

          if (banned || deactivate) {
            console.log("Something wrong, please try again later.");
          } else {
            localStorage.setItem("auth", res.data.token);
            ReactGA.event({
              category: "Login/Signup",
              action: "Successful Login",
            });
            ReactGA.set({ userId: props.email });

            if (isAdmin) {
              props.history.push("/app/admin");
            } else {
              props.history.push("/app");
            }
          }
        }
      })
      .catch((err) => {
        ReactGA.event({
          category: "Login/Signup",
          action: "Failed Login",
        });
      });
  };

  const reactivateAccount = () => {
    const data = {
      email: props.email,
    };

    return api
      .reactivateAccount(data)
      .then((res) => {
        if (res.status === 200) {
          setIsReactivated(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="login-section-container">
      <Container className="login-form-container">
        <Card className="login-form-card">
          <Card.Img
            variant="top"
            className="login-form-top-img"
            src={loginCardBanner}
          />
          <Card.Title className="lp-title">
            <span className="lg-login-link">Login</span>
          </Card.Title>
          <Card.Body className="login-form-card-body">
            {isReactivated && (
              <Alert
                variant="success"
                className="login-reactivate-success-alert"
              >
                Your account was successfully reactivated.
              </Alert>
            )}
            <div className="login-reactivate-body">
              <span className="login-reactivate-title">Welcome back.</span>
              <p className="login-reactivate-description">
                Your account was previously deactivated.
              </p>
              <Button
                variant="primary"
                type="submit"
                className="login-form-btn"
                size="lg"
                onClick={
                  isReactivated ? () => loginNow() : () => reactivateAccount()
                }
              >
                {isReactivated ? "Login now" : "Reactivate Account"}
              </Button>
              <div
                className="lp-reactivate-cancel"
                onClick={(e) => cancelReactivate()}
              >
                Cancel
              </div>
            </div>
            <div className="login-below-form-container">
              <p className="lp-signup-text">
                Don't have an account?{" "}
                <Link to="/signup" className="lp-signup-link">
                  Signup Now
                </Link>
              </p>

              <p className="lp-disclaimer-text">
                By continuing you agree to Sugarbae's{" "}
                <Link to="/terms">Terms</Link> and{" "}
                <Link to="/privacy">Privacy </Link>
                Policy. Promoting illegal commercial activities (such as
                escorting or prostitution) is strictly prohibited. If you are an
                ESCORT, DO NOT use this website.{" "}
              </p>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default withRouter(ReactivateLogin);
