import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { v1 as uuid } from "uuid";

import PhotoCard from "./PhotoCard";
import "./photogrid.scss";

const SortableImageContainer = SortableElement((props) => {
  return (
    <PhotoCard
      imgURL={props.image}
      index={props.id}
      editEnable={props.editEnable}
      removeHandler={props.removeHandler}
      uploadHandler={props.uploadHandler}
      showImage={props.showImage}
    />
  );
});

const PhotoContainer = SortableContainer((props) => {
  const sortImages = props.images.map(image => {
    return {
      uuid: uuid(),
      image,
    };
  });
  return (
    <div className="photo-grid-body">
      {sortImages.map((item, index) => (item.image
        ? (
          <SortableImageContainer
            key={item.uuid}
            index={index}
            id={index}
            editEnable={props.editEnable}
            showImage={props.showImage}
            image={item.image}
            removeHandler={props.removeHandler}
            uploadHandler={props.uploadHandler}
          />
        ) : (
          <PhotoCard imgURL={item.image} uploadHandler={props.uploadHandler} />
        )
      ))}
    </div>
  )
})

export default PhotoContainer;
