// API URLs
export const urls = {
  // ! Authentication Route
  SUBMIT_LOGIN: 'auth/login',
  SUBMIT_SIGNUP: 'auth/register',
  SUBMIT_RECAPTCHA: 'auth/recaptcha',
  CHECK_EMAIL: 'auth/checkEmail',
  CHECK_USERNAME: 'auth/checkUsername',
  FETCH_PROTECTED_URL: 'auth/protected',
  FETCH_NAVBAR_DATA: 'auth/navdata',
  PHONE_LOGIN: 'auth/phonelogin',
  PHONE_LOGIN_VERIFY: 'auth/phoneloginverify',
  PHONE_CHECK: 'auth/phonecheck',

  // ! Account Route
  CANCEL_SUBSCRIPTION: 'account/cancelsubscription',
  MANAGE_SUBSCRIPTION: 'account/managesubscription',
  DEACTIVATE_ACCOUNT: 'account/deactivate',
  REACTIVATE_ACCOUNT: 'account/reactivate',
  RESET_PASSWORD: 'account/resetpassword',
  VALIDATE_RESET_TOKEN: 'account/validateresettoken',
  SUBMIT_NEW_PASSWORD: 'account/forgotpasswordnewpassword',
  UPDATE_EMAIL: 'account/updateemail',
  UPDATE_PASSWORD: 'account/updatepassword',
  UPDATE_PHONE_NUMBER: 'account/updatephonenumber',
  FETCH_EMAIL: 'account/email',
  FETCH_PHONE_NUMBER: 'account/phonenumber',
  CONNECT_SOCIAL_NETWORK: 'account/connectsocialnetwork',
  GET_TWITTER_ACCOUNT_INFO: 'account/gettwitteruserinfo',
  UPDATE_IDENTITY: 'account/updateidentity',
  UPDATE_IDKEY: 'account/idkey',
  SAVE_VERIFICATION_VIDEO: 'account/saveverificationvideo',

  // ! Verififcation Route
  GENERATE_EMAIL_TOKEN: 'verify/emailtoken',
  VALIDATE_EMAIL_TOKEN: 'verify/validateemailcode',
  GENERATE_SMS_TOKEN: 'verify/smstoken',
  VALIDATE_SMS_TOKEN: 'verify/validatecode',
  FETCH_VERIFICATION_STATUS: 'verify/status',

  // !  Profile Route
  FETCH_CHILDREN: 'profile/children',
  FETCH_DRINKING: 'profile/drinking',
  FETCH_EDUCATION: 'profile/education',
  FETCH_EXERCISE: 'profile/exercise',
  FETCH_SMOKING: 'profile/smoking',
  UPDATE_LIFESTYLE: 'profile/updatelifestyle',
  UPDATE_LIFESTYLE_FEMALE: 'profile/updatelifestylefemale',
  FETCH_RELATIONSHIP_STATUS: 'profile/relationshipstatus',
  FETCH_PHYSICALS: 'profile/physicals',
  UPDATE_USERNAME: 'profile/updateusername',
  UPDATE_GENDER: 'profile/updategender',
  UPDATE_LOCATION: 'profile/updatelocation',
  UPDATE_PHYSICALS: 'profile/updatePhysicals',
  UPDATE_BIO_HEADLINE: 'profile/updatebioheadline',
  UPDATE_CHILDREN: 'profile/updatechildren',
  UPDATE_SMOKING: 'profile/updatesmoking',
  UPDATE_DRINKING: 'profile/updatedrinking',
  UPDATE_CANNABIS: 'profile/updatecannabis',
  UPDATE_EXERCISE: 'profile/updateexercise',
  UPDATE_BODYTYPE: 'profile/updatebodytype',
  UPDATE_ETHNICITY: 'profile/updateethnicity',
  UPDATE_EDUCATION: 'profile/updateeducation',
  UPDATE_RELATIONSHIP_STATUS: 'profile/updaterelationshipstatus',
  UPDATE_NETWORTH: 'profile/updatenetworth',
  UPDATE_ANNUAL_INCOME: 'profile/updateannualincome',
  UPDATE_HEADLINE: 'profile/updateheadline',
  UPDATE_BIO: 'profile/updatebio',
  UPDATE_BENEFITS: 'profile/updatebenefits',
  UPDATE_PROFILE_HEIGHT: 'profile/updateprofileheight',
  PREMIUM_CHECK: 'profile/premiumcheck',
  FETCH_OWN_USER: 'profile/fetchownuser',
  FETCH_BENEFITS: 'profile/benefits',
  FETCH_BIO: 'profile/fetchbio',
  FETCH_HEADLINE: 'profile/fetchheadline',
  FETCH_PROFILE_INFO: 'profile/fetchprofileinfo',
  FETCH_ACCOUNT_SETTINGS: 'profile/fetchaccountsettings',
  FETCH_PROFILE_PROGRESS: 'profile/profileprogress',
  FETCH_PROFILE_INVENTORY: 'profile/inventory',
  FETCH_PREFERENCESB: 'profile/fetchpreferences',
  FETCH_PREFERENCES: 'profile/getpreferences',
  UPDATE_PREFERENCES: 'profile/updatepreferences',
  UPDATE_HIDDEN: 'profile/updatehidden',
  FETCH_DIAMONDCOUNT: 'profile/diamondcount',
  FETCH_COMPLIMENT_HISTORY: 'profile/complimenthistory',
  UPDATE_BIRTHDATE: 'profile/updatebirthdate',
  UPLOAD_MEDIA: 'profile/uploadMedia',

  // ! Upload Route
  FETCH_IMAGES: 'upload/getimages',
  SAVE_IMAGES: 'upload/saveimages',
  SAVE_PRIVATE_IMAGES: 'upload/saveprivateimages',
  REKOGNITION_IMAGE: 'upload/rekognition',
  REKOGNITION_VIDEO_TEXT: 'upload/videorekognitiontext',
  REKOGNITION_VIDEO_CONTENT: 'upload/videorekognitioncontent',

  // ! Filters Route
  FETCH_FILTERS: 'filters/getfilters',
  UPDATE_FILTERS: 'filters/updatefilters',
  UPDATE_DISTANCE: 'filters/updatedistance',
  UPDATE_ADDRESS: 'filters/updateaddress',
  UPDATE_BASIC_FILTERS: 'filters/updatebasicfilters',
  UPDATE_ADVANCED_FILTERS: 'filters/updateadvancedfilters',
  UPDATE_AGE_FILTER: 'filters/updateagefilter',
  FETCH_ADDRESS_COORDINATES: 'filters/addresscoordinates',

  // video Route
  MAKE_VIDEO_CALL: 'call/makevideocall',
  GET_CALL_TOKEN: 'call/getcalltoken',

  // ! Swipe Route
  FETCH_SWIPE_URL: 'swipe/fetch',
  SWIPE_LIKE: 'swipe/like',
  SWIPE_LIKE_CHECK: 'swipe/likecheck',
  SWIPE_DISLIKE: 'swipe/dislike',
  SWIPE_SUPERLIKE: 'swipe/superlike',
  SWIPE_SUPERLIKE_CHECK: 'swipe/superlikecheck',
  SWIPE_UNDO: 'swipe/undo',
  SWIPE_UNDO_CHECK: 'swipe/undocheck',
  SWIPE_REMOVELIKE: 'swipe/removelike',
  SWIPE_BOOST: 'swipe/boost',
  SWIPE_BOOST_V2: 'swipe/boostV2',
  FETCH_BOOST_DATA: 'swipe/boostdata',

  // SWIPE_NOTE: "swipe/submitnote",
  SUBMIT_NOTE: 'swipe/submitnote',
  SWIPE_MATCH: 'swipe/match',
  FETCH_BOOST: 'swipe/fetchboost',
  BOOST_POP: 'swipe/boostpop',
  NOTE_CHECK: 'swipe/notecheck',
  LIKE_POP: 'swipe/likepop',
  FETCH_NOTE_INVALID_POP: 'swipe/noteinvalidpop',
  FETCH_SEND_NOTE_POP: 'swipe/sendnotepop',
  SUPERLIKE_POP_NOIMAGE: 'swipe/superlikepopnoimage',
  SUPERLIKE_POP: 'swipe/superlikepop',
  SUBMIT_COMPLIMENT: 'swipe/submitcompliment',
  FETCH_COMPLIMENT_IDS: 'swipe/complimentids',

  // ! Grid Route
  FETCH_GRID_NEWEST: 'grid/newest',
  FETCH_GRID_RECENTLY_ACTIVE: 'grid/recentlyactive',
  FETCH_GRID_RECOMMENDED: 'grid/recommended',
  FETCH_SEARCH_GRID: 'grid/searchgrid',
  FETCH_HOME_GRID: 'grid/homegrid',
  FETCH_DESKTOP_GRID: 'grid/desktopgrid',
  FETCH_LANDING_GRID: 'grid/landing',

  // ! Matches Route
  FETCH_LIKES: 'match/likes',
  FETCH_MATCHES: 'match/getmatches',
  FETCH_LIKES_IMAGE: 'match/likesimage',
  FETCH_MATCH_QUEUE: 'match/matchqueue',
  UPDATE_MATCH_QUEUE: 'match/updatematchqueue',
  FETCH_MATCH_INFO: 'match/matchinfo',
  SUBMIT_UNMATCH: 'match/unmatch',
  GET_ONE_USER: 'match/getoneuser',
  GET_UNREAD_MATCHES_COUNTER: 'match/getmatchcounter',
  GET_UNREAD_LIKES_COUNTER: 'match/getlikecounter',
  FETCH_LIKED_USERS: 'match/likedusers',
  CHECK_REQUEST: 'match/checkrequest',
  REQUEST_PRIVATE_PHOTOS: 'match/requestprivatepics',
  GET_REQUEST: 'match/getrequests',
  GET_APPROVED_REQUEST: 'match/getapprovedrequests',
  HANDLE_REQUEST: 'match/handlerequest',

  // ! Chat Route
  FETCH_MESSAGES_URL: 'chat/messages',
  FETCH_DESKTOP_MESSAGES: 'chat/messagesdesktop',
  FETCH_CHAT_QUEUE: 'chat/queue',
  FETCH_CHAT_QUEUE_UPDATE: 'chat/queueupdate',
  FETCH_CHAT_MATCHES: 'chat/matches',
  FETCH_SENT_MESSAGES: 'chat/sent',
  READ_MESSAGES_URL: 'chat/readmessage',
  GET_UNREAD_MESSAGES_COUNTER: 'chat/getmessagecounter',

  // ! Member Route
  FETCH_USER_PROFILE: 'member/profileurl',
  FETCH_USER_BENEFITS: 'member/benefits',
  FETCH_PROFILE: 'member/profile',

  // ! Safety Route
  SUBMIT_REPORT: 'safety/report',
  SUBMIT_BUG_REPORT: 'safety/bugreport',
  SUBMIT_USER_REPORT: 'safety/userreport',
  CLOSE_SAFETY_TIP_URL: 'safety/closesafetytip',

  // ! Purchase Route
  PURCHASE_NOTES: 'payments/purchasenotes',
  PURCHASE_BOOSTS: 'payments/purchaseboosts',
  PURCHASE_SUPERLIKES: 'payments/purchasesuperlikes',
  PURCHASE_CHECK_RISK_IP: 'payments/checkip',
  PURCHASE_DIAMONDS: 'payments/purchasediamonds',

  // ! Admin Route
  RUN_ADMIN_TASK: 'admin/updateschema',
  DISAPPROVE_BANNED_CHATS: 'admin/bannedchats',
  ADMIN_FETCH_CHATS: 'admin/fetchchats',
  ADMIN_REVIEW_CHATS: 'admin/reviewchats',
  FETCH_BOT_MESSAGES: 'admin/botmessages',
  FETCH_BOT_CONVO: 'admin/fetchbotconvo',
  UPDATE_BOT_CHAT: 'admin/updatebotchat',
  FETCH_ADMIN_IMAGES: 'admin/fetchimages',
  ADMIN_APPROVE_CHAT: 'admin/approvechat',
  ADMIN_DECLINE_CHAT: 'admin/declinechat',
  ADMIN_REMOVE_IMAGE: 'admin/removeImage',
  ADMIN_APPROVE_IMAGE: 'admin/approveimage',
  ADMIN_FETCH_REPORTS: 'admin/fetchreports',
  ADMIN_FETCH_BUGS: 'admin/fetchbugs',
  ADMIN_DECLINE_REPORT: 'admin/declinereport',
  SUBMIT_BAN: 'admin/submitban',
  SEND_LIKES: 'admin/sendlikes',
  UPDATE_DATABASE: 'admin/updateDatabase',
  ADMIN_BANNED: 'admin/banned',
  MAKE_PREMIUM: 'admin/makepremium',

  // ! Analytics Route
  ADMIN_CALCULATE_CONVERSIONS: 'analytics/conversiondata',
  FETCH_SIGNUP_DATA: 'analytics/signupdata',
  FETCH_CONVERSION_DATA: 'analytics/conversiondata',
  FETCH_TASK_DATA: 'analytics/tasksdata',
  FETCH_ENGAGEMENT_DATA: 'analytics/engagementdata',
  CREATE_NEW_SESSION: 'session/createsession',
  END_SESSION: 'session/endsession',
  FETCH_CHAT_MONITOR_DATA: 'analytics/chatmonitor',
  FETCH_ACTIVE_USER_DATA: 'analytics/activeuser',
  FETCH_ACTIVE_SUBSCRIPTION: 'analytics/activesub',
};

// Socket events
export const events = {
  // Emitters
  SEND_MESSAGE: 'newmessage',
  READ_MESSAGES: 'readmessages',
  CHECK_MATCH: 'checkmatch',
  MAKE_VIDEO_CALL: 'makevideocall',
  REJECT_VIDEO_CALL: 'rejectvideocall',
  CHECK_LIKE: 'checklike',

  // Listeners
  NEW_MESSAGE: 'newmessage',
  NEW_MATCH: 'newmatch',
  NEW_LIKE: 'newlike',
  READ_MESSAGES_SUCCESS: 'readmessagessuccess',
};
