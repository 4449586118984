import React, { Component } from "react";
import "../modals.scss";
import { Button, Card, Modal, Alert } from "react-bootstrap";
import { UserContext } from "../../../context/UserContext";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { api } from "../../../api";

export class MessageArchiveModal extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      show: true,
      unmatched: false,
    };
  }

  closeHandler = (e) => {
    this.setState({ show: false }, () => {
      this.props.closeHandler();
    });
  };

  unmatch = async (e) => {
    const data = {
      userid: this.context.user._id,
      cardid: this.props.unmatchId,
    };

    return await api
      .submitUnmatch(data)
      .then((res) => {
        this.context.updateMessageData(this.props.unmatchId, "noMessage", "unmatch");
        this.setState({ unmatched: true}, () => {
          setTimeout(() => {
            this.props.submitUnmatch();
          }, 3000);
        });
      })
      .catch((err) => {});
  };

  render() {
    const path = `/app/unmatchedreport/${this.props.unmatchId}`;
    return (
      <Modal
        size="lg"
        show={this.state.show}
        onHide={this.closeHandler}
        className="swipe-pop-modal"
      >
        <Modal.Body className="archive-modal-body">
          <div className="swipe-pop-modal-archive-body-note">
            <h2 className="swipe-archive-body-title">Everything OK?</h2>
            <p className="swipe-archive-body-description">
              Help us keep Sugarbae safe by telling us if there's a reason why
              you've unmatched.
            </p>
            <p className="swipe-archive-note">
              Don't worry, this is anonymous.
            </p>
          </div>
          {this.state.unmatched && (
            <Alert variant="success" className="unmatch-success">
              Successfully Unmatched.
            </Alert>
          )}
          <Card className="swipe-pop-features-card">
            <Card.Body className="swipe-pop-features-card-body-archive">
              <div className="archive-select-option">
                <i className="fas fa-frown archive-option-icon"></i>
                <Link to={path}>
                  <p className="archive-option-title">Made me uncomfortable</p>
                </Link>
              </div>
              <div className="archive-select-option">
                <i className="fas fa-comments archive-option-icon"></i>
                <Link to={path}>
                  <p className="archive-option-title">Abusive or threatening</p>
                </Link>
              </div>
              <div className="archive-select-option">
                <i className="fas fa-user-times archive-option-icon"></i>
                <Link to={path}>
                  <p className="archive-option-title">Inappropriate content</p>
                </Link>
              </div>
              <div className="archive-select-option">
                <i className="fas fa-exclamation-triangle archive-option-icon"></i>
                <Link to={path}>
                  <p className="archive-option-title">Spam or scam</p>
                </Link>
              </div>
              <div className="archive-select-option">
                <i className="fas fa-camera archive-option-icon"></i>
                <Link to={path}>
                  <p className="archive-option-title">Stolen photo</p>
                </Link>
              </div>
              <div className="archive-select-option">
                <div className="archive-under-age">
                  <p className="archive-age">+18</p>
                </div>
                <Link to={path}>
                  <p className="archive-option-title">User is under 18</p>
                </Link>
              </div>
              <div
                className="archive-select-last-option"
                onClick={(e) => this.unmatch()}
              >
                <p className="archive-option-title">I'm just not interested</p>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer className="swipe-pop-footer">
          <Button
            className="swipe-pop-close-btn"
            onClick={this.closeHandler}
            variant="link"
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(MessageArchiveModal);
