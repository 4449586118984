import React, { useContext, useState } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Image as BootstrapImage, Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { UserContext } from '../../../../context/UserContext';
import logo from '../../../../assets/logos/icon-only-white.png';
import Media from '../../../../components/media/Media';

function DesktopMatchedPopup(props) {
  const context = useContext(UserContext);

  const { card } = props;
  const closeHandler = (e) => {
    props.closeMatchHandler();
  };

  const images = card.imgurl;
  let mainImage = null;
  if (images) {
    mainImage = images[0];
  }
  let mainUserImage = null;
  if (context.user.imgurl) {
    mainUserImage = context.user.imgurl[0];
  }
  let messagesLink = `messages/${card._id}`;

  if (props.location.pathname === '/app') {
    messagesLink = `app/messages/${card._id}`;
  }

  return (
    <div className="desktop-v2-filters">
      <div className="desktop-v2-filters-container">
        <div
          className="desktop-v2-filters-popup"
          style={{ position: 'relative' }}
        >
          <div
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              padding: '1rem',
              zIndex: 15,
              color: '#fff',
              cursor: 'pointer',
            }}
            onClick={closeHandler}
          >
            <i
              className="far fa-times"
              style={{ color: '#fff', fontSize: '18px' }}
            />
          </div>
          <div className="match-v2-innerwrap">
            <div className="match-v2-image-overlay" />
            <div className="match-v2-content-container">
              <div className="match-v2-content-section">
                <div className="match-v2-content-innerwrap">
                  <div className="match-v2-match-images-wrapper">
                    <div className="match-v2-image-card-wrapper-left">
                      <Media image={mainImage} />
                      {/* <Image
                        publicId={
                          mainImage
                            ? getImgName(mainImage)
                            : "larger-placeholder-user-image_vvdghq.png"
                        }
                        version={
                          mainImage ? getImgVersion(mainImage) : "1590046357"
                        }
                        resource_type={
                          mainImage
                            ? checkGIF(mainImage)
                              ? "video"
                              : "image"
                            : null
                        }
                        effect="loop"
                        cloud_name="sugarbae"
                        className="match-v2-image-card"
                        fluid
                      >
                        <Transformation
                          height="200"
                          video_sampling="20"
                          delay="250"
                          duration="10"
                          effect="loop"
                          quality="auto"
                          flags="lossy"
                        />
                      </Image> */}
                    </div>
                    <div className="match-v2-logo-wrapper">
                      <BootstrapImage src={logo} className="match-v2-logo" />
                    </div>
                    <div className="match-v2-image-card-wrapper-right">
                      <Media image={mainUserImage} />
                      {/* <Image
                        publicId={
                          mainUserImage
                            ? getImgName(mainUserImage)
                            : 'larger-placeholder-user-image_vvdghq.png'
                        }
                        version={
                          mainUserImage
                            ? getImgVersion(mainUserImage)
                            : '1590046357'
                        }
                        resource_type={
                          mainUserImage
                            ? checkGIF(mainUserImage)
                              ? 'video'
                              : 'image'
                            : null
                        }
                        effect="loop"
                        cloud_name="sugarbae"
                        className="match-v2-image-card"
                        fluid
                      >
                        <Transformation
                          height="200"
                          video_sampling="20"
                          delay="250"
                          duration="10"
                          effect="loop"
                          quality="auto"
                          flags="lossy"
                        />
                      </Image> */}
                    </div>
                  </div>
                  <div className="match-v2-title-wrapper">
                    <h1 className="match-v2-title">It's a match!</h1>
                    <p className="match-v2-subtitle">
                      {card.username} likes you too!
                    </p>
                  </div>
                </div>
              </div>
              <div className="match-v2-content-footer">
                <Link to={messagesLink} style={{ width: '100%' }}>
                  <Button className="match-v2-send-message-button">
                    Send a quick "Hello"
                  </Button>
                </Link>

                <Button className="match-v2-other-tips-button">
                  Other Tips
                </Button>
              </div>
            </div>

            <Media image={mainImage} />

            {/* <Image
              publicId={
                mainImage
                  ? getImgName(mainImage)
                  : 'larger-placeholder-user-image_vvdghq.png'
              }
              version={mainImage ? getImgVersion(mainImage) : '1590046357'}
              resource_type={
                mainImage ? (checkGIF(mainImage) ? 'video' : 'image') : null
              }
              effect="loop"
              cloud_name="sugarbae"
              className="match-background-image"
              fluid
            >
              <Transformation
                height="200"
                video_sampling="20"
                delay="250"
                duration="10"
                effect="loop"
                quality="auto"
                flags="lossy"
              />
            </Image> */}
          </div>
        </div>

        <div className="desktop-v2-filters-overlay" onClick={closeHandler} />
      </div>
    </div>
  );
}

export default withRouter(DesktopMatchedPopup);
