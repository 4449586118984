import React, { useEffect, useState, useContext } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import BottomScrollListener from "react-bottom-scroll-listener";
import ClipLoader from "react-spinners/ClipLoader";
import { UserContext } from "../../../../context/UserContext";
import "./searchgridv2.scss";
import SearchUserCardV2 from "../searchUserCardV2/SearchUserCardV2";
import { Button } from "react-bootstrap";

function SearchGridV2(props) {
  const context = useContext(UserContext);

  var noResults = false;
  if (context.searches.length === 0) {
    noResults = true;
  }

  const toggleProfile = (e) => {
    props.toggleProfile(e);
  };

  return (
    <div className="search-grid-v2">
      <div className="search-grid-v2-content-container">
        {noResults ? (
          <div className="search-grid-v2-no-results-container"></div>
        ) : (
          context.searches.map((user, index) => {
            return (
              <SearchUserCardV2
                user={user}
                index={index}
                onClick={toggleProfile}
                finishedScrolling={props.finishedScrolling}
              />
            );
          })
        )}
        {props.scrollLoading && (
          <div className="search-scroll-loader-wrapper">
            <ClipLoader
              loading={props.scrollLoading}
              size={14}
              color="#b5b5b5"
            />
          </div>
        )}
        {context.endSearches && (
          <div className="max-users-wrap">
            <h4>End of users. Adjust your search filters to see more users.</h4>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchGridV2;
