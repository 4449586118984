import React from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Badge } from 'react-bootstrap';
import moment from 'moment';

import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../../utils/getCloudinaryInfo';
import { api } from '../../../../../api';

import '../../../admincomponents.scss';
import Media from '../../../../../components/media/Media';

export const ChatCard = (props) => {
  const {
    message,
    senderUser,
    receiverUser,
    approved,
    reviewed,
    createdAt,
  } = props.chat;
  const senderImage =
    senderUser.imgurl && senderUser.imgurl.length > 0
      ? senderUser.imgurl[0]
      : null;
  const receiverImage =
    receiverUser.imgurl && receiverUser.imgurl.length > 0
      ? receiverUser.imgurl[0]
      : null;

  const showConversation = () => {
    props.showConversationHandler(props.index);
  };

  const showInfo = () => {
    props.showChatInfo(props.index);
  };

  const approveHandler = () => {
    const data = {
      chatid: props.chat._id,
    };

    return api
      .approveChat(data)
      .then(() => {
        props.updateStatus(props.index, 'approved');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const declineHandler = () => {
    const data = {
      chatid: props.chat._id,
    };

    return api
      .declineChat(data)
      .then(() => {
        props.updateStatus(props.index, 'declined');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkPeriod = () => {
    const difference = moment(
      moment.duration(moment().diff(moment(createdAt)))
    );
    const { days, hours, minutes } = difference._i._data;

    if (days !== 0) {
      return `${days} days`;
    } else if (hours !== 0) {
      return `${hours} hours`;
    } else if (minutes !== 0) {
      return `${minutes} mins`;
    } else {
      return 'Just now';
    }
  };

  const status = !reviewed ? 'UnderReview' : approved ? 'Approved' : 'Declined';

  return (
    <div className="chat-card-admin">
      <div className="chat-card-header-admin">
        <div>
          <Media image={senderImage} />

          {/* <Image
            publicId={
              senderImage
                ? getImgName(senderImage) 
                : "placeholder-user-image-thumbnail_vankru.png"
            }
            version={senderImage ? getImgVersion(senderImage) : "1590045309"}
            resource_type={
              senderImage ? (checkGIF(senderImage) ? "video" : "image") : null
            }
            cloud_name="sugarbae"
            className="chat-card-sender-image"
          >
            <Transformation
              height="200"
              video_sampling="20"
              delay="250"
              duration="10"
              effect="loop"
              quality="auto"
              flags="lossy"
            />
          </Image> */}
          {/* <Image
            publicId={
              receiverImage
                ? getImgName(receiverImage)
                : 'placeholder-user-image-thumbnail_vankru.png'
            }
            version={
              receiverImage ? getImgVersion(receiverImage) : '1590045309'
            }
            resource_type={
              receiverImage
                ? checkGIF(receiverImage)
                  ? 'video'
                  : 'image'
                : null
            }
            cloud_name="sugarbae"
            className="chat-card-receiver-image"
          >
            <Transformation
              height="200"
              video_sampling="20"
              delay="250"
              duration="10"
              effect="loop"
              quality="auto"
              flags="lossy"
            />
          </Image> */}

          <Media image={receiverImage} />
        </div>
        <div className="chat-card-userinfo">
          <label className="chat-card-from-to">{`${senderUser.username} to ${receiverUser.username}`}</label>
          <br />
          <label>{checkPeriod()}</label>
        </div>
        <Badge
          pill
          variant={
            status === 'Approved'
              ? 'success'
              : status === 'Declined'
              ? 'danger'
              : 'warning'
          }
          className="chat-card-badge"
        >
          {status}
        </Badge>
        <div className="participant-info" onClick={() => showInfo()}>
          <span className="icon icon-info"></span>
        </div>
      </div>
      <div className="chat-card-body-admin">{message}</div>
      <div className="chatcard-footer">
        <div className="chat-card-button" onClick={() => showConversation()}>
          <span className="icon icon-coversation"></span>
          <label className="chat-card-button-title">Coversation</label>
        </div>
        <div className="chat-card-button" onClick={() => declineHandler()}>
          <span className="icon icon-trash"></span>
          <label className="chat-card-button-title">Decline</label>
        </div>
        <div className="chat-card-button" onClick={() => approveHandler()}>
          <span className="icon icon-apply-filter"></span>
          <label className="chat-card-button-title">Approve</label>
        </div>
      </div>
    </div>
  );
};

export default ChatCard;
