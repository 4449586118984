import React, { useState, useContext, useEffect, useRef } from "react";
import { Button, Card, ProgressBar, Alert } from "react-bootstrap";
import { arrayMove } from "react-sortable-hoc";
import Axios from "axios";
import S3 from "react-aws-s3";
import BounceLoader from "react-spinners/BounceLoader";

import { UserContext } from "../../../../../context/UserContext";
import { api } from "../../../../../api";
import EditPhoto from "./EditPhoto";
import RequestsManager from "./RequestsManager";
import PeoplesManager from "./PeoplesManager";
import PhotoContainer from "../../editProfileV2Components/photoGrid/PhotoContainer";

import "./editgallery.scss";
import { useFormControl } from "@material-ui/core";
import PhotoGridV2 from "../../../../photoGrid/PhotoGridV2";

var imageTypes = [
  "image/webp",
  "image/heif",
  "image/heic",
  "image/jpg",
  "image/jpe",
  "image/jpeg",
  "image/png",
  "image/pdf",
  "image/jp2",
  "image/wdp",
  "image/jxr",
  "image/hdp",
  "image/svg",
  "image/tif",
  "image/tiff",
];

var videoTypes = [
  "image/gif",
  "video/flv",
  "video/avi",
  "video/m3u8",
  "video/ts",
  "video/m2ts",
  "video/mts",
  "video/mov",
  "video/mkv",
  "video/mp4",
  "video/mpeg",
  "video/mpd",
  "video/webm",
  "video/wmv",
  "video/x-matroska",
  "video/quicktime",
];

const config = {
  bucketName: process.env.REACT_APP_AWS_S3_BUCKET,
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEYID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESSKEY,
};

const ReactS3Client = new S3(config);

const EditGallery = (props) => {
  const context = useContext(UserContext);
  const [tab, setTab] = useState("public");
  const [images, setImages] = useState(context.user.imgurl);
  const [privateImages, setPrivateImages] = useState(
    context.user.privatePhotos ? context.user.privatePhotos : []
  );

  return (
    <div>
      <div className="edit-profile-v2-gallery-container">
        <div className="edit-profile-v2-gallery-header">
          <div
            className="edit-profile-v2-gallery-header-section-left"
            onClick={() => props.closeHandler()}
          >
            <i className="fas fa-chevron-left"></i>
          </div>
          <div className="edit-profile-v2-header-section-middle">
            <h1 className="edit-profile-v2-header-title">Manage Gallery</h1>
          </div>
        </div>

        <div className="edit-gallery-content">
          <PhotoGridV2
            profileUser={context.user}
            ownProfile={true}
            gallery={true}
          />
        </div>
      </div>
    </div>
  );
};

export default EditGallery;
