import React, { Component } from "react";
import "./photogrid.scss";
import { Button, Card, Image } from "react-bootstrap";

export class PhotoCard extends Component {
  removeHandler = (e, imgURL) => {
    e.stopPropagation();
    this.props.removeHandler(imgURL);
  };

  uploadHandler = () => {
    this.props.uploadHandler();
  };

  render() {
    const { imgURL } = this.props;
    return (
      <Card className="photo-upload-card">
        {imgURL ? (
          <Image
            className="photo-upload-image"
            src={imgURL}
          />
        ) : null}

        {imgURL ? this.props.editEnable ? (
          <Button
            className="photo-view-btn"
            onClick={(e) => this.props.showImage(this.props.index)}
          >
            <i className="far fa-edit photo-upload-icon" />
          </Button>
        ) : null : (
          <Button
            className="photo-upload-btn"
            onClick={this.uploadHandler}
          >
            <i className="far fa-plus-square photo-upload-icon" />
          </Button>
        )}
      </Card>
    )
  }
};

export default PhotoCard;