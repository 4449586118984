import React, { Component } from "react";
import "../welcome.scss";
import { Button, Image, Form } from "react-bootstrap";
import { UserContext } from "../../../context/UserContext";
import Axios from "axios";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { withRouter } from "react-router-dom";
import { api } from "../../../api";

export class Physicals extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      disabledBtn: false,
      height: null,
      bodytype: null,
      ethnicity: null,
      bodytypeInvalid: false,
      heightInvalid: false,
      ethnicityInvalid: false,
    };
  }

  componentWillMount() {
    this.fetchPhysicals();
  }

  fetchPhysicals = async (e) => {
    const data = {
      userid: this.context.user._id,
    };

    return api
      .fetchPhysicals(data)
      .then((res) => {
        var height = res.data.height;
        var bodytype = res.data.bodytype;
        var ethnicity = res.data.ethnicity;

        this.setState({
          height: height,
          bodytype: bodytype,
          ethnicity: ethnicity,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    var heightValid = false;
    var bodytypeValid = false;
    var ethnicityValid = false;

    if (this.state.height === null) {
      this.setState({ heightInvalid: true });
    } else {
      heightValid = true;
    }
    if (this.state.bodytype === null) {
      this.setState({ bodytypeInvalid: true });
    } else {
      bodytypeValid = true;
    }

    if (this.state.ethnicity === null) {
      this.setState({ ethnicityInvalid: true });
    } else {
      ethnicityValid = true;
    }

    if (heightValid && bodytypeValid && ethnicityValid) {
      this.setState({ disabledBtn: true }, async (e) => {
        const data = {
          userid: this.context.user._id,
          height: this.state.height,
          bodytype: this.state.bodytype,
          ethnicity: this.state.ethnicity,
        };

        return api
          .updatePhysicals(data)
          .then((res) => {
            if (res.status === 200) {
              this.props.history.push("/app/welcome/lifestyle");
            }
          })
          .catch((err) => {
            this.setState({ error: true, disabledBtn: false });
          });
      });
    }
  };

  render() {
    return (
      <div className="welcome-component-container">
        <ProgressBar animated now={62.5} className="welcome-progress-bar" />

        <div className="welcome-content-wrapper">
          <div className="welcome-title-wrap">
            <i className="far fa-walking welcome-icon"></i>

            <h1 className="welcome-title">Your Physical Details</h1>
            <p className="welcome-subtitle">
              Enter some basic info so other users know what you look like.
            </p>
          </div>

          <Form className="welcome-form">
            <Form.Group>
              <Form.Label className="welcome-form-label">
                What is your height?
              </Form.Label>
              <Form.Control
                as="select"
                value={this.state.height}
                onChange={this.changeHandler}
                name="height"
                isInvalid={this.state.heightInvalid}
              >
                <option value="" disabled selected hidden>
                  Select Your Height
                </option>
                <option>4' 0"</option>
                <option>4' 1"</option>
                <option>4' 2"</option>
                <option>4' 3"</option>
                <option>4' 4"</option>
                <option>4' 5"</option>
                <option>4' 6"</option>
                <option>4' 7"</option>
                <option>4' 8"</option>
                <option>4' 9"</option>
                <option>4' 10"</option>
                <option>4' 11"</option>
                <option>5' 0"</option>
                <option>5' 1"</option>
                <option>5' 2"</option>
                <option>5' 3"</option>
                <option>5' 4"</option>
                <option>5' 5"</option>
                <option>5' 6"</option>
                <option>5' 7"</option>
                <option>5' 8"</option>
                <option>5' 9"</option>
                <option>5' 10"</option>
                <option>5' 11"</option>
                <option>6' 1"</option>
                <option>6' 2"</option>
                <option>6' 3"</option>
                <option>6' 4"</option>
                <option>6' 5"</option>
                <option>6' 6"</option>
                <option>6' 7"</option>
                <option>6' 8"</option>
                <option>6' 9"</option>
                <option>6' 10"</option>
                <option>6' 11"</option>
                <option>7' 0"</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label className="welcome-form-label">
                What is your bodytype?
              </Form.Label>
              <Form.Control
                as="select"
                value={this.state.bodytype}
                onChange={this.changeHandler}
                name="bodytype"
                isInvalid={this.state.bodytypeInvalid}
              >
                <option value="" disabled selected hidden>
                  Select Your Body Type
                </option>
                <option>Slim</option>
                <option>Fit</option>
                <option>Average</option>
                <option>Curvy</option>
                <option>A Few Extra Pounds</option>
                <option>Full / Overweight</option>
                <option>Other</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label className="welcome-form-label">
                What is your ethnicity?
              </Form.Label>
              <Form.Control
                as="select"
                value={this.state.ethnicity}
                onChange={this.changeHandler}
                name="ethnicity"
                isInvalid={this.state.ethnicityInvalid}
              >
                <option value="" disabled selected hidden>
                  Select Your Ethnicity
                </option>
                <option>Asian</option>
                <option>Black / African Decent</option>
                <option>Latin / Hispanic</option>
                <option>East Indian</option>
                <option>Middle Eastern</option>
                <option>Mixed</option>
                <option>Native American</option>
                <option>Pacific Islander</option>
                <option>White / Caucasian</option>
                <option>Other</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </div>
        <div className="welcome-btn-wrap">
          <Button
            variant="outline-primary"
            className="welcome-btn-advance"
            onClick={this.submitHandler}
            disabled={this.state.disabledBtn}
          >
            Next
          </Button>
          <Link to="/app/welcome/lifestyle" className="welcome-skip-link">
            Skip
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(Physicals);
