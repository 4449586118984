import React, { Component } from "react";
import "./aboutpage.scss";
import HomeNav from "../../../components/navbar/HomeNav/HomeNav";
import HomeFooter from "../../../components/footers/homeFooter/HomeFooter";
import NumberOneCta from "../../../components/ctas/numberOneCta/NumberOneCta";
import { Container, Jumbotron, Card, Image } from "react-bootstrap";
import bannerImageAbout from "../../../assets/Images/about-page/banner-image-about-page.jpg";
import MetaTags from "react-meta-tags";

export class AboutPage extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <MetaTags>
          <title>About Us | The #1 Arrangement Dating App | Sugarbae.com</title>{" "}
          <meta
            name="description"
            content="Sugarbae is the world's most popular Arranagement dating website and app. With over 1 million active users, find a Successful Member or Attractive Member in your area today."
          />
        </MetaTags>
        <HomeNav />
        <div className="about-hero-section">
          <Container>
            <div className="about-hero-content">
              <h1 className="about-hero-title">About Sugarbae</h1>
              <h2 className="about-hero-subtitle">
                The #1 Arrangement Dating App
              </h2>
            </div>
            <div className="about-hero-image-wrap">
              <Image />
            </div>
          </Container>
        </div>
        <div className="about-description-section">
          <Container className="about-description-container">
            <Card className="about-description-image-wrap">
              <Image src={bannerImageAbout} fluid rounded />
            </Card>
            <Card className="about-description-card">
              <Card.Header>
                <Card.Title className="about-description-title">
                  Relationships on Your Terms
                </Card.Title>
              </Card.Header>
              <Card.Body className="about-description-content">
                The #1 Arrangement Dating App. Where Attractive Members enjoy a
                life of luxury by being pampered with fine dinners, exotic
                trips, and allowances.
              </Card.Body>
            </Card>
          </Container>
        </div>
        <div className="about-stats-section">
          <Container className="about-stats-container">
            <Card className="as-card-left">
              <Card.Title className="as-card-title">1MM+</Card.Title>
              <Card.Body className="as-card-subtitle">
                Active Members Online
              </Card.Body>
            </Card>
            <Card className="as-card">
              <Card.Title className="as-card-title">#1</Card.Title>
              <Card.Body className="as-card-subtitle">
                Arrangement Dating App
              </Card.Body>
            </Card>
            <Card className="as-card-right">
              <Card.Title className="as-card-title">139+</Card.Title>
              <Card.Body className="as-card-subtitle">
                Countries Using Sugarbae
              </Card.Body>
            </Card>
          </Container>
        </div>
        <div className="our-mission-section">
          <Container className="our-mission-container">
            <Card className="our-mission-card">
              <Card.Title className="our-mission-card-title">
                Our Mission
              </Card.Title>
              <Card.Body className="our-mission-card-body">
                <div className="om-card-body-innerwrap">
                  <p>
                    An arrangement is where people are direct with one another
                    and stop wasting time. It allows people to immediately
                    define what they need and want in a relationship. Our
                    profiles allow members to effortlessly state their
                    expectations. This is what we like to call Relationships on
                    Your Terms.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
        <NumberOneCta />

        <HomeFooter />
      </div>
    );
  }
}

export default AboutPage;
