import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./filtersliderstyles.scss";
import Slider from "@material-ui/core/Slider";

function Cannabis(props) {
  var filters = props.filters;
  const [cannabis, setCannabis] = useState(filters.cannabis);

  const changeHandler = (e) => {
    var cannabisCopy = cannabis;

    if (e.target.value === "nonsmoker") {
      if (cannabisCopy.nonsmoker) {
        cannabisCopy.nonsmoker = false;
      } else {
        cannabisCopy.nonsmoker = true;
      }
    } else if (e.target.value === "lightsmoker") {
      if (cannabisCopy.lightsmoker) {
        cannabisCopy.lightsmoker = false;
      } else {
        cannabisCopy.lightsmoker = true;
      }
    } else if (e.target.value === "heavysmoker") {
      if (cannabisCopy.heavysmoker) {
        cannabisCopy.heavysmoker = false;
      } else {
        cannabisCopy.heavysmoker = true;
      }
    }

    setCannabis((prevState) => {
      return { ...prevState, ...cannabisCopy };
    });
  };

  const saveHandler = (e) => {
    props.updateHandler(cannabis);
  };

  return (
    <div className="filter-slider-component">
      <div className="filter-slider-overlay" onClick={props.onClose} />
      <div className="filter-slider-content">
        <div className="filter-slider-title-wrap">
          <i className="far fa-heart filter-sort-icon"></i>
          <h2 className="filter-slider-title">Cannabis</h2>
        </div>
        <div className="filter-slider-wrapper">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Non Smoker"}
                type="checkbox"
                value="nonsmoker"
                checked={cannabis.nonsmoker}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Light Smoker"}
                type="checkbox"
                value="lightsmoker"
                checked={cannabis.lightsmoker}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label={"Heavy Smoker"}
                type="checkbox"
                value="heavysmoker"
                checked={cannabis.heavysmoker}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="filter-save-button-wrapper">
          <Button className="filter-save-button" onClick={saveHandler}>
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Cannabis;
