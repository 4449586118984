import React from "react";
import { Button, Image } from "react-bootstrap";

import MatchCounter from "../navbar/navComponents/counters/MatchCounter";

import "./headerstyles.scss";
import logo from "../../assets/logos/sugarbae-home-icon.png";
import { Link } from "react-router-dom";

function SearchHeader(props) {
  const toggleFilters = (e) => {
    props.toggleFilters();
  };

  return (
    <div className="header-container">
      <div className="header-section-left">
        <Link to="/app/matches">
          <Button className="header-button">
            <i className="far fa-heart header-heart-icon">
              <MatchCounter />
            </i>
          </Button>
        </Link>
      </div>
      <div className="header-section-center">
        <Image src={logo} className="header-logo" />
      </div>
      <div className="header-section-right">
        <Button className="header-button">
          <i
            className="far fa-filter header-filter-icon"
            onClick={toggleFilters}
          ></i>
        </Button>
      </div>
    </div>
  );
}

export default SearchHeader;
