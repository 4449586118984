import React, { useEffect, useState, useContext } from 'react';
import './matchqueue.scss';
import { Image, Transformation } from 'cloudinary-react';
import LikesImage from '../likesImage/LikesImage';
import { UserContext } from '../../../../context/UserContext';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { api } from '../../../../api';
import matchIcon from '../../../../assets/Icons/match-white.png';
import { Image as BootstrapImage } from 'react-bootstrap';
import Media from '../../../../components/media/Media';

export const MatchQueue = (props) => {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [fetching, setFecthing] = useState(true);

  useEffect(() => {
    if (context.matchData.length !== 0) {
      props.matchCount(context.matchesCount);
      setFecthing(false);
    } else {
      props.matchCount(0);
      setFecthing(false);
    }
  }, [context.matchData]);

  const scrollHandle = async (e) => {
    const scrollWidth = document.getElementById('match-queue').scrollWidth;
    const offsetWidth = document.getElementById('match-queue').offsetWidth;
    const scrollLeft = document.getElementById('match-queue').scrollLeft;

    if (
      scrollWidth - scrollLeft < offsetWidth * 3 &&
      !context.endMatches &&
      !fetching
    ) {
      setFecthing(true);
      context.getMoreMatches();
    }
  };

  const vhh = window.innerHeight;

  const circleHeight = '35px';
  const circleWidth = circleHeight;

  const circleStyle = {
    height: circleHeight,
    width: circleWidth,
  };

  return (
    <div
      className="match-queue-container"
      id="match-queue"
      onScroll={scrollHandle}
    >
      <div className="match-queue-innerwrap">
        {context.matchData.length > 0 ? (
          <div className="match-queue-innerwrap">
            {context.matchData.map((match) => {
              var notification = match.isRead;
              if (match.user.imgurl.length > 0) {
                var image = match.user.imgurl[0];
                var imgurl = image;
              } else {
                imgurl = null;
              }
              return (
                <div
                  key={match._id}
                  className="match-queue-image-wrapper"
                  onClick={() => props.chatToggle(match.user._id)}
                >
                  <div className="match-indicator-v2">
                    <BootstrapImage
                      src={matchIcon}
                      fluid
                      className="match-indicator-icon"
                    />
                  </div>
                  <Media image={imgurl} />
                  {/* <Image
                    publicId={
                      imgurl
                        ? getImgName(imgurl)
                        : "placeholder-user-image-thumbnail_vankru.png"
                    }
                    version={imgurl ? getImgVersion(imgurl) : "1590045309"}
                    resource_type={
                      imgurl ? (checkGIF(imgurl) ? "video" : "image") : null
                    }
                    cloud_name="sugarbae"
                    className="match-image"
                    fluid
                  >
                    <Transformation
                      height="200"
                      video_sampling="20"
                      delay="250"
                      duration="10"
                      effect="loop"
                      quality="auto"
                      flags="lossy"
                    />
                  </Image> */}
                </div>
              );
            })}
            {!context.endMatches && (
              <div className="reached-arrow">
                <i className="fas fa-angle-right"></i>
              </div>
            )}
          </div>
        ) : (
          <div className="match-queue-innerwrap">
            <div className="match-queue-placeholder" style={circleStyle} />
            <div className="match-queue-placeholder" style={circleStyle} />
            <div className="match-queue-placeholder" style={circleStyle} />
            <div className="match-queue-placeholder" style={circleStyle} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MatchQueue;
