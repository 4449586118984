import React, { useState, useContext, useEffect } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { withRouter, useHistory } from 'react-router-dom';
import './wallet.scss';
import './wallethistory.scss';
import diamondIcon from '../../assets/Icons/diamonds_multiple.svg';
import diamondFlatIcon from '../../assets/Icons/diamond-shape.svg';
import PurchaseDiamonds from './PurchaseDiamonds';
import { UserContext } from '../../context/UserContext';
import { api } from '../../api';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../utils/getCloudinaryInfo';
import { Image as BootstrapImage } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import BeatLoader from 'react-spinners/BeatLoader';
import complimentIcon from '../../assets/Images/compliment/gifticon.png';
import Media from '../../components/media/Media';

function WalletHistory(props) {
  const context = useContext(UserContext);
  const [complimentHistory, setComplimentHistory] = useState([]);
  const [sortBy, setSortBy] = useState({
    all: true,
    count: false,
  });
  const [loading, setLoading] = useState(true);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [maxReached, setMaxReached] = useState(false);
  let history = useHistory();

  useEffect(() => {
    fetchData();
  }, [sortBy]);

  useEffect(() => {
    if (!loading && !scrollLoading && !maxReached) {
      setScrollLoading(true);
      fetchData();
    }
  }, [props.scrollTrigger]);

  const fetchData = async (e) => {
    const data = {
      sortBy: sortBy,
      skip: complimentHistory.length,
    };

    return api
      .fetchComplimentHistory(data)
      .then((res) => {
        if (res.status == 200) {
          let tempComplimentHistory = complimentHistory;
          let receivedComplimentHistory = res.data.complimentHistory;

          let newComplimentHistory = tempComplimentHistory.concat(
            receivedComplimentHistory
          );

          setComplimentHistory(newComplimentHistory);
          if (receivedComplimentHistory.length < 10) {
            setMaxReached(true);
          }
          setLoading(false);
          setScrollLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleSortBy = (props) => (e) => {
    var tempSortBy = sortBy;

    if (props === 'all') {
      tempSortBy = {
        all: true,
        count: false,
      };
    } else if (props === 'count') {
      tempSortBy = {
        all: false,
        count: true,
      };
    }

    setComplimentHistory([]);
    setMaxReached(false);
    setScrollLoading(false);
    setLoading(true);
    setSortBy(tempSortBy);
  };

  let sortByText = '';
  if (sortBy.all) {
    sortByText = 'All History';
  } else if (sortBy.count) {
    sortByText = 'Diamond Count';
  }

  return (
    <div className="wallet-history-component">
      <div className="wallet-dropdown-wrap">
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div className="wallet-dropdown-toggle-title">{sortByText}</div>
            <div className="wallet-dropdown-toggle-icon">
              <i className="far fa-chevron-down"></i>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="wallet-dropdown-menu">
            <Dropdown.Item onClick={toggleSortBy('all')}>
              <div className="wallet-dropdown-item-icon-wrap"></div>
              <div className="wallet-dropdown-item-title">
                <span className="icon icon-message-sort-all"></span>
                All History
              </div>
            </Dropdown.Item>
            <Dropdown.Item onClick={toggleSortBy('count')}>
              <div className="wallet-dropdown-item-icon-wrap"></div>
              <div className="wallet-dropdown-item-title">
                <span className="icon icon-message-sort-matches"></span>
                Diamond Count
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {loading ? (
        <div className="wallet-history-feed-loading">
          <ClipLoader loading={loading} color="#b5b5b5" size={14} />
        </div>
      ) : (
        <>
          {complimentHistory.length > 0 ? (
            <div className="wallet-history-feed">
              {complimentHistory.map((compliment, index) => {
                var mainImage = null;
                var images = compliment.receiverUser.imgurl;
                if (images) {
                  if (images.length > 0) {
                    mainImage = images[0];
                  }
                }

                return (
                  <div className="compliment-history-item" index={index}>
                    <div className="compliment-history-item-user-info">
                      <Media image={mainImage} />
                      {/* <Image
                        publicId={
                          mainImage
                            ? getImgName(mainImage)
                            : "larger-placeholder-user-image_vvdghq.png"
                        }
                        version={
                          mainImage ? getImgVersion(mainImage) : "1590046357"
                        }
                        resource_type={
                          mainImage
                            ? checkGIF(mainImage)
                              ? "video"
                              : "image"
                            : null
                        }
                        effect="loop"
                        cloud_name="sugarbae"
                        className="compliment-history-user-image"
                        fluid
                      >
                        <Transformation
                          height="200"
                          video_sampling="20"
                          delay="250"
                          duration="10"
                          effect="loop"
                          quality="auto"
                          flags="lossy"
                        />
                      </Image> */}
                      <div className="compliment-history-item-user-info-wrapper">
                        <div className="compliment-user-info-top">
                          <h1 className="compliment-user-username">
                            {compliment.receiverUser.username},{' '}
                            <span className="compliment-age">
                              {compliment.receiverUser.age}
                            </span>
                          </h1>
                        </div>
                        <div className="compliment-user-info-bottom">
                          You sent {compliment.compliment.diamonds} diamonds
                        </div>
                      </div>
                    </div>
                    <div className="compliment-history-item-diamond-count">
                      <BootstrapImage
                        src={diamondFlatIcon}
                        className="diamond-count-icon"
                        fluid
                      />
                      <span className="diamond-count-text">
                        {compliment.compliment.diamonds}
                      </span>
                    </div>
                  </div>
                );
              })}
              {scrollLoading && (
                <div className="wallet-scroll-loading-container">
                  <ClipLoader color="#b5b5b5" size={14} />
                </div>
              )}
              {maxReached && complimentHistory.length > 10 && (
                <div className="wallet-max-reached-container">
                  End of wallet history.
                </div>
              )}
            </div>
          ) : (
            <>
              {context.user.gender === 'male' ? (
                <div className="no-diamond-history-container">
                  <BootstrapImage
                    src={complimentIcon}
                    className="no-diamond-history-image"
                    fluid
                  />
                  <h2 className="no-diamond-history-title">
                    No Wallet History
                  </h2>
                  <p className="no-diamond-history-subtitle">
                    Send compliments with Diamonds to attract female members.
                  </p>
                </div>
              ) : (
                <div className="no-diamond-history-container">
                  <BootstrapImage
                    src={complimentIcon}
                    className="no-diamond-history-image"
                    fluid
                  />
                  <h2 className="no-diamond-history-title">
                    No Wallet History
                  </h2>
                  <p className="no-diamond-history-subtitle">
                    Receive Diamonds from male users to redeem for cash.
                  </p>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default withRouter(WalletHistory);
