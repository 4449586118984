import React, { Component } from "react";
import "../modals.scss";
import { Button, Card, Modal } from "react-bootstrap";
import { UserContext } from "../../../context/UserContext";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

export class ViewLikes extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      show: true,
      premium: false,
      loading: true,
      image: "",
    };
  }

  componentWillMount() {}

  closeHandler = (e) => {
    this.setState({ show: false }, () => {
      this.props.closeHandler();
    });
  };

  linkClickHandler = (e) => {
    this.props.history.push("/app/premium");
  };

  render() {
    return (
      <Modal size="lg" show={this.state.show} onHide={this.closeHandler}>
        <Modal.Body className="swipe-pop-modal-body-viewlikes">
          <div className="swipe-pop-body-premium-title-wrap">
            <i className="fas fa-gem swipe-pop-premium-icon"></i>
            <span className="swipe-pop-body-premium-title">Go Premium</span>
          </div>
          <div className="swipe-pop-icon">
            <i className="fas fa-eye swipe-pop-main-icon-eye"></i>{" "}
          </div>
          <h2 className="swipe-pop-body-title">See Who Likes You!</h2>
          <div className="swipe-pop-body-countdown-wrapper"></div>
          <p className="swipe-pop-body-subtitle">
            Go Premium and to <strong>view who liked your profile</strong> and{" "}
            much more.
          </p>
          <Card className="swipe-pop-features-card">
            <Card.Body className="swipe-pop-features-card-body">
              <div className="swipe-pop-feature-wrapper">
                <i className="fas fa-bolt swipe-pop-feature-icon-bolt"></i>
                <p className="swipe-pop-feature-text">Profile Boosts</p>
              </div>
              <div className="swipe-pop-feature-wrapper">
                <i className="fas fa-heart swipe-pop-feature-icon-heart"></i>
                <p className="swipe-pop-feature-text">Unlimited Likes</p>
              </div>
              <div className="swipe-pop-feature-wrapper">
                <i className="fas fa-stars swipe-pop-feature-icon-stars"></i>
                <p className="swipe-pop-feature-text">Extra Super Likes</p>
              </div>
              <div className="swipe-pop-feature-wrapper">
                <i className="fas fa-eye swipe-pop-feature-icon-eye"></i>
                <p className="swipe-pop-feature-text">View Profile Likes</p>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer className="swipe-pop-footer">
          <Link to="/app/premium" style={{ width: "100%" }}>
            <Button className="swipe-pop-premium-btn" size="lg">
              Continue
            </Button>
          </Link>
          <Button
            className="swipe-pop-close-btn"
            onClick={this.closeHandler}
            size="lg"
            variant="link"
          >
            No Thanks
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withRouter(ViewLikes);
