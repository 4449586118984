import React, { useState, useEffect, useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Image, Button } from "react-bootstrap";
import desktopLogo from "../../../assets/logos/logo-horizontal-dark-background.png";
import "./desktopnav.scss";
import Axios from "axios";
import { UserContext } from "../../../context/UserContext";
import MatchCounter from "../../navbar/navComponents/counters/MatchCounter";
import MessageCounter from "../../navbar/navComponents/counters/MessageCounter";
import ProfileCounter from "../../navbar/navComponents/counters/ProfileCounter";
import { api } from "../../../api";

function DesktopNav(props) {
  const [state, setState] = useState({
    premium: false,
  });

  const context = useContext(UserContext);

  useEffect(() => {
    const fetchData = async (e) => {
      const data = {
        userid: context.user._id,
      };

      return api
        .fetchNavbarData(data)
        .then((res) => {
          if (res.status === 200) {
            var premium = res.data.premium;
            var banned = res.data.banned;
            if (banned) {
              localStorage.removeItem("auth");
              props.history.push("/");
            }
            setState({
              premium: premium,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
  }, []);

  return (
    <div className="desktop-nav-component">
      <div className="desktop-nav-innerwrap">
        <div className="desktop-nav-logo-wrapper">
          <Link to="/app" className="desktop-nav-logo-link">
            <Image
              src={desktopLogo}
              alt="Sugarbae Logo"
              className="desktop-nav-logo"
            />
          </Link>
        </div>
        <div className="desktop-nav-navigation-wrapper">
          <div className="desktop-nav-button-wrap">
            <Link to="/app/search" className="desktop-nav-link">
              <Button className="desktop-nav-button">
                <i className="fas fa-search desktop-nav-icon"></i> Search
              </Button>
            </Link>
          </div>
          <div className="desktop-nav-button-wrap">
            <Link to="/app/matches" className="desktop-nav-link">
              <Button className="desktop-nav-button">
                <i className="fas fa-heart desktop-nav-icon">
                  <MatchCounter />
                </i>{" "}
                Matches
              </Button>
            </Link>
          </div>
          <div className="desktop-nav-button-wrap">
            <Link to="/app/messages" className="desktop-nav-link">
              <Button className="desktop-nav-button">
                <i className="fas fa-comment-alt-lines desktop-nav-icon">
                  <MessageCounter />
                </i>{" "}
                Messages
              </Button>
            </Link>
          </div>
          <div className="desktop-nav-button-wrap">
            <Link to="/app/profile" className="desktop-nav-link">
              <Button className="desktop-nav-button">
                <i className="fas fa-user desktop-nav-icon"></i> Profile
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(DesktopNav);
