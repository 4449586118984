import React, { Component } from 'react';
import './likesimage.scss';
import { Image as OriginImage } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import Axios from 'axios';
import { UserContext } from '../../../../context/UserContext';
import ViewLikes from '../../../modals/viewLikes/ViewLikes';
import { withRouter } from 'react-router-dom';
import premiumGif from '../../../../assets/Images/welcome/premium-diamond.gif';
import likesImagePlaceholder from '../../../../assets/Images/messages/likes-image-placeholder.png';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import Media from '../../../../components/media/Media';

export class LikesImage extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      likeCount: null,
      loading: true,
      premium: false,
      likeModalOpen: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (e) => {
    const token = localStorage.getItem('auth');
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + '/api/match/likesimage';
    const config = {
      auth: token,
      data: {
        userid: this.context.user._id,
      },
    };

    await Axios.post(url, config)
      .then((res) => {
        if (res.status === 200) {
          var image = res.data.image;
          var likeCount = res.data.likeCount;
          var premium = res.data.premium;
          this.setState({
            image: image,
            likeCount: likeCount,
            premium: premium,
            loading: false,
          });
        }
      })
      .catch((err) => {});
  };

  imageClickHandler = (e) => {
    this.props.history.push('/app/likes');
  };

  closeModalHandler = (e) => {
    this.setState({ likeModalOpen: false });
  };

  render() {
    var likeCountText = '';

    if (this.state.likeCount === null || this.state.likeCount === 0) {
      likeCountText = 'View Likes';
    } else {
      likeCountText = this.state.likeCount.toString() + ' Likes';
    }

    let vhh = window.innerHeight;
    let vhw = window.innerWidth;

    var circleHeight = vhh * 0.2 * 0.8 * 0.6 + 'px';
    var circleWidth = circleHeight;

    var iconBottom = vhh * 0.2 * 0.8 * 0.6 * 0.35 + 'px';
    var iconLeft = vhh * 0.2 * 0.8 * 0.6 * 0.42 + 'px';

    var circleStyle = {
      height: circleHeight,
      width: circleWidth,
    };

    var likesIconPosition = {
      bottom: iconBottom,
      left: iconLeft,
    };

    return (
      <div
        className="likes-image-component-wrapper"
        onClick={this.imageClickHandler}
      >
        <div className="likes-image-innerwrap">
          {this.state.likeModalOpen ? (
            <ViewLikes closeHandler={this.closeModalHandler} />
          ) : null}
          <div className="likes-image-wrap">
            {this.state.image === null ? (
              <OriginImage
                roundedCircle
                className="likes-image"
                src={likesImagePlaceholder}
                style={circleStyle}
              />
            ) : (
              <Media image={this.state.image} />
              // <Image
              //   publicId={getImgName(this.state.image)}
              //   version={getImgVersion(this.state.image)}
              //   resource_type={checkGIF(this.state.image) ? "video" : "image"}
              //   effect="loop"
              //   cloud_name="sugarbae"
              //   className={
              //     this.state.premium ? "likes-image" : "likes-image-blur"
              //   }
              // >
              //   <Transformation
              //     width="68"
              //     height="68"
              //     radius="max"
              //     background="white"
              //     crop="fill"
              //     fetchFormat="auto"
              //     quality="auto"
              //     flags="lossy"
              //   />
              // </Image>
            )}
          </div>

          {!this.state.loading && (
            <div className="likes-image-icon-wrapper" style={likesIconPosition}>
              <OriginImage
                src={premiumGif}
                className="match-queue-likes-image"
              />
            </div>
          )}

          {this.state.loading ? null : (
            <p className="likes-image-subtitle">{likeCountText}</p>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(LikesImage);
