import React, { Component } from "react";
import {
  Container,
  Form,
  Button,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import "./browsegrid.scss";
import axios from "axios";
import { UserCardHome } from "../../card/UserCardHome";
import { Link } from "react-router-dom";
import PlacesAutocomplete from "react-places-autocomplete";
import { api } from "../../../api";

export class BrowseGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      gender: "",
      address: "",
      changingAddress: "",
    };
  }

  handleAddressChange = (address) => {
    this.setState({ changingAddress: address }, () => {
      // this.fetchUsers();
    });
  };

  handleAddressSelect = (address) => {
    this.setState(
      {
        address: address,
        changingAddress: address,
      },
      () => {
        this.fetchUsers();
      }
    );
  };

  fetchUsers = async (e) => {
    var gender = this.state.gender;
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + "/api/user/homegrid";
    const data = {
      gender: gender,
      address: this.state.address,
    };

    return api
      .fetchHomeGrid(data)
      .then((res) => {
        const receivedUsers = res.data.data.users;
        this.setState({
          users: receivedUsers,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  genderChangeHandler = (props) => (e) => {
    if (props === "female") {
      this.setState({ gender: "female" }, () => {
        this.fetchUsers();
      });
    }
    if (props === "male") {
      this.setState({ gender: "male" }, () => {
        this.fetchUsers();
      });
    }

    if (props === "both") {
      this.setState({ gender: "both" }, () => {
        this.fetchUsers();
      });
    }
  };

  clearAddressHandler = (e) => {
    this.setState({ address: "", changingAddress: "" }, () => {
      this.fetchUsers();
    });
  };

  componentWillMount() {
    this.setState({ gender: this.props.gender }, () => {
      this.fetchUsers();
    });
  }
  render() {
    const searchOptions = {
      types: ["(cities)"],
    };
    var dropdownTitle = null;

    if (this.state.gender === "male") {
      dropdownTitle = "Browse Male Members";
    } else if (this.state.gender === "female") {
      dropdownTitle = "Browse Female Members";
    } else {
      dropdownTitle = "Browse All Members";
    }

    return (
      <Container className="browsegrid-container">
        <div className="hg-title-wrap">
          <h2 className="homegrid-title">
            Find Your Ideal <span className="one-million-hg">Arrangement</span>{" "}
          </h2>
          <Form className="browsegrid-location-form">
            <Form.Label className="homegrid-subtitle">
              Enter Your City
            </Form.Label>
            <PlacesAutocomplete
              value={this.state.changingAddress}
              onChange={this.handleAddressChange}
              onSelect={this.handleAddressSelect}
              searchOptions={searchOptions}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="browse-grid-location-input-wrapper">
                  <Form.Control
                    size="lg"
                    {...getInputProps({
                      placeholder: "Enter Your City Here",
                      className: "browse-grid-location-input",
                    })}
                  />

                  <div className="browsegrid-dropdown-card">
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <div className="bg-location-input-reset-btn-wrap">
              {this.state.address === "" ||
              this.state.address === null ||
              this.state.address === undefined ? null : (
                <Button
                  variant="link"
                  onClick={this.clearAddressHandler}
                  className="bg-location-input-reset-btn"
                >
                  Clear
                </Button>
              )}
            </div>
          </Form>
        </div>

        <div className="homegrid-genderpick-wrapper">
          <DropdownButton title={dropdownTitle} variant="outline-secondary">
            {this.state.gender === "male" ? null : (
              <div>
                <Dropdown.Item onSelect={this.genderChangeHandler("male")}>
                  Browse Male Members
                </Dropdown.Item>
                <Dropdown.Divider />
              </div>
            )}

            {this.state.gender === "female" ? null : (
              <div>
                <Dropdown.Item onSelect={this.genderChangeHandler("female")}>
                  Browse Female Members
                </Dropdown.Item>
              </div>
            )}

            {this.state.gender === "male" || this.state.gender === "female" ? (
              <div>
                <Dropdown.Divider />

                <Dropdown.Item onSelect={this.genderChangeHandler("both")}>
                  Browse All Members
                </Dropdown.Item>
              </div>
            ) : null}
          </DropdownButton>
        </div>

        {this.state.users.map((user, index) => {
          return (
            <UserCardHome
              key={index}
              user={user}
              username={user.username}
              address={user.address}
              imgurl={user.imgurl}
              approvedimgurl={user.approvedimgurl}
              videourl={user.videourl}
              profileurl={user.profileurl}
              lastlogin={user.lastlogin}
              premium={user.premium}
              verified={user.verified}
            />
          );
        })}
        <div className="homegrid-seemore-wrapper">
          <Link to="/signup" className="homegrid-seemore-link">
            <Button variant="secondary" className="homegrid-seemore-btn">
              See More
            </Button>
          </Link>
        </div>
      </Container>
    );
  }
}

export default BrowseGrid;
