import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import BeatLoader from 'react-spinners/BeatLoader';
import { Button, Form, InputGroup, Alert } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';

import { UserContext } from '../../../../context/UserContext';
import DesktopChatCard from '../desktopChatCard/DesktopChatCard';
import MessagesModal from '../../../modals/messagesModal/MessagesModal';
import SpamModal from '../../../modals/spamModal/SpamModal';
import WarningModal from '../../../modals/warningModal/WarningModal';
import VerificationModal from '../../../modals/verificationModal/VerificationModal';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { api, socket } from '../../../../api';
import Media from '../../../../components/media/Media';

import './desktopchat.scss';

function DesktopChat(props) {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [skip, setSkip] = useState(0);
  const [messages, setMessages] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [endOfMessages, setEndOfMessages] = useState(false);
  const [card, setCard] = useState(null, () => console.log('--great'));
  const [user, setUser] = useState(null);
  const [match, setMatch] = useState(null);
  const [safetyModalOpen, setSafetyModalOpen] = useState(false);
  const [sendMessage, setSendMessage] = useState('');
  const [sendMessageInputInvalid, setSendMessageInputInvalid] = useState(false);
  const [sendMessageButtonDisabled, setSendMessageButtonDisabled] = useState(
    false
  );
  const [isSpam, setIsSpam] = useState(false);
  const [spamTimeout, setSpamTimeout] = useState(null);
  const [isBanned, setIsBanned] = useState(false);
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);

  const [messagesModalOpen, setMessagesModalOpen] = useState(false);

  const scrollRef = useRef(null);

  const [socketActive, setSocketActive] = useState(false);
  const [spamDescription, setSpamDescription] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [previousIndex, setPreviousIndex] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (card) {
      socketOn();
      readMessages();
    }

    return () => {
      socket.offNewMessage(context.user.username);
      socket.offReadMessagesSuccess(context.user._id);
    };
  }, [card]);

  useEffect(() => {
    if (isBanned) {
      setTimeout(() => {
        localStorage.removeItem('auth');
        props.history.push('/');
      }, 5000);
    }
  }, [isBanned]);

  useEffect(() => {
    if (skip !== 0) {
      fetchData();
    }
  }, [skip]);

  const fetchData = async (e) => {
    const data = {
      userid: context.user._id,
      chatid: props.chatid,
      skip: skip,
    };

    return api
      .fetchDesktopMessages(data)
      .then((res) => {
        if (res.status === 200) {
          var messagesReceived = res.data.messages;
          var temp = [];
          if (messagesReceived < 20) {
            setEndOfMessages(true);
          }

          temp = messagesReceived.concat(messages);
          setMessages(temp);
          setUser(res.data.user);
          setCard(res.data.card);
          context.updateMessageData(res.data.card._id, '', 'read');
          setMatch(res.data.match);
          setLoading(false);
          setFetching(false);
          if (temp.length < 21) {
            bottomScrollHandler();
          } else {
            setPreviousIndex(messagesReceived.length);
            document.getElementById('previous-position') &&
              document.getElementById('previous-position').scrollIntoView();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const socketOn = (e) => {
    socket.onNewMessage(context.user.username, (newMessage, warning) => {
      if (newMessage.banned) {
        setIsBanned(true);
      } else if (newMessage.spam || newMessage.muted) {
        const description = newMessage.spam
          ? 'Your message was detected as spam and so lock messaging, please wait for a while.'
          : 'Your message contains unacceptable content and so lock messaging, please wait for a while.';
        setSpamDescription(description);
        setIsSpam(true);
        setSpamTimeout(newMessage.timeout);
      } else if (warning) {
        setShowWarning(true);
      }
      if (
        newMessage.senderusername === context.user.username ||
        newMessage.sender === card._id
      ) {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        bottomScrollHandler();

        readMessages();
      }
    });

    socket.onReadMessagesSuccess(context.user._id, ({ receiverId }) => {
      if (receiverId === card._id) {
        setMessages((messages) => {
          const messagesRead = messages.map((message) => ({
            ...message,
            isRead: true,
          }));

          return messagesRead;
        });
      }
    });
  };

  const readMessages = async (e) => {
    const data = {
      senderId: card._id,
      receiverId: context.user._id,
    };

    socket.readMessages(data);
  };

  const toggleSafetyModal = (e) => {
    if (safetyModalOpen) {
      setSafetyModalOpen(false);
    } else {
      setSafetyModalOpen(true);
    }
  };

  const textChangeHandler = (e) => {
    setSendMessage(e.target.value);

    if (e.target.value.length > 0) {
      setSendMessageInputInvalid(false);
      setSendMessageButtonDisabled(false);
    } else {
      setSendMessageButtonDisabled(true);
    }
  };

  const sendMessageHandler = async (e) => {
    e.preventDefault();
    if (sendMessage.length > 0 && sendMessage.length < 5001) {
      if (context.user.premium) {
        setSendMessageButtonDisabled(true);

        const data = {
          sender: context.user.username,
          receiver: card.username,
          message: sendMessage,
        };
        socket.sendMessage(data);
        context.updateMessageData(card._id, sendMessage, 'send');
        setSendMessage('');
      } else {
        setMessagesModalOpen(true);
        setSendMessage('');
      }
    } else {
      setSendMessageInputInvalid(true);
    }
  };

  const bottomScrollHandler = (e) => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const closeModalsHandler = (e) => {
    setMessagesModalOpen(false);
    setIsSpam(false);
    setVerificationModalOpen(false);
    setShowWarning(false);
  };

  const messageAbilityCheck = (e) => {
    if (!context.user.premium) {
      setMessagesModalOpen(true);
    } else {
    }
  };

  const scrollHandler = () => {
    const div = document.getElementById('chat-content');
    if (div.scrollTop === 0 && !endOfMessages && !fetching && !loading) {
      setSkip(skip + 20);
      setFetching(true);
    }
  };

  var inputDisabled = false;
  if (!context.user.premium) {
    inputDisabled = true;
  }

  if (loading) {
    return null;
  } else {
    var image = card.imgurl[0];
    var profileurl = '/app/member/' + card.profileurl;

    return (
      <div className="desktop-chat-component">
        {messagesModalOpen && (
          <MessagesModal
            closeHandler={closeModalsHandler}
            open={messagesModalOpen}
          />
        )}

        {isSpam && (
          <SpamModal
            closeHandler={closeModalsHandler}
            open={isSpam}
            timeout={spamTimeout}
            description={spamDescription}
          />
        )}

        {verificationModalOpen && (
          <VerificationModal
            verifications={context.user.verifications}
            onClose={closeModalsHandler}
          />
        )}

        {showWarning && <WarningModal closeHandler={closeModalsHandler} />}

        <div className="desktop-chat-header">
          <Link to={profileurl} className="desktop-chat-header-link">
            <div className="desktop-chat-header-leftside">
              <div className="desktop-chat-header-image-wrap">
                {image ? (
                  <Media image={image} />
                ) : (
                  // <Image
                  //   publicId={getImgName(image)}
                  //   version={getImgVersion(image)}
                  //   resource_type={checkGIF(image) ? "video" : "image"}
                  //   effect="loop"
                  //   cloud_name="sugarbae"
                  //   className="desktop-chat-header-image"
                  // >
                  //   <Transformation
                  //     height="200"
                  //     video_sampling="20"
                  //     delay="250"
                  //     duration="10"
                  //     effect="loop"
                  //     quality="auto"
                  //     flags="lossy"
                  //   />
                  // </Image>
                  <div className="desktop-header-image-placeholder">
                    <i className="far fa-user desktop-chat-header-placeholder-icon"></i>
                  </div>
                )}
              </div>
              <div className="desktop-chat-header-info-wrap">
                <h2 className="desktop-chat-header-username">
                  {card.username}
                </h2>
                <p className="desktop-chat-header-age-location">
                  {card.age} • {card.address}
                </p>
              </div>
            </div>
          </Link>
          <div className="desktop-chat-header-rightside">
            <Button
              variant="link"
              onClick={toggleSafetyModal}
              className="desktop-chat-safety-button"
            >
              <i className="fas fa-shield desktop-chat-header-safety-icon"></i>
            </Button>
          </div>
        </div>
        <div className="desktop-chat-content">
          {messages.length > 0 ? (
            <div
              className="desktop-message-feed"
              id="chat-content"
              onScroll={scrollHandler}
            >
              {fetching && (
                <div className="desktop-message-spinner">
                  <BeatLoader />
                </div>
              )}
              {messages.map((message, index) => {
                if (index !== 0 && index === previousIndex) {
                  return (
                    <div id="previous-position">
                      <DesktopChatCard message={message} index={index} />
                    </div>
                  );
                } else {
                  return <DesktopChatCard message={message} index={index} />;
                }
              })}
              <div ref={scrollRef} />
            </div>
          ) : (
            <div className="desktop-message-feed">
              <span>No Messages With This User</span>
            </div>
          )}
        </div>
        {isBanned && (
          <Alert variant="danger">
            Your account was banned and will be logout automatically.
          </Alert>
        )}
        <div className="desktop-chat-footer">
          <Form
            className="desktop-chat-footer-form"
            onSubmit={sendMessageHandler}
            onClick={messageAbilityCheck}
          >
            <InputGroup className="desktop-chat-input-group">
              <Form.Control
                as="textarea"
                rows="4"
                className="desktop-chat-footer-form-input"
                onChange={textChangeHandler}
                isInvalid={sendMessageInputInvalid}
                value={sendMessage}
                disabled={inputDisabled}
                placeholder="Write your message..."
              />
              <InputGroup.Append>
                <Button
                  type="submit"
                  className="desktop-chat-submit-button"
                  disabled={sendMessageButtonDisabled}
                  size="lg"
                >
                  Send
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Form>
        </div>
      </div>
    );
  }
}

export default withRouter(DesktopChat);
