import React, { useState, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import BottomScrollListener from "react-bottom-scroll-listener";
import ClipLoader from "react-spinners/ClipLoader";

import { UserContext } from "../../../../../context/UserContext";
import socket from "../../../../../helpers/Socket";
import MessageArchiveModal from "../../../../modals/messageArchiveModal/MessageArchiveModal";
import NoMessages from "../noMessages/NoMessages";

import MessageQueueItem from "./MessageQueueItem";
import "./messagequeue.scss";

export const MessageQueue = props => {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [archive, setArchive] = useState(false);
  const [unmatchId, setUnmatchedId] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    socket.on(`newmessage${context.user.username}`, (msgReturn) => {
      if (context.user._id !== msgReturn.sender && msgReturn.sender) {
        context.updateMessageData(msgReturn.sender, msgReturn.message, "receive");
        setRefresh(true);
      }
      context.updataMatchData(msgReturn.receiver, "unmatch");
    });

    return () => {
    socket.off(`newmessage${context.user.username}`, (msgReturn) => {});
    }
  },[]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  },[refresh]);

  const submitUnmatch = () => {
    setArchive(false);
  };

  const deleteHandler = (userid) => {
    setUnmatchedId(userid);
    setArchive(true);
  };

  const closeHandler = () => {
    setArchive(false);
  };

  useEffect(() => {
    if (!loading && context.messageData.length !== 0 && !fetching && props.index !== 0) {
      setFetching(true);
      context.getMoreMessage();
    }
  },[props.index]);

  useEffect(() => {
    setFetching(false);
  },[context.messageData]);

  if (context.messageQueueLoading) {
    return (
      <div style={{ textAlign: "center"}}>
        <ClipLoader
          size={40}
          color={"#000"}
          loading={context.messageQueueLoading}
        />
      </div>
    );
  } else {
    return (
      <div className="message-queue-component">
        {archive && (
          <MessageArchiveModal
            closeHandler={closeHandler}
            submitUnmatch={submitUnmatch}
            unmatchId={unmatchId}
          />
        )}
        {context.messageData && context.messageData.length > 0 ? (
          <div>
            {context.messageData.map((message) => {
              const matchedUserId = message.user._id;

              return (
                <div key={matchedUserId} style={{ position: "relative" }}>
                  <Button
                    variant="primary"
                    className="delete-message-button"
                    onClick={() => deleteHandler(matchedUserId)}
                  >
                    Delete
                  </Button>
                  <MessageQueueItem
                    id={matchedUserId}
                    chatToggle={props.chatToggle}
                    message={message}
                    userId={context.user._id}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-messages-wrapper">
            <NoMessages />
          </div>
        )}
      </div>
    );
  }
}

export default MessageQueue;
