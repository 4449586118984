import React from "react";

function EditGender() {
  return (
    <div>
      <span>Edit Gender</span>
    </div>
  );
}

export default EditGender;
