import React, { useState, useContext } from "react";
import "./desktopsliders.scss";
import { Image, Form } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import { UserContext } from "../../../../../context/UserContext";

const heights = [
  "4'0\"",
  "4'1\"",
  "4'2\"",
  "4'3\"",
  "4'4\"",
  "4'5\"",
  "4'6\"",
  "4'7\"",
  "4'8\"",
  "4'9\"",
  "4'10\"",
  "4'11\"",
  "5'0\"",
  "5'1\"",
  "5'2\"",
  "5'3\"",
  "5'4\"",
  "5'5\"",
  "5'6\"",
  "5'7\"",
  "5'8\"",
  "5'9\"",
  "5'10\"",
  "5'11\"",
  "6'0\"",
  "6'1\"",
  "6'2\"",
  "6'3\"",
  "6'4\"",
  "6'5\"",
  "6'6\"",
  "6'7\"",
  "6'8\"",
  "6'9\"",
  "6'10\"",
  "6'11\"",
  "7'0\"",
];

function Height(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [height, setHeight] = useState(filters.height);

  const changeHandler = (event, newValue) => {
    setHeight(newValue);
  };
  const commitHandler = (event, newValue) => {
    setHeight(newValue);
  };

  const saveHandler = (e) => {
    props.updateHandler(height);
  };

  const resetHandler = (e) => {};

  const minHeight = heights[height[0]];
  const maxHeight = heights[height[1]];

  return (
    <div className="desktop-v2-filters-content">
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.onClose}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-icon">
              <i className="far fa-ruler-vertical filter-sort-icon" />
            </div>
            <div className="desktop-v2-filters-slider-title">Height</div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={resetHandler}
            >
              Reset
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <div className="advanced-search-filters-slider-wrapper">
                <h2 className="search-slider-title">
                  Between {minHeight} and {maxHeight}
                </h2>
                <div className="slider-innerwrap">
                  <Slider
                    min={0}
                    max={36}
                    value={height}
                    onChange={changeHandler}
                    onChangeCommitted={commitHandler}
                  />
                </div>
              </div>
            </Form.Group>
          </Form>
        </div>
        <div className="desktop-filter-slider-footer" onClick={saveHandler}>
          <div className="desktop-filter-slider-apply-button">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default Height;
