import React, { useState, useContext } from "react";
import "./desktopsliders.scss";
import { Image, Form } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import { UserContext } from "../../../../../context/UserContext";

const heights = [
  "4'0\"",
  "4'1\"",
  "4'2\"",
  "4'3\"",
  "4'4\"",
  "4'5\"",
  "4'6\"",
  "4'7\"",
  "4'8\"",
  "4'9\"",
  "4'10\"",
  "4'11\"",
  "5'0\"",
  "5'1\"",
  "5'2\"",
  "5'3\"",
  "5'4\"",
  "5'5\"",
  "5'6\"",
  "5'7\"",
  "5'8\"",
  "5'9\"",
  "5'10\"",
  "5'11\"",
  "6'0\"",
  "6'1\"",
  "6'2\"",
  "6'3\"",
  "6'4\"",
  "6'5\"",
  "6'6\"",
  "6'7\"",
  "6'8\"",
  "6'9\"",
  "6'10\"",
  "6'11\"",
  "7'0\"",
];

function Bodytype(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [bodytype, setBodytype] = useState(filters.bodytype);

  const changeHandler = (e) => {
    const bodytypeCopy = bodytype;

    if (e.target.value === "slim") {
      if (bodytypeCopy.slim) {
        bodytypeCopy.slim = false;
      } else {
        bodytypeCopy.slim = true;
      }
    } else if (e.target.value === "fit") {
      if (bodytypeCopy.fit) {
        bodytypeCopy.fit = false;
      } else {
        bodytypeCopy.fit = true;
      }
    } else if (e.target.value === "muscular") {
      if (bodytypeCopy.muscular) {
        bodytypeCopy.muscular = false;
      } else {
        bodytypeCopy.muscular = true;
      }
    } else if (e.target.value === "average") {
      if (bodytypeCopy.average) {
        bodytypeCopy.average = false;
      } else {
        bodytypeCopy.average = true;
      }
    } else if (e.target.value === "afewextrapounds") {
      if (bodytypeCopy.afewextrapounds) {
        bodytypeCopy.afewextrapounds = false;
      } else {
        bodytypeCopy.afewextrapounds = true;
      }
    } else if (e.target.value === "curvy") {
      if (bodytypeCopy.curvy) {
        bodytypeCopy.curvy = false;
      } else {
        bodytypeCopy.curvy = true;
      }
    } else if (e.target.value === "fullfigured") {
      if (bodytypeCopy.fullfigured) {
        bodytypeCopy.fullfigured = false;
      } else {
        bodytypeCopy.fullfigured = true;
      }
    }

    setBodytype((prevState) => ({ ...prevState, ...bodytypeCopy }));
  };

  const saveHandler = (e) => {
    props.updateHandler(bodytype);
  };

  const resetHandler = (e) => {};

  return (
    <div className="desktop-v2-filters-content">
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.onClose}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-icon">
              <i className="far fa-heart filter-sort-icon" />
            </div>
            <div className="desktop-v2-filters-slider-title">Bodytype</div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={resetHandler}
            >
              Reset
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Slim"
                type="checkbox"
                value="slim"
                checked={bodytype.slim}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Fit"
                type="checkbox"
                value="fit"
                checked={bodytype.fit}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Muscular"
                type="checkbox"
                value="muscular"
                checked={bodytype.muscular}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Average"
                type="checkbox"
                value="average"
                checked={bodytype.average}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="A Few Extra Pounds"
                type="checkbox"
                value="afewextrapounds"
                checked={bodytype.afewextrapounds}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Curvy"
                type="checkbox"
                value="curvy"
                checked={bodytype.curvy}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Full Figured"
                type="checkbox"
                value="fullfigured"
                checked={bodytype.fullfigured}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="desktop-filter-slider-footer" onClick={saveHandler}>
          <div className="desktop-filter-slider-apply-button">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default Bodytype;
