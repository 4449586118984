import React, { useState, useContext, useEffect } from "react";
import { Button, Alert } from "react-bootstrap";
import PremiumFeatures from "../carousels/PremiumFeatures";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { Image, Transformation } from "cloudinary-react";
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from "../../utils/getCloudinaryInfo";
import { Image as BootstrapImage } from "react-bootstrap";
import sugarbaePlusLogo from "../../assets/logos/sugarbae-home-icon.png";
import axios from "axios";
import { api } from "../../api";
import ClipLoader from "react-spinners/ClipLoader";
import backgroundPattern from "../../assets/Images/background-pattern.png";
import wallet from "../../assets/Icons/wallet.png";
import suitcase from "../../assets/Icons/suitcase.png";
import box from "../../assets/Icons/box.png";
import diamondPack from "../../assets/Icons/diamondPack.svg";
import "./receipt.scss";
var moment = require("moment");

function Receipt(props) {
  const context = useContext(UserContext);
  const location = useLocation();

  const [purchaseType, setPurchaseType] = useState("default");
  const [purchaseData, setPurchaseData] = useState({
    count: null,
    price: null,
  });
  var locationPath = props.location.pathname;

  useEffect(() => {
    // if (props.location.pathname.includes("diamonds")) {
    //   setPurchaseType("diamonds");
    // }

    setPurchaseType(location.state.purchaseType);
    setPurchaseData(location.state.purchaseData);
  }, [location]);

  let history = useHistory();
  var mainImage = null;

  if (context.user.imgurl) {
    if (context.user.imgurl.length > 0) {
      mainImage = context.user.imgurl[0];
    }
  }

  const ctaHandler = (e) => {
    history.push("/app/search");
  };

  var todaysDate = moment().format("MMM, Do, YYYY").toString();
  var oneMonthDate = moment()
    .add(1, "month")
    .format("MMM, Do, YYYY")
    .toString();

  var boostPurchase = false;

  if (locationPath.includes("boost")) {
    boostPurchase = true;
    // if (!context.boostData.boosted) {
    //   api
    //     .checkBoosted()
    //     .then((res) => {})
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  }

  return (
    <div className="premium-success-component">
      <div className="premium-success-content">
        <div className="premium-success-thank-you-section">
          <h1 className="premium-success-thank-you-title">
            Hi, {context.user.username}
          </h1>
          {purchaseType === "boost" && (
            <p className="premium-success-thank-you-subtitle">
              Thank you for purchasing your profile
              <span style={{ color: "#c44b4b" }}> boost</span>.
            </p>
          )}
          {purchaseType === "diamonds" && (
            <p className="premium-success-thank-you-subtitle">
              You successfully purchased {purchaseData.count}
              <span style={{ color: "#c44b4b" }}> Diamonds</span>.
            </p>
          )}
          {purchaseType === "premium" && (
            <p className="premium-success-thank-you-subtitle">
              Thank you for purchasing your{" "}
              <span style={{ color: "#c44b4b" }}>premium</span> subscription.
            </p>
          )}
        </div>

        <div className="premium-success-receipt-section">
          {purchaseType === "diamonds" && (
            <div className="receipt-image-section">
              {purchaseData.count === 10 && (
                <div className="receipt-image-innerwrap">
                  <BootstrapImage
                    src={wallet}
                    className="receipt-image"
                    fluid
                  />
                  <p className="receipt-image-subtitle">
                    Wallet of 10 Diamonds
                  </p>
                </div>
              )}
              {purchaseData.count === 25 && (
                <div className="receipt-image-innerwrap">
                  <BootstrapImage src={box} className="receipt-image" fluid />
                  <p className="receipt-image-subtitle">Box of 25 Diamonds</p>
                </div>
              )}
              {purchaseData.count === 50 && (
                <div className="receipt-image-innerwrap">
                  <BootstrapImage
                    src={suitcase}
                    className="receipt-image"
                    fluid
                  />
                  <p className="receipt-image-subtitle">Case of 50 Diamonds</p>
                </div>
              )}
              {purchaseData.count === 100 && (
                <div className="receipt-image-innerwrap">
                  <BootstrapImage
                    src={diamondPack}
                    className="receipt-image"
                    fluid
                  />
                  <p className="receipt-image-subtitle">Pack of 100 Diamonds</p>
                </div>
              )}
            </div>
          )}

          <h2 className="premium-success-receipt-title">Order Details</h2>
          <div className="premium-success-order-details-wrapper">
            <p className="premium-success-detail">
              Purchase Type:{" "}
              {purchaseType === "diamonds" && (
                <span style={{ color: "#c44b4b" }}>
                  {purchaseData.count === 10 && "Wallet of 10 Diamonds"}
                  {purchaseData.count === 25 && "Box of 25 Diamonds"}
                  {purchaseData.count === 50 && "Case of 50 Diamonds"}
                  {purchaseData.count === 100 && "Pack of 100 Diamonds"}
                </span>
              )}
            </p>
            <p className="premium-success-detail">
              Date of purchase:{" "}
              <span style={{ color: "#c44b4b" }}>{todaysDate}</span>
            </p>
            <p className="premium-success-detail">
              Amount Paid:{" "}
              <span style={{ color: "#c44b4b" }}>{purchaseData.price}</span>
            </p>
            {purchaseType === "premium" && (
              <p className="premium-success-detail">
                Next Payment Date:{" "}
                <span style={{ color: "#c44b4b" }}>{oneMonthDate}</span>
              </p>
            )}
          </div>
          {purchaseType === "premium" && (
            <div className="premium-success-message-section">
              <p className="premium-success-message-text">
                Welcome to Sugarbae Premium, the world's best dating experience.
                We are happy to have you as part of our family.
              </p>
              <p className="premium-success-message-text">
                Enjoy your Premium benefits.
              </p>
              <p className="premium-success-message-text">Love, Sugarbae.</p>
            </div>
          )}
          {purchaseType === "diamonds" && (
            <div className="premium-success-message-section">
              <p className="premium-success-message-text">
                Your Diamonds have been added to your account. Sending Diamonds
                is the best way to attract female users on Sugarbae.
              </p>
              <p className="premium-success-message-text">
                Go send some Diamonds!
              </p>
              <p className="premium-success-message-text">Love, Sugarbae.</p>
            </div>
          )}
        </div>
      </div>
      <div className="premium-success-page-cta-v2">
        <Button
          className="premium-success-button-v2"
          variant="danger"
          size="lg"
          onClick={ctaHandler}
        >
          Search Your Match
        </Button>
      </div>
    </div>
  );
}

export default withRouter(Receipt);
