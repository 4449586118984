import React, { Component } from "react";
import "./counters.scss";

export class ProfileCounter extends Component {
  render() {
    return (
      <div className="counter-component">
        <i className="fad fa-circle counter-icon"></i>{" "}
      </div>
    );
  }
}

export default ProfileCounter;
