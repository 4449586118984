import React from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Image as BootstrapImage } from 'react-bootstrap';
import { withRouter, useHistory } from 'react-router-dom';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { submitLike, submitDislike } from '../../../../helpers/swipeActions';
import { UserContext } from '../../../../context/UserContext';
import cardMessage from '../../../../assets/Icons/actions/cardMessage.png';
import Media from '../../../../components/media/Media';

function DesktopMatchCard(props) {
  const history = useHistory();
  const images = props.card.user.imgurl;
  let image = null;
  if (images.length > 0) {
    image = props.card.user.imgurl[0];
  }

  const profileToggle = (e) => {
    const url = `/app/member/${props.card.user._id}`;
    history.push(url);
  };

  const handleMessage = (e) => {
    const url = `/app/messages/${props.card.user._id}`;
    history.push(url);
  };

  return (
    <div className="desktop-v2-likes-card">
      <div
        className="desktop-v2-likes-card-image-wrapper"
        onClick={profileToggle}
      >
        <Media image={image} />
        {/* <Image
          publicId={
            image
              ? getImgName(image)
              : "larger-placeholder-user-image_vvdghq.png"
          }
          version={image ? getImgVersion(image) : "1590046357"}
          resource_type={image ? (checkGIF(image) ? "video" : "image") : null}
          cloud_name="sugarbae"
          className="desktop-v2-likes-card-image"
        >
          <Transformation
            height="675"
            width="540"
            video_sampling="20"
            delay="250"
            duration="10"
            effect="loop"
            quality="auto"
            flags="lossy"
          />
        </Image> */}
        <div className="desktop-v2-likes-card-user-info-wrapper">
          <h1 className="desktop-v2-likes-card-username">
            {props.card.user.username},{' '}
            <span className="desktop-v2-likes-card-age">
              {props.card.user.age}
            </span>
          </h1>
        </div>
      </div>
      <div className="desktop-v2-likes-card-footer">
        <div
          className="desktop-v2-likes-card-action-button"
          onClick={handleMessage}
          style={{ borderRight: '1px solid #f4f4f4' }}
        >
          <BootstrapImage
            src={cardMessage}
            className="desktop-v2-likes-card-action-icon"
          />
        </div>
      </div>
    </div>
  );
}

export default withRouter(DesktopMatchCard);
