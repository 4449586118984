import React, { useState, useEffect } from "react";
import { api } from "../../api";
import "./graphs.scss";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  BarChart, 
  Bar, 
  Cell, 
  Legend,
} from "recharts";
var moment = require("moment");

function Tasks(props) {
  const [maleSignupData, setMaleSignupData] = useState([]);
  const [femaleSignupData, setFemaleSignupData] = useState([]);
  const [dailyTasks, setTaskData] = useState([]); 
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (e) => {
    return api
      .fetchTaskData()
      .then((res) => {
        if (res.status === 200) {
          setTaskData(res.data.dailyTasks);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderCustomAxisTick = ({ x, y, payload }) => {
    var date = payload.value;

    var formattedDate = moment(date).format("MMM DD").toString();

    return (
      <text x={x} y={y + 25} fill="#666" textAnchor="middle">
        {formattedDate}
      </text>
    );
  };
  return (
    <div className="graph-container">
      <div className="graph-header">
        <h1 className="graph-title">Daily Tasks - No filter </h1>
      </div>
      <div className="graph-content-container">
        <div className="graph-content">
          <h2 className="graph-content-title"></h2>
          <LineChart
            width={500}
            height={300}
            data={dailyTasks}
            margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
          >
            <Line type="monotone" dataKey="count" stroke="#D95454" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="_id" tick={renderCustomAxisTick} />
            <YAxis dataKey="count" />
            <Tooltip />
          </LineChart>
        </div>
      </div>
    </div>
  );
}

export default Tasks;
