// Import Dependencies
import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import moment from 'moment';
import './root.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { hotjar } from 'react-hotjar';
import { AnimatePresence } from 'framer-motion';
import Axios from 'axios';
import Signup from './pages/signup/Signup';
import Login from './pages/login/Login';
import App from './pages/app/App';
import Messages from './pages/messages/Messages';
import UserProfilePage from './pages/userProfilePage/UserProfilePage';
import SugarDaddy from './pages/homePages/sugardaddy/SugarDaddy';
import SugarBaby from './pages/homePages/sugarbaby/SugarBaby';
import Premium from './pages/premium/Premium';
import Privacy from './pages/help/privacy/Privacy';
import Terms from './pages/help/terms/Terms';
import Safety from './pages/help/safety/Safety';
import Faq from './pages/help/faq/Faq';
import Report from './pages/help/report/Report';
import Users from './admin/pages/users/Users';
import Dashboard from './admin/pages/dashboard/Dashboard';
import Bots from './admin/pages/bots/Bots';
import ProfilePage from './pages/profilePage/ProfilePage';
import Auth from './helpers/Auth';
import GA from './helpers/GoogleAnalytics';
import UserProvider from './context/UserContext';
import { Payments } from './admin/pages/payments/Payments';
import HomePage from './pages/homePages/homePage/HomePage';
import BrowsePage from './pages/homePages/browsePage/BrowsePage';
import AboutPage from './pages/homePages/aboutPage/AboutPage';
import SeekingArrangements from './pages/homePages/seekingArrangements/SeekingArrangements';
import HowItWorksPage from './pages/homePages/howItWorksPage/HowItWorksPage';
import ContactPage from './pages/homePages/contactPage/ContactPage';
import CreatePasswordPage from './pages/homePages/createPasswordPage/CreatePasswordPage';
import TermsPage from './pages/homePages/termsPage/TermsPage';
import PrivacyPage from './pages/homePages/privacyPage/PrivacyPage';
import WelcomePage from './pages/welcome/WelcomePage';
import WelcomePageV2 from './pages/welcome/WelcomePageV2';
import PhysicalsPage from './pages/welcome/physicals/PhysicalsPage';
import BenefitsPage from './pages/welcome/benefits/BenefitsPage';
import WelcomePhotosPage from './pages/welcome/photos/PhotosPage';
import LifestylePage from './pages/welcome/lifestyle/LifestylePage';
import NotificationsPage from './pages/notificationsPage/NotificationsPage';
import OfferPage from './pages/welcome/offer/OfferPage';
import CompletePage from './pages/welcome/complete/CompletePage';
import SearchPage from './pages/search/SearchPage';
import MatchPage from './pages/matchPage/MatchPage';
import MaintenancePage from './pages/homePages/maintenance/MaintenancePage';
import BoostCountdown from './components/boostCountdown/BoostCountdown';
import { PremiumSuccessPage } from './pages/premium/premiumSuccess/PremiumSuccessPage';
import { BoostPurchaseSuccess } from './pages/premium/premiumSuccess/BoostPurchaseSuccess';
import { NotePurchaseSuccess } from './pages/premium/premiumSuccess/NotePurchaseSuccess';
import { SuperlikePurchaseSuccess } from './pages/premium/premiumSuccess/SuperlikePurchaseSuccess';
import HookupLanding from './pages/landing/hookupLanding/HookupLanding';
import { Images } from './admin/pages/images/Images';
import AppSupportPage from './pages/appSupportPage/AppSupportPage';
import NewDashboard from './admin/pages/newDashboard/NewDashboard';
import SugarbaeLanding from './pages/landing/landing';
import UnmatchedReport from './pages/unmatchedReport/UnmatchedReport';
import LandingVariant2 from './pages/landing/landingVariant2/LandingVariant2';
import VerificationPage from './pages/verifications/VerificationPage';
import WalletPage from './pages/wallet/WalletPage';
import Receipt from './components/recept/Receipt';
import Requests from './components/requests/Requests';
import Home from './components/home/Home';
import EmailLogin from './components/home/logins/EmailLogin';
import PhoneLogin from './components/home/logins/PhoneLogin';
import { api } from './api';
import EditProfileV2 from './components/userProfile/editProfile/EditProfileV2';
import DesktopSettingsV2 from './components/desktop/desktopV2/desktopSettingsV2/DesktopSettingsV2';
import EmailLoginPage from './pages/SignupLogin/EmailLoginPage';
import PhoneLoginPage from './pages/SignupLogin/PhoneLoginPage';
import ResetPasswordPage from './pages/SignupLogin/ResetPassword';

// hotjar.initialize(1739060, 6);

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boost: {
        active: false,
        date: null,
      },
    };
  }

  handleBoost = async (e) => {
    const token = localStorage.getItem('auth');
    const userid = e;

    api
      .fetchBoost({ userid })
      .then((res) => {
        let tempBoost = res.data.boost;
        this.setState({ boost: tempBoost });
      })
      .catch((err) => console.log(err));
  };

  handleOnActive = (event) => {
    const diff = moment().valueOf() - this.state.lastActive;
    const duration = moment.duration(diff).asMinutes();
    if (duration > 30) {
      window.location.href = '/app';
    }

    const data = {};

    api
      .createNewSession(data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  handleOnIdle = (event) => {
    this.setState({ lastActive: this.idleTimer.getLastActiveTime() });

    const data = {};

    api
      .endSession(data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const vh = window.innerHeight;
    const vw = window.innerWidth;

    const height = `${vh}px`;
    return (
      <div className="Client" style={{ height, maxHeight: height }}>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * 5}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          debounce={250}
        />
        <Router>
          {GA.init() && <GA.RouteTracker />}
          <AnimatePresence exitBeforeEnter>
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/email" exact component={EmailLoginPage} />
              <Route path="/phone" exact component={PhoneLoginPage} />
              <Route path="/login" component={Login} />
              <Route path="/signup/:email" component={Signup} />
              <Route path="/signup" component={Signup} />
              <Route path="/browse" component={BrowsePage} />
              <Route
                path="/reset-password"
                exact
                component={ResetPasswordPage}
              />
              <Route
                path="/reset-password/create-password/:token"
                component={CreatePasswordPage}
              />

              <Route
                path="/seeking-arrangements"
                component={SeekingArrangements}
              />

              <Route path="/sugar-daddy" component={SugarDaddy} />
              <Route path="/sugar-baby" component={SugarBaby} />
              <Route path="/how-it-works" component={HowItWorksPage} />
              <Route path="/terms" component={TermsPage} />
              <Route path="/privacy" component={PrivacyPage} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/about" component={AboutPage} />
              <Route path="/maintenance" exact component={MaintenancePage} />

              <Route
                path="/join/sugar-daddy"
                exact
                render={(props) => <SugarbaeLanding pageName="sugar-daddy" />}
              />
              <Route
                path="/join/women"
                exact
                render={(props) => <SugarbaeLanding pageName="women" />}
              />
              <Route
                path="/join/sugar-baby"
                exact
                render={(props) => <SugarbaeLanding pageName="sugar-baby" />}
              />
              <Route
                path="/join/seeking-arrangements"
                exact
                render={(props) => <SugarbaeLanding />}
              />
              <Route path="/join/hookup" exact component={HookupLanding} />
              <Route
                path="/join/dating"
                exact
                render={(props) => <SugarbaeLanding pageName="dating" />}
              />

              <UserProvider>
                <Auth>
                  <Route
                    path="/app"
                    exact
                    render={(props) => <App handleBoost={this.handleBoost} />}
                  />

                  <Route
                    path="/app/search"
                    exact
                    render={(props) => (
                      <SearchPage handleBoost={this.handleBoost} />
                    )}
                  />
                  <Route
                    path="/app/settings"
                    exact
                    render={(props) => (
                      <DesktopSettingsV2 tab="Account Settings" />
                    )}
                  />
                  <Route
                    path="/app/settings/notifications"
                    exact
                    render={(props) => (
                      <DesktopSettingsV2 tab="Notifications" />
                    )}
                  />
                  <Route
                    path="/app/settings/community"
                    exact
                    render={(props) => <DesktopSettingsV2 tab="Community" />}
                  />
                  <Route
                    path="/app/settings/managesubscription"
                    exact
                    render={(props) => (
                      <DesktopSettingsV2 tab="Manage Subscription" />
                    )}
                  />
                  <Route
                    path="/app/settings/wallet"
                    exact
                    render={(props) => <DesktopSettingsV2 tab="Wallet" />}
                  />
                  <Route
                    path="/app/wallet"
                    exact
                    render={(props) => <WalletPage />}
                  />
                  <Route
                    path="/app/wallet/purchase"
                    exact
                    render={(props) => <WalletPage />}
                  />
                  <Route
                    path="/app/wallet/redeem"
                    exact
                    render={(props) => <WalletPage />}
                  />

                  <Route
                    path="/app/receipt"
                    exact
                    render={(props) => <Receipt option="default" />}
                  />
                  <Route
                    path="/app/receipt/diamonds"
                    exact
                    render={(props) => <Receipt option="diamonds" />}
                  />
                  <Route
                    path="/app/support"
                    exact
                    render={(props) => <AppSupportPage />}
                  />

                  <Route
                    path="/app/messages/"
                    exact
                    render={(props) => (
                      <Messages handleBoost={this.handleBoost} />
                    )}
                  />
                  <Route
                    path="/app/messages/:chatid"
                    exact
                    render={(props) => (
                      <Messages handleBoost={this.handleBoost} />
                    )}
                  />

                  <Route
                    path="/app/matches"
                    exact
                    render={(props) => (
                      <MatchPage handleBoost={this.handleBoost} />
                    )}
                  />

                  <Route
                    path="/app/likes"
                    exact
                    render={(props) => (
                      <MatchPage handleBoost={this.handleBoost} />
                    )}
                  />

                  <Route
                    path="/app/requests"
                    exact
                    render={(props) => <Requests />}
                  />
                  <Route
                    path="/app/requests/people"
                    exact
                    render={(props) => <Requests />}
                  />

                  <Route
                    path="/app/notifications"
                    exact
                    render={(props) => <NotificationsPage />}
                  />
                  <Route
                    path="/app/profile"
                    exact
                    render={(props) => (
                      <UserProfilePage handleBoost={this.handleBoost} />
                    )}
                  />
                  <Route
                    path="/app/profile/personal"
                    exact
                    render={(props) => (
                      <UserProfilePage handleBoost={this.handleBoost} />
                    )}
                  />
                  <Route
                    path="/app/profile/photos"
                    exact
                    render={(props) => (
                      <UserProfilePage handleBoost={this.handleBoost} />
                    )}
                  />
                  <Route
                    path="/app/profile/descriptions"
                    exact
                    render={(props) => (
                      <UserProfilePage handleBoost={this.handleBoost} />
                    )}
                  />
                  <Route
                    path="/app/profile/verifications"
                    exact
                    render={(props) => (
                      <UserProfilePage handleBoost={this.handleBoost} />
                    )}
                  />
                  <Route
                    path="/app/profile/settings"
                    exact
                    render={(props) => (
                      <UserProfilePage
                        handleBoost={this.handleBoost}
                        option="settings"
                      />
                    )}
                  />
                  <Route
                    path="/app/profile/edit"
                    exact
                    render={(props) => <EditProfileV2 option="home" />}
                  />
                  <Route
                    path="/app/profile/edit/basicinfo"
                    exact
                    render={(props) => <EditProfileV2 option="basicinfo" />}
                  />
                  <Route
                    path="/app/profile/edit/lookingfor"
                    exact
                    render={(props) => <EditProfileV2 option="lookingfor" />}
                  />

                  <Route
                    path="/app/profile/edit/aboutyou"
                    exact
                    render={(props) => <EditProfileV2 option="aboutyou" />}
                  />

                  <Route
                    path="/app/profile/edit/benefits"
                    exact
                    render={(props) => <EditProfileV2 option="benefits" />}
                  />
                  <Route
                    path="/app/profile/edit/gallery"
                    exact
                    render={(props) => <EditProfileV2 option="gallery" />}
                  />

                  <Route
                    path="/app/profile/account"
                    exact
                    render={(props) => (
                      <UserProfilePage handleBoost={this.handleBoost} />
                    )}
                  />
                  <Route
                    path="/app/profile/support"
                    exact
                    render={(props) => (
                      <UserProfilePage handleBoost={this.handleBoost} />
                    )}
                  />

                  <Route
                    path="/app/verification"
                    exact
                    render={(props) => <VerificationPage />}
                  />

                  <Route path="/app/welcome" exact component={WelcomePageV2} />
                  <Route
                    path="/app/welcome/2"
                    exact
                    component={WelcomePageV2}
                  />
                  <Route
                    path="/app/welcome/3"
                    exact
                    component={WelcomePageV2}
                  />
                  <Route
                    path="/app/welcome/4"
                    exact
                    component={WelcomePageV2}
                  />
                  <Route
                    path="/app/welcome/photos"
                    exact
                    component={WelcomePageV2}
                  />
                  <Route
                    path="/app/welcome/physicals"
                    exact
                    component={WelcomePageV2}
                  />
                  <Route
                    path="/app/welcome/benefits"
                    exact
                    component={WelcomePageV2}
                  />
                  <Route
                    path="/app/welcome/lookingfor"
                    exact
                    component={WelcomePageV2}
                  />
                  <Route
                    path="/app/welcome/about"
                    exact
                    component={WelcomePageV2}
                  />

                  {/* <Route
                    path="/app/welcome/photos"
                    exact
                    component={WelcomePhotosPage}
                  />

                  <Route
                    path="/app/welcome/physicals"
                    exact
                    component={PhysicalsPage}
                  />
                  <Route
                    path="/app/welcome/lifestyle"
                    exact
                    component={LifestylePage}
                  />

                  <Route
                    path="/app/welcome/benefits"
                    exact
                    component={BenefitsPage}
                  />

                  <Route
                    path="/app/welcome/notifications"
                    exact
                    component={NotificationsPage}
                  />
                  <Route
                    path="/app/welcome/offer"
                    exact
                    component={OfferPage}
                  />
                  <Route
                    path="/app/welcome/complete"
                    exact
                    component={CompletePage}
                  /> */}
                  <Route
                    path="/app/member/:profileurl"
                    component={ProfilePage}
                  />

                  <Route path="/app/premium" exact component={Premium} />
                  <Route
                    path="/app/premium/success"
                    exact
                    component={PremiumSuccessPage}
                  />
                  <Route
                    path="/app/premium/success/boost"
                    exact
                    component={PremiumSuccessPage}
                  />
                  <Route
                    path="/app/premium/success/note"
                    exact
                    component={PremiumSuccessPage}
                  />
                  <Route
                    path="/app/premium/success/superlike"
                    exact
                    component={PremiumSuccessPage}
                  />

                  <Route path="/app/privacy" component={Privacy} />
                  <Route path="/app/report" component={Report} />
                  <Route path="/app/terms" component={Terms} />
                  <Route path="/app/safety" component={Safety} />
                  <Route path="/app/help" component={Faq} />
                  <Route
                    path="/app/unmatchedreport"
                    component={UnmatchedReport}
                  />
                  <Route path="/app/admin" component={NewDashboard} />
                  <Route
                    path="/app/admin/payments"
                    exact
                    component={Payments}
                  />
                </Auth>
              </UserProvider>
            </Switch>
          </AnimatePresence>
        </Router>
      </div>
    );
  }
}

export default Root;
