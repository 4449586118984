import React from "react";
import {
  Button,
  Modal,
} from "react-bootstrap";

import "../modals.scss";

export const WarningModal = props => {
  const { closeHandler } = props;
  return (
    <Modal size="lg" show={true}>
      <Modal.Body className="spam-pop-modal-body-message">
        <div className="swipe-pop-icon">
          <i className="fa fa-exclamation swipe-pop-main-icon-message"></i>{" "}
        </div>
        <h2 className="swipe-pop-body-title">Warning!</h2>
        <p className="swipe-pop-body-subtitle">
          Sending Contact Information or Payment Information is against Sugarbae Terms of Service. Sending messages that contain this information will result in your account being muted or banned.
        </p>
      </Modal.Body>
      <Modal.Footer className="swipe-pop-footer">
        <Button
          className="swipe-pop-close-btn"
          onClick={closeHandler}
          size="lg"
          variant="link"
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WarningModal;