import React, { Component } from "react";
import "./messagesmatches.scss";
import MatchQueue from "./matchQueue/MatchQueue";
import { Badge } from "react-bootstrap";
import { motion } from "framer-motion";

export class MessagesMatches extends Component {
  state = {
    matchCount: null,
  };

  matchCount = (e) => {
    this.setState({ matchCount: e });
  };

  render() {
    const pageVariants = {
      in: {
        opacity: 1,
        x: 0,
      },
      out: {
        opacity: 0,
        x: "-100%",
      },
    };

    const pageTransition = {
      transition: "linear",
    };
    return (
      <motion.div
        className="messages-matches-component"
        transition={pageTransition}
        initial="out"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <div className="mm-title-wrap">
          <p className="mm-title">New Matches</p>
          {/* <Badge className="match-count-number">{this.state.matchCount}</Badge> */}
        </div>

        <MatchQueue
          matchCount={this.matchCount}
          chatToggle={this.props.chatToggle}
        />
      </motion.div>
    );
  }
}

export default MessagesMatches;
