import React, { useState, useEffect } from "react";
import { Image, Button } from "react-bootstrap";
import "./carouselstyles.scss";
import slideOne from "../../assets/Images/premiumFeaturesSlider/slide-one-unlimited-likes.jpg";
import { Link } from "react-router-dom";

function PremiumFeatures(props) {
  const [slide, setSlide] = useState(1);

  useEffect(() => {
    var slideCounter = 1;
    const interval = setInterval(() => {
      if (slideCounter < 6) {
        slideCounter++;
      } else {
        slideCounter = 1;
      }
      updateSlides(slideCounter);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const updateSlides = (e) => {
    setSlide(e);
  };

  let indicatorOneStyle = "carousel-indicator";
  let indicatorTwoStyle = "carousel-indicator";
  let indicatorThreeStyle = "carousel-indicator";
  let indicatorFourStyle = "carousel-indicator";
  let indicatorFiveStyle = "carousel-indicator";
  let indicatorSixStyle = "carousel-indicator";

  if (slide === 1) {
    indicatorOneStyle = "carousel-indicator-active";
  } else if (slide === 2) {
    indicatorTwoStyle = "carousel-indicator-active";
  } else if (slide === 3) {
    indicatorThreeStyle = "carousel-indicator-active";
  } else if (slide === 4) {
    indicatorFourStyle = "carousel-indicator-active";
  } else if (slide === 5) {
    indicatorFiveStyle = "carousel-indicator-active";
  } else if (slide === 6) {
    indicatorSixStyle = "carousel-indicator-active";
  }

  var option = null;

  if (props.option) {
    option = props.option;
  }

  return (
    <div className="carousel">
      {option !== "noButton" && (
        <h1 className="carousel-title">Premium Features</h1>
      )}
      <div className="carousel-slider">
        {slide === 1 && (
          <Image src={slideOne} className="carousel-slide" fluid />
        )}
        {slide === 2 && (
          <Image src={slideOne} className="carousel-slide" fluid />
        )}
        {slide === 3 && (
          <Image src={slideOne} className="carousel-slide" fluid />
        )}
        {slide === 4 && (
          <Image src={slideOne} className="carousel-slide" fluid />
        )}
        {slide === 5 && (
          <Image src={slideOne} className="carousel-slide" fluid />
        )}
        {slide === 6 && (
          <Image src={slideOne} className="carousel-slide" fluid />
        )}
      </div>
      <div className="carousel-slider-indicator">
        <div className="carousel-slider-innerwrap">
          <div className={indicatorOneStyle} />
          <div className={indicatorTwoStyle} />
          <div className={indicatorThreeStyle} />
          <div className={indicatorFourStyle} />
          <div className={indicatorFiveStyle} />
          <div className={indicatorSixStyle} />
        </div>
      </div>
      {option !== "noButton" && (
        <Link to="/app/premium">
          <Button className="premium-features-cta-button">
            Upgrade to Premium
          </Button>
        </Link>
      )}
    </div>
  );
}

export default PremiumFeatures;
