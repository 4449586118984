import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import "./sortbydropdown.scss";
import { UserContext } from "../../../../context/UserContext";

export class SortByDropdown extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      sortby: "Recently Active",
    };
  }

  toggleHandler = (props) => (e) => {
    var sortby = props;
    this.setState({ sortby: sortby });
    this.props.fetchUsers(sortby);
  };

  render() {
    return (
      <div className="grid-dropdown-wrapper">
        <Dropdown className="grid-dropdown">
          <Dropdown.Toggle
            variant="outline-secondary"
            className="grid-dropdown-btn"
          >
            {this.state.sortby}
          </Dropdown.Toggle>
          <Dropdown.Menu className="grid-dropdown-menu">
            {/* {this.state.sortby === "Top Picks" ? null : (
              <Dropdown.Item
                className="grid-dropdown-item"
                as="button"
                onClick={this.toggleHandler("Top Picks")}
                size="lg"
              >
                Top Picks
              </Dropdown.Item>
            )}
            {this.state.sortby === "Top Picks" ? null : <Dropdown.Divider />} */}

            {this.state.sortby === "Recently Active" ? null : (
              <Dropdown.Item
                className="grid-dropdown-item"
                as="button"
                onClick={this.toggleHandler("Recently Active")}
                size="lg"
              >
                Recently Active
              </Dropdown.Item>
            )}
            {this.state.sortby === "Recently Active" ||
            this.state.sortby === "Newest" ? null : (
              <Dropdown.Divider />
            )}

            {this.state.sortby === "Newest" ? null : (
              <Dropdown.Item
                className="grid-dropdown-item"
                as="button"
                onClick={this.toggleHandler("Newest")}
              >
                Newest
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

export default SortByDropdown;
