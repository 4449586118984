import React from "react";
import "./style.scss";

export const RiskScore = props => {
  const { riskScore } = props;
  const className = `icon icon-risk-score-${riskScore}`;

  return (
    <span className={className}></span>
  );
};

export default RiskScore;