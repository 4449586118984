import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./profilepage.scss";
import DesktopProfileV2 from "../../components/desktop/desktopV2/desktopProfileV2/DesktopProfileV2";

export class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { profileurl } = this.props.match.params;

    return <DesktopProfileV2 profileurl={profileurl} />;
  }
}

export default withRouter(ProfilePage);
