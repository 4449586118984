import React, { Component } from "react";
import "../welcome.scss";
import { Button, Image, Form, Alert } from "react-bootstrap";
import { UserContext } from "../../../context/UserContext";
import Axios from "axios";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { withRouter } from "react-router-dom";
import EmailValidator from "email-validator";
import BounceLoader from "react-spinners/BounceLoader";
import PhotoGrid from "../../userProfile/editProfile/editProfileV2Components/photoGrid/PhotoGrid";
import { api } from "../../../api";

export class Photos extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      disabledBtn: false,
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  handleDisable = (e) => {
    this.setState({ disabledBtn: e });
  };

  fetchData = async (e) => {
    const data = {
      userid: this.context.user._id,
    };

    return api
      .fetchEmail(data)
      .then((res) => {
        var email = res.data.email;

        this.setState({
          email: email,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="welcome-component-container-nonstick">
        <ProgressBar animated now={37.5} className="welcome-progress-bar" />
        <div className="welcome-content-wrapper-nonstick">
          <div className="welcome-title-wrap">
            <i className="far fa-camera welcome-icon"></i>
            <h1 className="welcome-title">Upload Photos & Videos</h1>
            <p className="welcome-subtitle">
              Upload photos or short videos of you so other users know what you
              look like.
            </p>
          </div>
          <div className="welcome-photo-grid-wrapper">
            <PhotoGrid />
          </div>
          <div className="welcome-photos-guidelines-wrapper">
            <h4 className="photos-guideline-title">Photo Guidelines:</h4>
            <ol className="photos-guidelines-list">
              <li className="photos-guidelines-list-item">
                No nudity or sexually explicit photos.
              </li>
              <li className="photos-guidelines-list-item">
                No photos containing or depicting illegal content.
              </li>
              <li className="photos-guidelines-list-item">
                No photos including children.
              </li>
              <li className="photos-guidelines-list-item">
                Cannot include contact information or social media handles.
              </li>
              <li className="photos-guidelines-list-item">
                Photos must include yourself.
              </li>
            </ol>
            <p className="photos-guidelines-disclaimer">
              * All uploaded photos will be reviewed before they are visable to
              other members.
            </p>
          </div>
        </div>
        <div className="welcome-btn-wrap-nonstick">
          <Button
            variant="outline-primary"
            className="welcome-btn-advance"
            href="/app/welcome/physicals"
            disabled={this.state.disabledBtn}
          >
            Next
          </Button>
          <Link to="/app/welcome/physicals" className="welcome-skip-link">
            Skip
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(Photos);
