import React, { Component } from "react";
import HomeNav from "../../../components/navbar/HomeNav/HomeNav";
import { Container, Image, Card, Button } from "react-bootstrap";
import "../homepages.scss";
import whiteLogo from "../../../assets/logos/logo_white.png";
import cardHeaderOne from "../../../assets/Images/sugar-daddy/header-one-sugar-daddy-card.jpg";
import cardHeaderTwo from "../../../assets/Images/sugar-daddy/header-two-sugar-daddy-card.jpg";
import cardHeaderThree from "../../../assets/Images/sugar-daddy/header-three-sugar-daddy-card.jpg";
import cardHeaderFour from "../../../assets/Images/sugar-daddy/header-four-sugar-daddy-card.jpg";
import HomeGrid from "../../../components/grid/homeGrid/HomeGrid";
import { Link } from "react-router-dom";
import HomeFooter from "../../../components/footers/homeFooter/HomeFooter";
import NumberOneCta from "../../../components/ctas/numberOneCta/NumberOneCta";
import MetaTags from "react-meta-tags";

export class SugarBaby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gender: "female",
    };
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <MetaTags>
          <title>
            How To Find A Sugar Baby | Ultimate Guide 2020 | Sugarbae{" "}
          </title>{" "}
          <meta
            name="description"
            content="Find an arrangement in your area online now. Sugar Dating 101 - the ultimate guide on how to find a arrangement.  Sugarbae is the #1 Arrangement dating app."
          />
        </MetaTags>
        <HomeNav />
        <div className="hero-section">
          <Container>
            <h2 className="sugar-baby-subtitle">How To Find A</h2>
            <h1 className="sugar-daddy-title">Sugar Baby</h1>
            <p className="sugar-daddy-hero-description">
              Millions of Men are choosing the Arrangement lifestyle. Here is
              how you can too.
            </p>
            <div className="sg-down-wrap">
              <p className="sg-down-arrow-txt">How It Works</p>
              <i className="fas fa-chevron-down sd-down-icon"></i>
            </div>
          </Container>
        </div>
        <div className="sd-getting-started">
          <h2 className="sd-gs-title">Getting Started</h2>
          <Container className="sd-gs-container">
            <div className="sd-gs-col">
              <i className="fal fa-user-plus getting-started-icon"></i>{" "}
              <h3 className="sd-gs-col-title">1. Join</h3>
              <p className="sd-gs-col-content">
                Meet millions of members by joining Sugarbae in as little as 5
                minutes. 100% Free. Setup your profile and choose your benefits.
              </p>
            </div>
            <div className="sd-gs-col">
              <i className="fal fa-comment-alt-lines getting-started-icon"></i>
              <h3 className="sd-gs-col-title">2. Seek</h3>
              <p className="sd-gs-col-content">
                Finally a dating experience where you can find what you are
                actually looking for. Find a mutually beneficial arrangement in
                just days.
              </p>
            </div>
            <div className="sd-gs-col">
              <i className="fab fa-hotjar getting-started-icon"></i>{" "}
              <h3 className="sd-gs-col-title">3. Benefit</h3>
              <p className="sd-gs-col-content">
                Experience the benefits of arrangements. Convenient,
                transparent, no strings attached dating that cuts straight to
                the point.
              </p>
            </div>
          </Container>
          <div className="sd-gs-btn-wrap">
            <Link to="/signup">
              <Button variant="outline-dark" className="sd-gs-btn">
                Get Started Now
              </Button>
            </Link>
          </div>
        </div>
        <div className="sugar-daddy-maincontent">
          <h2 className="sd-mc-title">Arrangements 101</h2>
          <h4 className="sd-mc-subtitle">Everything you need to know.</h4>
          <Container className="sd-mc-cardwrap">
            <Card border="dark" className="sd-mc-card">
              <Card.Img variant="top" src={cardHeaderOne} />
              <Card.Body>
                <Card.Title>
                  <h3 className="sd-mc-cardtitle">WHAT IS AN ARRANGEMENT?</h3>
                </Card.Title>
                <Card.Text>
                  An arrangement is where a successful man and an attractive
                  woman are upfront about their expectations and what they are
                  looking for in a relationship. On Sugarbae, millions of people
                  have found mutually beneficial arrangements.
                  <br></br>
                  <p className="sd-mc-card-text-list-title">
                    An arrangement is:
                  </p>
                  <ul className="hiwp-ul">
                    <li className="sd-mc-li">
                      Between a <strong>successful male</strong> and
                      <strong> attractive female</strong>.
                    </li>
                    <li className="sd-mc-li">
                      Expectations are clearly defined <strong>upfront</strong>.
                    </li>
                    <li className="sd-mc-li">
                      <strong>Convenient</strong>,{" "}
                      <strong>straight to the point</strong>,{" "}
                      <strong>no strings attached</strong> dating experience for
                      successful men and attractive women.
                    </li>
                    <li className="sd-mc-li">
                      <strong>Mutually beneficial</strong>. Both parties get
                      what they are looking for.
                    </li>
                  </ul>
                </Card.Text>
                <div className="sd-btn-wrap">
                  <Link to="/signup">
                    <Button variant="outline-dark">Get Started</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
            <Card border="dark" className="sd-mc-card">
              <Card.Img variant="top" src={cardHeaderTwo} />
              <Card.Body>
                <Card.Title>
                  <h3 className="sd-mc-cardtitle">
                    WHAT IS AN ATTRACTIVE MEMBER?
                  </h3>
                </Card.Title>
                <Card.Text>
                  An attractive member is an attractive, attentive, low
                  maintenance female that is giving and loving. She understands
                  how hard successful men work, and what they are looking for in
                  a relationship. She is eager to keep her man satisfied in
                  return for benefiting from his hard work and success.
                  <br></br>
                  <p className="sd-mc-card-text-list-title">
                    An Attractive Member is:
                  </p>
                  <ul className="hiwp-ul">
                    <li className="sd-mc-li">
                      An <strong>attractive female</strong> who is attentive and
                      seductive.
                    </li>
                    <li className="sd-mc-li">
                      <strong>No drama</strong>, and understands she may not be
                      a successful man's main priority.
                    </li>
                    <li className="sd-mc-li">
                      <strong>Loyal</strong> and{" "}
                      <strong>eager to satisfy</strong> her man in return for
                      the benefits of his success.
                    </li>
                    <li className="sd-mc-li">
                      Seeks <strong>adventure</strong>,{" "}
                      <strong>convenience</strong>, and{" "}
                      <strong>transparency</strong>. All of which are difficult
                      to find in traditional relationships.
                    </li>
                  </ul>
                </Card.Text>
                <div className="sd-btn-wrap">
                  <Link to="/signup">
                    <Button variant="outline-dark">Get Started</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
            <Card border="dark" className="sd-mc-card">
              <Card.Img variant="top" src={cardHeaderThree} />
              <Card.Body>
                <Card.Title>
                  <h3 className="sd-mc-cardtitle">WHAT ARE THE BENEFITS?</h3>
                </Card.Title>
                <Card.Text>
                  Arrangements have many benefits that are not available in a
                  traditional dating experience. Many men seek arranagements
                  that are no drama, no strings attached, and are mutually
                  beneficial. Expectations are clearly defined up front. There
                  are no games and both parties cut straight to the chase,
                  resulting in a mutually beneficial relationship where everyone
                  is satisfied.
                  <br></br>
                  <p className="sd-mc-card-text-list-title">
                    Arrangement Benefits Include:
                  </p>
                  <ul className="hiwp-ul">
                    <li className="sd-mc-li">
                      <strong>Convenient Dating:</strong> Arrangements are an
                      online dating shortcut. Both parties can find what they
                      are looking for efficiently, without games or delay.
                    </li>
                    <li className="sd-mc-li">
                      <strong>No Strings Attached: </strong>Expectations are
                      clearly defined upfront so if you are looking for a
                      casual, no strings attached dating experience, an
                      arrangement is for you.
                    </li>
                    <li className="sd-mc-li">
                      <strong>Find Love: </strong>Some members are looking for a
                      lifetime partner. The great thing about an arrangement is
                      whatever your ideal relationship is, you can find here.
                      Arrangements are about being transparent about your
                      expectations and desires.
                    </li>
                    <li className="sd-mc-li">
                      <strong>Travel companionship: </strong>Many successful
                      male members are looking for an attractive female to bring
                      on luxury vacations to spend time with. Find a beautiful
                      attractive women to bring on your next vacation.
                    </li>
                  </ul>
                </Card.Text>
                <div className="sd-btn-wrap">
                  <Link to="/signup">
                    <Button variant="outline-dark">Get Started</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
            <Card border="dark" className="sd-mc-card">
              <Card.Img variant="top" src={cardHeaderFour} />
              <Card.Body>
                <Card.Title>
                  <h3 className="sd-mc-cardtitle">
                    HOW DO I FIND AN ATTRACTIVE FEMALE?
                  </h3>
                </Card.Title>
                <Card.Text>
                  Luckily, our app has made it very easy to find an attractive
                  female member. On Sugarbae, you can set up your profile,
                  choose your benefits, and meet attractive females in less than
                  5 minutes. Sign up is 100% free.
                  <br></br>
                  <p className="sd-mc-card-text-list-title">
                    How To Find A Attractive Member:
                  </p>
                  <ul className="hiwp-ul">
                    <li className="sd-mc-li">
                      <strong>Take Action: </strong>The only way to find an
                      arrangement quickly is to take action and put yourself out
                      there. Join Sugarbae right now and get your profile setup
                      within 5 minutes.
                    </li>
                    <li className="sd-mc-li">
                      <strong>Know What You Want: </strong>
                      Arrangements are about finding your ideal relationship. In
                      order to do that, you need to know what you are looking
                      for. Take a few minutes to ask yourself what your ideal
                      arrangements looks like so you can clearly define your
                      expectations upfront.
                    </li>
                    <li className="sd-mc-li">
                      <strong>Initiate Conversation: </strong>Be ambitious and
                      active in your search for an attractive female. Don't
                      always wait for them to message you first. Using the
                      advanced search filters on Sugarbae, you can find exactly
                      what you are looking for and start conversations.
                    </li>
                    <li className="sd-mc-li">
                      <strong>Have a Great Profile: </strong>
                      Create a complete profile. Upload multiple photos of you.
                      Fill out all the profile information. Write a descriptive
                      bio and clearly explain what you are looking for. Put
                      effort into creating your profile to stand out from the
                      crowd.
                    </li>
                  </ul>
                </Card.Text>
                <div className="sd-btn-wrap">
                  <Link to="/signup">
                    <Button variant="outline-dark">Get Started</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>

        <NumberOneCta />
        <HomeGrid gender={this.state.gender} />
        <div className="sd-belowgrid-content">
          <h3 className="sd-bg-title">Sugarbae Features</h3>
          <h4 className="sd-bg-subtitle">Over 1 million satisfied users</h4>
          <Container className="sd-bg-container">
            <div className="sd-bg-features-wrapper">
              <div className="sd-bg-features-col">
                <i className="fas fa-venus-double sd-bg-features-icon"></i>{" "}
                <h4 className="sd-bg-features-title">
                  4x More Females Than Males
                </h4>
                <p className="sd-bg-feature-description">
                  On Sugarbae, there are 4 times as many female members as male
                  members. Meaning you get more selection and there is less
                  competition.
                </p>
              </div>
              <div className="sd-bg-features-col">
                <i className="fab fa-hotjar sd-bg-features-icon"></i>
                <h4 className="sd-bg-features-title">
                  Most Attractive Female's
                </h4>
                <p className="sd-bg-feature-description">
                  Society's most attractive females are interested in the
                  Arranagement lifestyle. Sugarbae has a higher percentage of
                  attractive females than any other dating app.
                </p>
              </div>
              <div className="sd-bg-features-col">
                <i className="fas fa-comment-alt-lines sd-bg-features-icon"></i>
                <h4 className="sd-bg-features-title">Real-Time Messaging</h4>
                <p className="sd-bg-feature-description">
                  Within minutes you can setup your profile and begin messaging
                  attractive females that are eager to find a man like you.
                  Chat, discuss an arragement, and plan to meet in real life.
                </p>
              </div>
              <div className="sd-bg-features-col">
                <i className="fas fa-hand-pointer sd-bg-features-icon"></i>
                <h4 className="sd-bg-features-title">Choose Your Benefits</h4>
                <p className="sd-bg-feature-description">
                  When creating your profile, you will select the specific
                  dating benefits that you are looking for. Arranagement's are
                  about finding your ideal arrangement, and cutting straight to
                  the point with no games or delay inbetween.
                </p>
              </div>
              <div className="sd-bg-features-col">
                <i className="fas fa-badge-check sd-bg-features-icon"></i>
                <h4 className="sd-bg-features-title">
                  Background Verified Profiles
                </h4>
                <p className="sd-bg-feature-description">
                  Sugarbae takes fake accounts, scams, and catfishes very
                  seriously. Over 65% of the profiles on Sugarbae are verified -
                  meaning you know who you are talking to is real and their
                  pictures are accurate.
                </p>
              </div>
              <div className="sd-bg-features-col">
                <i className="fas fa-gavel sd-bg-features-icon"></i>
                <h4 className="sd-bg-features-title">Men Have Power</h4>
                <p className="sd-bg-feature-description">
                  Traditional dating apps result in females having all the
                  power, with Men fighting for attention. On Sugarbae, men have
                  more power and can choose the attractive women that they want
                  to meet and interact with.
                </p>
              </div>
            </div>
          </Container>
        </div>
        <div className="sd-cta-two">
          <div className="sd-cta-img-wrap">
            <Image className="sd-cta-img" src={whiteLogo} />
          </div>
          <h2 className="sd-cta-title">Find Your Ideal Arrangement Today</h2>
          <div className="sd-cta-btn-wrap">
            <Link to="/signup" className="sd-cta-link">
              <Button className="sd-cta-btn" variant="outline-light">
                Join Free
              </Button>
            </Link>
          </div>
        </div>
        <HomeFooter />
      </div>
    );
  }
}

export default SugarBaby;
