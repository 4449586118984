import Axios from "axios";
import api from "../api/api";
import socket from "../helpers/Socket";

export const submitLike = async function (userid, cardid) {
  var match = false;
  var valid = false;
  const token = localStorage.getItem("auth");
  const baseurl = process.env.REACT_APP_API_URL;
  const url = baseurl + "/api/swipe/like";
  const urla = baseurl + "/api/swipe/likecheck";

  const data = {
    userid,
    cardid,
  };

  await api
    .swipeLikeCheck(data)
    .then((res) => {
      if (res.status === 200) {
        valid = res.data.valid;
      }
    })
    .catch((err) => {
      console.log(err);
    });

  if (valid) {
    await api
      .swipeLike(data)
      .then((res) => {
        if (res.status === 200) {
          match = res.data.match;
        }
      })
      .catch((err) => {});
  }

  if (match) {
    socket.emit("newmatch", {
      sender: userid,
      receiver: cardid,
      superlike: false,
    });
  } else if (valid) {
    socket.emit("newlike", {
      sender: userid,
      receiver: cardid,
    });
  }

  return { match, valid };
};

export const submitLikeSearch = async function (userid, cardid) {
  var match = false;
  var valid = false;
  const token = localStorage.getItem("auth");
  const baseurl = process.env.REACT_APP_API_URL;

  const data = {
    userid,
    cardid,
  };

  await api
    .swipeLike(data)
    .then((res) => {
      if (res.status === 200) {
        match = res.data.match;
        valid = true;
      }
    })
    .catch((err) => {});

  if (match) {
    socket.emit("newmatch", {
      sender: userid,
      receiver: cardid,
      superlike: false,
    });
  } else {
    socket.emit("newlike", {
      sender: userid,
      receiver: cardid,
    });
  }

  return { match, valid };
};

export const removeLikeSearch = async function (userid, cardid) {
  const data = {
    userid,
    cardid,
  };

  var valid = false;

  await api
    .swipeRemoveLike(data)
    .then((res) => {
      if (res.status === 200) {
        valid = true;
      }
    })
    .catch((err) => {});

  return { valid };
};

export const submitDislike = function (userid, cardid) {
  const token = localStorage.getItem("auth");
  const baseurl = process.env.REACT_APP_API_URL;
  const url = baseurl + "/api/swipe/dislike";
  const data = {
    userid,
    cardid,
  };

  return api
    .swipeDislike(data)
    .then((res) => {})
    .catch((err) => {
      console.log(err);
    });
};

export const submitSuperlike = async function (userid, cardid) {
  var match = false;
  var valid = false;
  const token = localStorage.getItem("auth");
  const baseurl = process.env.REACT_APP_API_URL;
  const url = baseurl + "/api/swipe/superlike";
  const urla = baseurl + "/api/swipe/superlikecheck";

  const data = {
    userid: userid,
    cardid: cardid,
  };

  await api
    .swipeSuperlikeCheck(data)
    .then((res) => {
      if (res.status === 200) {
        valid = res.data.valid;
      }
    })
    .catch((err) => {
      console.log(err);
    });

  if (valid) {
    await api
      .swipeSuperlike(data)
      .then((res) => {
        if (res.status === 200) {
          match = res.data.match;
        }
      })
      .catch((err) => {});
  }

  if (match) {
    socket.emit("newmatch", {
      sender: userid,
      receiver: cardid,
    });
  } else if (valid) {
    socket.emit("newlike", {
      sender: userid,
      receiver: cardid,
      superlike: true,
    });
  }

  return { valid, match };
};

export const submitUndo = async function (userid, cardid, action) {
  var valid = false;
  const token = localStorage.getItem("auth");
  const baseurl = process.env.REACT_APP_API_URL;
  const url = baseurl + "/api/swipe/undo";
  const urla = baseurl + "/api/swipe/undocheck";
  const data = {
    userid: userid,
    cardid: cardid,
    action: action,
  };

  await api
    .swipeUndoCheck(data)
    .then((res) => {
      if (res.status === 200) {
        valid = res.data.valid;
      }
    })
    .catch((err) => {});

  if (valid) {
    await api
      .swipeUndo(data)
      .then((res) => {
        if (res.status === 200) {
        }
      })
      .catch((err) => {});
  }

  return { valid };
};

export const submitRemoveLike = async function (userid, cardid) {
  const data = {
    userid: userid,
    cardid: cardid,
  };

  return api
    .swipeRemoveLike(data)
    .then((res) => {})
    .catch((err) => {});

  return;
};

export const submitBoost = async function (userid) {
  const data = {
    userid: userid,
  };

  var valid = false;
  var active = false;

  await api
    .swipeBoost(data)
    .then((res) => {
      if (res.status === 200) {
        valid = res.data.valid;
        active = res.data.active;
      }
    })
    .catch((err) => {});

  return { valid, active };
};

export const submitBoostV2 = async function (data) {
  var boostData = null;

  await api
    .swipeBoostV2(data)
    .then((res) => {
      if (res.status === 200) {
        boostData = res.data;
      }
    })
    .catch((err) => {});

  return boostData;
};

export const submitCompliment = async function (data) {
  var valid = false;

  var payload = {
    receiverID: data.receiver,
    message: data.message,
    diamondCount: data.diamondCount,
  };

  await api
    .submitCompliment(payload)
    .then((res) => {
      if (res.status === 200) {
        valid = true;
      }
    })
    .catch((err) => {
      console.log(err);
    });

  return valid;
};

export const submitNote = async function (userid, cardid, message) {
  const token = localStorage.getItem("auth");
  const baseurl = process.env.REACT_APP_API_URL;
  const url = baseurl + "/api/swipe/submitnote";

  const data = {
    userid: userid,
    cardid: cardid,
    message: message,
  };

  var valid = true;
  var match = false;

  await api
    .swipeNote(data)
    .then((res) => {
      if (res.status === 200) {
        match = res.data.match;
      }
    })
    .catch((err) => {});

  return { valid, match };
};
