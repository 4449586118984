import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../../context/UserContext';
import './spotlight.scss';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import SpotlightCard from './SpotlightCard';
import BoostModal from '../../../modals/boostModal/BoostModal';
import BoostStatsModal from '../../../modals/boostStatsModal/BoostStatsModal';
import PremiumModal from '../../../modals/premiumModal/PremiumModal';
import Media from '../../../media/Media';

import { Image as BootstrapImage } from 'react-bootstrap';
import boostPlusIcon from '../../../../assets/Icons/boost_plus.png';
var moment = require('moment');

function Spotlight(props) {
  const context = useContext(UserContext);
  const [boostOpen, setBoostOpen] = useState(false);
  const [boostStatsOpen, setBoostStatsOpen] = useState(false);
  const [spotlightCards, setSpotlightCards] = useState([]);
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);

  useEffect(() => {
    var tempCards = context.spotlight;
    function shuffleArray(tempCards) {
      for (let i = tempCards.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [tempCards[i], tempCards[j]] = [tempCards[j], tempCards[i]];
      }
    }
    setSpotlightCards(tempCards);
  }, []);

  var images = context.user.imgurl;
  var mainImage = null;
  if (images.length > 0) {
    mainImage = images[0];
  }

  const toggleProfile = (e) => {
    props.toggleProfile(e);
  };
  const toggleBoost = (e) => {
    if (context.user.premium) {
      if (context.boostData.boosted) {
        if (boostStatsOpen) {
          setBoostStatsOpen(false);
        } else {
          setBoostStatsOpen(true);
        }
      } else {
        if (boostOpen) {
          setBoostOpen(false);
        } else {
          setBoostOpen(true);
        }
      }
    } else {
      setPremiumModalOpen(true);
    }
  };
  const premiumCloseHandler = (e) => {
    setPremiumModalOpen(false);
  };

  if (context.boostData.boosted) {
    var expirationDate = context.boostData.boostExpirationDate;
    var now = moment();
    var dateType = 'hours';
    if (expirationDate) {
      expirationDate = moment(expirationDate);
      var difference = expirationDate.diff(now, 'hours');
      if (difference < 1) {
        difference = expirationDate.diff(now, 'minutes');
        dateType = 'minutes';
        if (difference < 1) {
          difference = expirationDate.diff(now, 'seconds');
          dateType = 'seconds';
        }
      }
    }
  }

  if (!props.finishedScrolling) {
    return null;
  } else {
    return (
      <div className="spotlight-component">
        {boostOpen && <BoostModal onClose={toggleBoost} show={boostOpen} />}
        {boostStatsOpen && (
          <BoostStatsModal onClose={toggleBoost} show={boostStatsOpen} />
        )}
        {premiumModalOpen && (
          <PremiumModal
            option="boost"
            show={premiumModalOpen}
            onClose={premiumCloseHandler}
          />
        )}

        <div className="spotlight-component-content-wrapper">
          {context.boostData.boosted ? (
            <div
              className="spotlight-component-cta-image"
              onClick={toggleBoost}
            >
              <div className="spotlight-component-cta-overlay-boosted">
                <i className="fas fa-bolt spotlight-cta-icon"></i>
                {dateType === 'hours' && (
                  <p className="spotlight-boosted-time">{difference} hrs</p>
                )}
                {dateType === 'minutes' && (
                  <p className="spotlight-boosted-time">{difference} min</p>
                )}
                {dateType === 'seconds' && (
                  <p className="spotlight-boosted-time">{difference} sec</p>
                )}
              </div>

              <Media image={mainImage} />

              {/* <Image
                publicId={
                  mainImage
                    ? getImgName(mainImage)
                    : "placeholder-user-image-thumbnail_vankru.png"
                }
                version={mainImage ? getImgVersion(mainImage) : "1590045309"}
                resource_type={
                  mainImage ? (checkGIF(mainImage) ? "video" : "image") : null
                }
                effect="loop"
                cloud_name="sugarbae"
                className="spotlight-cta-user-image"
                fluid
              >
                <Transformation
                  height="200"
                  video_sampling="20"
                  delay="250"
                  duration="10"
                  effect="loop"
                  quality="auto"
                  flags="lossy"
                />
              </Image> */}
            </div>
          ) : (
            <div
              className="spotlight-component-cta-image"
              onClick={toggleBoost}
            >
              <div className="spotlight-component-cta-overlay">
                <BootstrapImage
                  src={boostPlusIcon}
                  fluid
                  className="boost-plus-icon"
                />
              </div>
              <Media image={mainImage} />
              {/* <Image
                publicId={
                  mainImage
                    ? getImgName(mainImage)
                    : 'placeholder-user-image-thumbnail_vankru.png'
                }
                version={mainImage ? getImgVersion(mainImage) : '1590045309'}
                resource_type={
                  mainImage ? (checkGIF(mainImage) ? 'video' : 'image') : null
                }
                effect="loop"
                cloud_name="sugarbae"
                className="spotlight-cta-user-image"
                fluid
              >
                <Transformation
                  height="200"
                  video_sampling="20"
                  delay="250"
                  duration="10"
                  effect="loop"
                  quality="auto"
                  flags="lossy"
                />
              </Image> */}
            </div>
          )}

          {spotlightCards.map((user, index) => {
            return (
              <div className="spotlight-component-cta-image">
                <SpotlightCard
                  user={user}
                  index={index}
                  onClick={toggleProfile}
                />
              </div>
            );
          })}

          <div className="end-spacer" />
        </div>
      </div>
    );
  }
}

export default Spotlight;
