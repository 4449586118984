import React, { useState, useContext } from "react";
import "./desktopsliders.scss";
import { Image, Form } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import { UserContext } from "../../../../../context/UserContext";

function Cannabis(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [cannabis, setCannabis] = useState(filters.cannabis);

  const changeHandler = (e) => {
    const cannabisCopy = cannabis;

    if (e.target.value === "nonsmoker") {
      if (cannabisCopy.nonsmoker) {
        cannabisCopy.nonsmoker = false;
      } else {
        cannabisCopy.nonsmoker = true;
      }
    } else if (e.target.value === "lightsmoker") {
      if (cannabisCopy.lightsmoker) {
        cannabisCopy.lightsmoker = false;
      } else {
        cannabisCopy.lightsmoker = true;
      }
    } else if (e.target.value === "heavysmoker") {
      if (cannabisCopy.heavysmoker) {
        cannabisCopy.heavysmoker = false;
      } else {
        cannabisCopy.heavysmoker = true;
      }
    }

    setCannabis((prevState) => ({ ...prevState, ...cannabisCopy }));
  };

  const saveHandler = (e) => {
    props.updateHandler(cannabis);
  };
  const resetHandler = (e) => {};

  return (
    <div className="desktop-v2-filters-content">
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.onClose}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-icon">
              <i className="far fa-cannabis filter-sort-icon" />
            </div>
            <div className="desktop-v2-filters-slider-title">Cannabis</div>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div
              className="desktop-filter-slider-reset-button"
              onClick={resetHandler}
            >
              Reset
            </div>
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          <Form className="filter-slider-form">
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Non Smoker"
                type="checkbox"
                value="nonsmoker"
                checked={cannabis.nonsmoker}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Light Smoker"
                type="checkbox"
                value="lightsmoker"
                checked={cannabis.lightsmoker}
                onChange={changeHandler}
              />
            </Form.Group>
            <Form.Group className="filter-slider-form-group">
              <Form.Check
                className="filter-slider-form-check"
                label="Heavy Smoker"
                type="checkbox"
                value="heavysmoker"
                checked={cannabis.heavysmoker}
                onChange={changeHandler}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="desktop-filter-slider-footer" onClick={saveHandler}>
          <div className="desktop-filter-slider-apply-button">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default Cannabis;
