import React, { useState, useEffect, useContext } from 'react';
import './profile.scss';
import Axios from 'axios';
import { UserContext } from '../../context/UserContext';
import { Image as StampImage, Card, Button } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import Bio from '../swipe/swipeComponents/bio/Bio';
import Headline from '../swipe/swipeComponents/headline/Headline';
import UserInfo from '../swipe/swipeComponents/userInfo/UserInfo';
import AdditionalUserInfo from '../swipe/swipeComponents/addtionalUserInfo/AdditionalUserInfo';
import FinancialInfo from '../swipe/swipeComponents/financialInfo/FinancialInfo';
import { withRouter } from 'react-router-dom';
import ProfileActions from './profileComponents/profileActions/ProfileActions';
import superlikedStamp from '../../assets/stamps/superliked-stamp.png';
import likedStamp from '../../assets/stamps/liked-stamp.png';
import undoStamp from '../../assets/stamps/undo-stamp.png';
import {
  submitLike,
  submitSuperlike,
  submitUndo,
  submitNote,
} from '../../helpers/swipeActions';
import LikePop from '../../components/swipe/swipeComponents/swipePops/LikePop';
import Match from '../swipe/swipeComponents/match/Match';
import SuperlikePop from '../../components/swipe/swipeComponents/swipePops/SuperlikePop';
import UndoPop from '../../components/swipe/swipeComponents/swipePops/UndoPop';
import PhotoView from '../photoView';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../utils/getCloudinaryInfo';
import MainImage from '../swipe/swipeComponents/mainImage/MainImage';
import { api } from '../../api';
import Media from '../../components/media/Media';

var moment = require('moment');

function Profile(props) {
  const cardid = props.cardid;
  const context = useContext(UserContext);

  const [card, setCard] = useState({
    username: null,
    imgurl: null,
    age: null,
    height: null,
    ethnicity: null,
    bio: null,
    headline: null,
    lifestyle: null,
    lastlogin: null,
  });
  const [loading, setLoading] = useState(true);
  const [likeStatus, setLikeStatus] = useState(false);
  const [likeInvalid, setLikeInvalid] = useState(false);
  const [undoInvalid, setUndoInvalid] = useState(false);
  const [undoStamp, setUndoStamp] = useState(false);

  const [matched, setMatched] = useState(false);

  const [superlikeStatus, setSuperlikeStatus] = useState(false);
  const [superlikeInvalid, setSuperlikeInvalid] = useState(false);
  const [enableView, setEnableView] = useState(false);
  const [viewIndex, setViewIndex] = useState(null);

  useEffect(() => {
    const data = {
      userid: context.user._id,
      cardid: cardid,
    };

    const fetchData = async (e) => {
      return api
        .fetchProfile(data)
        .then((res) => {
          if (res.status === 200) {
            var data = res.data.card;
            var like = res.data.like;

            var likeStatus = false;
            var superlikeStatus = false;

            if (like !== null && like !== undefined) {
              var superlike = like.superlike;
              if (superlike) {
                superlikeStatus = true;
              } else {
                likeStatus = true;
              }
            }

            setCard({
              username: data.username,
              imgurl: data.imgurl,
              age: data.age,
              ethnicity: data.ethnicity,
              gender: data.gender,
              height: data.height,
              bio: data.bio,
              headline: data.headline,
              lifestyle: data.lifestyle,
              address: data.address,
              lastlogin: data.lastlogin,
              verifications: data.verifications,
            });
            setLikeStatus(likeStatus);
            setSuperlikeStatus(superlikeStatus);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
  }, [cardid]);

  const closeHandler = (e) => {
    props.closeHandler();
  };

  const chatHandler = (e) => {
    props.chatHandler(cardid);
  };

  const likeHandler = async (e) => {
    var userid = context.user._id;

    const like = await submitLike(userid, cardid).catch((err) => {
      console.log(err);
    });

    var valid = like.valid;
    var match = like.match;

    if (valid) {
      if (match) {
        setMatched(true);
        setLikeStatus(true);
      } else {
        setLikeStatus(true);
      }

      if (props.location.pathname.includes('/search')) {
        props.likeHandler(cardid);
      }
    } else {
      setLikeStatus(false);
      setLikeInvalid(true);
    }
  };

  const superlikeHandler = async (e) => {
    var userid = context.user._id;

    const superlike = await submitSuperlike(userid, cardid).catch((err) => {
      console.log(err);
    });

    var valid = superlike.valid;
    var match = superlike.match;

    if (valid) {
      if (match) {
        setMatched(true);
        setSuperlikeStatus(true);
      } else {
        setSuperlikeStatus(true);
      }

      if (props.location.pathname.includes('/search')) {
        props.superlikeHandler(cardid);
      }
    } else {
      setSuperlikeStatus(false);
      setSuperlikeInvalid(true);
    }
  };

  const undoHandler = async (e) => {
    setUndoStamp(true);
    var userid = context.user._id;
    var action = '';

    if (likeStatus) {
      action = 'like';
    } else if (superlikeStatus) {
      action = 'superlike';
    }

    const undo = await submitUndo(userid, cardid, action).catch((err) => {
      console.log(err);
    });

    var valid = undo.valid;

    if (valid) {
      setLikeStatus(false);
      setSuperlikeStatus(false);

      if (props.location.pathname.includes('/search')) {
        props.undoHandler(cardid, action);
      }
    } else {
      setUndoInvalid(true);
    }

    setUndoStamp(false);
  };

  const closePopHandler = (e) => {
    setLikeInvalid(false);
    setMatched(false);
    setUndoInvalid(false);
    setSuperlikeInvalid(false);
  };

  const showImage = (props) => {
    setViewIndex(props);
    setEnableView(true);
  };

  const closeImage = () => {
    setEnableView(false);
  };

  if (loading) {
    return null;
  } else {
    var images = card.imgurl;
    var imageCount = images.length;
    var mainImage = images[0];
    var mainImageThumb = images[0];

    var imageWidth = '100%';
    var imageHeight = '350px';
    if (imageCount > 1) {
      imageWidth = '45%';
      imageHeight = '200px';
    }

    var imageStyle = {
      width: imageWidth,
      height: imageHeight,
      borderRadius: '15px',
      objectFit: 'cover',
      marginRight: '5px',
    };

    var lastlogin = moment(card.lastlogin);
    var lastHour = moment().subtract(5, 'hours');
    var online = lastlogin.isAfter(lastHour);
    online = true;

    var verifications = card.verifications;
    var verified = true;
    if (verifications.email.status && verifications.phone.status) {
      verified = true;
    }

    var vh = window.innerHeight + 'px';

    var profileComponentStyle = {
      height: vh,
      maxHeight: vh,
    };

    return (
      <div
        className="profile-component-container"
        style={profileComponentStyle}
      >
        {likeInvalid && (
          <LikePop closeHandler={closePopHandler} cardid={cardid} />
        )}
        {matched && (
          <Match matchid={cardid} closeMatchHandler={closePopHandler} />
        )}
        {superlikeInvalid && (
          <SuperlikePop closeHandler={closePopHandler} cardid={cardid} />
        )}
        {undoInvalid && (
          <UndoPop closeHandler={closePopHandler} cardid={cardid} />
        )}

        <div className="profile-component-card">
          <div className="profile-component-card-header">
            <Button
              variant="link"
              className="profile-card-close-btn"
              onClick={closeHandler}
            >
              <i className="fas fa-chevron-left profile-back-icon"></i>
            </Button>
            <div className="profile-card-header-username-wrap">
              <Media image={mainImageThumb} />

              {/* <Image
                publicId={
                  mainImageThumb
                    ? getImgName(mainImageThumb)
                    : "larger-placeholder-user-image_vvdghq.png"
                }
                version={
                  mainImageThumb ? getImgVersion(mainImageThumb) : "1590046357"
                }
                resource_type={
                  mainImageThumb
                    ? checkGIF(mainImageThumb)
                      ? "video"
                      : "image"
                    : null
                }
                cloud_name="sugarbae"
                className="profile-card-header-image"
                roundedCircle
              >
                <Transformation
                  height="200"
                  video_sampling="20"
                  delay="250"
                  duration="10"
                  effect="loop"
                  quality="auto"
                  flags="lossy"
                />
              </Image> */}
              <h1 className="profile-card-header-title">{card.username}</h1>
            </div>
            <Button
              variant="link"
              className="profile-card-close-btn"
              onClick={closeHandler}
            >
              <i className="fas fa-shield profile-card-safety-icon"></i>
            </Button>
          </div>
          <div className="profile-component-card-body">
            <div
              className="profile-card-main-image-wrapper"
              onClick={() => showImage(0)}
            >
              {likeStatus && (
                <StampImage
                  src={likedStamp}
                  className="liked-stamp-image-profile"
                />
              )}
              {superlikeStatus && (
                <StampImage
                  src={superlikedStamp}
                  className="liked-stamp-image-profile"
                />
              )}
              {undoStamp && (
                <StampImage
                  src={undoStamp}
                  className="liked-stamp-image-profile"
                />
              )}
              {enableView && (
                <PhotoView
                  images={images}
                  index={viewIndex}
                  closeHandler={closeImage}
                />
              )}

              <Media image={mainImage} />
              {/* <Image
                publicId={
                  mainImage
                    ? getImgName(mainImage)
                    : 'larger-placeholder-user-image_vvdghq.png'
                }
                version={mainImage ? getImgVersion(mainImage) : '1590046357'}
                resource_type={
                  mainImage ? (checkGIF(mainImage) ? 'video' : 'image') : null
                }
                effect="loop"
                cloud_name="sugarbae"
                className="profile-card-main-image"
              >
                <Transformation
                  height="200"
                  video_sampling="20"
                  delay="250"
                  duration="10"
                  effect="loop"
                  quality="auto"
                  flags="lossy"
                />
              </Image> */}
              <div className="profile-card-main-image-user-info-wrapper">
                {online && (
                  <div className="profile-card-online-wrapper">
                    <i className="fas fa-circle profile-card-online-icon"></i>
                    <span className="profile-card-online-text">Online</span>
                  </div>
                )}
                <div className="profile-card-user-info-section">
                  <h1 className="profile-card-main-image-username">
                    {card.username}
                  </h1>
                  {verified && (
                    <i className="fad fa-badge-check profile-card-verified-icon"></i>
                  )}
                </div>
                <div className="profile-card-user-info-section">
                  <span className="profile-card-user-info-text">
                    {card.address} • {card.gender} • {card.age}
                  </span>
                </div>
              </div>
            </div>
            {imageCount > 1 && (
              <div className="profile-card-images-wrapper">
                {images.map((image, index) => {
                  if (index === 0) {
                    return null;
                  } else {
                    return (
                      <div onClick={() => showImage(index)}>
                        <Media image={image} />
                      </div>
                      // <Image
                      //   publicId={getImgName(image)}
                      //   version={getImgVersion(image)}
                      //   resource_type={checkGIF(image) ? 'video' : 'image'}
                      //   effect="loop"
                      //   cloud_name="sugarbae"
                      //   className="profile-card-image"
                      //   style={imageStyle}
                      //   onClick={() => showImage(index)}
                      // >
                      //   <Transformation
                      //     height="200"
                      //     video_sampling="20"
                      //     delay="250"
                      //     duration="10"
                      //     effect="loop"
                      //     quality="auto"
                      //     flags="lossy"
                      //   />
                      // </Image>
                    );
                  }
                })}
              </div>
            )}
            <Headline card={card} />
            <Bio card={card} />
            <UserInfo card={card} />
            <FinancialInfo card={card} />
            <AdditionalUserInfo card={card} />
          </div>
          <ProfileActions
            chatHandler={chatHandler}
            likeHandler={likeHandler}
            superlikeHandler={superlikeHandler}
            undoHandler={undoHandler}
            likeStatus={likeStatus}
            superlikeStatus={superlikeStatus}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(Profile);
