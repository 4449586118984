import React, { Component } from "react";
import "../help.scss";
import SwipeNav from "../../../components/navbar/SwipeNav";

export class Safety extends Component {
  render() {
    let vh = window.innerHeight;
    var height = vh + "px";
    return (
      <div
        className="app-component"
        style={{ height: height, maxHeight: height }}
      >
        <SwipeNav />

        <div className="help-container">
          <div className="help-page-content-wrap">
            <div className="safety-icon-wrap">
              <i className="far fa-user-shield safety-page-icon"></i>
            </div>
            <h1 className="help-page-title">Have a Safe Experience</h1>
            <h3 className="help-page-subtitletwo">
              Sugarbae encourages all members to take simple precautions when
              communicating with other members.
            </h3>
            <h3 className="help-page-subtitletwo">
              Ultimately your privacy and security rests in your own hands. To
              avoid an awkward or uncomfortable situation with another member,
              check out our safety tips:
            </h3>
            <h3 className="help-page-subtitle">Tips</h3>
            <ol>
              <li>Never send any money to someone you have not met.</li>
              <li>Never give your bank account details to anyone.</li>
              <li>Never buy photos or videos from anyone.</li>
              <li>Never buy gift cards for anyone.</li>
              <li>Be careful taking the conversation outside of Sugarbae.</li>
              <li>
                Report anyone you believe is behaving suspiciously to keep
                yourself and other Sugarbae members safe.
              </li>
              <li>
                Don’t share your password, access to your account or any
                official identity document with anyone.
              </li>
              <li>
                Report anyone attempting to sell escort or massage services so
                we can ban the member permanently. Escorts are strictly
                forbidden from using Sugarbae.
              </li>
              <li>
                Be careful with how much personal information and contact
                details you disclose.
              </li>
              <li>Always meet in a public place.</li>
            </ol>

            <h3 className="help-page-subtitle">Don't worry</h3>

            <ol>
              <li>
                If at anytime during your communication, the other member makes
                the decision to stop developing your relationship.
              </li>
              <li>
                If a member doesn’t answer all of your questions or reply to all
                of your messages.
              </li>
              <li>
                If a member has photos of relatives, friends, ex-partners and
                other personal info posted on any social network.
              </li>
              <li>
                If a member wears rings on their fingers. Wearing rings does not
                necessarily mean a person is married, regardless of which finger
                it is on.
              </li>
            </ol>
            <p>
              Hopefully you found the above information useful as you embark on
              your sugar journey! If you have questions or other concerns, we
              encourage you to reach out to us directly, read the articles on
              our blog or check out our FAQs.
            </p>
            <p>Wishing you the best on your sugar adventures!</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Safety;
