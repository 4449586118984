import React, { Component } from "react";
import SignupForm from "../../components/signupform/SignupForm";
import ValidateToken from "../../helpers/ValidateToken";
import MetaTags from "react-meta-tags";
import HomeNav from "../../components/navbar/HomeNav/HomeNav";
import HomeFooter from "../../components/footers/homeFooter/HomeFooter";

export class Signup extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
    if (ValidateToken() !== false) return this.props.history.push("/app");
  }
  render() {
    return (
      <div className="home-page-container">
        <HomeNav />{" "}
        <MetaTags>
          <title>Sugarbae Signup | Create Your Free Account | Sugarbae</title>{" "}
          <meta
            name="description"
            content="Join Sugarbae 100% free and find your ideal arrangement. Over 1 million active users. Find a Sugar Daddy or Sugar Baby today. The online dating shortcut."
          />
        </MetaTags>
        <SignupForm />
        <HomeFooter />
      </div>
    );
  }
}

export default Signup;
