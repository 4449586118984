import React, { useState, useContext, useEffect, useRef } from "react";
import "./verification.scss";
import { Button, Form, InputGroup } from "react-bootstrap";
import { UserContext } from "../../context/UserContext";
import ReactCodeInput from "react-verification-code-input";
import { api } from "../../api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function PhoneVerifyV2(props) {
  const context = useContext(UserContext);
  const [number, setNumber] = useState(context.user.verifications.phone.number);
  const [codeOpen, setCodeOpen] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [validationCode, setValidationCode] = useState("");
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
  const [sendAgainSuccess, setSendAgainSuccess] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [numberInvalid, setNumberInvalid] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numberExistsError, setNumberExistsError] = useState(false);
  const [numberInvalidError, setNumberInvalidError] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    var noPhoneNumber = true;
    if (
      context.user.verifications.phone.number !== null &&
      context.user.verifications.phone.number !== undefined
    ) {
      if (context.user.verifications.phone.number.length > 5) {
        noPhoneNumber = false;
      }
    }

    if (noPhoneNumber) {
      setNumber("");
      setDisabled(false);
    }
  }, []);

  const disableHandler = (e) => {
    if (disabled) {
      setNumber("");
      setSubmitDisabled(true);
      setDisabled(false);
    } else {
      setNumber(context.user.verifications.phone.number);
      setDisabled(true);
      resetErrors();
    }
  };

  const sendSmsCode = async (e) => {
    const data = {
      user: context.user._id,
      phonenumber: number,
    };

    return api
      .generateSmsToken(data)
      .then((res) => {
        if (res.status === 200) {
          context.updateUserInfo("verifications", res.data.verifications);
        }
      })
      .catch((err) => {});
  };

  const sendAgainHandler = (e) => {
    sendSmsCode();
    setSendAgainSuccess(true);
  };

  const validationCodeChange = async (e) => {
    setVerifyError(false);
    setValidationCode(e);

    if (e.length === 6) {
      setValidateLoading(true);

      const data = {
        validationcode: e,
      };

      await api
        .validateSmsToken(data)
        .then((res) => {
          if (res.status === 200) {
            context.updateUserInfo("verifications", res.data.verifications);
            props.handleSuccess();
          }
        })
        .catch((err) => {
          console.log(err);
          setValidateLoading(false);
          setVerifyError(true);
        });
    }
  };

  const changeHandler = (e) => {
    setNumber(e);
    setNumberInvalid(false);

    if (e.length < 17) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  };

  const verifyHandler = (e) => {
    if (codeOpen) {
      resetErrors();
      setDisabled(true);
      setCodeOpen(false);
    } else {
      resetErrors();
      setVerifyButtonDisabled(true);
      sendSmsCode();
      setCodeOpen(true);
    }
  };

  const resetErrors = (e) => {
    setSendAgainSuccess(false);
    setVerifyButtonDisabled(false);
    setNumberInvalid(false);
    setNumberExistsError(false);
    setNumberInvalidError(false);
  };
  const updateHandler = async (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    setLoading(true);
    verifyHandler();
  };

  return (
    <div>
      {codeOpen ? (
        <h1 className="verification-content-title">
          Enter the 6 digit verification code we texted you:
        </h1>
      ) : (
        <h1 className="verification-content-title">
          {disabled ? "Is this your number?" : "Enter your cell phone number:"}
        </h1>
      )}

      {codeOpen ? (
        <div className="verification-code-wrapper">
          <ReactCodeInput
            onChange={validationCodeChange}
            loading={validateLoading}
            disabled={validateLoading}
            fieldWidth={"16%"}
            className="verification-code-input-v2"
          />
          <div className="edit-screen-button-wrapper">
            {sendAgainSuccess ? (
              <span className="new-code-sent-text">New code sent</span>
            ) : (
              <Button
                className="edit-screen-send-again-button"
                onClick={sendAgainHandler}
              >
                Send again
              </Button>
            )}
          </div>
        </div>
      ) : (
        <Form className="edit-screen-email-form">
          <Form.Group className="phone-verification-v2-wrapper">
            {number !== null && number !== undefined ? (
              <PhoneInput
                country={"us"}
                value={number}
                onChange={changeHandler}
                preferredCountries={["us", "ca", "gb"]}
                disabled={disabled}
              />
            ) : null}
          </Form.Group>
        </Form>
      )}
      {numberInvalidError && (
        <div className="edit-screen-status-wrapper">
          <i className="far fa-exclamation-circle number-alert-icon"></i>
          <span>The number you entered in invalid</span>
        </div>
      )}

      {numberExistsError && (
        <div className="edit-screen-status-wrapper">
          <i className="far fa-exclamation-circle number-alert-icon"></i>
          <span>The number you entered already exists in our system</span>
        </div>
      )}
      {verifyError && (
        <div className="edit-screen-status-wrapper">
          <i className="far fa-exclamation-circle number-alert-icon"></i>
          <span>The verification code you entered is incorrect</span>
        </div>
      )}
      {codeOpen ? (
        <div className="verification-footer">
          <Button
            className="verification-footer-button-top"
            variant="link"
            onClick={verifyHandler}
          >
            Back
          </Button>

          <Button className="verification-footer-button" variant="danger">
            Confirm
          </Button>
        </div>
      ) : (
        <div className="verification-footer">
          {disabled ? (
            <Button
              className="verification-footer-button-top"
              variant="link"
              onClick={disableHandler}
            >
              No, I need to update it
            </Button>
          ) : (
            <Button
              className="verification-footer-button-top"
              variant="link"
              onClick={disableHandler}
            >
              Back
            </Button>
          )}

          {disabled ? (
            <Button
              className="verification-footer-button"
              variant="danger"
              onClick={verifyHandler}
            >
              Yes, that's my number
            </Button>
          ) : (
            <Button
              className="verification-footer-button"
              variant="danger"
              onClick={updateHandler}
            >
              Confirm Number
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default PhoneVerifyV2;
