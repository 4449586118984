import React from "react";
import "./socials.scss";

function Socials(props) {
  var facebookVerified = false;
  var instagramVerified = false;
  var twitterVerified = false;
  var linkedinVerified = false;

  var facebookStyle = "profile-v2-socials-badge";
  var instagramStyle = "profile-v2-socials-badge";
  var twitterStyle = "profile-v2-socials-badge";
  var linkedinStyle = "profile-v2-socials-badge";

  var facebookIconStyle = "fab fa-facebook-f facebook-verification-icon";
  var instagramIconStyle = "fab fa-instagram instagram-verification-icon";
  var twitterIconStyle = "fab fa-twitter twitter-verification-icon";
  var linkedinIconStyle = "fab fa-linkedin-in linkedin-verification-icon";

  if (facebookVerified) {
    facebookStyle = "profile-v2-socials-badge-verified";
    facebookIconStyle = "fab fa-facebook-f facebook-verification-icon-verified";
  }

  if (instagramVerified) {
    instagramStyle = "profile-v2-socials-badge-verified";
    instagramIconStyle =
      "fab fa-instagram instagram-verification-icon-verified";
  }
  if (twitterVerified) {
    twitterStyle = "profile-v2-socials-badge-verified";
    twitterIconStyle = "fab fa-twitter twitter-verification-icon-verified";
  }
  if (linkedinVerified) {
    linkedinStyle = "profile-v2-socials-badge-verified";
    linkedinIconStyle =
      "fab fa-linkedin-in linkedin-verification-icon-verified";
  }

  return (
    <div className="profile-v2-socials-component">
      <h3 className="benefits-title">Socials</h3>
      <div className="profile-v2-socials-content">
        <div className={facebookStyle}>
          <i className={facebookIconStyle}></i>
          {facebookVerified && (
            <div className="profile-v2-verifications-icon-wrapper">
              <i className="fad fa-check-circle profile-v2-verification-icon"></i>
            </div>
          )}
        </div>
        <div className={instagramStyle}>
          <i className={instagramIconStyle}></i>

          {instagramVerified && (
            <div className="profile-v2-verifications-icon-wrapper">
              <i className="fad fa-check-circle profile-v2-verification-icon"></i>
            </div>
          )}
        </div>
        <div className={twitterStyle}>
          <i className={twitterIconStyle}></i>

          {twitterVerified && (
            <div className="profile-v2-verifications-icon-wrapper">
              <i className="fad fa-check-circle profile-v2-verification-icon"></i>
            </div>
          )}
        </div>
        <div className={linkedinStyle}>
          <i className={linkedinIconStyle}></i>

          {linkedinVerified && (
            <div className="profile-v2-verifications-icon-wrapper">
              <i className="fad fa-check-circle profile-v2-verification-icon"></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Socials;
