export const countBenefits = async function (benefits) {
  var count = 0;

  if (benefits) {
    if (benefits.ActiveLifestyle) {
      count = count + 1;
    }
    if (benefits.EmotionalConnection) {
      count = count + 1;
    }
    if (benefits.FlexibleSchedule) {
      count = count + 1;
    }
    if (benefits.Attentive) {
      count = count + 1;
    }
    if (benefits.LongTerm) {
      count = count + 1;
    }
    if (benefits.AllEthnicities) {
      count = count + 1;
    }
    if (benefits.Discretion) {
      count = count + 1;
    }
    if (benefits.FineDining) {
      count = count + 1;
    }
    if (benefits.FriendsWithBenefits) {
      count = count + 1;
    }
    if (benefits.Investor) {
      count = count + 1;
    }
    if (benefits.LifeOfLeisure) {
      count = count + 1;
    }
    if (benefits.LuxuryLifestyle) {
      count = count + 1;
    }
    if (benefits.MarriageMinded) {
      count = count + 1;
    }
    if (benefits.Mentorship) {
      count = count + 1;
    }
    if (benefits.Monogamous) {
      count = count + 1;
    }
    if (benefits.NoStringsAttached) {
      count = count + 1;
    }
    if (benefits.NonMonogamous) {
      count = count + 1;
    }
    if (benefits.OpenRelationship) {
      count = count + 1;
    }
    if (benefits.PassportReady) {
      count = count + 1;
    }
    if (benefits.Platonic) {
      count = count + 1;
    }
    if (benefits.Romance) {
      count = count + 1;
    }
    if (benefits.ShowsAndEntertainment) {
      count = count + 1;
    }
    if (benefits.TravelToYou) {
      count = count + 1;
    }
    if (benefits.TravelWithMe) {
      count = count + 1;
    }
    if (benefits.Vacations) {
      count = count + 1;
    }
  }

  return count;
};

export const countUserInfo = async function (user) {
  var count = 0;

  if (user) {
    if (user.lifestyle.relationship) {
      count = count + 1;
    }

    if (user.height) {
      count = count + 1;
    }

    if (user.bodytype) {
      count = count + 1;
    }

    if (user.lifestyle.smoking) {
      count = count + 1;
    }

    if (user.lifestyle.ethnicity) {
      count = count + 1;
    }
    if (user.lifestyle.drinking) {
      count = count + 1;
    }
    if (user.lifestyle.cannabis) {
      count = count + 1;
    }
    if (user.lifestyle.children) {
      count = count + 1;
    }
    if (user.lifestyle.exercise) {
      count = count + 1;
    }
    if (user.lifestyle.education) {
      count = count + 1;
    }
  }

  return count;
};
