import React from 'react';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import './desktopchatcard.scss';
import Media from '../../../../components/media/Media';

var moment = require('moment');

function DesktopChatCard(props) {
  var username = props.message.senderusername;
  var image = props.message.senderimage;

  var createdAt = moment(props.message.createdAt);
  var timeDifference = moment().diff(createdAt, 'hours');
  var timestampText = 'about ' + timeDifference + ' hours ago';

  if (timeDifference > 24) {
    timeDifference = moment().diff(createdAt, 'days');
    timestampText = 'about ' + timeDifference + ' days ago';
  } else if (timeDifference < 1) {
    timeDifference = moment().diff(createdAt, 'minutes');
    if (timeDifference < 1) {
      timeDifference = moment().diff(createdAt, 'seconds');
      if (timeDifference < 10) {
        timestampText = 'just now';
      } else {
        timestampText = 'about ' + timeDifference + ' seconds ago';
      }
    } else {
      timestampText = 'about ' + timeDifference + ' minutes ago';
    }
  }

  return (
    <div className="desktop-chat-card-component">
      <div className="desktop-chat-card-image-wrap">
        {image ? (
          <Media image={image} />
        ) : (
          // <Image
          //   publicId={getImgName(image)}
          //   version={getImgVersion(image)}
          //   resource_type={checkGIF(image) ? "video" : "image"}
          //   effect="loop"
          //   cloud_name="sugarbae"
          //   className="desktop-chat-card-image"
          // >
          //   <Transformation
          //     height="200"
          //     video_sampling="20"
          //     delay="250"
          //     duration="10"
          //     effect="loop"
          //     quality="auto"
          //     flags="lossy"
          //   />
          // </Image>
          <div className="desktop-chat-card-image-placeholder">
            <i className="far fa-user desktop-chat-card-placeholder-icon"></i>
          </div>
        )}
      </div>
      <div className="desktop-chat-card-content-wrap">
        <div className="desktop-chat-card-username-wrap">
          <h2 className="desktop-chat-card-username">{username}</h2>
          <p className="desktop-chat-card-timestamp">{timestampText}</p>
        </div>
        <div className="desktop-chat-message-content-wrap">
          <p className="desktop-chat-message-content">
            {props.message.message}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DesktopChatCard;
