import React, { useState, useEffect, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import "./filtersliderstyles.scss";
import PlacesAutocomplete from "react-places-autocomplete";
import GoogleMapReact from "google-map-react";
import { UserContext } from "../../../context/UserContext";
import { api } from "../../../api";

function Location(props) {
  const context = useContext(UserContext);
  const { filters } = props;
  const [address, setAddress] = useState(filters.address);
  const [changingAddress, setChangingAddress] = useState("");
  const [updateLocationOpen, setUpdateLocationOpen] = useState(false);
  const [showMapPreview, setShowMapPreview] = useState(false);
  const [previewCoordinates, setPreviewCoordinates] = useState([]);

  const changeHandler = (e) => {
    setChangingAddress(e);
  };
  const commitHandler = async (e) => {
    setAddress(e);
    setChangingAddress(e);

    const data = {
      address: e,
    };

    await api
      .fetchAddressCoordinates(data)
      .then((res) => {
        if (res.status === 200) {
          setPreviewCoordinates(res.data.coordinates);
          setShowMapPreview(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveHandler = (e) => {
    filters.address = address;
    props.updateHandler(address);
  };

  const searchOptions = {
    types: ["(cities)"],
  };

  const editLocationToggle = (e) => {
    if (updateLocationOpen) {
      setUpdateLocationOpen(false);
    } else {
      setUpdateLocationOpen(true);
    }
  };

  return (
    <div className="filter-slider-v2">
      <div className="filter-slider-v2-overlay" onClick={props.onClose} />

      <div className="filter-slider-v2-content">
        <div className="filter-slider-v2-close-bar" />
        <div className="filter-slider-v2-pop">
          <div className="filter-slider-v2-header">
            <div className="filter-slider-v2-header-section">
              <i className="far fa-home sort-v2-heart-icon" />
              <span className="filter-slider-v2-title">Location</span>
            </div>
            <div className="filter-slider-v2-header-section">
              {updateLocationOpen ? (
                <span
                  className="filter-slider-v2-reset-text"
                  onClick={editLocationToggle}
                >
                  Reset
                </span>
              ) : (
                <span
                  className="filter-slider-v2-reset-text"
                  onClick={editLocationToggle}
                >
                  Edit
                </span>
              )}
            </div>
          </div>

          {!updateLocationOpen && (
            <h1 className="location-title">
              You are searching for users in{" "}
              <span style={{ fontWeight: "700" }}>{filters.address}.</span>
            </h1>
          )}
          {!updateLocationOpen && (
            <div className="location-map-wrapper">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyCoSbKzFFYZ2HnK-jOZwIqdhwp7Aq4fu4I",
                }}
                defaultCenter={{
                  lat: filters.location.coordinates[1],
                  lng: filters.location.coordinates[0],
                }}
                defaultZoom={11}
                options={{
                  panControl: false,
                  mapTypeControl: false,
                  scrollwheel: false,
                  disableDefaultUI: true,
                }}
                defaultOptions={{
                  fullscreenControl: false,
                }}
              />
            </div>
          )}

          {updateLocationOpen && (
            <div className="filter-slider-wrapper-location">
              <Form className="filter-slider-form-location">
                <Form.Group className="filter-slider-form-group-location">
                  <PlacesAutocomplete
                    value={changingAddress}
                    onChange={changeHandler}
                    onSelect={commitHandler}
                    searchOptions={searchOptions}
                    shouldFetchSuggestions
                    highlightFirstSuggestion
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="location-v2-input-wrapper">
                        <Form.Control
                          size="lg"
                          {...getInputProps({
                            placeholder: "Enter your city",
                            className: "location-v2-input",
                          })}
                          autoFocus
                        />

                        {showMapPreview ? (
                          <div className="location-map-wrapper">
                            <GoogleMapReact
                              bootstrapURLKeys={{
                                key: "AIzaSyCoSbKzFFYZ2HnK-jOZwIqdhwp7Aq4fu4I",
                              }}
                              defaultCenter={{
                                lat: previewCoordinates[1],
                                lng: previewCoordinates[0],
                              }}
                              defaultZoom={11}
                              options={{
                                panControl: false,
                                mapTypeControl: false,
                                scrollwheel: false,
                                disableDefaultUI: true,
                              }}
                              defaultOptions={{
                                fullscreenControl: false,
                              }}
                            />
                          </div>
                        ) : (
                          <div className="location-v2-dropdown-wrapper">
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "location-suggestion-item-active"
                                : "location-suggestion-item";

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                  })}
                                >
                                  <i className="far fa-map-marker-alt location-suggestion-icon" />
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Form.Group>
              </Form>
            </div>
          )}

          <div className="filter-save-button-wrapper">
            {updateLocationOpen ? (
              <div className="filter-save-button" onClick={saveHandler}>
                Update
              </div>
            ) : (
              <div className="filter-save-button" onClick={props.onClose}>
                Apply
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Location;
