import React, { useContext, useState } from "react";
import { Button, Image } from "react-bootstrap";
import "./headerstyles.scss";
import logo from "../../assets/logos/sugarbae-home-icon.png";
import { UserContext } from "../../context/UserContext";
import UndoPop from "../swipe/swipeComponents/swipePops/UndoPop";
import { Link } from "react-router-dom";
import MatchCounter from "../navbar/navComponents/counters/MatchCounter";
import { withRouter } from "react-router-dom";
import PremiumModal from "../modals/premiumModal/PremiumModal";

function SwipeHeader(props) {
  const context = useContext(UserContext);
  const [undoInvalid, setUndoInvalid] = useState(false);

  const toggleFilters = (e) => {
    props.toggleFilters();
  };

  const undoHandler = (e) => {
    if (context.user.premium || context.user.isAdmin) {
      if (!props.undoDisabled) {
        props.actionsHandler("undo");
      }
    } else {
      setUndoInvalid(true);
    }
  };

  const closeHandler = (e) => {
    setUndoInvalid(false);
  };

  const toggleMatches = (e) => {
    props.toggleMatches();
  };

  var isDisabled = props.undoDisabled;
  var undoIconStyle = "far fa-undo header-undo-icon-inactive";
  if (!isDisabled) {
    undoIconStyle = "far fa-undo header-undo-icon-active";
  }

  return (
    <div className="header-container">
      {undoInvalid && (
        <PremiumModal
          onClose={closeHandler}
          show={undoInvalid}
          option={"undo"}
        />
      )}
      <div className="header-section-left">
        <Button className="header-button" onClick={undoHandler}>
          <i className={undoIconStyle}></i>
        </Button>
        <Link to="/app/matches">
          <Button className="header-button" onClick={toggleMatches}>
            <i className="far fa-heart header-heart-icon">
              <MatchCounter />
            </i>
          </Button>
        </Link>
      </div>
      <div className="header-section-center">
        <Image src={logo} className="header-logo" />
      </div>
      <div className="header-section-right">
        <Button className="header-button" onClick={toggleFilters}>
          <i className="far fa-filter header-filter-icon"></i>
        </Button>
      </div>
    </div>
  );
}

export default withRouter(SwipeHeader);
