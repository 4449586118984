import React, { useState, useContext, useRef, useEffect } from "react";
import "./editscreensstyles.scss";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import { api } from "../../../../api";
var moment = require("moment");

function BirthDate(props) {
  const context = useContext(UserContext);
  const [birthDate, setBirthDate] = useState(null);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    var contextBirthDate = context.user.identity.birthDate;
    if (contextBirthDate) {
      var datePlaceHolder = moment(contextBirthDate)
        .format("YYYY-MM-DD")
        .toString();
      setBirthDate(datePlaceHolder);
    }
  }, []);

  const toggleTab = (e) => {
    props.toggleSubTab();
  };

  const birthDateChangeHandler = (e) => {
    setBirthDate(e.target.value);
    setSaveButtonDisabled(false);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setSaveButtonDisabled(true);

    var data = {
      option: "birthDate",
      birthDate: birthDate,
    };

    await api
      .updateIdentity(data)
      .then((res) => {
        if (res.status === 200) {
          context.updateUserInfo("identity", res.data.identity);
          toggleTab();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="edit-screen">
      <div className="edit-screen-header">
        <div className="edit-screen-header-section-left">
          <Button
            className="edit-screen-header-return-button"
            onClick={toggleTab}
          >
            <i className="fas fa-chevron-left"></i>
          </Button>
        </div>
        <div className="edit-screen-header-section-middle">
          <h1 className="edit-screen-header-title">Date of Birth</h1>
        </div>
      </div>
      <div className="edit-screen-content">
        <div className="settings-v2-section">
          <div className="settings-v2-section-content">
            <Form className="edit-screen-form">
              <Form.Group className="edit-screen-form-group">
                <Form.Label className="edit-screen-form-label">
                  Select Your Date of Birth
                </Form.Label>
                <Form.Control
                  type="date"
                  className="edit-screen-form-input"
                  name="birthDate"
                  onChange={birthDateChangeHandler}
                  required={true}
                  value={birthDate}
                  size="lg"
                />
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      <div className="edit-screen-cta-button-wrapper">
        <Button
          className="edit-screen-update-button"
          size="lg"
          onClick={toggleTab}
        >
          Back
        </Button>

        <Button
          className="edit-screen-cta-button-upgrade"
          size="lg"
          disabled={saveButtonDisabled}
          onClick={submitHandler}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}

export default BirthDate;
