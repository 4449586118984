import React, { useState, useContext, useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import { withRouter, useHistory } from "react-router-dom";
import "./wallet.scss";
import diamondIcon from "../../assets/Icons/diamonds_multiple.svg";
import { api } from "../../api";
import wallet from "../../assets/Icons/wallet.png";
import suitcase from "../../assets/Icons/suitcase.png";
import box from "../../assets/Icons/box.png";
import { UserContext } from "../../context/UserContext";
import diamondPack from "../../assets/Icons/diamondPack.svg";
import ClipLoader from "react-spinners/ClipLoader";

function PurchaseDiamonds(props) {
  const context = useContext(UserContext);

  let history = useHistory();
  const [diamondPackage, setDiamondPackage] = useState(0);
  const [purchaseOpen, setPurchaseOpen] = useState(false);
  const [purchaseLoading, setPurchaseLoading] = useState(false);

  const backHandler = (e) => {
    history.goBack();
  };

  const packageToggle = (props) => (e) => {
    setDiamondPackage(props);
  };

  const purchaseHandler = async (e) => {
    setPurchaseLoading(true);
    const data = {
      plan: diamondPackage,
    };
    let purchaseSuccess = false;

    const submitPurchase = await api
      .purchaseDiamonds(data)
      .then((res) => {
        if (res.status === 200) {
          purchaseSuccess = res.data.purchaseSuccess;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (purchaseSuccess) {
      var count = null;
      var price = null;
      if (diamondPackage === 0) {
        count = 100;
        price = "$97.99";
      } else if (diamondPackage === 1) {
        count = 50;
        price = "$59.99";
      } else if (diamondPackage === 2) {
        count = 25;
        price = "$32.50";
      } else if (diamondPackage === 3) {
        count = 10;
        price = "$14.99";
      }
      const purchaseData = {
        count: count,
        price: price,
      };
      history.push({
        pathname: "/app/receipt/diamonds",
        state: {
          purchaseType: "diamonds",
          purchaseData: purchaseData,
        },
      });
    } else {
      // Redirect to flexform

      var email = context.user.email;
      var urlPackageA =
        "https://api.ccbill.com/wap-frontflex/flexforms/3a1a9ee4-f573-407e-9e18-55e04e66b79c?Email=" +
        email;
      var urlPackageB =
        "https://api.ccbill.com/wap-frontflex/flexforms/f984fb65-fbac-4f14-93d9-01b64d1aaacf?Email=" +
        email;
      var urlPackageC =
        "https://api.ccbill.com/wap-frontflex/flexforms/3dc85450-8a63-41ed-aab0-6e9437e9f105?Email=" +
        email;
      var urlPackageD =
        "https://api.ccbill.com/wap-frontflex/flexforms/eba37657-6ff4-4178-b756-1807a3c0d44e?Email=" +
        email;

      if (diamondPackage === 0) {
        window.location.replace(urlPackageA);
      } else if (diamondPackage === 1) {
        window.location.replace(urlPackageB);
      } else if (diamondPackage === 2) {
        window.location.replace(urlPackageC);
      } else if (diamondPackage === 3) {
        window.location.replace(urlPackageD);
      }
    }
  };

  return (
    <div className="wallet-component">
      {purchaseLoading && (
        <div className="wallet-purchase-loading-overlay">
          <ClipLoader size={14} color="#b5b5b5" />
          <h1 className="wallet-purchase-loading-title">
            Attempting Purchase...
          </h1>
        </div>
      )}
      <div className="wallet-header">
        <div className="wallet-header-left">
          <Button className="wallet-return-button" onClick={backHandler}>
            <i className="fas fa-chevron-left"></i>
          </Button>
        </div>
        <div className="wallet-header-middle">
          <h1 className="wallet-header-title">Buy Diamonds</h1>
        </div>
      </div>
      <div className="wallet-content">
        <div className="wallet-content-header">
          <Image
            src={diamondIcon}
            className="wallet-content-header-image"
            fluid
          />
          <p className="wallet-content-header-subtitle">
            Sending diamonds is the best way to attract female users. Reload on
            diamonds now - the more you buy, the more you save.
          </p>
        </div>
        <div className="wallet-content-packages-container">
          <div
            className={
              diamondPackage === 0 ? "wallet-package-active" : "wallet-package"
            }
            onClick={packageToggle(0)}
          >
            <div className="wallet-package-body">
              <Image
                src={diamondPack}
                fluid
                className="wallet-package-icon-pack"
              />
              <p className="wallet-package-subtitle">
                Pack of <strong>100</strong> diamonds
              </p>
            </div>
            <div
              className={
                diamondPackage === 0
                  ? "wallet-package-footer-active"
                  : "wallet-package-footer"
              }
            >
              <p
                className={
                  diamondPackage === 0
                    ? "wallet-footer-discount-price-active"
                    : "wallet-footer-discount-price"
                }
              >
                USD $130
              </p>
              <p
                className={
                  diamondPackage === 0
                    ? "wallet-footer-price-active"
                    : "wallet-footer-price"
                }
              >
                USD $97.99
              </p>
            </div>
          </div>
          <div
            className={
              diamondPackage === 1 ? "wallet-package-active" : "wallet-package"
            }
            onClick={packageToggle(1)}
          >
            <div className="wallet-package-body">
              <Image src={suitcase} fluid className="wallet-package-icon" />
              <p className="wallet-package-subtitle">
                Case of <strong>50</strong> diamonds
              </p>
            </div>
            <div
              className={
                diamondPackage === 1
                  ? "wallet-package-footer-active"
                  : "wallet-package-footer"
              }
            >
              <p
                className={
                  diamondPackage === 1
                    ? "wallet-footer-discount-price-active"
                    : "wallet-footer-discount-price"
                }
              >
                USD $77
              </p>
              <p
                className={
                  diamondPackage === 1
                    ? "wallet-footer-price-active"
                    : "wallet-footer-price"
                }
              >
                USD $59.99
              </p>
            </div>
          </div>
          <div
            className={
              diamondPackage === 2 ? "wallet-package-active" : "wallet-package"
            }
            onClick={packageToggle(2)}
          >
            <div className="wallet-package-body">
              <Image src={box} fluid className="wallet-package-icon" />
              <p className="wallet-package-subtitle">
                Box of <strong>25</strong> diamonds
              </p>
            </div>
            <div
              className={
                diamondPackage === 2
                  ? "wallet-package-footer-active"
                  : "wallet-package-footer"
              }
            >
              <p
                className={
                  diamondPackage === 2
                    ? "wallet-footer-discount-price-active"
                    : "wallet-footer-discount-price"
                }
              >
                USD $45
              </p>
              <p
                className={
                  diamondPackage === 2
                    ? "wallet-footer-price-active"
                    : "wallet-footer-price"
                }
              >
                USD $32.50
              </p>
            </div>
          </div>
          <div
            className={
              diamondPackage === 3 ? "wallet-package-active" : "wallet-package"
            }
            onClick={packageToggle(3)}
          >
            <div className="wallet-package-body">
              <Image src={wallet} fluid className="wallet-package-icon" />
              <p className="wallet-package-subtitle">
                Wallet of <strong>10</strong> diamonds
              </p>
            </div>
            <div
              className={
                diamondPackage === 3
                  ? "wallet-package-footer-active"
                  : "wallet-package-footer"
              }
            >
              <p
                className={
                  diamondPackage === 3
                    ? "wallet-footer-discount-price-active"
                    : "wallet-footer-discount-price"
                }
              >
                USD $22
              </p>
              <p
                className={
                  diamondPackage === 3
                    ? "wallet-footer-price-active"
                    : "wallet-footer-price"
                }
              >
                USD $14.99
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="wallet-footer">
        <Button
          variant="danger"
          className="wallet-footer-button"
          onClick={purchaseHandler}
        >
          Purchase
        </Button>
      </div>
    </div>
  );
}

export default withRouter(PurchaseDiamonds);
