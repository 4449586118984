import React, { Component } from "react";
import "../welcome.scss";
import { Button, Image, Form } from "react-bootstrap";
import { UserContext } from "../../../context/UserContext";
import Axios from "axios";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { withRouter } from "react-router-dom";
import { api } from "../../../api";

export class Benefits extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      benefits: {},
    };
  }

  componentWillMount() {
    this.fetchInfo();
  }

  fetchInfo = async (e) => {
    const data = {
      userid: this.context.user._id,
    };

    return api
      .fetchBenefits(data)
      .then((res) => {
        var benefits = res.data.benefits;
        this.setState({
          benefits: benefits,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changeHandler = (props) => async (e) => {
    console.log(props);
    var benefits = this.state.benefits;

    if (props === "ActiveLifestyle") {
      if (benefits.ActiveLifestyle) {
        benefits.ActiveLifestyle = false;
      } else {
        benefits.ActiveLifestyle = true;
      }
    }

    if (props === "EmotionalConnection") {
      if (benefits.EmotionalConnection) {
        benefits.EmotionalConnection = false;
      } else {
        benefits.EmotionalConnection = true;
      }
    }

    if (props === "FlexibleSchedule") {
      if (benefits.FlexibleSchedule) {
        benefits.FlexibleSchedule = false;
      } else {
        benefits.FlexibleSchedule = true;
      }
    }

    if (props === "Attentive") {
      if (benefits.Attentive) {
        benefits.Attentive = false;
      } else {
        benefits.Attentive = true;
      }
    }

    if (props === "LongTerm") {
      if (benefits.LongTerm) {
        benefits.LongTerm = false;
      } else {
        benefits.LongTerm = true;
      }
    }

    if (props === "AllEthnicities") {
      if (benefits.AllEthnicities) {
        benefits.AllEthnicities = false;
      } else {
        benefits.AllEthnicities = true;
      }
    }

    if (props === "Discretion") {
      if (benefits.Discretion) {
        benefits.Discretion = false;
      } else {
        benefits.Discretion = true;
      }
    }

    if (props === "FineDining") {
      if (benefits.FineDining) {
        benefits.FineDining = false;
      } else {
        benefits.FineDining = true;
      }
    }

    if (props === "FriendsWithBenefits") {
      if (benefits.FriendsWithBenefits) {
        benefits.FriendsWithBenefits = false;
      } else {
        benefits.FriendsWithBenefits = true;
      }
    }

    if (props === "Investor") {
      if (benefits.Investor) {
        benefits.Investor = false;
      } else {
        benefits.Investor = true;
      }
    }

    if (props === "LifeOfLeisure") {
      if (benefits.LifeOfLeisure) {
        benefits.LifeOfLeisure = false;
      } else {
        benefits.LifeOfLeisure = true;
      }
    }

    if (props === "LuxuryLifestyle") {
      if (benefits.LuxuryLifestyle) {
        benefits.LuxuryLifestyle = false;
      } else {
        benefits.LuxuryLifestyle = true;
      }
    }

    if (props === "MarriageMinded") {
      if (benefits.MarriageMinded) {
        benefits.MarriageMinded = false;
      } else {
        benefits.MarriageMinded = true;
      }
    }

    if (props === "Mentorship") {
      if (benefits.Mentorship) {
        benefits.Mentorship = false;
      } else {
        benefits.Mentorship = true;
      }
    }

    if (props === "Monogamous") {
      if (benefits.Monogamous) {
        benefits.Monogamous = false;
      } else {
        benefits.Monogamous = true;
      }
    }

    if (props === "NoStringsAttached") {
      if (benefits.NoStringsAttached) {
        benefits.NoStringsAttached = false;
      } else {
        benefits.NoStringsAttached = true;
      }
    }

    if (props === "NonMonogamous") {
      if (benefits.NonMonogamous) {
        benefits.NonMonogamous = false;
      } else {
        benefits.NonMonogamous = true;
      }
    }

    if (props === "OpenRelationship") {
      if (benefits.OpenRelationship) {
        benefits.OpenRelationship = false;
      } else {
        benefits.OpenRelationship = true;
      }
    }

    if (props === "PassportReady") {
      if (benefits.PassportReady) {
        benefits.PassportReady = false;
      } else {
        benefits.PassportReady = true;
      }
    }

    if (props === "Platonic") {
      if (benefits.Platonic) {
        benefits.Platonic = false;
      } else {
        benefits.Platonic = true;
      }
    }

    if (props === "Romance") {
      if (benefits.Romance) {
        benefits.Romance = false;
      } else {
        benefits.Romance = true;
      }
    }

    if (props === "ShowsAndEntertainment") {
      if (benefits.ShowsAndEntertainment) {
        benefits.ShowsAndEntertainment = false;
      } else {
        benefits.ShowsAndEntertainment = true;
      }
    }

    if (props === "TravelToYou") {
      if (benefits.TravelToYou) {
        benefits.TravelToYou = false;
      } else {
        benefits.TravelToYou = true;
      }
    }

    if (props === "TravelWithMe") {
      if (benefits.TravelWithMe) {
        benefits.TravelWithMe = false;
      } else {
        benefits.TravelWithMe = true;
      }
    }

    if (props === "Vacations") {
      if (benefits.Vacations) {
        benefits.Vacations = false;
      } else {
        benefits.Vacations = true;
      }
    }

    this.setState({ benefits: benefits });
  };

  submitHandler = async (e) => {
    var benefits = this.state.benefits;

    const data = {
      userid: this.context.user._id,
      benefits: benefits,
    };

    return api
      .updateBenefits(data)
      .then((res) => {
        if (res.status === 200) {
          this.props.history.push("/app/welcome/offer");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="welcome-component-container">
        <ProgressBar animated now={87.5} className="welcome-progress-bar" />

        <div className="welcome-content-wrapper">
          <div className="welcome-title-wrap">
            <i className="far fa-gift welcome-icon"></i>

            <h1 className="welcome-title-bigger">Choose Your Benefits</h1>
            <p className="welcome-subtitle">
              Sugar Dating is like regular dating, but with benefits. Choose the
              benefits you want below.
            </p>
          </div>

          <div className="benefits-select-btn-wrapper">
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.ActiveLifestyle
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("ActiveLifestyle")}
            >
              Active Lifestyle
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.EmotionalConnection
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("EmotionalConnection")}
            >
              Emotional Connection
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.FlexibleSchedule
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("FlexibleSchedule")}
            >
              Flexible Schedule
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.Attentive
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("Attentive")}
            >
              Attentive
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.LongTerm
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("LongTerm")}
            >
              Long Term
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.AllEthnicities
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("AllEthnicities")}
            >
              All Ethnicities
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.Discretion
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("Discretion")}
            >
              Discretion
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.FineDining
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("FineDining")}
            >
              Fine Dining
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.FriendsWithBenefits
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("FriendsWithBenefits")}
            >
              Friends with Benefits
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.Investor
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("Investor")}
            >
              Investor
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.LifeOfLeisure
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("LifeOfLeisure")}
            >
              Life of Leisure
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.LuxuryLifestyle
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("LuxuryLifestyle")}
            >
              Luxury Lifestyle
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.MarriageMinded
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("MarriageMinded")}
            >
              Marriage Minded
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.Mentorship
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("Mentorship")}
            >
              Mentorship
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.Monogamous
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("Monogamous")}
            >
              Monogamous
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.NoStringsAttached
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("NoStringsAttached")}
            >
              No Strings Attached
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.NonMonogamous
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("NonMonogamous")}
            >
              Non Monogamous
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.OpenRelationship
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("OpenRelationship")}
            >
              Open Relationship
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.PassportReady
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("PassportReady")}
            >
              Passport Ready
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.Platonic
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("Platonic")}
            >
              Platonic
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.Romance
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("Romance")}
            >
              Romance
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.ShowsAndEntertainment
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("ShowsAndEntertainment")}
            >
              Shows & Entertainment
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.TravelToYou
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("TravelToYou")}
            >
              Travel to You
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.TravelWithMe
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("TravelWithMe")}
            >
              Travel with Me
            </Button>
            <Button
              variant="outline-primary"
              className={
                this.state.benefits.Vacations
                  ? "benefits-checkbox-btn-checked"
                  : "benefits-checkbox-btn"
              }
              onClick={this.changeHandler("Vacations")}
            >
              Vacations
            </Button>
          </div>
        </div>
        <div className="welcome-btn-wrap">
          <Button
            variant="outline-primary"
            className="welcome-btn-advance"
            onClick={this.submitHandler}
            disabled={this.state.disabledBtn}
          >
            Next
          </Button>
          <Link to="/app/welcome/offer" className="welcome-skip-link">
            Skip
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(Benefits);
