import React, { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";

import { withRouter, useHistory } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import { api } from "../../../../../api";

import "./editbio.scss";

const EditBio = (props) => {
  const history = useHistory();
  const context = useContext(UserContext);
  const [bio, setBio] = useState(context.user.bio);
  const [bioLen, setBioLen] = useState(
    context.user.bio ? 300 - context.user.bio.length : 300
  );

  const bioChange = (e) => {
    if (e.target.value.length < 301) {
      setBio(e.target.value);
      setBioLen(300 - e.target.value.length);
    }
  };

  const saveHandler = () => {
    const data = {
      userid: context.user._id,
      bio,
    };
    return api
      .updateBio(data)
      .then(() => {
        const tempUser = context.user;
        tempUser.bio = bio;
        context.updateUserInfo("all", tempUser);
        props.closeHandler();
      })
      .catch(() => {});
  };

  return (
    <div className="edit-profile-v2-lookingfor-container">
      <div className="edit-profile-v2-bio-header">
        <div
          className="edit-profile-v2-bio-header-section-left"
          onClick={() => history.goBack()}
        >
          <i className="fas fa-chevron-left" />
        </div>
        <div className="edit-profile-v2-header-section-middle">
          <h1 className="edit-profile-v2-header-title">Looking For</h1>
        </div>
      </div>
      <div className="edit-profile-v2-bio-title">
        <div className="edit-profile-v2-bio-title-text">
          <h1 className="edit-profile-v2-bio-title-one">If be honest</h1>
          <h1 className="edit-profile-v2-bio-title-two">
            What are you looking for?
          </h1>
        </div>
        <div className="edit-profile-v2-bio-description">
          Enter some words about wishes, it will help people know about you.
        </div>
      </div>
      <div className="edit-profile-v2-bio-input-field">
        <Form className="edit-profile-v2-bio-form">
          <Form.Control
            as="textarea"
            rows="6"
            placeholder="Enter some text"
            onChange={bioChange}
            value={bio}
            className="edit-profile-v2-bio-input"
            maxLength="300"
          />
        </Form>
        <div className="edit-profile-v2-bio-len">{`${bioLen} characters`}</div>
      </div>
      <Button
        className="edit-profile-v2-bio-save"
        onClick={() => saveHandler()}
      >
        Save
      </Button>
    </div>
  );
};

export default withRouter(EditBio);
