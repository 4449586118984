import React, { useState, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";

import { UserContext } from "../../../../../context/UserContext";
import { api } from "../../../../../api";

import "./editbenefits.scss";

const EditBenefits = (props) => {
  const context = useContext(UserContext);
  const [benefits, setBenefits] = useState(context.user.benefits);
  const [refresh, setRefresh] = useState(false);

  const toggleBenefits = (value) => {
    let benefitsCopy = benefits;

    if (value === "ActiveLifestyle") {
      if (benefitsCopy.ActiveLifestyle) {
        benefitsCopy.ActiveLifestyle = false;
      } else {
        benefitsCopy.ActiveLifestyle = true;
      }
    }

    if (value === "EmotionalConnection") {
      if (benefitsCopy.EmotionalConnection) {
        benefitsCopy.EmotionalConnection = false;
      } else {
        benefitsCopy.EmotionalConnection = true;
      }
    }

    if (value === "FlexibleSchedule") {
      if (benefitsCopy.FlexibleSchedule) {
        benefitsCopy.FlexibleSchedule = false;
      } else {
        benefitsCopy.FlexibleSchedule = true;
      }
    }

    if (value === "Attentive") {
      if (benefitsCopy.Attentive) {
        benefitsCopy.Attentive = false;
      } else {
        benefitsCopy.Attentive = true;
      }
    }

    if (value === "LongTerm") {
      if (benefits.LongTerm) {
        benefitsCopy.LongTerm = false;
      } else {
        benefitsCopy.LongTerm = true;
      }
    }

    if (value === "AllEthnicities") {
      if (benefitsCopy.AllEthnicities) {
        benefitsCopy.AllEthnicities = false;
      } else {
        benefitsCopy.AllEthnicities = true;
      }
    }

    if (value === "Discretion") {
      if (benefitsCopy.Discretion) {
        benefitsCopy.Discretion = false;
      } else {
        benefitsCopy.Discretion = true;
      }
    }

    if (value === "FineDining") {
      if (benefitsCopy.FineDining) {
        benefitsCopy.FineDining = false;
      } else {
        benefitsCopy.FineDining = true;
      }
    }

    if (value === "FriendsWithBenefits") {
      if (benefitsCopy.FriendsWithBenefits) {
        benefitsCopy.FriendsWithBenefits = false;
      } else {
        benefitsCopy.FriendsWithBenefits = true;
      }
    }

    if (value === "Investor") {
      if (benefitsCopy.Investor) {
        benefitsCopy.Investor = false;
      } else {
        benefitsCopy.Investor = true;
      }
    }

    if (value === "LifeOfLeisure") {
      if (benefitsCopy.LifeOfLeisure) {
        benefitsCopy.LifeOfLeisure = false;
      } else {
        benefitsCopy.LifeOfLeisure = true;
      }
    }

    if (value === "LuxuryLifestyle") {
      if (benefitsCopy.LuxuryLifestyle) {
        benefitsCopy.LuxuryLifestyle = false;
      } else {
        benefitsCopy.LuxuryLifestyle = true;
      }
    }

    if (value === "MarriageMinded") {
      if (benefitsCopy.MarriageMinded) {
        benefitsCopy.MarriageMinded = false;
      } else {
        benefitsCopy.MarriageMinded = true;
      }
    }

    if (value === "Mentorship") {
      if (benefitsCopy.Mentorship) {
        benefitsCopy.Mentorship = false;
      } else {
        benefitsCopy.Mentorship = true;
      }
    }

    if (value === "Monogamous") {
      if (benefitsCopy.Monogamous) {
        benefitsCopy.Monogamous = false;
      } else {
        benefitsCopy.Monogamous = true;
      }
    }

    if (value === "NoStringsAttached") {
      if (benefitsCopy.NoStringsAttached) {
        benefitsCopy.NoStringsAttached = false;
      } else {
        benefitsCopy.NoStringsAttached = true;
      }
    }

    if (value === "NonMonogamous") {
      if (benefitsCopy.NonMonogamous) {
        benefitsCopy.NonMonogamous = false;
      } else {
        benefitsCopy.NonMonogamous = true;
      }
    }

    if (value === "OpenRelationship") {
      if (benefitsCopy.OpenRelationship) {
        benefitsCopy.OpenRelationship = false;
      } else {
        benefitsCopy.OpenRelationship = true;
      }
    }

    if (value === "PassportReady") {
      if (benefitsCopy.PassportReady) {
        benefitsCopy.PassportReady = false;
      } else {
        benefitsCopy.PassportReady = true;
      }
    }

    if (value === "Platonic") {
      if (benefitsCopy.Platonic) {
        benefitsCopy.Platonic = false;
      } else {
        benefitsCopy.Platonic = true;
      }
    }

    if (value === "Romance") {
      if (benefitsCopy.Romance) {
        benefitsCopy.Romance = false;
      } else {
        benefitsCopy.Romance = true;
      }
    }

    if (value === "ShowsAndEntertainment") {
      if (benefitsCopy.ShowsAndEntertainment) {
        benefitsCopy.ShowsAndEntertainment = false;
      } else {
        benefitsCopy.ShowsAndEntertainment = true;
      }
    }

    if (value === "TravelToYou") {
      if (benefitsCopy.TravelToYou) {
        benefitsCopy.TravelToYou = false;
      } else {
        benefitsCopy.TravelToYou = true;
      }
    }

    if (value === "TravelWithMe") {
      if (benefitsCopy.TravelWithMe) {
        benefitsCopy.TravelWithMe = false;
      } else {
        benefitsCopy.TravelWithMe = true;
      }
    }

    if (value === "Vacations") {
      if (benefitsCopy.Vacations) {
        benefitsCopy.Vacations = false;
      } else {
        benefitsCopy.Vacations = true;
      }
    }

    setBenefits(benefitsCopy);
    setRefresh(true);
  };

  const saveHandler = () => {
    const data = {
      userid: context.user._id,
      benefits,
    };

    return api
      .updateBenefits(data)
      .then(() => {
        let tempUser = context.user;
        tempUser.benefits = benefits;
        context.updateUserInfo("all", tempUser);
        props.closeHandler();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <div className="edit-profile-v2-benefits-container">
      <div className="edit-profile-v2-benefits-header">
        <div
          className="edit-profile-v2-benefits-header-section-left"
          onClick={() => props.closeHandler()}
        >
          <i className="fas fa-chevron-left"></i>
        </div>
        <div className="edit-profile-v2-header-section-middle">
          <h1 className="edit-profile-v2-header-title">About you</h1>
        </div>
      </div>
      <div className="edit-profile-v2-benefits-title">
        <div className="edit-profile-v2-benefits-title-text">
          <h1 className="edit-profile-v2-benefits-title-one">Choose your</h1>
          <h1 className="edit-profile-v2-benefits-title-two">Benefits</h1>
        </div>
        <div className="edit-profile-v2-benefits-description">
          Sugar dating is like regular dating, but with benefits. Choose the
          benefits you want below.
        </div>
      </div>
      <div className="edit-profile-v2-benefits-body">
        <div
          className={
            benefits.ActiveLifestyle
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("ActiveLifestyle")}
        >
          Active Lifestyle
        </div>
        <div
          className={
            benefits.EmotionalConnection
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("EmotionalConnection")}
        >
          Emotional Connection
        </div>
        <div
          className={
            benefits.FlexibleSchedule
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("FlexibleSchedule")}
        >
          Flexible Schedule
        </div>
        <div
          className={
            benefits.Attentive
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Attentive")}
        >
          Attentive
        </div>
        <div
          className={
            benefits.LongTerm
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("LongTerm")}
        >
          Long Term
        </div>
        <div
          className={
            benefits.AllEthnicities
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("AllEthnicities")}
        >
          All Ethnicities
        </div>
        <div
          className={
            benefits.Discretion
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Discretion")}
        >
          Discretion
        </div>
        <div
          className={
            benefits.FineDining
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("FineDining")}
        >
          Fine Dining
        </div>
        <div
          className={
            benefits.FriendsWithBenefits
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("FriendsWithBenefits")}
        >
          Friends with Benefits
        </div>
        <div
          className={
            benefits.Investor
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Investor")}
        >
          Investor
        </div>
        <div
          className={
            benefits.LifeOfLeisure
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("LifeOfLeisure")}
        >
          Life of Leisure
        </div>
        <div
          className={
            benefits.MarriageMinded
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("MarriageMinded")}
        >
          Marriage Minded
        </div>
        <div
          className={
            benefits.Mentorship
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Mentorship")}
        >
          Mentorship
        </div>
        <div
          className={
            benefits.Monogamous
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Monogamous")}
        >
          Monogamous
        </div>
        <div
          className={
            benefits.NoStringsAttached
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("NoStringsAttached")}
        >
          No Strings Attached
        </div>
        <div
          className={
            benefits.NonMonogamous
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("NonMonogamous")}
        >
          Non Monogamouse
        </div>
        <div
          className={
            benefits.OpenRelationship
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("OpenRelationship")}
        >
          Open Relationship
        </div>
        <div
          className={
            benefits.PassportReady
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("PassportReady")}
        >
          Passport Ready
        </div>
        <div
          className={
            benefits.Platonic
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Platonic")}
        >
          Platonic
        </div>
        <div
          className={
            benefits.Romance
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Romance")}
        >
          Romance
        </div>
        <div
          className={
            benefits.ShowsAndEntertainment
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("ShowsAndEntertainment")}
        >
          Shows and Entertainment
        </div>
        <div
          className={
            benefits.TravelToYou
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("TravelToYou")}
        >
          Travel to You
        </div>
        <div
          className={
            benefits.TravelWithMe
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("TravelWithMe")}
        >
          Travel with Me
        </div>
        <div
          className={
            benefits.Vacations
              ? "edit-profile-v2-benefit-option-active"
              : "edit-profile-v2-benefit-option"
          }
          onClick={() => toggleBenefits("Vacations")}
        >
          Vacations
        </div>
      </div>
      <Button
        className="edit-profile-v2-benefits-save"
        onClick={() => saveHandler()}
      >
        Save
      </Button>
    </div>
  );
};

export default EditBenefits;
