import React from "react";
import "./message.scss";
import { Card, Image } from "react-bootstrap";
import diamondIcon from "../../../../assets/Icons/whiteDiamond.png";

const moment = require("moment");

function Message(props) {
  const { message } = props;
  const { card } = props;
  const { user } = props;
  const { data } = props;

  let isCompliment = false;

  if (message.compliment) {
    if (message.compliment.status) {
      isCompliment = true;
    }
  }

  let bubbleClass = "";
  let cardClass = "";
  if (data.consecutive) {
    if (data.timeStamp) {
      if (isCompliment) {
        cardClass = "message-card-compliment-consecutive-timestamp";
      } else {
        cardClass = "message-card-consecutive-timestamp";
      }
    } else if (isCompliment) {
      cardClass = "message-card-compliment-consecutive";
    } else {
      cardClass = "message-card-consecutive";
    }
    if (data.received) {
      if (isCompliment) {
        bubbleClass = "bubble-consecutive-compliment-received";
      } else {
        bubbleClass = "bubble-consecutive-received";
      }
    } else if (isCompliment) {
      bubbleClass = "bubble-consecutive-compliment-sent";
    } else {
      bubbleClass = "bubble-consecutive-sent";
    }
  } else {
    if (isCompliment) {
      cardClass = "message-card-compliment";
    } else {
      cardClass = "message-card";
    }

    if (data.received) {
      if (isCompliment) {
        bubbleClass = "bubble-received-compliment";
      } else {
        bubbleClass = "bubble-received";
      }
    } else if (isCompliment) {
      bubbleClass = "bubble-sent-compliment";
    } else {
      bubbleClass = "bubble-sent";
    }
  }

  const { image } = card;

  const date = moment(message.createdAt).calendar();

  const readReceiptsModalToggle = (e) => {};

  return (
    <div className="message-component">
      <Card className={cardClass}>
        {data.timeStamp ? (
          <Card.Header className="message-card-header">
            <Card.Title className="message-card-title">{date}</Card.Title>
          </Card.Header>
        ) : null}
        {data.matchStamp ? (
          <Card.Header className="message-card-header">
            <Card.Title className="message-card-title">
              You Matched {data.matchDate}
            </Card.Title>
          </Card.Header>
        ) : null}
        <Card.Body
          className={
            data.received ? "message-card-received" : "message-card-sent"
          }
        >
          {isCompliment ? (
            <div className="compliment-message">
              <div className="compliment-message-content">
                <span className="compliment-message-text">
                  {message.message}
                </span>
              </div>
              <div className="compliment-message-diamond-count">
                <Image
                  src={diamondIcon}
                  className="compliment-message-diamond-icon"
                  fluid
                />
                <span className="compliment-message-diamond-count-text">
                  {message.compliment.diamonds}
                </span>
              </div>
            </div>
          ) : (
            <div className={bubbleClass}>
              <span
                className={
                  data.received ? "chat-text-receiver" : "chat-text-sent"
                }
              >
                {message.message}
              </span>
            </div>
          )}
        </Card.Body>
        {data.showReadReceipt ? (
          <Card.Footer className="message-card-footer">
            {user.premium ? (
              <div className="card-footer-innerwrap">
                <div className="message-sent-indicator-wrapper">
                  {data.isRead ? (
                    <p className="message-sent-indicator">Read</p>
                  ) : (
                    <p className="message-sent-indicator">Unread</p>
                  )}
                </div>
                <div className="message-read-indicator-wrapper-premium">
                  <div className="message-read-receipt-button">
                    {data.isRead ? (
                      <i className="far fa-check-double read-receipt-btn-icon" />
                    ) : (
                      <i className="far fa-check read-receipt-btn-icon-single" />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="card-footer-innerwrap"
                onClick={readReceiptsModalToggle}
              >
                <div className="message-read-indicator-wrapper">
                  <div className="message-read-receipt-button">
                    <i className="far fa-check-double read-receipt-btn-icon" />
                  </div>
                  <i className="fas fa-plus read-receipt-plus" />
                </div>
                <div className="message-sent-indicator-wrapper">
                  <p className="message-sent-indicator">Sent</p>
                </div>
              </div>
            )}
          </Card.Footer>
        ) : null}
      </Card>
    </div>
  );
}

export default Message;
