import React, { Component } from 'react';
import './likescard.scss';
import { Card, Image as IconImage, Button } from 'react-bootstrap';
import { Image, Transformation } from 'cloudinary-react';
import { submitLike, submitDislike } from '../../../../helpers/swipeActions';
import { UserContext } from '../../../../context/UserContext';
import Match from '../../../../components/swipe/swipeComponents/match/Match';
import unlockIcon from '../../../../assets/Images/matches/unlock-icon.gif';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import Media from '../../../../components/media/Media';

var moment = require('moment');

export class LikesCard extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      match: false,
      matchid: '',
    };
  }

  handleLike = async (e) => {
    this.setState({ submitting: true }, async (e) => {
      var userid = this.context.user._id;
      var cardid = this.props.like.user._id;
      const like = await submitLike(userid, cardid).catch((err) => {
        console.log(err);
      });
      var match = like.match;

      if (match) {
        this.context.updateMatches();
        this.setState({ match: true, matchid: cardid });
      }
    });
  };

  handleDislike = async (e) => {
    this.setState({ submitting: true }, async (e) => {
      var userid = this.context.user._id;
      var cardid = this.props.like.user._id;
      const dislike = await submitDislike(userid, cardid).catch((err) => {
        console.log(err);
      });
      this.props.dislikeHandler(this.props.index);
    });
  };

  closeMatchHandler = (e) => {
    this.setState({ match: false }, () => {
      this.props.likeHandler(this.props.index);
    });
  };

  viewLikesModalToggle = (e) => {
    this.props.viewLikesModalToggle();
  };

  profileToggle = (e) => {
    var card = this.props.like.user;
    this.props.profileToggle(card);
  };

  render() {
    var like = this.props.like;

    if (like.superlike) {
      var cardClass = 'likes-card-superlike';
    } else if (like.note.status) {
      var cardClass = 'likes-card-note';
    } else {
      var cardClass = 'likes-card';
    }

    if (this.props.like.user.imgurl.length > 0) {
      var image = this.props.like.user.imgurl[0];

      var isVideo = image.includes('video');

      if (isVideo) {
        var imgurl = image;
      } else {
        var imgsettings = 'w_540,h_675,f_auto,c_fill,q_auto:eco/';
        var firstHalfimgurl = this.props.like.user.imgurl[0].slice(0, 49);
        var secondHalfimgurl = this.props.like.user.imgurl[0].slice(49);
        var imgurl = firstHalfimgurl + imgsettings + secondHalfimgurl;
      }
    } else {
      var imgurl =
        'https://res.cloudinary.com/sugarbae/image/upload/v1590046357/larger-placeholder-user-image_vvdghq.png';
    }

    var lastlogin = moment(this.props.like.user.lastlogin);
    var lastHour = moment().subtract(5, 'hours');

    var online = false;

    if (lastlogin > lastHour) {
      online = true;
    }

    return (
      <div className="likes-card-component">
        {this.state.match ? (
          <Match
            matchid={this.state.matchid}
            matchUser={this.props.like.user}
            closeMatchHandler={this.closeMatchHandler}
          />
        ) : null}
        <Card className={cardClass}>
          <Card.Body className="likes-card-body-premium">
            {like.superlike ? (
              <div className="likes-card-superlike-icon-wrapper">
                <i className="fas fa-star likes-card-icon-superlike"></i>
              </div>
            ) : null}
            {like.note.status ? (
              <div className="likes-card-superlike-icon-wrapper">
                <i className="fad fa-paper-plane likes-card-icon-note"></i>
              </div>
            ) : null}

            <div className="likes-card-info">
              <h2 className="likes-card-username">
                {this.props.like.user.username}, {this.props.like.user.age}
              </h2>
            </div>

            <Media image={image} />
            {/* <Image
              publicId={
                image
                  ? getImgName(image)
                  : "larger-placeholder-user-image_vvdghq.png"
              }
              version={image ? getImgVersion(image) : "1590046357"}
              resource_type={
                image ? (checkGIF(image) ? "video" : "image") : null
              }
              cloud_name="sugarbae"
              className="likes-card-image"
              onClick={this.profileToggle}
            >
              <Transformation
                height="675"
                width="540"
                video_sampling="20"
                delay="250"
                duration="10"
                effect="loop"
                quality="auto"
                flags="lossy"
              />
            </Image> */}
            <div className="likes-card-action-wrapper">
              <div
                className="likes-card-button"
                onClick={() => this.handleDislike()}
              >
                <i className="fas fa-times likes-card-icon-dislike"></i>
              </div>
              <div
                className="likes-card-button"
                onClick={() => this.handleLike()}
              >
                <i className="fas fa-heart likes-card-icon-like"></i>
              </div>
            </div>
          </Card.Body>
          {like.superlike ? (
            <Card.Footer className="likes-card-footer-superlike">
              <span>Superliked You</span>
            </Card.Footer>
          ) : null}
          {like.note.status ? (
            <Card.Footer className="likes-card-footer-note">
              <span>Sent a note:</span>
              <p className="likes-card-note-message">{like.note.message}</p>
            </Card.Footer>
          ) : null}
        </Card>
      </div>
    );
  }
}

export default LikesCard;
