import React, { Component } from "react";
import HomeFooter from "../../../components/footers/homeFooter/HomeFooter";
import HomeNav from "../../../components/navbar/HomeNav/HomeNav";
import { Container } from "react-bootstrap";
import NewPassword from "../../../components/resetPassword/newPassword/NewPassword";

export class CreatePasswordPage extends Component {
  state = {
    token: null,
  };
  componentWillMount() {
    window.scrollTo(0, 0);
    this.setState({ token: this.props.match.params.token });
  }
  render() {
    return (
      <div>
        <HomeNav />
        <NewPassword token={this.state.token} />
        <HomeFooter />
      </div>
    );
  }
}

export default CreatePasswordPage;
