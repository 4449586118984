import React, { Component } from "react";
import "../welcome.scss";
import { Button, Image, Form } from "react-bootstrap";
import { UserContext } from "../../../context/UserContext";
import Axios from "axios";

import { withRouter, Link } from "react-router-dom";

import ProgressBar from "react-bootstrap/ProgressBar";
import { api } from "../../../api";

export class Lifestyle extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      disabledBtn: false,
      networth: "",
      gender: "",
      jobtitleInvalid: false,
      annualincomeInvalid: false,
      networthInvalid: false,
      relationshipstatus: "",
      relationshipstatusInvalid: false,
      children: "",
      childrenInvalid: false,
      education: "",
      educationInvalid: false,
    };
  }

  componentWillMount() {
    var gender = this.context.user.gender;
    this.setState({ gender: gender });
    this.fetchLifestyle();
  }

  fetchLifestyle = async (e) => {
    const data = {
      userid: this.context.user._id,
    };

    return api
      .fetchOwnUser(data)
      .then((res) => {
        var user = res.data.user;
        var lifestyle = user.lifestyle;

        if (lifestyle) {
          var networth = lifestyle.networth;
          var annualincome = lifestyle.annualincome;
          var job = lifestyle.job;
          var jobtitle = job.jobtitle;
          var children = lifestyle.children;
          var relationshipstatus = lifestyle.relationship;
          var education = lifestyle.education;
        } else {
          var annualincome = "";
          var jobtitle = "";
          var networth = "";
          var children = "";
          var relationshipstatus = "";
          var education = "";
        }

        this.setState({
          networth: networth,
          annualincome: annualincome,
          jobtitle: jobtitle,
          children: children,
          relationshipstatus: relationshipstatus,
          education: education,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    var networth = this.state.networth;
    var annualincome = this.state.annualincome;
    var jobtitle = this.state.jobtitle;
    var networthValid = false;
    var annualincomeValid = false;
    var jobtitleValid = false;

    if (networth === "" || networth === null || networth === undefined) {
      networthValid = false;
    } else {
      networthValid = true;
    }
    if (
      annualincome === "" ||
      annualincome === null ||
      annualincome === undefined
    ) {
      annualincomeValid = false;
    } else {
      annualincomeValid = true;
    }
    if (jobtitle === "" || jobtitle === null || jobtitle === undefined) {
      jobtitleValid = false;
    } else {
      jobtitleValid = true;
    }

    if (networthValid && annualincomeValid && jobtitleValid) {
      this.setState({ disabledBtn: true }, async (e) => {
        const data = {
          userid: this.context.user._id,
          jobtitle: jobtitle,
          networth: networth,
          annualincome: annualincome,
        };

        return api
          .updateLifestyle(data)
          .then((res) => {
            if (res.status === 200) {
              this.props.history.push("/app/welcome/benefits");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else {
      this.setState({
        networthInvalid: !networthValid,
        annualIncomeInvalid: !annualincomeValid,
        jobtitleInvlaid: !jobtitleValid,
      });
    }
  };

  submitHandlerFemale = (e) => {
    var children = this.state.children;
    var education = this.state.education;
    var relationshipstatus = this.state.relationshipstatus;
    var educationValid = false;
    var childrenValid = false;
    var relationshipstatusValid = false;

    if (children === "" || children === null || children === undefined) {
      childrenValid = false;
    } else {
      childrenValid = true;
    }
    if (education === "" || education === null || education === undefined) {
      educationValid = false;
    } else {
      educationValid = true;
    }
    if (
      relationshipstatus === "" ||
      relationshipstatus === null ||
      relationshipstatus === undefined
    ) {
      relationshipstatusValid = false;
    } else {
      relationshipstatusValid = true;
    }

    if (relationshipstatusValid && childrenValid && educationValid) {
      this.setState({ disabledBtn: true }, async (e) => {
        const data = {
          userid: this.context.user._id,
          education: education,
          children: children,
          relationshipstatus: relationshipstatus,
        };

        return api
          .updateLifestyleFemale(data)
          .then((res) => {
            if (res.status === 200) {
              this.props.history.push("/app/welcome/benefits");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    } else {
      this.setState({
        educationValid: !educationValid,
        childrenValid: !childrenValid,
        relationshipstatusValid: !relationshipstatusValid,
      });
    }
  };

  render() {
    var gender = this.state.gender;

    if (gender === "" || gender === null || gender === undefined) {
      return null;
    } else if (gender === "male" || gender === "Male") {
      return (
        <div className="welcome-component-container">
          <ProgressBar animated now={62.5} className="welcome-progress-bar" />

          <div className="welcome-content-wrapper">
            <div className="welcome-title-wrap">
              <i className="far fa-info-square welcome-icon"></i>

              <h1 className="welcome-title">About Your Lifestyle</h1>
              <p className="welcome-subtitle">
                A few basic questions about your lifestyle.
              </p>
            </div>

            <Form className="welcome-form">
              <Form.Group>
                <Form.Label className="welcome-form-label">
                  What is your Net Worth?
                </Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.networth}
                  onChange={this.changeHandler}
                  name="networth"
                  isInvalid={this.state.networthInvalid}
                >
                  <option value="" disabled selected hidden>
                    Select Your Net Worth
                  </option>
                  <option>$100,000</option>
                  <option>$250,000</option>
                  <option>$500,000</option>
                  <option>$750,000</option>
                  <option>$1 million</option>
                  <option>$2 million</option>
                  <option>$5 million</option>
                  <option>$10 million</option>
                  <option>$50 million</option>
                  <option>$100 million</option>
                  <option>More than $100 million</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="welcome-form-label">
                  What is your Annual Income?
                </Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.annualincome}
                  onChange={this.changeHandler}
                  name="annualincome"
                  isInvalid={this.state.annualincomeInvalid}
                >
                  <option value="" disabled selected hidden>
                    Select Your Annual Income
                  </option>
                  <option>Less than $100,000</option>
                  <option>$100,000</option>
                  <option>$125,000</option>
                  <option>$150,000</option>
                  <option>$175,000</option>
                  <option>$200,000</option>
                  <option>$250,000</option>
                  <option>$300,000</option>
                  <option>$400,000</option>
                  <option>$500,000</option>
                  <option>$1 million</option>
                  <option>More than $1 million</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="welcome-form-label">
                  What is your Job Title ?
                </Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.jobtitle}
                  onChange={this.changeHandler}
                  name="jobtitle"
                  isInvalid={this.state.jobtitleInvlaid}
                >
                  <option value="" disabled selected hidden>
                    Select Your Job Title
                  </option>
                  <option>Architecture</option>
                  <option>Art and Design</option>
                  <option>Building Maintenance and Grounds Cleaning</option>
                  <option>Community and Social Services</option>
                  <option>Construction and Extraction</option>
                  <option>Data Science</option>
                  <option>Education</option>
                  <option>Engineering</option>
                  <option>Entrepreneur</option>
                  <option>Farming Fishing and Forestry</option>
                  <option>Finance</option>
                  <option>Food Service</option>
                  <option>Healthcare Practitioner</option>
                  <option>Healthcare Support</option>
                  <option>Homemaker</option>
                  <option>Installation Maintenance and Repair Services</option>
                  <option>Law Practitioner</option>
                  <option>Life and Physical Sciences</option>
                  <option>Management</option>
                  <option>Marketing</option>
                  <option>Mathematics</option>
                  <option>Media</option>
                  <option>Office Administration</option>
                  <option>Personal Care and Lifestyle Service</option>
                  <option>Protective Service</option>
                  <option>Sales</option>
                  <option>Self-employed</option>
                  <option>Sports</option>
                  <option>Student</option>
                  <option>Training Services</option>
                  <option>Transportation and Material Moving</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="welcome-btn-wrap">
            <Button
              variant="outline-primary"
              className="welcome-btn-advance"
              onClick={this.submitHandler}
              disabled={this.state.disabledBtn}
            >
              Next
            </Button>
            <Link to="/app/welcome/benefits" className="welcome-skip-link">
              Skip
            </Link>
          </div>
        </div>
      );
    } else if (gender === "female" || gender === "Female") {
      return (
        <div className="welcome-component-container">
          <ProgressBar animated now={62.5} className="welcome-progress-bar" />

          <div className="welcome-content-wrapper">
            <div className="welcome-title-wrap">
              <i className="far fa-info-square welcome-icon"></i>

              <h1 className="welcome-title">About Your Lifestyle</h1>
              <p className="welcome-subtitle">
                A few basic questions about your lifestyle.
              </p>
            </div>

            <Form className="welcome-form">
              <Form.Group>
                <Form.Label className="welcome-form-label">
                  What is your Relationship Status?
                </Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.relationshipstatus}
                  onChange={this.changeHandler}
                  name="relationshipstatus"
                  isInvalid={this.state.relationshipstatusInvalid}
                >
                  <option value="" disabled selected hidden>
                    Select Your Relationship Status
                  </option>
                  <option>Single</option>
                  <option>Divorced</option>
                  <option>Separated</option>
                  <option>Married But Looking</option>
                  <option>Open Relationship</option>
                  <option>Widowed</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="welcome-form-label">
                  Do you have any children?
                </Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.children}
                  onChange={this.changeHandler}
                  name="children"
                  isInvalid={this.state.childrenInvalid}
                >
                  <option value="" disabled selected hidden>
                    Select the Best Answer
                  </option>
                  <option>Prefer Not to Say</option>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6+</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label className="welcome-form-label">
                  What is your Education level?
                </Form.Label>
                <Form.Control
                  as="select"
                  value={this.state.education}
                  onChange={this.changeHandler}
                  name="education"
                  isInvalid={this.state.educationInvalid}
                >
                  <option value="" disabled selected hidden>
                    Select Your Education Level
                  </option>
                  <option>High School</option>
                  <option>Some College</option>
                  <option>Associates Degree</option>
                  <option>Bachelors Degree</option>
                  <option>Bachelors Degree</option>
                  <option>Graduate Degree</option>
                  <option>PhD / Post Doctoral</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
          <div className="welcome-btn-wrap">
            <Button
              variant="outline-primary"
              className="welcome-btn-advance"
              onClick={this.submitHandlerFemale}
              disabled={this.state.disabledBtn}
            >
              Next
            </Button>
            <Link to="/app/welcome/benefits" className="welcome-skip-link">
              Skip
            </Link>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(Lifestyle);
