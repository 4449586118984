import React, { Component } from "react";
import { Card } from "react-bootstrap";
import { UserContext } from "../../../../context/UserContext";
import "../userinfocards.scss";

export class AdditionalUserInfo extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    var card = this.props.card;
    var lifestyle = card.lifestyle;
    var smoking = lifestyle.smoking;
    var drinking = lifestyle.drinking;
    var cannabis = lifestyle.cannabis;
    var exercise = lifestyle.exercise;
    var education = lifestyle.education;
    var relationship = lifestyle.relationship;
    var children = lifestyle.children;

    var showSmoking = false;
    var showDrinking = false;
    var showCannabis = false;
    var showRelationship = false;
    var showEducation = false;
    var showChildren = false;
    var showExercise = false;

    if (drinking !== null && drinking !== undefined) {
      showDrinking = true;
    }
    if (smoking !== null && smoking !== undefined) {
      showSmoking = true;
    }
    if (cannabis !== null && cannabis !== undefined) {
      showCannabis = true;
    }
    if (exercise !== null && exercise !== undefined) {
      showExercise = true;
    }
    if (relationship !== null && relationship !== undefined) {
      showRelationship = true;
    }
    if (children !== null && children !== undefined) {
      showChildren = true;
    }
    if (education !== null && education !== undefined) {
      showEducation = true;
    }

    if (
      !showCannabis &&
      !showEducation &&
      !showSmoking &&
      !showDrinking &&
      !showRelationship &&
      !showChildren &&
      !showExercise
    ) {
      return null;
    } else {
      return (
        <Card className="user-info-card">
          <Card.Header className="user-info-card-header">
            <Card.Title className="user-info-card-header-title">
              <i className="far fa-umbrella-beach user-info-header-icon"></i>
              Lifestyle Info
            </Card.Title>
          </Card.Header>
          <Card.Body className="user-info-card-body">
            {showSmoking && (
              <div
                className={
                  !showDrinking &&
                  !showCannabis &&
                  !showRelationship &&
                  !showEducation &&
                  !showChildren &&
                  !showExercise
                    ? "user-info-section-bottom"
                    : "user-info-section"
                }
              >
                <h4 className="user-info-section-title">Smoking: </h4>
                <p className="user-info-section-text">{smoking}</p>
              </div>
            )}
            {showDrinking && (
              <div
                className={
                  !showCannabis &&
                  !showRelationship &&
                  !showEducation &&
                  !showChildren &&
                  !showExercise
                    ? "user-info-section-bottom"
                    : "user-info-section"
                }
              >
                <h4 className="user-info-section-title">Drinking: </h4>
                <p className="user-info-section-text">{drinking}</p>
              </div>
            )}
            {showCannabis && (
              <div
                className={
                  !showRelationship &&
                  !showEducation &&
                  !showChildren &&
                  !showExercise
                    ? "user-info-section-bottom"
                    : "user-info-section"
                }
              >
                <h4 className="user-info-section-title">Cannabis: </h4>
                <p className="user-info-section-text">{cannabis}</p>
              </div>
            )}
            {showRelationship && (
              <div
                className={
                  !showEducation && !showChildren && !showExercise
                    ? "user-info-section-bottom"
                    : "user-info-section"
                }
              >
                <h4 className="user-info-section-title">
                  Relationship Status:{" "}
                </h4>
                <p className="user-info-section-text">{relationship}</p>
              </div>
            )}
            {showEducation && (
              <div
                className={
                  !showChildren && !showExercise
                    ? "user-info-section-bottom"
                    : "user-info-section"
                }
              >
                <h4 className="user-info-section-title">Education: </h4>
                <p className="user-info-section-text">{education}</p>
              </div>
            )}
            {showChildren && (
              <div
                className={
                  !showExercise
                    ? "user-info-section-bottom"
                    : "user-info-section"
                }
              >
                <h4 className="user-info-section-title">Children: </h4>
                <p className="user-info-section-text">{children}</p>
              </div>
            )}
            {showExercise && (
              <div className="user-info-section-bottom">
                <h4 className="user-info-section-title">Exercise: </h4>
                <p className="user-info-section-text">{exercise}</p>
              </div>
            )}
          </Card.Body>
        </Card>
      );
    }
  }
}

export default AdditionalUserInfo;
