import React, { useState, useContext } from "react";
import "./desktopfilters.scss";
import { Image } from "react-bootstrap";
import filtersIcon from "../../../../assets/Icons/navbar/filterIcon.png";
import { UserContext } from "../../../../context/UserContext";
import plusIcon from "../../../../assets/Icons/sugarbaePlusIcon.png";
import AdvancedFilterCounter from "../../../filters/filtersComponents/AdvancedFilterCounter";
import SortBy from "./desktopFiltersSliders/SortBy";
import Age from "./desktopFiltersSliders/Age";
import Location from "./desktopFiltersSliders/Location";
import Advanced from "./desktopFiltersSliders/Advanced";
import Plus from "./desktopFiltersSliders/Plus";
import Distance from "./desktopFiltersSliders/Distance";
import { api } from "../../../../api";

function DesktopFiltersV2(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;

  const [sortbyOpen, setSortbyOpen] = useState(false);
  const [locationOpen, setLocationOpen] = useState(false);
  const [distanceOpen, setDistanceOpen] = useState(false);
  const [ageRangeOpen, setAgeRangeOpen] = useState(false);
  const [advancedFiltersOpen, setAdvancedFiltersOpen] = useState(false);
  const [plusSettingsOpen, setPlusSettingsOpen] = useState(false);

  const updateDistance = async (e) => {
    if (e) {
      filters.distance = e;
    }

    context.updateFilters(filters);
    setDistanceOpen(false);
  };

  const updateAgeRange = async (e) => {
    if (e) {
      filters.age = e;
    }

    context.updateFilters(filters);
    setAgeRangeOpen(false);
  };

  const updateSortBy = async (e) => {
    if (e) {
      filters.sortby = e;
    }

    context.updateFilters(filters);
    setSortbyOpen(false);
  };
  const updateLocation = async (e) => {
    if (e) {
      filters.address = e;
    }

    const data = {
      address: filters.address,
    };

    await api
      .updateAddress(data)
      .then((res) => {
        if (res.status === 200) {
          filters.location = res.data.location;
        }
      })
      .catch((err) => {
        console.log(err);
      });

    context.updateFilters(filters);
    setLocationOpen(false);
  };

  const filterToggle = (e) => {
    if (e === "sortby") {
      if (sortbyOpen) {
        setSortbyOpen(false);
      } else {
        setSortbyOpen(true);
      }
    } else if (e === "location") {
      if (locationOpen) {
        setLocationOpen(false);
      } else {
        setLocationOpen(true);
      }
    } else if (e === "distance") {
      if (distanceOpen) {
        setDistanceOpen(false);
      } else {
        setDistanceOpen(true);
      }
    } else if (e === "age") {
      if (ageRangeOpen) {
        setAgeRangeOpen(false);
      } else {
        setAgeRangeOpen(true);
      }
    } else if (e === "advanced") {
      if (advancedFiltersOpen) {
        setAdvancedFiltersOpen(false);
      } else {
        setAdvancedFiltersOpen(true);
      }
    } else if (e === "plus") {
      if (plusSettingsOpen) {
        setPlusSettingsOpen(false);
      } else {
        setPlusSettingsOpen(true);
      }
    }
  };

  const clearFilterHandler = (e) => {
    if (e === "distance") {
      updateDistance(500);
    } else if (e === "age") {
      updateAgeRange([18, 80]);
    }
  };

  let sortbyText = filters.sortby;
  let locationText = filters.address;
  let maximumDistanceText = filters.distance;
  const ageRange = filters.age;
  let ageRangeText = "-";
  if (ageRange) {
    if (ageRange[0] === 18 && ageRange[1] === 80) {
      ageRangeText = "Any";
    } else {
      ageRangeText = `${ageRange[0].toString()} - ${ageRange[1].toString()}`;
    }
  }

  if (!sortbyText) {
    sortbyText = "-";
  }
  if (!locationText) {
    locationText = "-";
  }

  if (maximumDistanceText) {
    if (maximumDistanceText === 500) {
      maximumDistanceText = "Any";
    } else {
      maximumDistanceText = `${maximumDistanceText.toString()} kilometers`;
    }
  } else {
    maximumDistanceText = "-";
  }

  return (
    <div className="desktop-v2-filters">
      <div className="desktop-v2-filters-container">
        <div className="desktop-v2-filters-popup">
          {!sortbyOpen &&
            !locationOpen &&
            !distanceOpen &&
            !ageRangeOpen &&
            !advancedFiltersOpen &&
            !plusSettingsOpen && (
              <div className="desktop-v2-filters-header">
                <div className="desktop-v2-filter-title-wrapper">
                  <Image
                    src={filtersIcon}
                    className="desktop-v2-filters-popup-icon"
                    fluid
                  />
                  <span className="desktop-v2-filters-popup-title">
                    Filters
                  </span>
                </div>
                <div
                  className="desktop-v2-filter-close-button"
                  onClick={props.toggleFilters}
                >
                  <i className="far fa-times" />
                </div>
              </div>
            )}
          {sortbyOpen && (
            <SortBy
              backHandler={() => setSortbyOpen(false)}
              updateHandler={updateSortBy}
            />
          )}
          {locationOpen && (
            <Location
              backHandler={() => setLocationOpen(false)}
              updateHandler={updateLocation}
            />
          )}
          {ageRangeOpen && (
            <Age
              backHandler={() => setAgeRangeOpen(false)}
              updateHandler={updateAgeRange}
            />
          )}
          {advancedFiltersOpen && (
            <Advanced backHandler={() => setAdvancedFiltersOpen(false)} />
          )}
          {plusSettingsOpen && (
            <Plus backHandler={() => setPlusSettingsOpen(false)} />
          )}
          {distanceOpen && (
            <Distance
              backHandler={() => setDistanceOpen(false)}
              updateHandler={updateDistance}
            />
          )}

          {!sortbyOpen &&
            !locationOpen &&
            !distanceOpen &&
            !ageRangeOpen &&
            !advancedFiltersOpen &&
            !plusSettingsOpen && (
              <div className="desktop-v2-filters-content">
                <div>
                  <div
                    className="desktop-v2-filters-row"
                    onClick={() => filterToggle("sortby")}
                  >
                    <div className="desktop-v2-filters-row-section">
                      <div className="desktop-v2-filters-row-title-container">
                        <div className="desktop-v2-filters-icon">
                          <i className="far fa-sort desktop-v2-filters-icon" />
                        </div>
                        <div className="desktop-v2-filters-row-title">
                          Sort By
                        </div>
                      </div>
                    </div>
                    <div className="desktop-v2-filters-row-section">
                      <div className="desktop-v2-filters-row-action-container">
                        <div className="desktop-v2-filters-row-content-text">
                          {sortbyText}
                        </div>
                        <div className="desktop-v2-filters-row-action-button">
                          <i className="far fa-chevron-right" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="desktop-v2-filters-row"
                    onClick={() => filterToggle("location")}
                  >
                    <div className="desktop-v2-filters-row-section">
                      <div className="desktop-v2-filters-row-title-container">
                        <div className="desktop-v2-filters-icon">
                          <i className="far fa-home desktop-v2-filters-icon" />
                        </div>
                        <div className="desktop-v2-filters-row-title">
                          Location
                        </div>
                      </div>
                    </div>
                    <div className="desktop-v2-filters-row-section">
                      <div className="desktop-v2-filters-row-action-container">
                        <div className="desktop-v2-filters-row-content-text">
                          {locationText}
                        </div>
                        <div className="desktop-v2-filters-row-action-button">
                          <i className="far fa-chevron-right" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="desktop-v2-filters-row">
                    <div
                      className="desktop-v2-filters-row-section"
                      onClick={() => filterToggle("distance")}
                    >
                      <div className="desktop-v2-filters-row-title-container">
                        <div className="desktop-v2-filters-icon">
                          <i className="far fa-expand-alt desktop-v2-filters-icon" />
                        </div>
                        <div className="desktop-v2-filters-row-title">
                          Maximum distance
                        </div>
                      </div>
                    </div>
                    <div className="desktop-v2-filters-row-section">
                      <div className="desktop-v2-filters-row-action-container">
                        <div
                          className="desktop-v2-filters-row-content-text"
                          onClick={() => filterToggle("distance")}
                        >
                          {maximumDistanceText}
                        </div>
                        {filters.distance === 500 ? (
                          <div
                            className="desktop-v2-filters-row-action-button"
                            onClick={() => filterToggle("distance")}
                          >
                            <i className="far fa-chevron-right" />
                          </div>
                        ) : (
                          <div
                            className="desktop-v2-filters-row-action-button"
                            onClick={() => clearFilterHandler("distance")}
                          >
                            <i className="far fa-times-circle" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="desktop-v2-filters-row">
                    <div
                      className="desktop-v2-filters-row-section"
                      onClick={() => filterToggle("age")}
                    >
                      <div className="desktop-v2-filters-row-title-container">
                        <div className="desktop-v2-filters-icon">
                          <i className="far fa-id-card desktop-v2-filters-icon" />
                        </div>
                        <div className="desktop-v2-filters-row-title">
                          Age Range
                        </div>
                      </div>
                    </div>
                    <div className="desktop-v2-filters-row-section">
                      <div className="desktop-v2-filters-row-action-container">
                        <div
                          className="desktop-v2-filters-row-content-text"
                          onClick={() => filterToggle("age")}
                        >
                          {ageRangeText}
                        </div>
                        {filters.age[0] === 18 && filters.age[1] === 80 ? (
                          <div
                            className="desktop-v2-filters-row-action-button"
                            onClick={() => filterToggle("age")}
                          >
                            <i className="far fa-chevron-right" />
                          </div>
                        ) : (
                          <div
                            className="desktop-v2-filters-row-action-button"
                            onClick={() => clearFilterHandler("age")}
                          >
                            <i className="far fa-times-circle" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className="desktop-v2-filters-row"
                    onClick={() => filterToggle("advanced")}
                  >
                    <div className="desktop-v2-filters-row-section">
                      <div className="desktop-v2-filters-row-title-container">
                        <div className="desktop-v2-filters-icon">
                          <i className="far fa-wine-glass-alt desktop-v2-filters-icon" />
                        </div>
                        <div className="desktop-v2-filters-row-title">
                          Advanced Filters
                        </div>
                      </div>
                    </div>
                    <div className="desktop-v2-filters-row-section">
                      <div className="desktop-v2-filters-row-action-container">
                        <div className="desktop-v2-filters-row-content-text">
                          <AdvancedFilterCounter filters={filters} />
                        </div>
                        <div className="desktop-v2-filters-row-action-button">
                          <i className="far fa-chevron-right" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="desktop-v2-filters-row"
                    onClick={() => filterToggle("plus")}
                  >
                    <div className="desktop-v2-filters-row-section">
                      <div className="desktop-v2-filters-row-title-container">
                        <div className="desktop-v2-filters-icon">
                          <Image
                            src={plusIcon}
                            className="filters-sb-plus-icon"
                            fluid
                          />
                        </div>
                        <div className="desktop-v2-filters-row-title">
                          Sugarbae Plus Settings
                        </div>
                      </div>
                    </div>
                    <div className="desktop-v2-filters-row-section">
                      <div className="desktop-v2-filters-row-action-container">
                        <div className="desktop-v2-filters-row-content-text" />
                        <div className="desktop-v2-filters-row-action-button">
                          <i className="far fa-chevron-right" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
        <div
          className="desktop-v2-filters-overlay"
          onClick={props.toggleFilters}
        />
      </div>
    </div>
  );
}

export default DesktopFiltersV2;
