import React, { Component } from "react";
import "./browsepage.scss";
import HomeNav from "../../../components/navbar/HomeNav/HomeNav";
import HomeFooter from "../../../components/footers/homeFooter/HomeFooter";
import BrowseGrid from "../../../components/grid/browseGrid/BrowseGrid";
import NumberOneCta from "../../../components/ctas/numberOneCta/NumberOneCta";
import MetaTags from "react-meta-tags";

export class BrowsePage extends Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <MetaTags>
          <title>
            Browse Members | Find a Successfull Man or Attractive Female |
            Sugarbae
          </title>{" "}
          <meta
            name="description"
            content="Browse Successful Members and Attractive Members online now on Sugarbae. The #1 Arrangement dating app with over 1 million active users. Find your ideal arrangement."
          />
        </MetaTags>
        <HomeNav />
        <BrowseGrid />
        <NumberOneCta />
        <HomeFooter />
      </div>
    );
  }
}

export default BrowsePage;
