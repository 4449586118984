import React, { useContext, useState } from 'react';
import { UserContext } from '../../../../context/UserContext';
import './matchv2.scss';
import { Image, Transformation } from 'cloudinary-react';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { Button } from 'react-bootstrap';
import { Image as BootstrapImage } from 'react-bootstrap';
import logo from '../../../../assets/logos/icon-only-white.png';
import { Link, withRouter } from 'react-router-dom';
import Media from '../../../../components/media/Media';

function Match(props) {
  const context = useContext(UserContext);
  var images = props.matchUser.imgurl;
  var mainImage = null;
  if (images) {
    mainImage = images[0];
  }

  const closeHandler = (e) => {
    props.closeMatchHandler();
  };

  var mainUserImage = null;
  if (context.user.imgurl) {
    mainUserImage = context.user.imgurl[0];
  }

  var messagesLink = 'messages/' + props.matchUser._id;

  if (props.location.pathname === '/app') {
    messagesLink = 'app/messages/' + props.matchUser._id;
  }

  return (
    <div className="match-v2-component">
      <div className="match-v2-innerwrap">
        <div className="match-v2-close-wrapper">
          <Button className="match-v2-close-button" onClick={closeHandler}>
            <i className="far fa-times match-v2-close-icon"></i>
          </Button>
        </div>
        <div className="match-v2-image-overlay" />
        <div className="match-v2-content-container">
          <div className="match-v2-content-section">
            <div className="match-v2-content-innerwrap">
              <div className="match-v2-match-images-wrapper">
                <div className="match-v2-image-card-wrapper-left">
                  <Media image={mainImage} />
                  {/* <Image
                    publicId={
                      mainImage
                        ? getImgName(mainImage)
                        : "larger-placeholder-user-image_vvdghq.png"
                    }
                    version={
                      mainImage ? getImgVersion(mainImage) : "1590046357"
                    }
                    resource_type={
                      mainImage
                        ? checkGIF(mainImage)
                          ? "video"
                          : "image"
                        : null
                    }
                    effect="loop"
                    cloud_name="sugarbae"
                    className="match-v2-image-card"
                    fluid
                  >
                    <Transformation
                      height="200"
                      video_sampling="20"
                      delay="250"
                      duration="10"
                      effect="loop"
                      quality="auto"
                      flags="lossy"
                    />
                  </Image> */}
                </div>
                <div className="match-v2-logo-wrapper">
                  <BootstrapImage src={logo} className="match-v2-logo" />
                </div>
                <div className="match-v2-image-card-wrapper-right">
                  <Media image={mainUserImage} />
                  {/* <Image
                    publicId={
                      mainUserImage
                        ? getImgName(mainUserImage)
                        : 'larger-placeholder-user-image_vvdghq.png'
                    }
                    version={
                      mainUserImage
                        ? getImgVersion(mainUserImage)
                        : '1590046357'
                    }
                    resource_type={
                      mainUserImage
                        ? checkGIF(mainUserImage)
                          ? 'video'
                          : 'image'
                        : null
                    }
                    effect="loop"
                    cloud_name="sugarbae"
                    className="match-v2-image-card"
                    fluid
                  >
                    <Transformation
                      height="200"
                      video_sampling="20"
                      delay="250"
                      duration="10"
                      effect="loop"
                      quality="auto"
                      flags="lossy"
                    />
                  </Image> */}
                </div>
              </div>
              <div className="match-v2-title-wrapper">
                <h1 className="match-v2-title">It's a match!</h1>
                <p className="match-v2-subtitle">
                  {props.matchUser.username} likes you too!
                </p>
              </div>
            </div>
          </div>
          <div className="match-v2-content-footer">
            <Link to={messagesLink} style={{ width: '100%' }}>
              <Button className="match-v2-send-message-button">
                Send a quick "Hello"
              </Button>
            </Link>

            <Button className="match-v2-other-tips-button">Other Tips</Button>
          </div>
        </div>

        <Media image={mainImage} />

        {/* <Image
          publicId={
            mainImage
              ? getImgName(mainImage)
              : 'larger-placeholder-user-image_vvdghq.png'
          }
          version={mainImage ? getImgVersion(mainImage) : '1590046357'}
          resource_type={
            mainImage ? (checkGIF(mainImage) ? 'video' : 'image') : null
          }
          effect="loop"
          cloud_name="sugarbae"
          className="match-background-image"
          fluid
        >
          <Transformation
            height="200"
            video_sampling="20"
            delay="250"
            duration="10"
            effect="loop"
            quality="auto"
            flags="lossy"
          />
        </Image> */}
      </div>
    </div>
  );
}

export default withRouter(Match);
