import React from "react";
import "./filterscomponentsstyles.scss";

function AdvancedFilterCounter(props) {
  var filters = props.filters;
  var count = 0;
  if (filters) {
    if (filters.relationshipstatus) {
      if (
        filters.relationshipstatus.single ||
        filters.relationshipstatus.divorced ||
        filters.relationshipstatus.separated ||
        filters.relationshipstatus.marriedbutlooking ||
        filters.relationshipstatus.openrelationship ||
        filters.relationshipstatus.widowed
      ) {
        count = count + 1;
      }
    }
    if (filters.height) {
      if (filters.height[0] !== 0 || filters.height[1] !== 36) {
        count = count + 1;
      }
    }
    if (filters.bodytype) {
      if (
        filters.bodytype.slim ||
        filters.bodytype.fit ||
        filters.bodytype.muscular ||
        filters.bodytype.average ||
        filters.bodytype.afewextrapounds ||
        filters.bodytype.curvy ||
        filters.bodytype.fullfigured
      ) {
        count = count + 1;
      }
    }
    if (filters.ethnicity) {
      if (
        filters.ethnicity.white ||
        filters.ethnicity.black ||
        filters.ethnicity.asian ||
        filters.ethnicity.hispanic ||
        filters.ethnicity.indian ||
        filters.ethnicity.middleeastern ||
        filters.ethnicity.other
      ) {
        count = count + 1;
      }
    }
    if (filters.drinking) {
      if (
        filters.drinking.nondrinker ||
        filters.drinking.socialdrinker ||
        filters.drinking.heavydrinker
      ) {
        count = count + 1;
      }
    }
    if (filters.smoking) {
      if (
        filters.smoking.nonsmoker ||
        filters.smoking.lightsmoker ||
        filters.smoking.heavysmoker
      ) {
        count = count + 1;
      }
    }
    if (filters.cannabis) {
      if (
        filters.cannabis.nonsmoker ||
        filters.cannabis.lightsmoker ||
        filters.cannabis.heavysmoker
      ) {
        count = count + 1;
      }
    }
    if (filters.education) {
      if (
        filters.education.highschool ||
        filters.education.somecollege ||
        filters.education.associatesdegree ||
        filters.education.bachelorsdegree ||
        filters.education.graduatedegree ||
        filters.education.phd
      ) {
        count = count + 1;
      }
    }
    if (filters.children) {
      if (
        filters.children.nokids ||
        filters.children.onekid ||
        filters.children.twokids ||
        filters.children.threekids ||
        filters.children.fourpluskids
      ) {
        count = count + 1;
      }
    }
    if (filters.exercise) {
      if (
        filters.exercise.never ||
        filters.exercise.sometimes ||
        filters.exercise.regularly ||
        filters.exercise.often ||
        filters.exercise.daily
      ) {
        count = count + 1;
      }
    }
  }

  if (count > 0) {
    return (
      <div className="advanced-filter-counter">
        <span>{count}</span>
      </div>
    );
  } else {
    return null;
  }
}

export default AdvancedFilterCounter;
