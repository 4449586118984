import React, { useContext, useState, useEffect } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import { UserContext } from '../../context/UserContext';
import { api } from '../../api';
import {
  getImgName,
  getImgVersion,
  checkGIF,
} from '../../utils/getCloudinaryInfo';
import { withRouter, useHistory } from 'react-router-dom';
import './requests.scss';
import complimentIcon from '../../assets/Images/compliment/gifticon.png';
import { Image as BootstrapImage } from 'react-bootstrap';
import Media from '../../components/media/Media';

function People(props) {
  const context = useContext(UserContext);
  const [peoples, setPeoples] = useState(context.approvedRequests);
  const [fetching, setFetching] = useState(false);
  const [refresh, setRefresh] = useState(false);
  let history = useHistory();

  const declineHandler = (requestid, index) => {
    const data = {
      requestid,
      option: 'decline',
    };

    return api
      .handleRequest(data)
      .then(() => {
        peoples.splice(index, 1);
        context.updateApprovedRequests(peoples);
        setPeoples(peoples);
        setRefresh(true);
      })
      .catch(() => {});
  };

  const handleScroll = () => {
    if (!context.endApprovedRequests && !fetching) {
      setFetching(true);
      context.getMoreApprovedRequests();
    }
  };
  useEffect(() => {
    setFetching(false);
    setPeoples(context.approvedRequests);
  }, [context.approvedRequests]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  const backHandler = (e) => {
    history.goBack();
  };

  const scrollRef = useBottomScrollListener(handleScroll);

  return (
    <div className="requests-component">
      <div className="requests-header">
        <div className="requests-header-left">
          <div className="requests-return-button" onClick={backHandler}>
            <i className="fas fa-chevron-left"></i>
          </div>
        </div>
        <div className="requests-header-middle">
          <h1 className="requests-header-title">People</h1>
        </div>
      </div>

      {!peoples || peoples.length < 1 ? (
        <div className="requests-content">
          <div className="no-diamond-history-container">
            <BootstrapImage
              src={complimentIcon}
              className="no-diamond-history-image"
              fluid
            />
            <h2 className="no-diamond-history-title">Private Photo Access</h2>
            <p className="no-diamond-history-subtitle">
              Manage who has access to your private photos. No users currently
              have access.
            </p>
          </div>
        </div>
      ) : (
        <div className="requests-content" ref={scrollRef}>
          {peoples &&
            peoples.map((item, index) => {
              const { imgurl, username, age, address } = item.user;
              const mainImage = imgurl.length > 0 ? imgurl[0] : null;
              const requestId = item._id;
              return (
                <div className="edit-profile-v2-private-request">
                  <div className="edit-profile-v2-request-image-wrapper">
                    <Media image={mainImage} />
                    {/* <Image
                      publicId={
                        mainImage
                          ? getImgName(mainImage)
                          : "placeholder-user-image-thumbnail_vankru.png"
                      }
                      version={
                        mainImage ? getImgVersion(mainImage) : "1590045309"
                      }
                      resource_type={
                        mainImage
                          ? checkGIF(mainImage)
                            ? "video"
                            : "image"
                          : null
                      }
                      effect="loop"
                      cloud_name="sugarbae"
                      className="edit-profile-v2-request-image"
                    >
                      <Transformation
                        height="200"
                        video_sampling="20"
                        delay="250"
                        duration="10"
                        effect="loop"
                        quality="auto"
                        flags="lossy"
                      />
                    </Image> */}
                  </div>
                  <div className="edit-profile-v2-request-info">
                    <h1 className="edit-profile-v2-request-username">{`${username}, ${age}`}</h1>
                    <label className="edit-profile-v2-request-user-address">
                      {address}
                    </label>
                  </div>
                  <div className="edit-profile-v2-request-action">
                    <i
                      className="far fa-trash-alt edit-profile-v2-request-decline-icon"
                      onClick={() => declineHandler(requestId, index)}
                    ></i>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default withRouter(People);
