import React, { useState, useEffect, useContext } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import GoogleMapReact from "google-map-react";
import { Button, Form } from "react-bootstrap";
import { UserContext } from "../../../../../context/UserContext";
import { api } from "../../../../../api";
import ClipLoader from "react-spinners/ClipLoader";

const moment = require("moment");

function EditAge(props) {
  const context = useContext(UserContext);
  const [birthdate, setBirthdate] = useState(context.user.identity.birthdate);
  const todaysDate = moment().format("YYYY-MM-DD");
  const minDate = moment(todaysDate).subtract(18, "years").format("YYYY-MM-DD");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      context.user.identity.birthDate === null ||
      context.user.identity.birthDate === undefined
    ) {
      const userAge = context.user.age + 1;

      const userBirthday = moment()
        .subtract(userAge, "years")
        .format("YYYY-MM-DD");
      setBirthdate(userBirthday);
    } else {
      const formattedBirthdate = moment(context.user.identity.birthDate).format(
        "YYYY-MM-DD"
      );
      setBirthdate(formattedBirthdate);
    }
  }, []);

  const saveHandler = async (e) => {
    setLoading(true);
    if (birthdate) {
      const data = {
        birthdate,
      };

      await api
        .updateBirthdate(data)
        .then((res) => {
          let tempIdentity = context.user.identity;
          const tempBirthdate = moment(birthdate).toDate();
          tempIdentity.birthDate = tempBirthdate;
          const userAge = moment().diff(tempBirthdate, "years", false);
          context.updateUserInfo("identity", tempIdentity);
          context.updateUserInfo("age", userAge);

          props.onClose();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setLoading(false);
    props.onClose();
  };

  return (
    <div className="filter-slider-v2">
      {loading && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 3,
          }}
        >
          <ClipLoader size="14" color="#b5b5b5" />
        </div>
      )}
      <div className="filter-slider-v2-overlay" onClick={props.onClose} />
      <div className="filter-slider-v2-content">
        <div className="filter-slider-v2-close-bar" />
        <div className="filter-slider-v2-pop">
          <div className="filter-slider-v2-header">
            <div className="filter-slider-v2-header-section">
              <i className="far fa-birthday-cake sort-v2-heart-icon" />
              <span className="filter-slider-v2-title">Birthdate</span>
            </div>
            <div className="filter-slider-v2-header-section" />
          </div>
          <div className="filter-slider-v2-form-wrapper">
            <div className="filter-slider-v2-form">
              <div
                className="filter-slider-v2-form-group"
                style={{ paddingBottom: "15rem" }}
              >
                <label>
                  Enter your date of birth:
                  <input
                    className="login-v2-birthdate"
                    type="date"
                    id="date"
                    value={birthdate}
                    max={minDate}
                    onChange={(e) => setBirthdate(e.target.value)}
                  />
                  <span className="filter-slider-v2-form-checkmark" />
                </label>
              </div>
            </div>
          </div>

          <div className="filter-save-button-wrapper">
            <div className="filter-save-button" onClick={saveHandler}>
              Update
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAge;
