import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Image, Card, Button } from "react-bootstrap";

export class GettingStarted extends Component {
  render() {
    return (
      <div className="sd-getting-started">
        <h2 className="sd-gs-title">Getting Started</h2>
        <Container className="sd-gs-container">
          <div className="sd-gs-col">
            <i className="fal fa-user-plus getting-started-icon"></i>{" "}
            <h3 className="sd-gs-col-title">1. Join</h3>
            <p className="sd-gs-col-content">
              Meet millions of members by joining Sugarbae in as little as 5
              minutes. 100% Free. Setup your profile and choose your benefits.
            </p>
          </div>
          <div className="sd-gs-col">
            <i className="fal fa-comment-alt-lines getting-started-icon"></i>
            <h3 className="sd-gs-col-title">2. Seek</h3>
            <p className="sd-gs-col-content">
              Finally a dating experience where you can find what you are
              actually looking for. Find a mutually beneficial arrangement in
              just days.
            </p>
          </div>
          <div className="sd-gs-col">
            <i className="fab fa-hotjar getting-started-icon"></i>{" "}
            <h3 className="sd-gs-col-title">3. Benefit</h3>
            <p className="sd-gs-col-content">
              Experience the benefits of the Sugar Daddy lifestyle. Convenient,
              transparent, no strings attached dating that cuts straight to the
              point.
            </p>
          </div>
        </Container>
        <div className="sd-gs-btn-wrap">
          <Link to="/signup">
            <Button variant="outline-dark" className="sd-gs-btn">
              Get Started Now
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default GettingStarted;
