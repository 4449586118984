import React, { Component } from "react";
import "./featurescard.scss";
import { Button, Card, Image } from "react-bootstrap";

export class FeaturesCard extends Component {
  render() {
    return (
      <Card className="features-card">
        <Card className="features-card-body">
          <div className="features-card-title-wrap">
            <i className="fas fa-gem features-card-title-icon"></i>
            <h2 className="features-card-title">Premium Features</h2>
          </div>
          <div className="features-card-features-wrapper">
            <Card className="features-card-feature">
              <Card.Body className="features-card-feature-body">
                <i className="fas fa-comment-alt-lines feature-card-feature-icon-message"></i>
                <div className="feature-card-feature-text-wrap">
                  <h4 className="feature-card-feature-title">
                    Unlock Messaging
                  </h4>
                  <p className="feature-card-feature-description">
                    Message anyone including non-matches.
                  </p>
                </div>
              </Card.Body>
            </Card>
            <Card className="features-card-feature">
              <Card.Body className="features-card-feature-body">
                <i className="fas fa-heart feature-card-feature-icon-heart"></i>
                <div className="feature-card-feature-text-wrap">
                  <h4 className="feature-card-feature-title">
                    Unlimited Likes
                  </h4>
                  <p className="feature-card-feature-description">
                    Like as many users as you want.
                  </p>
                </div>
              </Card.Body>
            </Card>
            <Card className="features-card-feature">
              <Card.Body className="features-card-feature-body">
                <i className="fas fa-stars feature-card-feature-icon-stars"></i>
                <div className="feature-card-feature-text-wrap">
                  <h4 className="feature-card-feature-title">
                    Extra Super Likes
                  </h4>
                  <p className="feature-card-feature-description">
                    Get 5 Super Likes per day.
                  </p>
                </div>
              </Card.Body>
            </Card>
            <Card className="features-card-feature">
              <Card.Body className="features-card-feature-body">
                <i className="fas fa-bolt feature-card-feature-icon-bolt"></i>
                <div className="feature-card-feature-text-wrap">
                  <h4 className="feature-card-feature-title">Profile Boosts</h4>
                  <p className="feature-card-feature-description">
                    Skip the line and boost your profile. 1 Boost per week.
                  </p>
                </div>
              </Card.Body>
            </Card>
            <Card className="features-card-feature">
              <Card.Body className="features-card-feature-body">
                <i className="fas fa-paper-plane feature-card-feature-icon-note"></i>
                <div className="feature-card-feature-text-wrap">
                  <h4 className="feature-card-feature-title">Send Notes</h4>
                  <p className="feature-card-feature-description">
                    Send Notes to users you haven't matched with.
                  </p>
                </div>
              </Card.Body>
            </Card>
            <Card className="features-card-feature">
              <Card.Body className="features-card-feature-body">
                <i className="fas fa-undo feature-card-feature-icon-undo"></i>
                <div className="feature-card-feature-text-wrap">
                  <h4 className="feature-card-feature-title">
                    Unlimited Rewinds
                  </h4>
                  <p className="feature-card-feature-description">
                    Rewind likes, dislikes, and superlikes. Never miss out on
                    matches.
                  </p>
                </div>
              </Card.Body>
            </Card>
            <Card className="features-card-feature">
              <Card.Body className="features-card-feature-body">
                <i className="fas fa-sliders-h feature-card-feature-icon-filters"></i>
                <div className="feature-card-feature-text-wrap">
                  <h4 className="feature-card-feature-title">
                    Advanced Filters
                  </h4>
                  <p className="feature-card-feature-description">
                    Unlock extra search filters.
                  </p>
                </div>
              </Card.Body>
            </Card>
            <Card className="features-card-feature">
              <Card.Body className="features-card-feature-body">
                <i className="fas fa-check-double feature-card-feature-icon-read"></i>
                <div className="feature-card-feature-text-wrap">
                  <h4 className="feature-card-feature-title">Read Receipts</h4>
                  <p className="feature-card-feature-description">
                    Know when your messages have been read.
                  </p>
                </div>
              </Card.Body>
            </Card>
            <Card className="features-card-feature">
              <Card.Body className="features-card-feature-body">
                <i className="fas fa-eye feature-card-feature-icon-eye"></i>
                <div className="feature-card-feature-text-wrap">
                  <h4 className="feature-card-feature-title">View Likes</h4>
                  <p className="feature-card-feature-description">
                    See who likes you.
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Card>
      </Card>
    );
  }
}

export default FeaturesCard;
