import React, { Component } from "react";
import RingLoader from "react-spinners/RingLoader";
import { Image } from "react-bootstrap";
import "./spinners.scss";
import whiteLogo from "../../assets/logos/sugarbae-all-white.png";
import PropagateLoader from "react-spinners/PropagateLoader";

export class LogoSpinner extends Component {
  render() {
    return (
      <div className="loading-page-component">
        <div className="loading-page-image-section">
          <Image className="logo-spinner-logo" src={whiteLogo} />
        </div>
        <div className="loading-page-progress-section">
          <div className="loading-page-cliploader-wrapper">
            <PropagateLoader size={15} color={"#fff"} />
          </div>
          <div className="loading-page-loading-text-wrapper">
            <p className="logo-spinner-subtitle">Loading</p>
          </div>
        </div>
      </div>
    );
  }
}

export default LogoSpinner;
