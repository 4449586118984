import React, { useContext, useState, useEffect } from "react";
import "./tabs.scss";
import Axios from "axios";

import { withRouter } from "react-router-dom";
import { UserContext } from "../../../../../context/UserContext";
import MatchCard from "../../matchCard/MatchCard";
import NoMatchesIndicator from "./indicator/NoMatchesIndicator";
import BottomScrollListener from "react-bottom-scroll-listener";
import { motion } from "framer-motion";
import { api } from "../../../../../api";

export const Matches = props => {
  const context = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    context.updateMatchesAsRead();
    setFetching(false);
  },[context.matches]);

  const handleScroll = (e) => {
    if (!fetching && !context.maxMatches) {
      setFetching(true);
      context.checkMoreMatches();
    }
  };

  const redirectHandler = (e) => {
    props.history.push(e);
  };


  const pageVariants = {
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: "-100%",
    },
  };

  const pageTransition = {
    transition: "linear",
  };

  if (loading) {
    return null;
  } else {
    return (
      <BottomScrollListener
        onBottom={handleScroll}
        offset="1500"
      >
        {(scrollRef) => (
          <motion.div
            className="tabs-component"
            ref={scrollRef}
            transition={pageTransition}
            initial="out"
            animate="in"
            exit="out"
            variants={pageVariants}
          >
            {context.matches.length > 0 ? (
              <div className="matches-tab-innerwrap">
                {context.matches.map((match) => {
                  return (
                    <MatchCard
                      match={match}
                      link={redirectHandler}
                      profileToggle={props.profileToggle}
                    />
                  );
                })}
                {context.maxMatches && (
                  <div className="end-indicator">
                    No more Matches.
                  </div>
                )}
              </div>
            ) : (
              <div className="matches-tab-innerwrap">
                <NoMatchesIndicator />
              </div>
            )}
          </motion.div>
        )}
      </BottomScrollListener>
    );
  }
}

export default withRouter(Matches);
