import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Table,
  Card,
  Button,
  Modal,
  Form,
  Alert,
  Badge,
  Dropdown,
} from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import Select from 'react-dropdown-select';
import { Image, Transformation } from 'cloudinary-react';
import { Image as NoneImage } from 'react-bootstrap';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import Axios from 'axios';

import male_user from '../../../../assets/Images/adminSidebar/male_user.png';
import female_user from '../../../../assets/Images/adminSidebar/female_user.png';
import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../../utils/getCloudinaryInfo';
import { api } from '../../../../api';
import BugCard from './reportTabComponents/BugCard';
import ReportUsers from './reportTabComponents/ReportUsers';
import Media from '../../../../components/media/Media';

import '../../admincomponents.scss';
import moment from 'moment';

export const ReportTab = (props) => {
  const reportOptions = [
    {
      value: 'first',
      label: 'All',
    },
    {
      value: 'second',
      label: 'Inappropriate Behavior',
    },
    {
      value: 'third',
      label: 'Feels Like Spam',
    },
    {
      value: 'fourth',
      label: 'Other',
    },
  ];

  const bugOptions = [
    {
      value: 'first',
      label: 'All',
    },
    {
      value: 'second',
      label: 'General',
    },
    {
      value: 'third',
      label: 'Uploading Photos / Videos',
    },
    {
      value: 'fourth',
      label: 'Messaging',
    },
    {
      value: 'fifth',
      label: 'Swiping / Matching',
    },
    {
      value: 'sixth',
      label: 'Changing Email or Password',
    },
    {
      value: 'seventh',
      label: 'Managing Subscription',
    },
    {
      value: 'eighth',
      label: 'Billing and Payments',
    },
    {
      value: 'nineth',
      label: 'Login & Signup',
    },
  ];

  const [loading, setLoading] = useState(true);
  const [reason, setReason] = useState('All');
  const [options, setOptions] = useState(reportOptions);
  const [reportOption, setReportOption] = useState('report');
  const [reportSkip, setReportSkip] = useState(0);
  const [reports, setReports] = useState([]);
  const [selectedOption, setSelectedOption] = useState(reportOptions[0]);
  const [optionChanged, setOptionChanged] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState('1');
  const [pages, setPages] = useState([
    {
      value: '1',
      label: '1',
    },
    {
      value: '2',
      label: '2',
    },
    {
      value: '3',
      label: '3',
    },
  ]);
  const [bugSkip, setBugSkip] = useState(0);
  const [bugs, setBugs] = useState([]);
  const [endBugs, setEndBugs] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = () => {
    const data = {
      skip: reportSkip,
      reason,
    };

    return api
      .fetchReports(data)
      .then((res) => {
        setReports(res.data.reports);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchBugs = () => {
    const data = {
      skip: bugSkip,
      reason,
    };

    return api
      .fetchbugs(data)
      .then((res) => {
        const newBugs = res.data.bugs;
        const temp = bugs.concat(newBugs);
        setBugs(temp);
        if (newBugs.length < 12) {
          setEndBugs(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeOption = (values) => {
    if (reason !== values[0].label) {
      setLoading(true);
      setReason(values[0].label);
      if (reportOption === 'report') {
        setReportSkip(0);
      } else {
        setEndBugs(false);
        setBugSkip(0);
        setBugs([]);
      }
    }
  };

  const bugOption = (e) => {
    setReason('All');
    setOptionChanged(true);
    if (e.target.checked === false) {
      setReportOption('report');
      setReportSkip(0);
      setLoading(true);
      setOptions(reportOptions);
    } else {
      setReportOption('bug');
      setBugs([]);
      setBugSkip(0);
      setEndBugs(false);
      setLoading(true);
      setOptions(bugOptions);
    }
  };

  const prevPage = () => {
    if (Number(page) > 1) {
      setLoading(true);
      setReportSkip(reportSkip - 12);
      setPage(String(Number(page) - 1));
    }
  };

  const nextPage = () => {
    setLoading(true);
    setReportSkip(reportSkip + 12);
    setPage(String(Number(page) + 1));
  };

  const refresh = () => {
    if (reportOption === 'report' && reason === 'All' && reportSkip === 0) {
      return null;
    }
    setLoading(true);
    setReportSkip(0);
    setReportOption('report');
    setOptions(reportOptions);
    setReason('All');
  };

  const scrollHandler = () => {
    if (!loading && !endBugs) {
      setLoading(true);
      setBugSkip(bugSkip + 12);
    }
  };

  const showUserInfo = (index) => {
    setShowInfo(true);
    setSelectedReport(index + 1);
  };

  const closeUserInfo = () => {
    setShowInfo(false);
    setSelectedReport(null);
  };

  const banDefendant = (index) => {
    setSelectedReport(index + 1);
    setAction('ban');
    setShowModal(true);
  };

  const declineReport = (index) => {
    setSelectedReport(index + 1);
    setAction('decline');
    setShowModal(true);
  };

  const cancelAction = () => {
    setAlertOpen(false);
    setSelectedReport(null);
    setAction('');
    setShowModal(false);
  };

  const banHandler = () => {
    const token = localStorage.getItem('auth');
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + '/api/admin/banned';
    const config = {
      headers: { auth: token },
      user: reports[selectedReport - 1].receiverUser,
      banned: true,
    };

    return Axios.post(url, config)
      .then((res) => {
        setAlertOpen(true);
      })
      .catch((err) => {
        console.log('Error Submitting Action');
      });
  };

  const declineHandler = () => {
    const data = {
      reportid: reports[selectedReport - 1]._id,
    };

    return api
      .declineReport(data)
      .then(() => {
        setAlertOpen(true);
        reports.splice(selectedReport - 1, 1);
        setReports(reports);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setPage('1');
    if (reportOption === 'report') {
      fetchReports();
    } else {
      fetchBugs();
    }
    setOptionChanged(false);
  }, [reportOption]);

  useEffect(() => {
    setPage('1');
    if (!optionChanged && reportOption === 'report') {
      fetchReports();
    } else if (!optionChanged && reportOption === 'bug') {
      fetchBugs();
    } else {
      setLoading(false);
    }
  }, [reason]);

  useEffect(() => {
    fetchReports();
  }, [reportSkip]);

  useEffect(() => {
    if (bugSkip !== 0) {
      fetchBugs();
    }
  }, [bugSkip]);

  const scrollRef = useBottomScrollListener(scrollHandler);

  return (
    <Card bg={'dark'} text={'white'} className="report-table-card">
      {selectedReport && showModal && (
        <Modal
          show={showModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirm Action:{' '}
              {action === 'ban' ? 'Ban Defendant' : 'Decline Report'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!alertOpen ? (
              <h4>
                {action === 'decline'
                  ? 'Are you Sure? '
                  : `Defendant: ${
                      reports[selectedReport - 1].receiverUser.username
                    }`}
              </h4>
            ) : (
              <h4>Action successfully done.</h4>
            )}
          </Modal.Body>
          <Modal.Footer className="modal-footer-innerwrap">
            <Button variant="secondary" onClick={() => cancelAction()}>
              Close
            </Button>
            {action === 'ban' ? (
              <Button variant="danger" onClick={() => banHandler()}>
                Ban Defendant
              </Button>
            ) : (
              <Button variant="danger" onClick={() => declineHandler()}>
                Decline Report
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
      <Card.Header>
        <div className="card-header-wrap">
          <div className="card-header-leftside" id="card-header-table">
            <span className="icon icon-moderate-reports"></span>
            <h4 className="card-header-title">Reports</h4>
          </div>
          <div className="loading-spinner">
            <ClipLoader size={10} color={'#FFF'} loading={loading} />
          </div>
          <div className="report-option">
            <div className="toggle-button-cover">
              <div className="button-cover">
                <div className="button-report b2" id="button-10">
                  <input
                    type="checkbox"
                    className="checkbox-report"
                    checked={reportOption !== 'report'}
                    onChange={bugOption}
                  />
                  <div className="knobs">
                    <span>User reports</span>
                  </div>
                  <div className="layer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="refresh-reports" onClick={() => refresh()}>
          <span className="icon icon-refresh"></span>
        </div>
        <div className="select-sortby-report">
          <Select
            searchable={false}
            values={[options.find((opt) => opt.value === 'first')]}
            options={options}
            onChange={onChangeOption}
            className="reporttable-sortby"
          />
        </div>
        {showInfo && (
          <ReportUsers
            report={reports[selectedReport - 1]}
            closeInfoHandler={closeUserInfo}
          />
        )}
      </Card.Header>
      <Card.Body className="report-card-body">
        {reportOption === 'report' && (
          <Table responsive striped borderless hover variant="dark">
            <thead>
              <tr>
                <th></th>
                <th>Plaintiff</th>
                <th>Reason</th>
                <th>Defendant</th>
                <th>Status</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {reports &&
                reports.map((report, index) => {
                  const { senderUser, receiverUser, reason } = report;
                  const senderImage =
                    senderUser.imgurl &&
                    senderUser.imgurl.length !== 0 &&
                    senderUser.imgurl[0];
                  const receiverImage =
                    receiverUser.imgurl &&
                    receiverUser.imgurl.length !== 0 &&
                    receiverUser.imgurl[0];
                  return (
                    <tr>
                      <td>{(page - 1) * 12 + index + 1}</td>
                      <td>
                        <div className="report-sender-info">
                          {senderImage ? (
                            <Media image={senderImage} />
                          ) : (
                            // <Image
                            //   publicId={getImgName(senderImage)}
                            //   version={getImgVersion(senderImage)}
                            //   resource_type={checkGIF(senderImage) ? "video" : "image"}
                            //   effect="loop"
                            //   cloud_name="sugarbae"
                            //   className="img-table"
                            // >
                            //   <Transformation
                            //     height="200"
                            //     video_sampling="20"
                            //     delay="250"
                            //     duration="10"
                            //     effect="loop"
                            //   />
                            // </Image>
                            <div className="none-image-container">
                              <NoneImage
                                className="none-user-image"
                                src={
                                  senderUser.gender === 'male'
                                    ? male_user
                                    : female_user
                                }
                              />
                            </div>
                          )}
                          <p className="report-sender-name">
                            {senderUser.username}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="report-reason">{reason}</div>
                      </td>
                      <td>
                        <div className="report-sender-info">
                          {receiverImage ? (
                            <Media image={receiverImage} />
                          ) : (
                            // <Image
                            //   publicId={getImgName(receiverImage)}
                            //   version={getImgVersion(receiverImage)}
                            //   resource_type={
                            //     checkGIF(receiverImage) ? 'video' : 'image'
                            //   }
                            //   effect="loop"
                            //   cloud_name="sugarbae"
                            //   className="img-table"
                            // >
                            //   <Transformation
                            //     height="200"
                            //     video_sampling="20"
                            //     delay="250"
                            //     duration="10"
                            //     effect="loop"
                            //   />
                            // </Image>
                            <div className="none-image-container">
                              <NoneImage
                                className="none-user-image"
                                src={
                                  receiverUser.gender === 'male'
                                    ? male_user
                                    : female_user
                                }
                              />
                            </div>
                          )}
                          <p className="report-sender-name">
                            {receiverUser.username}
                          </p>
                        </div>
                      </td>
                      <td>
                        {report.receiverUser.banned ? (
                          <Badge variant="danger">Banned</Badge>
                        ) : (
                          <Badge variant="success">No Banned</Badge>
                        )}
                      </td>
                      <td>{moment(report.date).format('LLL')}</td>
                      <td>
                        <Dropdown className="report-dropdown-action">
                          <Dropdown.Toggle id="dropdown-report-admin">
                            <span className="icon icon-more-option"></span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="admin-report-menu">
                            <Dropdown.Item
                              className="admin-report-option"
                              onClick={() => banDefendant(index)}
                            >
                              Ban defendant
                            </Dropdown.Item>
                            <Dropdown.Divider className="admin-reprt-divider" />
                            <Dropdown.Item
                              className="admin-report-option"
                              onClick={() => showUserInfo(index)}
                            >
                              Users
                            </Dropdown.Item>
                            <Dropdown.Divider className="admin-reprt-divider" />
                            <Dropdown.Item
                              className="admin-report-option"
                              onClick={() => declineReport(index)}
                            >
                              Decline report
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <span className="icon icon-more-option"></span> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        )}
        {reportOption === 'bug' && (
          <div ref={scrollRef} className="bug-panel">
            {bugs &&
              bugs.map((bug, index) => {
                return <BugCard bug={bug} />;
              })}
          </div>
        )}
      </Card.Body>
      {reportOption === 'report' && (
        <Card.Footer
          style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            height: '50px',
            background: '#343a40',
            zIndex: 2,
          }}
        >
          <div className="card-footer-wrap">
            <div className="reporttable-pagination">
              <div className="arrow-panel" onClick={() => prevPage()}>
                <span className="icon icon-prev"></span>
              </div>
              <div className="arrow-panel" onClick={() => nextPage()}>
                <span className="icon icon-next"></span>
              </div>
              <div className="pages-title">{`Pages: `}</div>
              <div className="report-page">{page}</div>
            </div>
          </div>
        </Card.Footer>
      )}
    </Card>
  );
};

export default withRouter(ReportTab);
