import React from "react";

function Terms(props) {
  const backHandler = (e) => {
    props.backHandler();
  };
  return (
    <div className="desktop-v2-settings-content">
      <div className="desktop-v2-settings-content-header">
        <div className="desktop-v2-settings-back-button" onClick={backHandler}>
          <i
            className="far fa-long-arrow-alt-left"
            style={{ marginRight: "5px" }}
          />
          <span>Back</span>
        </div>
        <h1 className="desktop-v2-settings-content-header-title">
          Terms of Service
        </h1>
      </div>
      <div className="desktop-v2-settings-help-content">
        <p>By using Sugarbae.com, you agree that:</p>
        <p>
          You’re at least 18 years old. It’s legal for you to use this website
          and accept our terms of agreement in your country of residence. You’ve
          never been convicted of a felony and you’re not registered as a sex
          offender. It’s not illegal for you to use these services or be a
          member of this website in the rules and regulations of your country.
        </p>
        <p>
          Your use of this service represents your agreement with these terms.
          We may periodically modify these terms, and any changes will be
          effective as soon as they’re posted on this website.
        </p>
        <p>
          If you don’t consent to these terms of services, you agree to no
          longer use these services and cancel your membership.
        </p>
        <p>
          You can sign up for sugarbae.com at no cost, but by using the service
          you acknowledge that additional benefits, like contacting members,
          viewing photos, and favoriting profiles, may only be available if
          you’re a paying subscriber. You also acknowledge that your profile may
          still remain visible even if you’re not actively using the service.
        </p>
        <p>
          We reserve the right to block users from certain IP addresses from
          using or accessing this website for any reason.
        </p>
        <h3 className="">Your Terms and Termination</h3>
        <p>
          You agree to these terms as long as you’re a member of sugarbae.com:
        </p>
        <ul>
          <li>
            You can change your membership status at any time, for any reason by
            following the instructions on your Account page.
          </li>
          <li>
            We can terminate or suspend your membership or subscription to the
            service at any time, without notice, if we believe you’ve violated
            this agreement, in which case you won’t be entitled to a refund.
            We’re not required to disclose the reason for your termination (and
            it may be prohibited by law).
          </li>
        </ul>

        <h3 className="">Your Subscription and Billing</h3>
        <p>
          We will bill you though an online account for use of any services
          through this website. By purchasing credits, you agree:
        </p>
        <ul>
          <li>
            To pay all charges at the agreed upon price at the time of your
            purchase
          </li>
          <li>
            To authorize the company to charge your chosen payment provider
          </li>
          <li>
            That we can correct any billing mistakes or errors even if we’ve
            already received or requested a payment
          </li>
          <li>
            If you reverse a payment, we can terminate your account immediately
            and you will not be entitled to a refund or a reinstatement of your
            subscription
          </li>
          <li>
            To provide current and accurate billing information for your account
          </li>
          <li>
            To alert us to any security breaches associated with your account or
            payment method
          </li>
        </ul>
        <p>
          If you wish to change or cancel your subscription, You can contact us.
        </p>

        <h3>Your Account Security</h3>
        <p>
          You’re responsible for any activity that occurs on your account and
          for the security of your username and password. If there is any
          unauthorized use of your username, password, or your account, you
          agree to notify us immediately.
        </p>

        <h3>Your Interactions with Other Members</h3>
        <p>
          Sugarbae.com is for personal use only. You may not use this site or
          information gathered on this site for commercial endeavors such as
          advertising or soliciting members to buy or sell products or
          networking for commercial purposes.
        </p>

        <p>
          You’re responsible for any interactions you have with other members of
          this site. By using this website you agree that you understand we
          don’t conduct criminal background checks or security screening of any
          of our members. We are not responsible, nor do we verify the validity
          of, any statements made by any members of our site.
        </p>
        <p>
          We’re not responsible for the conduct of any member of this website.
          We’re not liable for any loses and damages arising from your use
          (including communications through or meetings resulting from the use
          of this website) or the use of any member of this website, including
          death, bodily injury, emotional distress, or any other damages.
        </p>
        <p>
          We make no guarantee about your results from using this website,
          either expressly stated or implied.
        </p>
        <p>
          We can investigate and take legal action against any members of this
          site in response to illegal or unauthorized use of this website,
          including conducting criminal background checks, sex offender register
          searches, or other screenings, at any time, and using available public
          records.
        </p>
        <p>
          We do our best to verify member photos, but we can't guarantee that
          members will look the same in person as they do in any photos on their
          profile.
        </p>
        <p>
          From time to time, we may create test accounts to test certain
          features of the site.
        </p>
        <h3>Content and Information Posted By You</h3>
        <p>
          You’re responsible for any information or content that you include on
          our website (either posted, linked to, or uploaded by you) or
          transmitted to other members of this service. We reserve the right to
          remove any content that violates our guidelines and terminate the
          membership of any member who violates them.
        </p>
        <p>
          By using this website, you agree to not post on this website or share
          with any member any content that:
        </p>
        <ul>
          <li>
            is offensive, inaccurate, abusive, obscene, profane, intimidating,
            harassing, racially offensive, or illegal
          </li>
          <li>infringes or violates another person’s rights</li>
          <li>promotes harm to another member</li>
          <li>is inaccurate, misleading, or false</li>
          <li>intimidates or harasses another member</li>
          <li>attempts to defraud another member</li>
          <li>spams or trolls another member</li>
          <li>
            uses someone else’s copyrighted work or content without permission
          </li>
          <li>promotes illegal activity</li>
          <li>contains information that is deliberately misleading</li>
          <li>
            contains photos, videos, or audio of another person without
            permission
          </li>
          <li>
            contains content that exploits someone in an illegal manner (either
            sexually, violently, or otherwise)
          </li>
          <li>
            attempts to gather personal info from anyone under the age of 18
          </li>
          <li>contains viruses or other damaging code</li>
          <li>
            Impersonates another person or any affiliation with another person,
            group, or corporation promotes or publicizes any sales or commercial
            activities
          </li>
        </ul>
        <p>By using this website, you agree that:</p>
        <ul>
          <li>
            We’re not responsible for any content you post on this website
          </li>
          <li>
            We can delete any content at any time if we feel it violates our
            terms and conditions or may harm our company in any way
          </li>
          <li>
            We have the right to use, copy, display, adapt, or modify any
            content you post
          </li>
          <li>
            Any content you post publicly may be viewed by any person viewing
            the website
          </li>
          <li>
            We have the right to access and share your account information and
            content with law enforcement if we’re required to do so by law or if
            we feel it’s necessary to help with an investigation
          </li>
          <li>We can access your account information</li>
        </ul>
        <p>
          If you believe your photos, work, or content has been posted on our
          website without your written consent, contact us to alert us to the
          copyright infringement. Please include a description of the work you
          believe has been infringed, a link to where the copied work is listed,
          your contact information, and a written statement claiming you believe
          your work has been infringed.
        </p>
        <h3>Banned Activities</h3>
        <p>
          If you believe your photos, work, or content has been posted on By
          using this website, you agree to not participate in any of the
          activities listed below. Participating in any of these activities at
          any time gives us the right to terminate your membership and forfeit
          any pre-paid fees.
        </p>
        <ul>
          <li>Impersonate another person</li>
          <li>
            Post any prohibited content (listed in “Content and Information
            Posted By You” listed above)
          </li>
          <li>Intimidate, stalk, harass, or “troll” another member</li>
          <li>
            Be abusive, intimidating, threatening, harassing, or otherwise
            offensive to our support staff
          </li>
          <li>
            Use any device to index, process, retrieve, or in any way reproduce
            or circumvent the contents, structure, or presentation of this
            website
          </li>
          <li>
            Collect display names or email addresses of members of this website
            for the purpose of sending unsolicited communication
          </li>
          <li>
            Interfere with or disrupt the service of this website or any
            services or networks connected to this website
          </li>
          <li>
            Use, post, transmit, or distribute any content or information
            obtained from this website or service
          </li>
          <li>
            Transmit any computer code, files, or viruses designed to damage,
            destroy, or limit the functionality of this website or any computer
            software or hardware it comes in contact with
          </li>
          <li>
            Make any statements that you imply are endorsed by us without our
            prior written consent.
          </li>
          <li>
            Direct any users to another service or website through our website
            or service
          </li>
        </ul>
        <h3>Our Communication With You</h3>
        <p>
          By becoming a member of this website, you agree to receive email
          communications from us, including transactional (relating to your use
          of the website); service announcements; or commercial offers,
          promotions, or special offers from us or third party partners. These
          can be unsubscribed from at any point.
        </p>
        <h3>Our Proprietary Rights</h3>
        <p>
          We own all content, trademarks, trade names, service marks, and other
          intellectual property rights on this website and service. By using
          this website, you agree to not copy, modify, transmit, create any
          derivative works from, make use of, or reproduce in any way and of the
          above without first obtaining written consent.
        </p>
        <h3>Advertising by or Links to Third Party Services</h3>
        <p>
          This website may have links, promotions, or advertisements of third
          parties, including links to other websites. By using this website, you
          agree we’re not responsible for the content contained on any third
          party sites we link to or promote, nor are we responsible for the
          availability of any third party services. We’re also not liable for
          any problems related to any content or services provided by any third
          parties.
        </p>
        <h3>Modifications to Our Service and Terms of Service</h3>
        <p>
          By using this website you agree that we have the right to modify or
          discontinue any part of this website, service, and terms of service
          with or without notice. We’ll use any reasonable means available to
          let you know of any changes (by email, postal mail, postings on our
          website, or other means), but you agree you may not receive these
          notices if you’ve accessed this service or website in an unauthorized
          manner. We’re not responsible for you not receiving any of these
          messages.
        </p>
        <h3>Disclaimers </h3>
        <p>By using this website, you agree that we’re not responsible for:</p>
        <ul>
          <li>
            Any loss of damage of personal property, injury, or death as a
            result of using this website or any information or content posted on
            this website or in connection with this website.
          </li>
          <li>
            The conduct of any of our members, whether offline or through this
            service
          </li>
          <li>
            Any problems resulting from downloading materials in connection with
            this website or service
          </li>
        </ul>
        <p>
          We do not give warranties of any kind, even implied, of your
          satisfaction with this service or guarantee that your service will be
          uninterrupted, error-free, or without defects or problems.
        </p>
        <p>
          You use this website and service and download any materials from this
          website at your own risk. You’re responsible for and waive our
          responsibility for any damage to your computer, internet service, or
          any other device or data as a result of using this service or
          downloading any content from this website.
        </p>
        <p>
          The use of this website and service is intended for your entertainment
          only. We’re not responsible for any behavior or results you achieve by
          following any advice found through this website.
        </p>
        <p>
          Our liability to you for any cause will always be limited to the
          amount you paid for the service. You agree that any claim you make
          against this website or service must be filed within one year after
          the claim arose, regardless of any statute or law to the contrary.
        </p>
      </div>
    </div>
  );
}

export default Terms;
