import React, { useEffect, useState, useContext } from "react";
import "./imagereview.scss";

function ImagesReview() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    return () => {};
  }, []);

  const fetchData = async (e) => {};

  return (
    <div className="admin-image-review-component">
      <div></div>
    </div>
  );
}

export default ImagesReview;
