import React, { useState, useContext } from "react";
import "./desktopsliders.scss";
import PlacesAutocomplete from "react-places-autocomplete";
import GoogleMapReact from "google-map-react";
import { Button, Form } from "react-bootstrap";
import { UserContext } from "../../../../../context/UserContext";
import { api } from "../../../../../api";

function Location(props) {
  const context = useContext(UserContext);
  const { filters } = context.user;
  const [address, setAddress] = useState(filters.address);
  const [changingAddress, setChangingAddress] = useState("");
  const [updateLocationOpen, setUpdateLocationOpen] = useState(false);
  const [showMapPreview, setShowMapPreview] = useState(false);
  const [previewCoordinates, setPreviewCoordinates] = useState([]);

  const changeHandler = (e) => {
    setChangingAddress(e);
  };
  const commitHandler = async (e) => {
    setAddress(e);
    setChangingAddress(e);

    const data = {
      address: e,
    };

    await api
      .fetchAddressCoordinates(data)
      .then((res) => {
        if (res.status === 200) {
          setPreviewCoordinates(res.data.coordinates);
          setShowMapPreview(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetHandler = (e) => {
    // setSortBy("Recently Active");
  };

  const editLocationToggle = (e) => {
    if (updateLocationOpen) {
      setUpdateLocationOpen(false);
    } else {
      setUpdateLocationOpen(true);
    }
  };

  const saveHandler = (e) => {
    if (filters.address !== address) {
      filters.address = address;
      props.updateHandler(address);
    } else {
      props.backHandler();
    }
  };

  const searchOptions = {
    types: ["(cities)"],
  };

  return (
    <div className="desktop-v2-filters-content">
      <div className="desktop-filter-slider">
        <div className="desktop-filter-slider-header">
          <div
            className="desktop-filter-slider-back-button"
            onClick={props.backHandler}
          >
            <i className="far fa-long-arrow-alt-left" />
            <span className="desktop-filter-slider-back-text">Back</span>
          </div>
          <div className="desktop-filter-slider-header-section">
            <div className="desktop-v2-filters-slider-icon">
              <i className="far fa-home desktop-v2-filters-slider-icon" />
            </div>
            <div className="desktop-v2-filters-slider-title">Location</div>
          </div>
          <div
            className="desktop-filter-slider-header-section"
            style={{ cursor: "pointer" }}
          >
            {updateLocationOpen ? (
              <span
                className="filter-slider-v2-reset-text"
                onClick={editLocationToggle}
              >
                Reset
              </span>
            ) : (
              <span
                className="filter-slider-v2-reset-text"
                onClick={editLocationToggle}
              >
                Edit
              </span>
            )}
          </div>
        </div>
        <div className="desktop-filter-slider-content">
          {!updateLocationOpen && (
            <h1 className="desktop-location-title">
              You are searching for users in{" "}
              <span style={{ fontWeight: "700" }}>{filters.address}.</span>
            </h1>
          )}
          {!updateLocationOpen && (
            <div className="location-map-wrapper">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyCoSbKzFFYZ2HnK-jOZwIqdhwp7Aq4fu4I",
                }}
                defaultCenter={{
                  lat: filters.location.coordinates[1],
                  lng: filters.location.coordinates[0],
                }}
                defaultZoom={11}
                options={{
                  panControl: false,
                  mapTypeControl: false,
                  scrollwheel: false,
                  disableDefaultUI: true,
                }}
                defaultOptions={{
                  fullscreenControl: false,
                }}
              />
            </div>
          )}
          {updateLocationOpen && (
            <div className="filter-slider-wrapper-location">
              <Form className="filter-slider-form-location">
                <Form.Group className="filter-slider-form-group-location">
                  <PlacesAutocomplete
                    value={changingAddress}
                    onChange={changeHandler}
                    onSelect={commitHandler}
                    searchOptions={searchOptions}
                    shouldFetchSuggestions
                    highlightFirstSuggestion
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div className="location-v2-input-wrapper">
                        <Form.Control
                          size="lg"
                          {...getInputProps({
                            placeholder: "Enter your city",
                            className: "location-v2-input",
                          })}
                          autoFocus
                        />

                        {showMapPreview ? (
                          <div className="location-map-wrapper">
                            <GoogleMapReact
                              bootstrapURLKeys={{
                                key: "AIzaSyCoSbKzFFYZ2HnK-jOZwIqdhwp7Aq4fu4I",
                              }}
                              defaultCenter={{
                                lat: previewCoordinates[1],
                                lng: previewCoordinates[0],
                              }}
                              defaultZoom={11}
                            />
                          </div>
                        ) : (
                          <div className="location-v2-dropdown-wrapper">
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "location-suggestion-item-active"
                                : "location-suggestion-item";

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                  })}
                                >
                                  <i className="far fa-map-marker-alt location-suggestion-icon" />
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                </Form.Group>
              </Form>
            </div>
          )}
        </div>
        <div className="desktop-filter-slider-footer" onClick={saveHandler}>
          <div className="desktop-filter-slider-apply-button">Apply</div>
        </div>
      </div>
    </div>
  );
}

export default Location;
