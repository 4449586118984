import React, { useState, useEffect } from 'react';
import { Image, Transformation } from 'cloudinary-react';
import { Badge, Button } from 'react-bootstrap';
import Axios from 'axios';

import {
  getImgVersion,
  getImgName,
  checkGIF,
} from '../../../../../utils/getCloudinaryInfo';
import { api } from '../../../../../api';

import '../../../admincomponents.scss';
import { ref } from 'yup';
import Media from '../../../../../components/media/Media';

export const PersonalImages = (props) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [imgurl, setImgurl] = useState(props.user.imgurl);
  const [refresh, setRefresh] = useState(false);
  const [approved, setApproved] = useState(false);
  const [noImage, setNoImage] = useState(false);
  const [isBanned, setIsBanned] = useState(props.user.banned);
  const mainImage = imgurl[0];
  let { approvedimgurl } = props.user;

  const removeHandler = () => {
    const data = {
      user: props.user,
      image: imgurl[imageIndex],
    };

    return api
      .removeImage(data)
      .then(() => {
        imgurl.splice(imageIndex, 1);
        setImageIndex(0);
        setImgurl(imgurl);
        if (imgurl.length === 0) {
          setNoImage(true);
          setTimeout(() => {
            props.removeUser();
            props.closeHandler();
          }, 1500);
        }
        setRefresh(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const banUserHandler = () => {
    const token = localStorage.getItem('auth');
    const baseurl = process.env.REACT_APP_API_URL;
    const url = baseurl + '/api/admin/banned';
    const config = {
      headers: { auth: token },
      user: props.user,
      banned: true,
    };

    return Axios.post(url, config)
      .then((res) => {
        setIsBanned(true);
        props.banHandler();
      })
      .catch((err) => {
        console.log('Error Submitting Action');
      });
  };

  const approveHandler = () => {
    if (approved) {
      return null;
    }
    const data = {
      user: props.user,
      image: imgurl[imageIndex],
    };

    return api
      .approveImage(data)
      .then(() => {
        approvedimgurl.push(imgurl[imageIndex]);
        setApproved(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    const index = approvedimgurl.findIndex(
      (imageurl) => imageurl === imgurl[imageIndex]
    );
    if (index > -1) {
      setApproved(true);
    } else {
      setApproved(false);
    }
  }, [imageIndex]);

  useEffect(() => {
    //re-render for Banned Badge
  }, [setIsBanned]);

  if (noImage) {
    return (
      <div className="personal-non-image">
        <span className="icon icon-no-result"></span>
      </div>
    );
  } else {
    return (
      <div>
        <div className="personal-header">
          <Media image={mainImage} />

          {/* <Image
            publicId={getImgName(mainImage)}
            version={getImgVersion(mainImage)}
            resource_type={checkGIF(mainImage) ? "video" : "image"}
            effect="loop"
            cloud_name="sugarbae"
            className="personal-image-header"
          >
            <Transformation
              height="200"
              video_sampling="20"
              delay="250"
              duration="10"
              effect="loop"
            />
          </Image> */}
          <p className="personal-image-username">{props.user.username}</p>
          <div
            className="personal-images-close"
            onClick={() => props.closeHandler()}
          >
            <p className="personal-close">Close</p>
            <span className="icon icon-close"></span>
          </div>
          <div className="image-tab-ban-action">
            {isBanned ? (
              <Badge variant="warning">Banned User</Badge>
            ) : (
              <Button variant="danger" onClick={() => banUserHandler()}>
                Ban User
              </Button>
            )}
          </div>
        </div>
        <div className="personal-panel-body">
          <div className="personal-panel-image-show">
            <Media image={imgurl[imageIndex]} />

            {/* <Image
              publicId={getImgName(imgurl[imageIndex])}
              version={getImgVersion(imgurl[imageIndex])}
              resource_type={checkGIF(imgurl[imageIndex]) ? 'video' : 'image'}
              effect="loop"
              cloud_name="sugarbae"
              className="personal-image-shower"
            >
              <Transformation
                height="200"
                video_sampling="20"
                delay="250"
                duration="10"
                effect="loop"
              />
            </Image> */}
            {approved && (
              <Badge pill variant={'success'} className="personal-image-badge">
                {'Approved'}
              </Badge>
            )}
            <div className="personal-image-action">
              <div
                className="personal-image-action-option"
                onClick={() => removeHandler()}
              >
                <span className="icon icon-trash"></span>
                <p className="personal-image-action-title">Decline Picture</p>
              </div>
              <div
                className="personal-image-action-option"
                onClick={() => approveHandler()}
              >
                <span className="icon icon-apply-filter"></span>
                <p className="personal-image-action-title">Approve Picture</p>
              </div>
            </div>
          </div>
          <div className="personal-image-list">
            {imgurl.map((image, index) => {
              return (
                <Media image={image} key={index} />
                // <Image
                //   publicId={getImgName(image)}
                //   version={getImgVersion(image)}
                //   resource_type={checkGIF(image) ? 'video' : 'image'}
                //   effect="loop"
                //   cloud_name="sugarbae"
                //   className="img-card"
                //   onClick={() => setImageIndex(index)}
                // >
                //   <Transformation
                //     height="200"
                //     video_sampling="20"
                //     delay="250"
                //     duration="10"
                //     effect="loop"
                //   />
                // </Image>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};

export default PersonalImages;
