import React, { Component } from "react";
import { Badge, Dropdown } from "react-bootstrap";
import { motion } from "framer-motion";

import MessageQueue from "./inboxComponents/messageQueue/MessageQueue";
import { UserContext } from "../../../context/UserContext";

import "./inbox.scss";

const pageVariants = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: "-100%",
  },
};

const pageTransition = {
  transition: "linear",
};

export class Inbox extends Component {
  static contextType = UserContext;
  state = {
    messageCount: null,
    chatOpen: true,
  };

  setFilterBy = (option) => {
    this.context.updateMessageQueueSortby(option);
  };

  render() {
    return (
      <motion.div
        className="inbox-component"
        transition={pageTransition}
        initial="out"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <div className="inbox-sort-wrap">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="dropdown-toggle-title">
                {this.context.messageQueueSortby}
              </div>
              <div className="dropdown-toggle-icon">
                <i className="far fa-chevron-down"></i>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-inbox">
              <Dropdown.Item onClick={() => this.setFilterBy("All Messages")}>
                <div className="inbox-dropdown-item-icon-wrap"></div>
                <div className="inbox-dropdown-item-title">
                  <span className="icon icon-message-sort-all"></span>
                  All Messages
                </div>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.setFilterBy("Compliments")}>
                <div className="inbox-dropdown-item-icon-wrap"></div>
                <div className="inbox-dropdown-item-title">
                  <span className="icon icon-message-sort-compliments"></span>
                  Compliments
                </div>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.setFilterBy("Online")}>
                <div className="inbox-dropdown-item-icon-wrap"></div>
                <div className="inbox-dropdown-item-title">
                  <span className="icon icon-message-sort-online"></span>
                  Online
                </div>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => this.setFilterBy("Matches")}>
                <div className="inbox-dropdown-item-icon-wrap"></div>
                <div className="inbox-dropdown-item-title">
                  <span className="icon icon-message-sort-matches"></span>
                  Matches
                </div>
              </Dropdown.Item>

              <Dropdown.Item onClick={() => this.setFilterBy("Nearest")}>
                <div className="inbox-dropdown-item-icon-wrap"></div>
                <div className="inbox-dropdown-item-title">
                  <span className="icon icon-message-sort-nearest"></span>
                  Nearest
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <MessageQueue
          chatToggle={this.props.chatToggle}
          index={this.props.index}
        />
      </motion.div>
    );
  }
}

export default Inbox;
