import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../../../../context/UserContext";
import { Link, withRouter } from "react-router-dom";
import Axios from "axios";
import "./accounttab.scss";
import "../desktopprofiletabs.scss";
import { Button, Alert } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { api } from "../../../../api";

var moment = require("moment");

function AccountTab(props) {
  const context = useContext(UserContext);

  var premium = props.user.premium;
  var subscription = props.user.subscription;
  var plan = subscription.plan;
  var nextBillingDate = "";
  var statusText = "";
  var startDate = "";

  var alert = subscription.alert;

  var currency = subscription.currency;
  var billingPrice = subscription.billingPrice;

  if (subscription) {
    startDate = subscription.startDate;
    if (startDate) {
      startDate = moment(startDate).format("MMMM Do YYYY");
    }

    if (subscription.active) {
      statusText = "Active";
    } else {
      statusText = "Not Active";
    }
  }

  if (plan === "monthly") {
    nextBillingDate = moment(subscription.startDate)
      .add(1, "month")
      .format("MMMM Do YYYY");
  } else if (plan === "semiannual") {
    nextBillingDate = moment(subscription.startDate)
      .add(180, "days")
      .format("MMMM Do YYYY");
  } else if (plan === "annual") {
    nextBillingDate = moment(subscription.startDate)
      .add(365, "days")
      .format("MMMM Do YYYY");
  }

  const [
    deactivateAccountConfirmationOpen,
    setDeactivateAccountConfirmationOpen,
  ] = useState(false);

  const [deactivateSuccess, setDeactivateSuccess] = useState(false);

  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);

  const [cancelLoading, setCancelLoading] = useState(false);

  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [cancelError, setCancelError] = useState(false);

  const toggleDeativateAccountConfirmation = (e) => {
    if (deactivateAccountConfirmationOpen) {
      setDeactivateAccountConfirmationOpen(false);
    } else {
      setDeactivateAccountConfirmationOpen(true);
    }
  };

  const toggleCancelConfirmation = (e) => {
    if (cancelConfirmationOpen) {
      setCancelConfirmationOpen(false);
    } else {
      setCancelConfirmationOpen(true);
    }
  };

  const submitDeactivation = async (e) => {
    const data = {
      userid: context.user._id,
    };

    return api
      .deactivateAccount(data)
      .then((res) => {
        if (res.status === 200) {
          setDeactivateSuccess(true);
          toggleDeativateAccountConfirmation(false);

          setTimeout(function () {
            localStorage.removeItem("auth");
            props.history.push("/");
          }, 3000);
        }
      })
      .catch((err) => {});
  };
  const submitCancelSubscription = async (e) => {
    const data = {
      userid: context.user._id,
    };

    return api
      .cancelSubscription(data)
      .then((res) => {
        if (res.status === 200) {
          var successfulCancel = res.data.successfulCancel;
          if (successfulCancel) {
            setCancelLoading(false);
            setCancelConfirmationOpen(false);
            setCancelSuccess(true);
            setCancelError(false);
          } else {
            setCancelLoading(false);
            setCancelConfirmationOpen(false);
            setCancelSuccess(false);
            setCancelError(true);
          }
        } else {
          setCancelLoading(false);
          setCancelConfirmationOpen(false);
          setCancelSuccess(false);
          setCancelError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setCancelLoading(false);
        setCancelConfirmationOpen(false);
        setCancelSuccess(false);
        setCancelError(true);
      });
  };

  return (
    <div className="desktop-profile-tab-component">
      <div className="desktop-profile-tab-header">
        <h1 className="desktop-profile-tab-header-title">
          Manage Your Account
        </h1>
      </div>
      <div className="desktop-profile-tab-content-container">
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap-verifications">
            <h3 className="desktop-profile-tab-section-title-verifications">
              Manage Subscription
            </h3>
          </div>
          {premium ? (
            <div className="desktop-profile-tab-section-form-wrapper-verifications">
              {cancelError && (
                <Alert variant="danger">
                  Error cancelling your subscription. Please contact
                  support@sugarbae.com
                </Alert>
              )}
              {cancelSuccess && (
                <Alert variant="success">
                  Your subscription has been cancelled. You can resume your
                  subscription anytime from your account settings.
                </Alert>
              )}

              {cancelConfirmationOpen ? (
                <div className="desktop-profile-tab-manage-subscription-innerwrap">
                  {cancelLoading && (
                    <div className="desktop-profile-cancel-loading-wrapper">
                      <div className="desktop-profile-cancel-loading-innerwrap">
                        <ClipLoader color="#fff" />
                      </div>
                    </div>
                  )}
                  <div className="desktop-profile-cancel-subscription-section">
                    <p className="desktop-profile-cancel-subscription-text">
                      Are you sure you want to cancel your subscription? You
                      will still have Premium access until your subscription
                      expires. You can resume your subscription at anytime.
                    </p>
                  </div>
                  <div className="desktop-profile-cancel-subscription-section">
                    <Button
                      variant="link"
                      className="desktop-deactivate-account-cancel-button"
                      onClick={toggleCancelConfirmation}
                    >
                      Back
                    </Button>
                    <Button
                      // className="desktop-deactivate-account-confirm-button"
                      variant="outline-danger"
                      onClick={submitCancelSubscription}
                    >
                      Confirm Cancel
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="desktop-profile-tab-manage-subscription-innerwrap">
                  <p className="desktop-manage-subscription-text">
                    Status:{" "}
                    <span
                      className={
                        subscription.active
                          ? "desktop-manage-subscription-premium-status-text-active"
                          : "desktop-manage-subscription-premium-status-text-not-active"
                      }
                    >
                      {statusText}
                    </span>
                  </p>
                  <p className="desktop-manage-subscription-text">
                    Plan:{" "}
                    <span className="desktop-manage-subscription-item-text">
                      {plan}
                    </span>
                  </p>
                  {subscription.active && (
                    <p className="desktop-manage-subscription-text">
                      Start Date:{" "}
                      <span className="desktop-manage-subscription-item-text">
                        {startDate}
                      </span>
                    </p>
                  )}
                  {subscription.active ? (
                    <p className="desktop-manage-subscription-text">
                      Next Billing Date:{" "}
                      <span className="manage-subscription-item-text">
                        {nextBillingDate}
                      </span>
                    </p>
                  ) : (
                    <p className="desktop-manage-subscription-text">
                      Subscription End Date:{" "}
                      <span className="desktop-manage-subscription-item-text">
                        {nextBillingDate}
                      </span>
                    </p>
                  )}
                  {subscription.active ? (
                    <p className="desktop-manage-subscription-text">
                      Billing Price:{" "}
                      <span className="desktop-manage-subscription-item-text">
                        {billingPrice} {currency}
                      </span>
                    </p>
                  ) : null}
                  {subscription.active ? (
                    <div className="desktop-manage-subscription-subscription-button-wrapper">
                      <Button
                        className="desktop-manage-subscription-cancel-subscription-button"
                        variant="outline-danger"
                        size="lg"
                        onClick={toggleCancelConfirmation}
                      >
                        Cancel Subscription
                      </Button>
                    </div>
                  ) : (
                    <div className="desktop-manage-subscription-subscription-button-wrapper">
                      <Button className="desktop-manage-subscription-resume-subscription-button">
                        Resume Subscription
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="desktop-profile-tab-section-form-wrapper-verifications">
              <p className="desktop-profile-manage-subscription-status-text">
                No active subscription.
              </p>
              <Link
                to="/app/premium"
                className="desktop-profile-manage-subscription-link"
              >
                <Button className="desktop-profile-manage-subscription-premium-cta-button">
                  <i className="far fa-gem desktop-manage-subscription-go-premium-icon"></i>
                  Go Premium
                </Button>
              </Link>
            </div>
          )}
        </div>
        <div className="desktop-profile-tab-section">
          <div className="desktop-profile-tab-section-title-wrap-verifications">
            <h3 className="desktop-profile-tab-section-title-verifications">
              Deactivate Account
            </h3>
          </div>
          {deactivateAccountConfirmationOpen ? (
            <div className="desktop-profile-tab-section-form-wrapper-verifications">
              <div className="desktop-profile-deactivate-account-section">
                <p className="desktop-profile-deactivate-account-text">
                  Are you sure you want to deactivate your account? You will
                  lose all your matches, likes and messages. This cannot be
                  undone.
                </p>
              </div>
              <div className="desktop-profile-deactivate-account-section">
                <Button
                  variant="link"
                  className="desktop-deactivate-account-cancel-button"
                  onClick={toggleDeativateAccountConfirmation}
                >
                  Cancel
                </Button>
                <Button
                  className="desktop-deactivate-account-confirm-button"
                  onClick={submitDeactivation}
                >
                  Deactivate
                </Button>
              </div>
            </div>
          ) : (
            <div className="desktop-profile-tab-section-form-wrapper-verifications">
              {deactivateSuccess ? (
                <Alert variant="success">
                  Your account has successfully been deactivate, you will
                  automatically be logged out now.
                </Alert>
              ) : (
                <Button
                  variant="link"
                  className="desktop-deactivate-account-cta-button"
                  onClick={toggleDeativateAccountConfirmation}
                >
                  Deactivate Your Account
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(AccountTab);
