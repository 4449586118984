import React from "react";
import Verification from "../../components/verifications/Verification";

function VerificationPage() {
  let vh = window.innerHeight;
  var vw = window.innerWidth;
  var height = vh + "px";
  var width = vw + "px";

  return (
    <div
      className="app-component"
      style={{
        height: height,
        width: width,
        maxHeight: height,
        maxWidth: width,
      }}
    >
      <Verification />
    </div>
  );
}

export default VerificationPage;
