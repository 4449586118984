import React, { useState, useContext, useEffect, useRef } from 'react';
import './verification.scss';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { UserContext } from '../../context/UserContext';
import ReactCodeInput from 'react-verification-code-input';
import { api } from '../../api';
import EmailValidator from 'email-validator';
import PasswordValidator from 'password-validator';

const passwordSchema = new PasswordValidator();
passwordSchema.is().min(6).is().max(100).has().not().spaces();

function EmailVerifyV2(props) {
  const context = useContext(UserContext);
  const [email, setEmail] = useState(context.user.email);
  const [codeOpen, setCodeOpen] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [validationCode, setValidationCode] = useState('');
  const [verifySuccess, setVerifySuccess] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [verifyButtonDisabled, setVerifyButtonDisabled] = useState(false);
  const [sendAgainSuccess, setSendAgainSuccess] = useState(false);
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordIncorrectError, setPasswordIncorrectError] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const [emailInvalidError, setEmailInvalidError] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (!codeOpen) {
      inputRef.current.focus();
    }
  }, [disabled]);

  const sendEmailCode = async (e) => {
    const data = {
      user: context.user._id,
      email: email,
    };

    return api
      .generateEmailToken(data)
      .then((res) => {})
      .catch((err) => {});
  };

  const sendAgainHandler = (e) => {
    sendEmailCode();
    setSendAgainSuccess(true);
  };

  const validationCodeChange = async (e) => {
    setVerifyError(false);
    setValidationCode(e);

    if (e.length === 6) {
      setValidateLoading(true);

      const data = {
        validationcode: e,
      };

      await api
        .validateEmailToken(data)
        .then((res) => {
          if (res.status === 200) {
            context.updateUserInfo('verifications', res.data.verifications);
            props.handleSuccess();
          }
        })
        .catch((err) => {
          console.log(err);
          setValidateLoading(false);
          setVerifyError(true);
        });
    }
  };

  const disableHandler = (e) => {
    if (disabled) {
      setEmail('');
      setPassword('');
      setDisabled(false);
    } else {
      setEmail(context.user.email);
      setDisabled(true);
      resetErrors();
    }
  };

  const emailChangeHandler = (e) => {
    setEmail(e.target.value);
    setEmailInvalid(false);
    setSubmitDisabled(false);
  };

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);
    setPasswordInvalid(false);
    setSubmitDisabled(false);
  };

  const backHandler = (e) => {
    setCodeOpen(false);
    setValidationCode('');
    setValidateLoading(false);
  };

  const verifyHandler = (e) => {
    if (codeOpen) {
      resetErrors();
      setDisabled(true);
      setCodeOpen(false);
    } else {
      resetErrors();
      setVerifyButtonDisabled(true);
      sendEmailCode();
      setCodeOpen(true);
    }
  };

  const resetErrors = (e) => {
    setSendAgainSuccess(false);
    setVerifyButtonDisabled(false);
    setEmailInvalid(false);
    setPasswordInvalid(false);
    setPasswordIncorrectError(false);
    setEmailExistsError(false);
    setEmailInvalidError(false);
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    setLoading(true);

    var emailValid = EmailValidator.validate(email);
    var passwordValid = passwordSchema.validate(password);

    if (!emailValid) {
      setEmailInvalid(true);
    }
    if (!passwordValid) {
      setPasswordInvalid(true);
    }

    if (emailValid && passwordValid) {
      var data = {
        email: email,
        password: password,
      };

      await api
        .updateEmail(data)
        .then((res) => {
          if (res.status === 200) {
            if (
              res.data.emailValid &&
              res.data.passwordValid &&
              !res.data.emailExists
            ) {
              context.updateUserInfo('email', email);
              var tempVerifications = context.user.verifications;
              tempVerifications.email.status = false;
              context.updateUserInfo('verifications', tempVerifications);
              verifyHandler();
            } else {
              if (!res.data.emailValid) {
                setEmailInvalid(true);

                if (!res.data.emailExists) {
                  setEmailInvalidError(true);
                }
              }

              if (!res.data.passwordValid) {
                setPasswordInvalid(true);
                setPasswordIncorrectError(true);
              }

              if (res.data.emailExists) {
                setEmailInvalid(true);
                setEmailExistsError(true);
              }
              setSubmitDisabled(false);
              setLoading(false);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setSubmitDisabled(false);
          setLoading(false);
        });
    }
  };

  return (
    <div>
      {codeOpen ? (
        <h1 className="verification-content-title">
          Enter the 6 digit verification code we emailed you:
        </h1>
      ) : (
        <h1 className="verification-content-title">
          {disabled ? 'Is this your email?' : 'Enter your new email address:'}
        </h1>
      )}

      {codeOpen ? (
        <div className="verification-code-wrapper">
          <ReactCodeInput
            onChange={validationCodeChange}
            loading={validateLoading}
            disabled={validateLoading}
            fieldWidth={'16%'}
            className="verification-code-input-v2"
          />
          <div className="edit-screen-button-wrapper">
            {sendAgainSuccess ? (
              <span className="new-code-sent-text">New code sent</span>
            ) : (
              <Button
                className="edit-screen-send-again-button"
                onClick={sendAgainHandler}
              >
                Send again
              </Button>
            )}
          </div>
        </div>
      ) : (
        <Form>
          <Form.Group>
            <InputGroup className="verification-content-form">
              <InputGroup.Prepend className="verification-content-form-prepend">
                <InputGroup.Text className="verification-content-form-text">
                  <i className="far fa-envelope verification-form-icon"></i>
                </InputGroup.Text>
              </InputGroup.Prepend>

              <Form.Control
                type="email"
                className="verification-content-form-input"
                value={email}
                disabled={disabled}
                onChange={emailChangeHandler}
                ref={inputRef}
              />
            </InputGroup>
          </Form.Group>
          {!disabled && (
            <Form.Group>
              <h1 className="verification-content-title">
                Confirm your password:
              </h1>
              <InputGroup className="verification-content-form">
                <InputGroup.Prepend className="verification-content-form-prepend">
                  <InputGroup.Text className="verification-content-form-text">
                    <i className="far fa-key verification-form-icon"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>

                <Form.Control
                  type="password"
                  className={
                    passwordInvalid
                      ? 'edit-screen-input-invalid'
                      : 'edit-screen-input'
                  }
                  disabled={disabled}
                  value={password}
                  onChange={passwordChangeHandler}
                  size="lg"
                  invalid={passwordInvalid}
                />
              </InputGroup>
            </Form.Group>
          )}
        </Form>
      )}
      {emailInvalidError && (
        <div className="edit-screen-status-wrapper">
          <i className="far fa-exclamation-circle number-alert-icon"></i>
          <span>The email address you entered in invalid</span>
        </div>
      )}
      {passwordIncorrectError && (
        <div className="edit-screen-status-wrapper">
          <i className="far fa-exclamation-circle number-alert-icon"></i>
          <span>You entered the wrong password</span>
        </div>
      )}
      {emailExistsError && (
        <div className="edit-screen-status-wrapper">
          <i className="far fa-exclamation-circle number-alert-icon"></i>
          <span>The email you entered already exists in our system</span>
        </div>
      )}
      {verifyError && (
        <div className="edit-screen-status-wrapper">
          <i className="far fa-exclamation-circle number-alert-icon"></i>
          <span>The verification code you entered is incorrect</span>
        </div>
      )}
      {codeOpen ? (
        <div className="verification-footer">
          <Button
            className="verification-footer-button-top"
            variant="link"
            onClick={verifyHandler}
          >
            Back
          </Button>

          <Button className="verification-footer-button" variant="danger">
            Confirm
          </Button>
        </div>
      ) : (
        <div className="verification-footer">
          {disabled ? (
            <Button
              className="verification-footer-button-top"
              variant="link"
              onClick={disableHandler}
            >
              No, I need to update it
            </Button>
          ) : (
            <Button
              className="verification-footer-button-top"
              variant="link"
              onClick={disableHandler}
            >
              Back
            </Button>
          )}

          {disabled ? (
            <Button
              className="verification-footer-button"
              variant="danger"
              onClick={verifyHandler}
            >
              Yes, that's my email
            </Button>
          ) : (
            <Button
              className="verification-footer-button"
              variant="danger"
              onClick={updateHandler}
            >
              Confirm Email
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default EmailVerifyV2;
